<template>
  <div class="change-password" v-if="visibleReal">
    <!-- <el-button type="text" @click="dialogVisible=true">修改密砝</el-button> -->
    <el-dialog :title="$t('changePassword.key1')" @closed="closed" :visible.sync="dialogVisible" custom-class="v-dialog v-dialog--xs v-dialog--footer v-dialog--mdpadding" :close-on-click-modal="false" :append-to-body="true" @close="beforeClose ">
      <el-form ref="refForm" :model="form" status-icon :rules="rules" :label-width="$i18n.locale ==='zh' ? '100px' : '150px'">
        <el-form-item :label="$t('changePassword.key2')" prop="password">
          <el-input type="password" v-model="form.password" auto-complete="off" minlength="3" maxlength="20"></el-input>
        </el-form-item>
        <el-form-item :label="$t('changePassword.key3')" prop="pass">
          <el-input type="password" v-model="form.pass" auto-complete="off" minlength="3" maxlength="20"></el-input>
        </el-form-item>
        <el-form-item :label="$t('changePassword.key4')" prop="checkPass">
          <el-input type="password" v-model="form.checkPass" auto-complete="off" minlength="3" maxlength="20"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="customStyle" type="text" size="medium" @click="dialogVisible = false">{{$t('common.cancel')}}</el-button>
        <el-button class="customStyle" plain type="primary" size="medium" @click="change" :loading="btnLoading">{{$t('common.save')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { ChangePassword } from '@/api/login'
export default {
  data() {
    let validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('changePassword.tip2')));
      } else {
        if (this.form.checkPass !== '') {
          this.$refs.refForm.validateField('checkPass');
        }
        callback();
      }
    }

    let validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('changePassword.tip3')));
      } else if (value !== this.form.pass) {
        callback(new Error(this.$t('changePassword.tip4')));
      } else {
        callback();
      }
    }

    return {
      visibleReal: false,
      btnLoading: false,
      form: {
        password: '',
        pass: '',
        checkPass: '',
      },
      dialogVisible: false,
      rules: {
        password: [
          { required: true, message: this.$t('changePassword.tip1'), trigger: 'blur' }
        ],
        pass: [
          { validator: validatePass, trigger: 'blur' }
        ],
        checkPass: [
          { validator: validatePass2, trigger: 'blur'}
        ]
      }
    }
  },
  methods: {
    okHandleValidate() {

      const promise = new Promise((resolve, reject) => {
        this.$refs.refForm.validate(valid => {
          if (valid) {
            resolve(true)
          } else {
            reject(false)
          }
        })
      })

      return promise
    },

    async change() {
      let valid
      try {
        valid = await this.okHandleValidate()
      } catch (e) {
        valid = false
      }

      console.log(valid)
      if (!valid) {
        return
      }

      this.btnLoading = true

      ChangePassword(this.form.password, this.form.pass).then(({data}) => {
        if (data.ErrCode) {
          this.$message(data.ErrMsg)
        } else {
          this.$message({ message: this.$t('common.success'), type: 'success' })
          this.dialogVisible = false
        }
        this.btnLoading = false
      }).catch(err => {
        this.$message(err)
        this.btnLoading = false
      })
    },

    beforeClose() {
      this.form = {
        password: '',
        pass: '',
        checkPass: ''
      }
      this.$refs.refForm.resetFields()
    },
    open() {
      this.dialogVisible = true
      this.visibleReal = true
    },
    closed() {
      this.visibleReal = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/element-variables.scss";
.change-password {
  padding-left: 20px !important;
  padding-right: 0 !important;

  & ::v-deep .el-button {
    color: $--color-text-secondary !important;
    &:hover {
      color: $--color-primary !important;
    }
  }
}
</style>
