<template>
  <el-dialog
    append-to-body
    :visible.sync="mapSelectDialog"
    :close-on-click-modal="false"
    custom-class="v-dialog v-dialog--lg"
    :close-on-press-escape="false"
    :show-close="false"
    :title="$t('other.selectAddress')"
    >
    <div class="map" ref="container" @click="handleBlur">

    </div>
    <div class="fix-panel" @click.native.stop>
      <el-input v-model="key" size="small" placeholder="查找位置" prefix-icon="el-icon-search" @focus="visible=true" @keydown.native.enter="keyChangeHandle"></el-input>
      <el-scrollbar wrap-style="max-height:200px;" v-show="visible" style="border:1px solid #ccc; border-top: 0;" >
        <label class="result-label" v-for="(item,index) in list" :key="index" @click="clickItem(item)">{{item.name}}<span>{{item.district}}</span></label>
        <label class="result-label" v-if="!list.length && key">
          <p style="color: #999;text-align:center;line-height: 48px;">没有位置信息</p>
        </label>
      </el-scrollbar>
    </div>
    <div class="fix-right">
      在<el-input v-model="city"></el-input>查找
    </div>
    <div class="format_address">
      <label class="tip">点击地图选择地址：</label>
      <p v-if="address && point" @click="selectedIndex = 0"><span class="line" :class="{active: selectedIndex == 0}">{{address}} <span style="color:#999;font-size: 12px;">{{point}}</span> <i class="el-icon-success" /> </span></p>
      <p v-if="key && point" @click="selectedIndex = 1"><span class="line" :class="{active: selectedIndex == 1}">{{key}} <span style="color:#999;font-size: 12px;">{{point}}</span> <i class="el-icon-success" /></span></p>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancelHander">{{$t('common.cancel')}}</el-button>
      <el-button type="primary" plain class="border" @click="confirmHandler" :disabled="!address">{{$t('common.confirm')}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { remoteLoad, debounce } from '@/common/utils'
import { GetGeoCode } from '@/api/common'
export default {
  props: {

  },
  data() {
    return {
      city: '全国',
      key: '',
      mapSelectDialog: false,
      address: null,
      lng: 0,
      lat: 0,
      list: [],
      visible: false,
      street: '',
      addressComponent: {},
      selectedIndex: 0
    }
  },
  watch: {
    key() {
      this.keyChangeHandle()
    },
    city() {
      this.keyChangeHandle()
    }
  },
  created() {
    this.keyChangeHandle = debounce(this.keyChange, 300)
  },
  mounted() {
    // this.initMap()
  },
  computed: {
    point() {
      if (!this.lng) {
        return ''
      } else {
        return this.lng + ',' + this.lat
      }
    }
  },
  methods: {
    async initMap() {

      if (!window.AMap) {
        await remoteLoad(this.$AMAP_URL)
      }

      if (!this.$refs.container) {
        return
      }


      let map = new window.AMap.Map(this.$refs.container, {
        resizeEnable: true,
        zoom:11,
        expandZoomRange: true,
        zooms: [3,20]
      })

      const geo = new window.AMap.Geocoder({
        radius: 1000 //范围，默认：500
      })

      map.on('click', e => {
        this.lng = e.lnglat.getLng()
        this.lat = e.lnglat.getLat();

        this.setMarker([e.lnglat.getLng(), e.lnglat.getLat()])

        geo.getAddress(this.marker.getPosition(), (status, result) => {
            if (status === 'complete') {
                console.log(result);
                this.address = result.regeocode.formattedAddress;
                this.addressComponent = { districtName:
                  result.regeocode.addressComponent.province
                  + result.regeocode.addressComponent.city
                  + result.regeocode.addressComponent.district };
                this.select()
            }
        })
      })

      map.on('complete', () => {
        if (this.lng && this.lat) {
          setTimeout(() => {
            this.map.setZoomAndCenter(17, [this.lng, this.lat])
            this.setMarker([this.lng, this.lat])
          }, 50)
        }
      })

      this.map = map

      this.autoComplete = new window.AMap.Autocomplete({city: '全国'})

    },
    confirmHandler() {
      if (this.promise) {

        let address = this.address
        if (this.key && this.selectedIndex === 1) {
          address = this.key
        }
        this.resolve({
          address: address,
          lng: this.lng,
          lat: this.lat,
          addressComponent: this.addressComponent})
      }
      this.mapSelectDialog = false
    },
    cancelHander() {
      if (this.promise) {
        this.reject(null)
      }
      this.mapSelectDialog = false
    },
    async getAddress(options = null) {

      this.address = null
      this.addressComponent = {}
      this.lng = null
      this.lat = null
      this.mapSelectDialog = true
      this.marker = null


      setTimeout(() => {
        this.initMap()

        // 默认值
        if (options != null) {
          this.lng = options.lng
          this.lat = options.lat
          this.address = options.address
        }
      }, 50)
      const promise = new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
      this.promise = promise
      return promise
    },
    select() {
      this.$emit('select', this.address, this.lng, this.lat)
    },
    promise1() {
      return new Promise(resolve => {
        this.autoComplete.search(this.key, (status, result) => {
          // 搜索成功时，result即是对应的匹配数据
          if (result.info === 'OK') {
            resolve(result.tips)
          } else {
            resolve([])
          }
        })
      })
    },
    promise2() {
      return new Promise(resolve => {
        GetGeoCode(this.key, this.city).then(ret => {
          if (ret.data.status == '1') {
            let list = ret.data.geocodes.slice()
            list.forEach(k => {
              k.location = new window.AMap.LngLat(k.location.split(',')[0], k.location.split(',')[1])
              k.name = k.formatted_address
            })

            resolve(list)
          }
        }).catch(err => {
          console.error(err)
          resolve([])
        })
      })
    },
    keyChange() {
      if (!this.key) {
        this.list = []
        return
      }

      Promise.all([this.promise2(), this.promise1()]).then(lists => {
        let result = []
        lists.forEach(k => result = result.concat(k))
        console.log(result)
        this.list = result
      })
      // this.autoComplete.search(this.key, (status, result) => {
      //   // 搜索成功时，result即是对应的匹配数据
      //   console.log(result)
      //   if (result.info === 'OK') {
      //     this.list = result.tips
      //   } else {
      //     this.list = []
      //   }
      // })
    },
    setMarker(location) {
      if (this.marker) {
          this.marker.setPosition(location);
      } else {
        this.marker = new window.AMap.Marker({
          position: location,
          map: this.map
        })
      }
    },
    clickItem(item) {
      if (!item.location) {
        this.$message(this.$t('commonPanel.noPlaceTip'))
        return
      }

      this.map.setZoomAndCenter(15, item.location)
      this.addressComponent = { districtName: item.district }
      this.address = item.formatted_address || (item.district + item.name)
      this.lng = item.location.getLng()
      this.lat = item.location.getLat()
      this.visible = false
      this.setMarker(item.location)
    },
    handleBlur() {
      setTimeout(() => {
        this.visible = false
      }, 100)
    }
  }
}
</script>

<style lang="scss" scoped>
.map {
  margin: 0;
  height: calc(100% - 100px);
  width: 100%;
  border: 1px solid #ccc;
}
.fix-right {
  position: absolute;
  top: 60px;
  right: 60px;
  z-index: 1;
  box-shadow: 0 2px 6px 0 rgba(114, 124, 245, .5);
  background-color: #FFF;
  padding: 0 12px;
  padding-bottom: 1px;
  .el-input {
    width: 80px;
    margin-bottom: -1px;
    & ::v-deep input {
      padding: 0;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      text-align: center;
    }
  }
}
.fix-panel {
  position: absolute;
  top: 60px;
  left: 60px;
  z-index: 1;
  background-color: #fff;
  box-shadow: 0 2px 6px 0 rgba(114, 124, 245, .5);
  .el-input {
    min-width: 400px;
  }
}
.result-label {
  color: rgba(0, 0, 0, .56);
  font-size: 13px;
  line-height: 24px;
  display: block;
  padding: 0 12px;

  &:hover {
    background-color: #cae1ff;
    cursor: pointer;
  }
  span {
    padding-left: 4px;
    color: rgba(0, 0, 0, .26);
  }
}
.format_address {
  background-color: #FFF;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-top: 0;
  color: #333;
  font-size: 13px;
  box-shadow: 0 2px 6px 0 rgba(114, 124, 245, .5);
  line-height: 28px;
  .tip {
    color: #999;
    margin-bottom: 4px;
    font-size: 12px;
  }

  p {
    margin-bottom: 4px;
    cursor: pointer;
    transition: all .3s ease;

    &:hover span.line {
      background-color: #EFEFEF;
    }

    span.line {
      padding: 0 12px;
      background-color: #fafafa;
      display: block;
      border-radius: 4px;
      color: #999;

      &.active {
        border: 1px solid var(--color-p);
        color: #333;
        i {
          color: var(--color-p);
        }
      }
    }
    
    i {
      float: right;
      margin: 6px 0 0;
      color: rgba(0,0,0,.26);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
.dialog-footer {
  .el-button {
    min-width: 80px;
  }
}
</style>
