<template>
  <el-dialog custom-class="v-dialog v-dialog--md v-dialog--mdpadding v-dialog--footer" :visible.sync="visible" v-if="visibleReal" :title="$t('updateVip.adminVip')" @closed="closed">
    <el-form label-width="6em">
      <h2>{{$t('updateVip.orderTip1')}}：</h2>
      <el-form-item :label="$t('updateVip.orderName')">
        <img src="../../assets/icon/vip.png"/> {{$t('updateVip.updateVip')}}
      </el-form-item>
      <el-form-item :label="$t('updateVip.orderVehicle')">
        {{$t('updateVip.total')}} <span class="important">{{list.length}}</span> {{$t('updateVip.deviceUnit')}}{{$t('updateVip.device')}}
      </el-form-item>
      <el-form-item :label="$t('updateVip.orderMethod')">
         {{$t('updateVip.adminVip')}}
      </el-form-item>
      <el-form-item :label="$t('updateVip.orderAmount')">
        <el-radio v-model="mode" :label="1">{{$t('updateVip.byMonth')}}</el-radio>
        <el-radio v-model="mode" :label="2">{{$t('updateVip.byDate')}}</el-radio>
      </el-form-item>
      <el-form-item label="">
        <el-input-number v-if="mode===1" v-model="payMonth" controls-position="right" :min="1" :max="99"></el-input-number>
        <el-date-picker
          v-else
          v-model="endTime"
          type="date">
        </el-date-picker>
        <span v-if="mode===1" class="iml-0">{{$t('updateVip.monthUnit')}}</span>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="confirm" :loading="loading" style="min-width:80px;">{{$t('common.confirm')}}</el-button>
        <el-button @click="close" style="min-width:80px;">{{$t('common.cancel')}}</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import {AddMultiOrderAdmin} from '@/api/fee'
import miment from 'miment'
export default {
  data() {
    return {
      list: [],
      mode: 1,
      payMonth: 12,
      endTime: miment().add(1, 'YYYY'),
      visible: false,
      visibleReal: false,
      loading: false
    }
  },
  methods: {
    open(list) {
      this.visible = true
      this.visibleReal = true
      this.list = list.slice()
    },
    close() {
      this.visible = false
    },
    closed() {
      this.list.splice(0, this.list.length)
      this.visibleReal = false
    },
    confirm() {
      if (this.list.length === 0) {
        this.$alert(this.$t('updateVip.pleaseSelectVehicle'))
        return
      }
      this.confirmMessage().then(() => {
        this.loading = true
        AddMultiOrderAdmin(this.list, this.payMonth, this.endTime, this.mode).then(ret => {
          if (ret.data.errCode) {
            this.$alert(ret.data.errMsg)
            return
          }
          // 成功
          this.$emit('success')
          this.successMessage()
        }).finally(() => {
          this.loading = false
        })
      })
    },
    confirmMessage() {
      return this.$confirm(this.$t('updateVip.confirmUpdateMsg', {
        count: this.list.length,
        option: this.mode===1 ? this.$t('updateVip.byMonth') : this.$t('updateVip.byDate'),
        date: this.mode===1 ? this.payMonth : miment(this.endTime).format('YYYY/MM/DD') }), this.$t('common.warn'), {
          confirmButtonText: this.$t('common.confirm'),
          cancelButtonText: this.$t('common.cancel'),
          type: 'warning'
        })
    },
    successMessage() {
      this.$confirm(this.$t('updateVip.updateSuccess'), this.$t('wallet.tip'), {
        confirmButtonText: this.$t('updateVip.viewOrder'),
        cancelButtonText: this.$t('common.close'),
        type: 'success'
      }).then(() => {
        this.close()

        if (this.mode === 1) {
          // this.$router.push('/manage/expire2')
          this.$router.push('/vip/expire')
        } else {
          this.$emit('refresh')
        }

      }).catch(() => {
        this.close()
        this.$emit('refresh')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-form {
  max-width: 300px;
  margin: 0 auto;
}
h2 {
  margin-top: 24px;
  margin-bottom: 24px;
}
.el-form-item {
  margin-bottom: 8px;
}
</style>
