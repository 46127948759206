import request from '@/common/request'
import { commonParams } from '@/api/config'
import { getToken } from '@/common/auth'

export function Query(pageIndex, pageSize, sortKey, sortDirect, filters, HoldID, hasChild,startTime ,endTime) {
  const loginKey = getToken()
  return request({
    url: './query/QueryVehicleInstallMain',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      pageIndex,
      pageSize,
      sortKey,
      sortDirect,
      filters,
      HoldID,
      hasChild,
      startTime,
      endTime
    })
  })
}

export function QueryVehicleInstallDetail(OrderID) {
  const loginKey = getToken()
  return request({
    url: './query/QueryVehicleInstallDetail',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      OrderID
    })
  })
}

export function ModifyVehicleInstallDetailOrder(VehicleInstallOrderDetailID, reason, VehicleID = 0) {
  const loginKey = getToken()
  return request({
    url: './modify/ModifyVehicleInstallDetailOrder',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      VehicleInstallOrderDetailID,
      reason,
      VehicleID
    })
  })
}

export function GetVehicleListByVehicleIDs(VehicleIDs) {
  const loginKey = getToken()
  return request({
    url: './query/GetVehicleListByVehicleIDs',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      VehicleIDs
    })
  })
}

export function AddVehicleInstallOrder(newItem, detail) {
  const loginKey = getToken()
  return request({
    url: './add/AddVehicleInstallOrder',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      newItem,
      detail
    })
  })
}

export function Delete(IDs) {
  const loginKey = getToken()
  return request({
    url: './delete/DeleteVehicleInstallOrder',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      IDs
    })
  })
}

export function Modify({InstallPeopleID, HoldID, PeopleName='', UserName='', PassWord='', Mobile='', QQ='', Wechat='', Remark=''}) {
  const loginKey = getToken()
  return request({
    url: './modify/ModifySkillMan',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      InstallPeopleID, HoldID, PeopleName, UserName, PassWord, Mobile, QQ, Wechat, Remark
    })
  })
}

export function Fetch(KeyID) {
  const loginKey = getToken()
  return request({
    url: './query/QuerySkillManInfo',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      KeyID
    })
  })
}

export function ModifyVehicleInstallOrderStart(VehicleInstallOrderID) {
  const loginKey = getToken()
  return request({
    url: './modify/ModifyVehicleInstallOrderStart',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      VehicleInstallOrderID
    })
  })
}

export function ModifyVehicleInstallOrderEnd(VehicleInstallOrderID) {
  const loginKey = getToken()
  return request({
    url: './modify/ModifyVehicleInstallOrderEnd',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      VehicleInstallOrderID
    })
  })
}

export function Export(HoldID, hasChild, filters, Ids ,startTime ,endTime) {
  const loginKey = getToken()
  return request({
    url: './query/ExportVehicleInstallMain',
    method: 'post',
    responseType: 'blob',
    data: Object.assign({}, commonParams, {
      loginKey,
      HoldID,
      hasChild,
      filters,
      Ids,
      startTime,
      endTime
    })
  })
}

export function ModifyVehicleInstall(newItem, detail) {
  const loginKey = getToken()
  return request({
    url: './modify/ModifyVehicleInstall',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      newItem,
      detail
    })
  })
}

export function QueryTaskDetail(vehicleInstallId) {
  const loginKey = getToken()
  return request({
    url: './query/QueryTaskDetail',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      vehicleInstallId
    })
  })
}

export function ModifyVehicleInstallDetailVehicleID(VehicleInstallOrderDetailID, VehicleID) {
  const loginKey = getToken()
  return request({
    url: './modify/ModifyVehicleInstallDetailVehicleID',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      VehicleInstallOrderDetailID,
      VehicleID
    })
  })
}

export function ModifyVehicleInstallOrderApproval(VehicleInstallOrderID) {
  const loginKey = getToken()
  return request({
    url: './modify/ModifyVehicleInstallOrderApproval',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      VehicleInstallOrderID
    })
  })
}

export function ModifyVehicleInstallOrderNotApproval(VehicleInstallOrderID, reason) {
  const loginKey = getToken()
  return request({
    url: './modify/ModifyVehicleInstallOrderNotApproval',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      VehicleInstallOrderID,
      reason
    })
  })
}

export function QueryTaskLog(VehicleInstallOrderID) {
  const loginKey = getToken()
  return request({
    url: './query/QueryTaskLog',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      VehicleInstallOrderID
    })
  })
}

export function GetTransNo() {
  const loginKey = getToken()
  return request({
    url: './query/GetTransNo',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey
    })
  })
}

export function DownLoadImportWonoDetail(fileName) {
  const loginKey = getToken()
  return request({
    url: './add/DownLoadImportWonoDetail',
    method: 'post',
    responseType: 'blob',
    timeout: 60000,
    data: Object.assign({}, commonParams, {
      loginKey,
      fileName
    })
  })
}

export function Import(fileName, holdId) {
  const loginKey = getToken()
  return request({
    url: './add/AddInstallOrderBatch',
    method: 'post',
    timeout: 60000,
    data: Object.assign({}, commonParams, {
      loginKey,
      fileName,
      holdId
    })
  })
}

export function QueryTaskCheck(VehicleInstallOrderID) {
  const loginKey = getToken()
  return request({
    url: './query/QueryTaskCheck',
    method: 'post',
    timeout: 60000,
    data: Object.assign({}, commonParams, {
      loginKey,
      VehicleInstallOrderID
    })
  })
}

export function AddVehicleInstallOrderCheck(vin) {
  const loginKey = getToken()
  return request({
    url: './add/AddVehicleInstallOrderCheck',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      vin
    })
  })
}