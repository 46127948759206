import axios from 'axios'
import { BASE_URL } from '@/api/config'
import { removeToken, setTokenTime } from '@/common/auth'
import miment from 'miment'
import i18n from '../lang'

const service = axios.create({
  baseURL: BASE_URL,
  timeout: 300000
})

service.interceptors.response.use(
  response => {
    setTokenTime(miment().format('YYYY-MM-DD hh:mm:ss'))
    const res = response.data
    if (res.d) {
      const ret = JSON.parse(res.d)
      if (ret.errCode === 40001) {
        // tokenå¤±æ•ˆ
        removeToken()
        location.reload()
      }
      if (ret.errCode === -1) {
        // tokenå¤±æ•ˆ
        removeToken()
        //location.reload()
      }
      // set response.data = JSON.parse(response.data.d)
      response.data = ret
    }
    if (res.errCode === 40001) {
      // tokenå¤±æ•ˆ
      removeToken()
      location.reload()
    }
    if (res.errCode === -1) {
      // tokenå¤±æ•ˆ
      removeToken()
      location.reload()
    }
    return response
  },
  error => {
    if (!error.response) {
      error.message = i18n.t('common.netError')
    } else if (error.response.status === 500) {
      error.message = i18n.t('common.serverError')
    }

    // eslint-disable-next-line
    console.log('axios', error)
    return Promise.reject(error)
  }
)

const errHandle = data => {
  if (data.errCode) {
    console.error(data.errMsg, data) // eslint-disable-line
    return false
  }
  return true
}

export {errHandle}

export default service
