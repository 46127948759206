export default {
  system: 'GPS Track System',
  route: {
    home: 'Home',
    gpsMenu: 'Monitor',
    riskMenu: 'Risk Manage',
    infoMenu: 'Info Manage',
    vipMenu: 'VIP',
    reportMenu: 'Reports',
    notifyMenu: 'Notice',
    gpsOnlineMonitor: 'Vehicle Monitor',
    cctv: 'DVR',
    gpsHistoryPlay: 'History Trace',
    gpsRegion: 'Geo-fence',
    gpsRoad: 'Road',
    gpsPhotoView: 'Photo view',
    gpsMessage: 'Send Message',
    riskQuery: 'Credit Evaluate',
    riskAssets: 'Assets analyze',
    riskPlace: 'Frequent stop place',
    riskEvent: 'Risk event',
    riskEvaluate: 'Risk evaluate',
    riskPrevent: 'Risk warning',
    riskShcm: 'Risk place',
    riskPeople: 'Grey list ',
    infoHold: 'Enterprise',
    infoVehicle: 'Device',
    infoDevice: 'Device',
    infoUser: 'User',
    infoPeople: 'Installer',
    infoTask: 'Task',
    taskSystem: 'Task System',
    vipWallet: 'My Wallet',
    vipWalletDetail: 'Orders',
    vipUpdate: 'Upgrade VIP',
    vipUpdateHistory: 'Order',
    walletInvoice: 'Invoice manage',
    userCenter: 'User center',
    userLog: 'User Log',
    language: 'Language',
    exit: 'Exit',
    setting: 'Setting',
    spread: 'Spread',
    gpsMenufk: 'Vehicle Monitor',
    spreadfk: 'Spread',
    taskSystemfk: 'Task',
    infoPeoplefk: 'Installer',
    reportMenufk: 'Reports',
    provinceManage: 'Province',
    visitorlog: 'Vistor Log',
    manageInsurance: 'Insurance',
    systemInfo: 'System Info',
    gpsTrack: "Live Track"
  },
  common: {
    back: 'Back',
    save: 'Save',
    close: 'Close',
    active: 'Activate',
    cancel: 'Cancel',
    success: 'Success',
    errorTip: 'Sorry,please try again',
    removeChecked: 'Remove checked',
    removeAll: 'Remove All',
    next: 'Next',
    selectDate: 'Please choice date',
    inputText: 'Please input text',
    noResults: 'No data',
    noMoreResults: 'No more data',
    operate: 'Operate',
    move: 'Move',
    delete: 'Delete',
    edit: 'Edit',
    modify: 'Modify',
    emptySelectedTip: 'Please select target data',
    deleteTip: 'Are you sure to delete this data?',
    warn: 'Warning',
    confirm: 'Confirm',
    deleteSuccessed: 'Delete Successed',
    loading: 'Loading',
    bind: 'Bind',
    refresh: 'Refresh',
    ok: 'OK',
    migrate: 'Migrate',
    assTaskCar: 'Follow',
    timeout: 'Request timeout',
    query: 'Query',
    queryAndBind: 'Bind vehicle',
    funDisabled: 'This function is in maintaining, please waiting.',
    netError: 'Network error',
    serverError: 'Internal server error',
    inputKey: 'Please input key',
    queryItem: 'Query',
    joinAll: 'Join all',
    clears: 'Clear mark',
    addMarkers: 'Add points of interest',
    joinRisk: 'Add risk control points'
  },
  other: {
    sendSuccess: 'Send command success',
    cancelAlarm: 'Cancel alarm',
    cancelAlarlText: 'Please enter the cancel alarm reason or not.',
    selectAddress: 'Select address'
  },
  contextMenu: {
    refresh: 'Refresh',
    close: 'Close',
    closeOtherTabs: 'Close Other Tabs',
    closeAll: 'Close All Tabs',
    history: 'History',
    newPage: 'Open in a new page'
  },
  layout: {
    noMessage: 'No new message',
    dontShow: 'Don\'t show again',
    notify: 'Notify',
    exit: 'Logout',
    favourite: 'Favorites',
    changeLanguage: 'Change language',
    queryBykey: 'Vehicle/Device/Enterprise',
    changeLayout: 'Layout',
    contact: 'Contact',
    topMenu: 'Top menu',
    rightMenu: 'Left menu',
    '功能': 'Menu',
    '用户': 'Enterprise',
    '车辆': 'Vehicle'
  },
  login: {
    ipAndPort: '9881',
    ip: 'Server',
    port: 'Port',
    submitButton: 'Login',
    userNamePlaceHolder: 'Please input Login Name',
    passWordPlaceHolder: 'Please input Password',
    autoLogin: 'Auto Login',
    autoLoginTitle: 'Please don\'t tick in public occasion',
    noUsernameTip: 'Login Failed,Please input login name',
    passwordErrorTip: 'Login Failed,Please input password',
    pleaseInputMobile: 'Please input your mobile',
    pleaseInputCode: 'Please input verification code',
    errorMobile: 'Error mobile',
    loginBySMS: 'Login By Mobile',
    loginByPassword: 'Login By Password',
    loginCheck: 'Please check the agreement',
    ProtocolTips: 'Agree to and comply with the',
    ProtocolName: 'user use agreement',
    ProtocolName2: 'user privacy policy',
    ourService: 'Our Service',
    wechat: 'Wechat',
    forgetPassword: 'Forgot password？',
    findPassword: 'Find',
    register: 'No Account?',
    registerAccount: 'Register',
    scanQR: 'Scan QR Code',
    followUS: 'Follow US',
    downloadAndroidAPP: 'Download Android APP',
    downloadIOSAPP: 'Download IOS APP',
    anonymous: 'Anonymous',
    allService: [{
        title: 'Easy Manage Vehicles',
        comments: ['Self Manage', 'Easy postion', 'Trace replay', 'Rich reports']
      },
      {
        title: 'Professional',
        comments: ['Pre-evaluation', 'Risk Montor', 'Find car']
      },
      {
        title: 'SIM Manage',
        comments: ['Balance query', 'Self recharge', 'Send message']
      },
      {
        title: 'Customization',
        comments: ['Protocol interface', 'Open interface', 'Personalized customize']
      }
    ],
    notFoundID: 'This device id not found.',
    reset: 'Reset'
  },
  forget: {
    findPassword: 'Forgot password？',
    register: 'Register',
    login: 'Login',
    pleaseInputYourMobile: 'Please input your phone number',
    mobile: 'Phone',
    valid: 'Verify',
    pleaseDrag: 'Drag slider to the end',
    validSuccess: 'Verify success',
    findError: 'Sorry, no matching account was found, please contact the administrator!',
    findSuccess: 'Password has been retrieved and sent to your mobile phone or email, please pay attention to check!',
    findSuccessSms: 'We have sent a verification code to your mobile phone.',
    findSuccessEmail: 'We have sent a verification code to your email',
    resetPassword: 'Reset password',
    multiTip: 'This email has bind by multiple enterprises, please choose one.',
    email: 'Email',
    pleaseInputEmail: 'Please input your email address',
    errorFormatEmail: 'Error email address',
    useEmail: 'Use email',
    useMobile: 'Use mobile'
  },
  register: {
    registerUser: 'Register',
    pleaseLogin: 'Login',
    tip: '',
    registerType1: 'Device Account',
    registerType2: 'Enterprise Account',
    mobile: 'Mobile',
    pleaseInputMobile: 'Please input your phone number',
    valid: 'Verify',
    nextStep: 'Next',
    mobileFormatError: 'Error phone number',
    pleaseDrag: 'Drag slider to the end',
    validSuccess: 'Verify success',
    step1: 'Phone Number',
    step2: 'Info',
    step3: 'device',
    step4: 'Success',
    step5: 'Email–Login Name',
    errorPhone: 'Error phone number',
    validTitle: 'Valid phone number',
    validTitleEmail: 'Validate your email address',
    validTip: 'Verification code has been sent to your mobile phone',
    validTipEmail: 'Verification code has been sent to your email',
    validTip2: 'Verification code has been sent to your mobile phone',
    validTip2Email: 'Verification code has been sent to your email',
    sendButton: 'Send',
    validCode: 'Verification Code',
    errorValidCode: 'Invalid verification code',
    tryAgain: 'Try again',
    name: 'Name',
    password: 'Password',
    passwordAgain: 'Confirm',
    vehicleName: 'Vehicle Plate',
    assetName: 'Asset Name/label',
    asset: 'Asset',
    sim: 'SIM',
    sim2: 'Device ID',
    typeID: 'Registration Type',
    vehicle: 'vehicle',
    person: 'Person',
    article: 'Asset',
    registerSuccess: 'Success. Go to login page after {time} seconds',
    companyName: 'Account Name',
    contacter: 'Contact Name',
    address: 'Address',
    inputNewPassword: 'Input New Password',
    pleaseInputName: 'Please input name',
    pleaseInputPassword: 'Please input password',
    pleaseInputPasswordAgain: 'Please input password again',
    pleaseInputVehiclePlate: 'Please input vehicle plate',
    pleaseInputassetName: 'Please input asset name/label',
    pleaseInputSim: 'Please input sim card number',
    pleaseInputSim2: 'Please input device id',
    pleaseInputEnterprise: 'Please input account Name',
    pleaseInputContacter: 'Please input contact Name',
    pleaseInputAddress: 'Please input address',
    pleaseInputSixNumber: 'Please enter 6 to 18 digits password',
    pleaseInputSixNumber2: 'Please enter 6 to 18 letters or numbers',
    errorFormat: 'Input format error',
    checkPassError: 'New and confirmed passwords are not the same',
    pleaseLoginTip1: 'Please use vehicle plate as login name to login',
    pleaseLoginTip2: 'Please use phone number as login name to login',
    pleaseLoginTip3: 'Please use email address as login name to login',
    pleaseLoginTip4: 'Please use asset name/label as login name to login',
    customerAccount: 'Customer Account'
  },
  batchImport: {
    title: 'Import',
    tip1: 'Drag file to here. Or',
    tip2: ' Click to upload',
    tip3: ' Click to Download',
    tip4: 'Template',
    fileName1: 'VehicleTemplate.xlsx',
    fileName2: 'DeviceTemplate.xlsx',
    fileName3: '工单导入模板.xlsx',
    fileName4: '批量更新SIM卡号模板.xlsx',
    fileName5: '保单导入模板.xlsx',
    fileName6: 'DevicePersonTemplate.xlsx',
    fileName7: 'DeviceArticleTemplate.xlsx',
    fileName8: '批量更新车牌模板.xlsx',
    download: 'Download',
    close: 'Close',
    downloadDetail: 'Download detail',
    closePage: 'Close window',
    importdetail: 'ImportDetail',
    importProcess: 'Processing...',
    errTip: 'Upload error',
    target: 'Target enterprise',
    nowLoading: 'Now loading...',
    nowProcess: 'Process...',
    nowEnd: 'Imported'
  },
  home: {
    '低电压报警': 'Low voltage alarm',
    '低电量报警': 'Low battery level alarm',
    '超速报警': '超速报警',
    '禁行时间段报警': '禁行时间段报警',
    '滞留车辆报警': '滞留车辆报警',
    '拆机报警': 'Removal alarm',
    '剪线报警': 'Power off alarm',
    '区域报警': 'Geo-fence alarm',
    '双离线报警': 'All offline',
    '设备分离报警': 'Split alarm',
    '出省报警': 'Out of province',
    '二押点报警': 'High risk place',
    '断电报警': 'Power failure alarm',
    '区域报警': 'Entrance Alarm',
    '围栏报警': 'Exit Alarm',
    '紧急报警': 'Emergency alarm',
    '偏离路线报警': 'Departure route alarm',
    '跌落报警': 'Fall alarm',
    '碰撞报警': 'Collision alarm',
    simState: 'SIM State',
    key1: 'Expired SIMs',
    key2: 'Tracker online',
    key3: 'Asset tracker offline',
    key4: 'New device added in 90 days',
    chart1: {
      '正常': 'Normal',
      '即将到期': 'Expire Soon',
      '已到期': 'Expired',
      '自购卡': 'Self purchase SIM'
    },
    chart2: {
      '在线': 'Online',
      '离线1天内': 'Offline 1 day',
      '离线1-7天': 'Offline>1day',
      '离线7-30天': 'Offline>7days',
      '离线30天以上': 'Offline 30days+',
      '未上线': 'Unused',
      '不在线': 'Offline',
      '离线': 'Offline',
      '正常': 'Online'
    },
    chart3: {
      '未上线': 'Offline',
      '1天内': '1 day',
      '1-7天': '1- 7 days',
      '7-30天': '7- 30 days',
      '30天以上': '30 days above',
      '在线': 'Online',
      '不在线': 'Offline',
      '离线': 'Offline',
      '正常': 'Online'
    },
    chart4: {

    },
    chartOnline: {
      s1: 'Online',
      s2: 'Offline',
      total: 'Devices'
    },
    msgTips: 'Device ID number has been copied',
    unit1: ' Piece',
    unit2: ' unit2',
    unit3: ' Set',
    unit4: ' year',
    recentNew: 'Recently',
    recentActive: 'Recently Active',
    count: 'Count',
    incomeExpired: 'About to expire',
    nodate: 'No date',
    offlineRate: 'Offline rate',
    gpsOnlineRate: 'Online Rate',
    wireGpsOnlineRate: 'Wireless Online Rate',
    remainDay: 'Remain {number} days',
    titleOnline: 'Online statistics',
    titleDevice: 'Devices location',
    titleNet: 'Connected Devices',
    titleTotal: 'Devices Online',
    titleNew: 'Recently devices',
    titleYear: 'Added in recent 3 years',
    titleAlarm: 'Alarm statistics',
    titleEven: 'Alarm event',
    noDatas: "No news",
    copy: 'copy',
    deviceStatus1: "Vehicle",
    deviceStatus2: "Person",
    deviceStatus3: "Asset",
  },
  commonTreeUser: {
    search: 'Search',
    searchUser: 'Search user',
    searchUserOrDevice: 'Search user or device',
    back: 'Back',
    backParent: 'Back to Superior',
    backRoot: 'Back to Root ',
    includeChild: 'Include Subordinate ',
    userList: 'User List',
    checkContainTip: 'Check not include Subordinate',
    checkNotContainTip: 'Check include Subordinate',
    exchange: 'Exchange mode',
    exchangeCountVisible: 'Show or hide vehicle count',
    pleaseInputKey: 'Please input keywords',
    keyTip: 'Vehicle Plate/Device ID/VIN/WONO/PeopleName',
    loading: 'loading...',
    searchPlace: 'Search User'
  },
  commonListVehicle: {
    search: 'Search',
    searchVehicle: 'Enter license plate/name/device ID',
    vehicleList: 'Vehicle List',
    serarchDev: 'Enter device ID/name',
    modify: 'Modify',
    command: 'Command',
    rcvTime: 'Receive Time',
    noDevice: 'No Bind Device',
    noVehicle: 'No Vehicle Info',
    all: 'All',
    online: 'Online',
    offline: 'Offline',
    onAlarm: 'Alarm',
    unused: 'UnUsed',
    star: 'Follow',
    typeAll: 'All',
    typeVehicle: 'Vehicle',
    typePerson: 'Person',
    typeAirtcle: 'Asset',
    allTip: 'Show all vehicles.',
    onlineTip: 'Show online vehicles .',
    offlineTip: 'Show offline vehicles .',
    onAlarmTip: 'show vehicles with alarms .',
    unusedTip: 'Show unused vehicles.',
    starTip: 'Show follow vehicles.',
    expiredDevice: 'Sorry. This device vip has been expired.',
    simTip: '*This device has no device id, this is sim card number.',
    data: 'Modify',
    enterprise: 'Enterprise',
    sms: 'SMS',
    vehicleInfo: 'Vehicle info',
    deviceInfo: 'Device info',
    totalRecord: 'Total is {count}',
    deviceList: 'Device List',
    userList: 'user list',
    followStatus: "Followed",
    followStatus2: "unsubscribe",
  },
  commonPanel: {
    place: 'Location',
    clickViewLocation: 'Where am I?',
    showLngLat: 'Show longitude and latitude.',
    showLocation: 'Show location.',
    noPlaceTip: 'No location.',
    noPlace: 'No location',
    battery: 'Battery',
    endurance: 'Endurance',
    day: 'Days',
    wake: 'Wake',
    lbsLocation: 'LBS',
    wifiLocation: 'WIFI',
    gpsLocation: 'GPS',
    lastLocation: 'GPS Time',
    satelLocation: 'GPS',
    wakeTime: 'Upload',
    temperature: 'Temperature',
    allMiles: 'total mileage'
  },
  userManage: {
    userName: 'Company Name',
    account: 'Account',
    contacer: 'Contact',
    phone: 'Phone',
    createTime: 'Create Time',
    remark: 'Remark',
    fullPath: 'Path',
    addHold: 'New User',
    deleteChecked: 'Delete Selected',
    changeParent: 'Migrate',
    export: 'Export',
    includeChild: 'Include Subordinate',
    search: 'Search'
  },
  commonManage: {
    search: 'Search',
    selectType: 'Please Select',
    inputPlaceHolder: 'Please input search text'
  },
  vehicleManage: {
    vehicleName: 'Vehicle Plate',
    vehiclePeople: 'Owner',
    vehicleBrand: 'Brand',
    vehicleType: 'Vehicle Type',
    deviceCount: 'Device Count',
    createTime: 'Create Time',
    remark: 'Remark',
    add: 'New Vehicle',
    delete: 'Delete Selected',
    change: 'Migrate',
    export: 'Export',
    upload: 'Import',
    includeChild: 'Include Subordinate',
    removeSuccess: 'Remove successed',
    addDevice: 'Add device',
    searchBind: 'Search & bind',
    enterprise: 'Enterprise',
    storeName: 'Store Name',
    wono: 'WONO',
    vin: 'VIN',
    installTime: 'Install Time',
    nodevice: 'no device'
  },
  deviceManage: {
    deviceID: 'Device ID',
    vehicleName: 'Vehicle Plate',
    deviceName: 'Device name',
    articleName: 'Item name',
    articleId: 'Item ID',
    articleColor: 'color',
    articleWeight: 'weight',
    articleLong: 'long',
    articlesize: 'size',
    articleWidth: 'width',
    articleHigh: 'high',
    personName: 'Personnel name',
    personId: 'Person ID',
    personAge: 'age',
    personIDCard: 'IDCard',
    personPhone: 'phone',
    personSex: 'gender',
    pesonGender1: 'male',
    pesonGender2: 'Female',
    personType: 'Personnel category',
    vip: 'VIP EXP.',
    status: 'Status',
    online: 'Online',
    offline: 'Offline',
    acc: ' Status',
    alarm: 'Alarm',
    rcvTime: 'Receive Time',
    gpsTime: 'GPS Time',
    offlineTime: 'Offline Time',
    sim: 'SIM NO.',
    place: 'Location',
    remark: 'Remark',
    createTime: 'Create Time',
    deviceType: 'Device Type',
    mdtType: 'Protocol',
    add: 'New Device',
    active: 'Activate device',
    delete: 'Delete Selected',
    export: 'Export',
    changeParent: 'Batch Migrate',
    upload: 'Batch Import',
    uploadsim: 'Batch Update SIM Card Number',
    includeChild: 'Include Subordinate',
    optionKey1: 'All',
    optionKey2: 'Online',
    optionKey3: 'Offline',
    optionKey4: 'Move',
    optionKey5: 'Ignition off',
    optionKey6: 'Alarm',
    optionKey7: 'Normal',
    optionKey8: 'SIM Card Normal Status',
    optionKey9: 'SIM card to be expired',
    optionKey10: 'SIM card expired',
    optionKey11: 'SIM card no enough balance',
    optionKey12: 'VIP Expired',
    optionKey13: 'VIP expire in 7 days',
    optionKey14: 'VIP expire in 30 days',
    optionKey15: 'VIP expire in 60 days',
    statusKey1: "All",
    statusKey2: "inactivated",
    statusKey3: "normal",
    statusKey4: "Downtime",
    statusKey5: "Offline",
    displayMore: 'Display More',
    optionKey16: "Static",
    optionKey17: "Weak",
    wrap: 'Wrap',
    updateVip: 'Upgrade VIP',
    moverDevices: 'Migrate Devices',
    activateDevice: 'Activate device',
    keyOnline: 'Online',
    keyOffline: 'Offline',
    keyOnRoad: 'Move',
    keyDaisu: 'Idle',
    keyRef: 'Reference Location',
    keyNoSpeed: 'Acc Off',
    keyNoSpeedRef: 'Acc Off, Reference Location',
    keyAlarm: 'Alarm',
    keyNormarl: 'Normal',
    holdName: 'Enterprise',
    remainDays: 'Remain days',
    days: 'Days',
    normalSearch: 'Normal search',
    multipleSearch: 'Batch search',
    textareaTip: 'Please input device ID，multiple device IDs are separated by commas，up to 1000 inputs per time.',
    enterTip: 'You has input {count} device IDs.',
    clear: 'Clear',
    simInfo: 'SIM Information',
    deviceMode: 'Device View',
    vehicleMode: 'Vehicle View',
    Index: 'Index',
    SIM2: 'Device ID',
    SIM: 'SIM',
    MDTModel: 'Device model',
    TrackerTypeName: 'Tracker type',
    Remark: 'Remark',
    errDesc: 'Description',
    errMsg: 'Result',
    clickViewDetail: 'View device detail',
    clickViewSIM: 'View SIM detail',
    trace: 'Trace report',
    findDevice: 'Find {count} devices',
    inputAgain: 'Input again',
    peopleName: 'People Name',
    wono: 'WONO',
    storeName: 'Store Name',
    installTime: 'Install Time',
    vin: 'VIN',
    effectiveWay: 'Option',
    effectiveNow: 'Now',
    effectiveOnActive: 'OnActive',
    effectiveDelay: '3 Months Later',
    oneYear: '1 Year',
    twoYear: '2 Years',
    threeYear: '3 Years',
    fourYears: '4 Years',
    fiveYears: '5 Years'
  },
  accountManage: {
    account: 'Login Name',
    userName: 'Enterprise Name',
    userType: 'User Type',
    startTime: 'Start Time',
    endTime: 'End Time',
    remark: 'Remark',
    fullPath: 'Path',
    add: 'New User',
    delete: 'Delete Selected',
    export: 'Export',
    includeChild: 'Include Subordinate'
  },
  peopleManage: {
    userName: 'Enterprise Name',
    account: 'Login Name',
    phone: 'Phone',
    createTime: 'Create Time',
    fullPath: 'Path',
    remark: 'Remark',
    add: 'New People',
    delete: 'Delete Selected',
    export: 'Export',
    includeChild: 'Include Subordinate',
    enterprise: 'Enterprise',
    userType: 'User Type'
  },
  taskManage: {
    orderNo: 'Order No.',
    installPeople: 'Installer',
    startTime: 'Start Time',
    endTime: 'End Time',
    total: 'Total',
    taskState: 'Task',
    remark: 'Remark',
    add: 'New Task',
    delete: 'Delete Selected',
    export: 'Export',
    createTime: 'Create Time',
    agentName: 'Agent Name',
    expectTime: 'Expect Time',
    projectName: 'Project Name',
    customerName: 'Customer Name',
    vin: 'VIN',
    checkTime: 'Check Time',
    orderStatus: {
      '0': 'Created',
      '1': 'Waiting confirm',
      '2': 'Working',
      '3': 'Waiting approval',
      '9': 'Completed'
    }
  },
  wallet: {
    time: 'Datetime',
    name: 'Service ',
    vpoint: 'VPoint',
    remain: 'Remain',
    balance: 'Balance',
    accountBalance: 'Account balance',
    orderNo: 'Order NO',
    remarkOrder: 'Order NO./Remark',
    remark: 'Remark',
    createUser: 'Create User',
    user: 'User/Account',
    account: 'Account',
    openWallet: 'Open Wallet',
    export: 'Export',
    includeChild: 'Include Subordinate',
    orderDetail: 'Order Detail',
    recharge: "Renewal order",
    readDetail: 'View the renewal record',
    recgargeTitle: 'Recent transaction record',
    vehicleName: 'Vehicle Plate',
    holdName: 'Enterprise Name',
    deviceID: 'Device ID',
    amount: 'Amount',
    vipEndDate: 'VIP Expire Date',
    wallet: 'Wallet',
    sendVPoint: 'VPoint gift',
    gift: 'Gift',
    pay: 'Amount',
    payPrefix: '',
    payMethod: 'Pay Method',
    alipay: 'Alipay',
    wechat: 'Wechat',
    alipayTip: 'Hundreds of millions of users are using it, and security can be entrusted.',
    wechatTip: 'The choice of millions of users, faster and safer',
    payButton: 'Pay',
    custom: 'Custom',
    payTotal: 'Pay Total',
    payUnit: 'CNY',
    backMine: 'Back',
    successTip: 'Successd, now you have {total} VPoint',
    bottomButton: 'Thank you',
    targetUser: 'Target User',
    targetCount: 'Target Count',
    sendSuccessed: 'Send success',
    limitRemain: 'Your vpoint is less than {count}.',
    confirmSend: 'Are you sure send {count} vpoints to {target}, you will remain {remain} vpoint after send?',
    tip: 'Tip',
    giftPrefix: '',
    selectUser: 'Please Select User',
    buy: '',
    year: 'Year',
    recentOrders: 'Recent orders',
    viewAll: 'View all',
    addVpoint: 'Add vpoint',
    addVpointDesc: 'Use vpoint to upgrade VIP',
    orderDetailDesc: 'View wallet detail orders',
    invoiceManage: 'Bill manage',
    invoiceManageDesc: 'View or request bills',
    pleaseInputRemark: 'Please input remark.',
    administrator: 'Administrator',
    currentUser: 'Current User',
    confirmSend2: 'Are you sure send {count} vpoints to {target}？'
  },
  updateVip: {
    orderNo: 'Order No.',
    holdName: 'Department',
    orderCount: 'Renewal Quantity',
    orderPoint: 'VPoint',
    orderStatus: 'Order Status',
    orderUser: 'Payer',
    createTime: 'Create Time',
    updateTime: 'Update Time',
    includeChild: 'Include Subordinate',
    add: 'Upgrade VIP',
    delete: 'Delete Selected',
    view: 'View',
    orderDetail: 'Order Detail',
    vehicleName: 'Vehicle Plate',
    deviceID: 'Device ID',
    amount: 'Upgrade Time',
    vipEndDate: 'VIP Expire Date',
    sim: 'SIM Card',
    trackerType: 'Wire/Wireless',
    online: ' Online or not',
    wallet: 'My Wallet',
    sendVPoint: 'VPoint gift',
    updateVip: 'Upgradete VIP',
    adminVip: 'Upgrade VIP(admin)',
    selectVehicle: 'Select Vehicle',
    remain: 'Balance',
    addPoint: 'New VPoint',
    vpoint: 'VPoint',
    payAmount: 'Upgrade time',
    year: 'Year',
    month: 'Month',
    payMethod: 'Pay Method',
    wechat: 'Wechat Pay',
    alipay: 'AliPay',
    payFee: 'Fee',
    payUnit: 'CNY',
    selected: 'Selected',
    deviceUnit: '',
    device: 'Device',
    orderTip1: 'Please verify your order',
    orderName: 'Service name',
    orderAmount: 'Upgrade time',
    orderVehicle: 'Vehicles',
    orderMethod: 'Pay',
    payVehicle: 'Vehicles',
    payTime: 'Upgrade time',
    orderTip2: 'Please use wechat scan the qr code and complete the payment',
    nextButtonText: 'Continue payment',
    backBUttonText: 'Reselect',
    updateSuccess: 'Upgrade Success',
    viewOrder: 'View Order',
    selectedList: 'Selected List',
    total: 'Total',
    feeTip: 'GPS Tracker {price1}RMB/Year, Assets Tracker {price2}RMB/Year',
    pleaseSelectVehicle: 'Please select target vehicle first',
    expired: 'Expired',
    vipTip1: 'VIP vehicle keeps more than 90 days of history data.',
    vipTip2: 'Free vehicle saves 7 days of history data.',
    dateTo: 'VIPdate to',
    vipFeatures: 'VIP Features',
    vipFeature90: 'Keep track for 90 days',
    vipFeaturePhoto: 'Take photo',
    vipFeatureGeo: 'Geo fence',
    vipFeatrueOil: 'Oil report',
    vipFeaturePlace: 'Find a frequent stop',
    vipFeatureMore: 'More',
    oneCLickPay: 'Pay all',
    myOrder: 'Orders',
    byMonth: 'By month',
    byDate: 'Fixed date',
    confirmUpdateMsg: 'Are you sure upgrade vip for {count} vehicles? upgrade plan:{option}，time:{date}',
    monthUnit: 'Months',
    canYouConfirmAll: 'Can you want to select all vehicles in the query result?'
  },
  userLog: {
    operateTime: 'Operate Time',
    user: 'User/Account',
    userName: 'UserName',
    account: 'Account',
    operateType: 'Operate Type',
    description: 'Description',
    remark: 'Remark',
    range: 'Query time',
    '新增': 'New',
    '增加': 'New',
    '修改': 'Modify',
    '删除': 'Delete',
    '登录': 'Login',
    '迁移': 'Migrate',
    '手机客户端': 'Mobile end',
    'order1': 'Send clear alarm command successfully',
    '修改安装照片同时更新APP工单照片地址': 'Modify Vehicle Photo',
    '下发终端指令': 'Issue terminal commands',
    '系统': 'system',
  },
  userCenter: {
    title: 'User Info',
    myProvider: 'My Service Provider',
    provider: 'Provider',
    contacter: 'Contact',
    phone: 'Phone',
    address: 'Address',
    email: 'Email',
    changePassword: 'Change Password',
    changePasswordTip: 'In order to protect the safety of your account, please change your password timely.',
    change: 'Change',
    customlize: 'Self-defined Setting',
    customlizeTip1: 'Please input 1-30 characters or digits,English title ',
    customlizeTip2: 'Please upload 40px*40px pixel PNG photo!',
    upload: 'Upload',
    changeTitle: 'Change Title',
    newTitle: 'New Title',
    changeLogo: 'Change Logo',
    newLogo: 'New Logo',
    changeLogoTip: 'Drag files here or ',
    clickUpload: 'Click to upload',
    emptyWarning: 'Please input title',
    changeAllLogo: 'Sub group\'s title and logo setting',
    changeAllLogoTip: 'Please select the item to be set',
    changeAllLogoOption1: 'Title',
    changeAllLogoOption2: 'Logo',
    changeAllLogoConfirm: 'If you confirm setting your all sub group, please input "confirm" in the fllowing textbox, and click the save button.',
    changeAllLogoConfirmText: 'confirm',
    changeMobile: 'Change Mobile',
    newMobile: 'New mobile',
    emailTips: "Email notifications will be sent to the email address on account",
    wordChange: 'Change',
    wordPassword: "Password",
    wordEmail: "Email",
    wordNotic: "Notifications"
  },
  changePassword: {
    key1: 'Change password',
    key2: 'Old password',
    key3: 'New password',
    key4: 'Confirm password',
    tip1: 'Please input old password',
    tip2: 'Please input new password',
    tip3: 'Please input new password again',
    tip4: 'Inconsistent Password'
  },
  holdDetail: {
    name: 'Enterprise',
    basicInfo: 'Basic Info',
    authInfo: 'Permission',
    parentHoldName: 'Superior',
    holdName: 'Enterprise Name',
    userName: 'Login Name',
    password: 'Password',
    contacter: 'Contact',
    email: 'Email',
    phone: 'Phone',
    remark: 'Remark',
    timezone: 'Time Zone',
    address: 'Address',
    ServiceEndTime: 'Service End Date',
    placeHolder1: 'Please input enterprise name',
    placeHolder2: 'Please input account',
    placeHolder3: 'Please input password',
    placeHolder4: 'Please input contacter',
    placeHolder5: 'Please input telephone',
    placeHolder6: 'Please select service end date',
    defaultProvince: 'Default province',
    byInstallAddr: 'Install address',
    byFixed: 'Fixed address',
    nullAddress: 'None',
    random: 'Random'
  },
  vehicleDetail: {
    name: 'Vehicle Detail',
    QueryPwd: 'Query Password',
    basicInfo: 'Basic Info',
    peopleInfo: 'Owner info',
    moreInfo: 'More Info',
    holdName: 'Enterprise',
    vehiclePlate: 'Vehicle Plate',
    devicePlate: "device Plate",
    vehicleColor: 'Vehicle Color',
    vehicleBrand: 'Vehicle Brand',
    vehicleType: 'Vehicle Type',
    deviceID: 'Device ID',
    deviceType: 'Device Model',
    deviceLine: 'Device type',
    remark: 'Remark',
    peopleName: 'Name',
    peopleSex: 'Gender',
    male: 'Male',
    female: 'Female',
    phone: 'Telephone',
    idcard: 'ID Card',
    wechat: 'Wechat',
    qq: 'QQ',
    Age: 'age',
    homeAddress: 'Home Address',
    companyAddress: 'Company Address',
    vehicleId: 'Vehicle VIN',
    vehicleMachineId: 'Engine number',
    actionRemove: 'Remove',
    actionUnBind: 'UnBind',
    actionViewInstall: 'View Install Info',
    actionInstall: 'Install',
    moveVehicle: 'Migrate Vehicle',
    pleaseSelectVehicle: 'Please select vehicle',
    pleaseInputVehicle: 'Please input vehicle plate number',
    pleaseSelectBrand: 'Please select vehicle brand',
    pleaseSelectType: 'Please select vehicle type',
    pleaseInputName: 'Please input name',
    pleaseSelectSex: 'Please select gender',
    pleaseInputTelephont: 'Please input phone number',
    pleaseInputAge: 'Please enter age',
    pleaseInputIdCard: 'Please input id card number',
    pleaseInputWechat: 'Please input wechat number',
    pleaseInputQQ: 'Please input qq number',
    pleaseInputFrameNo: 'Please input vehicle frame number',
    pleaseInputEngineNo: 'Please input vehicle engine number',
    bindDevice: 'Bind Device',
    installDevice: 'Install Detail',
    saveInstallInfo: 'Save',
    noDeviceInfo: 'No Device installed',
    clickToSelect: 'Click To Select',
    tip: 'Tip',
    unbindText: 'Confirm unbind this device with vehicle?',
    location: 'Location',
    getLocation: 'GetLocation',
    getSuccess: 'Get location success',
    needConfirm: 'This position is too wide. Please click on the right magnifying glass to select the correct position.',
    getFailed: 'Get location failed, please click the right button to get location.',
    savePeopleTip: 'In order to save address information, the owner\'s name should be entered first.',
    wono: 'WONO',
    carInstallStore: 'StoreName',
    carCost: 'Cost',
    carStorePhone: 'StoreMobile',
    carInstallTime: 'ActiveTime',
    selectPos: 'Select',
    installed: 'Installed device',
    saveAddressTip: '',
    usevandplogin: 'Use license plate and query password to log in platform to query vehicle location',
    '不规则形体': 'Irregular shape',
    '长方体': 'cuboid',
    '圆形/椭圆形': 'Round/ellipse',
    '儿童': 'child',
    '老人': 'The elderly',
    '中年': 'Middle-aged',
    '青年': 'youth',
    '其他': 'other'
  },
  installInfo: {
    vehicleNumber: 'Device name',
    deviceID: 'Device ID',
    installTime: 'Install Time',
    installPeople: 'Install People',
    installPlace: 'Install Position',
    installPhoto: 'Install Photo',
    remark: 'Remark',
    dianping: 'Battery',
    fujiashi: 'Co driving glove box',
    zuocetaban: 'Left pedal',
    youcetaban: 'Right pedal',
    fangxiangpan: 'Under the steering wheel',
    qiandingdeng: 'Front head lamp',
    houdingdeng: 'Rear head lamp',
    houbeixiang: 'Trunk',
    qita: 'Other',
    change: 'Replace',
    pleaseSelectDate: 'Please select installation time',
    pleaseSelectPeople: 'Please select the installer',
    pleaseSelectPosition: 'Please select the installation location',
    pleaseUploadPhoto: 'Please upload photo',
    errorTip1: 'Uploading headshot pictures can only be in JPG format!',
    errorTip2: 'The size of uploaded DP should not exceed 5MB!',
    exceedTip: 'Upload should not exceed 9 pictures',
    exceedTip4: 'Upload should not exceed 4 pictures',
    installPositionNumber: 'Position number'
  },
  deviceDetail: {
    name: 'Device Detail',
    personName: 'Person',
    articleName: 'Asset',
    articleNameTag: 'Asset',
    personNameTag: 'Person',
    deviceName: 'name',
    personName: 'Person name',
    personId: 'Person ID',
    personAge: 'age',
    personIDCard: 'ID card',
    personPhone: 'cellphone number',
    personSex: 'gender',
    pesonGender1: 'male',
    pesonGender2: 'Female',
    personType: 'Person category',
    personPic: 'photo',
    articleName: 'Item name',
    articleWeight: 'weight',
    articleLong: 'length',
    articleHigh: 'height',
    articleColor: 'Item color',
    articleWidth: 'width',
    articleShape: 'Item shape',
    articlesize: 'size',
    Unit: 'cm',
    Unit2: 'KG',
    holdName: 'Enterprise',
    payEndDate: 'VIP Exipring Date',
    deviceID: 'Device ID',
    sim: 'SIM Card',
    deviceType: 'Device Model',
    remark: 'Remark',
    timezone: 'Time Zone',
    pleaseInputSim: 'Please input sim card number',
    pleaseInputDeviceID: 'Please input device id',
    pleaseSelectDeviceType: 'Please select device type',
    device: 'Device',
    vehicle: 'Vehicle',
    showMore: 'Show more',
    showLess: 'Show less',
    saveVehicleInfo: 'Add vehicle infomation',
    tip1: 'Reminder：',
    tip2: '1.You can save vehicle infomation while adding device and auto bind the device to the vehicle. You can also click the removal button on the right side of the vechile infomation to remove the vehicle infomation and only save device infomation. Finally, you can bind the device to the vehicle on the vehicle manage page.',
    tip3: '2.Use vehicle manage page if you need device bind to existing vehicles.',
    unBindTip: 'This will unbind the device with vehicle {name}',
    unBindConfirm: 'Confirm',
    unBindSuccess: 'Unbind successed',
    selectVehicle: 'Select vehicle{name}',
    bindSuccess: 'Bind successed',
    select: 'Select',
    bind: 'Bind',
    tip4: 'Please select vehicle to bind width device.',
    clickToUnbind: 'Click to unbind the vehicle',
    remainTime: 'Remain',
    days: 'Days',
    install: 'Install',
    installTime: 'Install time',
    installPlace: 'Install place',
    installPeople: 'Worker',
    installPhoto: 'Install photo',
    showInstallInfo: 'Show install infomation',
    saveInstallInfo: 'Add install infomation',
    clickToDeleteInformation: 'Click to delete install information',
    deleteInfoConfirm: 'Are you sure delete install information',
    tip: 'Tip',
    vehicleProvince: 'Vehicle province',
    pleaseSelectProvince: 'Please select vehicle province',
    detailInfo: 'Detail info',
    deviceInfo: 'Device info',
    mdtType: 'Protocol',
    createTime: 'Crate time',
    simInfo: 'SIM',
    gpsInfo: 'GPS data',
    gpsTime: 'GPS time',
    rcvTime: 'Receive time',
    oil: 'Oil',
    speed: 'Speed',
    miles: 'Miles',
    location: 'Location',
    statsuDes: 'Status',
    userInfo: 'User info',
    contacter: 'Contacter',
    contactTel: 'Tel',
    addr: 'Addr',
    vehicleType: 'Vehicle Type',
    brandIcon: 'Brand',
    editImg: 'Edit',
    editImgTip: 'Cropp image',
    editImgSuccess: 'Success',
    contactInfo: 'Email',
    WebCam: 'WebCam'
  },
  userDetail: {
    name: 'User Detail',
    basicInfo: 'Basic Info',
    autoInfo: 'Permission',
    holdName: 'User',
    realName: 'User Name',
    userName: 'Login Name',
    password: 'Password',
    startTime: 'Start Time',
    endTime: 'End Time',
    remark: 'Remark',
    timezone: 'Time Zone',
    checkAll: 'Check All',
    cancelAll: 'Cancel All',
    pleaseInputLongUserName: 'UserName must be at least 2 characters',
    pleaseInputUserName: 'Please enter login name',
    pleaseInputPassword: 'Please enter the user password',
    pleaseInputName: 'Please enter the login name',
    pleaseSelctStartTime: 'Please select the start date',
    pleaseSelectEndTime: 'Please select the end date',
    mobile: 'Mobile'
  },
  bindDevice: {
    placeHolder: 'Please input device id',
    tip: 'Tip: Vehicle can bind multiple devices',
    deviceID: 'Device ID',
    sim: 'SIM',
    deviceModel: 'Device Model',
    trackerType: 'Tracker Type',
    remark: 'Remark',
    selected: 'Selected',
    search: 'Search',
    viewAll: 'View all',
    vehicleName: 'Vehicle Plate',
    containAll: 'Contain binded'
  },
  popoverInfo: {
    enterprise: 'Enterprise',
    deviceID: 'Device ID',
    SIM: 'SIM',
    deviceModel: 'Device Model',
    remark: 'Remark',
    modifier: 'Modifier',
    updateTime: 'Update Time',
    vehicle: 'Vehicle Plate',
    queryPswd: 'Query Password',
    bingCount: 'Bind Count',
    owner: 'Owner',
    ownerMobile: 'Mobile',
    ownerAddress: 'Address',
    createUser: 'Create User',
    createTime: 'Create Time',
    loginName: 'Login Name',
    serviceEnddate: 'Service Enddate',
    contacter: 'Contacter',
    mobile: 'Mobile'
  },
  favorite: {
    name: 'Favorites',
    detail: 'Vehicle Detail',
    empty: 'Empty',
    export: 'Export',
    print: 'Print',
    fullScreen: 'Full Screen',
    monitor: 'Monitor',
    vehicleName: 'Vehicle Plate',
    peopleName: 'Owner Name',
    deviceID: 'Device ID',
    alarm: 'Alarm',
    statusdes: 'Status',
    riskStatus: 'Risk Status',
    operation: 'Operation',
    remove: 'unsubscribe',
    onlineDevice: 'Wiring GPS Tracker',
    nolineDevice: 'Assets Tracker',
    time: 'Time',
    remark: 'Remark',
    isAlarm: 'Alarm',
    highRisk: 'High risk',
    middleRisk: 'Middle risk',
    lowRisk: 'Low risk',
    tip: 'Tip',
    text1: 'Clear list？',
    excelName: 'DeviceDetail',
    batteryTip1: 'Assets Tracker, Battery {number}%',
    battery: 'Battery',
    removeOne: 'Did you confirm onlonger follow this vehicle:{name}?'
  },
  onlineMonitor: {
    userList: 'User List',
    sendSMS: 'Send Message',
    sendSMSTip: 'Please enter the sim card number',
    sendSMSError: 'Please enter the correct sim card number',
    favoritePlace: 'POI',
    favoritePlaceTip: 'Please enter keywords to search POI',
    favoriteRegion: 'Region',
    favoriteRegionTip: 'Please enter keywords to search region',
    regionSearch: 'Rect',
    regionSearchTip: 'Draw square areas on the map to find vehicles',
    refreshTip: 'Refresh after {count} seconds',
    refreshAction: 'Pause',
    refreshResume: 'Resume',
    ruler: 'Ruler',
    save: 'Save',
    fullScreen: 'FullScreen',
    emptyMap: 'Clear Map',
    batchCommand: "Batch Command",
    exit: 'Exit',
    searchEryadian: 'Risk Place',
    searchEryadianTip: 'Please enter keywords to search risk place',
    distanceTip: 'Click the left mouse button to get the measurement point, and click right mouse button to end the ranging',
    vehicleName: 'Vehicle Plate',
    deviceName: "device name",
    speed: 'Speed',
    totalMile: 'Mileage',
    oil: 'Fuel',
    statsDes: 'Status',
    gpsTime: 'GPS Time',
    dataTime: 'Data Time',
    rcvTime: 'Rcv Time',
    place: 'Place',
    lineFeed: 'Line Feed',
    deviceID: 'Device ID',
    directoin: 'Direction',
    remove: 'Remove',
    mile: 'Mile',
    dayMiles: 'Day miles',
    allMiles: 'Odometer',
    gps: 'GPS',
    lbs: 'LBS',
    wifi: 'WIFI',
    viewInfo: 'ViewDetail',
    realSence: 'Street view',
    gpsHistoryPlay: 'Play History',
    gpsTrack: 'tracing',
    setCommand: 'Set Command',
    riskScan: 'Risk detect',
    alarm: 'Alarm',
    noAlarm: 'No Alarm',
    haslineDevice: 'Wiring GPS Tracker',
    nolineDevice: 'Assets Tracker',
    noRealsenceTip: 'This positon has no street view.',
    unit1: 'km/h',
    unit2: 'km',
    unit3: 'L',
    unit4: '千克',
    un1: 'KM/H',
    un2: 'KM',
    un3: '°C',
    panelLink1: 'LBS/WIFI',
    panelLink2: 'Detail',
    panelLink3: 'St. View',
    panelLink4: 'History Trace',
    panelLink5: 'Command',
    panelLink6: 'Risk Detect',
    panelLink7: 'Send msg',
    panelLink8: 'Set Defense',
    panelLink9: 'Cancel Defense',
    panelLink10: 'Follow',
    panelLink11: 'unfollow',
    panelLink12: 'Live Tracking',
    panelLink13: '应急预案',
    panelLink14: 'Tire pressure monitoring',
    setSF: 'Set success',
    cancelSF: 'Cancel success',
    noLbsTip: 'This device have not LBS and wifi info.',
    lngLat: 'Coordinate',
    removeFavirite: 'Remove POI',
    removeEryadian: 'Remove POI',
    saveMapInfo: 'Map position saved successfully.',
    noPositionTip: 'This device has not been used',
    regionName: 'Geo-fence Name',
    createTime: 'Create Time',
    deletePoiTip: 'Delete POI {name} successed',
    savePoiTitle: 'Please input POI name',
    savePoiTip: 'Please Input',
    savePoiTip2: 'Input name too long',
    saveSuccessed: 'Save successed',
    savePoiTip3: 'Please click map to New POI',
    layers: 'Layers',
    layerNormal: 'Standard',
    layerZixingche: 'Bicycle',
    layerTraffic: 'Traffic',
    layerHumanitarian: 'Humanitarian',
    layerSatellite: 'Satellite',
    layerGoogle: 'Google Map',
    layerBaidu: 'Baidu Map',
    layerTecent: 'Tecent Map',
    monitorAll: 'Monitor All',
    refresh: "Refresh",
    onRefresh: "Turn on refresh",
    offRefresh: "Turn off refresh",
    monitorAllTip: 'Maximum 500 devices can be loaded.',
    all: 'All',
    simcard: 'SIM Card Number',
    pleaseInputSimcard: 'Please input your device sim card number',
    inputSimcardSeeHistory: 'Input sim card number to view history',
    alarmType: 'Alarm type',
    enterprise: 'Enterprise',
    refreshUserTree: 'Refresh user tree',
    fullscreen: "Toggle fullscreeen",
    showAddress: 'Show address default',
    peopleName: 'People',
    vin: 'VIN',
    storeName: 'Store',
    setViewItem: 'Set view item',
    searchPlace: 'Search place',
    clickRightRemove: 'click right button move',
    emptyList: 'Empty',
    uName: 'name',
    uAge: 'age',
    uSex: 'gender',
    uMale: 'male',
    uFemale: 'female',
    aName: 'Item name',
    aId: 'ID',
    aWeight: 'Weight',
    aSize: 'Size',
    aColor: 'Color',
    statusTip1: ' Move',
    statusTip2: 'Ignition off',
    statusTip3: 'Static',
    statusTip4: 'Offline',
    statusTip5: 'Alarm',
    statusTip6: 'Weak',
    statusTip7: 'Unused',
    alamStatus1: "Online",
    alamStatus2: "Alarm",
    alamStatus3: "Total",
    disalarm: 'Disarm the alarm',
    lastLocation: 'Last upload',
    gps1: "Strong",
    gps2: "Medium",
    gps3: "weak",
    gsm1: "Strong",
    gsm2: "Medium",
    gsm3: "weak",
    owner: '驾驶员',
    goods: '运输货物',
    departure: '出发地',
    destination: '目的地',
    weight: '重量',
    personInfo: 'Person Info',
    assetInfo: 'Asset Info',
    tireHight: 'High pressure',
    tireLow: 'Too low',
    tireStatus1: 'Air leak',
    tireStatus2: 'Air leak',
    tireStatus3: 'Air leak',
    tireStatus4: 'Low battery',
    tireTitle: 'Tire pressure monitoring',
    tireTimes: 'Monitoring time',
    tirePress: 'Tire pressure',
    tireTemp: 'Temperature',
    tireDialogTips1: 'No tire pressure related information',
    tireDialogTips2: 'There is no temperature curve and tire pressure curve data',
    tireBegin: 'Start date',
    tireIner: 'to',
    tireEnd: 'End date',
  },
  onlineTrack: {
    vehicleInfo: 'Vehicle Info',
    vehicleNumber: 'Device name',
    deviceList: 'Device List',
    vehicleSelectTip: 'Select vehicle',
    placeParams: 'Replay parameters',
    viewTrip: 'View Trip',
    today: 'Today',
    yestoday: 'Yesterday',
    beforeYestoday: 'DBY',
    threeDay: 'Last 3 days',
    startTime: 'Start Time',
    endTime: 'End Time',
    longStop: 'Over time stop',
    mode: 'Mode',
    lineMode: 'Line',
    pointMode: 'Point',
    realTimeMode: 'Real time',
    historyMode: 'History',
    other: 'Options',
    showPoint: 'Show Point',
    hideZero: 'Ignore 0km/h',
    referPosition: 'Reference Location',
    historyDetail: 'Show Detail',
    showAddress: 'Show Address',
    showColor: '3 color trace',
    showRealSence: 'Street View',
    playSpeed: 'Speed',
    playTrack: 'Play',
    onPlay: 'On play',
    orderNumber: 'No.',
    speed: 'Speed',
    miles: 'Mileage',
    oil: 'Fuel',
    statusDes: 'Status',
    gpst: 'GPS',
    rcvt: 'RCV',
    gpsTime: 'GPS Time',
    rcvTime: 'Receive Time',
    place: 'Place',
    time: 'Time',
    speedDesc: '{speed} km/h',
    direction: 'Direction',
    mile: 'Mileage',
    currentMile: 'Mileage',
    status: 'Status',
    stopFlag: 'Stop Flag',
    showStopFlag: 'Show parking',
    show: 'Display',
    infoWindow: 'InfoWindow',
    trackLine: 'TrackLine',
    blod: 'Bold',
    progress: 'Progress',
    wrap: 'Line feed',
    searchVehicle: 'Search Vehicle',
    minute: 'Minute',
    lowSpeed: 'Low Speed',
    highSpeed: 'High Speed',
    lowAndHigh: 'Low and high',
    normal: 'Normal',
    low: 'Low',
    high: 'High',
    realSenceTip: ' Slow down playback when display street view',
    colorTip: '速度<30km/h;红色;速度<60km/h 蓝色;速度>80 绿色',
    historyTrip: 'History Trip',
    query: 'Query',
    kilo: 'KM',
    from: 'From',
    to: 'To',
    noRealSenceTip: 'There is no street view here',
    oilUnit: 'L',
    stopTime: 'Stop time',
    stopTip: 'Stop {time} minutes',
    poiPanel: 'POI & Fence',
    poiTab: 'POI',
    regionTab: 'Fence',
    circleTab: '圆形围栏',
    rectangleTab: '矩形围栏',
    regionTabLink: 'Link fence',
    loadAll: 'Load all',
    noStopInfo: 'No stop data.',
    play: 'Play',
    pause: 'Pause',
    stop: 'Stop',
    historyRange: 'History areas',
    historyTime: 'Time',
    historySpeed: 'Speed',
    historySearch: 'Query',
    historyOnsearch: 'Loading',
    historyTip: 'Please select the area in the drop-down box on the map.',
    historySuccess: 'Find {count} cars.',
    showInfo: 'Open infoWindow',
    isGps: 'Is GPS',
    yes: 'Y',
    showMore: 'More',
    hideMore: 'Hide',
    measure: 'Measure',
    showPath: 'Show path',
    homeAndCom: 'Show home address',
    noHomeAndComPosition: 'No home address and no company address',
    noComPosition: 'No company address',
    noHomePosition: 'No home address',
    goPosition: 'Set address',
    homeAddress: 'Home address',
    companyAddress: 'Company address',
    lnglat: 'Lnglat',
    exportData: 'Export',
    exportFileName: 'Trackdata_{name}_{start}_{end}_.xlsx',
    ADSpeed: 'ADSpeed',
    collision: 'collision',
    drop: 'drop',
    GPSFlag: 'GPSFlag',
    Overturn: 'Overturn',
    SharpTurn: 'SharpTurn',
    Attachment: 'Attachment',
    ubiRollover: 'rollover',
    ubiCollision: 'collision',
    ubiDecelerate: 'harsh accelerate',
    ubiAccelerate: 'harsh decelerate',
    ubiTurnRight: 'Sharp right turn',
    ubiTurnLeft: 'Sharp left turn',
    realTracking: 'Real-time tracking',
    timeLimitTips: 'Select time (up to 30 days can be queried)'
  },
  regionManage: {
    name: 'Geo-fence Manage',
    totalTip: 'Total {count}',
    includeChild: 'Include Subordinate',
    modify: 'Modify',
    add: 'New',
    deflete: 'Delete',
    viewDetail: 'View Detail',
    contactRegion: 'Bind ',
    cancelContact: 'Unbind ',
    addTip: 'Please click on the map drawing ploygon, double-click or click right mouse to finish.',
    regionSetting: 'Geo-fence configure',
    start: 'Start',
    regionName: 'Geo-fence Name',
    recordType: 'Record type',
    validTime: 'Valid time range',
    triggerAlarm: 'Trigger Alarm',
    regionLimit: 'Limit Speed',
    limitTip: 'km/h',
    enterTip: 'Enter Geo-fence Tip',
    leaveTip: 'Leave Geo-fence Tip',
    vehiceBindList: 'Vehicle Bind List',
    regionTip: 'Entry and exit region tip only support VDF full-function model',
    viewBindDevice: 'View binded device',
    bindCountTip: 'Total {count} binded',
    clear: 'Clear',
    clearTip: 'Clear old Geo-fence and draw again',
    drawAgain: 'Draw Again',
    selectVehicle: 'Select Vehicle',
    enterRegion: 'Enter Geo-fence',
    leaveRegion: 'Leave Geo-fence',
    enterLeaveRegion: 'Enter and leave Geo-fence',
    enterLeaveRegionNoAction: 'Enter and leave not record',
    vehicleName: 'Vehicle Plate',
    deviceID: 'Device ID',
    lineType: 'Device type',
    yes: 'Yes',
    no: 'No',
    limitTip2: '{speed} limit km/h',
    ioTypeList: ['Enter and leave Geo-fence', 'Enter Geo-fence', 'Leave Geo-fence'],
    deleteTip: 'Delete {name}?',
    deleteSuccessed: 'Delete successed',
    selectTip: 'Please select Geo-fence',
    selectTip2: 'Please select a route',
    saveSuccessed: 'Success save',
    enterRegionTip: 'Please input Geo-fence name',
    enterRoadTip: 'Please input Road name',
    jiechu: 'Remove',
    nodata: 'No data',
    viewAll: 'View All',
    addRoad: 'Add Road',
    roadLimit: 'Limit Speed',
    roadWidth: 'Road Width',
    roadSetting: 'Road Setting',
    modifyRoad: 'Modify Road',
    roadName: 'Road Name',
    totalRoadTip: 'Total {count}',
    endEdit: 'Complete',
    editPoly: 'Modify',
    unit1: 'Unit: m',
    regionLimit2: 'Maximum residence time',
    triggerAlarm2: 'Stay settings',
    limitTip2: 'minute',
  },
  placeManage: {
    name: "Device Information",
    deviceName: "Device name",
    deviceList: "Device List",
    parma: "Query parameter",
    placeHolderSearch: "Find device",
    placeHolderFind: "Please select a device",
    today: "today",
    yestoday: 'yesterday',
    lastday: "The day before yesterday",
    day1: "Last 3 days",
    begin: 'Starting time',
    end: "End Time",
    placeHolderTime: "Choose date and time",
    stay: 'Stay time',
    lt: 'exceed',
    min: 'minute',
    modes: 'Query mode',
    mode1: "Check often stop",
    mode2: "Check the line",
    btn: 'Query',
    form: 'From',
    to: "To",
    roadDetail: 'Route details',
    export: 'Export',
    pointerDetail: 'Stay details',
    clock: "time",
    address: "position",
    show: 'show',
    showHome: 'Home location',
    showCompany: "Company Location",
    showAll: "Show full image",
    layout1: "Satellite imagery",
    layout2: "Road Network",
    layout3: "Street view",
    startTime: "departure time",
    endTime: "End Time",
    stayTimes: "Length of stay",
    roadAll: "Whole journey",
    length: 'Total length',
    startPointer: "starting point",
    endPointer: "end",
    lonlat: "Latitude and longitude",
    location: "address",
  },
  photo: {
    search: 'Search',
    vipTip: 'Only VIP Device',
    last1Day: 'Recent 1 day',
    last2Days: 'Recent 2 days',
    last3Days: 'Recent 3 days',
    vehicleName: 'Vehicle',
    searchTip: 'Total {count} images',
    startTime: 'Start',
    endTime: 'End',
    takePhoto: 'Take Photo',
    photoCount: 'Count',
    photoInterval: 'Interval',
    photoChnnel: 'Channel',
    sendCommand: 'Send Command',
    setting: 'Setting',
    paraType: 'Type',
    photoSize: 'Resolution',
    runTakePhoto: 'Normal',
    conditionPhoto: 'Event',
    size: 'Size',
    direction: 'Direction',
    place: 'Place',
    speed: 'Speed',
    unUsed: 'UnUsed',
    sec: 'Sec',
    chnnel1: 'Channel 1',
    chnnel2: 'Channel 2',
    chnnel3: 'Channel 3',
    chnnel4: 'Channel 4',
    selectVehicleTip: 'Please select vehicle',
    maxCountWarn: 'There are too many images that meet your query criteria, the system will only return the latest 300 images.',
    sendSuccessed: 'Success send',
    vehicle: 'Vehicle',
    keyDaojing: 'Alarm',
    keySpeed: 'Stop',
    keyDoorOpen: 'Door open',
    keyDoorClose: 'Door close',
    keyCus: 'Carry',
    keyNon: 'Empty',
    keyAccOn: 'ACC on',
    keyAccOff: 'ACC off'
  },
  notify: {
    title: 'title',
    BeginTime: 'Activation time',
    ShowLevel: 'Individual user viewing restrictions',
    UpdateTime: 'Update time',
    CreateTime: 'Creation time',
    UserName: 'Create user',
    operation: 'operation',
    preview: 'preview',
    delete: 'delete',
    levelTips: 'Restriction, level',
    verson1: 'deleted',
    verson2: 'Currently enabled',
    verson3: 'Scheduled',
    verson4: 'old version',
    tip1: "Increase announcement",
    tip2: "delete selected"
  },
  changeMap: {
    name: 'Change Map',
    google: 'Google Map',
    amap: 'AMap',
    bmap: 'Baidu Map',
    qqmap: 'Tencent Map',
    bing: 'Bing Map'
  },
  homeAlarm: {
    name: 'Alarm Detail',
    alarmType: 'Alarm Type',
    query: 'Query',
    export: 'Export',
    tip: 'Only show online and alarm device.',
    vehicleName: 'Vehicle Plate',
    deviceID: 'Device ID',
    alarmTime: 'Alarm Time',
    alarmPlace: 'Alarm Place',
    alarmType1: 'Low voltage alarm',
    alarmType2: 'Removal alarm',
    alarmType3: 'Power off alarm',
    alarmType4: 'Entrance Alarm',
    alarmType5: 'All offline',
    alarmType6: 'Split Alarm',
    alarmType7: 'Out Province alarm',
    alarmType8: 'Risk place',
    alarmType9: 'Low battery level alarm',
    alarmType10: 'Power failure alarm',
    alarmType11: 'Exit Alarm',
    alarmType12: 'Departure route alarm',
    alarmType13: 'Fall alarm',
    alarmType14: 'Collision alarm',
    alarmType15: 'Emergency alarm',
    alarmType16: '超速报警',
    alarmType17: '禁行时间段报警',
    alarmType18: '滞留车辆报警',
    tip2: 'No data.'
  },
  dialogCellAndLbs: {
    name: 'LBS/WIFI Location',
    gpsPoint: 'GPS Location',
    wifiPoint: 'WIFI Location',
    lbsCenter: 'LBS Center',
    distance: 'Distance',
    distanceWithLbs: 'Distance with LBS location',
    distanceWidthWifi: 'Distance width WIFI location',
    emtpy: '(null)'
  },
  dialogBatchCommand: {
    sendCommand: 'Send Command',
    commandHistory: 'Command History',
    selectVehicle: 'Select vehicle',
    selectDevices: 'Total device {count}',
    hasSelect: 'Selected',
    selectType: 'Device Protocol',
    selectCommand: 'Command Name',
    setOptions: 'Set Parameters',
    sendButtonText: 'Send',
    commandName: 'Command Name',
    commandContent: 'Command content',
    commandDeviceCount: 'Device Count',
    Resend: 'Resend',
    successRate: 'Success rate',
    time: 'Time',
    options: 'Options',
    queryTime: 'Query Time',
    queryButton: 'Query',
    recentUse: 'Recent Used',
    totalCommand: 'Total Command',
    usuallyCommand: 'Usually',
    moreCommand: 'More',
    vehicleName: 'Vehicle plate',
    deviceID: 'Device ID',
    sendStatus: 'Send Status',
    refresh: 'Refresh',
    query: 'Query',
    selectedList: 'Selected List',
    wireType: 'Device Type',
    isOnline: 'Online or not ',
    allResend: 'All resent',
    view: 'View',
    reSend: 'ReSendAll',
    sendDetail: 'Send Detail',
    alreadySend: 'Already Resend',
    reSendAll: 'Resend All',
    reSendChecked: 'Resend Checked',
    sending: 'Sending...',
    sendSuccessed: 'Send Success',
    sendError: 'Send Error. Please try again.',
    unableMsg: 'Too many selected',
    expand: 'Expand',
    collapse: 'Collapse',
    line: 'GPS Tracker',
    offline: 'Wireless GPS Tracker',
    unsupported: 'Unsupported device protocol',
    sleepErroMsg: 'The parameter is too small. It is recommended to set the parameter range from 30s to 300s',
    sleepErroMsg2: 'The parameter is too large. It is recommended to set the parameter range from 30s to 300s',
    intervalErroMsg: 'The parameter is too small. It is recommended to set the parameter range from 5 minutes to 43200 minutes',
    intervalErroMsg2: 'The parameter is too large. It is recommended to set the parameter range from 5 minutes to 43200 minutes'
  },
  dialogMessage: {
    name: 'Send Message',
    selectVehicle: 'Select Vehicle',
    searchInfo: 'Title',
    searchBody: 'Message',
    queryPlaceHolder: 'Please enter keyword ',
    bodyPlaceHolder: 'please enter content',
    sendButton: 'Send',
    saveAndSend: 'Save & Send',
    pleaseSelectVehicle: 'Select vehicle',
    sendSuccessed: 'Send success',
    confirmDelete: 'Are you sure you want to delete this information?',
    deleteSuccessed: 'Delete success'
  },
  dialogSetting: {
    vehicleNumber: 'Device name',
    tableData: 'Table Column',
    changeTip: '*Settings take effect after refreshing the page',
    demoData: {
      PeopleName: 'Jack',
      VehicleName: 'AA-001',
      SIM2: '861906228999018',
      SIM: '1440118228887',
      VIN: 'LHRTY4852D8478254',
      VehiclePriceName: '$66000',
      TrackerTypeName: 'W',
    },
    tips: 'Save successfully. Do you want to reload the page?',
  },
  moveParent: {
    name: 'Move User',
    tip: 'Migrating Vehicles will Migrate Vehicle Binding device at the same time',
    moveTarget: 'Migrating objects',
    oldParent: 'Old superior',
    newParent: 'New superior',
    selectParent: 'Please select a new superior',
    selectUser: 'Please select a user',
    changeSuccessed: 'Modify success',
    activeSuccessed: 'Activation successful',
    activeFail: 'Activation failed',
    total: 'Total {count}',
    inputIds: 'Input DeviceID',
    vipDate: 'VIP expiry Update',
    vipExpiring: 'VIP expiring Date'
  },
  reportQuery: {
    allReport: 'All reports',
    condition: 'Report parameter',
    result: 'Search results',
    btnQuery: 'Search',
    options: 'Option',
    export: 'Export',
    fullScreen: 'Full Screen',
    expand: 'Expand',
    noData: 'No Data',
    day1: 'Today',
    day2: 'Yesterday',
    day3: 'The day before yesterday',
    day12: 'Last 2 days',
    day13: 'Last 3 days',
    day14: 'Last 7 days',
    week1: 'Week',
    week2: 'Last week',
    week3: 'The week before last',
    week12: 'Last 2 weeks',
    week13: 'Last 3 weeks',
    month1: 'Month',
    month2: 'Last month',
    month3: 'The month before last',
    month12: 'Last 2 months',
    month13: 'Last 3 months',
    time: 'Time',
    temperature: 'Temperature',
    noavailable: 'No available report'
  },
  spread: {
    full: 'Fullscreen',
    sum: 'Total',
    unit: '',
    exit: 'Exit'
  },
  reportMenu: {
    '基础报表': 'Basic report',
    '里程报表': 'Mileage report',
    '油耗报表': 'Fuel consumption report',
    '警情报表': 'Alarm report',
    '温度报表': 'Temperature Report',
    '超速报表': 'Overspeed Report',
    '行驶状态报表': 'Driving status report',
    '区域报表': 'Geo-fence report',
    '统计报表': 'Analysis report',
    '其他': 'Other',
    '用户资料报表': 'User data report',
    '车辆资料报表': 'Vehicle data report',
    '人物资料报表': 'Character data report',
    '物品资料报表': 'Item data report',
    '里程日统计表': 'Daily mileage  statistics',
    '里程月统计表': 'Monthly mileage statistics',
    '里程汇总表': 'Mileage summary',
    '空重车里程时间统计表': 'Load and unload vehicle mileage time statistcs report',
    '油量曲线表': 'Fuel curve report',
    '油耗明细表': 'Fuel consumption details report ',
    '报警明细表': 'Alarm details report',
    '处警明细表': 'Handled alarm report',
    '报警日统计表': 'Daily Alarm statistics',
    '报警月统计表': 'Monthly Alarm statistics',
    '温度曲线表': 'Temperature curve report',
    '超速明细表': 'Overspeed report',
    '超速日统计表': 'Daily overspeed report',
    '超速月统计表': 'Monthly overspeed report',
    'ACC时间统计表': 'ACC time report',
    '车辆工作情况报表': 'Vehicle Work Status report',
    '车辆驶停情况表': 'Vehicle drive and park report',
    '进出区域统计表': 'Geo-fence statistics report',
    '进出区域明细表': 'Geo-fence details report',
    '车辆趟次明细表': 'Trip report',
    '车辆趟次统计表': 'Trip statistics',
    '轨迹报表': 'Trace report',
    '时刻状态报表': 'Time status report',
    '车门状态报表': 'Door status report',
    '目标巡检报表': 'Target inspection report',
    '公共信息报表': 'Public information report',
    '无线设备电量报表': 'Battery level report of asset trackers',
    "车辆轨迹报表": "Vehicle trace report",
    "时段里程报表": "Time slot mileage report",
    '设备激活报表': 'Device Activation Report',
    '围栏报警报表': 'Fence alarm report',
    '防拆报表': 'Demolition Report',
    '最后定位报表': 'Final Location Report',
    '停驶报表': 'Suspension Report',
    '电量统计': 'Electricity statistics',
    '设备分离报表': 'Split report',
    '离线报警表': 'Offline report',
    '长时间未回家报表': 'Leave home report',
    '长时间未回公司报表': 'Leave company report',
    '出省报表': 'Out province report',
    '夜间用车报表': 'Night Car Report',
    '里程统计报表': 'Mileage Statistics Report',
    '超速明细报表': 'Overspeed Detail Report',
    '设备资料报表': 'Device Detail Report',
    '其他报警报表': 'Other Alarm Report',
    '拆机报警报表': 'Fall off Alarm Report',
    '长时间停驶报表': 'Long Stop Report',
    '进出围栏报表': 'Geo Fence Report',
    '工单统计报表': 'Install Report',
    '事故报警报表': 'Crash Report',
    '设防/撤防报表': 'Armed/disarmed report',
    'UBI明细表': 'UBI schedule',
    '摩托车管理报表': 'Motorcycle management report',
    '服务费到期报表': 'Service fee due report',
    '续费记录报表': 'Renewal Record Report',
    '新增激活报表': 'New activation report'
  },
  funMenu: {
    'GPS监控': 'GPS monitor',
    '在线监控': 'Vehicle Monitor',
    '位置服务': 'Monitor',
    '系统信息': 'System Info',
    '报表中心': 'Reports',
    '监听': 'Listen-in',
    '显示SIM': 'Show SIM',
    '激活': 'Activate',
    '轨迹回放': 'History Trace',
    '拍照浏览': 'Photo view',
    '车辆管理': 'Device',
    '风控管理': 'Risk management',
    '线路管理': 'Road',
    '省界管理': 'Provincial boundary management',
    '业务分布图': 'Business distribution map',
    '二押点': 'Second bet',
    '工单系统': 'Work Order System',
    '工单管理': 'Work order management',
    '技师管理': 'Technician Management',
    '导出': 'Export',
    '审核': 'Audit',
    '审核后编辑': 'Edit after review',
    '保单管理': 'Policy management',
    '录入保单': 'Enter the policy',
    '锁车': 'Lock car',
    '解锁': 'Unlock',
    '解除报警': 'Release alarm',
    '目标图像': 'target image',
    '轨迹查询': 'History Trace',
    '电子围栏': 'Geo-fence',
    '体验日志': 'Visitor log',
    '发送信息': 'Send message',
    '发送指令': 'Send command',
    '发送短信': 'Send SMS',
    '汽车金融': 'BHPH',
    '风控评估': 'Risk evaluation',
    '资产分析': 'Assets analysis',
    '暗访追车': 'Unannounced  car chase',
    '找常停点': 'Frequent stop place',
    '灰名单': 'Grey list',
    '新增': 'New',
    '删除': 'Delete',
    '编辑': 'Edit',
    '查询': 'Query',
    '续费充值': 'Upgrade',
    '到期提醒': 'Expiration reminder',
    '自助续费': 'Self recharge',
    '我的钱包': 'My wallet',
    '转赠': 'Giving to',
    '信息管理': 'Info Manage',
    '用户管理': 'Enterprise',
    '账号管理': 'User',
    '设备管理': 'Device',
    '重要信息': 'Important information',
    'VIP有效期': 'VIP validity period',
    '默认VIP(4年)': 'Default VIP(4 years)',
    '服务截止日期': 'Service end date',
    '免费查看7天轨迹': 'Free 7-day Track View',
    '安装人员': 'Installer',
    '安装任务': 'Install task',
    '报表查询': 'Reports',
    '操作日志': 'User Log',
    '基础报表': 'Basic report',
    '里程报表': 'Mileage report',
    '油耗报表': 'Fuel consumption report',
    '警情报表': 'Alarm report',
    '温度报表': 'Temperature Report',
    '超速报表': 'Overspeed Report',
    '行驶状态报表': 'Driving status report',
    '区域报表': 'Geo-fence report',
    '摩托车管理报表': 'Motorcycle management report',
    '服务费到期报表': 'Service fee due report',
    '续费记录报表': 'Renewal Record Report',
    '新增激活报表': 'New activation report',
    'UBI明细表': 'UBI schedule',
    '设防/撤防报表': 'Armed/disarmed report',
    '工单统计报表': 'Work Order Statistics Report',
    '其他': 'Other',
    '用户资料报表': 'User data report',
    '里程日统计表': 'Daily Mileage statistics',
    '里程月统计表': 'Monthly Mileage statistics',
    '里程汇总表': 'Mileage summary',
    '空重车里程时间统计表': 'Load and unload vehicle mileage time statistcs',
    '油量曲线表': 'Fuel level curve report',
    '油耗明细表': 'Fuel consumption report',
    '报警明细表': 'Alarm report',
    '处警明细表': 'Alarm handling list',
    '报警月统计表': 'Monthly Alarm statistics ',
    '温度曲线表': 'Temperature curve list',
    '超速明细表': 'Overspeed List',
    '超速日统计表': 'Speed report',
    '超速月统计表': 'Monthly overspeed statistics',
    'ACC时间统计表': 'ACC time report',
    '车辆工作情况报表': 'Vehicle Work Status List',
    '车辆驶停情况表': 'Vehicle park and drive report',
    '进出区域统计表': 'Geo-fence statistics',
    '进出区域明细表': 'Geo-fence list',
    '车辆趟次明细表': 'Trip list',
    '车辆趟次统计表': 'Trip Statistcis',
    '统计报表': 'Statistical report',
    '轨迹报表': 'Trace report',
    '时刻状态报表': 'Time status report',
    '车门状态报表': 'Door status report',
    '目标巡检报表': 'Target inspection report',
    '公共信息报表': 'Public information report',
    '无线设备电量报表': 'Battery level report of the asset trackers',
    '消费': 'Consume',
    "VIP管理": "VIP",
    "升级VIP": "Upgrade VIP",
    "公告管理": "Notice",
    "资料管理": "Data Manage",
    "风控名单": "Risk List",
    "车辆资料报表": "Vehicle data report",
    '人物资料报表': 'Character data report',
    '物品资料报表': 'Item data report',
    "车辆轨迹报表": "Vehicle trace report",
    "报警日统计表": "Alarm daily report",
    "时段里程报表": "Time slot mileage report",
    '设备分离报表': 'Split report',
    '离线报警表': 'Offline report',
    '长时间未回家报表': 'Leave home report',
    '长时间未回公司报表': 'Leave company report',
    '出省报表': 'Out province report',
    '设备激活报表': 'Device Activation Report',
    '围栏报警报表': 'Fence alarm report',
    '防拆报表': 'Demolition Report',
    '最后定位报表': 'Final Location Report',
    '停驶报表': 'Suspension Report',
    '电量统计': 'Electricity statistics',
    '夜间用车报表': 'Night Car Report',
    '里程统计报表': 'Mileage Statistics Report',
    '超速明细报表': 'Overspeed Detail Report',
    '设备资料报表': 'Device Detail Report',
    '其他报警报表': 'Other Alarm Report',
    '拆机报警报表': 'Fall off Alarm Report',
    '长时间停驶报表': 'Long Stop Report',
    '进出围栏报表': 'Geo Fence Report',
    '批量指令': 'Batch command',
    '查询电子围栏': 'Query Geo',
    '事故报警报表': 'Crash Report',
    'DVR视频': 'DVR',
    'WiFi管理': 'Wifi Manage ',
    '基站管理': 'LBS Manage',
    '震动检测点': 'Motion Schedule',
    '装车时长报表': 'Parked Day Counter',
    '设防/撤防报表': 'Armed/disarmed report',
    'UBI明细表': 'UBI schedule',
    '摩托车管理报表': 'Motorcycle management report',
    '服务费到期报表': 'Service fee due report',
    '续费记录报表': 'Renewal Record Report',
    '新增激活报表': 'New activation report'
  },
  reportDetail: {
    '用户名称': 'Enterprise Name',
    '信用等级': 'credit rating',
    '营业执照': 'Business license',
    '传真号码': 'Fax number',
    '24小时联系电话': '24 hour phone number.',
    '联系人': 'Contacts',
    '邮政编码': 'Postal Code',
    '联系地址': 'Contact address',
    '状态描述': 'State description',
    '服务截止日期': 'Service deadline',
    '设备ID': 'Device ID',
    '车牌/设备ID': 'Vehicle plate/Device ID',
    '车牌/设备ID/车架号/工单号': 'Vehicle plate/Device ID/VIN/WONO',
    '开始日期': 'Start date',
    '结束日期': 'End date',
    '统计日期': 'Statistical date',
    '所属单位': 'Enterprise',
    '牌号': 'Vehicle Plate',
    '里程(公里)': 'Mileage (km)',
    '开始时间': 'start time',
    '结束时间': 'End time',
    '报警类型': 'Alarm type',
    '显示位置': 'Display position',
    '报警时间': 'Alarm time',
    '编号': 'number',
    '速度(KM/H)': 'Speed (KM/H)',
    'GPS状态描述': 'GPS status description',
    'GPS时间': 'GPS Time',
    '经度': 'longitude',
    '纬度': 'latitude',
    '位置': 'position',
    '状态类型': 'Status type',
    '持续时间(分钟)': 'Duration (minutes)',
    '驾驶员': 'Driver',
    'ACC状态': 'Acc Status',
    '持续时长': 'Duration',
    '里程': 'mileage',
    '总里程': 'Total mileage',
    '油耗': 'Oil consumption',
    '起点经度': 'Starting longitude',
    '起点纬度': 'Starting latitude',
    '终点经度': 'End longitude',
    '终点纬度': 'Destination latitude',
    '起点位置': 'Starting position',
    '终点位置': 'Ending location',
    '速度≥': 'Speed ≥',
    '接收时间': 'Receiving time',
    '方向': 'direction',
    '里程(KM)': 'Mileage (KM)',
    '油量(L)': 'Fuel level (L)',
    '状态': 'status',
    '状 态': 'status',
    '定位': 'Location',
    '日期': 'Date',
    '离线时长': 'Offline description',
    'SIM卡号': 'Sim card No.',
    '设备型号': 'Device type',
    'SIM有效期': 'SIM validity period',
    '最后在线': 'Last online',
    '最后定位': 'Final location',
    '最后状态': 'Final status',
    '所属用户': 'Owners',
    '用户联系人': 'contacts',
    '用户电话': 'Phone',
    '安装日期': 'install date',
    '机主姓名': 'Name',
    '机主电话': 'Main telephone',
    '车牌编号': 'License plate number',
    '车牌号码': 'Vehicle Number',
    '开始年月': 'From Date',
    '结束年月': 'To Date',
    '年月': 'Date',
    '处警内容': 'Content',
    '定位时间': 'GPS Time',
    '车牌': 'Vehicle',
    '报警名称': 'Alarm Name',
    '处警时间': 'Alarm Time',
    '处警人': 'Handle People',
    '空车里程(KM)': 'Empty Mileage(KM)',
    '空车时间(分钟)': 'Empty Time(Minute)',
    '重车里程(KM)': 'Heavy Mileage(KM)',
    '重车时间(分钟)': 'Heavy Time(Minute)',
    '时 间': 'Time',
    '时间': 'Time',
    '油量(升)': 'Oil(litre)',
    '变化类型': 'Change Type',
    '开始油量(升)': 'From Oil(litre)',
    '结束油量(升)': 'To Oil(litre)',
    '变化油量(升)': 'Change Oil(litre)',
    '类型': 'Type',
    '开始里程(公里)': 'Start Mileage(KM)',
    '行驶里程(公里)': 'Trip Mileage(KM)',
    '劫警': 'Rob',
    '盗警': 'Steal',
    '超速报警': 'High Speed',
    '低速报警': 'Low Speed',
    '疲劳驾驶': 'Tired',
    '进范围报警': 'Enter Geo Fence',
    '出范围报警': 'Leave Geo Fence',
    '超出线路报警': 'Over Road',
    '剪线报警': 'Cut Line',
    '油量上升异常报警': 'Oil rise exception',
    '油量异常下降报警': 'Oil decline exception',
    '天线断路报警': 'Antenna break',
    '搅拌时间过长': 'Long mixing',
    '非法时间卸料': 'Illegal Unloading',
    '其它': 'Others',
    '报警总数': 'Total Alarms',
    '单位': 'Company',
    '最高速度(KM/H)': 'MaxSpeed(KM/H)',
    '超速里程(KM)': 'OverSpeed(KM)',
    '开始位置': 'Start Place',
    '结束位置': 'End Place',
    '当日最高速度(KM/H)': 'Daily maximum speed',
    '超速总时长': 'OverSpeed Total Time',
    '当日超速次数': 'OverSpeed Times',
    '熄火': 'Acc off',
    '启动怠速': 'Idle',
    '启动行驶': 'Acc on',
    '总时长': 'Total Times',
    '第一次启动': 'First Start-up',
    '最后一次熄火': 'Last acc off',
    '第一次启动位置': 'First acc on place',
    '最后一次熄火位置': 'Last acc on place',
    'acc状态': 'Acc Status',
    '区域名称': 'Geo Fence Name',
    '进区域次数': 'Enter Fence Times',
    '出区域次数': 'Leave Fence Times',
    '进出总次数': 'Enter Or Leave Fence Times',
    '进出类型': 'Enter Or Leave',
    '当前油量(L)': 'Current Old(L)',
    '当前里程(KM)': 'Current Mileage(KM)',
    '出区': 'Leave Fence',
    '进区': 'Enter Fence',
    '上次出区时间': 'Last Leave Time',
    '进区时间': 'Enter Time',
    '出区时间': 'Leave Time',
    '途中时长': 'Duration',
    '途中里程(KM)': 'Mileage',
    '区内停留时间': 'Stop in fence',
    '起始点': 'Start Place',
    '到达点': 'End Place',
    '趟次数': 'Trip Count',
    '持续时间≥X分钟': 'Min Duration',
    '发送时间': 'Send Time',
    '信息内容': 'Send Message',
    '接收': 'Receive',
    '操作单位': 'Operate Name',
    '操作员': 'Operate User',
    '电量低于(单位%):': 'Max Battery(%)',
    '剩余电量': 'Remain Battery',
    '车主姓名': 'Vehicle Name',
    '车主电话': 'Owner Telephone',
    '当月最高速度(KM/H)': 'Monthly Max Speed',
    '当月超速次数': 'Monthly OverSpeed Count',
    '车辆编号': 'Vehicle Code',
    '全部': 'All',
    '加油': 'Fuel Charging',
    '异常': 'Exception',
    '是': 'Yes',
    '否': 'No',
    '全部类型': 'All Type',
    '拆机报警': 'Fall off Alarm',
    '低油量报警': 'Low Oil Alarm',
    '围栏报警': 'Fence exit',
    '电池低电压报警': 'Battery low voltage Alarm',
    '定位模块故障报警': 'Positioning module failure Alarm',
    '定位天线断路报警': 'Positioning antenna open circuit alarm',
    '定位天线短路报警': 'Positioning antenna short circuit alarm',
    '断电报警': 'Power failure alarm',
    '紧急报警': 'Emergency alarm',
    '进入二押点': 'Enter the second bet',
    '进围栏报警': 'Alarm when entering the fence',
    '开盖报警': 'Cover opening alarm',
    '碰撞报警': 'Collision warning',
    '疲劳驾驶报警': 'Fatigue driving alarm',
    '区域超速报警': 'Area overspeed alarm',
    '通讯受干扰报警': 'Communication interference alarm',
    '震动报警': 'Vibration alarm',
    '震动传感器故障': 'Vibration sensor failure',
    '主电低压报警': 'Device low pressure alarm',
    '低电压报警': 'Low voltage Alarm',
    '出省报警': 'Out of province',
    '油量异常上升报警': 'Oil Rise Exception',
    '非法卸料报警': 'Illegal Unloading',
    '超时未返回': 'Callback Timeout',
    '电瓶拆除报警': 'Battery dismantling',
    '天线短路报警': 'Antenna short circuit',
    'GSM模块故障': 'Failure of GSM Module',
    'GPS接收机故障': 'GPS Receiver Fault',
    '总线故障': 'Bus failure',
    '密码输入错误': 'Incorrect Password',
    '非法动车报警': 'Illgeal Move',
    '开箱报警': 'Open Alarm',
    '启动怠速 (速度=0)': 'Idle(speed=0)',
    '启动行驶 (速度>0)': 'Idle(speed>0)',
    '启动(速度>=0)': 'Run(speed>0)',
    '进区域': 'Enter Geo Fence',
    '出区域': 'Leave Geop Fence',
    '门开': 'Door open',
    '门关': 'Door close',
    '参考油耗': 'Reference fuel consumption',
    '参考百公里油耗': 'Reference to 100 km fuel consumption',
    '耗油量': 'Fuel consumption',
    '实际百公里油耗': 'Actual fuel consumption of 100 kilometers',
    '加油量': 'Fuel volume',
    '异常油量(升)': 'Abnormal oil volume (liter)',
    '参考耗油量': 'Reference fuel consumption',
    '变化油量': 'Variable oil volume',
    '开始时段': 'Start time',
    '结束时段': 'End time',
    '时段起始时间': 'From',
    '时段结束时间': 'To',
    '低电量报警': 'Low battery level alarm',
    '移位报警': 'Displacement alarm',
    '安装异常报警': 'Install error alarm',
    '滞留报警': 'Overstay alarm',
    '上线报警': 'Online alarm',
    '设防报警': 'Defence alarm',
    '侧翻报警':'Rollover alarm',
    '疲劳驾驶预警': 'Fatigue driving alarm',
    '二押点报警': 'High risk place',
    '低油量报警': 'Low fuel alarm',
    '超时停车报警': 'Timeout parking alarm',
    '出围栏报警': 'Out of geofence alarm',
    '1小时': '1 hour',
    '3小时': '3 hours',
    '6小时': '6 hours',
    '12小时': '12 hours',
    '1天': '1 day',
    '2天': '2 days',
    '3天': '3 days',
    '1周': '1 week',
    '2周': '2 weeks',
    '3周': '3 weeks',
    '1月': '1 month',
    '2月': '2 months',
    '3月': '3 months',
    '6月': '6 months',
    '1年': '1 year',
    '到期时间': 'Expire date',
    '已到期': 'be expired',
    '<3天': '<3 days',
    '<7天': '<7 days',
    '<15天': '<15 days',
    '<30天': '<30 days',
    '设备号': 'Device No',
    '名称': 'name',
    '颜色': 'color',
    '长': 'long',
    '宽': 'width',
    '高': 'high',
    '重量': 'weight',
    '设备状态': 'equipment status',
    '偏离线路报警': 'Deviation line alarm',
    '电话': 'phone',
    '年龄': 'age'
  },
  dict: {
    '小轿车': 'Car',
    '救护车': 'Ambulance',
    '大卡车': 'Pickup Truck ',
    '油罐车': 'Fuel tank car',
    '警车': 'Police car',
    '公交车': 'Bus',
    '工程车': 'Engineering vehicle',
    '环卫车': 'Sanitation truck',
    '旅游车': 'Touring car',
    '挖机': 'Excavator',
    '厢式车': 'Van',
    '混凝土搅拌车': 'Concrete mixer truck',
    '个人定位': 'Personal positioning',
    '其他': 'Others',
    '货车': 'Truck',
    '摩托车': 'Motorcycle',
    '渣土车': 'Muck truck',
    "RV": "RV",
    "Fifth Wheel": "Tow behind",
    "Trailer": "Trailer",
    "Marine": "Marine",
    "ATV": "ATV",
    "Pop Up Camper": "Pop Up Camper",
    "Skid Steer": "Skid Steer",
    "Lift": "Lift",
    "Generator": "Generator",
    "Closed Trailer": "Closed Trailer",
    "Snowmobile": "Snowmobile",
    "Semi Truck": "Semi Truck",
    "Arrow Sign": "Arrow Sign",
    "Message Board": "Message Board",
    "Toilet Trailer": "Toilet Trailer",
    "Porta Potty": "Porta Potty",
    "Crash Truck": "Crash Truck",
    "Roll off Bin": "Roll off Bin",
    "Diesel Plate": "Diesel Plate",
    "RTV": "RTV",
    "Speed Sign": "Speed Sign",
    "5th wheel": "5th wheel",
    "Single": "Single",
    '有线': 'GPS Tracker',
    '无线': 'Assets Tracker',
    '有线设备': 'Wiring GPS Tracker',
    '无线设备': 'Assets Tracker',
    '超级管理员': 'Super administrator',
    '普通管理员': 'Administrator',
    '普通帐号': 'User',
    '安装人员': 'Installer',
    '奥迪': 'Audi',
    'ALPINA': 'ALPINA',
    '宝马': 'BMW',
    '奔驰': 'Benz',
    '保时捷': 'Porsche',
    '宝沃': 'Baowo',
    '大众': 'Dazhong',
    'Smart': 'Smart',
    '西雅特': 'West Yate',
    '本田': 'Honda',
    '丰田': 'Toyota',
    '雷克萨斯': 'LEXUS',
    '铃木': 'Suzuki',
    '马自达': 'Mazda',
    '讴歌': 'Eulogize',
    '日产': 'Nissan',
    '斯巴鲁': 'Subaru',
    '三菱': 'Mitsubishi',
    '五十铃': 'Isuzu',
    '英菲尼迪': 'Infiniti',
    '起亚': 'KLA',
    '双龙': 'Shuanglong',
    '现代': 'Modern',
    '别克': 'Buick',
    '道奇': 'Dodge',
    '福特': 'Ford',
    'Faraday Future': 'Faraday Future',
    'GMC': 'GMC',
    'Jeep': 'Jeep',
    '凯迪拉克': 'Cadillac',
    '克莱斯勒': 'Chrysler',
    '林肯': 'Lincoln',
    '特斯拉': 'Tesla',
    '雪佛兰': 'Chevrolet',
    '标致': 'Beautiful',
    'DS': 'DS',
    '雷诺': 'Renault',
    '雪铁龙': 'Citroen',
    '阿斯顿·马丁': 'Aston',
    '宾利': 'Bentley',
    '捷豹': 'Jaguar',
    '路虎': 'Land Rover',
    '劳斯莱斯': 'Rolls-Royce',
    'MINI': 'MINI',
    '迈凯伦': 'Mclaren',
    '阿尔法·罗密欧': 'ALFAROMEO',
    '布加迪': 'Bugatti',
    '菲亚特': 'Fiat',
    '法拉利': 'Ferrari',
    '兰博基尼': 'Lamborghini',
    '玛莎拉蒂': 'Maserati',
    '依维柯': 'Iveco',
    'Polestar': 'Polestar',
    '沃尔沃': 'Volvo',
    '斯柯达': 'SKODA',
    'ARCFOX': 'ARCFOX',
    '宝骏': 'Bao Jun',
    '比亚迪': 'BYD',
    '奔腾': 'Pentium',
    '比速': 'Specific speed',
    '北汽绅宝': 'SAIC Saab',
    '北汽幻速': 'Beiqi magic speed',
    '北汽威旺': 'Beiqi Wei Wang',
    '北汽昌河': 'Beiqi Changhe',
    '北汽制造': 'BAW',
    '北汽道达': 'Beiqi Dao Da',
    '北汽新能源': 'Beiqi New Energy',
    '北京': 'Beijing',
    '长安汽车': 'CHANA',
    '长安欧尚': 'Auchan',
    '长安轻型车': 'CHANA Light',
    '长安跨越': 'CHANA Kuayue',
    '长城': 'Great Wall',
    '大乘汽车': 'Mahayana',
    '东风风度': 'East',
    '东风风光': 'East',
    '东风风神': 'East',
    '东风风行': 'East',
    '东风小康': 'East',
    '东风': 'East',
    '东南': 'Southeast',
    '电咖': 'Dianka',
    '福迪': 'Fudi',
    '福汽启腾': 'Fuqi Qi Teng',
    '福田': 'Fukuda',
    '广汽传祺': 'Guang Qi',
    '广汽新能源': 'Guang Qi',
    '观致': 'GuanZhi',
    '国金': 'GuoJin',
    '哈弗': 'Haff',
    '海马': 'Haima',
    '汉腾': 'HanTeng',
    '红旗': 'HongQi',
    '华泰': 'Huatai',
    '红星汽车': 'Hongxing',
    '黄海': 'HuangHai',
    '华骐': 'HuaQi',
    '华颂': 'HuaSong',
    '吉利': 'Auspicious',
    '江淮': 'JiangHuai',
    '捷途': 'JieTu',
    '江铃': 'JiangLing',
    '奇点汽车': 'QiDian',
    '金杯': 'JinBei',
    '金龙': 'JinLong',
    '九龙': 'JiuLong',
    '君马': 'JunMa',
    '凯翼': 'GaiYi',
    '开瑞': 'KaiRui',
    '卡升': 'KaSheng',
    '卡威': 'Carvey',
    '康迪全球鹰': 'Condi Global Hawk',
    '领克': 'Collar gram',
    '陆风': 'Land breeze',
    '力帆': 'Lifan',
    '猎豹': 'LieBao',
    '零跑汽车': 'LingPao',
    '领途汽车': 'LingTu',
    '理想智造': 'LiXiang',
    '名爵': 'MingJue',
    '纳智捷': 'Nazhijie',
    '欧尚汽车': 'Oushang',
    '欧拉': 'Oula',
    '奇瑞': 'Qirui',
    '启辰': 'Qichen',
    '前途': 'Qiantui',
    '庆铃': 'Qingling',
    '荣威': 'Rongwei',
    'SWM斯威': 'SWM',
    '思皓': 'Sihao',
    '上汽大通': 'Shangqi',
    '腾势': 'Tengshi',
    '五菱': 'Wuling',
    'WEY': 'WEY',
    '蔚来': 'Weilai',
    '潍柴英致': 'Weichai',
    '威马汽车': 'Weima',
    '小鹏汽车': 'Xiaopeng',
    '星驰': 'Xingchi',
    '新特汽车': 'Xinte',
    '驭胜': 'Qusheng',
    '野马': 'Yema',
    '一汽': 'FAW',
    '裕路': 'Yulu',
    '云度': 'Yundu',
    '云雀汽车': 'Yunque',
    '众泰': 'Zhongtai',
    '中华': 'Zhonghua',
    '知豆': 'Zhidou',
    '之诺': 'Zhinuo',
    '中兴': 'ZTE',
    '全部': 'All'
  },
  color: {
    '蓝色': 'Blue',
    '绿色': 'Green',
    '黄色': 'Yellow',
    '白色': 'White',
    '黑色': 'Black',
    'red': "Rouge",
    'orange': 'Orange',
    'grey': "Gris",
    'brown': "Brun",
    'pink': "Rose",
    'purple': "Mauve"
  },
  commandList: {
    '回传间隔': 'Return interval',
    '无线闹钟': 'Wireless alarm clock',
    '休眠时长': 'Dormancy duration',
    '追踪设置': 'Trace settings',
    '定位模式': 'Location mode',
    '锁车/解锁': 'Lock/Unlock',
    '拆机报警设置': 'Light sensor / Fall of alarm',
    '超速报警设置': 'Overspeed Alarm Setting',
    '里程参数设置': 'Mileage parameter setting',
    '设置功能开关': 'Switch setting',
    '其他设置': 'Other setting',
    '控制终端状态': 'Control device status',
    '设置低油量/油量异常报警阀值': 'Oil alarm setting',
    '设置号码': 'Phone number setting',
    '设置油量特征参数': 'Setting oil parameter',
    //'电话回拨': 'Remote monitor',
    '立即拍照': 'Take photo',
    '连续拍照': 'Continuois photo',
    '拍照间隔设置': 'Photo interval',
    '紧急抓拍设置': 'Emergency snapshot setting',
    '条件抓拍设置': 'Condition snapshot setting',
    '图像分辨率设置': 'Image resolution setting',
    '遥控车门锁': 'Remote door lock',
    '位移报警设置': 'Displacemeng alarm',
    '震动报警设置': 'Vibration alarm',
    '查询ICCID': 'Query ICCID',
    '远程升级': 'Remote Update',
    '高级指令': 'Advance command',
    '锁车解锁': 'Lock/Unlock',
    '设置通讯参数': 'Setting Communication Parameter',
    '设置接入参数': 'Setting Access Parameter',
    '位置汇报参数设置': 'Location setting',
    '终端通话设置': 'Terminal call setting',
    '终端电话拨打': 'Terminal telephone call',
    '图像/视频设置': 'Image/Vedio Setting',
    '报警开关设置': 'Alarm switch setting',
    '报警发送SMS开关': 'SMS setting',
    '报警拍摄开关': 'Alarm photo setting',
    '报警拍摄存储开关': 'Alarl photo save setting',
    '关键报警开关': 'Important Alarm setting',
    '报警参数设置': 'Alarm parameter setting',
    '超速参数设置': 'Overspeed parameter setting',
    '车辆基本资料设置': 'Basic Info setting',
    '电话本设置': 'Telephone book setting',
    '连接指定服务器': 'Server setting',
    '终端关机': 'Terminal close',
    '终端复位': 'Terminal reset',
    '恢复出厂设置': 'Restore the factory setting',
    '关闭数据通信': 'Close data comminication',
    '关闭所有无线通信': 'Close all network',
    '车辆控制': 'Device control',
    '临时位置跟踪控制': 'Tracking control',
    '终端唤醒': 'Terminal awake',
    '设置油量报警报警参数': 'Oil alarm setting',
    '设置油箱参数': 'Tank setting',
    '录音指令': 'Record setting',
    '单次点名': 'Query place',
    '设置ACC开发送数据间隔': 'Setting the data interval of ACC on',
    '设置ACC关发送数据间隔': 'Setting the data interval of ACC off',
    '设备重启': 'Device restart',
    '取消所有报警': 'Cancal all alarm',
    '里程清零': 'Mileage cleared',
    '升级': 'Updatae Softwire',
    '里程初始化': 'Mileage Setting',
    'MT01专用': 'MT01',
    '设置油量异常报警参数': 'Setting Oil Abnormal Alarm Parameters',
    '打开实时视频': 'Open live video',
    '停止实时视频': 'Stop real-time video',
    '运动静止回传间隔设置': 'Set the uploading interval in motion and static mode'
  },
  commandParams: {
    '选择操作': 'Select',
    '锁车': 'Lock car',
    '解锁': 'Unlock',
    '终端心跳时间间隔': 'Terminal Heart Rate Interval',
    '秒': 'Sec',
    '分钟': 'Minutes',
    '次': 'Times',
    '度': 'Degrees',
    '毫米': 'Millimeter',
    'TCP应答超时时间': 'TCP response timeout',
    'TCP重传次数': 'Number of TCP Retransmissions',
    'UDP应答超时时间': 'UDP response timeout',
    'UDP重传次数': 'Number of UDP Retransmissions',
    'SMS应答超时时间': 'SMS response timeout',
    'SMS重传次数': 'Number of SMS Retransmissions',
    '设置接入参数': 'Setting Access Parameters',
    '主服务器APN': 'Master Server APN',
    '拨号用户名': 'Dial-up username',
    '拨号密码': 'Dial up password',
    '主服务器IP': 'Master server IP',
    '备服务器APN': 'Second server APN',
    '备服务器IP': 'Second server IP',
    '服务器TCP端口': 'Server TCP Port',
    '服务器UDP端口': 'Server UDP port',
    '监控平台电话': 'Telephone of Monitoring Platform',
    '终端复位电话': 'Terminal reset telephone',
    '恢复出厂设置电话': 'Restore factory set-up telephone',
    '监控平台SMS电话': 'Monitoring Platform SMS Telephone',
    '接收终端SMS文本报警电话': 'Receiving Terminal SMS Text Alarm Phone',
    //'电话回拨号码': 'Monitor phone numbers',
    '监管平台特权短信号码': 'Privileged SMS Number of Supervisory Platform',
    '位置汇报策略': 'Location Reporting Strategy',
    '定时汇报': 'Timing reporting',
    '定距汇报': 'Distance reporting',
    '定时和定距汇报': 'Timing and distance reporting',
    '位置汇报方案': 'Location Reporting Scheme',
    '根据ACC状态': 'Depend on acc status',
    '根据登录状态和ACC状态': 'Depending on login status and ACC status',
    '缺省汇报时间间隔': 'Default reporting interval',
    '驾驶员未登录汇报时间间隔': 'Driver Unlogged Reporting Time Interval',
    '休眠汇报时间间隔': 'Hibernation reporting interval',
    '紧急报警汇报时间间隔': 'Emergency alarm reporting interval',
    '缺省汇报距离间隔': 'Default reporting distance interval',
    '驾驶员未登录汇报距离间隔': 'Driver Unlogged Reporting Distance Interval',
    '休眠汇报距离间隔': 'Hibernation Reporting Distance Interval',
    '紧急报警汇报距离间隔': 'Emergency alarm reporting distance interval',
    '拐点补传角度': 'Inflexion point supplementary transmission angle',
    '终端电话接听策略': 'Terminal Telephone Reception Strategy',
    '自动接听': 'Auto Answer',
    'ACC ON时自动接听': 'Auto Answer When ACC ON',
    '单次通话最长': 'Longest single call',
    '月通话最长': 'Longest monthly call',
    '终端电话拨打类型': 'Types of terminal telephone calls',
    '普通通话': 'Ordinary call',
    //'电话回拨': 'Monitor',
    '拨打号码': 'Dial number',
    '图像/视频质量': 'Image/Video Quality',
    '亮度': 'Brightness',
    '对比度': 'Contrast',
    '饱和度': 'Saturation',
    '色度': 'Chroma',
    '紧急报警': 'Emergency alarm',
    '关': 'Close',
    '开': 'Open',
    '超速报警': 'Speed Alarm',
    '疲劳驾驶报警': 'Fatigue driving alarm',
    '预警': 'Early warning',
    'GNSS模块故障': 'GNSS Module Alarm',
    'GNSS天线未接': 'GNSS Antenna not connected',
    'GNSS天线短路': 'GNSS Antenna short circuit',
    '终端主电源欠压': 'Undervoltage of terminal main power supply',
    '终端主电源掉电': 'Power failure of terminal main power supply',
    '终端LCD故障': 'Terminal LCD Fault',
    'TTS模块故障': 'TTS module failure',
    '摄像头故障': 'Camera malfunction',
    '当天累计驾驶超时': 'Cumulative driving overtime on that day',
    '超时停车': 'Overtime parking',
    '进出区域': 'Import and export area',
    '进出路线': 'Route of entry and exit',
    '路段行驶时间不足/过长': 'Insufficient/Excessive Driving Time in Section',
    '路线偏离报警': 'Alarm for route deviation',
    '车辆VSS故障': 'Vehicle VSS Fault',
    '车辆油量异常': 'Vehicle oil abnormality',
    '车辆被盗': 'Vehicle theft',
    '车辆非法点火': 'Illegal ignition of vehicles',
    '车辆非法位移': 'Illegal displacement of vehicles',
    '最高速度': 'Top speed',
    '超速持续时间': 'Overspeed duration',
    '连续驾驶最长时间': 'Continuous driving for the longest time',
    '当天累计驾驶最大时间': 'Cumulative maximum driving time for the day',
    '最小休息时间': 'Minimum rest time',
    '最大停车时间': 'Maximum parking time',
    '车辆里程表读数': 'Vehicle odometer readings',
    '所在省域ID': 'Provincial ID',
    '所在市域ID': 'City ID',
    '车牌号': 'License plate number',
    '车牌颜色': 'License plate color',
    '指令类型': 'Instruction type',
    '删除所有联系人': 'Delete All Contacts',
    '更新电话本': 'Update telephone book',
    '追加电话本': 'Additional telephone book',
    '修改电话本': 'Modification of telephone book',
    '标志': 'Sign',
    '呼入': 'In call',
    '呼出': 'Out call',
    '呼入/呼出': 'In/Out',
    '电话号码': 'Phone number',
    '联系人': 'Contacts',
    '连接控制': 'Connection control',
    '切换到指定监管平台服务器': 'Switch to the designated monitoring platform server',
    '切换回原缺省监管平台服务器': 'Switch back to the original default monitoring platform server',
    '鉴权码': 'Authentication code',
    '拨号点名称': 'Dial Point Name',
    'IP地址': 'IP address',
    'TCP端口': 'TCP Port',
    'UDP端口': 'UDP Port',
    '连接到指定服务器时限': 'Connecting to specified server time limit',
    '该指令无需参数': 'This instruction does not require parameters',
    '车门控制': 'Door control',
    '解锁车门': 'Unlocking door',
    '关闭车门': 'Close the door',
    '汇报间隔': 'Reporting interval',
    '跟踪有效期': 'Tracking expiry date',
    '低油量报警值': 'Low oil alarm value',
    '升': 'Litre',
    '油量异常下降报警阈值': 'Alarm threshold for abnormal drop of oil volume',
    '油量异常下降时间阈值': 'Threshold of abnormal oil decline time',
    '油箱1容积': 'Tank 1 Volume',
    '油箱1高度': 'Fuel Tank 1 Height',
    '油箱2容积': 'Fuel Tank 2 Volume',
    '油箱2高度': 'Fuel Tank 2 Height',
    '停止录音': 'Stop recording',
    '开始录音': 'Start recording',
    '录音时间': 'Recording time',
    '保存标志': 'Save flag',
    '实时长传': 'Real time long pass',
    '保存': 'Save',
    '音频采样率': 'Audio sampling rate',
    '请输入指令内容': 'Please enter the instruction content.',
    '时间间隔': 'Time interval',
    '端口': 'Port',
    '里程值': 'Mileage value',
    '回传间隔': 'Return interval',
    '启动回传间隔': 'Return interval(Acc on)',
    '熄火回传间隔': 'Return interval(Acc off)',
    '休眠回传间隔': 'Return interval(Sleep)',
    '回传间隔 (30-300秒)': 'gps sending interval(30-300 s)',
    '回传间隔 (5-300秒)': 'gps sending interval(5-300 s)',
    '持续时间 (5-57600分)': 'duration(5-57600 min)',
    '唤醒时间1': 'Wake-up time 1',
    '唤醒时间2': 'Wake-up time 2',
    '唤醒时间3': 'Wake-up time 3',
    '唤醒时间4': 'Wake-up time 4',
    '休眠时长': 'Dormancy duration',
    '打开': 'Open',
    '关闭': 'Close',
    '持续时间': 'Duration',
    '分': 'Min',
    '纯基站定位': 'Pure Base Station Location',
    '基站+GPS': 'Base station +GPS',
    '报警，追踪15分钟，间隔300秒': 'Alarm, follow-up 15 minutes, interval 300 seconds',
    '报警不追踪': 'Alarm not tracking',
    '清零': 'Zero clearing',
    '设置里程值': 'Setting Mileage Value',
    '省电关闭通信模块': 'Power saving shutdown communication module',
    '不关闭': 'Not shut down',
    '超速报警速度值0-200': 'Overspeed alarm speed value 0-200',
    '超速预警值': 'Overspeed warning value',
    '持续超速触发报警时间1-255,推荐5': 'Continuous overspeed trigger alarm time 1-255, recommendation 5',
    '低速报警速度值': 'Low speed alarm speed value',
    '持续低速触发报警时间1-65535,推荐600': 'Continuous low-speed trigger alarm time 1-65535, recommended 600',
    'ACC进入省电时间1-65535,推荐600': 'ACC Entry Power Saving Time 1-65535, Recommendation 600',
    '拐角补传角度,推荐30': 'Corner angle, recommend 30',
    '震动报警灵敏度': 'Sensitivity of Vibration Alarm',
    '最低': 'Minimum',
    '低': 'Low',
    '中': 'Medium',
    '高': 'High',
    '最高': 'Highest',
    '选择动作': 'Select',
    '重启终端': 'Restart terminal',
    '唤醒终端': 'Wake up terminal',
    '关闭终端': 'Closing terminal',
    '恢复出厂设置': 'Restore factory settings',
    '低油量报警阀值': 'Low oil alarm threshold',
    '油量异常上升报警阈值': 'Alarm threshold for abnormal rise of oil volume',
    '油量异常上升时间阈值': 'Threshold of abnormal rise time of oil volume',
    '中心号码1': 'Center Number 1',
    '中心号码2': 'Center number 2',
    '报警通知号码1': 'Alarm Notification Number 1',
    '报警通知号码2': 'Alarm Notification Number 2',
    //'电话回拨号码': 'Monitor number',
    '邮箱总容积0-999.9': 'Total mailbox volume 0-999.9',
    '空邮箱电压AD值0-9999': 'Air Mail Voltage AD Value 0-9999',
    '1/4邮箱油量电压AD值0-9999': '1/4 Mailbox Fuel Voltage AD Value 0-9999',
    '1/2邮箱油量电压AD值0-9999': '1/2 Mailbox Fuel Voltage AD Value 0-9999',
    '3/4邮箱油量电压AD值0-9999': '3/4 Mailbox Fuel Voltage AD Value 0-9999',
    '满邮箱油量电压AD值0-9999': 'Full Mailbox Fuel Voltage AD Value 0-9999',
    '拍照通路': 'Photo path',
    '第一路': 'Channel 1',
    '第二路': 'Channel 2',
    '第三路': 'Channel 3',
    '第四路': 'Channel 4',
    '抓拍间隔,最大65535': 'Capture interval, maximum 65535',
    '抓拍次数,最大65535': 'Number of snaps, maximum 65535',
    '设置紧急情况（盗警、劫警）下图像抓拍的时间间隔和次数': 'Set up the time interval and frequency of image capture under emergencies (robbery and robbery)',
    '图像抓拍时间间隔，最大255秒': 'Image capture interval, maximum 255 seconds',
    '抓拍间隔': 'Snapping interval',
    '抓拍次数': 'Snapping times',
    '设置状态改变情况下图像抓拍的时间间隔和次数': 'Set the time interval and number of image captures when the state changes',
    '拍照间隔': 'Photo interval',
    '触发条件': 'Trigger event',
    '开车门': 'Open the Car Door',
    '关车门': 'Closing door',
    'ACC点火': 'ACC ignition',
    'ACC熄火': 'ACC stall',
    '空车': 'Empty car',
    '重车': 'Heavy car',
    '进出范围': 'Range of entry and exit',
    '速度为0': 'The speed is 0.',
    '选择分辨率': 'Selective Resolution',
    '上锁': 'Lock up',
    '开锁': 'Unlock',
    '打开位移报警': 'Open Displacement Alarm',
    '关闭位移报警': 'Turn off displacement alarm',
    '位移半径，推荐300': 'Displacement radius, recommendation 300',
    '米': 'M',
    '仅平台': 'Platform only',
    '平台+短信': 'Platform + SMS',
    '平台+短信+电话': 'Platform + SMS + Telephone',
    '打开震动报警': 'Turn on the vibration alarm',
    '关闭震动报警': 'Turn off vibration alarm',
    '选择报警通知方式': 'Selection of alarm notification mode',
    '查询ICCID并自动更新SIM卡号，该指令无需参数': 'Inquire ICCID and update SIM card number automatically. No parameters are required for this instruction.',
    '升级': 'Upgrade',
    '停止': 'Stop',
    '服务器IP地址': 'Server IP address',
    '端口号': 'Port',
    '终端软件版本号': 'Terminal Software Version Number',
    '退出追踪': 'Exit tracking',
    'WIFI>LBS,GPS 关': 'WIFI>LBS,GPS OFF',
    '命令字': 'CMDKey',
    '消息体': 'Message',
    '运动回传间隔': 'Data uploading interval in motion',
    '静止回传间隔': 'Data uploading interval in static',
    '震动报警': 'Vibration alarm'
  }
}
