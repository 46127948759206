<template>
  <div class="brand">
    <el-popover
      :placement="placement"
      :title="title"
      trigger="click"
      :width="useIndex?527:475"
      :popper-class="useIndex ? 'brand-select userIndex elevation-m' : 'brand-select elevation-m'"
      :disabled="disabled"
      v-model="show">
      <div class="option-wrapper" :class="{useIndex: useIndex}">
        <div class="option" v-for="item in currentIndexList" :key="item[props.value]" @click="select(item[props.value], item[props.label])">
          <img :src="require(`../../assets/${folder}/${item[props.label]}.${extension}`)" :class="{'large': largeImage}" :style="size?{'width':size,'height':size}:{}"/>
          <span>{{transformName ? ($t('dict')[item[props.label]] || item[props.label]) : item[props.label] }}</span>
        </div>
      </div>
      <div class="brand-index" v-if="useIndex">
        <span v-for="k in alphabets.split('')" :key="k" :class="{active:k===activeIndex,disabled:!options[k]}" @click="changeIndex(k)">{{k}}</span>
      </div>
      <div class="active" slot="reference" v-if="disabled" :class="{'disabled': disabled}">
        <template v-if="!!current">
          <img :src="require(`../../assets/${folder}/${current[props.label]}.${extension}`)" />
          <span>{{transformName ? ($t('dict')[current[props.label]] || current[props.label]) : current[props.label]}}</span>
        </template>
      </div>
      <div class="active" slot="reference" v-if="!disabled">
        <span class="tip" v-if="!current">{{$t('vehicleDetail.clickToSelect')}}<i class="el-icon-caret-bottom" style="margin-left:0.5em;"></i></span>
        <select-box icon active v-else style="width:100%;">
          <img :src="require(`../../assets/${folder}/${current[props.label]}.${extension}`)" />
          <span>{{transformName ? ($t('dict')[current[props.label]] || current[props.label]) : current[props.label]}}</span>
        </select-box>
      </div>
    </el-popover>
  </div>
</template>

<script>
import SelectBox from '@/components/select-box'
export default {
  props: {
    size: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: Number,
      default: null
    },
    options: {
      type: [Array, Object],
      default: () => ([])
    },
    folder: {
      type: String,
      default: 'brand'
    },
    extension: {
      type: String,
      default: 'jpg'
    },
    props: {
      type: Object,
      default: () => ({
        label: 'label',
        value: 'value'
      })
    },
    placement: {
      type: String,
      default: 'bottom'
    },
    height: {
      type: Number,
      default: null
    },
    useIndex: {
      type: Boolean,
      default: false
    },
    largeImage: {
      type: Boolean,
      default: false
    },
    transformName: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeIndex: 'A',
      alphabets: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
      show: false,
      innerValue: this.value
    }
  },
  watch: {
    value() {
      this.innerValue = this.value
    }
  },
  computed: {
    currentIndexList() {
      return this.useIndex ? this.options[this.activeIndex] : this.options
    },
    current() {
      let list = []
      if (this.useIndex) {
        for (let k in this.options) {
          list.push(...this.options[k])
        }
      } else {
        list = this.options
      }
      const index = list.findIndex(k => k[this.props.value] == this.innerValue)
      if (index != -1) {
        return list[index]
      }
      return null
    }
  },
  methods: {
    select(val) {
      if (this.disabled) {
        return
      }
      this.$emit('input', val)
      this.innerValue = val
      this.show = false
    },
    changeIndex(item) {
      if (!this.options[item]) return
      this.activeIndex = item
    }
  },
  components: {
    SelectBox
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/variables-simple.scss";
@import "../../styles/mixin.scss";
.brand {
  max-height: 250px;
  @include scroll-bar-grey;
  .active {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
    // max-width: 215px;
    &:hover {
      cursor: pointer;
      background-color: #FAFAFA;
    }

    &.disabled:hover {
      cursor: default;
      background-color: transparent !important;
    }

    & ::v-deep .v-select-box {
      min-width: 215px;
      padding-left: 15px;
      border-radius: 2px;
    }

    img {
      height: 21px;
      margin-right: 4px;
    }
    span {
      display: block;
      flex-grow: 1;
    }

  }
  .tip {
    background-color: #FAFAFA;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    color: #c0c4cc;
    cursor: pointer;
    padding: 0 15px;
  }
}

</style>
<style lang="scss">
@import "../../styles/variables-simple.scss";
@import "../../styles/mixin.scss";
.brand-select {
  &.userIndex {
    padding-bottom: 40px;
    height: 250px;
    overflow: auto;
  }

  .option-wrapper {
    &.useIndex {
      max-height: 190px;
      overflow-y: auto;
      @include scroll-bar-grey;
    }

    .option {
      display: inline-flex;
      align-items: center;
      //float: left;
      width: 143px;
      margin-bottom: 8px;
      transition: all 0.3s ease;
      padding: 4px 8px;
      border: 1px solid transparent;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      &:hover {
        cursor: pointer;
        background-color: #FAFAFA;
        border-radius: 2px;
        border: 1px solid #617895;
      }

      img {
        height: 21px;
        margin-right: 4px;
        &.large {
          height: 40px;
        }
      }
      span {
        width: 100px;
      }
    }
  }


  .brand-index {
    position: absolute;
    left: 0;
    bottom: 10px;
    width: 100%;
    text-align: center;
    user-select: none;
    span {
      margin: 0 3px;
      cursor: pointer;
      color: $--color-primary;
      &.active {
        cursor: default;
        color: inherit;
      }
      &.disabled {
        color: #E0E0E0;
        cursor: default;
      }
    }
  }
}

.v-select-box.active {
  border-color: #617895 !important;
}
</style>
