<template>
  <!-- <div class="wrapper" v-if="false">
    <div class="area">
      <div class="area--right">
        <el-row class="imb-3">
          <el-col :span="8">
            <span class="v-label">车牌号码:</span>
            <el-input class="v-input" disabled :value="vehicleInfo.VehicleName"></el-input>
          </el-col>
          <el-col :span="8">
            <span class="v-label">设备ID:</span>
            <el-input class="v-input" disabled v-model="device.SIM2"></el-input>
          </el-col>
          <el-col :span="8">
            <span class="v-label">安装时间:</span>
            <el-date-picker type="datetime" class="v-input" placeholder="选择安装时间" v-model="device.InstallTime"></el-date-picker>
          </el-col>
        </el-row>
        <el-row class="imb-3">
          <el-col :span="8">
            <span class="v-label">安装人员:</span>
            <div style="max-width:215px;display:inline-block;">
              <el-select v-model="device.InstallPeopleID" size="medium">
                <el-option v-for="item in skillList" :label="item.PeopleName" :value="item.InstallPeopleID" :key="item.InstallPeopleID"></el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="16">
          </el-col>
        </el-row>
        <el-row class="imb-3">
          <el-col :span="8">
            <span class="v-label">安装位置:</span>
            <div style="display:inline-block;max-width:215px;vertical-align:top;">
              <span class="v-radio--span"><el-radio v-model="device.InstallPlace" label="电瓶"></el-radio></span>
              <span class="v-radio--span"><el-radio v-model="device.InstallPlace" label="副驾驶杂物箱"></el-radio></span>
              <span class="v-radio--span"><el-radio v-model="device.InstallPlace" label="左侧踏板"></el-radio></span>
              <span class="v-radio--span"><el-radio v-model="device.InstallPlace" label="右侧踏板"></el-radio></span>
              <span class="v-radio--span"><el-radio v-model="device.InstallPlace" label="方向盘下面"></el-radio></span>
              <span class="v-radio--span"><el-radio v-model="device.InstallPlace" label="前顶灯"></el-radio></span>
              <span class="v-radio--span"><el-radio v-model="device.InstallPlace" label="后顶灯"></el-radio></span>
              <span class="v-radio--span"><el-radio v-model="device.InstallPlace" label="后备箱"></el-radio></span>
              <span class="v-radio--span"><el-radio v-model="device.InstallPlace" label="其他"></el-radio></span>
            </div>
          </el-col>
          <el-col :span="8">
            <span class="v-label"></span>
            <el-input class="v-input" v-if="false" placeholder="请填写安装位置"></el-input>
          </el-col>
        </el-row>
        <el-row class="imb-3">
          <el-col :span="16">
            <span class="v-label">安装照片:</span>
            <a v-if="imageUrl" :href="imageUrl" target="_blank">
              <img v-if="imageUrl" :src="imageUrl" class="avatar isview" />
            </a>
            <el-upload
              class="avatar-uploader"
              :class="{'is-button':!!imageUrl}"
              :action="`${POST_URL}?loginKey=${loginInfo.loginKey}&userid=${loginInfo.UserID}&type=image`"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload">
              <el-button v-if="imageUrl" plain>更换</el-button>
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-col>
          <el-col :span="8">
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="16">
            <span class="v-label">备注：</span>
            <el-input class="v-input" type="textarea" v-model="device.Remark" :rows="2"></el-input>
          </el-col>
          <el-col :span="8"></el-col>
        </el-row>
      </div>
    </div>
  </div> -->
  <el-form ref="form" :rules="rules" :model="device" label-width="6em">
    <el-row>
      <el-col :span="12"><el-form-item :label="$t('installInfo.vehicleNumber')">{{ vehicleInfo.VehicleName }}</el-form-item></el-col>
      <el-col :span="12"><el-form-item :label="$t('installInfo.deviceID')">{{ device.SIM2 }}</el-form-item></el-col>
    </el-row>
    <el-row>
      <el-col :span="12"><el-form-item :label="$t('installInfo.installTime')" prop="InstallTime"><el-date-picker type="datetime" class="v-input" placeholder="选择安装时间" v-model="device.InstallTime"></el-date-picker></el-form-item></el-col>
      <el-col :span="12">
        <!-- v-if="loginInfo.allFuns.includes(20324)" -->
        <el-form-item :label="$t('installInfo.installPeople')" prop="InstallPeopleID">
          <el-select v-model="device.InstallPeopleID" size="medium" clearable filterable>
            <el-option v-for="item in skillList" :label="item.PeopleName" :value="item.InstallPeopleID" :key="item.InstallPeopleID"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-form-item :label="$t('installInfo.installPlace')" prop="InstallPosition">
          <!-- <div style="display:inline-block;max-width:215px;vertical-align:top;margin-bottom:12px;">
            <span class="v-radio--span"><el-radio v-model="device.InstallPosition" :label="$t('installInfo.dianping')"></el-radio></span>
            <span class="v-radio--span"><el-radio v-model="device.InstallPosition" :label="$t('installInfo.fujiashi')"></el-radio></span>
            <span class="v-radio--span"><el-radio v-model="device.InstallPosition" :label="$t('installInfo.zuocetaban')"></el-radio></span>
            <span class="v-radio--span"><el-radio v-model="device.InstallPosition" :label="$t('installInfo.youcetaban')"></el-radio></span>
            <span class="v-radio--span"><el-radio v-model="device.InstallPosition" :label="$t('installInfo.fangxiangpan')"></el-radio></span>
            <span class="v-radio--span"><el-radio v-model="device.InstallPosition" :label="$t('installInfo.qiandingdeng')"></el-radio></span>
            <span class="v-radio--span"><el-radio v-model="device.InstallPosition" :label="$t('installInfo.houdingdeng')"></el-radio></span>
            <span class="v-radio--span"><el-radio v-model="device.InstallPosition" :label="$t('installInfo.houbeixiang')"></el-radio></span>
            <span class="v-radio--span"><el-radio v-model="device.InstallPosition" :label="$t('installInfo.qita')"></el-radio></span>
          </div> -->
          <!-- <el-input   v-model="device.InstallPosition" :placeholder="$t('installInfo.installPositionNumber')"></el-input> -->
          <el-select class="imb-1" style="max-width:215px;" v-model="device.InstallPosition">
            <el-option v-for="(k,index) in places" :key="index" :label="k" :value="k"></el-option>
          </el-select>
          <div>
            <router-link tag="a" target="_blank" :to="`/image?src=${imgSrc}`">
              <img :src="imgSrc" style="max-width:460px;border:1px solid #DDD;cursor:pointer;" />
            </router-link>
          </div>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item :label="$t('installInfo.installPhoto')">
          <!-- <a v-if="imageUrl" :href="imageUrl" target="_blank">
            <img v-if="imageUrl" :src="imageUrl" class="avatar isview" /><br/>
          </a>
          <el-upload
            class="avatar-uploader"
            :class="{'is-button':!!imageUrl}"
            :action="`${POST_URL}?loginKey=${loginInfo.loginKey}&userid=${loginInfo.UserID}&type=image`"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload">
            <el-button v-if="imageUrl" style="margin-left:60px;margin-top:8px;" plain>{{$t('installInfo.change')}}</el-button>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload> -->

          <el-upload
            multiple
            class="multi-uploader"
            accept="image/png, image/jpeg"
            :action="`${POST_URL}?loginKey=${loginInfo.loginKey}&userid=${loginInfo.UserID}&type=image`"
            list-type="picture-card"
            :file-list="imageNameList"
            :limit="9"
            :on-remove="handleRemove"
            :on-preview="handlePreview"
            :on-success="handleAvatarSuccessMultiple"
            :on-exceed="handleExceed"
            auto-upload>
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-form-item :label="$t('installInfo.remark')"><el-input class="v-input" type="textarea" v-model="device.Remark" :rows="2" maxlength="127"></el-input></el-form-item>
      </el-col>
      <el-col :span="12">

      </el-col>
    </el-row>
    <el-row type="flex" justify="center" align="middle">
      <el-button @click="cancel" class="customStyle" plain type="text" size="medium">{{$t('common.cancel')}}</el-button>
      <el-button @click="okHandler" class="customStyle border" plain type="primary" size="medium">{{$t('common.save')}}</el-button>
    </el-row>
  </el-form>
</template>

<script>
import mixin from './mixin-detail'
import {Query as QuerySkillAPI} from '@/api/skillman'
import {AddInstallInfo} from '@/api/device'
import { POST_URL,BASE_URL,DOWNLOAD_URL } from '@/api/config'
let url  = window.location.href
export default {
  name:'install-detail',
  props: {
    itemId: {
      default: null
    },
    vehicleId: {
      default: null
    },
    vehicleInfo: {
      type: Object,
      default: () => ({})
    },
    deviceInfo: {
      type: Object,
      default: () => ({})
    }
  },
  mixins: [mixin],
  data() {
    return {
      POST_URL,
      BASE_URL,
      device: this.deviceInfo,
      skillList: [],
      imageUrl: null,
      file: null,
      serverPath: '',
      rules: {
        InstallTime: [ {required: true,message:this.$t('installInfo.pleaseSelectDate'),trigger: 'blur'} ],
        InstallPeopleID: [ {required: true,message:this.$t('installInfo.pleaseSelectPeople'),trigger: 'blur'} ],
        InstallPosition: [ {required: true,message:this.$t('installInfo.pleaseSelectPosition'),trigger: 'blur'} ]
      },
      imageNameList: [],
      imgSrc: `${DOWNLOAD_URL}/static/image/installpositionv2.png`,
      places: [
        '1.排挡右侧',
        '2.前仪表盘',
        '3.导航后',
        '4.三角板',
        '5.挡风玻璃',
        '6.前门左边压条',
        '7.(左)升降开关',
        '8.后门左压条',
        '9.后挡风玻璃下方装饰板下',
        '10.后备箱(左右)',
        '11.油箱盖',
        '12.后门右边压条',
        '13.(右)升降开关',
        '14.前门右边压条',
        '15.右三角板',
        '16.杂物箱后',
        '17.前保险杠',
        '18.左右叶子板',
        '19.A柱',
        '20.OBD',
        '21.左侧门板',
        '22.B柱',
        '23.C柱',
        '24.后保险杠',
        '25.后座地毯下',
        '26.主驾驶座椅下',
        '27.门板杂物箱',
        '28.座椅皮套内',
        '29.排挡内',
        '30.副驾杂物箱',
        '31.外循环出风口'
      ],
      href:(url.search('juruiyun')>0)?'http://gps.juruiyun.com':'http://www.365qczx.com'
    }
  },
  watch: {
    deviceInfo() {
      this.initData()
    }
  },
  created() {
    this.initData()
    this.getSkillMan()
  },
  methods: {
    async okHandler() {
      try{
        await this.$refs.form.validate()
      }catch{
        return
      }

      const item = Object.assign(this.device, {
        // PhotoName: this.serverPath
        PhotoName: this.imageNameList.map(k => k.response).join('|')
      })

      item.InstallPeopleID = item.InstallPeopleID || 0;

      if (!item.InstallTime) {
        this.$alert(this.$t('installInfo.pleaseSelectDate'))
        return
      }
      if (!item.PhotoName) {
        this.$alert(this.$t('installInfo.pleaseUploadPhoto'))
        return
      }
      AddInstallInfo(item).then(ret => {
        this.device = ret.data
        this.$emit('confirm-install', this.device)
      })
    },
    initData() {
      let url = this.href+'/api/posts/image.ashx';
      let url2 = this.href+'/upload/image/';
      this.device = this.deviceInfo
      this.device.InstallTime = this.device.InstallTime ? this.device.InstallTime : new Date()
      this.device.InstallPeopleID = this.device.InstallPeopleID === 0 ? null : this.device.InstallPeopleID

      if (this.device.PhotoName) {
        this.imageUrl = `${url2}${this.device.PhotoName}`

        this.imageNameList = this.device.PhotoName.split('|').filter(k => !!k).map(k => ({
          name: k,
          response: k,
          // url_l: `${this.$IMAGE_URL_PREFIX}${k}`,
          // url: `${this.$IMAGE_URL_PREFIX_M}?name=${k}&size=140`
          url_l: `${url2}${k}`,
          url: `${url}?name=${k}&size=140`
          // ${url}
        }))
      } else {
        this.imageNameList = []
      }

      this.serverPath = this.device.PhotoName
    },
    cancel() {
      this.$emit('cancel-install')
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
      this.file = file
      this.serverPath = res
    },
    beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg';
        const isPNG = file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isJPG && !isPNG) {
          this.$message.error(this.$t('installInfo.errorTip1'));
        }
        if (!isLt2M) {
          this.$message.error(this.$t('installInfo.errorTip2'));
        }
        return (isJPG || isPNG) && isLt2M;
    },
    getSkillMan() {
      QuerySkillAPI(1, 1000, '', '', [], null, true).then(ret => {
        this.skillList = ret.data.list.slice()
      })
    },
    handleRemove(file, fileList) {
      this.imageNameList = fileList.slice()
    },
    handlePreview(file) {
      const page = this.$router.resolve(`/image?src=${file.url_l}`)
      window.open('./' + page.href)
    },
    handleAvatarSuccessMultiple(res, file, fileList) {
      this.imageNameList = fileList.slice()
    },
    handleExceed(files, fileList) {
      this.$message({
        type: 'error',
        message: this.$t('installInfo.exceedTip')
      })
      console.log(files, fileList)
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/mixin.scss";
.v-label {
  text-align: right;
  width: 100px;
  display: inline-block;
  margin-right: 12px;
  font-size: 14px;
}
.v-input {
  max-width: 215px;
  display: inline-block;
  font-size: 14px;
  & ::v-deep .el-input {
    color: #909399;
  }
  &.el-textarea {
    vertical-align: top;
  }
}
.v-radio--span {
  min-width: 100px;
  display: inline-block;
  margin-bottom: 4px;
}
.wrapper {
  @include scroll-bar;
  height: 100%;
  overflow-y: auto;
  padding: 24px;
  background-color: #F0F2F5;
  .area {
    background-color: #FFF;
    display: flex;
    align-items: stretch;

    .area--left {
      width: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #409EFF;
      color: #FFF;
      span {
        width: 14px;
        font-size: 14px;
      }
    }

    .area--right {
      padding: 48px 32px;
      flex-grow: 1;
    }
  }
}

  .avatar-uploader{
    display: inline-block;
    vertical-align: top;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    &.is-button {
      border-color: transparent;
    }
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;

  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
    &.isview {
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      display: inline-block;
      vertical-align: top;
      //margin-right: 16px;
    }
  }

  .multi-uploader {
    & ::v-deep {
      .el-upload-list__item {
        max-height: 100px;
        max-width: 100px;
        text-align: center;
        border-radius: 0;
        border: 0;
        transition: none !important;
        float: left;
      }
      .el-upload-list__item-status-label {
        display: none !important;
      }
      img {
        max-height: 100px;
        max-width: 100px;
        width: auto;
        height: auto;
      }

      > .el-upload {
        width: 100px;
        height: 100px;
        line-height: 100px;
      }
      .el-icon-close-tip {
        display: none;
        visibility: hidden;
      }
    }
  }
</style>

