<template>
  <div class="v-select-box" :class="{active: active}" @click="() => $emit('click')">
    <slot></slot>
    <i class="el-icon-arrow-down"></i>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    },
    icon: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.v-select-box {
  border: 1px solid #DCDFE6;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  i {
    visibility: hidden;
  }
  &.active {
    i {
      visibility: visible;
      position: absolute;
      right: 10px;
      bottom: 9px;
      z-index: 1;
      font-size: 14px;
      color: #c0c4cc;
      transform: rotateZ(0);
    }
  }

}
</style>
