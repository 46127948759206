import {mapGetters,mapMutations} from 'vuex'
import TreeUser from '@/views/common/tree-user'
let common = {
  data() {
    return {
      mountedFlag: false
    }
  },
  components: {
    TreeUser
  },
  computed: {
    ...mapGetters([
      'loginInfo',
      'currentHoldId',
      'currentHoldName',
      'currentHasChild'
    ])
  },
  methods: {
    print(type, msg, time = 10000) {
      if (!msg) {
        return
      }
      this.$message({
        showClose: true,
        message: msg,
        type,
        duration: time
      })
    },
    ...mapMutations({
      setCurrentHoldId: 'SET_CURRENT_HOLD_ID',
      setCurrentHoldName: 'SET_CURRENT_HOLD_NAME'
    })
  },
  filters: {
    emptyFormat: function (value) {
      if (!value) return '--'
      return value
    }
  }
}

export default common
