import '@/common/lowVersionCheck'
import 'babel-polyfill'
import 'es6-promise/auto'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'normalize.css/normalize.css'
import 'animate.css'
import ElementUI from 'element-ui'
import '@/styles/index.scss'
import i18n from './lang'
import store from './store'
import DataPanel from '@/components/data-panel/data-panel.js'
import infoWindow from '@/components/info-window/index.js'
import IIcon from '@/components/i-icon'
import MainWrapper from '@/components/main-wrapper'
import Space from '@/components/space'
import splitPane from '@/components/split-pane'
import ToolTip from '@/views/common/tool-tip'
import { AMAP_URL,GOOGLE_URL,GOOGLE_URL_ZH,GOOGLE_URL_EN,BMAP_URL, BMAP_URL_GL,AMAP_URL_loca,AMAP_URL_GL} from '@/common/config'
import { DEFAULT_IMAGE_URL_PREFIX } from '@/api/config'
import Directives from '@/directives'
import VehicleName from '@/views/common/vehicle-name'
import VTooltip from 'v-tooltip'
import HeaderSimple from '@/views/common/header-simple'
// import LightTimeline from '@/components/time-line'
import DialogWrapper from '@/views/dialog/dialog-wrapper'
const SpanAddress = require('@/views/common/span-address.fk').default

Vue.config.productionTip = false

Vue.use(VTooltip)
Vue.use(DataPanel)
Vue.use(infoWindow)
Vue.use(Directives)
// Vue.use(LightTimeline)
Vue.use(ElementUI, {
  // size: 'small',
  i18n: (key, value) => i18n.t(key, value)
})

Vue.prototype['$AMAP_URL'] = AMAP_URL
Vue.prototype['$AMAP_URL_GL'] = AMAP_URL_GL
Vue.prototype['$AMAP_URL_loca'] = AMAP_URL_loca
Vue.prototype['$GOOGLE_URL'] = GOOGLE_URL
Vue.prototype['$GOOGLE_URL_ZH'] = GOOGLE_URL_ZH
Vue.prototype['$GOOGLE_URL_EN'] = GOOGLE_URL_EN
Vue.prototype['$BMAP_URL'] = BMAP_URL
Vue.prototype['$BMAP_URL_GL'] = BMAP_URL_GL
Vue.prototype['$IMAGE_URL_PREFIX'] = DEFAULT_IMAGE_URL_PREFIX
Vue.prototype['$IMAGE_URL_PREFIX_M'] = DEFAULT_IMAGE_URL_PREFIX.replace('upload/image/', 'api/posts/image.ashx')

Vue.component('i-icon', IIcon)
Vue.component('split-pane', splitPane)
Vue.component('space', Space)
Vue.component('main-wrapper', MainWrapper)
Vue.component('tool-tip', ToolTip)
Vue.component('span-address', SpanAddress)
Vue.component('vehicle-name', VehicleName)
Vue.component('header-simple', HeaderSimple)
Vue.component('dialog-wrapper', DialogWrapper)

Vue.prototype.$bus = (new Vue())

// 格式yyyyMMddHHmmss
window.compileVersion = '20210129160000'
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

