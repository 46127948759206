<template>
  <el-dialog :title="confirmPrintFlag?$t('favorite.detail'):$t('favorite.name')" @closed="closed" class="riskList" :custom-class="fullScreen?'':'v-dialog v-dialog--md v-dialog--mdpadding'" :visible.sync="visible" width="920px" :fullscreen="fullScreen" append-to-body>
    <div style="margin-bottom:8px;text-align:right;" v-if="!onPrintMode">
      <el-button class="customStyle border" type="primary" plain size="medium" v-ripple @click="clearTask">{{$t('favorite.empty')}}</el-button>
      <el-button class="customStyle border" type="primary" plain size="medium" v-ripple @click="exportExcel">{{$t('favorite.export')}}</el-button>
      <el-button class="customStyle border" type="primary" plain size="medium" v-ripple @click="print" v-if="false">{{$t('favorite.print')}}</el-button>
      <el-button class="customStyle border" type="primary" plain size="medium" v-ripple @click="cancelPrint" v-if="fullScreen">{{$t('common.cancel')}}</el-button>
      <el-button class="customStyle border" type="primary" plain size="medium" v-ripple @click="momitorAll">{{$t('favorite.monitor')}}</el-button>
    </div>
    <div style="margin-bottom:8px;text-align:right;" v-if="onPrintMode && !confirmPrintFlag">
      <el-button class="customStyle border" type="primary" plain size="medium" v-ripple @click="confirmPrint" v-if="onPrintMode">{{$t('favorite.print')}}</el-button>
      <el-button class="customStyle border" type="primary" plain size="medium" v-ripple @click="cancelPrint" v-if="fullScreen">{{$t('common.cancel')}}</el-button>
    </div>
    <el-form label-width="3em" v-if="onPrintMode">
      <el-form-item :label="$t('favorite.time')">
        {{miment().format('YYYY-MM-DD hh:mm:ss')}}
      </el-form-item>
      <el-form-item :label="$t('favorite.remark')">
        <el-input type="textarea" :class="{'hignlightInput': confirmPrintFlag}"></el-input>
      </el-form-item>
    </el-form>
    <el-table class="v-table__customStyle" :class="{'hignlightInput': confirmPrintFlag}" :data="list" ref="table" :height="fullScreen ? '-webkit-fill-available' : '488px'" v-loading="tableLoading">
      <el-table-column type="index" width="50px" :index="i => i+1"></el-table-column>
      <el-table-column prop="VehicleName" :label="$t('favorite.vehicleName')">
        <template slot-scope="{row}">
          <p :title="$t('favorite.vehicleName')"> 
            <template v-if="row.TypeID===0">
              <span class="iconType"><i-icon name="iconxinxiguanli_cheliang_weixuanzhong" :class="{'online': row.isOnline}"></i-icon></span>
            </template>
            <template v-if="row.TypeID===1">
              <span class="iconType"><i-icon name="iconxinxiguanli_renyuan_xuanzhong" :class="{'online': row.isOnline}"></i-icon></span>
            </template>
            <template v-if="row.TypeID===2">
              <span class="iconType"><i-icon name="iconxinxiguanli_wupin_xuanzhong" :class="{'online': row.isOnline}"></i-icon></span>
            </template>
            <!-- <i-icon name="icon-car" style="font-size: 16px;" :class="{'online': row.isOnline}"/>  -->
            
            
            {{row.VehicleName}}</p>
          <p v-if="row.PeopleName" class="secondary--text" :title="$t('favorite.peopleName')">{{row.PeopleName}}</p>
        </template>
      </el-table-column>
      <el-table-column prop="SIM2" :label="$t('favorite.deviceID')">
        <template slot-scope="{row}">
          <p :title="$t('favorite.deviceID')">{{row.SIM2||'--'}}</p>
          <p class="secondary--text nowrap" :title="`${row.TrackerType!=0? $t('favorite.batteryTip1', {number: row.Battery}):''}`">{{row.TrackerType==0?$t('favorite.onlineDevice'):$t('favorite.nolineDevice')}}<span style="" v-if=" row.TrackerType!=0 && !!row.Battery">，{{$t('favorite.battery')}}:{{row.Battery}}%</span></p>
        </template>
      </el-table-column>
      <el-table-column prop="isAlarm" :label="$t('favorite.alarm')">
        <template slot-scope="{row}">
          <p>{{row.isAlarm?$t('favorite.isAlarm'):'--'}}</p>
          <p class="secondary--text" :title="joinArray(row.AlarmTypes)">{{joinArray(row.AlarmTypes)}}</p>
        </template>
      </el-table-column>
      <el-table-column prop="StatusDes" :label="$t('favorite.statusdes')" class-name="stateRow" show-overflow-tooltip></el-table-column>
      <!-- <el-table-column :label="$t('favorite.riskStatus')">
        <template slot-scope="{row}">
          <p v-if="getRiskScore(row) >= 60" style="color:#cc3333">{{$t('favorite.highRisk')}}</p>
          <p v-if="getRiskScore(row) >= 40" style="color:#F9A825">{{$t('favorite.middleRisk')}}</p>
          <p v-else style="color:#2E7D32">{{$t('favorite.lowRisk')}}</p>
        </template>
      </el-table-column> -->
      <el-table-column :label="$t('favorite.operation')" v-if="!onPrintMode">
        <template slot-scope="{row}">
          <span class="i-link" @click="monitor(row)">{{$t('favorite.monitor')}}</span>
          <span class="i-link" @click="remove(row)">{{$t('favorite.remove')}}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('favorite.remark')" v-else>
        <el-input slot-scope="{row}"></el-input>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      class="v-pager"
      :page-size="200"
      :pager-count="11"
      :current-page.sync="pageIndex"
      layout="total"
      :total="list.length"
      @current-change="pageCurrentChange"
      @size-change="pageSizeChange"
      popper-class="custom">
    </el-pagination>
  </el-dialog>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { QueryTaskDataByObjectIDs, ExportTaskDataByObjectIDs, DeleteStar, DeleteAllStar,QueryFocusDeviceList, ExportFocusDeviceList} from '@/api/common'
import { saveBlob } from '@/common/utils'
import miment from 'miment'

export default {
  data() {
    return {
      list: [],
      visible: false,
      visibleReal: false,
      tableLoading: false,
      pageIndex: 1,
      fullScreen: false,
      onPrintMode: false,
      confirmPrintFlag: false
    }
  },
  computed: {
    ...mapGetters([
      'taskList'
    ])
  },
  methods: {
    open() {
      this.visible = true
      this.visibleReal = true
      if (!this.taskList.length) {
        this.list.splice(0)
        return
      }
      this.tableLoading = true
      // QueryTaskDataByObjectIDs(this.taskList).then(ret => {
      //   this.tableLoading = false
      //   this.list = ret.data
      // })
      QueryFocusDeviceList().then(ret => {
        this.tableLoading = false
        this.list = ret.data
      })
    },
    close() {
      this.visible = false
    },
    closed() {
      this.visibleReal = false
    },
    clearTask() {
      this.$confirm(this.$t('favorite.text1'), this.$t('favorite.tip'), {
        type: 'warning'
      }).then(() => {
        this.delAllTaskCar()
        DeleteAllStar()
        this.visible = false
      })
    },
    pageCurrentChange() {

    },
    pageSizeChange() {

    },
    print() {
      this.fullScreen = true
      this.onPrintMode = true
      this.$refs.table.doLayout()
      // setTimeout(() => {
      //   window.print()
      // }, 25)
    },
    confirmPrint() {
      this.confirmPrintFlag = true
      setTimeout(() => {
        window.print()
      }, 25)
      setTimeout(() => {
        this.confirmPrintFlag = false
      }, 500)
    },
    cancelPrint() {
      this.fullScreen = false
      this.onPrintMode = false
    },
    momitorAll() {
      this.$router.push('/online/monitor?action=addTaskList')
      this.visible = false
    },
    monitor(row) {
      this.$router.push(`/online/monitor?action=addTaskList&objectid=${row.ObjectID}`)
      this.visible = false
    },
    exportExcel() {
      // ExportTaskDataByObjectIDs(this.taskList).then(ret => {
      //   saveBlob(ret.data, `${this.$t('favorite.excelName')}_${miment().format('YYYYMMDD')}.xls`)
      // })
       ExportFocusDeviceList(this.taskList).then(ret => {
        saveBlob(ret.data, `${this.$t('favorite.excelName')}_${miment().format('YYYYMMDD')}.xlsx`)
      })
    },
    remove(row) {
      this.$confirm(this.$t('favorite.removeOne', { name: row.VehicleName }), this.$t('favorite.tip'), {
        type: 'warning'
      }).then(() => {
        this.delTaskCar(row.ObjectID)
        DeleteStar(row.VehicleID,row.TypeID)
        const index = this.list.findIndex(k => k.ObjectID == row.ObjectID)
        this.list.splice(index, 1)
      }).catch(() => {})
    },
    getRiskScore(row) {
      let i = 0
      for (let x in row.ActiveRisks) {
        if (row.ActiveRisks[x] && this.nameCollection[x]) {
          i++
        }
      }
      return i * 10
    },
    joinArray(arr) {
      if (!arr) {
        return ''
      }
      return arr.join(',')
    },
    ...mapMutations({
      'delTaskCar': 'DEL_TASK_CAR',
      'delAllTaskCar': 'DEL_ALL_TASK_CAR'
    })
  },
  created() {
    this.nameCollection = {
      isOffLineGpsTracker: '有线设备离线',
      isRemovedGpsTracker: '有线设备拆除',
      isSeparatedVehicle: '设备分离',
      isNoGpsSingleVehicle: '在线不定位',
      HasTrackEvent: '风控记录',
      isOutOfProvince: '出省报警',
      isOnRegionAlarm: '围栏报警',
      isStayLongTime: '停车超时 > 3天'
    }

    this.miment = miment
  }
}
</script>

<style lang="scss" scoped>
@import "../../../styles/variables-simple.scss";
@mixin customLine {
  .el-input__inner {
    border-radius: 2px;
    border-color: rgba($home-colorprimary, .4);
    background-color: inherit;
    .el-range-input {
      background-color: inherit;
    }
    &.is-active, &:focus {
      border-color: rgba($home-colorprimary, 1);
    }
  }
}
.v-pager ::v-deep {
  padding: 12px 24px;
  margin-top: 8px;
  //background-color: $home-backcolor;
  text-align: center;
  @include customLine;
  .el-pagination__sizes .el-input__inner{
    border: 0;
    border-radius: 2px !important;
  }
  .el-pagination__jump {
    float: right;
  }
  .el-pagination__total, .el-pagination__sizes {
    float: left;
  }
  .btn-prev,.btn-next {
    background-color: inherit;
  }
  .el-pager li {
    background-color: inherit;
  }
}

.riskList ::v-deep {
}
.el-table ::v-deep {
  .el-table__row > td {
    vertical-align: text-top;
  }
  .stateRow .cell{
    overflow:hidden;
    max-height: 70px;
  }
}
.hignlightInput{
  &.el-textarea ::v-deep textarea{
    border: none;
    color: #CC3333;
    padding-left: 0;
  }
  &.el-table ::v-deep .cell .el-input input{
    border: none;
    color: #CC3333;
    padding-left: 0;
    background-color: transparent;
  }
}
.nowrap {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.icon.online {
  color: #4CAF50;
}
.iconType{
  display: inline-block;
  width: 20px;
  height: 20px;
  overflow: hidden;
  position: relative;
  font-size: 30px;
  .icon{
    position: absolute;
    top: 0;
    left: -4px;
  }
}
</style>

