<template>
  <div class="vi-wrapper">
    <div class="vi-r">
      <!-- <ul class="light-menu">
        <li v-for="(k, index) in datas" :key="k.title">
          <el-popover :disabled="!k.items.length" placement="bottom" :open-delay="0" transition="page-header-transtion"
            popper-class="page-header-light-popover page-header-light-popover__top page-header-light-popover__nav" :boundaries-padding="0" ref="pops"
            trigger="hover">
            <router-link slot="reference" tag="span" :to="k.link" class="light-mfun"
              :class="{'is-arrow': k.items.length}">
              <i-icon v-if="k.icon" :name="k.icon" :class="k.icon" :style="{color:k.icolor}"></i-icon>
              {{k.title}}
              <i class="el-icon-arrow-down" v-if="k.items.length"></i>
            </router-link>
            <ul class="vi-light-mfun-sub" @click="hidePops(k, index)">
              <router-link v-for="lk in k.items" tag="li" :to="lk.link" :key="lk.title" @click.native="linkEmit(lk)"
                class="vi-light-mfun-subitem" :class="{'normalLink': !lk.link}">
                <i-icon v-if="lk.icon" :name="lk.icon" :class="lk.icon" :style="{color:lk.icolor}"></i-icon>
                {{lk.title}}
              </router-link>
            </ul>
          </el-popover>
        </li>
      </ul> -->
      <div class="vi-space">
        <img :src="require('../../assets/images/gs-n1.png')" alt="" style="margin-left: 30px;">
      </div>
      <div class="i-menus">
        <ul class="v-menus">
          <li>
            <router-link :to="{name: 'mapControl'}"><i-icon name="icongesai-1-01"></i-icon>监控</router-link>
          </li>
          <li v-if="loginInfo.allFuns.includes(3005)"> 
            <router-link :to="{name: 'mapControlTrack'}"><i-icon name="icongesai-1-04"></i-icon>轨迹</router-link>
          </li>
          <li v-if="loginInfo.allFuns.includes(5010)&&loginInfo.allFuns.includes(20140)">
            <router-link :to="{name: 'manageHold'}"><i-icon name="icongesai-1-05"></i-icon>单位</router-link>
          </li>
          <li v-if="loginInfo.allFuns.includes(5030)&&loginInfo.allFuns.includes(20140)">
            <router-link :to="{name: 'manageUser'}"><i-icon name="icongesai-1-08"></i-icon>账号</router-link>
          </li>
          <li v-if="loginInfo.allFuns.includes(5040)&&loginInfo.allFuns.includes(20140)">
            <router-link :to="{name: 'manageGroup'}"><i-icon name="icongesai-1-06"></i-icon>人车</router-link>
          </li>
          <li v-if="loginInfo.allFuns.includes(5040)&&loginInfo.allFuns.includes(20140)" @click="linkMsg">
            <a><i-icon name="icongesai-1-09"></i-icon>信息</a>
          </li>
          <li v-if="loginInfo.allFuns.includes(5040)&&loginInfo.allFuns.includes(20140)" @click="linkCommand">
            <a><i-icon name="icongesai-1-09"></i-icon>对讲机线路</a>
          </li>
        </ul>
        <!--用户-->
        <el-popover placement="bottom" :open-delay="0" transition="page-header-transtion"
          popper-class="page-header-light-popover page-header-light-popover__top" :boundaries-padding="0" ref="popUser"
          trigger="hover">
          <!-- <div slot="reference" class="icon-message abox">
            <i-icon name="icongerenzhongxin" style="color:#1890ff"></i-icon>
          </div> -->
          <div class="icon-nav" slot="reference">
            <i-icon name="icongerenzhongxin"></i-icon> <strong>账号信息</strong>
          </div>
          <ul class="vi-light-mfun-sub" @click="$refs.popUser.doClose()">
            <router-link to @click.native="openDialogMobileObject" key="绑定手机" tag="li" class="vi-light-mfun-subitem"
              v-if="loginInfo.UserID === 0 && $i18n.locale!=='en'">
              <i-icon name="icon-_icon-13" style="color:#409EFF"></i-icon>绑定手机
            </router-link>
            <router-link v-for="lk in lastMenu" tag="li" :to="lk.link" :key="lk.title" @click.native="linkEmit(lk)"
              class="vi-light-mfun-subitem" :class="{'normalLink': !lk.link}">
              <i-icon v-if="lk.icon" :name="lk.icon" :class="lk.icon" :style="{color:lk.icolor}"></i-icon>
              {{lk.title}}
            </router-link>
          </ul>
        </el-popover>
      </div>

      <div class="i-bg">
        <img :src="require('../../assets/images/gs-n4.png')" alt="">
      </div>

      <div class="light-action">
        <!--浏览历史-->
        <!-- <el-popover
          placement="bottom"
          :open-delay="0"
          transition="page-header-transtion"
          popper-class="page-header-light-popover"
          :boundaries-padding="0"
          ref="hisMenu"
          trigger="hover"
        >
          <div slot="reference" class="icon-action icon-lishi abox">
            <i-icon name="iconlishijilu" class="icon-lishi" style="display:block;color:#4ab117"></i-icon>
          </div>

          <ul class="history-group">
            <h4
              class="history-group-title"
              @click="refreshSelectedTag()"
            >{{$t('contextMenu.history')}}</h4>
            <div class="history-action">
              <span @click="closeOthersTags">{{$t('contextMenu.closeOtherTabs')}}</span>
              <span @click="closeAllTags">{{$t('contextMenu.closeAll')}}</span>
            </div>
            <el-scrollbar wrapClass="history-scroll">
              <transition-group name="slidegroup">
                <li v-for="tag in Array.from(visitedViews)" :key="tag.path">
                  <router-link
                    class="history-link"
                    tag="span"
                    :to="{ path: tag.path, query: tag.query }"
                    @click.native="closeHisMenu"
                  >{{$t(`route.${tag.title}`)}}</router-link>

                  <i class="el-icon-close" @click="closeSelectedTag(tag)"></i>
                </li>
              </transition-group>
            </el-scrollbar>
            <div class="history-bt"></div>
          </ul>
        </el-popover> -->

        <!--收藏夹-->
        <!-- <div
          class="icon-action icon-shoucangjia abox"
          @click="openTaskList"
          v-tooltip="$t('layout.favourite')"
        >
          <el-badge
            :value="taskList.length"
            :hidden="!taskList.length"
            style="vertical-align:text-top;"
          >
            <i-icon name="iconshoucangjia" style="display:block;color:#fcaf15"></i-icon>
          </el-badge>
        </div> -->



        <!-- <div class="icon-message abox" @click="$emit('notify', true)">
          <i-icon name="icon-lingdang1"></i-icon>
        </div>-->

        <!--公告-->
        <div class="icon-message" @click="$emit('notify', true)" v-tooltip="$t('layout.notify')" v-ripple v-if="false">
          <i-icon name="icon-lingdang1"></i-icon>
        </div>

        <!--退出-->
        <!-- <div class="icon-denglu" @click="exit" v-tooltip="$t('layout.exit')">
          <i-icon name="icon-denglu-copy" class="icon-denglu-copy"></i-icon>
        </div> -->

        <!-- <div class="icon-split" v-if="languageVisible"></div> -->

        <!--修改语言-->
        <!-- <el-dropdown trigger="click" class="language abox" @command="language" v-if="languageVisible" v-ripple
          v-tooltip="$t('layout.changeLanguage')">
          <span class="el-dropdown-link">
            <template v-if="$i18n.locale==='zh'">
              <i-icon name="icon-zhongguo" class="language-icon"></i-icon><span class="language-text">中文</span>
            </template>
            <template v-else-if="$i18n.locale==='fr'">
              <i-icon name="icon-faguo" class="language-icon"></i-icon><span class="language-text">‪Français</span>
            </template>
            <template v-else>
              <i-icon name="icon-meiguo" class="language-icon"></i-icon><span class="language-text">English</span>
            </template>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="zh">
              <el-row align="middle" type="flex">
                <i-icon name="icon-zhongguo" class="language-option-icon"></i-icon>中文
              </el-row>
            </el-dropdown-item>
            <el-dropdown-item command="en">
              <el-row align="middle" type="flex">
                <i-icon name="icon-meiguo" class="language-option-icon"></i-icon>English
              </el-row>
            </el-dropdown-item>
            <el-dropdown-item command="fr">
              <el-row align="middle" type="flex">
                <i-icon name="icon-faguo" class="language-icon"></i-icon>‪Français
              </el-row>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown> -->
      </div>

      <tags-view class="page-tagsview" ref="tagsview" style="display:none;"></tags-view>
      <task-list ref="taskList"></task-list>
      <change-password ref="password"></change-password>
      <dialog-message ref="dialogMessage"></dialog-message>
      <dialog-comand ref="dialogCmd"></dialog-comand>
      <dialog-change-mobile-object ref="dialogChangeMobileObject"></dialog-change-mobile-object>
    </div>
  </div>
</template>

<script>
  import {
    mapGetters,
    mapActions,
    mapMutations
  } from "vuex";
  import {
    removeToken
  } from "@/common/auth";
  import {
    setAutoLogin,
    setUserRole,
    getOutUrl,
    setLanguage,
    getLanguageVisible,
  } from "@/common/cache";
  import {
    BASE_URL,
    setCommonParam
  } from "@/api/config";
  import DialogMessage from "@/views/online/components/dialog-message";
  import DialogComand from "@/views/online/components/dialog-comand";
  import ChangePassword from "@/views/common/change-password";
  import TaskList from "@/views/online/components/task-list";
  import HeadSearch from "@/views/layout/head-search";
  import TagsView from "./tags-view";
  import LayoutSwitch from "@/views/layout/layout-switch";
  import {
    DOWNLOAD_URL
  } from "@/api/config";
  import DialogChangeMobileObject from "@/views/info/dialog-change-mobile-object";

  import miment from "miment";
  export default {
    data() {
      return {
        platform: process.env.VUE_APP_PLAT,
        DOWNLOAD_URL,
        // 菜单列表
        datas: [],
        menus: [],
        visible: false,
        top: 0,
        left: 0,
        selectedTag: {},
        languageVisible: true,
        lastMenu: [],
      };
    },
    components: {
      ChangePassword,
      DialogMessage,
      DialogComand,
      HeadSearch,
      TaskList,
      TagsView,
      LayoutSwitch,
      DialogChangeMobileObject,
    },
    computed: {
      ...mapGetters(["loginInfo", "visitedViews", "taskList"]),
      taskListLength() {
        return this.taskList ? this.taskList.length : 0;
      },
    },
    created() {
      this.createMenu();
      this.initLanguage();
    },
    watch: {
      "$i18n.locale": "createMenu",
    },
    methods: {
      createMenu() {
        let UserMenu = [{
            title: this.$t("route.home"),
            icon: "iconshouye",
            link: "/home",
            id: 20100,
            icolor: '#4ab117',
            items: [],
          },
          {
            title: this.$t("route.gpsMenu"),
            icon: "iconweizhifuwu",
            link: "/online",
            id: 20100,
            icolor: '#1890ff',
            collapse: this.$route.path.indexOf("/online/monitor") === -1,
            items: [{
                title: this.$t("route.gpsOnlineMonitor"),
                icon: "iconjiankong",
                link: "/online/monitor",
                id: 3001,
                icolor: '#4ab117',
              },
              {
                title: this.$t('route.cctv'),
                icon: 'iconDVRshipin',
                link: '/online/monitor2',
                id: 30300,
                icolor: '#dd1f29',
              },
              {
                title: this.$t("route.gpsHistoryPlay"),
                icon: "iconguijihuifang",
                link: "/online/track",
                id: 3005,
                icolor: '#1890ff',
              },
              {
                title: this.$t("route.riskPlace"),
                icon: "iconzhaochangtingdian2",
                link: "/risk/place",
                id: 20070,
                icolor: '#fcaf15',
              },
              {
                title: this.$t("route.gpsPhotoView"),
                icon: "iconpaizhaoliulan",
                link: "/online/photo",
                id: 3007,
                icolor: '#1890ff',
              },
              {
                title: this.$t("route.gpsRegion"),
                icon: "icondianziweilan1",
                link: "/online/polygon",
                id: 20110,
                icolor: '#fcaf15',
              },
              {
                title: this.$t("route.gpsRoad"),
                icon: "iconxianluguanli",
                link: "/risk/road",
                id: 30210,
                icolor: '#dd1f29',
              },
              // {title: this.$t('route.provinceManage'), icon: 'icon-_icon-10', link: '/online/province', id: 30215, icolor: '#722ED1'},
              {
                title: this.$t("route.gpsMessage"),
                icon: "icon-_icon-11",
                link: "",
                id: 20125,
                emit: "sendMessage",
                icolor: '#4ab117',
              },
            ],
            sub: true,
          },
          {
            title: this.$t("route.infoMenu"),
            icon: "iconjiagou",
            link: "/manage",
            id: 20140,
            icolor: '#dd1f29',
            items: [{
                title: this.$t("route.infoHold"),
                icon: "icondanweiguanli",
                link: "/manage/hold",
                id: 5010,
                icolor: '#1890ff',
              },
              {
                title: this.$t("route.infoVehicle"),
                icon: "iconshebeiguanli",
                link: "/manage/group",
                id: 5040,
                icolor: '#4ab117',
              },
              // { title: this.$t('route.infoVehicle'), icon: '', link: '/manage/vehicle', id: 5040 },
              // { title: this.$t('route.infoDevice'), icon: '', link: '/manage/device', id: 5040 },
              {
                title: this.$t("route.infoUser"),
                icon: "iconzhanghaoguanli",
                link: "/manage/user",
                id: 5030,
                icolor: '#fcaf15',
              },
              // { title: '快速装车', icon: '', link: '/manage/install' }
            ],
            sub: true,
          },
          {
            title: this.$t("route.vipMenu"),
            icon: "iconshengjiVIP",
            link: "/vip",
            id: 20200,
            icolor: '#fcaf15',
            items: [{
                title: this.$t("route.vipUpdate"),
                icon: "iconshengjiVIP",
                link: "/vip/expire",
                id: 20141,
                icolor: '#fcaf15',
              },
              {
                title: this.$t("route.vipWallet"),
                icon: "iconwodeqianbao",
                link: "/vip/wallet",
                id: 20080,
                icolor: '#dd1f29',
              },
            ],
            sub: true,
          },
          {
            title: this.$t("route.reportMenufk"),
            icon: "iconbaobiao",
            link: "/report/one",
            items: [],
            id: 20150,
            icolor: '#4ab117',
          },
          {
            title: this.$t("route.systemInfo"),
            icon: "iconxitong",
            link: "/manage/notify",
            id: 50500,
            icolor: '#fcaf15',
            items: [{
                title: this.$t("route.notifyMenu"),
                icon: "icongonggaoguanli",
                link: "/manage/notify",
                id: 20170,
                icolor: '#1890ff',
              },
              {
                title: this.$t("route.userLog"),
                icon: "iconcaozuorizhi",
                link: "/log",
                id: 20160,
                icolor: '#4ab117',
              },
            ],
            sub: true,
          },
        ];

        // let InstallPeopleMenu = [
        //   {
        //     title: this.$t('route.infoTask'),
        //     icon: 'icon-_icon-14',
        //     link: '/manage/task',
        //     items: [],
        //     icolor: '#FA8C16'
        //   }
        // ]

        // if (this.loginInfo.UserID === 1 || this.loginInfo.UserID === 2) {
        //   if (UserMenu.length > 1) {
        //     UserMenu[1].items.push({ title: '省份管理', icon: 'icon-_icon-10', link: '/online/province', id: 20140, icolor: '#722ED1' },)
        //   }
        // }

        let FunMenu = UserMenu.filter((k) => {
          if (!k.id) return true;
          return this.loginInfo.allFuns.includes(k.id);
        });

        if (this.loginInfo.UserID === 0) {
          const notAllow = ["/home"];
          // 车辆登录用户
          FunMenu = FunMenu.filter((k) => !notAllow.includes(k.link));
        }

        let notAllow = [];
        if (process.env.VUE_APP_PLAT === "_kc" || process.env.VUE_APP_PLAT === '_gb') {
          notAllow = ["/online/photo"];
        }

        FunMenu.forEach((k) => {
          if (!k.items || k.items.length === 0) return;
          k.items = k.items.filter((j) => {
            if (!j.id) return true;
            return (
              this.loginInfo.allFuns.includes(j.id) && !notAllow.includes(j.link)
            );
          });
        });

        this.datas = FunMenu.filter((k) => !k.sub || k.items.length > 0);
        // this.datas = this.loginInfo.UserType === 961 ? InstallPeopleMenu : FunMenu

        const lastSubmenu = [];

        if (this.loginInfo.UserID > 0) {
          lastSubmenu.push({
            title: this.$t("route.userCenter"),
            icon: "icongerenzhongxin",
            link: "/info",
            id: 99999,
            icolor: "#dd1f29",
          });
          lastSubmenu.push({
            title: this.$t("layout.notify"),
            icon: "iconxiaoxi",
            link: "",
            emit: "notify",
            id: 99998,
            icolor: "#fcaf15",
          });
          // lastSubmenu.push({
          //   title: this.$t("route.setting"),
          //   icon: "iconshezhi1",
          //   link: "",
          //   emit: "setting",
          //   id: 99996,
          //   icolor: "#4ab117",
          // });
        }

        // if (this.loginInfo.allFuns.includes(20160)) {
        //   lastSubmenu.push({ title: this.$t('route.userLog'), icon: 'icon-_icon-7', link: '/log', id: 20160, icolor: '#FA8C16' })
        // }
        if (this.loginInfo.allFuns.includes(30200)) {
          lastSubmenu.push({
            title: this.$t("route.visitorlog"),
            icon: "icon-_icon-7",
            link: "/visitorlog",
            id: 30200,
            // icolor: "#007ae9",
          });
        }
        lastSubmenu.push({
          title: this.$t("route.exit"),
          icon: "icontuichu",
          link: "",
          id: 99997,
          emit: "openExit",
          icolor: "#dd1f29",
        });
        this.lastMenu = lastSubmenu;
        // const lastMenu = {
        //   title: this.loginInfo.HoldName,
        //   icon: 'icon-account',
        //   link: '/info',
        //   id: 99996,
        //   items: lastSubmenu
        // }

        // this.datas.push(lastMenu)
        this.imgSrc = `${BASE_URL}/assets/Logo?holdid=${
        this.loginInfo.HoldID
      }&d=${miment().format("YYYYMMDD")}`;
      },
      initLanguage() {
        this.languageVisible = getLanguageVisible();
      },
      exit() {
        setAutoLogin({
          u: '',
          p: '',
          isAuto: false,
          isAgree: true,
        });
        removeToken();
        setUserRole();
        this.delAllViews().then(() => {
          const outUrl = getOutUrl();
          if (outUrl) {
            window.location.href = outUrl;
          } else {
            this.$router.push("/login");
          }
        });
      },
      reload() {
        window.location.reload();
      },
      // 隐藏子菜单
      hidePops(k, i) {
        // this.$refs.pops[i].$el.display = 'none'
        // console.log(i)
        // console.log(this.$refs.pops)
        // k.visible = false
        this.$refs.pops & this.$refs.pops[i].doClose();
        // console.log(this.$refs.pops[i])
      },
      linkMsg(){
        this.$refs.dialogMessage.open();
      },
      linkCommand(){
        this.$refs.dialogCmd.open();
      },
      // 普通菜单
      linkEmit(k) {
        if (k.emit) {
          switch (k.emit) {
            case "sendMessage":
              this.$refs.dialogMessage.open();
              break;
            case "openExit":
              this.exit();
              break;
            case "notify":
              this.$emit("notify", true);
              break;
            case "setting":
              this.$root.$emit("dialog-setting");
              break;
          }
        }
      },
      openDialogMobileObject() {
        this.$refs.dialogChangeMobileObject.open();
      },
      language(key) {
        this.$i18n.locale = key;
        setLanguage(key);
        setCommonParam({
          language: key
        });
        //console.log(this)
      },
      openTaskList() {
        this.$refs.taskList.open();
      },
      closeHisMenu() {
        this.$refs.hisMenu.showPopper = false;
      },

      // tag-views
      openMenu(tag, e) {
        this.visible = true;
        this.selectedTag = tag;
        this.left = e.clientX;
        this.top = e.clientY;
      },
      getCurrentTag() {
        if (!this.$route.meta) {
          return null;
        }

        const index = this.visitedViews.findIndex(
          (k) => k.title === this.$route.meta.title
        );
        if (index == -1) {
          return null;
        }

        return this.visitedViews[index];
      },
      isActive(route) {
        return route.path === this.$route.path;
      },
      refreshSelectedTag(view) {
        view = view || this.getCurrentTag();
        if (!view) {
          return;
        }

        this.delCachedTrees();
        this.delCachedView(view).then(() => {
          const {
            fullPath
          } = view;
          this.$nextTick(() => {
            // console.log( '/redirect' + fullPath)
            this.$router.replace({
              path: "/redirect" + fullPath,
            });
          });
        });
      },
      closeSelectedTag(view) {
        this.delView(view).then(({
          visitedViews
        }) => {
          if (this.isActive(view)) {
            const latestView = visitedViews.slice(-1)[0];
            if (latestView) {
              this.$router.push(latestView);
            } else {
              this.$router.push("/");
            }
          }
        });
      },
      closeOthersTags() {
        let selectedTag = this.getCurrentTag();
        if (!selectedTag) {
          return;
        }

        this.$router.push({
          path: selectedTag.path,
          query: selectedTag.query,
        });
        this.delOthersViews(selectedTag).then(() => {
          // this.moveToCurrentTag()
        });

        this.closeHisMenu();
      },
      closeAllTags() {
        this.delAllViews();
        this.$router.push("/home");
        this.closeHisMenu();
      },

      ...mapActions([
        "delView",
        "delAllViews",
        "delCachedView",
        "delOthersViews",
        "delAllViews",
      ]),
      ...mapMutations({
        delCachedTrees: "DEL_CACHED_TREES",
      }),
    },
  };

</script>

<style lang="scss" scoped>
  @import "../../styles/variables-simple.scss";

  .vi-wrapper {
    height: $page-header-height-light;
    display: flex;
    align-items: center;
    box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.2);
    position: relative;
    z-index: $page-header-zindex; // 111
    background-color: #000D24;

    .vi-l {
      // min-width: 540px;
      display: flex;
      align-items: center;
      width: 460px;
      // flex-grow: 2;
    }

    .navbg {
      height: $page-header-height-light;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: -1;

      img {
        height: 100%;
      }
    }

    .vi-r {
      display: flex;
      width: 100%;
      // flex-grow: 3;
      // align-items: center;
      // flex: 1 1 auto;
      justify-content: space-between;
      position: relative;
      z-index: 1;
    }

    .vi-logo {
      height: 40px;
      width: 40px;
      margin-left: 24px;
    }

    .vi-logo2 {
      height: auto;
      width: 164px;
      margin-left: 24px;
    }

    .vi-title {
      margin-left: 16px;
      font-size: 18px;
      color: #fff;
    }

    $liHeight: $page-header-height-light;
    $liSubHeight: $page-header-height-light;

    .i-menus {
      flex-grow: 1;
      display: flex;
      justify-content: space-between;
      padding-right: 20px;
      .v-menus {
        display: flex;
        padding-left: 50px;
        padding-top: 10px;

        li {
          width: 136px;
          height: 38px;
          margin-left: -15px;
          text-align: center;
          line-height: 38px;
          color: #E6E6E6;
          cursor: pointer;
          a {
            display: block;
            width:100%;
            height: 38px;
            font-size: 14px;
            font-weight: 400;
            color: #E6E6E6;
            background: url(../../assets/images/gs-n2.png) no-repeat center;
            background-size: 100% 100%;
            text-decoration: none;
            .icon{
              font-size: 16px;
              margin-right: 5px;
              position: relative;
              top: 2px;
            }
          }
          .router-link-active{
            color: #00FFFF;
            background: url(../../assets/images/gs-n3.png) no-repeat center;
            background-size: 100% 100%;
          }
        }
        img{
          position: relative;
          top:7px;
          z-index: 1;
          margin-right: 5px;
        }

        li:hover {
          a {
            color: #00FFFF;
            background: url(../../assets/images/gs-n3.png) no-repeat center;
            background-size: 100% 100%;
          }
        }
        
      }

      .icon-nav {
        margin-top: 10px;
        width: 130px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        cursor: pointer;
        color: #E6E6E6;
        font-size: 14px;
        background: url(../../assets/images/gs-n2.png) no-repeat center;
        background-size: 100% 100%;
        strong{
          margin-left: 8px;
        }
      }

      .icon-nav:hover {
        background: url(../../assets/images/gs-n3.png) no-repeat center;
        background-size: 100% 100%;
      }
    }


    .vi-space {
      padding-top: 10px;
    }

    .i-bg {
      position: absolute;
      right: 10%;
      top: 50%;
      margin-top: -8px;
      z-index: -1;
    }

    .light-menu {
      list-style: none;
      margin-left: 24px;
      display: flex;
      align-items: center;
      position: relative;
      z-index: 101;
      flex: 0 0 auto;

      li {
        display: flex;
        align-items: center;
        position: relative;
        outline: none;

        .light-micon {
          width: 24px;
          height: 24px;
          margin-right: 16px;
          color: #fff;
          vertical-align: text-bottom;
        }

        .light-mfun {
          // background-color: #007ae9;
          color: #fff;
          height: $liHeight;
          line-height: 50px;
          font-size: 14px;
          position: relative;
          padding: 0 24px;
          z-index: 101;
          display: inline-block;
          outline: none;
          user-select: none;

          .icon {
            font-size: 26px;
            margin-bottom: -2px;
            margin-right: 6px;
          }

          .el-icon-caret-bottom {
            color: rgba(0, 0, 0, 0.54);
          }

          &.router-link-active {
            color: #fff;
            opacity: 1;

            .el-icon-caret-bottom {
              color: #fff;
              opacity: 1;
            }

            &::after {
              content: "";
              display: block;
              position: absolute;
              bottom: 0;
              left: 24px;
              width: calc(100% - 48px);
              z-index: 1;
              height: 2px;
              background-color: #fcaf15;
            }

            // &.is-arrow::after {
            //   width: calc(100% - 67px);
            // }
          }
        }

        .light-mfun_sub {
          background-color: #007ae9;
          height: $liSubHeight;
          line-height: $liSubHeight;
          position: absolute;
          bottom: 0;
          left: 0;
          transform: translateY(100%);
          display: none;
          width: max-content;
          padding: 0;
          z-index: 100;
        }

        &:hover {
          // background-color: #EBEBEB;
          cursor: pointer;


          .light-mfun {
            color: #fff;
            opacity: 0.8;

            .el-icon-caret-bottom {
              color: #fff;
              opacity: 0.8;
            }
          }

          .light-mfun_sub {
            display: flex;
          }
        }
      }
    }

    $iconHeight: 48px;

    .light-action {
      display: flex;
      align-items: center;
      padding-right: 12px;

      &>*.abox {
        border-radius: 50%;
        color: #fff;

        &:hover {
          background-color: #ffffff33;
          background-color: none;
          cursor: pointer;
        }
      }

      &>*:not(:last-child) {
        margin-right: 12px;
      }

      &>*:first-child {
        margin-right: 4px;
      }


      .icon {
        padding: 15px;
        height: $iconHeight;
        width: $iconHeight;
        color: #fff;
      }

      // 切换语言
      // .language-icon {
      //   margin-bottom: -3px;
      // }
      // 收藏夹按钮
      .icon-shoucangjia {
        padding: 17px;
        width: 36px;
        height: 36px;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        // .icon {
        //   padding: 15px;
        //   width: 48px;
        //   height: 48px;
        // }

        & ::v-deep .el-badge__content.is-fixed {
          transform: translateY(5px) translateX(100%);
        }
      }

      // 消息按钮
      .icon-message {
        height: $iconHeight;
        width: $iconHeight;

        .icon {
          color: #fff;
        }
      }

      // 退出按钮
      .icon-denglu {
        height: $iconHeight;
        width: $iconHeight;
      }

      // 分割线
      .icon-split {
        height: 24px;
        width: 1px;
        background-color: #ddd;
      }
    }

    @media screen and (max-width: 1750px) {
      .light-menu {
        li {
          span.light-mfun {
            padding: 0 16px;

            &.router-link-active {
              &::after {
                left: 16px;
                width: calc(100% - 32px);
              }

              // &.is-arrow::after {
              //   width: calc(100% - 51px);
              // }
            }
          }
        }
      }

      .light-action {
        &>*:not(:last-child) {
          margin-right: 8px;
        }
      }
    }

    @media screen and (max-width: 1850px) {
      .light-menu {
        li {
          span.light-mfun {
            // padding: 0 18px;
            padding: 0 12px;

            &.router-link-active {
              &::after {
                left: 18px;
                width: calc(100% - 32px);
              }

              // &.is-arrow::after {
              //   width: calc(100% - 43px);
              // }
            }
          }
        }
      }

      .light-action {
        &>*:not(:last-child) {
          margin-right: 6px;
        }
      }
    }

    @media screen and (max-width: 1720px) {
      .light-menu {
        li {
          span.light-mfun {
            padding: 0 12px;
            // padding: 0 6px;

            &.router-link-active {
              &::after {
                left: 12px;
                width: calc(100% - 24px);
              }

              // &.is-arrow::after {
              //   width: calc(100% - 43px);
              // }
            }
          }
        }
      }

      .light-action {
        &>*:not(:last-child) {
          margin-right: 4px;
        }
      }
    }

    @media screen and (max-width: 1650px) {
      .light-menu {
        li {
          span.light-mfun {
            // padding: 0 3px;

            padding: 0 12px;

            &.router-link-active {
              &::after {
                left: 6px;
                width: calc(100% - 12px);
              }

              // &.is-arrow::after {
              //   width: calc(100% - 31px);
              // }
            }
          }
        }
      }

      .light-action {
        &>*:not(:last-child) {
          margin-right: 0;
        }
      }
    }

    @media screen and (max-width: 1520px) {
      .light-menu {
        margin-left: 0;

        li {
          span.light-mfun {
            padding: 0 12px;
            // padding: 0 0;

            &.router-link-active {
              &::after {
                left: 4px;
                width: calc(100% - 8px);
              }

              // &.is-arrow::after {
              //   width: calc(100% - 27px);
              // }
            }
          }
        }
      }

      .light-action {
        &>*:not(:last-child) {
          margin-right: 0;
        }
      }

      .language {
        padding-right: 0;
        margin-right: -12px;

        .language-text {
          display: none;
        }
      }
    }

    @media screen and (max-width: 1400px) {
      .light-menu {
        li {
          span.light-mfun {
            padding: 0 12px;
            // padding: 0 0;

            &.router-link-active {
              &::after {
                left: 6px;
                width: calc(100% - 12px);
              }

              // &.is-arrow::after {
              //   width: calc(100% - 31px);
              // }
            }
          }
        }
      }

      .light-action {
        &>*:not(:last-child) {
          margin-right: 0;
        }

        .h-wrapper {
          width: 34px;
          overflow: hidden;
          justify-content: flex-start;

          & ::v-deep .el-input__inner {
            padding-right: 0;
            padding-left: 32px;
          }

          &:focus-within {
            overflow: visible;
            justify-content: flex-start;

            & ::v-deep .el-input__inner {
              position: relative;
              width: 215px;
              z-index: 1;
            }
          }
        }
      }
    }

    @media screen and (max-width: 1650px) {
      .h-wrapper {
        width: 100px;
        overflow: hidden;
        justify-content: flex-start;

        & ::v-deep .el-input__inner {
          padding-right: 0;
          padding-left: 32px;
        }

        &:focus-within {
          overflow: visible;
          justify-content: flex-start;

          & ::v-deep .el-input__inner {
            position: relative;
            width: 215px;
            z-index: 1;
          }
        }
      }
    }
  }

  .abox {
    border-radius: 50%;

    &:hover {
      // background-color: #f4f4f8;
      cursor: pointer;
    }
  }

  .language-option-icon {
    font-size: 18px;
    margin-right: 6px;
  }

  .history-group {
    list-style: none;
    font-size: 13px;

    .history-group-title {
      padding: 12px 24px 4px;
    }

    & ::v-deep .history-scroll {
      padding: 8px 48px 4px 24px;
      height: 300px;
    }

    li {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }

      i {
        margin-left: 8px;
        cursor: pointer;
        color: rgba(0, 0, 0, 0.24);

        &:hover {
          color: $--color-primary;
        }

        &:focus {
          color: rgba($--color-primary, 0.75);
        }
      }
    }

    .history-link {
      color: rgba(0, 0, 0, 0.54);
      // background-color: #F4F4F8;
      padding: 6px 16px;
      border-radius: 16px;
      //border-radius: 4px;
      border: 1px solid #ddd;
      display: inline-block;
      min-width: calc(4em + 24px);

      &:hover {
        cursor: pointer;
        color: $--color-primary;
      }

      &.router-link-exact-active {
        background-color: rgba($--color-primary, 0.15);
        color: $--color-primary;
      }
    }

    .history-action {
      padding: 4px 24px 8px 24px;
      border-bottom: 1px solid rgba($--color-primary, 0.12);

      &>span {
        margin-right: 16px;
        cursor: pointer;
        color: rgba(0, 0, 0, 0.24);

        &:hover {
          color: $--color-primary;
        }
      }
    }

    .history-close {
      margin-left: 12px;
      display: inline-block;
      border-radius: 50%;
      background-color: #f4f4f8;
      color: rgba(0, 0, 0, 0.54);
      cursor: pointer;
      padding: 1px;
      border: 1px solid #f4f4f8;

      i {
        font-size: 16px;
        width: 16px;
        height: 16px;
      }

      &:hover {
        background-color: #cc3333;
        color: #fff;
      }
    }

    .history-bt {
      height: 28px;
      border-top: 1px solid rgba($--color-primary, 0.12);
      background-color: #fafafa;
    }
  }

  .contextmenu {
    margin: 0;
    background: #fff;
    z-index: 201;
    position: fixed;
    list-style-type: none;
    padding: 5px 0;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    color: $--color-text-regular;
    // box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, .3);
    box-shadow: $popover-shadow-deep;

    li {
      margin: 0;
      padding: 7px 16px;
      cursor: pointer;
      white-space: nowrap;

      &:hover {
        background: #eee;
      }
    }
  }

  // 切换语言
  .language {
    border-radius: 4px !important;
    padding-right: 12px;
  }

  .el-dropdown-link {
    display: flex;
    align-items: center;
  }

  $borderColor: #ddd !important;
  $menuHeight: 42px;

  .vi-light-mfun-sub {
    background-color: #fff;
    list-style: none;
    padding: 5px 0px;
    border-radius: 2px;

    .vi-light-mfun-subitem {
      height: $menuHeight;
      line-height: $menuHeight;
      border-radius: 2px;
      padding: 0 24px 0 12px;
      color: #606266;
      font-size: 15px;

      &:hover {
        color: #fff;
        background-color: #32435f;
        border-radius: 0;
        // background-color: rgb(19, 115, 204);
        cursor: pointer;
      }

      .icon {
        margin-right: 6px;
        margin-bottom: -1px;
        font-size: 20px;
        // color: #007ae9;
      }

      // &.router-link-exact-active:not(.normalLink) {
      //   background-color: #F4F4F8;
      // }
    }
  }

  // 图标颜色
  // .icon.icon-_icon-1 {
  //   color: #F5222D;
  // }
  // .icon.icon-_icon-2 {
  //   color: #7CB305;
  // }
  // .icon.icon-_icon-7 {
  //   color: #FA8C16;
  // }
  // .icon.icon-_icon-6 {
  //   color: #722ED1;
  // }
  // .icon.icon-_icon-3 {
  //   color: #D46B08;
  // }
  // .icon.icon-_icon-5 {
  //   color: #EB2F96;
  // }

</style>
