<template>
  <div class="custom-input-group" :class="$i18n.locale">
    <template v-if="mode==1">
      <el-select v-model="select" :placeholder="$t('commonManage.selectType')" popper-class="custom" size="small">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-input
        :placeholder="$t('commonManage.inputPlaceHolder')"
        clearable
        @blur="blurHandler"
        @keypress.native.enter="click"
        class="innerSearch"
        v-model="value" size="small">
        <template slot="append">

    <el-button type="primary" @click="click" plain v-ripple :class="{'is-drop': multipleMode}">{{$t('commonManage.search')}}</el-button>
    <el-popover
      v-if="multipleMode"
      :visible-arrow="false"
      placement="bottom-end"
      width="132px"
      popper-class="custom el-popper--isnp el-popper--isdrop"
      trigger="hover">
      <div class="dropList">
        <p class="option" @click="mode=1" v-if="false">{{$t('deviceManage.normalSearch')}}</p>
        <p class="option" @click="openDialog">{{$t('deviceManage.multipleSearch')}}</p>
      </div>
      <el-button icon="el-icon-caret-bottom" type="primary" class="dropdown" slot="reference" plain></el-button>
    </el-popover>


        </template>
      </el-input>
    </template>
    <template v-if="mode==2">
      <el-input
        class="mode2"
        @focus="openDialog"
        :placeholder="$t('commonManage.inputPlaceHolder')"
        clearable
        @blur="blurHandler"
        readonly
        size="small"
        v-model="textarea">
      </el-input>
    </template>
    <!-- <el-button icon="el-icon-search" type="primary" @click="click" plain v-ripple :class="{'is-drop': multipleMode}">{{$t('commonManage.search')}}</el-button>
    <el-popover
      v-if="multipleMode"
      :visible-arrow="false"
      placement="bottom-end"
      width="132px"
      popper-class="custom el-popper--isnp el-popper--isdrop"
      trigger="hover">
      <div class="dropList">
        <p class="option" @click="mode=1" v-if="false">{{$t('deviceManage.normalSearch')}}</p>
        <p class="option" @click="openDialog">{{$t('deviceManage.multipleSearch')}}</p>
      </div>
      <el-button icon="el-icon-caret-bottom" type="primary" class="dropdown" slot="reference" plain></el-button>
    </el-popover> -->
    <el-dialog custom-class="v-dialog v-dialog--sm v-dialog--mdpadding v-dialog--footer detailPage" :title="$t('deviceManage.multipleSearch')" :visible.sync="dialogVisible" v-if="dialogVisibleReal" @closed="closed" append-to-body>
      <p class="imb-0" style="color:#fff">{{$t('deviceManage.textareaTip')}}</p>
      <el-input
        autofocus
        clearable
        ref="textarea"
        type="textarea"
        @blur="textAreaBlur"
        :rows="16"
        size="small"
        class="gs-txt"
        v-model="textarea">
      </el-input>
      <template slot="footer">
        <span v-if="textAreaCount" class="searchGroupCount">
          {{$t('deviceManage.enterTip', {count:textAreaCount})}}
          <el-button type="text" @click="clearText">{{$t('deviceManage.clear')}}</el-button>
        </span>
        <el-button @click="dialogVisible=false" class="customStyle" type="text" size="medium" style="font-size:14px">{{$t('common.cancel')}}</el-button>
        <el-button @click="clickBatch" class="customStyle queryBtn" plain type="primary" size="medium" v-ripple style="font-size:14px">{{$t('common.query')}}</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {Op} from '@/api/config'
const SPLIT = ','
export default {
  props: {
    options: {
      type: Array,
      default: () => ([])
    },
    field: {
      type: Object,
      default: () => ({
        select: null,
        value: ''
      })
    },
    multipleMode: {
      type: Boolean,
      default: false
    },
    multipleKey: {
      type: String,
      default: null
    }
  },
  computed: {
    textAreaCount() {
      if (!this.textarea) {
        return 0
      }
      return this.textarea.split(SPLIT).length
    }
  },
  data() {
    return {
      SPLIT,

      dialogVisible: false,
      dialogVisibleReal: false,
      textarea: '',

      select: this.field.select,
      value: this.field.value,

      mode: 1 // 1 普通搜索 2 批量搜索
    }
  },
  watch: {
    field() {
      this.select = this.field.select
      if (this.field.value && typeof this.field.value === 'object') {
        return
      }
      this.value = this.field.value
    },
    select() {
      this.$emit('input', {
        select: this.select,
        value: this.value || '',
        Op: this.getOption(this.select)
      })
    },
    value() {
      this.$emit('input', {
        select: this.select,
        value: this.value || '',
        Op: this.mode === 2 ? Op.SListContains : this.getOption(this.select)
      })
    },
    mode() {
      this.value = ''
      this.textarea = ''
    }
  },
  methods: {
    click() {
      this.$emit('input', {
        select: this.select,
        value: this.value || '',
        Op: this.getOption(this.select)
      })
      this.$emit('click', { select: this.select, value: this.value })
    },
    clickBatch() {
      console.log(this.select)
      let key = this.multipleKey
      if(this.select==='D.SIM2'||this.select==='D.SIM'||this.select==='VehicleNum'){
        key = this.select
      }
      const vals = this.textarea.length ? this.textarea.split(SPLIT) : ''
      this.$emit('input', { select: key, value: vals, Op: Op.SListContains})
      this.$emit('click')
      this.dialogVisible = false
    },
    getOption(select) {
      const index = this.options.findIndex(k => {
        return k.value == select
      })
      return this.options[index].Op
    },
    blurHandler() {
      if (typeof this.value === 'string') {
        this.value = this.value.trim()
      }
    },
    openDialog() {
      this.dialogVisible = true
      this.dialogVisibleReal = true
      setTimeout(() => {
        this.$refs.textarea.focus()
      }, 317)
    },
    textAreaBlur() {
      let val = this.textarea
      // 替换.;换行符为逗号
      val = val.replace(/\./g, SPLIT)
      val = val.replace(/;/g, SPLIT)
      val = val.replace(/[\r\n]/g, SPLIT)
      val = val.replace(/[\n]/, SPLIT)
      // 替换数字以外的字符为空
      val = val.replace(/[^\d,]/g, '')
      // 移除空字符
      const arr = val.split(SPLIT).filter(k => !!k).slice(0, 1000)
      val = arr.join(SPLIT)
      this.textarea = val
      setTimeout(() => {
        this.$refs.textarea.focus()
      }, 317)
    },
    clearText() {
      this.textarea = ''
    },
    cancel() {
      this.dialogVisible = false
      //this.textarea = ''
    },
    closed() {
      this.dialogVisibleReal = false
    }
  }
}
</script>

<style lang="scss" scoped>

.custom-input-group {
  display: inline-block;
  .el-select {
    width: 120px;
    display: inline-block;
    margin-right: 4px;
    margin-left: 4px;
    // border: none;
    background-color: inherit;
    vertical-align: middle;
    & ::v-deep .el-input__inner {
      border-radius: 8px;
      background-color: inherit;
      border-color:#27446B;
      color: #fff;
    }
  }
  &.en .el-select {
    width: 130px;
  }
  .el-input {
    width: 200px;
    // margin-right: 8px;
    & ::v-deep .el-input__inner {
      background-color: inherit;
      border-radius: 8px;
      border-color:#27446B;
      color: #fff;
    }
    &.mode2 {
      margin-left: 12px;
    }
  }
  .innerSearch{
   & ::v-deep .el-input__inner{
      width:180px;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
    & ::v-deep .el-input-group__append {
      background-color: #000416;
      border:none;
      overflow: hidden;
      padding-left: 10px;
      position: relative;
      .el-button{
        display: inline-block;
        // padding: 8px 5px!important;
        width:84px;
        color: #DFF1FD;
        padding:10px 0!important;
        background-color: #001F39!important;
        border-radius: 0 8px 8px 0!important;
        border:0!important;
      }
      span{
        position: relative;
      }
      .el-button:focus{
        color:#fff!important;
      }
    }


  }
  & ::v-deep .el-input-group__prepend {
    width: 100px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
  & ::v-deep .el-input-group__append {
    // width: 100px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
  .el-button {
    width: 100px;
    border-radius: 2px;
    padding: 11px 16px;
    border: 0;
    &.isdrop {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .dropdown {
    padding: 9px !important;
    margin-left: 0;
    border-left: 0 !important;
    width: 32px !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}
.dropList {
  margin: 4px 0 0;
  border: 1px solid rgba(0, 0, 0, .12);
  .option {
    line-height: 24px;
    height: 24px;
    margin: 4px auto;
    text-align: center;
    width: 100px;
    font-size: 12px;
    color: rgba(0, 0, 0, .54);
    cursor: pointer;
    &:hover {
      color: rgba(0, 0, 0, .82);
      background-color: #F4F4F8;
    }
  }
}

.customStyle {
  font-size: 14px !important;
  padding: 9px 16px !important;
  max-width: 80px !important;
  color: #fff!important;
  & ::v-deep .el-button{
    color: #fff!important;
  }
}
.queryBtn.el-button--primary {
  border: 1px solid currentColor !important;
}
.searchGroupCount {
  float: left;
  color: #fff;
  font-size: 14px;
}
.gs-txt{
  color:#fff;
  & ::v-deep .el-textarea__inner{
    border-color: #617895 !important;
    background-color: #2c394a !important;
    color: #fff!important;
  }
}


</style>
