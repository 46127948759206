<template>
  <div>
    <el-dialog :visible.sync="visible" :title="$t('deviceDetail.name')+'【'+currentHoldName+'】'" v-if="visibleReal"
    :custom-class=" showVehicleForm ? 'v-dialog dialogUser v-dialog--footer' : 'v-dialog dialogUser v-dialog--footer' "
    class="v-dialog--bk" @closed="closed" :append-to-body="appendToBody" :close-on-click-modal="false">
      <div class="vipImg" v-if="remainDays >= 0 && !!id && false">
        <img src="../../../assets/images/VIP64x50.png" />
        <label class="vipImgState">{{$t('deviceDetail.remainTime')}}</label>
        <label class="vipImgTime">{{remainDays}}{{$t('deviceDetail.days')}}</label>
      </div>
      <div class="dl-panel">
        <div class="dl-panel__chunk">
          <div class="chunk-title">
            <i-icon class="chunk-title__icon" name="icondankuang_shebei"></i-icon>
            <span>{{$t('deviceDetail.device')}}</span>
          </div>
          <div class="chunk-content">
            <el-form ref="form" :rules="rules" :model="item" :label-width="$i18n.locale==='zh' ? '6em' : '10em'" :inline-message="showVehicleForm" label-position="left">
              <el-row :gutter="gap">
                <el-col :span="12">
                  <el-form-item :label="$t('deviceDetail.deviceID')" prop="SIM2">
                    <el-input v-model.trim="item.SIM2" :placeholder="$t('deviceDetail.pleaseInputDeviceID')" maxlength="20" :disabled="id && !loginInfo.allFuns.includes(5046)" size="small"></el-input>
                  </el-form-item>
                  <el-form-item :label="$t('deviceDetail.deviceType')" prop="MDTModel" key="MDTModel">
                    <el-select v-model="item.MDTModel" style="width: 100%;" @change="mdtModelChange" size="small">
                      <el-option v-for="item in objectTypeList" :key="item.ItemID" :label="`${item.ItemName}`" :value="item.ItemID">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item :label="$t('deviceDetail.payEndDate')" v-if="id || loginInfo.allFuns.includes(20142)">
                    <el-date-picker type="date" v-model="item.PayEndDate" format="yyyy-MM-dd" :placeholder="$t('common.selectDate')" v-if="loginInfo.allFuns.includes(20142)" style="width:100%" size="small"></el-date-picker>
                    <span v-else>{{miment(item.PayEndDate).format("YYYY-MM-DD")}}</span>
                  </el-form-item>
                  <!-- <el-form-item v-if="$i18n.locale!=='zh'" :label="$t('deviceDetail.timezone')" prop="TimeZone" key="TimeZone">
                    <el-select v-model.trim="item.TimeZone" style="width: 100%;">
                      <el-option v-for="(item,index) in regions" :key="index" :label="`${item.label}`" :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item> -->
                </el-col>
                <el-col :span="12">
                  <el-form-item :label="$t('deviceDetail.sim')" prop="SIM">
                    <el-input v-model.trim="item.SIM" :placeholder="$t('deviceDetail.pleaseInputSim')" maxlength="20" :disabled="id && !loginInfo.allFuns.includes(5046)" size="small"></el-input>
                  </el-form-item>
                  <el-form-item :label="$t('deviceDetail.remark')" key="Remark"><el-input type="textarea" v-model="item.Remark" maxlength="127" size="small"></el-input></el-form-item>
                  <el-form-item label="" v-if="remainDays > 0 && !!id && loginInfo.allFuns.includes(20210)">
                    <img src="../../../assets/icon/vip.png" style="vertical-align:middle;margin-right:6px;"/>
                    <span>{{$t('deviceDetail.remainTime')}}{{remainDays}}{{$t('deviceDetail.days')}}</span>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-form-item v-if="false">
                <el-button v-if="showMoreDevice" type="text" @click="showMoreDevice = !showMoreDevice">{{$t('deviceDetail.showLess')}}<i class="el-icon-caret-top"></i></el-button>
                <el-button v-else type="text" @click="showMoreDevice = !showMoreDevice">{{$t('deviceDetail.showMore')}}<i class="el-icon-caret-bottom"></i></el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="dl-panel__chunk" v-if="showVehicleForm">
          <div class="chunk-title">
            <i-icon class="chunk-title__icon" name="icondankuang_cheliang"></i-icon>
            <span>{{$t('deviceDetail.vehicle')}}</span>
          </div>
          <div class="chunk-content">
            <el-form ref="form1" :rules="rules" :model="vehicle" :label-width="$i18n.locale==='zh' ? '6em' : '10em'" inline-message label-position="left">

              <template v-if="saveVehicle">
                <el-row :gutter="gap">
                  <el-col :span="12">
                    <el-form-item :label="$t('vehicleDetail.vehiclePlate')" prop="VehicleName" key="VehicleName">
                      <el-input v-model.trim="vehicle.VehicleName" :placeholder="$t('vehicleDetail.pleaseInputVehicle')" maxlength="40" size="small" class="other">
                        <!-- @keydown.native="e =>{if(e.keyCode == 32) e.returnValue = false;}" @change="inputChage" @focus="inputFocus(vehicle.VehicleName)" -->
                        <el-select v-model.trim="vehicle.VehicleNumBackColor" slot="prepend" style="width:80px;" class="vehicleBackcolor" :class="getVehicleBackColor(vehicle.VehicleNumBackColor)" size="small">
                          <el-option :label="$t('color')['蓝色']" value="蓝色" style="background-color:#1976D2;color:#FFF;"></el-option>
                          <el-option :label="$t('color')['绿色']" value="绿色" style="background-color:#81C784;color:#FFF;"></el-option>
                          <el-option :label="$t('color')['黄色']" value="黄色" style="background-color:#FBC02D;color:black;"></el-option>
                          <el-option :label="$t('color')['白色']" value="白色" style="background-color:#FAFAFA;"></el-option>
                          <el-option :label="$t('color')['黑色']" value="黑色" style="background-color:black;color:#FFF;"></el-option>
                        </el-select>
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item :label="$t('vehicleDetail.vehicleType')" prop="VehicleTypeID" key="VehicleTypeID">
                      <select-brand
                        title=""
                        size="28px"
                        style="width:240px;display:inline-block;"
                        v-model="vehicle.VehicleTypeID"
                        :options="other.carTypeList"
                        :props="other.carTypeProps"
                        folder="car-type"
                        extension="svg"
                        transform-name
                        placement="bottom-start"></select-brand>
                        <!-- <el-button style="float:right;" type="danger" plain @click="removeVehicle" :title="$t('deviceDetail.clickToUnbind')" circle><i class="el-icon-minus"></i> </el-button> -->
                    </el-form-item>
                  </el-col>
                </el-row>
                <!-- <el-row :gutter="gap">
                  <el-col :span="12">
                    <el-form-item :label="$t('vehicleDetail.vehicleBrand')" prop="VehicleBrandID" key="VehicleBrandID">
                      <select-brand
                        large-image
                        style="width:100%;"
                        use-index
                        title=""
                        v-model="vehicle.VehicleBrandID"
                        :options="other.brandList"
                        :props="other.brandProps"
                        transform-name
                        placement="bottom-start"></select-brand>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item :label="$t('vehicleDetail.QueryPwd')" prop="QueryPwd">
                      <el-input v-model="vehicle.QueryPwd" maxlength="20"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row> -->
                <el-row :gutter="gap">
                  <!-- <el-col :span="12">
                    <el-form-item :label="$t('deviceDetail.vehicleProvince')" prop="Province" v-if="$i18n.locale==='zh'">
                      <el-select v-model="vehicle.Province" style="width:240px;" :placeholder="$t('deviceDetail.pleaseSelectProvince')">
                        <el-option v-for="k in cityOptions" :label="k.name" :value="k.value" :key="k.name"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col> -->
                  <el-col :span="12">
                    <el-form-item :label="$t('vehicleDetail.vehicleId')" prop="FrameNo">
                      <el-input v-model.trim="vehicle.FrameNo" :placeholder="$t('vehicleDetail.pleaseInputFrameNo')" maxlength="17" size="small"></el-input>
                    </el-form-item>
                  </el-col>
                   <el-col :span="12">
                    <el-form-item :label="$t('vehicleDetail.vehicleMachineId')" prop="EngineNo">
                      <el-input v-model.trim="vehicle.EngineNo" :placeholder="$t('vehicleDetail.pleaseInputEngineNo')" maxlength="17" size="small"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <!-- <el-row :gutter="gap">
              
                  <el-col :span="12">
                    <el-form-item :label="$t('vehicleDetail.remark')"><el-input v-model.trim="vehicle.Remark" type="textarea" maxlength="127"></el-input></el-form-item>
                  </el-col>
                </el-row> -->

              </template>
              <el-form-item v-else key="saveVehicle">
                <el-button plain @click="saveVehicle=true"><i class="el-icon-plus"></i> {{$t('deviceDetail.saveVehicleInfo')}}</el-button>
              </el-form-item>
            </el-form>
            <el-form ref="formPeople" v-if="showMoreVehicle && saveVehicle && showVehicleForm" :rules="rulePeople" inline-message :model="other.people" :label-width="$i18n.locale === 'zh' ? '6em' : '10em'"
              label-position="left">

              <el-row :gutter="gap">
                <el-col :span="12">
                  <el-form-item :label="$t('vehicleDetail.peopleName')" prop="PeopleName"><el-input v-model="other.people.PeopleName" maxlength="20" size="small"></el-input></el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item :label="$t('vehicleDetail.peopleSex')" prop="PeopleSex">
                    <el-radio-group v-model="other.people.PeopleSex" size="small">
                      <el-radio :label="1">{{$t('vehicleDetail.male')}}</el-radio>
                      <el-radio :label="2">{{$t('vehicleDetail.female')}}</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="gap">
                <el-col :span="12">
                  <el-form-item :label="$t('vehicleDetail.phone')" prop="Mobile"><el-input v-model="other.people.Mobile" :placeholder="$t('vehicleDetail.pleaseInputTelephont')" maxlength="25" size="small"></el-input></el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item :label="$t('vehicleDetail.idcard')"><el-input v-model="other.people.IDCard" :placeholder="$t('vehicleDetail.pleaseInputIdCard')" maxlength="18" size="small"></el-input></el-form-item>
                </el-col>
              </el-row>
              <!-- <el-row :gutter="gap">
                <el-col :span="12">
                  <el-form-item :label="$t('vehicleDetail.wechat')"><el-input v-model="other.people.Wechat" :placeholder="$t('vehicleDetail.pleaseInputWechat')" maxlength="15"></el-input></el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item :label="$t('vehicleDetail.qq')"><el-input v-model="other.people.QQ" :placeholder="$t('vehicleDetail.pleaseInputQQ')" maxlength="15"></el-input></el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="gap">
                <el-col :span="12">
                  <el-form-item :label="$t('vehicleDetail.homeAddress')" v-show="$i18n.locale === 'zh'">
                    <el-input v-model="other.people.HomeAddress">
                      <el-button slot="append" icon="el-icon-search" @click="getAddressHome"></el-button>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item :label="$t('vehicleDetail.companyAddress')" v-show="$i18n.locale === 'zh'" >
                    <el-input v-model="other.people.CompanyAddress">
                      <el-button slot="append" icon="el-icon-search" @click="getAddressCompany"></el-button>
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row> -->
              <el-row :gutter="gap">
                <el-col :span="12">
                  <el-form-item :label="$t('vehicleDetail.remark')" v-if="false"><el-input v-model="other.people.Remark" maxlength="127" size="small"></el-input></el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </div>
      <el-form ref="form" v-if="false" v-loading="loading" :rules="rules" :model="item" :label-width="$i18n.locale==='zh' ? '6em' : '10em'" >
        <el-form-item :label="$t('deviceDetail.holdName')">{{ id ? other.holdInfo.Name : currentHoldName }}</el-form-item>
      </el-form>
      <!-- <map-select ref="map"></map-select> -->
      <!-- <image-editor ref="imageEditor" @success="changeImg"></image-editor> -->
      <div slot="footer">
        <el-button @click="cancel" class="customStyle" type="text" size="medium">{{$t('common.cancel')}}</el-button>
        <el-button @click="save" v-if="loginInfo.allFuns.includes(5041)&& !id ||loginInfo.allFuns.includes(5042)" :loading="btnSaveLoading" :disabled="loading" class="customStyle" plain type="primary" size="medium" v-ripple>{{$t('common.save')}}</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import mixin from './mixin.js'
import * as API from '@/api/device'
import { QueryDistrict} from '@/api/device'
import { QueryDicts } from '@/api/common'
import {mapGetters} from 'vuex'
import miment from 'miment'
import SelectBrand from '@/views/common/select-brand'
import MapSelect from '@/views/common/map-select'
import { POST_URL,BASE_URL } from '@/api/config'
import {Query as QuerySkillAPI} from '@/api/skillman'
// import ImageEditor from '@/views/common/image-editor'
let url  = window.location.href
export default {
  props: {
    appendToBody: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    remainDays() {
      if (!this.item) {
        return -1
      }
      return  Math.floor((-miment().diff(this.item.PayEndDate))/1000/3600/24)
    },
    ...mapGetters([
      'loginInfo',
      'currentHoldName'
    ])
  },
  components: {
    SelectBrand,
    MapSelect
  },
  data() {
    var validNumber = (rule, value, cb) => {
      if (!/^\d{0,20}$/.test(value)) {
        cb(new Error(this.$t('register.errorFormat')))
      } else {
        cb()
      }
    }
    // var validName = (rule, value, cb) => {
    //    if (!/^[\S]*$/g.test(value)) {
    //     cb(new Error(this.$t('register.errorFormat2')))
    //   } else {
    //     cb()
    //   }
    // }
    var validName = (rule, value, cb) => {
       if (value.trim().length === 0) {
        cb(new Error(this.$t('register.errorFormat2')))
      } else {
        cb()
      }
    }

   
    return {
      miment,
      item: {},
      videoCircuitList:[],
      rules: {
        SIM: [ {required: true,message:this.$t('deviceDetail.pleaseInputSim'),trigger: 'blur'}, { validator: validNumber, trigger: 'blur' } ],
        SIM2: [ {required: true,message:this.$t('deviceDetail.pleaseInputDeviceID'),trigger: 'blur'}, { validator: validNumber, trigger: 'blur' } ],
        MDTModel: [ {required: true,message:this.$t('deviceDetail.pleaseSelectDeviceType'),trigger: 'blur'} ],
        VehicleName: [ {required: true,message:this.$t('vehicleDetail.pleaseInputVehicle'),trigger: 'blur'}, { validator: validName, trigger: 'blur' } ],
        // TrackerType: [ {required: true,message:'请选择有线无线类型',trigger: 'blur'} ],
      },
      rulePeople: {
        PeopleName: [ {required: true,message:this.$t('vehicleDetail.pleaseInputName'),trigger: 'blur'} ],
        PeopleSex: [ {required: true,message:this.$t('vehicleDetail.pleaseSelectSex'),trigger: 'blur'} ],
        Mobile: [ {required: true,message:this.$t('vehicleDetail.pleaseInputTelephont'),trigger: 'blur'} ]
      },

      ruleInsatll: {
        InstallTime: [ {required: true,message:this.$t('installInfo.pleaseSelectDate'),trigger: 'blur'} ],
        InstallPeopleID: [ {required: true,message:this.$t('installInfo.pleaseSelectPeople'),trigger: 'blur'} ],
        InstallPosition: [ {required: true,message:this.$t('installInfo.pleaseSelectPosition'),trigger: 'blur'} ]
      },

      vehicle: {},
      regions: [
        { label: '(UTC -12:00) Eniwetok, Kwajalein', value: -12.0 },
        { label: '(UTC -11:00) Midway Island, Samoa', value: -11.0 },
        { label: '(UTC -10:00) Hawaii', value: -10.0 },
        { label: '(UTC -9:00) Alaska', value: -9.0 },
        { label: '(UTC -8:00) Pacific Time (US & Canada)', value: -8.0 },
        { label: '(UTC -7:00) Mountain Time (US & Canada)', value: -7.0 },
        { label: '(UTC -6:00) Central Time (US & Canada), Mexico City', value: -6.0 },
        { label: '(UTC -5:00) Eastern Time (US & Canada), Bogota, Lima', value: -5.0 },
        { label: '(UTC -4:30) Caracas', value: -4.5 },
        { label: '(UTC -4:00) Atlantic Time (Canada), Caracas, La Paz', value: -3.0 },
        { label: '(UTC -3:30) Newfoundland', value: -3.5 },
        { label: '(UTC -3:00) Brazil, Buenos Aires, Georgetown', value: -3.0 },
        { label: '(UTC -2:00) Mid-Atlantic', value: -2.0 },
        { label: '(UTC -1:00 hour) Azores, Cape Verde Islands', value: -1.0 },
        { label: '(UTC) Western Europe Time, London, Lisbon, Casablanca', value: +0 },
        { label: '(UTC +1:00 hour) Brussels, Copenhagen, Madrid, Paris', value: +1.0 },
        { label: '(UTC +2:00) Kaliningrad, South Africa', value: +2.0 },
        { label: '(UTC +3:00) Baghdad, Riyadh, Moscow, St. Petersburg', value: +3.0 },
        { label: '(UTC +3:30) Teheran', value: +3.5 },
        { label: '(UTC +4:00) Abu Dhabi, Muscat, Baku, Tbilisi', value: +4.0 },
        { label: '(UTC +4:30) Kabul', value: +4.5 },
        { label: '(UTC +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent', value: +5.0 },
        { label: '(UTC +5:30) Mumbai, Calcutta, Madras, New Delhi', value: +5.5 },
        { label: '(UTC +6:00) Almaty, Dhaka, Colombo', value: +6.0 },
        { label: '(UTC +6:30) Yangon', value: +6.5 },
        { label: '(UTC +7:00) Bangkok, Hanoi, Jakarta', value: +7.0 },
        { label: '(UTC +8:00) Beijing, Perth, Singapore, Hong Kong', value: +8.0 },
        { label: '(UTC +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk', value: +9.0 },
        { label: '(UTC +9:30) Adelaide, Darwin', value: +9.5 },
        { label: '(UTC +10:00) Eastern Australia, Guam, Vladivostok', value: +10.0 },
        { label: '(GMT +11:00) Magadan, Solomon Islands, New Caledonia, East Australia DST', value: +11.0 },
        { label: '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka', value: +12.0 },
        { label: '(GMT +13:00) New Zealand DST', value: +13.0 },
      ],
      other: {
        holdInfo: {},
        people: {},
        deviceList: [],
        brandList: [],
        carTypeList: [],
        objectTypeList: [],
        brandProps: {
          label: 'VehicleBrandName',
          value: 'VehicleBrandID'
        },
        carTypeProps: {
          label: 'ItemName',
          value: 'ItemID'
        },
      },

      objectTypeList: [],
      trackTypeList: [],
      showMoreDevice: true,
      showMoreVehicle: true,
      showMoreInstall: true,
      saveVehicle: true,
      showVehicleForm: true,
      showInstallInfo: false,

      skillList: [],
      serverPath: '',
      imageUrl: null,
      file: null,
      POST_URL,
      BASE_URL,

      cityOptions: [],

      imageNameList: [],
      gap: 24,
      targetHoldID: null,
      places: [
        '1.排挡右侧',
        '2.前仪表盘',
        '3.导航后',
        '4.三角板',
        '5.挡风玻璃',
        '6.前门左边压条',
        '7.OBD',
        '8.(左)升降开关',
        '9.后门左压条',
        '10.后挡风玻璃下方装饰板下',
        '11.油箱盖',
        '12.后门右边压条',
        '13.前门右边压条',
        '14.(右)升降开关',
        '15.右三角板',
        '16.杂物箱后',
        '17.前保险杠',
        '18.左右叶子板',
        '19.A柱',
        '20.左侧门板',
        '21.B柱',
        '22.C柱',
        '23.主驾驶座椅下',
        '24.后座地毯下',
        '25.后保险杠',
        '26.后备箱(左右)',
        '27.门板杂物箱',
        '28.座椅皮套内',
        '29.排挡内',
        '30.副驾杂物箱',
        '31.外循环出风口'
      ],
      href:(url.search('juruiyun')>0)?'http://gps.juruiyun.com':'https://www.365qczx.com/'
    }
  },
  methods: {
    inputFocus(e){
      this.vehicle.VehicleName = e.replace(/\s+/g, "")
    },
    inputChage(e){
      this.vehicle.VehicleName = e.replace(/\s+/g, "")
    },
    fetchItem(datas,id) {
      if (datas) {
        if (datas.hideVehicle) {
          this.showVehicleForm = false
          this.saveVehicle = false
        }
        if (datas.tempHoldID) {
          this.targetHoldID = datas.tempHoldID
        } else {
          this.targetHoldID = this.currentHoldId
        }
      } else {
        this.targetHoldID = this.currentHoldId
      }

      const promise1 = new Promise(resolve => {
        API.Get(this.id||0, this.targetHoldID).then(ret => {
          // let url = this.href+'/api/posts/image.ashx';
          // let url2 = this.href+'/upload/image/';
          this.item = Object.assign({}, ret.data.device, {
            SIM: ret.data.device.SIM ? ret.data.device.SIM : null,
            SIM2: ret.data.device.SIM2 ? ret.data.device.SIM2 : null
          })
          // let payDate = new Date(this.item.PayEndDate)
          // let now = new Date()
          // if(!this.id){
          //   if((payDate.getFullYear()===now.getFullYear())&&(payDate.getMonth()===now.getMonth())&&(payDate.getDate()===now.getDate())){
          //     let today = payDate.setFullYear(now.getFullYear()+4)
          //     this.item.PayEndDate = new Date(today)
          //   }
          // }

          if (!this.id && datas && datas.vid) {
            this.item.VehicleID = datas.vid
          }
          if(this.id){
            let vcList = []
            ret.data.videoCircuit?vcList=ret.data.videoCircuit.split(','):'';
            this.videoCircuitList = vcList
          }else{
            this.videoCircuitList=['1']
          }
          this.other.holdInfo = Object.assign({}, ret.data.holdInfo)
        

          this.vehicle = Object.assign({}, ret.data.vehicle)
          this.other.people = ret.data.people
          this.other.deviceList = ret.data.deviceList.slice(0)
          this.other.brandList = JSON.parse(ret.data.brandList).dict
          this.other.carTypeList = JSON.parse(ret.data.carTypeList).list.slice(0)
          this.other.objectTypeList = JSON.parse(ret.data.objectTypeList).list.slice(0)

          if (this.vehicle.VehicleID) {
            this.saveVehicle = true
          } else if (this.id) {
            this.saveVehicle = false
          }
          if(this.vehicle.VehicleTypeID===1){
            this.vehicle.VehicleTypeID=21
          }

          this.serverPath = this.item.PhotoName
          if (this.serverPath) {
            // this.imageUrl = `${url2}${this.serverPath}`
            this.imageUrl = `${this.$IMAGE_URL_PREFIX}${this.serverPath}`
            this.showInstallInfo = true
          } else {
            this.imageUrl = null
            this.showInstallInfo = false
          }
          if (this.item.PhotoName) {
            this.imageNameList = this.item.PhotoName.split('|').filter(k => !!k).map(k => ({
              name: k,
              response: k,
              url_l: `${this.$IMAGE_URL_PREFIX}${k}`,
              url: `${this.$IMAGE_URL_PREFIX_M}?name=${k}&size=140`
              // url_l: `${url2}${k}`,
              // url: `${url}?name=${k}&size=140`
            }))
          } else {
            this.imageNameList = []
          }

          resolve(ret)
        })
      })

      const promise2 = new Promise(resolve => {
        QueryDicts(1, 100, '', '', [], [58, 60]).then(ret => {
          this.objectTypeList = ret.data.list.filter(k => k.DictID == 58).slice()
          this.trackTypeList = ret.data.list.filter(k => k.DictID == 60).slice()
          resolve()
        })
      })

      // const promise3 = new Promise(resolve => {
      //   QuerySkillAPI(1, 1000, '', '', [], null, true).then(ret => {
      //     this.skillList = ret.data.list.slice()
      //     resolve()
      //   })
      // })

      return Promise.all([promise1, promise2])
    },

    addItem() {
      this.item.HoldID = this.targetHoldID
      this.vehicle.HoldID = this.targetHoldID
      this.other.people.HoldID = this.targetHoldID
      this.item.PhotoName = this.serverPath
      this.item.TypeID = 0
      this.item.PhotoName = this.imageNameList.map(k => k.response).join('|')
      if (this.saveVehicle && !this.vehicle.VehicleName) {
        this.vehicle.VehicleName = this.item.SIM2
      }
      let videoCircuitList = this.item.MDTModel===3169?this.videoCircuitList.toString():''
      
      return new Promise((resolve, reject) => {
        API.AddWidthVehicle(this.vehicle, this.other.people, this.other.deviceList, this.item, this.saveVehicle, videoCircuitList).then(ret => {
          if (!ret.data.errCode) {
            this.$emit('success', ret.data.item)
          }
          resolve(ret)
        }).catch(err => reject(err))
        
      })
    },

    modifyItem() {
      if (this.vehicle.VehicleID === 0) {
        this.vehicle.HoldID = this.currentHoldId
      }

      this.item.PhotoName = this.serverPath
      this.item.TypeID = 0
      this.item.PhotoName = this.imageNameList.map(k => k.response).join('|')
      let videoCircuitList = this.item.MDTModel===3169?this.videoCircuitList.toString():''
      return new Promise((resolve, reject) => {
        API.ModifyDeviceWithVehicle(this.vehicle, this.other.people, this.item, this.saveVehicle, videoCircuitList).then(ret =>{
          if (!ret.data.errCode) {
            this.$emit('success', ret.data.item)
          }
          resolve(ret)
        }).catch(err => reject(err))
      })
    },

    deleteInstallInfo() {
    },

    validForms() {
      let promise2 = new Promise(resolve => resolve())
      // if (this.$refs.formPeople && this.other.people && this.other.people.PeopleName && this.showMoreVehicle)
      if (this.$refs.formPeople && this.showMoreVehicle) {
        promise2 = new Promise((resolve, reject) => {
          if(this.vehicle.VehicleName===null){
            resolve()
          }else{
            this.$refs.formPeople.validate().then(valid => resolve(valid)).catch(err => {
              reject(err)
            })
          }  
        })
      } else {
        this.$refs.formPeople && this.$refs.formPeople.resetFields()
      }

      let promise3 = new Promise(resolve => resolve())
      if (this.$refs.formInstall) {
        promise3 = new Promise((resolve, reject) => {
          this.$refs.formInstall.validate().then(valid => resolve(valid)).catch(err => {
            reject(err)
          })
        })
      } else {
        this.$refs.formInstall && this.$refs.formInstall.resetFields()
      }

      if (this.$refs.form1) {
        return Promise.all([this.$refs.form.validate(), this.$refs.form1.validate(), promise2, promise3])
      } else {
        return Promise.all([this.$refs.form.validate(), promise2, promise3])
      }
    },

    resetForms() {
      this.item = {}
      this.$refs.form.resetFields()
      this.$refs.form1 && this.$refs.form1.resetFields()
      // this.showMoreDevice = false
      // this.showMoreVehicle = false
      // this.showMoreInstall = false
      this.saveVehicle = true
      this.showVehicleForm = true
    },

    mdtModelChange(newMdtModel) {
      const index = this.objectTypeList.findIndex(k => k.ItemID == newMdtModel)
      this.item.TrackerType = this.objectTypeList[index].ParentID ? 0 : 1
    },

    removeVehicle() {
      if (this.vehicle.VehicleID) {
        // 解除绑定
        this.$confirm(this.$t('deviceDetail.unBindTip', {name:this.vehicle.VehicleName}), this.$t('deviceDetail.unBindConfirm'), {
          distinguishCancelAndClose: true,
          confirmButtonText: this.$t('common.ok'),
          cancelButtonText: this.$t('common.cancel')
        })
          .then(() => {
            API.UnBind([this.id]).then(() => {
              this.$message({
                type: 'success',
                message: this.$t('deviceDetail.unBindSuccess')
              })
              this.saveVehicle = false
              this.fetchItem()
              this.$emit('refresh')
            })
          })
          .catch(() => {
          })
      } else {
        this.saveVehicle = false
      }
    },

    showInstallForm() {
      this.item.InstallPosition = this.$t('installInfo.qita')
      this.showInstallInfo = true
    },
    removeInstall() {
      this.$confirm(`${this.$t('deviceDetail.deleteInfoConfirm')}?`, this.$t('deviceDetail.tip'), {
        distinguishCancelAndClose: true,
        confirmButtonText: this.$t('common.ok'),
        cancelButtonText: this.$t('common.cancel')
      }).then(() => {
        this.item.InstallTime = null
        this.item.InstallPosition = null
        this.item.InstallPeopleID = null
        this.item.PhotoName = null
        this.serverPath = ''
        this.showInstallInfo = false
      })
    },
    getAddressHome() {
      this.$refs.map.getAddress().then(({address, lng, lat}) => {
        this.other.people.HomeAddress = address
        this.other.people.HomeLon = lng
        this.other.people.HomeLat = lat
      }).catch()
    },
    getAddressCompany() {
      this.$refs.map.getAddress().then(({address, lng, lat}) => {
        this.other.people.CompanyAddress = address
        this.other.people.CompanyLon = lng
        this.other.people.CompanyLat = lat
      }).catch()
    },
    getVehicleBackColor(name) {
      return {
        '蓝色': 'blue',
        '绿色': 'green',
        '黄色': 'yellow',
        '白色': 'white',
        '黑色': 'black'
      }[name] || 'blue'
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
      this.file = file
      this.serverPath = res
    },
    handleAvatarSuccessMultiple(res, file, fileList) {
      this.imageNameList = fileList.slice()
    },
    handleRemove(file, fileList) {
      this.imageNameList = fileList.slice()
    },
    handlePreview(file) {
      const page = this.$router.resolve(`/image?src=${file.url_l}`)
      window.open('./' + page.href)
    },
    handleExceed(files, fileList) {
      this.$message({
        type: 'error',
        message: this.$t('installInfo.exceedTip')
      })
      console.log(files, fileList)
    },
    beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg';
        const isPNG = file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isJPG && !isPNG) {
          this.$message.error(this.$t('installInfo.errorTip1'));
        }
        if (!isLt2M) {
          this.$message.error(this.$t('installInfo.errorTip2'));
        }
        return (isJPG || isPNG) && isLt2M;
    },
    // getCityOptions() {
    //   const data = require('@/common/city.json')
    //   let citys = data.districts[0].districts.map(i => {
    //     return {
    //       name: i.name,
    //       value: i.name
    //     }
    //   })
    //   citys = citys.sort( (a, b) => {
    //     return a.name.localeCompare(b.name)
    //   })

    //   //console.log(citys.map(k => k.name).join('/t'))
    //   citys.splice(0, 0, {
    //     name: '--',
    //     value: null
    //   })
    //   this.cityOptions = citys
    // },
    getCityOptions() {
      return new Promise((resolve, reject) => {
        QueryDistrict().then(ret => {
          let citys = ret.data.list.map(i => {
            return {
              name: i.DName,
              value: i.DName
            }
          })
          citys = citys.sort( (a, b) => {
            return a.name.localeCompare(b.name)
          })
          //console.log(citys.map(k => k.name).join('/t'))
          citys.splice(0, 0, {
            name: '--',
            value: null
          })
          this.cityOptions = citys
          resolve(ret.data.list)
        }).catch(err => reject(err))
      })
      // const data = require('@/common/city.json')
      
    },
    editImg(src) {
      this.$refs.imageEditor.open(src, this.item.ObjectID)
    },
    changeImg(fileName) {
      this.serverPath = fileName
      this.imageUrl = `${this.$IMAGE_URL_PREFIX}${fileName}`
    }
  },
  created() {

  },
  mixins: [mixin]
}
</script>

<style lang="scss" scoped>
$subTitleWidth: 140px;
// .v-dialog--bk {
//   & ::v-deep .el-dialog__body {
//      background-color: #F4F4F8;
//   }
// }
.el-select.vehicleBackcolor {
  width: 88px !important;
  &.blue ::v-deep {
    .el-input__inner,.el-input__inner:hover {
      background-color: #1976D2 !important;
      color: #FFF !important;
    }
    .el-select__caret {
      color: #FFF !important;
    }
  }
  &.green ::v-deep {
    .el-input__inner,.el-input__inner:hover {
      background-color: #81C784 !important;
      color: #FFF !important;
    }
    .el-select__caret {
      color: #FFF !important;
    }
  }
  &.yellow ::v-deep {
    .el-input__inner,.el-input__inner:hover {
      background-color: #FBC02D !important;
      color: #000 !important;
    }
    .el-select__caret {
      color: #000 !important;
    }
  }
  &.white ::v-deep {
    .el-input__inner,.el-input__inner:hover {
      background-color: #FAFAFA !important;
      color: #000 !important;
    }
    .el-select__caret {
      color: #000 !important;
    }
  }
  &.black ::v-deep {
    .el-input__inner,.el-input__inner:hover {
      background-color: #000 !important;
      color: #FFF !important;
    }
    .el-select__caret {
      color: #FFF !important;
    }
  }
}
.vipImg {
  position: absolute;
  top: 48px;
  right: 48px;
  height: 70px;
  box-sizing: border-box;
  overflow: hidden;
  z-index: 99;
  color: #FFF;
  padding: 10px;
  background-color: #67C23A;
  border-radius: 2px;
  box-shadow: 0 0 6px 0 rgba(0,0,0,.3);
  img {
    float: left;
  }
  .vipImgState {
    display: block;
    text-align: right;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 5px;
    margin-left: 70px;
  }
  .vipImgTime {
    display: block;
    text-align: right;
    font-size: 18px;
    line-height: 24px;
    margin-left: 70px;
  }
}
.v-radio--span {
  min-width: 100px;
  display: inline-block;
  margin-bottom: 4px;
}

  .avatar-uploader{
    display: inline-block;
    vertical-align: top;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    &.is-button {
      border-color: transparent;
    }
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;

  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    max-width: 178px;
    max-height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    max-width: 178px;
    max-height: 178px;
    display: block;
    &.isview {
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      display: inline-block;
      vertical-align: top;
      //margin-right: 16px;
    }
  }

  .multi-uploader {
    & ::v-deep {
      .el-upload-list__item {
        max-height: 100px;
        max-width: 100px;
        text-align: center;
        border-radius: 0;
        border: 0;
        transition: none !important;
        float: left;
      }
      .el-upload-list__item-status-label {
        display: none !important;
      }
      img {
        max-height: 100px;
        max-width: 100px;
        width: auto;
        height: auto;
      }

      > .el-upload {
        width: 100px;
        height: 100px;
        line-height: 100px;
      }
      .el-icon-close-tip {
        display: none;
        visibility: hidden;
      }
    }
  }

  .el-input {
    & ::v-deep .el-input-group__prepend {
      overflow: hidden;
    }
  }
   .el-textarea{
    & ::v-deep  .el-textarea__inner{
      border-color: #617895;
      background-color: #617895;
      color:#fff;
    }
  }
  .other{
   & ::v-deep .el-input__inner{
     border-color: #2c394a !important;
   }
  }

 .mgbt15{
    margin-bottom: 15px;
  }
  .chunk-content{
    & ::v-deep .el-form--label-top{
      .el-form-item__label{
        padding: 0;
      }
    }
  }
</style>
