<template>
  <div class="wrapper" :class="{isCollapse:isCollapse}">
    <div class="aside__logo">
      <p class="aside__logoImg" @click="$router.push('/info')"><img :src="loginInfo.PlatformLogo"/></p>
      <p class="aside__logoText" @click="reload">{{loginInfo.PlatformTitle}}</p>
    </div>

    <page-list-menu :datas="datas" :is-collapse="isCollapse"
      @openInfo="goToInfo"
      @openPassword="changePassword"
      @openExit="exit"
      @sendMessage="sendDeviceMessage"></page-list-menu>
    <change-password ref="password"></change-password>
    <detail-user ref="detailUser"></detail-user>
    <dialog-message ref="dialogMessage"></dialog-message>

    <arrow-button v-if="false" @click="$emit('collapse')" class="aside__toogle" :class="isCollapse ? 'collapse' : ''" :position="isCollapse?'right':'left'" :attach="isCollapse"></arrow-button>
  </div>
</template>

<script>
import PageListMenu from '@/components/page-list-menu'
import {mapGetters,mapActions} from 'vuex'
import { removeToken } from '@/common/auth'
import { setAutoLogin, setUserRole } from '@/common/cache'
import ChangePassword from '@/views/common/change-password'
import DetailUser from '@/views/manage/detail/detail-user'
import DialogMessage from '@/views/online/components/dialog-message'
import {BASE_URL} from '@/api/config'
import miment from 'miment'
import {getOutUrl} from '@/common/cache'
import ArrowButton from '@/components/arrow-button'

// const UserMenu = [
//   {
//     title: '首页',
//     icon: 'icon-home',
//     link: '/home',
//     items: []
//   },
//   {
//     title: '位置服务',
//     icon: 'icon-map-marker-multiple',
//     link: '',
//     id: 20100,
//     items: [
//       { title: '在线监控', icon: '', link: '/online/monitor', id: 3001 },
//       { title: '轨迹回放', icon: '', link: '/online/track', id: 3005 },
//       { title: '电子围栏', icon: '', link: '/risk/polygon', id: 20110 },
//       { title: '拍照浏览', icon: '', link: '/photo', id: 3007},
//       { title: '发送信息', icon: '', link: '', id: 3001, emit: 'sendMessage' },
//     ]
//   },
//   {
//     title: '风控管理',
//     icon: 'icon-bell-ring',
//     link: '',
//     id: 20000,
//     items: [
//       { title: '信用评估', icon: '', link: '/risk/query', id: 20050 },
//       { title: '资产分析', icon: '', link: '/risk/assets', id: 20300 },
//       { title: '找常停点', icon: '', link: '/risk/place', id: 20070 },
//       { title: '暗访追车', icon: '', link: '/risk/event', id: 20310 },
//       { title: '风险评估', icon: '', link: '/risk/evaluate' },
//       { title: '风险预警', icon: '', link: '/risk/prevent' },
//       { title: '灰名单', icon: '', link: '/risk/people', id: 20060 }
//     ]
//   },
//   {
//     title: '信息管理',
//     icon: 'icon-information',
//     link: '',
//     id: 20140,
//     items: [
//       { title: '用户管理', icon: '', link: '/manage/hold', id: 5010 },
//       { title: '车辆管理', icon: '', link: '/manage/vehicle', id: 5040 },
//       { title: '设备管理', icon: '', link: '/manage/device', id: 5040 },
//       { title: '账号管理', icon: '', link: '/manage/user', id: 5030 },
//       { title: '安装人员', icon: '', link: '/manage/skillman', id: 20320 },
//       { title: '安装任务', icon: '', link: '/manage/task', id: 20330 },
//       // { title: '快速装车', icon: '', link: '/manage/install' }
//     ]
//   },
//   {
//     title: 'VIP管理',
//     icon: 'icon-clipboard-account',
//     link: '',
//     id: 5010,
//     items: [
//       { title: '我的钱包', icon: '', link: '/wallet', id: 20080 },
//       { title: 'VIP管理', icon: '', link: '/manage/expire', id: 20141 },
//     ]
//   },
//   {
//     title: '报表查询',
//     icon: 'icon-magnify',
//     link: '/report/one',
//     items: [],
//     id: 20150
//   },
//   {
//     title: '公告管理',
//     icon: 'icon-bell-ring',
//     link: '/manage/notify',
//     id: 20170,
//     items: []
//   }
//   // {
//   //   title: '我的钱包',
//   //   icon: 'icon-google-wallet',
//   //   link: '/wallet',
//   //   items: [],
//   //   id: 20080
//   // },
//   // {
//   //   title: '操作日志',
//   //   icon: 'icon-calendar-text',
//   //   link: '/log',
//   //   items: [],
//   //   id: 20160
//   // }
//   // {
//   //   title: '设置',
//   //   icon: 'icon-settings',
//   //   link: '/setting',
//   //   items: []
//   // }
// ]

// const InstallPeopleMenu = [
//   {
//     title: '安装任务',
//     icon: 'icon-information',
//     link: '/manage/task',
//     items: []
//   }
// ]

export default {
  props: {
    isCollapse: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      'loginInfo'
    ])
  },
  components: {
    ArrowButton,
    ChangePassword,
    PageListMenu,
    DetailUser,
    DialogMessage
  },
  data() {
    return {
      datas: [],
      imgSrc: ''
    }
  },
  watch: {
    '$i18n.locale': 'createMenu'
  },
  created() {
    this.createMenu()
  },
  methods: {
    createMenu() {
      let UserMenu = [
        {
          title: this.$t('route.home'),
          icon: 'icon-_icon-1',
          link: '/home',
          id: 20100,
          items: [],
          icolor: '#F5222D'
        },
        {
          title: this.$t('route.gpsMenu'),
          icon: 'icon-_icon-2',
          link: '/online',
          id: 20100,
          collapse: this.$route.path.indexOf('/online/monitor') === -1,
          items: [
            { title: this.$t('route.gpsOnlineMonitor'), icon: 'icon-_icon-4', link: '/online/monitor', id: 3001, icolor: '#1890FF' },
            { title: this.$t('route.cctv'), icon: 'icon-shexiang-copy-copy-copy', link: '/online/monitor2', id: 30300, icolor: '#1890FF' },
            { title: this.$t('route.gpsHistoryPlay'), icon: 'icon-_icon-8', link: '/online/track', id: 3005, icolor: '#389E0D' },
            { title: this.$t('route.riskPlace'), icon: 'icon-_icon-17', link: '/risk/place', id: 20070, icolor: '#7CB305' },
            { title: this.$t('route.gpsPhotoView'), icon: 'icon-_icon-9', link: '/online/photo', id: 3007, icolor: '#13C2C2' },
            { title: this.$t('route.gpsRegion'), icon: 'icon-zhalan', link: '/online/polygon', id: 20110, icolor: '#722ED1' },
            { title: this.$t('route.gpsRoad'), icon: 'icon-gongjiaoxianlu', link: '/risk/road', id: 30210, icolor: '#7CB305' },     
            // {title: this.$t('route.provinceManage'), icon: 'icon-_icon-10', link: '/online/province', id: 30215, icolor: '#722ED1'},
            { title: this.$t('route.gpsMessage'), icon: 'icon-_icon-11', link: '', id: 20125, emit: 'sendMessage', icolor: '#FA8C16' },
          ],
          icolor: '#7CB305',
          sub: true
        },
        {
          title: this.$t('route.infoMenu'),
          icon: 'icon-_icon-7',
          link: '/manage',
          id: 20140,
          items: [
            { title: this.$t('route.infoHold'), icon: 'icon-_icon-13', link: '/manage/hold', id: 5010, icolor: '#1890FF' },
            { title: this.$t('route.infoVehicle'), icon: 'icon-_icon-12', link: '/manage/group', id: 5040, icolor: '#45A41D' },
            // { title: this.$t('route.infoVehicle'), icon: '', link: '/manage/vehicle', id: 5040 },
            // { title: this.$t('route.infoDevice'), icon: '', link: '/manage/device', id: 5040 },
            { title: this.$t('route.infoUser'), icon: 'icon-_icon-22', link: '/manage/user', id: 5030, icolor: '#FA541C' },
            // { title: '快速装车', icon: '', link: '/manage/install' }
          ],
          icolor: '#FA8C16',
          sub: true
        },
        // {
        //   title: this.$t('route.riskMenu'),
        //   icon: 'icon-_icon-6',
        //   link: '/risk',
        //   id: 20000,
        //   items: [
        //     // { title: this.$t('route.riskQuery'), icon: 'icon-_icon-16', link: '/risk/query', id: 20050, icolor: '#2F54EB' },
        //     { title: this.$t('route.riskEvaluate'), icon: 'icon-_icon-21', link: '/risk/evaluate', id: 20050, icolor: '#2F54EB' },
        //     { title: this.$t('route.riskEvent'), icon: 'icon-_icon-12', link: '/risk/event', id: 20310, icolor: '#000000' },
        //     { title: this.$t('route.riskShcm'), icon: 'icon-_icon-19', link: '/risk/shcm', id: 20340, icolor: '#CF1322' },
        //     { title: this.$t('route.riskPeople'), icon: 'icon-_icon-20', link: '/risk/people', id: 20060, icolor: '#919191' },
        //     { title: this.$t('route.riskAssets'), icon: 'icon-_icon-18', link: '/risk/assets', id: 20300, icolor: '#FA8C16' }, 
        //     // { title: this.$t('route.riskPrevent'), icon: 'icon-_icon-25', link: '/risk/prevent', id: 5040, icolor: '#FA8C16' },
        //     // { title: this.$t('route.infoTask'), icon: 'icon-_icon-14', link: '/risk/task', id: 20330, icolor: '#FA8C16' },
        //     // { title: this.$t('route.infoPeople'), icon: 'icon-_icon-15', link: '/risk/skillman', id: 20320, icolor: '#722ED1' },
        //   ],
        //   icolor: '#722ED1',
        //   sub: true
        // },
        // {
        //   title: this.$t('route.taskSystemfk'),
        //   icon: 'icon-_icon-14',
        //   link: '/wono',
        //   id: 25000,
        //   items: [
        //     { title: this.$t('route.infoTask'), icon: 'icon-_icon-14', link: '/wono/manage', id: 25001, icolor: '#FA8C16' },
        //     { title: this.$t('route.infoPeoplefk'), icon: 'icon-_icon-15', link: '/wono/people', id: 20320, icolor: '#722ED1' },
        //   ],
        //   sub: true
        // },
        // {
        //   title: '保单管理',
        //   icon: 'icon-_icon-21',
        //   link: '/manage/insurance',
        //   items: [],
        //   emit: 'print',
        //   id: 30000, 
        //   icolor: '#FA541C' 
        // },
        // {
        //   title: this.$t('route.vipMenu'),
        //   icon: 'icon-_icon-3',
        //   link: '/vip',
        //   id: 20200,
        //   items: [
        //     { title: this.$t('route.vipUpdate'), icon: 'icon-_icon-23', link: '/vip/expire', id: 20141, icolor: '#D46B08' },
        //     { title: this.$t('route.vipWallet'), icon: 'icon-_icon-24', link: '/vip/wallet', id: 20080, icolor: '#F5222D' }
        //   ],
        //   icolor: '#D46B08',
        //   sub: true
        // },
        {
          title: this.$t('route.reportMenufk'),
          icon: 'icon-_icon-5',
          link: '/report/one',
          items: [],
          id: 20150,
          icolor: '#EB2F96'
        },
        {
          title: this.$t('route.systemInfo'),
          icon: 'icon-_icon-25',
          link: '/manage/notify',
          id: 50500,
          items: [
            {  title: this.$t('route.notifyMenu'), icon: 'icon-bell-ring', link: '/manage/notify', id: 20170, icolor: '#E7A753' },
            { title: this.$t('route.userLog'), icon: 'icon-_icon-30',  link: '/log',  id: 20160, icolor: '#1890FF' }
          ],
          icolor: '#D46B08',
          sub: true
        }
      ]

      // let InstallPeopleMenu = [
      //   {
      //     title: this.$t('route.infoTask'),
      //     icon: 'icon-_icon-14',
      //     link: '/manage/task',
      //     items: [],
      //     icolor: '#FA8C16'
      //   }
      // ]

      // if (this.loginInfo.UserID === 1 || this.loginInfo.UserID === 2) {
      //   if (UserMenu.length > 1) {
      //     UserMenu[1].items.push({ title: this.$t('route.provinceManage'), icon: 'icon-_icon-10', link: '/online/province', id: 20140, icolor: '#722ED1' },)
      //   }
      // }

      let notAllow = []
      if (process.env.VUE_APP_PLAT === '_kc') {
        notAllow = ['/online/photo']
      }

      const FunMenu = UserMenu.filter(k => {
        if (!k.id) return true
        return this.loginInfo.allFuns.includes(k.id)
      })
      FunMenu.forEach(k => {
        if (!k.items || k.items.length === 0) return
        k.items = k.items.filter(j => {
          if (!j.id) return true
          return this.loginInfo.allFuns.includes(j.id) && !notAllow.includes(j.link)
        })
      })

      // this.datas = this.loginInfo.UserType === 961 ? InstallPeopleMenu : FunMenu
      this.datas = FunMenu.filter(k => !k.sub || k.items.length > 0)

      const lastSubmenu = []

      lastSubmenu.push({ title: this.$t('route.userCenter'), icon: '', link: '/info', id: 99999 })
      // if (this.loginInfo.allFuns.includes(20160)) {
      //   lastSubmenu.push({ title: this.$t('route.userLog'), icon: '', link: '/log', id: 20160 })
      // }
      lastSubmenu.push({ title: this.$t('route.exit'), icon: '', link: '', id: 99997, emit: 'openExit' })
      // const lastMenu = {
      //   title: this.loginInfo.HoldName,
      //   icon: 'icon-account',
      //   link: '',
      //   id: 99996,
      //   items: lastSubmenu
      // }

      // this.datas.push(lastMenu)
      this.imgSrc = `${BASE_URL}/assets/Logo?holdid=${this.loginInfo.HoldID}&d=${miment().format('YYYYMMDD')}`
    },
    exit() {
      setAutoLogin({
        u: '',
        p: '',
        isAuto: false,
        isAgree:true,
      })
      removeToken()
      setUserRole()
      this.delAllViews().then(() => {
        const outUrl = getOutUrl()
        if (outUrl) {
          window.location.href = outUrl
        } else {
          this.$router.push('/login')
        }
      })
    },
    reload() {
      window.location.reload()
    },
    goToInfo() {
      this.$refs.detailUser.open(this.loginInfo.UserID)
      //this.$router.push(`/manage/hold/detail?id=${this.loginInfo.HoldID}`)
    },
    changePassword() {
      this.$refs.password.open()
    },
    sendDeviceMessage() {
      this.$refs.dialogMessage.open()
    },
    ...mapActions(['delAllViews'])
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/variables-simple.scss";
.wrapper {
  width: $page-aside-width;
  .aside__logo {
    .aside__logoImg {
      cursor: pointer;
      height: 90px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 40px;
        margin: 0 auto;
        // height: 45px;
        user-select: none;
        flex-shrink: 0;
      }
    }
    .aside__logoText {
      text-align: center;
      line-height: 40px;
      height: 40px;
      font-size: 14px;
      //font-weight: 700;
      cursor: pointer;
      &:hover {
        opacity: .75;
      }
    }
  }

  &.isCollapse {
    width: 100%;
    // height: 100vh;
    // overflow-x: hidden;
    // overflow-y: scroll;
    .aside__logoText {
      display: none;
    }
  }
  .aside__toogle {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    transition: all 0.3s ease-out;
    &.collapse {
      transform: translateX(-17px) translateY(-50%);
    }
  }
}
</style>
