export const AMAP_KEY = process.env.VUE_APP_AMAP_KEY || '8d15184de8d8856ee21c5d68f0d9943e'
export const AMAP_PLUGIN = 'AMap.Autocomplete,AMap.Geocoder,AMap.Scale,AMap.ToolBar,AMap.OverView,AMap.MapType'
export const AMAP_URL = `https://webapi.amap.com/maps?v=1.4.15&key=${AMAP_KEY}&plugin=${AMAP_PLUGIN}`
export const AMAP_URL_loca = `https://webapi.amap.com/loca?v=2.0.0&key=${AMAP_KEY}`
// https://webapi.amap.com/maps?v=1.4.9&key=8d15184de8d8856ee21c5d68f0d9943e&plugin=AMap.Autocomplete,AMap.Geocoder
export const AMAP_URL_GL= `https://webapi.amap.com/maps?v=2.0&key=${AMAP_KEY}&plugin=${AMAP_PLUGIN}`
// export const GOOGLE_KEY = 'AIzaSyDOsJMiWn_3Pz16A19ajqkGT58NR0Fub6s'
export const GOOGLE_KEY = 'AIzaSyB-uO6aYio5Pdnm925W6bP193oGCzyZByA'
// AIzaSyDOsJMiWn_3Pz16A19ajqkGT58NR0Fub6s
// AIzaSyAVVrCY1GGb0kURGF_VsndqsS6j8tNZtgE
export const GOOGLE_URL_EN = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_KEY}&libraries=drawing&language=en-US`
export const GOOGLE_URL_ZH = `https://maps.google.cn/maps/api/js?key=${GOOGLE_KEY}&libraries=drawing&language=en-US`
export const GOOGLE_URL = GOOGLE_URL_ZH
export const BMAP_KEY = 'LhjIjdX2bNpnfyCADDMWKMOQ7qCbQQRi'
//  export const BMAP_KEY = 'kwGwUFis9LNMImE4cR9dpux1BHdVO3zr'   // 私人百度地图key

export const BMAP_URL = `https://api.map.baidu.com/getscript?v=3.0&ak=${BMAP_KEY}&s=1&services=&t=20190601000000`
export const BMAP_URL_GL = `http://api.map.baidu.com/getscript?v=1.0&type=webgl&ak=${BMAP_KEY}`

