<template>
  <el-dialog :visible.sync="visible" v-if="visibleReal" @closed="closed" title="设置对讲机线路"
    custom-class="v-dialog v-dialog--md v-dialog--mdpaading v-gs-dialog" class="dialog-message" append-to-body>
    <div class="tab-wrapper">
      <div class="tab-nav">
        <tree-user :title="$t('dialogMessage.selectVehicle')" v-if="treeVisible" show-icon show-vehicle
          :show-object-count.sync="showObjectCount" :use-local-setting="false" :mdtTypeID="currentCommand"
          :widthFixed='false' @check="treeCheck"></tree-user>
      </div>
      <div class="tab-body">
        <div class="input-area">
          <el-form label-width="6em">
            <el-form-item label="操作类型">
              <el-select v-model="form.OP">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="线路文件名:">
              <el-input type="textarea" v-model="form.FileName" placeholder="请输入线路文件名">
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" class="border" size="medium" :disabled="sendLoading" @click="sendMsg" plain>设置</el-button>
            </el-form-item>
          </el-form>
          <!-- <el-row>
            <el-input type="textarea" :autosize="{ minRows: 6, maxRows: 12}" placeholder="请输入信息内容" v-model="phoneNumber"></el-input>
          </el-row> -->
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import TreeUser from '@/views/common/tree-user'
import * as API from '@/api/message'
import { GetObjectIdByMixID } from '@/api/common'
import { GetObjectListByObjectIDs, SendCommand,BatchCommandHistory,BatchCommandDetail,ReSendCommandList } from '@/api/command'
import * as config from '@/views/batch/config/batch-command-config'
export default {
  data() {
    return {
      config: Object.assign({}, config),
      visible: false,
      visibleReal: false,
      treeVisible: true,
      currentCommand: 20,
      checkedKeys: [],
      checkedObjectKeys: [],
      autocompleText: null,
      showObjectCount: false,
      btnLoading: false,
      form: {
        OP: 1,
        FileName: ''
      },
      options: [
        { label: "设置线路", value: 1 },
        { label: "删除线路", value: 2 }
      ],
      sendLoading: false
    }
  },
  components: {
    TreeUser
  },
  watch: {
    currentCommand() {
      this.checkedObjectKeys.splice(0)
      // this.checkedObjectList.splice(0)
      this.treeVisible = false
      setTimeout(() => {
        this.treeVisible = true
      })
    },
  },
  methods: {
    open() {
      this.visible = true
      this.visibleReal = true
      this.form = Object.assign({},{ OP: 1, FileName: ''})
    },
    closed() {
      this.visibleReal = false
    },
    treeCheck(keys) {
      this.checkedKeys = keys.slice()
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        this.fetchMixIds()
        this.timer = null
      }, 50)
    },
    fetchMixIds() {
      this.btnLoading = true
      GetObjectIdByMixID(this.checkedKeys, this.currentCommand).then(ret => {
        this.btnLoading = false
        this.checkedObjectKeys = ret.data
      })
    },
    fetchData(key, cb) {
      API.GetSmsInfo(key).then(ret => {
        if (!ret.data.errCode) {
          cb(ret.data)
        }
      })
    },
    handerSelect(item) {
      this.form.messageid = item.id
      this.form.message = item.value
    },
    sendMsg() {
      if (!this.checkedObjectKeys.length) {
        this.$alert(this.$t('dialogMessage.pleaseSelectVehicle'))
        return
      }
      let datas = this.form
      console.log(JSON.stringify(datas))
      this.sendLoading = true
      SendCommand(this.checkedObjectKeys, '808', "设置线路", JSON.stringify(datas)).then(ret => {
        if (ret.data.errCode) {
          this.$alert(ret.data.errMsg)
        } else {
          this.$alert(this.$t('dialogBatchCommand.sendSuccessed'))
        }
      }).catch(err => {
        this.$message({
          message: this.$t('dialogBatchCommand.sendError'),
          type: 'error'
        })
        console.error(err)
      }).finally(() => {
        this.sendLoading = false
      })
    },
    deleteMsg() {
      if (!this.form.messageid) {
        return
      }

      const ret = confirm(this.$t('dialogMessage.confirmDelete'))
      if (!ret) {
        return
      }
      API.DeleteSmsInfo(this.form.messageid).then(ret => {
        if (!ret.data.errCode) {
          this.$message({
            message: this.$t('dialogMessage.deleteSuccessed'),
            type: 'success'
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.tab-wrapper {
  display: flex;
  height: 590px;
  align-items: stretch;

  .tab-nav {
    width: 300px;
    position: relative;
    border: 1px solid #afdff2;
    border-radius: 5px;
  }

  .tab-body {
    flex-grow: 1;
  }
}

.dialog-message ::v-deep {
  .el-dialog__body {
    padding: 15px 24px 24px;

    & ::v-deep .el-input__inner {
      color: #fff;
      background-color: #001025;
      border-color: #001025;
      border-bottom-color: #617895;
    }
  }
}

.input-area {
  max-width: 560px;
  margin: 48px auto;

  & ::v-deep .el-input__inner {
    color: #fff;
    background-color: #001025;
    border-color: #001025;
    border-bottom-color: #617895;
  }

  & ::v-deep .el-textarea__inner {
    border-color: #617895;
    background-color: #2c394a;
    color: #fff;
  }

  .el-button {
    border: none;
    color: #000;
    width: 120px;
    line-height: 22px;
    background: url(../../../assets/images/form-btn1.png) no-repeat center;
    background-size: auto 100%;
  }

  .el-button:hover {
    color: #000;
    background: url(../../../assets/images/form-btn1.png) no-repeat center;
    background-size: auto 100%;
  }

  .el-button:focus {
    color: #000;
    background: url(../../../assets/images/form-btn1.png) no-repeat center;
    background-size: auto 100%;
  }
}

.closeIcon {
  display: inline-block;
  height: 32px;
  line-height: 32px;
  width: 32px;
  text-align: center;

  &:hover {
    color: #cc3333;
    cursor: pointer;
  }
}
</style>

