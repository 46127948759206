import request from '@/common/request'
import { commonParams } from '@/api/config'
import { getToken } from '@/common/auth'

export function GetSmsInfo(KeyValue) {
  const loginKey = getToken()
  return request({
    url: './query/GetSmsInfo',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      KeyValue
    })
  })
}

export function SendSmsInfo(text, objectIDs, save) {
  const loginKey = getToken()
  return request({
    url: './query/SendSmsInfo',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      text,
      objectIDs,
      save
    })
  })
}

export function DeleteSmsInfo(iRecID) {
  const loginKey = getToken()
  return request({
    url: './query/DeleteSmsInfo',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      iRecID
    })
  })
}
