<template>
  <div>
    <el-dialog :visible.sync="visible" v-if="visibleReal" :title="$t('vehicleDetail.name')" custom-class="detailPage v-dialog v-dialog--md v-dialog--free v-dialog--mdpadding v-dialog--footer" append-to-body @closed="closed"
      :close-on-click-modal="false">

      <div class="detail-body">
      <el-form class="fresh stop">
        <el-row :gutter="gap">
          <el-col :span="24">
            <label>{{$t('vehicleDetail.basicInfo')}}</label>
          </el-col>
        </el-row>
      </el-form>
      <el-form ref="form" class="fresh" :rules="rules" :model="item" :label-width="$i18n.locale === 'zh' ? '120px' : '160px'" label-position="left">

        <el-row :gutter="gap">
          <el-col :span="12">
            <el-form-item :label="$t('vehicleDetail.holdName')">{{id ? other.holdInfo.Name : currentHoldName}}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('vehicleDetail.vehicleType')" prop="VehicleTypeID">
              <select-brand
                title=""
                style="width:100%;"
                v-model="item.VehicleTypeID"
                :options="other.carTypeList"
                :props="other.carTypeProps"
                folder="car-type"
                extension="gif"
                transform-name
                placement="bottom-start"></select-brand>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="gap">
          <el-col :span="12">

            <el-form-item :label="cvehiclePlate" prop="VehicleName">
              <el-input v-model.trim="item.VehicleName" placeholder="" maxlength="20" style="width:205px;margin-right:8px;float:left;" :style="$i18n.locale!='zh' ? 'width:170px': ''">

              </el-input>

              <el-select v-model="item.VehicleNumBackColor" style="width:90px;float:left;" class="vehicleBackcolor" :class="getVehicleBackColor(item.VehicleNumBackColor)">
                <el-option :label="$t('color')['蓝色']" value="蓝色" style="background-color:#1976D2;color:#FFF;"></el-option>
                <el-option :label="$t('color')['绿色']" value="绿色" style="background-color:#81C784;color:#FFF;"></el-option>
                <el-option :label="$t('color')['黄色']" value="黄色" style="background-color:#FBC02D;color:black;"></el-option>
                <el-option :label="$t('color')['白色']" value="白色" style="background-color:#FAFAFA;"></el-option>
                <el-option :label="$t('color')['黑色']" value="黑色" style="background-color:black;color:#FFF;"></el-option>
                <el-option v-for="(k, index) in ccolorOp" :label="k" :value="k" :key="index" :style="`background-color:${k};color:#FFF;text-transform:capitalize;`"></el-option>
              </el-select>

            </el-form-item>
          </el-col>
          <el-col :span="12">

            <el-form-item :label="$t('vehicleDetail.vehicleBrand')" prop="VehicleBrandID">
              <select-brand
                large-image
                style="width:100%;"
                use-index
                title=""
                v-model="item.VehicleBrandID"
                :options="other.brandList"
                :props="other.brandProps"
                transform-name
                placement="bottom-start"></select-brand>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="gap">
          <el-col :span="12">
            <el-form-item :label="$t('deviceDetail.vehicleProvince')" prop="Province" v-if="$i18n.locale==='zh'">
              <el-select v-model="item.Province" style="width:100%;" :placeholder="$t('deviceDetail.pleaseSelectProvince')">
                <el-option v-for="k in cityOptions" :label="k.name" :value="k.value" :key="k.name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('vehicleDetail.QueryPwd')" prop="QueryPwd">
              <el-input v-model="item.QueryPwd" maxlength="20" :placeholder="$t('vehicleDetail.usevandplogin')"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="gap">
          <el-col :span="12">
            <el-form-item :label="$t('vehicleDetail.vehicleId')" prop="FrameNo">
              <el-input v-model="item.FrameNo" :placeholder="$t('vehicleDetail.pleaseInputFrameNo')" maxlength="17"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="cengineNo" prop="EngineNo">
              <el-input v-model="item.EngineNo" placeholder="" maxlength="17"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <el-form class="fresh stop">
        <el-row :gutter="gap">
          <el-col :span="24">
            <label>{{$t('vehicleDetail.installed')}}</label>
          </el-col>
        </el-row>
      </el-form>

      <el-form class="fresh">
        <el-row class="btngroup imb-1" type="flex" justify="start">
          <el-button v-if="loginInfo.allFuns.includes(5041)" @click="$refs.detail.open(null, {hideVehicle: true, vid: id, tempHoldID: targetHoldID})" icon="el-icon-plus" plain class="customStyle" circle type="primary" size="small" v-ripple></el-button>
          <el-button @click="openSelectDevice" icon="el-icon-search" plain circle class="customStyle" type="primary" size="small" v-ripple></el-button>
        </el-row>
        <el-table :data="other.deviceList" class="imb-2" border :empty-text="$t('vehicleDetail.noDeviceInfo')">
          <el-table-column prop="SIM2" :label="$t('vehicleDetail.deviceID')" width="150" show-overflow-tooltip>
            <template slot-scope="{row}">
              <span class="i-link" @click="$refs.detail.open(row.ObjectID, {hideVehicle: true, vid: id})">{{row.SIM2 || row.SIM}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="SIM" v-if="false" :label="$t('vehicleDetail.sim')" width="125" show-overflow-tooltip></el-table-column>
          <el-table-column :label="$t('vehicleDetail.deviceType')" width="100" show-overflow-tooltip>
            <template slot-scope="{row}">{{getObjectTypeName(row.MDTModel)}}</template>
          </el-table-column>
          <el-table-column :label="$t('vehicleDetail.deviceLine')" width="140" show-overflow-tooltip>
            <template slot-scope="{row}">{{row.TrackerType===0?$t('dict')['有线设备']:$t('dict')['无线设备']}}</template>
          </el-table-column>
          <el-table-column prop="Remark" :label="$t('vehicleDetail.remark')" show-overflow-tooltip>
            <template slot-scope="{row}">{{row.Remark|emptyFormat}}</template>
          </el-table-column>
          <el-table-column label=""  width="200" show-overflow-tooltip>
            <template slot-scope="{row}">
              <span v-if="!row.InstallPeopleID" v-show="$i18n.locale==='zh'" class="i-link" @click="gotoInstlll(row)">{{$t('vehicleDetail.actionInstall')}}</span>
              <span v-if="row.InstallPeopleID" v-show="$i18n.locale==='zh'" class="i-link" @click="removeDevice(row)">{{$t('vehicleDetail.actionRemove')}}</span>
              <span class="i-link" @click="deleteDevice(row)">{{$t('vehicleDetail.actionUnBind')}}</span>
              <span v-if="row.InstallPeopleID" v-show="$i18n.locale==='zh'" class="i-link" @click="gotoInstlll(row)">{{$t('vehicleDetail.actionViewInstall')}}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-form>

      <el-form class="fresh stop">
        <el-row :gutter="gap">
          <el-col :span="24">
            <label>{{$t('vehicleDetail.peopleInfo')}}</label>
          </el-col>
        </el-row>
      </el-form>

      <el-form ref="formPeople" class="fresh" :rules="rulePeople" label-position="left" :model="other.people" :label-width="$i18n.locale === 'zh' ? '120px' : '160px'">
        <el-row :gutter="gap">
          <el-col :span="12">
            <el-form-item :label="$t('vehicleDetail.peopleName')" prop="PeopleName">
              <el-input v-model="other.people.PeopleName" maxlength="10" style="width:210px;margin-right:18px;float:left;" :style="$i18n.locale!='zh' ? 'width:170px': ''"></el-input>
              <el-select v-model="other.people.PeopleSex" style="width:80px;float:left;">
                <el-option :label="$t('vehicleDetail.male')" :value="1" ></el-option>
                <el-option :label="$t('vehicleDetail.female')" :value="2" ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="">
              <span class="hightip">{{$t('vehicleDetail.saveAddressTip')}}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="gap">
          <el-col :span="12"><el-form-item :label="$t('vehicleDetail.phone')" prop="Mobile"><el-input v-model="other.people.Mobile" :placeholder="$t('vehicleDetail.pleaseInputTelephont')" maxlength="25"></el-input></el-form-item></el-col>
          <el-col :span="12"><el-form-item :label="$t('vehicleDetail.idcard')"><el-input v-model="other.people.IDCard" :placeholder="$t('vehicleDetail.pleaseInputIdCard')" maxlength="25"></el-input></el-form-item></el-col>
        </el-row>
        <el-row :gutter="gap" v-if="false">
          <el-col :span="12"><el-form-item :label="$t('vehicleDetail.wechat')"><el-input v-model="other.people.Wechat" :placeholder="$t('vehicleDetail.pleaseInputWechat')" maxlength="15"></el-input></el-form-item></el-col>
          <el-col :span="12"><el-form-item :label="$t('vehicleDetail.qq')"><el-input v-model="other.people.QQ" :placeholder="$t('vehicleDetail.pleaseInputQQ')" maxlength="15"></el-input></el-form-item></el-col>
        </el-row>
        <el-row :gutter="gap">
          <el-col :span="24">
            <el-form-item :label="$t('vehicleDetail.homeAddress')" v-show="$i18n.locale === 'zh'">
              <el-input v-model="other.people.HomeAddress" style="width:calc(100% - 98px);">
              </el-input>
              <el-button style="width:80px;float:right;" icon="el-icon-search" @click="getAddressHome">{{$t('vehicleDetail.selectPos')}}</el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="gap">
          <el-col :span="24">
            <el-form-item :label="$t('vehicleDetail.location')" v-show="$i18n.locale === 'zh' && other.people.HomeAddress">
              {{other.people.HomeLon ? (other.people.HomeLon + ',' + other.people.HomeLat) : '--'}}
              <el-button type="text" class="iml-1" @click="parseLocation1">{{$t('vehicleDetail.getLocation')}}</el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="gap">
          <el-col :span="24">
            <el-form-item :label="$t('vehicleDetail.companyAddress')" v-show="$i18n.locale === 'zh'">
              <el-input v-model="other.people.CompanyAddress" style="width:calc(100% - 98px);">
              </el-input>
              <el-button style="width:80px;float:right;" icon="el-icon-search" @click="getAddressCompany">{{$t('vehicleDetail.selectPos')}}</el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="gap">
          <el-col :span="24">
            <el-form-item :label="$t('vehicleDetail.location')" v-show="$i18n.locale === 'zh' && other.people.CompanyAddress">
              {{other.people.CompanyLon ? (other.people.CompanyLon + ',' + other.people.CompanyLat) : '--'}}
              <el-button type="text" class="iml-1" @click="parseLocation2">{{$t('vehicleDetail.getLocation')}}</el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="gap">
          <el-col :span="24">
            <el-form-item :label="$t('vehicleDetail.remark')"><el-input v-model="other.people.Remark" type="textarea" maxlength="127"></el-input></el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="gap">
          <el-col :span="24">
            <el-form-item v-if="false">
              <span class="caption">*{{$t('vehicleDetail.savePeopleTip')}}</span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <el-form class="fresh stop" v-show="$i18n.locale === 'zh'">
        <el-row :gutter="gap">
          <el-col :span="24">
            <label>{{$t('vehicleDetail.moreInfo')}}</label>
          </el-col>
        </el-row>
      </el-form>

      <el-form ref="form1" class="fresh" :label-width="$i18n.locale === 'zh' ? '120px' : '160px'" label-position="left" v-show="$i18n.locale === 'zh'">
        <el-row>
          <el-col :span="12">
            <el-form-item :label="$t('vehicleDetail.carCost')" prop="CarCost">
              <el-input-number v-model="item.CarCost" controls-position="right" :min="1" :max="99999999" style="width:225px"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="12"></el-col>
        </el-row>
        <el-row :gutter="gap">
          <el-col :span="12">
            <el-form-item :label="$t('vehicleDetail.wono')" prop="WONO">
              <el-input v-model="item.WONO" maxlength="25"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('vehicleDetail.carInstallTime')" prop="CarInstallTime">
              <el-date-picker type="date" v-model="item.CarInstallTime" format="yyyy-MM-dd HH:mm" :placeholder="$t('common.selectDate')" style="width:100%"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="gap">
          <el-col :span="12">
            <el-form-item :label="$t('vehicleDetail.carInstallStore')" prop="CarInstallStore">
              <el-input v-model="item.CarInstallStore" maxlength="32"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('vehicleDetail.carStorePhone')" prop="CarStorePhone">
              <el-input v-model="item.CarStorePhone" maxlength="17"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item :label="$t('vehicleDetail.remark')"><el-input v-model="item.Remark" type="textarea" maxlength="127"></el-input></el-form-item>
          </el-col>
        </el-row>
      </el-form>
      </div>

      <detail-device ref="detail" @refresh="refreshHandle" append-to-body @success="successHandler"></detail-device>
      <!-- <map-select ref="map"></map-select> -->
      <el-dialog :title="`${$t('vehicleDetail.bindDevice')}: ${this.item.VehicleName||''}`" width="980px" :visible.sync="dialogBind" :append-to-body="true" :close-on-click-modal="false">
        <device-bind v-model="dialogBindList" v-if="dialogBindShow"></device-bind>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogBind = false" class="customStyle" type="text" size="medium">{{$t('common.cancel')}}</el-button>
          <el-button type="primary" class="customStyle border" plain @click="confirmSelectDevice" size="medium">{{$t('common.save')}}</el-button>
        </span>
      </el-dialog>
      <el-dialog :title="$t('vehicleDetail.installDevice')" v-if="dialogInstallIf" :visible.sync="dialogInstall" width="980px" :append-to-body="true" @closed="closedInstall" :close-on-click-modal="false">
        <install-detail :item-id="dialogInstallId" :vehicle-info="item" :device-info="dialogInstallDevice" @confirm-install="confirmInstall" @cancel-install="cancelInstall"></install-detail>
      </el-dialog>

      <div slot="footer">
        <el-button style="float:left;" v-if="installPage && taskStatus==2" type="primary" class="customStyle border imt-1" plain @click="saveInstallInfo">{{$t('vehicleDetail.saveInstallInfo')}}</el-button>
        <el-button @click="cancel" class="customStyle"  type="text" size="medium">{{$t('common.cancel')}}</el-button>
        <el-button @click="save" v-if="this.loginInfo.allFuns.includes(5042)" :loading="btnSaveLoading" class="customStyle border" plain type="primary" size="medium" v-ripple>{{$t('common.save')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import mixin from './mixin.js'
import * as API from '@/api/vehicle'
import { QueryDistrict} from '@/api/device'
import SelectBrand from '@/views/common/select-brand'
import MapSelect from '@/views/common/map-select'
import DetailDevice from './detail-device'
import DeviceBind from '../device-bind'
import {Bind as BindAPI, UnBind as DeleteDeviceAPI, RemoveDevice as RemoveDeviceAPI} from '@/api/device'
import {replaceFromArray} from '@/common/utils'
import InstallDetail from '../install-detail'
import {ModifyVehicleInstallDetailOrder} from '@/api/task'
import { GetGeoCode } from '@/api/common'
import { mapGetters } from 'vuex'

export default {
  props: {
    installPage: {
      type: Boolean,
      default: false
    },
    appendToBody: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      item: {

      },
      rules: {
        VehicleName: [ {required: true,message: process.env.VUE_APP_PLAT === '_kc' || process.env.VUE_APP_PLAT === '_gb'? 'Please input display name' : this.$t('vehicleDetail.pleaseInputVehicle'),trigger: 'blur'} ],
        // VehicleBrandID: [ {required: true,message:this.$t('vehicleDetail.pleaseSelectBrand'),trigger: 'blur', type: 'number'} ],
        // VehicleTypeID: [ {required: true,message:this.$t('vehicleDetail.pleaseSelectType'),trigger: 'blur',type: 'number'} ]
      },
      rulePeople: {
        PeopleName: [ {required: true,message:this.$t('vehicleDetail.pleaseInputName'),trigger: 'blur'} ],
        PeopleSex: [ {required: true,message:this.$t('vehicleDetail.pleaseSelectSex'),trigger: 'blur'} ],
        Mobile: [ {required: true,message:this.$t('vehicleDetail.pleaseInputTelephont'),trigger: 'blur'} ]
      },
      other: {
        holdInfo: {},
        people: {},
        deviceList: [],
        brandList: [],
        carTypeList: [],
        objectTypeList: [],
        brandProps: {
          label: 'VehicleBrandName',
          value: 'VehicleBrandID'
        },
        carTypeProps: {
          label: 'ItemName',
          value: 'ItemID'
        },
      },
      tabName: 'vehicle',
      dialogBind: false,
      dialogBindShow: false,
      dialogBindList: [],

      dialogInstall: false,
      dialogInstallIf: true,
      dialogInstallId: null,
      dialogInstallDevice: {},
      istaskid: null,
      taskStatus: null,

      cityOptions: [],
      targetHoldID: null,
      gap: 36
    }
  },
  components: {
    SelectBrand,
    MapSelect,
    DetailDevice,
    DeviceBind,
    InstallDetail
  },
  methods: {

    fetchItem(options) {
      if (options.tabName) {
        this.tabName = options.tabName
      }
      if (this.installPage && options.istaskid) {
        this.istaskid = options.istaskid
      }
      if (this.installPage && options.taskStatus) {
        this.taskStatus = options.taskStatus
      }
      if (this.installPage && options.tempHoldID) {
        this.targetHoldID = options.tempHoldID
      } else {
        this.targetHoldID = this.currentHoldId
      }
      console.log(options)
      // console.log(this.tabName)
      return new Promise(resolve => {
        API.Get(this.id||0).then(ret => {
          this.item = Object.assign({}, ret.data.vehicle)
          this.other.people = ret.data.people
          this.other.deviceList = ret.data.deviceList.slice(0)
          this.other.brandList = JSON.parse(ret.data.brandList).dict
          this.other.carTypeList = JSON.parse(ret.data.carTypeList).list.slice(0)
          this.other.objectTypeList = JSON.parse(ret.data.objectTypeList).list.slice(0)
          if(this.item.VehicleTypeID===1){
            this.item.VehicleTypeID=21
          }
          if (this.id) {
            this.other.holdInfo = ret.data.holdInfo
          } else if (this.installPage) {
            if (options.VIN) {
              this.item.FrameNo = options.VIN
            }
            if (options.PeopleName) {
              this.other.people.PeopleName = options.PeopleName
            }
            if (options.WONO) {
              this.item.WONO = options.WONO
            }
            if (options.CarInstallStore) {
              this.item.CarInstallStore = options.CarInstallStore
            }
          }

          resolve(ret)
        })
      })
    },

    addItem() {
      this.item.HoldID = this.targetHoldID
      this.other.people.HoldID = this.targetHoldID
      return new Promise((resolve, reject) => {
        API.AddVehicleComplex(this.item, this.other.people, this.other.deviceList)
        .then(ret => {
          this.$emit('added', ret.data.data)
          resolve(ret)
        })
        .catch(err => reject(err))
      })
    },

    modifyItem() {
      return new Promise((resolve, reject) => {
        API.ModifyVehicleComplex(this.item, this.other.people).then(ret => resolve(ret)).catch(err => reject(err))
      })
    },

    validForms() {
      const promise1 = new Promise((resolve, reject) => {
        this.$refs.form.validate().then(valid => resolve(valid)).catch(err => {
          this.tabName = 'vehicle'
          reject(err)
        })
      })

      let promise2 = new Promise(resolve => resolve())
      if (this.other.people.PeopleName) {
        promise2 = new Promise((resolve, reject) => {
          this.$refs.formPeople.validate().then(valid => resolve(valid)).catch(err => {
            this.tabName = 'people'
            reject(err)
          })
        })
      } else {
        this.$refs.formPeople.resetFields()
      }

      return Promise.all([promise1, promise2])
    },

    resetForms() {
      this.item = {}
      this.$refs.form.resetFields()
      this.$refs.formPeople.resetFields()
      this.tabName = 'vehicle'
    },

    getAddressHome() {
      this.$refs.map.getAddress({
        address: this.other.people.HomeAddress,
        lng: this.other.people.HomeLon,
        lat: this.other.people.HomeLat
      }).then(({address, lng, lat}) => {
        this.other.people.HomeAddress = address
        this.other.people.HomeLon = lng
        this.other.people.HomeLat = lat
      }).catch(() => {})
    },
    getAddressCompany() {
      this.$refs.map.getAddress({
        address: this.other.people.CompanyAddress,
        lng: this.other.people.CompanyLon,
        lat: this.other.people.CompanyLat
      }).then(({address, lng, lat}) => {
        this.other.people.CompanyAddress = address
        this.other.people.CompanyLon = lng
        this.other.people.CompanyLat = lat
      }).catch(() => {})
    },
    getObjectTypeName(objectTypeID) {
      const index = this.other.objectTypeList.findIndex(k => k.ItemID == objectTypeID)
      if (index != -1) {
        return this.other.objectTypeList[index].ItemName
      }
      return ''
    },

    // 查找并添加设备
    openSelectDevice() {
      this.dialogBindList.splice(0)
      this.dialogBindShow = false
      setTimeout(() => {
        this.dialogBindShow = true
        this.dialogBind = true
      }, 25)
    },
    confirmInstall(device) {
      this.dialogInstall = false
      this.updateDeviceList(device)
    },
    cancelInstall() {
      this.dialogInstall = false
    },
    closedInstall() {
      this.dialogInstallIf = false
      setTimeout(() => {
        this.dialogInstallIf = true
      }, 25)
    },
    // 确认选择
    confirmSelectDevice() {
      if (this.id) {
        BindAPI(this.dialogBindList.map(k => k.ObjectID), this.item.VehicleID).then(() => {
          this.dialogBindList.forEach(j => {
            this.other.deviceList.push(j)
          })
          this.dialogBind = false
        })
      } else {
        this.dialogBindList.forEach(j => {
          this.other.deviceList.push(j)
        })
        this.dialogBind = false
      }
    },
    // 解绑设备
    deleteDevice(device) {
      this.$confirm(this.$t('vehicleDetail.unbindText'), this.$t('vehicleDetail.tip'), {
        confirmButtonText: this.$t('common.confirm'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning'
      }).then(() => {
        if (this.id) {
          DeleteDeviceAPI([device.ObjectID]).then(() => {
            const index = this.other.deviceList.findIndex(k => k.SIM == device.SIM)
            if (index != -1) {
              this.other.deviceList.splice(index, 1)
            }
          })
        } else {
          const index = this.other.deviceList.findIndex(k => k.SIM == device.SIM)
          if (index != -1) {
            this.other.deviceList.splice(index, 1)
          }
        }
      }).catch(() => {

      })


    },
    updateDeviceList(device) {
      const index = this.other.deviceList.findIndex(k => k.ObjectID == device.ObjectID)
      this.other.deviceList.splice(index, 1, device)
    },
    // 拆除设备
    async removeDevice(row) {
      try {
        await this.$confirm('拆除将删除安装信息, 是否拆除该设备？', '提示', {type: 'warning'})
        RemoveDeviceAPI(row.ObjectID).then(ret => {
          replaceFromArray(this.other.deviceList, ret.data, k => k.ObjectID === row.ObjectID)
        })
      } catch(e){
        return
      }
    },
    gotoInstlll(row) {
      this.dialogInstallId = row.ObjectID
      this.dialogInstall = true
      this.dialogInstallDevice = row
    },
    confirmMessage() {
      const promise = new Promise((resolve, reject) => {
        this.$prompt('请确认已按照安装规范完成安装？', '提示', {
          inputPlaceholder: '请输入备注信息'
        }).then(({value}) => {
          resolve(value)
        }).catch(() => {
          reject(false)
        })
      })
      return promise
    },
    saveInstallInfo () {
      let vid = this.id || 0
      this.validForms()
        .then(this.id ? this.modifyItem : this.addItem)
        .then(ret => {
          this.id = vid = ret.data.data
          return new Promise((resolve, reject) => {
            if (!ret.data.errCode) {
              resolve()
            } else {
              this.print('error', ret.data.errMsg)
              reject(false)
            }
          })
        })
        .then(this.confirmMessage)
        .then(value => {
          return ModifyVehicleInstallDetailOrder(this.istaskid, value||'', vid)
        })
        .then(ret => {
          //console.log(ret)
          if (!ret.data.errCode) {
            this.$message({
              message: this.$t('common.success'),
              type: 'success'
            })
            this.visible = false
            this.$emit('refresh')
          } else {
            this.$message({
              message: ret.data.errMsg,
              type: 'info'
            })
            if (ret.data.errCode === 62501) {
              this.tabName = 'vehicle'
            }
            this.fetchItem({})
          }
        })
        .catch(err => {
          if (typeof err === 'boolean') return
          this.print('error', '保存失败，请重试')
        })
    },
    refreshHandle() {

    },
    successHandler(item) {
      //console.log(item)
      this.other.deviceList.push(item)
    },
    getVehicleBackColor(name) {
      let color = {
        '蓝色': 'blue',
        '绿色': 'green',
        '黄色': 'yellow',
        '白色': 'white',
        '黑色': 'black'
      }[name]

      if (!color) {
        color = name
      }
      return color
    },
    // getCityOptions() {
    //   const data = require('@/common/city.json')
    //   let citys = data.districts[0].districts.map(i => {
    //     return {
    //       name: i.name,
    //       value: i.name
    //     }
    //   })
    //   citys = citys.sort( (a, b) => {
    //     return a.name.localeCompare(b.name)
    //   })
    //   //console.log(citys.map(k => k.name).join('/t'))
    //   citys.splice(0, 0, {
    //     name: '--',
    //     value: ''
    //   })
    //   this.cityOptions = citys
    // },
     getCityOptions() {
      return new Promise((resolve, reject) => {
        QueryDistrict().then(ret => {
          let citys = ret.data.list.map(i => {
            return {
              name: i.DName,
              value: i.DName
            }
          })
          citys = citys.sort( (a, b) => {
            return a.name.localeCompare(b.name)
          })
          //console.log(citys.map(k => k.name).join('/t'))
          citys.splice(0, 0, {
            name: '--',
            value: null
          })
          this.cityOptions = citys
          resolve(ret.data.list)
        }).catch(err => reject(err))
      })
    },
    parseLocation1() {
      GetGeoCode(this.other.people.HomeAddress, '').then(ret => {
        this.other.people.HomeLon = ret.data.geocodes[0].location.split(',')[0]
        this.other.people.HomeLat = ret.data.geocodes[0].location.split(',')[1]
        if (ret.data.status == '1' && ret.data.count > 0 && !this.notAllowLevel.includes(ret.data.geocodes[0].level)) {
          this.$message({
            type: 'success',
            message: this.$t('vehicleDetail.getSuccess')
          })
        } else {
          this.$message({
            type: 'warning',
            message: this.$t('vehicleDetail.needConfirm')
          })
        }
      }).catch(() => {
        this.$message({
          type: 'error',
          message: this.$t('vehicleDetail.getFailed')
        })
      })
    },
    parseLocation2() {
      GetGeoCode(this.other.people.CompanyAddress, '').then(ret => {
        this.other.people.CompanyLon = ret.data.geocodes[0].location.split(',')[0]
        this.other.people.CompanyLat = ret.data.geocodes[0].location.split(',')[1]
        if (ret.data.status == '1' && ret.data.count > 0 && !this.notAllowLevel.includes(ret.data.geocodes[0].level)) {
          this.$message({
            type: 'success',
            message: this.$t('vehicleDetail.getSuccess')
          })
        } else {
          this.$message({
            type: 'warning',
            message: this.$t('vehicleDetail.needConfirm')
          })
        }
      }).catch(() => {
        this.$message({
          type: 'error',
          message: this.$t('vehicleDetail.getFailed')
        })
      })
    }
  },
  computed: {
     ...mapGetters([
      'loginInfo',
      'currentHoldId'
    ]),
    isPeople() {
      return this.loginInfo.UserType === 961
    },
    cvehiclePlate() {
      if (process.env.VUE_APP_PLAT === '_kc'|| process.env.VUE_APP_PLAT === '_gb') {
        return 'Display Name'
      }
      return this.$t('vehicleDetail.vehiclePlate')
    },
    cengineNo() {
      if (process.env.VUE_APP_PLAT === '_kc'|| process.env.VUE_APP_PLAT === '_gb') {
        return 'Stock Number'
      }
      return this.$t('vehicleDetail.vehicleMachineId')
    },
    ccolorOp() {
      if (process.env.VUE_APP_PLAT === '_kc'|| process.env.VUE_APP_PLAT === '_gb') {
        return ['red', 'orange', 'grey', 'brown', 'pink', 'purple']
      }
      return []
    }
  },
  created() {
    // this.getCityOptions()
    this.notAllowLevel = [
      '国家',
      '省',
      '市',
      '区县',
      '开发区',
      '乡镇',
      '未知'
    ]
  },
  mixins: [mixin]
}
</script>

<style lang="scss" scoped>
@import "../../../styles/variables-simple.scss";
@import "../../../styles/mixin.scss";
.el-form {
  .el-form-item {
    &.is-error {
      & ::v-deep .el-popover__reference.active .tip {
        border-color: $--color-danger;
      }
    }
  }
}
.pane {
  min-height: 420px;
  overflow-y: auto;
  @include scroll-bar;
  position: relative;
  overflow-x: hidden;
  .btngroup {
    position: absolute;
    right: 28px;
    top: 139px;
    z-index: 1;
    .el-button {
      &.is-circle {
        border: 1px solid rgba($--color-primary, .5);
        background-color: #FFF;
        color: $--color-primary;
        &:active, &:focus {
          background-color: #FFF;
          color: $--color-primary;
        }
        &:hover {
          background-color: $--color-primary;
          color: #FFF;
        }
      }
    }
  }
}

.el-select {
  .vehicleBackcolor {
    width: 85px;
  }
  & ::v-deep input {
    text-transform: capitalize;
  }
  &.blue ::v-deep {
    .el-input__inner,.el-input__inner:hover {
      background-color: #1976D2 !important;
      color: #FFF !important;
    }
    .el-select__caret {
      color: #FFF !important;
    }
  }
  &.green ::v-deep {
    .el-input__inner,.el-input__inner:hover {
      background-color: #81C784 !important;
      color: #FFF !important;
    }
    .el-select__caret {
      color: #FFF !important;
    }
  }
  &.yellow ::v-deep {
    .el-input__inner,.el-input__inner:hover {
      background-color: #FBC02D !important;
      color: #000 !important;
    }
    .el-select__caret {
      color: #000 !important;
    }
  }
  &.white ::v-deep {
    .el-input__inner,.el-input__inner:hover {
      background-color: #FAFAFA !important;
      color: #000 !important;
    }
    .el-select__caret {
      color: #000 !important;
    }
  }
  &.black ::v-deep {
    .el-input__inner,.el-input__inner:hover {
      background-color: #000 !important;
      color: #FFF !important;
    }
    .el-select__caret {
      color: #FFF !important;
    }
  }
  &.red ::v-deep {
    .el-input__inner,.el-input__inner:hover {
      background-color: red !important;
      color: #FFF !important;
    }
    .el-select__caret {
      color: #FFF !important;
    }
  }
  &.orange ::v-deep {
    .el-input__inner,.el-input__inner:hover {
      background-color: orange !important;
      color: #FFF !important;
    }
    .el-select__caret {
      color: #FFF !important;
    }
  }
  &.grey ::v-deep {
    .el-input__inner,.el-input__inner:hover {
      background-color: grey !important;
      color: #FFF !important;
    }
    .el-select__caret {
      color: #FFF !important;
    }
  }
  &.brown ::v-deep {
    .el-input__inner,.el-input__inner:hover {
      background-color: brown !important;
      color: #FFF !important;
    }
    .el-select__caret {
      color: #FFF !important;
    }
  }
  &.pink ::v-deep {
    .el-input__inner,.el-input__inner:hover {
      background-color: pink !important;
      color: #FFF !important;
    }
    .el-select__caret {
      color: #FFF !important;
    }
  }
  &.purple ::v-deep {
    .el-input__inner,.el-input__inner:hover {
      background-color: purple !important;
      color: #FFF !important;
    }
    .el-select__caret {
      color: #FFF !important;
    }
  }
}

.detail-body {
  padding: 0 18px;
}

.container {
  .el-form-item ::v-deep {
    .el-form-item__label {
    }
    .el-form-item__content {
      .el-input,.el-select {
      }
    }
  }
}

.stop {
  position: sticky;
  top: -28px;
  left: 0;
  z-index: 1;
}
</style>
