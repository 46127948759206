<template>
  <div class="wrapper-404">
    <div class="imgele">
      <img src="../assets/icon/404.svg" alt="" />
    </div>
    <div class="content">
      <h1>404</h1>
      <p>抱歉，你访问的页面不存在</p>
      <el-button class="imt-2" type="primary" @click="$router.go(-1)">返回</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  props: {
    fullPage: {
      type: Boolean,
      default: false
    }
  }
}
</script>


<style lang="scss" scoped>
.wrapper-404 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: -webkit-fill-available;
  background-color: #F0F2F5;
  .imgele {
    width: 430px;
    height: 360px;
    // background-repeat: no-repeat;
    // background-position: 50% 50%;
    // background-size: contain;
    // background-image: url('../assets/icon/404.svg');
    margin-right: 152px;
    overflow: hidden;
    img {
      width: 430px;
      height: 360px;
    }
  }
  .content {
    h1 {
      font-size: 72px;
      color: #434e59;
      user-select: none;
      margin-bottom: 24px;
    }
    p {
      color: rgba(0,0,0,.45);
      font-size: 20px;
      user-select: none;
    }
  }
}
</style>
