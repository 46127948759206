<template>
  <el-dialog :visible.sync="visible" v-if="visibleReal" :custom-class="class1" @closed="visibleReal=false" :close-on-click-modal="closeOnClickModal" :title="title">
    <slot ref="content"></slot>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      title: '',
      class1: '',
      visible: false,
      visibleReal: false,
      closeOnClickModal: true
    }
  },
  methods: {
    open(options) {
      if (options) {
        this.title = options.title
        this.class1 = options.class
        this.visible = true
        this.visibleReal = true
      }
    },
    close() {
      this.visible = false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
