import storage from 'good-storage'
let urlTag = window.location.hostname

const SEARCH_KEY = '__search__'
const SEARCH_MAX_LEN = 2
const USERNAME = '__username__'
const PASSWORD = '__password__'
const REMEMBER = '__remember__'
const LOGIN_USERINFO = '__loginuserinfo__'
const VUEX_KEY = '__vuexkey__'
const VUEX_TIME = '__vuextime__'
const MAP_KEY = '__map-setting__'
const USER_TREE_SETTING = '__user_tree_setting__'
const DEVICE_TREE_SETTING = '__device_tree_setting__'
const COMMAND_LIKE_LIST = '__command_like_list__'
const MAP_POSITION = '__map_position__'
const REPORT_LIKE_LIST = '__report_like_list__'
const AUTO_LOGIN = '__auto_login__'
const USER_ROLE = '__user_role__'
const MESSAGE_PHONE_NUMBER = '__message_phone_number__'
const NOTIFY_HISTORY_LIST = '__notify_hitory_list__'
const COLUMN_WIDTH = '__column_width__'
const LAST_LOGIN_INFO = '__last_login_info__'+urlTag
const LANGUAGE_KEY = '__language_key__'+urlTag
const DEFAULT_MAP = '__default_map__'+urlTag
const PLAY_SOUND = '__play_sound__'
const LAST_ACTIVE_DAY = '__last_active_day__'
const OUT_URL = '__out_url__'
const GOOGLE_KEY = '_google_key__'+urlTag
const AMAP_KEY = '_amap_key__'+urlTag
const BMAP_KEY = '_bmap_key__'+urlTag
const VISITED_VIEW_INFO = '__visited_view_info__'
const LANGUAGE_VISIBLE = '__language_visible__'+urlTag
const LAYOUT_KEY = '__layout_key__'
const MONITOR_CACHE = '__monitor_cache__'
const TRACK_CACHE = '__track_cache__'
const TRACK_CONFIG = '__track_config__'+urlTag
const DEVICE_CACHE = '__device_cache__'
const RISK_REFRESH_TIME = '__risk_refresh_time__'
const CUSTOM_SETTING = '__custom_setting__'
const ASIDE_TREE_VISIBLE = '__aside_tree_visible__'
export const S_NEXT_TRACK_PARAM = '__s_next_track_param__'
export const S_NEXT_REPORT_PARAM = 'S_NEXT_REPORT_PARAM'
export const S_LONG_CONNECT_KEY = 'S_LONG_CONNECT_KEY'
export const S_IMAGE_PREFIX_URL = 'S_IMAGE_PREFIX_URL'
const IS_SHOW_MORE = '_is_show_more_'

function insertArray(arr, val, compare, maxLen) {
  const index = arr.findIndex(compare)
  if (index === 0) {
    return
  }
  if (index > 0) {
    arr.splice(index, 1)
  }
  arr.unshift(val)
  if (maxLen && arr.length > maxLen) {
    arr.pop()
  }
}

function deleteFromArray(arr, compare) {
  const index = arr.findIndex(compare)
  if (index > -1) {
    arr.splice(index, 1)
  }
}

export function saveSearch(query) {
  let searches = storage.get(SEARCH_KEY, [])
  insertArray(searches, query, item => {
    return item === query
  }, SEARCH_MAX_LEN)
  storage.set(SEARCH_KEY, searches)
  return searches
}

export function deleteSearch(query) {
  let searches = storage.get(SEARCH_KEY, [])
  deleteFromArray(searches, item => {
    return item === query
  })
  storage.set(SEARCH_KEY, searches)
  return searches
}

export function clearSearch() {
  storage.remove(SEARCH_KEY)
  return []
}

export function loadSearch() {
  return storage.get(SEARCH_KEY, [])
}

export function saveLoginInfo(username, password, remember) {
  storage.set(USERNAME, username)
  storage.set(PASSWORD, password)
  storage.set(REMEMBER, remember)
}

export function loadLoginInfo() {
  return {
    username: storage.get(USERNAME, ''),
    password: storage.get(PASSWORD, ''),
    remember: storage.get(REMEMBER, true)
  }
}

export function deleteLoginInfo() {
  storage.remove(USERNAME)
  storage.remove(PASSWORD)
  storage.remove(REMEMBER)
}

export function setLoginUserInfo(info) {
  if (info) {
    storage.set(LOGIN_USERINFO, info)
  } else {
    storage.remove(LOGIN_USERINFO)
  }
}

export function getLoginUserInfo() {
  return storage.get(LOGIN_USERINFO)
}

// 地图设置

// vuex设置、有效期15分钟
export function setVuex(state) {
  if (state) {
    storage.set(VUEX_KEY, state)
    // yyyy-MM-ddTHH:mm:ss.sssZ
    storage.set(VUEX_TIME, (new Date()).toISOString())
  } else {
    storage.remove(VUEX_KEY)
  }
}

export function getVuex() {
  const defaultVal = {}

  // 过期时长60分钟
  const time = storage.get(VUEX_TIME)
  const auto_login_tag = localStorage.getItem(AUTO_LOGIN)?JSON.parse(localStorage.getItem(AUTO_LOGIN)).isAuto:false
  const limit = (new Date()).getTime() - (new Date(time)).getTime() > 1000 * 60 * 60
  if (!time) {
    return defaultVal
  } else if (limit&&!auto_login_tag) {
    return defaultVal
  }
  return storage.get(VUEX_KEY, {})
}

export function setMapConfig(config) {
  return storage.set(MAP_KEY, config)
}

export function getMapConfig() {
  return storage.get(MAP_KEY, {})
}

export function setUserTreeSetting(setting) {
  if (setting) {
    storage.set(USER_TREE_SETTING, setting)
  } else {
    storage.remove(USER_TREE_SETTING)
  }
}

export function getUserTreeSetting() {
  return storage.get(USER_TREE_SETTING, {})
}

export function setDeviceTreeSetting(setting) {
  if (setting) {
    storage.set(DEVICE_TREE_SETTING, setting)
  } else {
    storage.remove(DEVICE_TREE_SETTING)
  }
}

export function getDeviceTreeSetting() {
  return storage.get(DEVICE_TREE_SETTING, {
    checkList: []
  })
}

export function setLikeCommandList(arr) {
  if (arr) {
    storage.set(COMMAND_LIKE_LIST, arr)
  } else {
    storage.remove(COMMAND_LIKE_LIST)
  }
}

export function getLikeCommandList() {
  return storage.get(COMMAND_LIKE_LIST, [])
}

export function setMapPosition(position) {
  if (position) {
    storage.set(MAP_POSITION, position)
  } else {
    storage.remove(MAP_POSITION)
  }
}

export function getMapPosition() {
  return storage.get(MAP_POSITION, null)
}

export function setReportLikeList(arr) {
  if (arr) {
    storage.set(REPORT_LIKE_LIST, arr)
  } else {
    storage.remove(REPORT_LIKE_LIST)
  }
}

export function getReportLikeList() {
  return storage.get(REPORT_LIKE_LIST, ['11101','11102','11034','11038','11039','11089','11800'])
}

export function setAutoLogin(val) {
  if (typeof val === 'object') {
    storage.set(AUTO_LOGIN, val)
  } else {
    storage.remove(AUTO_LOGIN)
  }
}

export function getAutoLogin() {
  return storage.get(AUTO_LOGIN, {
    u: null,
    p: null,
    isAgree:true,
    isAuto: false
  })
}

export function setUserRole(val) {
  if (val) {
    storage.set(USER_ROLE, val)
  } else {
    storage.remove(USER_ROLE)
  }
}

export function getUserRole() {
  return storage.get(USER_ROLE, {})
}

export function getMessagePhoneNumber() {
  return storage.get(MESSAGE_PHONE_NUMBER, '')
}

export function setMessagePhoneNumber(val) {
  return storage.set(MESSAGE_PHONE_NUMBER, val)
}

export function addNotifyHistoryList(val) {
  const list = storage.get(NOTIFY_HISTORY_LIST, [])
  list.push(val)
  return storage.set(NOTIFY_HISTORY_LIST, list)
}

export function getNotifyHistoryList() {
  return storage.get(NOTIFY_HISTORY_LIST, [])
}

export function setNotifyHistoryList(val) {
  return storage.set(NOTIFY_HISTORY_LIST, val)
}

export function setColumnWidth(val) {
  if (val) {
    return storage.set(COLUMN_WIDTH, val)
  } else {
    return storage.remove(COLUMN_WIDTH)
  }
}

export function getColumnWidth() {
  return storage.get(COLUMN_WIDTH, {})
}

export function setLastLoginInfo(val) {
  if (val) {
    return storage.set(LAST_LOGIN_INFO, val)
  } else {
    return storage.remove(LAST_LOGIN_INFO)
  }
}

export function getLastLoginInfo() {
  return storage.get(LAST_LOGIN_INFO, {})
}

export function setLanguage(val) {
  if (val) {
    storage.set(LANGUAGE_KEY, val)
  } else {
    storage.remove(LANGUAGE_KEY)
  }
}

export function getLanguage(val) {
  return storage.get(LANGUAGE_KEY, val)
}

export function setDefaultMap(name) {
  if (name) {
    return storage.set(DEFAULT_MAP, name)
  } else {
    return storage.remove(DEFAULT_MAP, name)
  }
}

export function getDefaultMap(name) {
  return storage.get(DEFAULT_MAP, name)
}

export function setPlaySound(val) {
  return storage.set(PLAY_SOUND, val)
}

export function getPlaySound(val) {
  return storage.get(PLAY_SOUND, val)
}

export function getLastActiveDay(val) {
  return storage.get(LAST_ACTIVE_DAY, val)
}

export function setLastActiveDay(val) {
  return storage.set(LAST_ACTIVE_DAY, val)
}

export function setOutUrl(val) {
  if (val) {
    return storage.set(OUT_URL, val)
  } else {
    return storage.remove(OUT_URL)
  }
}

export function getOutUrl() {
  return storage.get(OUT_URL)
}

export function setGoogleKey(val) {
  if (val) {
    return storage.set(GOOGLE_KEY, val)
  } else {
    return storage.remove(GOOGLE_KEY)
  }
}

export function getGoogleKey() {
  return storage.get(GOOGLE_KEY)
}

export function setBmapKey(val) {
  if (val) {
    return storage.set(BMAP_KEY, val)
  } else {
    return storage.remove(BMAP_KEY)
  }
}

export function getBmapKey() {
  return storage.get(BMAP_KEY)
}

export function setAmapKey(val) {
  if (val) {
    return storage.set(AMAP_KEY, val)
  } else {
    return storage.remove(AMAP_KEY)
  }
}

export function getAmapKey() {
  return storage.get(AMAP_KEY)
}

export function getVisitedViewInfo() {
  return storage.get(VISITED_VIEW_INFO)
}

export function setVisitedViewInfo(val) {
  if (val) {
    return storage.set(VISITED_VIEW_INFO, val)
  } else {
    return storage.remove(VISITED_VIEW_INFO)
  }
}

export function setLanguageVisible(val) {
  if(typeof val === 'boolean') {
    return storage.set(LANGUAGE_VISIBLE, val)
  } else {
    return storage.remove(LANGUAGE_VISIBLE)
  }
}

export function getLanguageVisible () {
  return storage.get(LANGUAGE_VISIBLE, true)
}

export function getLayout() {
  return storage.get(LAYOUT_KEY)
}

export function setLayout(val) {
  return storage.set(LAYOUT_KEY, val)
}

export function setMonitorCache(val) {
  if (val) {
    return storage.set(MONITOR_CACHE, val)
  } else {
    return storage.remove(MONITOR_CACHE)
  }
}

export function getMonitorCache(defaultVal) {
  return storage.get(MONITOR_CACHE, defaultVal)
}

export function setTrackCache(val) {
  if (val) {
    return storage.set(TRACK_CACHE, val)
  } else {
    return storage.remove(TRACK_CACHE)
  }
}

export function getTrackCache(defaultVal) {
  return storage.get(TRACK_CACHE, defaultVal)
}


export function setTrackConfig(val) {
  if (val) {
    return storage.set(TRACK_CONFIG, val)
  } else {
    return storage.remove(TRACK_CONFIG)
  }
}

export function getTrackConfig(defaultVal) {
  return storage.get(TRACK_CONFIG, defaultVal)
}

export function setDeviceCache(val) {
  if (val) {
    const list = storage.get(DEVICE_CACHE, [])
    list.push(val)
    return storage.set(DEVICE_CACHE, list)
  } else {
    return storage.remove(DEVICE_CACHE)
  }
}
export function removeDeviceCache(val) {
  if (val) {
    const list = storage.get(DEVICE_CACHE, [])
    for (const i of list) {
      if (i.ObjectID === val.ObjectID) {
        const index = list.indexOf(i)
        list.splice(index, 1)
        break
      }
    }
    return storage.set(DEVICE_CACHE, list)
  }
}
export function getDeviceCache(defaultVal) {
  return storage.get(DEVICE_CACHE, defaultVal)
}


export function setSessionConnectKey(val) {
  if (val) {
    return storage.session.set(S_LONG_CONNECT_KEY, val)
  } else {
    return storage.session.remove(S_LONG_CONNECT_KEY)
  }
}

export function getSessionConnectKey() {
  return storage.session.get(S_LONG_CONNECT_KEY)
}

export function setRiskRefreshTime(val) {
  if (val) {
    return storage.set(RISK_REFRESH_TIME, val)
  } else {
    return storage.remove(RISK_REFRESH_TIME)
  }
}

export function getRiskRefreshTime() {
  return storage.get(RISK_REFRESH_TIME)
}

export function setCustomSetting(key, val) {
  let setting = storage.get(CUSTOM_SETTING, {})
  if (val) {
    setting[key] = val
  } else {
    delete setting[key]
  }

  storage.set(CUSTOM_SETTING, setting)
}

export function getCustomSetting(key) {
  let setting = storage.get(CUSTOM_SETTING, {})
  return setting[key]
}

export function getAsideVisible() {
  return storage.get(ASIDE_TREE_VISIBLE, true)
}

export function setAsideVisible(val) {
  return storage.set(ASIDE_TREE_VISIBLE, val)
}

export function setShowMore(val) {
  if (val) {
    return storage.set(IS_SHOW_MORE, val)
  } else {
    return storage.remove(IS_SHOW_MORE)
  }
}

export function getShowMore(val) {
  return storage.get(IS_SHOW_MORE, val)
}