<template>
  <div class="wrapper">
    <svg class="icon no-data" :class="`mode-${mode}`" aria-hidden="true" :fill="color" v-if="mode==1">
        <use xlink:href="#icon-zanwushuju"></use>
    </svg>
    <svg class="icon no-data" :class="`mode-${mode}`" aria-hidden="true" :fill="color" v-if="mode==2">
        <use xlink:href="#icon-noData"></use>
    </svg>
    <p class="no-text" :class="`mode-${mode}`" :style="{'color':color}">{{text}}</p>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: 'no data'
    },
    color: {
      type: String,
      default: '#999999'
    },
    mode: {
      type: Number,
      default: 1
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  text-align: center;
  .no-data {
    font-size: 140px;
    &.mode-1 {

    }
    &.mode-2 {
      font-size: 100px;
    }
  }
  .no-text {
    &.mode-1 {
      transform: translateX(-5px) translateY(-15px);
    }
    &.mode-2 {

    }
    margin: 0;
    font-size: 14px;
  }
}

</style>
