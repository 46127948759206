<template>
  <div class="main-wrapper" :class="{'manage-page': manage, 'footer-on': footer, 'tool-on': tool}">
    <div class="main--tool ipx-0 regular--text" v-if="tool" :style="headerStyle">
      <slot name="tool"></slot>
      <div class="main--toolBorderTop" :style="borderStyle" v-if="borderTop"></div>
    </div>
    <div class="main-content regular--text" :style="contentStyle">
      <slot></slot>
    </div>
    <div class="main-footer regular--text" v-if="footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tool: {
      type: Boolean,
      default: true
    },
    manage: {
      type: Boolean,
      default: false
    },
    detail: {
      type: Boolean,
      default: false
    },
    toolBackgroundColor: {
      type: String,
      default: 'transparent'
    },
    toolBackgroundImage: {
      type: String,
      default: ''
    },
    toolColor: {
      type: String,
      default: 'inherit'
    },
    footer: {
      type: Boolean,
      default: false
    },
    borderLeftColor: {
      type: String,
      default: null
    },
    borderTop: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    headerStyle() {
      if (this.toolBackgroundImage) {
        return {
          backgroundImage: this.toolBackgroundImage,
          color: this.toolColor
        }
      }

      return {
        backgroundColor: this.toolBackgroundColor,
        color: this.toolColor
      }
    },
    borderStyle() {
      if (this.borderLeftColor) {
        return {
          // borderLeftColor: this.borderLeftColor,
          // borderTopColor: this.borderLeftColor
        }
      } else {
        return {}
      }
    },
    contentStyle() {
      if (this.borderLeftColor) {
        return {
          // borderRight: '1px solid ' + this.borderLeftColor
        }
      } else {
        return {}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/mixin.scss';
@import '../styles/variables-simple.scss';
.main-wrapper {
  $header-height: $page-header-height;
  $tool-height: $page-main-tool-height;
  $footer-height: 48px;
  position: relative;
  .main--tool {
    height: $tool-height;
    line-height: $tool-height;
    box-sizing: border-box;
    user-select: none;
    position: relative;
    z-index: 2;
    // border-top-right-radius: 8px;
    // border-bottom: 1px solid #e6e6e6;
    &BorderTop {
      position: absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      z-index: -1;
      background-color: transparent;
      border-top: 1px solid transparent;
      border-left: 1px solid transparent;
      border-top-left-radius: 8px;
    }
    // &BorderLeft {
    //   position: absolute;
    //   top:0;
    //   left:0;
    //   height:100%;
    //   width:1px;
    //   z-index: 2;
    //   background-color: transparent;
    // }
  }
  .main-content {
    @include scroll-bar;
    min-height: $page-main-height;
    min-height: var(--page-main-height);
    // overflow-y: auto;
  }
  &.tool-on .main-content {
    height: $page-main-body-height;
  }
  .main-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 48px;
    padding: 0 24px;
    z-index: 1;
    width: 100%;
    border-top: 1px solid #e6e6e6;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: #FFF;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
  }

  &.footer-on {
    .main-content {
      height: calc(100vh - #{$header-height} - #{$tool-height} - #{$footer-height});
      position: relative;
      & ::v-deep .v-wrapper {
        .v-nav {

        }
        .v-body {
          @include scroll-bar;
        }
      }
    }
  }

  &.manage-page {
    position: relative;
    & ::v-deep .page__detail {
      box-sizing: border-box;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      z-index: 100;
      background-color: #FFF;
    }
  }
}
</style>

