const desc = ['正北', '东北', '正东', '东南', '正南', '西南', '正西', '西北']
const desc_en = ['North', 'North East', 'East', 'South East', 'South', 'South West', 'West', 'North West']
export function getDirectionDescription(direction, locale) {
  direction = parseInt(direction)
  //console.log('getDirectionDescription', direction)
  if (direction != direction) {
    return ''
  }


  const index = parseInt((direction + 22) % 360 / 45)

  //console.log('getDirectionDescription', index)
  return locale === 'zh' ? desc[index] : desc_en[index]
}

export const regions = [
  { label: '(UTC -12:00) Eniwetok, Kwajalein', value: -12.0 },
  { label: '(UTC -11:00) Midway Island, Samoa', value: -11.0 },
  { label: '(UTC -10:00) Hawaii', value: -10.0 },
  { label: '(UTC -9:00) Alaska', value: -9.0 },
  { label: '(UTC -8:00) Pacific Time (US & Canada)', value: -8.0 },
  { label: '(UTC -7:00) Mountain Time (US & Canada)', value: -7.0 },
  { label: '(UTC -6:00) Central Time (US & Canada), Mexico City', value: -6.0 },
  { label: '(UTC -5:00) Eastern Time (US & Canada), Bogota, Lima', value: -5.0 },
  { label: '(UTC -4:30) Caracas', value: -4.5 },
  { label: '(UTC -4:00) Atlantic Time (Canada), Caracas, La Paz', value: -3.0 },
  { label: '(UTC -3:30) Newfoundland', value: -3.5 },
  { label: '(UTC -3:00) Brazil, Buenos Aires, Georgetown', value: -3.0 },
  { label: '(UTC -2:00) Mid-Atlantic', value: -2.0 },
  { label: '(UTC -1:00 hour) Azores, Cape Verde Islands', value: -1.0 },
  { label: '(UTC) Western Europe Time, London, Lisbon, Casablanca', value: +0 },
  { label: '(UTC +1:00 hour) Brussels, Copenhagen, Madrid, Paris', value: +1.0 },
  { label: '(UTC +2:00) Kaliningrad, South Africa', value: +2.0 },
  { label: '(UTC +3:00) Baghdad, Riyadh, Moscow, St. Petersburg', value: +3.0 },
  { label: '(UTC +3:30) Teheran', value: +3.5 },
  { label: '(UTC +4:00) Abu Dhabi, Muscat, Baku, Tbilisi', value: +4.0 },
  { label: '(UTC +4:30) Kabul', value: +4.5 },
  { label: '(UTC +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent', value: +5.0 },
  { label: '(UTC +5:30) Mumbai, Calcutta, Madras, New Delhi', value: +5.5 },
  { label: '(UTC +6:00) Almaty, Dhaka, Colombo', value: +6.0 },
  { label: '(UTC +6:30) Yangon', value: +6.5 },
  { label: '(UTC +7:00) Bangkok, Hanoi, Jakarta', value: +7.0 },
  { label: '(UTC +8:00) Beijing, Perth, Singapore, Hong Kong', value: +8.0 },
  { label: '(UTC +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk', value: +9.0 },
  { label: '(UTC +9:30) Adelaide, Darwin', value: +9.5 },
  { label: '(UTC +10:00) Eastern Australia, Guam, Vladivostok', value: +10.0 },
  { label: '(GMT +11:00) Magadan, Solomon Islands, New Caledonia, East Australia DST', value: +11.0 },
  { label: '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka', value: +12.0 },
  { label: '(GMT +13:00) New Zealand DST', value: +13.0 },
]
