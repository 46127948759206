import request from '@/common/request'
import { commonParams } from '@/api/config'
import { getToken } from '@/common/auth'

export function Query(pageIndex, pageSize, sortKey, sortDirect, filters, HoldID, hasChild) {
  const loginKey = getToken()
  return request({
    url: './query/QuerySkillman',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      pageIndex,
      pageSize,
      sortKey,
      sortDirect,
      filters,
      HoldID,
      hasChild
    })
  })
}

export function QuerySkillmanExternal(pageIndex, pageSize, sortKey, sortDirect, filters, HoldID, hasChild) {
  const loginKey = getToken()
  return request({
    url: './query/QuerySkillmanExternal',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      pageIndex,
      pageSize,
      sortKey,
      sortDirect,
      filters,
      HoldID,
      hasChild
    })
  })
}

export function Add(newItem, hasChild) {
  const loginKey = getToken()
  return request({
    url: './add/AddInstallPeople',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      newItem,
      hasChild
    })
  })
}

export function Delete(InstallPeopleIDs) {
  const loginKey = getToken()
  return request({
    url: './delete/DeleteSkillMan',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      InstallPeopleIDs
    })
  })
}

export function Modify(newItem, hasChild) {
  const loginKey = getToken()
  return request({
    url: './modify/ModifySkillMan',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      newItem,
      hasChild
    })
  })
}

export function Fetch(KeyID) {
  const loginKey = getToken()
  return request({
    url: './query/QuerySkillManInfo',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      KeyID
    })
  })
}

export function Get(KeyID) {
  const loginKey = getToken()
  return request({
    url: './query/QuerySkillManInfo',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      KeyID
    })
  })
}

export function Export(filters, HoldID, hasChild, Ids) {
  const loginKey = getToken()
  return request({
    url: './query/ExportSkillman',
    method: 'post',
    responseType: 'blob',
    data: Object.assign({}, commonParams, {
      loginKey,
      filters,
      HoldID,
      hasChild,
      Ids
    })
  })
}

export function Import(fileName, HoldID) {
  const loginKey = getToken()
  return request({
    url: './add/ImportSkillManFromExcel',
    method: 'post',
    timeout: 120000,
    data: Object.assign({}, commonParams, {
      loginKey,
      fileName,
      HoldID
    })
  })
}

export function DownLoadImportSkillManDetail(fileName) {
  const loginKey = getToken()
  return request({
    url: './add/DownLoadImportSkillManDetail',
    method: 'post',
    responseType: 'blob',
    data: Object.assign({}, commonParams, {
      loginKey,
      fileName
    })
  })
}