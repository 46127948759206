<template>
  <span v-if="firstLoad==true" @click="enter" key="1">{{$t('commonPanel.clickViewLocation')}}</span>
  <span v-else @click="toggleShow" key="2" :title="disabled?'' : (showAddress?$t('commonPanel.showLngLat'):$t('commonPanel.showLocation'))"
  :class="{'noPosition': !hasPosition}"
  >{{time|textFilter}} {{hasPosition?showText:$t('commonPanel.noPlaceTip')}}</span>
</template>

<script>
import { GetAddress } from '@/api/common'
export default {
  props: {
    lon: {
      type: [String, Number],
      default: null
    },
    lat: {
      type: [String, Number],
      default: null
    },
    firstloadDefault: {
      type: Boolean,
      default: false
    },
    showDefault: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    time: {
      type: String,
      default: ''
    }
  },
  filters: {
    textFilter (val) {
      return val ? `(${val})` : ''
    }
  },
  data() {
    return {
      firstLoad: this.firstloadDefault,
      showAddress: this.firstloadDefault,
      address : null
    }
  },
  created() {
    if (this.showDefault) {
      this.fetchAddress()
      this.showAddress = true
    }
  },
  watch: {
    firstLoad() {
      this.fetchAddress()
    },
    showAddress(val) {
      if (val && !this.address) {
        this.fetchAddress()
      }
    },
    showDefault(val) {
      if (val) {
        if (this.address === null) {
          this.fetchAddress()
        }
       
        this.showAddress = true
      }
    },
    lon() {
      this.showAddress && this.fetchAddress()
    },
    lat() {
      this.showAddress && this.fetchAddress()
    }
  },
  computed: {
    hasPosition() {
      return this.lon && this.lat
    },
    LonLat() {
      return this.hasPosition ? `${this.lon + "," + this.lat}` : '暂无位置信息'
    },
    showText() {
      return this.showAddress ? this.address : this.LonLat
    }
  },
  methods: {
    enter() {
      if (this.disabled) {
        return
      }
      this.firstLoad =false
    },
    fetchAddress() {
      this.address = '...'
      if (this.lon === null && this.lat === null) {
        return
      }
      GetAddress(this.lon, this.lat).then(ret => {
        this.address = ret.data.address
      })
    },
    toggleShow(e) {
      if (this.disabled) {
        this.$emit('click')
        return
      }
      if (!this.hasPosition) return
      this.showAddress = !this.showAddress
      this.$emit('click', e)
    }
  }
}
</script>

<style lang="scss" scoped>
.noPosition {
  color: #606266;
  cursor: default;
  &:hover {
    text-decoration: none;
  }
}
span:not(.noPosition) {
  cursor: pointer;
  &:hover {
    opacity: .8;
  }
}
</style>
