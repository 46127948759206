import request from '@/common/request'
import { commonParams } from '@/api/config'
import { getToken } from '@/common/auth'

export function Query(pageIndex, pageSize, sortKey, sortDirect, filters, HoldID, hasChild) {
  const loginKey = getToken()
  return request({
    url: './query/QueryVehicle',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      pageIndex,
      pageSize,
      sortKey,
      sortDirect,
      filters,
      HoldID,
      hasChild
    })
  })
}

export function Add({HoldID, VehicleName = '', VehicleBrandID, VehicleTypeID, Remark = ''}) {
  const loginKey = getToken()
  return request({
    url: './add/AddVehicle',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      HoldID, VehicleName, VehicleBrandID, VehicleTypeID, Remark
    })
  })
}

export function AddBatch(fileName) {
  const loginKey = getToken()
  return request({
    url: './add/AddCustomerBatch',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      fileName
    }),
    timeout: 60000
  })
}

export function Delete(VehicleIDs) {
  const loginKey = getToken()
  return request({
    url: './delete/DeleteVehicle',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      VehicleIDs
    })
  })
}

export function Modify({CID, Company, Contact = '', Mobile = '', TEL = '', Fax = '', Email = '', Website = '', QQ = '', Wechat = '',
  Skype = '', Position = '', Address = '', TypeID = 1, Comment = '', SalesID = 0, Area = []}) {
  const loginKey = getToken()
  return request({
    url: './modify/ModifyCustomer',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      CID, Company, Contact, Mobile, TEL, Fax, Email, Website, QQ, Wechat,
      Skype, Position, Address, TypeID, Comment, SalesID, Area
    })
  })
}

export function ExportCustomer(pageIndex, pageSize, sortKey, sortDirect, filters) {
  const loginKey = getToken()
  return request({
    url: './query/ExportCustomer',
    method: 'post',
    responseType: 'blob',
    data: Object.assign({}, commonParams, {
      loginKey,
      pageIndex,
      pageSize,
      sortKey,
      sortDirect,
      filters
    })
  })
}

export function QueryAutomaticData(pageIndex, pageSize, sortKey, sortDirect, filters, mode, minRate, useSimple) {
  const loginKey = getToken()
  return request({
    url: './query/QueryAutomaticData',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      pageIndex,
      pageSize,
      sortKey,
      sortDirect,
      filters,
      mode,
      minRate,
      useSimple
    })
  })
}

export function AddVehicleComplex(newVehicleItem, newPeopleItem, devices) {
  const loginKey = getToken()
  return request({
    url: './add/AddVehicleComplex',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      newVehicleItem,
      newPeopleItem,
      devices
    })
  })
}

export function Get_zd(VehicleID, holdId = null) { //新疆准东定制接口
  const loginKey = getToken()
  return request({
    url: './query/ZD_QueryVehicleComplex',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      VehicleID,
      holdId
    })
  })
}


export function QueryVehicleComplex(VehicleID, holdId = null) {
  const loginKey = getToken()
  return request({
    url: './query/QueryVehicleComplex',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      VehicleID,
      holdId
    })
  })
}

export function Get(VehicleID, holdId = null) {
  const loginKey = getToken()
  return request({
    url: './query/QueryVehicleComplex',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      VehicleID,
      holdId
    })
  })
}

export function QueryVehicleComplexByObjectID(ObjectID, holdId = null) {
  const loginKey = getToken()
  return request({
    url: './query/QueryVehicleComplexByObjectID',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      ObjectID,
      holdId
    })
  })
}

export function ModifyVehicle({VehicleID, HoldID, VehicleName='', VehicleBrandID, VehicleTypeID, remark=''}) {
  const loginKey = getToken()
  return request({
    url: './modify/ModifyVehicle',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      VehicleID,
      HoldID,
      VehicleName, VehicleBrandID, VehicleTypeID, remark
    })
  })
}

export function ModifyVehicleComplex(newVehicleItem, newPeopleItem) {
  const loginKey = getToken()
  return request({
    url: './modify/ModifyVehicleComplex',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      newVehicleItem,
      newPeopleItem
    })
  })
}

export function ZD_ModifyVehicleComplex(newVehicleItem, newPeopleItem,newZD_Item) {
  const loginKey = getToken()
  return request({
    url: './modify/ZD_ModifyVehicleComplex',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      newVehicleItem,
      newPeopleItem,
      newZD_Item
    })
  })
}


export function BatchChangeParnetHoldForVehicle(VehicleIDs, newParentHoldID) {
  const loginKey = getToken()
  return request({
    url: './modify/BatchChangeParnetHoldForVehicle',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      VehicleIDs,
      newParentHoldID
    })
  })
}

export function Export(HoldID, hasChild) {
  const loginKey = getToken()
  return request({
    url: './query/ExportVehicle',
    method: 'post',
    responseType: 'blob',
    data: Object.assign({}, commonParams, {
      loginKey,
      HoldID,
      hasChild
    })
  })
}

export function ExportVehicle_af(HoldID, hasChild) {
  const loginKey = getToken()
  return request({
    url: './query/ExportVehicle_af',
    method: 'post',
    responseType: 'blob',
    data: Object.assign({}, commonParams, {
      loginKey,
      HoldID,
      hasChild
    })
  })
}

export function Import(fileName, HoldID) {
  const loginKey = getToken()
  return request({
    url: './add/ImportVehicleFromExcel',
    method: 'post',
    timeout: 60000,
    data: Object.assign({}, commonParams, {
      loginKey,
      fileName,
      HoldID
    })
  })
}

export function DownLoadImportVehicleDetail(fileName) {
  const loginKey = getToken()
  return request({
    url: './add/DownLoadImportVehicleDetail',
    method: 'post',
    responseType: 'blob',
    data: Object.assign({}, commonParams, {
      loginKey,
      fileName
    })
  })
}
