<template>
  <el-dialog :visible.sync="visible" v-if="visibleReal" @closed="closed" :title="$t('dialogMessage.name')" custom-class="v-dialog v-dialog--md v-dialog--mdpaading v-gs-dialog" class="dialog-message" append-to-body>
    <div class="tab-wrapper">
      <div class="tab-nav">
        <tree-user
          :title="$t('dialogMessage.selectVehicle')"
          v-if="treeVisible"
          show-icon
          show-vehicle
          :show-object-count.sync="showObjectCount"
          :use-local-setting="false"
          :mdtTypeID="currentCommand"
          :widthFixed= 'false'
          @check="treeCheck"
        ></tree-user>
      </div>
      <div class="tab-body">
        <div class="input-area">
          <el-form label-width="6em">
            <el-form-item :label="$t('dialogBatchCommand.selectType')">
             <el-select v-model="currentCommand">
                <el-option
                  v-for="item in config.MDTTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('dialogMessage.searchInfo') + '：'">
              <el-autocomplete
              v-model="autocompleText"
              :fetch-suggestions="fetchData"
              :placeholder="$t('dialogMessage.queryPlaceHolder')"
              @select="handerSelect"
              :style="(form.messageid&&autocompleText) ? `width:calc(100% - 32px)` : 'width:100%'">
              </el-autocomplete>
              <span v-if="form.messageid&&autocompleText" @click="deleteMsg" class="closeIcon"><i class="el-icon-delete"></i></span>
            </el-form-item>
            <el-form-item :label="$t('dialogMessage.searchBody') + '：'">
              <el-input type="textarea" v-model="form.message" :autosize="{ minRows: 11, maxRows: 16}" :placeholder="$t('dialogMessage.bodyPlaceHolder')">
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" class="border" size="medium" :disabled="btnLoading" @click="sendMsg" plain>{{$t('dialogMessage.sendButton')}}</el-button>
              <el-button type="primary" class="border" size="medium" :disabled="btnLoading" @click="sendMsgAndSave" plain>{{$t('dialogMessage.saveAndSend')}}</el-button>
            </el-form-item>
          </el-form>
          <!-- <el-row>
            <el-input type="textarea" :autosize="{ minRows: 6, maxRows: 12}" placeholder="请输入信息内容" v-model="phoneNumber"></el-input>
          </el-row> -->
        </div>
      </div>

    </div>

  </el-dialog>
</template>

<script>
import TreeUser from '@/views/common/tree-user'
import * as API from '@/api/message'
import { GetObjectIdByMixID } from '@/api/common'
import * as config from '@/views/batch/config/batch-command-config'
export default {
  data() {
    return {
      config: Object.assign({}, config),
      visible: false,
      visibleReal: false,
      treeVisible: true,
      currentCommand: 10,
      checkedKeys: [],
      checkedObjectKeys: [],
      autocompleText: null,
      showObjectCount: false,
      btnLoading: false,
      form: {
        messageid: null,
        message: null
      }
    }
  },
  components: {
    TreeUser
  },
  watch:{
     currentCommand() {
      this.checkedObjectKeys.splice(0)
      // this.checkedObjectList.splice(0)
      this.treeVisible = false
      setTimeout(() => {
        this.treeVisible = true
      })
    },
  },
  methods: {
    open() {
      this.visible = true
      this.visibleReal = true
    },
    closed() {
      this.visibleReal = false
    },
    treeCheck(keys) {
      this.checkedKeys = keys.slice()
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        this.fetchMixIds()
        this.timer = null
      }, 50)
    },
    fetchMixIds() {
      this.btnLoading = true
      GetObjectIdByMixID(this.checkedKeys, this.currentCommand).then(ret => {
        this.btnLoading = false
        this.checkedObjectKeys = ret.data
      })
    },
    fetchData(key, cb) {
      API.GetSmsInfo(key).then(ret => {
        if (!ret.data.errCode) {
          cb(ret.data)
        }
      })
    },
    handerSelect(item) {
      this.form.messageid = item.id
      this.form.message = item.value
    },
    sendMsg() {
      if (!this.form.message) {
        this.$alert(this.$t('dialogMessage.bodyPlaceHolder'))
        return
      }

      if (!this.checkedObjectKeys.length) {
        this.$alert(this.$t('dialogMessage.pleaseSelectVehicle'))
        return
      }

      this.send(this.form.message, this.checkedObjectKeys, false)
    },
    sendMsgAndSave() {
      if (!this.form.message) {
        this.$alert(this.$t('dialogMessage.bodyPlaceHolder'))
        return
      }

      if (!this.checkedObjectKeys.length) {
        this.$alert(this.$t('dialogMessage.pleaseSelectVehicle'))
        return
      }

      this.send(this.form.message, this.checkedObjectKeys, true)
    },
    send(text, objectids, save) {
      API.SendSmsInfo(text, objectids, save).then(ret => {
        if (!ret.data.errCode) {
          this.$message({
            message: this.$t('dialogMessage.sendSuccessed'),
            type: 'success'
          })
        }
      })
    },
    deleteMsg() {
      if (!this.form.messageid) {
        return
      }

      const ret = confirm(this.$t('dialogMessage.confirmDelete'))
      if (!ret) {
        return
      }

      API.DeleteSmsInfo(this.form.messageid).then(ret => {
        if (!ret.data.errCode) {
          this.$message({
            message: this.$t('dialogMessage.deleteSuccessed'),
            type: 'success'
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.tab-wrapper {
  display: flex;
  height: 590px;
  align-items: stretch;
  .tab-nav {
    width: 300px;
    position: relative;
    border: 1px solid #afdff2;
    border-radius: 5px;
  }
  .tab-body {
    flex-grow: 1;
  }
}

.dialog-message ::v-deep {
  .el-dialog__body {
    padding: 15px 24px 24px;
    & ::v-deep .el-input__inner{
      color: #fff;
      background-color: #001025;
      border-color: #001025;
      border-bottom-color: #617895;
    }
  }
}
.input-area {
  max-width: 560px;
  margin: 48px auto;
   & ::v-deep .el-input__inner{
    color: #fff;
    background-color: #001025;
    border-color: #001025;
    border-bottom-color: #617895;
  }
   & ::v-deep .el-textarea__inner{
      border-color: #617895;
      background-color: #2c394a;
      color: #fff;
   }
   .el-button{
     border:none;
     color:#000;
     width: 120px;
      line-height: 22px;
     background: url(../../../assets/images/form-btn1.png) no-repeat center;
     background-size: auto 100%;
   }
   .el-button:hover{
     color:#000;
     background: url(../../../assets/images/form-btn1.png) no-repeat center;
     background-size: auto 100%;
   }
   .el-button:focus{
     color:#000;
     background: url(../../../assets/images/form-btn1.png) no-repeat center;
     background-size: auto 100%;
   }
}

.closeIcon {
  display: inline-block;
  height: 32px;
  line-height: 32px;
  width: 32px;
  text-align: center;
  &:hover {
    color: #cc3333;
    cursor: pointer;
  }
}
</style>

