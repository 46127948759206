import Ripple from './ripple'
//import drag from './drag'

export {
  Ripple,
  //drag
}

export default function install (Vue) {
  Vue.directive('ripple', Ripple)
  //Vue.directive('drag',drag)
}

