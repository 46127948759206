export const MDTTypeList = [{
    label: 'VDF',
    value: 10,
    category: [{
        name: 'line',
        //list: ['锁车/解锁', '回传间隔', '超速报警设置', '控制终端状态', '里程参数设置', '设置功能开关', '其他设置', '设置低油量/油量异常报警阀值', '设置号码', '设置油量特征参数', '电话回拨', '立即拍照', '连续拍照', '拍照间隔设置', '紧急抓拍设置', '条件抓拍设置', '图像分辨率设置', '位移报警设置', '震动报警设置', '查询ICCID', '远程升级', '高级指令']
        list: ['锁车/解锁', '回传间隔', '超速报警设置', '控制终端状态', '里程参数设置', '设置功能开关', '其他设置', '设置低油量/油量异常报警阀值', '设置号码', '设置油量特征参数', '立即拍照', '连续拍照', '拍照间隔设置', '紧急抓拍设置', '条件抓拍设置', '图像分辨率设置', '位移报警设置', '震动报警设置', '查询ICCID', '远程升级', '高级指令']
      },
      {
        name: 'offline',
        list: ['无线闹钟', '追踪设置', '控制终端状态', '运动静止回传间隔设置','定位模式', '拆机报警设置', '其他设置', '设置号码', '查询ICCID', '远程升级', '高级指令']
      }
    ]
  },
  {
    label: 'VDF2',
    value: 21
  },
  {
    label: '808',
    value: 20,
    category: [{
        name: 'line',
        list: ['锁车/解锁', '设置接入参数', '设置号码', '位置汇报参数设置', '终端通话设置', '终端电话拨打', '图像/视频设置', '终端电话拨打', '图像/视频设置', '报警参数设置', '超速参数设置', '终端关机', '终端复位', '恢复出厂设置', '终端唤醒', '打开实时视频', '停止实时视频', '设置油量报警报警参数', '高级指令', '报警开关设置', '报警发送SMS开关', '报警拍摄开关', '报警拍摄存储开关', '关键报警开关', '车辆基本资料设置', '电话本设置', '关闭数据通信', '关闭所有无线通信', '车辆控制', '设置油箱参数']
      },
      {
        name: 'offline',
        list: ['无线闹钟', '追踪设置', '临时位置跟踪控制', '设置接入参数', '设置号码', '终端关机', '恢复出厂设置', '高级指令', '定位模式', '拆机报警设置', '车辆基本资料设置'],
      }
    ]
  },
  {
    label: 'YIWEI',
    value: 24
  },
  {
    label: 'HYXT',
    value: 13
  },
  {
    label: 'SG',
    value: 15
  },
  {
    label: 'iLock',
    value: 19,
    category: [{
        name: 'line',
        list: ['锁车/解锁', '设置接入参数', '设置号码', '位置汇报参数设置', '终端通话设置', '终端电话拨打', '图像/视频设置', '终端电话拨打', '图像/视频设置', '报警参数设置', '超速参数设置', '终端关机', '终端复位', '恢复出厂设置', '终端唤醒', '打开实时视频', '停止实时视频', '设置油量报警报警参数', '高级指令', '报警开关设置', '报警发送SMS开关', '报警拍摄开关', '报警拍摄存储开关', '关键报警开关', '车辆基本资料设置', '电话本设置', '关闭数据通信', '关闭所有无线通信', '车辆控制', '设置油箱参数']
      },
      {
        name: 'offline',
        list: ['无线闹钟', '追踪设置', '临时位置跟踪控制', '设置接入参数', '设置号码', '终端关机', '恢复出厂设置', '高级指令', '定位模式', '拆机报警设置', '车辆基本资料设置'],
      }
    ]
  },
  {
    label: 'India140',
    value: 25
  }
]

export const command_808 = require('./command_808.json')
export const command_hyxt = require('./command_hyxt.json')
export const command_sg = require('./command_sg.json')
export const command_vdf = require('./command_vdf.json')
export const command_vdf2 = require('./command_vdf2.json')
export const command_yiwei = require('./command_yiwei.json')
export const command_exp = require('./command_exp.json')
export const command_ilock = require('./command_808.json')
export const commandList_exp = require('./commandList_exp.json')
export let commandList_808 = require('./commandList_808.json').slice()
export const commandList_hyxt = require('./commandList_hyxt.json')
export const commandList_sg = require('./commandList_sg.json')
export const commandList_vdf = require('./commandList_vdf.json')
export const commandList_vdf2 = require('./commandList_vdf2.json')
export const commandList_yiwei = require('./commandList_yiwei.json')
export const command_hot_list = require('./command_hot_list.json')
export const command_india140 = require('./command_india140.json')
export const commandList_india140 = require('./commandList_india140.json')
export const commandList_ilock  = require('./commandList_808.json')
