import request from '@/common/request'
import { commonParams } from '@/api/config'
import { getToken } from '@/common/auth'
export function Query(pageIndex, pageSize, sortKey, sortDirect, filters, HoldID, hasChild) {
  const loginKey = getToken()
  return request({
    url: './query/QueryHold',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      pageIndex,
      pageSize,
      sortKey,
      sortDirect,
      filters,
      HoldID,
      hasChild
    })
  })
}

export function QueryHoldDetail(HoldID, parentHoldId = null) {
  const loginKey = getToken()
  return request({
    url: './query/QueryHoldDetail',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      HoldID,
      parentHoldId
    })
  })
}

export function Get(HoldID, parentHoldId = null) {
  const loginKey = getToken()
  return request({
    url: './query/QueryHoldDetail',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      HoldID,
      parentHoldId
    })
  })
}

export function AddHold(newItem, newFun) {
  const loginKey = getToken()
  return request({
    url: './add/AddHold',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      newItem,
      newFun
    })
  })
}

export function Add(newItem, newFun) {
  const loginKey = getToken()
  return request({
    url: './add/AddHold',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      newItem,
      newFun
    })
  })
}

export function Delete(HoldIDs) {
  const loginKey = getToken()
  return request({
    url: './delete/DeleteHold',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      HoldIDs
    })
  })
}

export function Modify(newItem, newFun) {
  const loginKey = getToken()
  return request({
    url: './modify/ModifyHold',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      newItem,
      newFun
    })
  })
}

export function BatchChangeParentHoldForHold(HoldIDs, newParentHoldID) {
  const loginKey = getToken()
  return request({
    url: './modify/BatchChangeParentHoldForHold',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      HoldIDs,
      newParentHoldID
    })
  })
}

export function Export(HoldID, hasChild) {
  const loginKey = getToken()
  return request({
    url: './query/ExportHold',
    method: 'post',
    responseType: 'blob',
    data: Object.assign({}, commonParams, {
      loginKey,
      HoldID,
      hasChild
    })
  })
}
