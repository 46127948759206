<template>
  <el-dialog :title="$t('batchImport.importdetail')" :visible.sync="visible" :close-on-press-escape="false" center :close-on-click-modal="false" fullscreen @closed="closed">
    <div class="import-detail-tip">
      <label v-if="beforeImport">
        <circle1></circle1><span class="animated infinite flash">{{$t('batchImport.nowLoading')}}</span>
      </label>
      <label v-else-if="loading">
        <circle1></circle1><span class="animated infinite flash">{{$t('batchImport.nowProcess')}}( {{currentCount}} / {{totalCount}} ) </span>
      </label>
      <label v-else>
        <i class="el-icon-success"></i><span>{{$t('batchImport.nowEnd')}} ( {{currentCount}} / {{totalCount}} ) </span>
      </label>
      <el-progress :text-inside="true" :stroke-width="32" :percentage="rate" status="success"></el-progress>
    </div>
    <el-table :data="list" style="min-height: 400px;" :element-loading-text="$t('batchImport.importProcess')">
      <el-table-column :label="item.label" :prop="item.prop" v-for="(item, index) in items" :key="index"></el-table-column>
    </el-table>
    <div slot="footer">
      <el-button @click="download" size="medium" type="primary"  class="gs-button2" :disabled="loading">{{$t('batchImport.downloadDetail')}}</el-button>
      <el-button @click="close" size="medium" :disabled="loading" class="gs-button1">{{$t('batchImport.closePage')}}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { saveBlob } from '@/common/utils'
import { Circle as Circle1 } from 'vue-loading-spinner'
const REFRESH_INTERVAL = 1000
export default {
  props: {
    items: {
      type: Array,
      default: () => ([])
    },
    downloadMethod: {
      type: Function,
      default: () => {}
    },
    progress: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      fileName: '',
      visible: false,
      loading: false,
      list: [],
      rate: 0,
      currentCount: 0,
      totalCount: 0,
      beforeImport: true
    }
  },
  methods: {
    start() {
      this.visible = true
      this.beforeImport = true
    },
    open(fileName, total = 0) {
      this.fileName = fileName
      this.loading = true
      this.visible = true
      this.beforeImport = false
      this.totalCount = total

      this.currentCount = 0 // 当前完成数量
      this.rate = total == 0 ? 100 : 0 // 导入比例
      this.list = []

      if (total > 0) {
        this.loadProgress()
      }

    },
    loadProgress() {
      // 刷新进度
      if (this.progress === null) {
        return
      }

      const handle = () => {
        this.progress().then(ret => {
          this.currentCount = ret.data.data
          this.rate = this.totalCount === 0 ? 100 : parseInt( (this.currentCount * 100 / this.totalCount).toFixed(0) )
        }).finally(() => {
          if (this.currentCount >= this.totalCount) {
            clearInterval(window.timer_import_detail)
          }
        })
      }

      clearInterval(window.timer_import_detail)
      window.timer_import_detail = setInterval(handle, REFRESH_INTERVAL)
    },
    setData(list) {
      this.loading = false
      this.list = list

      this.totalCount = list.length
      this.currentCount = list.length
      this.rate = this.totalCount === 0 ? 100 : parseInt( (this.currentCount * 100 / this.totalCount).toFixed(0) )
      clearInterval(window.timer_import_detail)
    },
    close() {
      this.visible = false
    },
    closed() {
      clearInterval(window.timer_import_detail)
      this.list = []
      this.$emit('closed')
    },
    download() {
      this.downloadMethod(this.fileName).then(ret => {
        saveBlob(ret.data, `${this.$t('batchImport.importdetail')}.xlsx`)
      })
    }
  },
  components: {
    Circle1
  }
}
</script>

<style lang="scss" scoped>
.import-detail-tip {
  height: 38px;
  line-height: 38px;
  color: rgba(0, 0, 0, .95);
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  label {
    margin-right: 18px;
    & > svg {
      width: 38px;
      height: 38px;
      vertical-align: middle;
      margin-right: 12px;
    }
    & > i {
      font-size: 20px;
      color: #43A047;
      vertical-align: middle;
      margin-right: 12px;
    }
    span {
      font-style: normal;
      animation-duration: 2s;
      display: inline-block;
      color:#fff;
    }
  }
  .el-progress {
    height: 32px;
    flex: 1 1 auto;
  }
}

.el-table {
  border-top: none;
  border-left: none;
  background-color: #001025;
  & ::v-deep .el-table__header{
    th{
      padding: 8px 0;
      background: #263346;
      color: #a1a1a1;
      font-weight: 600;
      border-bottom: none;
    }
  }
    & ::v-deep .el-table__body-wrapper{
      background-color: #263346;
  }
    & ::v-deep .el-table__body{
      p{
        color:#fff;
      }
      tr{
        background-color: #001025;
        color:#fff;
      }
      tr:hover{
        background-color: #001025;
      }
      td{
      background-color: #001025!important;
      border: none !important;
      }
      td:hover{
        background-color: #001025;
      }
  }
  &::before {
    background-color: #126585;
  }
  &::after {
    background-color: #126585;
  }

}
.gs-button1{
  background: url(../../../assets/images/form-btn2.png) no-repeat center;
  background-size: auto 100%;
  color: #2F302C;
  padding: 0;
  border: none;
  height: 48px;
  min-width: 110px;
}
.gs-button1:hover{
  color: #2F302C;
  background: url(../../../assets/images/form-btn2.png) no-repeat center;
  background-size: auto 100%;
}
.gs-button2{
  background: url(../../../assets/images/form-btn1.png) no-repeat center !important;
  background-size: auto 100% !important;
  color: #2F302C !important;
  padding: 0;
  border: none;
  height: 48px;
  min-width: 110px;
}
.gs-button2:hover{
  color: #2F302C;
  background: url(../../../assets/images/form-btn1.png) no-repeat center !important;
  background-size: auto 100% !important;
}

</style>

