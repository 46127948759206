export const SET_USER_NAME = 'SET_USER_NAME'
export const SET_USER_ID = 'SET_USER_ID'
export const SET_STATE = 'SET_STATE'
export const SET_LOGIN_INFO = 'SET_LOGIN_INFO'
export const SET_CURRENT_HOLD_ID = 'SET_CURRENT_HOLD_ID'
export const SET_CURRENT_HOLD_NAME = 'SET_CURRENT_HOLD_NAME'
export const SET_CURRENT_HAS_CHILD = 'SET_CURRENT_HAS_CHILD'
export const ADD_VISITED_VIEW = 'ADD_VISITED_VIEW'
export const DEL_VISITED_VIEW = 'DEL_VISITED_VIEW'
export const DEL_OTHERS_VISITED_VIEWS = 'DEL_OTHERS_VISITED_VIEWS'
export const DEL_ALL_VISITED_VIEWS = 'DEL_ALL_VISITED_VIEWS'
export const ADD_CACHED_VIEW = 'ADD_CACHED_VIEW'
export const DEL_CACHED_VIEW = 'DEL_CACHED_VIEW'
export const DEL_OTHERS_CACHED_VIEWS = 'DEL_OTHERS_CACHED_VIEWS'
export const DEL_ALL_CACHED_VIEWS = 'DEL_ALL_CACHED_VIEWS'
export const UPDATE_VISITED_VIEW = 'UPDATE_VISITED_VIEW'
export const DEL_CACHED_TREES = 'DEL_CACHED_TREES'
export const ADD_CACHED_TREES = 'ADD_CACHED_TREES'

export const ADD_TASK_CAR = 'ADD_TASK_CAR'
export const DEL_TASK_CAR = 'DEL_TASK_CAR'
export const DEL_ALL_TASK_CAR = 'DEL_ALL_TASK_CAR'

export const ADD_TASK_DEVICES = 'ADD_TASK_DEVICES'
export const DEL_TASK_DEVICES = 'DEL_TASK_DEVICES'
export const DEL_ALL_TASK_DEVICES = 'DEL_ALL_TASK_DEVICES'

export const ADD_VIEWS_DEVICES = 'ADD_VIEWS_DEVICES'
export const DEL_VIEWS_DEVICES = 'DEL_VIEWS_DEVICES'
export const DEL_ALL_VIEWS_DEVICES = 'DEL_ALL_VIEWS_DEVICES'

export const ADD_TASK_DVR = 'ADD_TASK_DVR'
export const DEL_TASK_DVR = 'DEL_TASK_DVR'
export const DEL_ALL_TASK_DVR = 'DEL_ALL_TASK_DVR'

export const SET_CURRENT_MAP = 'SET_CURRENT_MAP'
export const SET_ALL_VIEWS = 'SET_ALL_VIEWS'
export const SET_MENU_POSITION = 'SET_MENU_POSITION'
export const SET_VER = 'SET_VER'
export const SET_HAS_MENU = 'SET_HAS_MENU'
export const SET_MONITOR_LEFT = 'SET_MONITOR_LEFT'

export const SET_BMAP_GL = 'SET_BMAP_GL'
export const SET_AMAP_GL = 'SET_AMAP_GL'