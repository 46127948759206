import request from '@/common/request'
import { commonParams } from '@/api/config'
import { getToken } from '@/common/auth'

export function Query(pageIndex, pageSize, sortKey, sortDirect, filters, HoldID, hasChild) {
  const loginKey = getToken()
  return request({
    url: './query/QueryMultiOrder',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      pageIndex,
      pageSize,
      sortKey,
      sortDirect,
      filters,
      HoldID,
      hasChild
    })
  })
}

export function Add(ObjectIDs, PayYear) {
  const loginKey = getToken()
  return request({
    url: './add/AddMultiOrder',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      ObjectIDs,
      PayYear
    })
  })
}

export function Delete(IDs) {
  const loginKey = getToken()
  return request({
    url: './delete/DeleteMultiOrder',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      IDs
    })
  })
}

export function QueryMultiOrderDetail(OrderID) {
  const loginKey = getToken()
  return request({
    url: './query/QueryMultiOrderDetail',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      OrderID
    })
  })
}

export function UpdateVIPWechat(ObjectIDs, PayYear) {
  const loginKey = getToken()
  return request({
    url: './add/UpdateVIPWechat',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      ObjectIDs,
      PayYear
    })
  })
}

export function UpdateVIPAliPay(ObjectIDs, PayYear) {
  const loginKey = getToken()
  return request({
    url: './add/UpdateVIPAliPay',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      ObjectIDs,
      PayYear
    })
  })
}

export function IsWxPaySuccess(orderNo) {
  const loginKey = getToken()
  return request({
    url: './pay/IsWxPaySuccess',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      orderNo
    })
  })
}

export function GetTotalFeeByIDs(ids, amount) {
  const loginKey = getToken()
  return request({
    url: './query/GetTotalFeeByIDs',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      ids,
      amount
    })
  })
}

export function AddMultiOrderAdmin(ObjectIDs, PayMonth, endTime, mode) {
  const loginKey = getToken()
  return request({
    url: './add/AddMultiOrderAdmin',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      ObjectIDs,
      PayMonth,
      endTime,
      mode
    })
  })
}
