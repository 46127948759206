import request from '@/common/request'
import { commonParams } from '@/api/config'
import { getToken } from '@/common/auth'
export function Query(pageIndex, pageSize, sortKey, sortDirect, filters, HoldID, hasChild) {
  const loginKey = getToken()
  return request({
    url: './query/QueryWalletList',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      pageIndex,
      pageSize,
      sortKey,
      sortDirect,
      filters,
      HoldID,
      hasChild
    })
  })
}

export function GetWalletInfo(HoldID) {
  const loginKey = getToken()
  return request({
    url: './query/GetWalletInfo',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      HoldID
    })
  })
}

export function GetWalletDiscount(buyCount) {
  const loginKey = getToken()
  return request({
    url: './query/GetWalletDiscount',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      buyCount
    })
  })
}

export function GetWxImage(totalPoints) {
  const loginKey = getToken()
  return request({
    url: './pay/GetWxImage',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      totalPoints
    })
  })
}

export function IsWxPaySuccess(orderNo) {
  const loginKey = getToken()
  return request({
    url: './pay/IsWxPaySuccess',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      orderNo
    })
  })
}

export function SendPoint(newHoldID, points) {
  const loginKey = getToken()
  return request({
    url: './modify/SendPoint',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      newHoldID,
      points
    })
  })
}

export function Export(HoldID, hasChild) {
  const loginKey = getToken()
  return request({
    url: './query/ExportWalletList',
    method: 'post',
    responseType: 'blob',
    data: Object.assign({}, commonParams, {
      loginKey,
      HoldID,
      hasChild
    })
  })
}

export function AddVpointAdmin(targetHoldID, totalPoints, remark) {
  const loginKey = getToken()
  return request({
    url: './pay/AddVpoint',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      targetHoldID,
      totalPoints,
      remark
    })
  })
}
