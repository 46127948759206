const _import = require('./_import_' + process.env.NODE_ENV)
import LayoutDefault from '@/views/layout/layout.1.vue'
import NotFoundDefault from '@/components/NotFound'
export const Layout = LayoutDefault
export const NotFound = NotFoundDefault
export const Home = _import('home/home-new')
// export const Login = _import('login/login' + process.env.VUE_APP_LOGIN)
export const Login = _import('login/login')
export const Log = _import('log/log')
export const RiskPlace = _import('risk/risk-place')
export const ManageDevice =  _import('manage/manage-device') 
export const ManagePerson = _import('manage/manage-person')
export const ManageObject = _import('manage/manage-object')
export const ManageComplex = _import('manage/manage-complex')
export const ManageHold = _import('manage/manage-hold')
export const MangaeUser = _import('manage/manage-user')
export const ManageVehicle = _import('manage/manage-vehicle')
export const ManageGroup = _import('manage/manage-group')
export const ReportOne = _import('report/report-one')
// export const ReportTwo = _import('report/report-two')
export const DetailVehicle = _import('manage/detail/detail-vehicle') 
export const DetailDevice = _import('manage/detail/detail-device')
export const DetailHold = _import('manage/detail-hold')
export const DetailUser = _import('manage/detail-user')
export const InstallDetail = _import('manage/install-detail')
export const Wallet = _import('wallet/wallet')
export const BatchExpire = _import('batch/batch-expire')
export const BatchExpire2 = _import('batch/batch-expire2')
export const Redirect = _import('redirect/index')
export const AsideTree = _import('layout/aside-tree')
export const ManageNotify = _import('notify/manage-notify')
export const Info = _import('info/info') 
export const MapControl = _import('online/map-control')
export const MapControlTrack = _import('online/map-control-track')
export const MapControlPolygon = _import('online/map-control-polygon')
export const Forget = _import('login/forget')
export const SecurityProtocol = _import('login/securityProtocol') //隐私协议
export const UsageProtocol = _import('login/usageProtocol') //软件许可和使用协议
export const Register = _import('login/register')
export const WalletView = _import('wallet/wallet-view')
export const WalletInvoice = _import('wallet/wallet-invoice')
export const Connect = _import('login/connect')
export const ImageViewer = _import('common/image-viewer')
export const VisitorLog = _import('log/visitorlog')
export const RiskRoad = _import('risk/risk-road')
// export const ManageInsurance = _import('manage/manage-insurance')
export const Tracing = _import('online/tracing')
export const Auth = _import('login/auth')