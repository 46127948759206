import request from '@/common/request'
import { commonParams } from '@/api/config'
import { getToken } from '@/common/auth'

export function Query(pageIndex, pageSize, sortKey, sortDirect, filters, HoldID, hasChild) {
  const loginKey = getToken()
  return request({
    url: './query/QueryUser',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      pageIndex,
      pageSize,
      sortKey,
      sortDirect,
      filters,
      HoldID,
      hasChild
    })
  })
}

export function QueryUserDetail(UserID, ParentHoldId=null) {
  const loginKey = getToken()
  return request({
    url: './query/QueryUserDetail',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      UserID,
      ParentHoldId
    })
  })
}

export function Get(UserID, ParentHoldId=null) {
  const loginKey = getToken()
  return request({
    url: './query/QueryUserDetail',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      UserID,
      ParentHoldId
    })
  })
}

export function GetFunTree(id, type) {
  const loginKey = getToken()
  return request({
    url: './query/GetFunTree',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      id,
      type
    })
  })
}

export function Add(newItem, newFun) {
  const loginKey = getToken()
  return request({
    url: './add/AddUser',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      newItem,
      newFun
    })
  })
}

export function Delete(UserIDs) {
  const loginKey = getToken()
  return request({
    url: './delete/DeleteUser',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      UserIDs
    })
  })
}

export function Modify(newItem, newFun) {
  const loginKey = getToken()
  return request({
    url: './modify/ModifyUser',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      newItem,
      newFun
    })
  })
}

export function Export(HoldID, hasChild) {
  const loginKey = getToken()
  return request({
    url: './query/ExportUser',
    method: 'post',
    responseType: 'blob',
    data: Object.assign({}, commonParams, {
      loginKey,
      HoldID,
      hasChild
    })
  })
}

export function SendCodeToOldMobile() {
  const loginKey = getToken()
  return request({
    url: './query/SendCodeToOldMobile',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
    })
  })
}

export function SendCodeToNewMobile(mobile) {
  const loginKey = getToken()
  return request({
    url: './query/SendCodeToNewMobile',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      mobile
    })
  })
}

export function ChangeUserMobile(oldCode, newMobile, newCode) {
  const loginKey = getToken()
  return request({
    url: './query/ChangeUserMobile',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      oldCode,
      newMobile,
      newCode
    })
  })
}

export function GetObjMobile() {
  const loginKey = getToken()
  return request({
    url: './query/GetObjMobile',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey
    })
  })
}

export function ChangeUserMobileAdmin(userId, newMobile, newCode) {
  const loginKey = getToken()
  return request({
    url: './query/ChangeUserMobileAdmin',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      userId,
      newMobile,
      newCode
    })
  })
}

export function QueryInsurance(pageIndex, pageSize, sortKey, sortDirect, filters, HoldID, hasChild) {
  const loginKey = getToken()
  return request({
    url: './query/QueryInsurance',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      pageIndex,
      pageSize,
      sortKey,
      sortDirect,
      filters,
      HoldID,
      hasChild
    })
  })
}

export function ExportInsurance(HoldID, hasChild, filters, Ids) {
  const loginKey = getToken()
  return request({
    url: './query/ExportInsurance',
    method: 'post',
    responseType: 'blob',
    data: Object.assign({}, commonParams, {
      loginKey,
      HoldID,
      hasChild,
      filters,
      Ids
    })
  })
}

export function ImportInsurance(fileName, HoldID) {
  const loginKey = getToken()
  return request({
    url: './add/ImportInsurance',
    method: 'post',
    timeout: 60000,
    data: Object.assign({}, commonParams, {
      loginKey,
      fileName,
      HoldID
    })
  })
}

export function DeleteInsurance(IDs) {
  const loginKey = getToken()
  return request({
    url: './delete/DeleteInsurance',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      IDs
    })
  })
}