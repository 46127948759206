import T from './dialog-change-mobile'
import { GetObjMobile } from '@/api/user'

const methods = Object.assign({}, T.methods, {
  open() {
    GetObjMobile().then(ret => {
      this.form.oldMobile = ret.data.data
      this.dialogVisible = true
    })
  }
})

export default Object.assign({}, T, {
  methods
})