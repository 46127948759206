<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-form label-width="100px">
          <el-row>

          </el-row>
          <el-form-item :label="`${$t('wallet.remain')}${$t('wallet.vpoint')}:`">
            <p>{{remain}}{{$t('wallet.vpoint')}}</p>
          </el-form-item>
          <el-form-item :label="`${$t('wallet.targetUser')}:`">
            <span v-if="newHoldInfo.id"><i-icon name="icon-account"></i-icon>{{newHoldInfo.name}}</span>
            <span v-else style="color:#C0C4CC;">{{$t('wallet.selectUser')}}</span>
          </el-form-item>
          <el-form-item :label="`${$t('wallet.targetCount')}:`">
            <div class="options">
              <div class="option" :class="{active: !form.isCustom && item.value === form.count}" v-for="item in options" :key="item.value" @click="changeCount(item)">{{item.label}}</div>
              <div class="option expand" :class="{active:form.isCustom}" @click="form.isCustom=true">
                <p v-if="!form.isCustom">{{$t('wallet.custom')}}</p>
                <p v-else>{{$t('wallet.giftPrefix')}}￥<el-input-number v-model="form.count" controls-position="right" :min="50" :max="999999"></el-input-number></p>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <el-row type="flex">
              <el-button type="primary" plain class="border" v-ripple style="flex-grow:1;" @click="submit" :loading="btnLoading">{{$t('common.confirm')}}</el-button>
            </el-row>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="12">
        <div style="height:410px;position:relative;border:1px solid #DCDFE6">
          <tree-user :checkbox="false" :title="$t('moveParent.selectUser')" show-icon @click="treeClick">
          </tree-user>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import TreeUser from '@/views/common/tree-user'
import * as Wallet from '@/api/wallet'
export default {
  props: {
    point: {
      type: Number,
      default: 0
    }
  },
  computed: {
    prop() {
      return {

      }
    }
  },
  data() {
    return {
      options: [
        { label: this.$t('wallet.giftPrefix') + '￥1000', value: 1000 },
        { label: this.$t('wallet.giftPrefix') + '￥500', value: 500 },
        { label: this.$t('wallet.giftPrefix') + '￥200', value: 200 },
        { label: this.$t('wallet.giftPrefix') + '￥100', value: 100 }
      ],
      btnLoading: false,
      newHoldInfo: {
        id: 0,
        name: ''
      },
      remain: null,
      form: {
        count: 100,
        isCustom: false
      }
    }
  },
  components: {
    TreeUser
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      Wallet.GetWalletInfo(null).then(ret => {
        this.remain = ret.data.TotalBalance
      })
    },
    treeClick(id, name) {
      this.newHoldInfo.id = id
      this.newHoldInfo.name = name
    },
    changeCount(item) {
      this.form.count = item.value
    },
    submit() {
      if (!this.newHoldInfo.id) {
        this.$alert(this.$t('wallet.selectUser'))
        return
      }
      if (this.form.count > this.remain) {
        this.$alert(this.$t('wallet.limitRemain', {count: this.form.count}))
        return
      }
      const maxSend = this.form.count > this.remain ? this.remain : this.form.count
      this.$confirm(
        this.$t('wallet.confirmSend', {count: maxSend, target: this.newHoldInfo.name, remain: this.remain - maxSend}), this.$t('wallet.tip'), {
        confirmButtonText: this.$t('common.ok'),
        cancelButtonText: this.$t('common.cancel'),
        lockScroll: false,
        type: 'info'
      }).then(() => {
        // 赠送
        Wallet.SendPoint(this.newHoldInfo.id, maxSend).then(ret => {
          if (!ret.data.errCode) {
            this.$emit('close')
            this.$alert(this.$t('wallet.sendSuccessed'))
          } else {
            this.$alert({
              message: ret.data.errMsg,
              type: 'error'
            })
          }
        })
      }).catch(err => {
        // 取消
        console.error(err)
      })
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.options {
  margin-right: -10px;
  margin-bottom: -10px;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  .option {
    cursor: pointer;
    width: calc(50% - 10px);
    background-color: #F8F8F8;
    height: 40px;
    line-height: 40px;
    text-align: center;
    margin-right: 10px;
    margin-bottom: 10px;
    &.active {
      background-color: #67C23A;
      color: #FFF;
    }
    &:hover {
      background-color: #67C23A;
      color: #FFF;
    }
    &.expand {
      width: calc(100% - 10px);
    }
  }
}
</style>
