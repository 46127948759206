
import {DOWNLOAD_URL} from '@/api/config'

(function () {
  var userAgent = window.navigator.userAgent;
  var isIE = userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1;
  if (isIE) {
    var reg = new RegExp('MSIE (\\d+\\.\\d+);');
    reg.test(userAgent);
    var ieVersion = parseInt(RegExp.$1, 10);
    if (ieVersion < 11) {
      window.location.href = DOWNLOAD_URL + 'static/file/index.html';
    }
  }
})();
