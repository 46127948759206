<template>
  <div class="wrapper">
    <span class="vehiclename" @click.stop="cliclHandle" :class="{ [className]: true, 'disabled':disabled }" :style="styleName">
      <slot>--</slot>
    </span>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  props: {
    color: {
      type: String,
      default: '蓝色'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    brand: {
      type: String,
      default: null
    }
  },
  computed: {
    className() {
      const names = {
        '蓝色': 'blue',
        '绿色': 'green',
        '黄色': 'yellow',
        '白色': 'white',
        '黑色': 'black'
      }
      return names[this.color] || 'blue'
    },
    styleName() {
      let color = {
        '蓝色': '#1976D2',
        '绿色': '#81C784',
        '黄色': '#FBC02D',
        '白色': '#FAFAFA',
        '黑色': 'black'
      }[this.color]
      if (!color) {
        color = this.color
      }
      return `background-color:${color} !important`
    },
    canModify() {
      return this.loginInfo.allFuns.includes(5042)
    },
    ...mapGetters([
      'loginInfo'
    ])
  },
  methods: {
    cliclHandle(e) {
      if (this.canModify) {
        this.$emit('click', e)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.brand {
  height: 21px;
  vertical-align: bottom;
  //margin-right: 2px;
  //box-shadow: 0 0 15px #606266;
  margin-bottom: 1px;
  border: 1px solid #DCDFE6;
}
.wrapper {
  position: relative;
}
.vehiclename {
  padding: 4px 8px;
  border-radius: 4px;
  position: relative;
  display:inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  line-height: 17px;
  vertical-align: middle;
  &.disabled {
    cursor: default;
  }
  &::before {
    position: absolute;
    left: 1px;
    top: 1px;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    content: '';
    display: block;
    border: 1px solid currentColor;
    border-radius: 4px;
  }
  &.blue {
    background-color: #1976D2 !important;
    color: #FFF !important;
    box-shadow: 4px 2px 5px rgba(#1976D2, .5);
    transition: all 0.3s ease;
    &:hover:not(.disabled) {
      box-shadow: none;
    }
  }
  &.green {
    background-color: #81C784 !important;
    color: #FFF !important;
    box-shadow: 4px 2px 5px rgba(#81C784, .5);
    &:hover:not(.disabled) {
      box-shadow: none;
    }
  }
  &.yellow {
    background-color: #FBC02D !important;
    color: #606266 !important;
    box-shadow: 4px 2px 5px rgba(#606266, .5);
    &:hover:not(.disabled) {
      box-shadow: none;
    }
  }
  &.white {
    background-color: #FAFAFA !important;
    color: #606266 !important;
    box-shadow: 4px 2px 5px rgba(#606266, .5);
    &:hover:not(.disabled) {
      box-shadow: none;
    }
  }
  &.black {
    background-color: #000 !important;
    color: #FFF !important;
    box-shadow: 4px 2px 5px rgba(#000, .5);
    &:hover:not(.disabled) {
      box-shadow: none;
    }
  }
}
</style>
