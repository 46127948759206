<template>
  <div class="wrapper" v-if="orderStep != COMPLETED_ORDER">
    <!-- <div class="top">
      <img class="img" src="../../assets/images/wallet.png"/>
      <p class="wallet-remain">
        {{$t('updateVip.remain')}}：{{remain}}{{$t('updateVip.vpoint')}}
      </p>
      <el-button class="iml-0 imr-3" type="primary" size="mini" round @click="$emit('go-wallet')">{{$t('updateVip.addPoint')}}</el-button>
    </div> -->
    <div class="top">
      <img class="img" src="../../assets/images/wb.png"/>
      <div class="wallet-remain">
         <h5>{{$t('updateVip.remain')}}</h5>
        <h4><strong>{{remain}}</strong>{{$t('updateVip.vpoint')}}</h4>
        <!-- <p style="font-size:14px;">￥1 = 1{{$t('wallet.vpoint')}}</p> -->
      </div>
      <el-button type="primary" size="mini" @click="$emit('go-wallet')">{{$t('updateVip.addPoint')}}</el-button>
    </div>
    <div class="middle">
      <p class="item" v-if="false">{{$t('updateVip.orderVehicle')}} <span style="color:#89ABE3; float:right;">{{vehicleName}}</span></p>
      <p class="vipdesc">{{$t('updateVip.vipTip1')}}</p>
      <p class="vipdesc">{{$t('updateVip.vipTip2')}}</p>
      <div class="borderBox">
        <div class="bdTit">{{$t('updateVip.payAmount')}}</div>
        <div class="options">
          <div class="option" :class="{active:item.value === form.PayYear}" v-for="item in options" :key="item.value" @click="changePayYear(item)">{{item.label}}{{$t('updateVip.year')}}</div>
        </div>
      </div>
      <!-- <div class="options">
        <div class="option" :class="{active:item.value === form.PayYear}" v-for="item in options" :key="item.value" @click="changePayYear(item)">{{item.label}}{{$t('updateVip.year')}}</div>
      </div> -->
      <div class="borderBox">
        <div class="bdTit">{{$t('updateVip.payMethod')}}</div>
          <div class="pay-method" @click="form.PayMethod=2">
          <img class="pay-icon" src="../../assets/images/yxx_wechat_pay_success.svg"/>
          <div class="pay-info">
            <p class="name">{{$t('updateVip.wechat')}}</p>
            <p class="desc">{{$t('wallet.alipayTip')}}</p>
          </div>
          <img class="pay-check" src="../../assets/images/check_box.svg" v-if="form.PayMethod==2"/>
          <img class="pay-check" src="../../assets/images/check_box_gray.svg" v-else/>
        </div>
        <div class="pay-method"  @click="form.PayMethod=3">
          <img class="pay-icon" src="../../assets/images/icon_alipay.svg"/>
          <div class="pay-info">
            <p class="name">{{$t('updateVip.alipay')}}</p>
            <p class="desc">{{$t('wallet.wechatTip')}}</p>
          </div>
          <img class="pay-check" src="../../assets/images/check_box.svg" v-if="form.PayMethod==3"/>
          <img class="pay-check" src="../../assets/images/check_box_gray.svg" v-else/>
        </div>
        <div class="pay-method"  @click="form.PayMethod=1">
          <img class="pay-icon vpoint" src="../../assets/icon/VPoint.png"/>
          <div class="pay-info">
            <p class="name">{{$t('updateVip.vpoint')}}</p>
            <p class="desc"></p>
          </div>
          <img class="pay-check" src="../../assets/images/check_box.svg" v-if="form.PayMethod==1"/>
          <img class="pay-check" src="../../assets/images/check_box_gray.svg" v-else/>
        </div>
      </div>
      <p class="info">
        <span>{{$t('updateVip.dateTo')}}:<span style="color:909399;">{{endTime}}</span></span>
        <!-- <span style="float:right;">{{$t('updateVip.payFee')}}:<span style="color:909399;">{{payTotal}}</span> {{form.PayMethod==1 ? $t('updateVip.vpoint') : $t('updateVip.payUnit')}}</span> -->
      </p>
    </div>
    <!-- <el-button class="bottom border" plain type="primary" @click="payCheckOrder" :loading="btnLoading">{{$t('wallet.payButton')}}</el-button> -->
    <el-button class="bottom border" plain type="primary" @click="payCheckOrder" :loading="btnLoading">{{payTotal}}{{form.PayMethod==1 ? $t('updateVip.vpoint') : $t('updateVip.payUnit')}}</el-button>
    <transition name="el-zoom-in-bottom">
      <div class="wx-img" v-if="orderStep == IN_WECHAT_IMAGE">
        <i-icon name="icon-close_circle_solid" class="wx-img-close" @click="cancelPay"></i-icon>
        <el-row type="flex" justify="center">
          <img :src="wxImgUrl"/>
        </el-row>
        <el-row type="flex" justify="center">{{$t('updateVip.payFee')}}: ￥{{payTotal}}{{$t('updateVip.payUnit')}}</el-row>
        <el-row type="flex" justify="center">
          <el-col type="flex" justify="center" align="middle">
            <i-icon name="icon-weixinzhifu" class="weixinzhifu"></i-icon>
          </el-col>
        </el-row>
      </div>
    </transition>

    <transition name="el-zoom-in-bottom">
      <div class="wx-img inOrder" v-if="orderStep == IN_ORDER" v-loading="payLoading" :element-loading-text="payLoadingText">
        <i-icon name="icon-close_circle_solid" class="wx-img-close" @click="cancelPay"></i-icon>
        <h2>{{$t('updateVip.orderTip1')}}</h2>
        <p class="orderItem clearfix">
          <span class="orderTitle">{{$t('updateVip.orderName')}}：</span>
          <span class="orderText"><img src="../../assets/icon/vip.png"/> {{$t('updateVip.updateVip')}} </span>
        </p>
        <p class="orderItem clearfix">
          <span class="orderTitle">{{$t('updateVip.orderAmount')}}：</span>
          <span class="orderText"><span class="important">{{form.PayYear}}</span> 年</span>
        </p>
        <p class="orderItem clearfix">
          <span class="orderTitle">{{$t('updateVip.orderVehicle')}}：</span>
          <span class="orderText">{{$t('updateVip.total')}} <span class="important">1</span> {{$t('updateVip.deviceUnit')}} {{$t('updateVip.device')}}</span>
        </p>
        <p class="orderItem clearfix">
          <span class="orderTitle">{{$t('updateVip.orderMethod')}}：</span>
          <span class="orderText">
            <i-icon name="icon-weixinzhifu1" v-if="form.PayMethod===2"></i-icon>
            <i-icon name="icon-zhifubao" v-if="form.PayMethod===3"></i-icon>
            <img src="../../assets/icon/VPoint.png" class="img" v-if="form.PayMethod===1" alt="">
            {{payMethodText}}
          </span>
        </p>
        <p class="orderItem clearfix">
          <span class="orderTitle">{{$t('updateVip.payFee')}}：</span>
          <span class="orderText"><span class="important">{{payTotal}}</span> {{payMethodSuffix}}</span>
        </p>
        <el-row type="flex" justify="center">
          <el-button class="orderPay" size="medium" @click="pay">{{$t('updateVip.nextButtonText')}}</el-button>
          <el-button class="orderPay cancel" size="medium" @click="cancelPay">{{$t('updateVip.backBUttonText')}}</el-button>
        </el-row>
      </div>
    </transition>
    <div class="wx-model" v-if="orderStep != PRE_ORDER && orderStep != COMPLETED_ORDER"></div>
  </div>
  <div class="wrapper wrapper--success" v-else>
    <img class="success" src="../../assets/images/success.svg"/>
    <p class="success-tip">{{$t('updateVip.updateSuccess')}}</p>
    <el-button class="bottom border" plain type="primary" @click="close" :loading="btnLoading">{{$t('wallet.bottomButton')}}</el-button>
  </div>
</template>

<script>
import * as API from '@/api/fee'
import * as Wallet from '@/api/wallet'
import miment from 'miment'
import {BASE_URL} from '@/api/config'
import {getToken} from '@/common/auth'
const PRE_ORDER = 1
const IN_ORDER = 2
const IN_WECHAT_IMAGE = 3
const COMPLETED_ORDER = 9
export default {
  props: {
    id: {
      type: Number,
      default: null,
      required: true
    },
    vehicleName: {
      default: null
    },
    serviceEndDate: {
      type: [Date, String],
      default: null
    },
    trackerType: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      PRE_ORDER,
      IN_ORDER,
      IN_WECHAT_IMAGE,
      COMPLETED_ORDER,
      orderStep: PRE_ORDER,
      options: [
        { label: '1', value: 1 },
        { label: '2', value: 2 },
        { label: '3', value: 3 },
        { label: '5', value: 5 }
      ],
      isShowSuccess: false,
      btnLoading: false,
      remain: null,
      oneYearPrice: 12,
      oneYearPrice2: 6,
      holdName: '',
      startTime: this.serviceEndDate,
      form: {
        ObjectID: null,
        ObjectName: 123123,
        VehicleName: null,
        PayYear: 1,
        PayMethod: 2
      },
      tip: 'VIP车辆保存90天以上历史数据，开放全部功能；免费车辆保存7天历史数据，无VIP功能，例如：拍照，电子围栏，油量，温度等。',
      wxImgUrl: '',
      aliPayUrl: '',
      orderNo: '',
      payLoading: false,
      payLoadingText: ''
    }
  },
  watch: {
    serviceEndDate(val) {
      this.startTime = val
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    endTime() {
      if (this.startTime) {
        return miment(this.startTime).add(this.form.PayYear, 'YYYY').format("YYYY-MM-DD")
      } else {
        return miment().add(this.form.PayYear, 'YYYY').format("YYYY-MM-DD")
      }
    },
    payTotal() {
      if (this.trackerType === 0) {
        return this.oneYearPrice * this.form.PayYear
      } else {
        return this.oneYearPrice2 * this.form.PayYear
      }
    },
    payMethodText() {
      return ['', '沃点', '微信', '支付宝'][this.form.PayMethod]
    },
    payMethodSuffix() {
      return ['', '沃点', '元', '元'][this.form.PayMethod]
    }
  },
  methods: {
    fetchData() {
      Wallet.GetWalletInfo(null).then(ret => {
        this.remain = ret.data.TotalBalance
        this.oneYearPrice = ret.data.key_price
        this.oneYearPrice2 = ret.data.key_price2
      })
    },
    changePayYear(item) {
      this.form.PayYear = item.value
    },
    payCheckOrder() {
      // 如果是支付宝
      if (this.form.PayMethod === 3) {
        // 创建支付宝支付充值钱包订单
        this.btnLoading = true
        API.UpdateVIPAliPay([this.id], this.form.PayYear).then(ret => {
          this.btnLoading = false
          if (!ret.data.errCode) {
            this.orderStep = IN_ORDER
            this.orderNo = ret.data.orderNo
            this.aliPayUrl = `${BASE_URL}/pay/AlipayByOrder?loginKey=${getToken()}&totalPoints=${this.payTotal}&OrderNo=${this.orderNo}&format=json`
          } else if (ret.data.errMsg){
            this.$alert(ret.data.errMsg)
            console.error(ret.data)
          } else {
            console.error(ret.data)
          }
        }).catch(err => {
          this.btnLoading = false
          this.$message({
            message: err,
            type: 'error'
          })
        })
        return
      }
      this.orderStep = IN_ORDER
    },
    pay() {

      if (this.form.PayMethod === 2) {
        // 创建微信支付充值钱包订单
        this.payLoading = true
        this.payLoadingText = 'loading'
        API.UpdateVIPWechat([this.id], this.form.PayYear).then(ret => {
          this.payLoading = false
          this.payLoadingText = ''
          if (!ret.data.errCode) {
            this.orderStep = IN_WECHAT_IMAGE
            this.wxImgUrl = `${BASE_URL}/pay/Image?text=${ret.data.code_url}`
            this.orderNo = ret.data.orderNo
            this.startCheckWxPay()
          } else if (ret.data.errMsg){
            this.$alert(ret.data.errMsg)
            console.error(ret.data)
          } else {
            console.error(ret.data)
          }
        }).catch(err => {
          this.payLoading = false
          this.$message({
            message: err,
            type: 'error'
          })
        })
        return
      } else if (this.form.PayMethod === 3){
        this.payLoadingText = '支付完成前请不要关闭页面'
        this.startCheckWxPay()
        window.open(this.aliPayUrl)
      } else {
        this.payLoading = true
        this.payLoadingText = '操作正在完成中'
        API.Add([this.id], this.form.PayYear).then(ret => {
          this.payLoading = false
          this.payLoadingText = '操作正在完成中'
          if (!ret.data.errCode) {
            this.orderStep = COMPLETED_ORDER
          } else if (ret.data.errMsg){
            this.$alert(ret.data.errMsg)
            console.error(ret.data)
          } else {
            console.error(ret.data)
          }
        }).catch(err => {
          this.payLoading = false
          this.$message({
            message: err,
            type: 'error'
          })
        })
      }
    },
    startCheckWxPay() {
      API.IsWxPaySuccess(this.orderNo).then(ret => {
        if (ret.data.isSuccess) {
          this.payLoading = true
          this.payLoadingText = '支付正在完成中'
          API.Add([this.id], this.form.PayYear).then(ret => {
            this.payLoading = false
            this.payLoadingText = ''
            if (!ret.data.errCode) {
              this.orderStep = COMPLETED_ORDER
            } else if (ret.data.errMsg){
              this.$alert(ret.data.errMsg)
              console.error(ret.data)
            } else {
              console.error(ret.data)
            }
          }).catch(err => {
            this.payLoading = false
            this.$message({
              message: err,
              type: 'error'
            })
          })
        } else {
          this.startCheckWxPay_timer = setTimeout(() => {
            this.startCheckWxPay()
          }, 2000)
        }
      })
    },
    endCheckWxPay() {
      if (this.startCheckWxPay_timer) {
        clearTimeout(this.startCheckWxPay_timer)
        this.startCheckWxPay_timer = null
      }
    },
    cancelPay() {
      this.orderStep = PRE_ORDER
      this.payLoading = false
      this.payLoadingText = ''
      this.endCheckWxPay()
    },
    // pay() {
    //   this.btnLoading = true
    //   API.Add([this.id], this.form.PayYear).then(ret => {
    //     this.btnLoading = false
    //     if (!ret.data.errCode) {
    //       this.orderStep = COMPLETED_ORDER
    //     } else {
    //       //console.error(ret.data)
    //     }
    //   }).catch(err => {
    //     this.btnLoading = false
    //     this.$message({
    //       message: err,
    //       type: 'error'
    //     })
    //     //console.error(ret.data)
    //   })
    // },
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  height: 595px;
  position: relative;
  &.wrapper--success {
    background-color: #89ABE3;
  }
  padding: 10px 20px 80px 20px;
}
.top {
  height: 100px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  .img {
    width: 100%;
    height: 100%;
    position: absolute;
    left:0;
    top:0;
    z-index: -1;
  }
  p{
    line-height: 22px;
    font-size: 16px;
  }
  h5{
    font-size: 12px;
    font-weight: normal;
  }
  h4{
    font-size: 20px;
    font-weight: normal;
    strong{
      font-size: 24px;
    }
  }
  .wallet-remain {
    padding-top: 10px;
    padding-left: 15px;
    color:#fff;
    
  }
  .el-button{
    display: block;
    position: absolute;
    right: 20px;
    top: 30px;
    z-index: 1;
    width: 100px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    font-size: 16px;
    color: #fff;
    border-radius: 25px;
    padding:0;
    background-color: #f1a501;
    cursor: pointer;
  }

}
.middle {
  // padding: 0 15px;
  .borderBox{
    padding: 10px 20px;
    margin-top: 10px;
    border: 1px solid #f3f3f3;
    border-radius: 10px;
  }
  .bdTit{
    height: 24px;
    font-size: 14px;
    color:#80899a;
  }
  .item {
    font-size: 14px;
    height: 38px;
    line-height: 38px;
    color: #303133;
  }
  .vipdesc {
    text-align: center;
    height: 22px;
    line-height: 22px;
    font-size: 12px;
  }
   .options {
    margin-right: -10px;
    margin-bottom: -10px;
    display: flex;
    position: relative;
    flex-wrap: wrap;
    .option {
      cursor: pointer;
      width: calc(50% - 10px);
      border: 1px solid #f9f9f9;
      border-radius: 5px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      margin-right: 10px;
      margin-bottom: 10px;
      color:#4d6182;
      font-size: 16px;
      &.active {
        color: #fff;
        background-color: #f1a501;
      }
      &:hover {
        color: #fff;
        background-color: #f1a501;
      }

      &.expand {
        width: calc(100% - 10px);
      }
    }
  }
  .tip {
    color: #67C23A;
    margin-top: 15px;
  }
  .pay-method {
    height: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .pay-icon {
      height: 40px;
      &.vpoint {
        width: 40px;
        height: auto;
      }
    }
    .pay-info {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      font-size: 12px;
      margin-left: 15px;
      .name {
        color: #000;
        margin-bottom: 5px;
      }
      .desc {
        color: #959595;
      }
    }
    .pay-check {
      height: 18px;
    }
    & + .pay-method {
      margin-top: 12px;
    }
  }
  .info {
    position: absolute;
    left: 15px;
    bottom: 70px;
    width: calc(100% - 30px);
    z-index: 1;
    text-align: center;
    color:#f1a501;
  }
}
.bottom {
  position: absolute;
  height: 40px;
  border-radius: 20px;
  width: calc(100% - 30px);
  bottom: 15px;
  left: 15px;
  &.el-buttom {
    height: 40px;
    line-height: 40px;
    
  }
}
.success {
  padding-top: 40px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.success-tip {
  color: #ECEFF1;
  font-size: 13px;
  line-height: 25px;
  padding: 24px;
  text-align: center;
}
.wx-model {
  position: absolute;
  height: 100%;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  background-color: transparent;
}
.wx-img {
  position: absolute;
  height: 415px;
  z-index: 5;
  left: 0;
  bottom: 2px;
  background-color: #FFF;
  width:100%;
  box-shadow: 0 2px 25px rgba(0, 0, 0, .4);
  img {
    margin-top: 48px;
    margin-bottom: 48px;
  }
  .icon.weixinzhifu {
    font-size: 10em;
  }
  .wx-img-close {
    position: absolute;
    right:5px;
    top: -5px;
    cursor: pointer;
    font-size: 27px;
    transform: translateY(-100%);
    border: 1px solid rgba(0, 0, 0, .4);
    border-radius: 50%;
  }
  $payBackgroundImage: linear-gradient(to bottom, #ef5350 0%, #d32f2f 100%);
  $payBackgroundImageHover: linear-gradient(to bottom, #e57373 0%, #d32f2f 100%);
  &.inOrder {
    h2 {
      text-align: center;
      margin: 30px auto;
    }
    $fWidth: 24em;
    .orderItem {
      line-height: 38px;
      height: 38px;
      font-size: 14px;
      max-width: $fWidth;
      margin: 0 auto;
      padding-left: 0;
      .orderTitle {
        width: 11em;
        text-align: right;
        float: left;
        color: #303133;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .orderText {
        margin-left: 11em;
        width: calc(#{$fWidth} - 11em);
        text-align: left;
        display: block;
        img {
          vertical-align: sub;
          margin: 0;
          &.img {
            height: 18px;
          }
        }
        span.important {
          color: rgb(255, 102, 0);
        }
        .icon {
          font-size: 16px;
          margin-right: 4px;
        }
      }
    }
    .orderPay {
      margin-top: 30px;
      color: #FFF;
      background-image: $payBackgroundImage;

      &:hover {
        background-image: $payBackgroundImageHover;
      }
      &.cancel {
        background-image: linear-gradient(to bottom, #BDBDBD 0%, #757575 100%);
        &:hover {
          background-image: linear-gradient(to bottom, #E0E0E0 0%, #757575 100%);
        }
      }
    }
  }

}
</style>
