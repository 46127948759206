import request from '@/common/request'
import {commonParams, PLATFORM} from '@/api/config'
import {getToken} from '@/common/auth'

export function Login(username, password) {
  return request({
    url: './LoginByPassword',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginName: username,
      passWord: password,
      platform: PLATFORM
    })
  })
}

export function LoginByWxkey(wxkey) {
  return request({
    url: './LoginByWxKey',
    method: 'post',
    data: Object.assign({}, commonParams, {
      wxkey
    })
  })
}

export function LoginByWxCode(code) {
  return request({
    url: './LoginByWxCode',
    method: 'post',
    data: Object.assign({}, commonParams, {
      format: 'json',
      code
    })
  })
}

export function LogOut() {
  const loginKey = getToken()
  return request({
    url: './LogOut',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey
    })
  })
}

export function ChangePassword(passWord, newPassWord) {
  const loginKey = getToken()
  return request({
    url: './ChangePassword',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      passWord,
      newPassWord
    })
  })
}

export function FindPassword(mobile, accountType) {
  const loginKey = getToken()
  return request({
    url: './FindPassword',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      mobile,
      accountType
    })
  })
}

export function SendVerifyCode(mobile, accountType, userType) {
  const loginKey = getToken()
  return request({
    url: './SendVerifyCode',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      mobile,
      accountType,
      userType
    })
  })
}

export function RegisterVehicle(mobile, contacter, queryPassword, vehicleName, sim, sim2,typeID) {
  const loginKey = getToken()
  return request({
    url: './RegisterVehicle',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      mobile,
      contacter,
      queryPassword,
      vehicleName,
      sim,
      sim2,
      typeID
    })
  })
}

export function RegisterVehicleHolding(mobile, contacter, queryPassword, vehicleName, sim, sim2) {
  const loginKey = getToken()
  return request({
    url: './RegisterVehicleHolding',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      mobile,
      contacter,
      queryPassword,
      vehicleName,
      sim,
      sim2
    })
  })
}

export function RegisterAccount(mobile, holdName, holdContacter, address, password) {
  const loginKey = getToken()
  return request({
    url: './RegisterAccount',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      mobile,
      holdName,
      holdContacter,
      address,
      password
    })
  })
}

export function LoginDemo(refer) {
  return request({
    url: './LoginDemo',
    method: 'post',
    data: Object.assign({}, commonParams, {
      refer
    })
  })
}

export function LoginByDownMD5(downMD5) {
  return request({
    url: './LoginByDownMD5',
    method: 'post',
    data: Object.assign({}, commonParams, {
      downMD5
    })
  })
}

export function SendCaptcha(mobile) {
  return request({
    url: './SendCaptcha',
    method: 'post',
    data: Object.assign({}, commonParams, {
      mobile
    })
  })
}

export function CheckCaptcha(mobile, captcha, nickName, sex, company) {
  return request({
    url: './CheckCaptcha',
    method: 'post',
    data: Object.assign({}, commonParams, {
      mobile, captcha, nickName, sex, company
    })
  })
}

export function ChangePasswordByCaptcha(newPassWord, guid, captcha) {
  const loginKey = getToken()
  return request({
    url: './ChangePasswordByCaptcha',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      newPassWord,
      guid,
      captcha
    })
  })
}

export function ChangePasswordByCaptchaV2(newPassWord, guid, captcha, holdId) {
  const loginKey = getToken()
  return request({
    url: './ChangePasswordByCaptchaV2',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      newPassWord,
      guid,
      captcha,
      holdId
    })
  })
}

export function ChangeEmailByCaptcha(guid, captcha, objectId) {
  return request({
    url: './ChangeEmailByCaptcha',
    method: 'post',
    data: Object.assign({}, commonParams, {
      guid,
      captcha,
      objectId
    })
  })
}

export function LoginSendVerifyCode(mobile) {
  return request({
    url: './LoginSendVerifyCode',
    method: 'post',
    data: Object.assign({}, commonParams, {
      mobile
    })
  })
}

export function LoginByMobile(mobile, captcha) {
  return request({
    url: './LoginByMobile',
    method: 'post',
    data: Object.assign({}, commonParams, {
      mobile, 
      captcha, 
      platform: PLATFORM
    })
  })
}


export function CheckVerifyCode(VerifyCode, guid) {
  return request({
    url: './CheckVerifyCode',
    method: 'post',
    data: Object.assign({}, commonParams, {
      VerifyCode,
      guid
    })
  })
}