export const userName = state => state.userName
export const userId = state => state.userId
export const loginInfo = state => state.loginInfo
export const currentHoldId = state => state.currentHoldId
export const currentHoldName = state => state.currentHoldName
export const currentHasChild = state => state.currentHasChild
export const visitedViews = state => state.visitedViews
export const cachedViews = state => state.cachedViews
export const cachedTrees = state => state.cachedTrees
export const taskList = state => state.taskList
export const cachedDevices = state => state.cachedDevices
export const cacheDvrs = state => state.cacheDvrs
export const currentMap = state => state.currentMap
export const menuPosition = state => state.menuPosition
export const ver = state => state.ver
export const hasMenu = state => state.hasMenu
export const monitorLeft = state => state.monitorLeft
export const deviceViews = state => state.deviceViews
export const glBmap = state => state.glBmap
export const glAmap = state => state.glAmap

