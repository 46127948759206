import Vue from 'vue'
import Router from 'vue-router'
import { getToken } from '@/common/auth'
import { getUserRole } from '@/common/cache'
import NProgress from 'nprogress'
import '@/styles/nprogress.css'
NProgress.configure({ showSpinner: false })
Vue.use(Router)

// eslint-disable-next-line
const _import = require('./_import_' + process.env.NODE_ENV)
// const fileName = process.env.VUE_APP_VRC
const {
  Layout,
  NotFound,
  Home,
  Login,
  Log,
  RiskPlace,
  ManageDevice,
  ManageHold,
  MangaeUser,
  ManageVehicle,
  ManageGroup,
  ReportOne,
  // ReportTwo,
  DetailVehicle,
  DetailDevice,
  DetailHold,
  DetailUser,
  InstallDetail,
  Wallet,
  BatchExpire,
  BatchExpire2,
  Redirect,
  AsideTree,
  ManageNotify,
  Info,
  MapControl,
  MapControlTrack,
  MapControlPolygon,
  Forget,
  Register,
  WalletView,
  WalletInvoice,
  Connect,
  ImageViewer,
  VisitorLog,
  RiskRoad,
  SecurityProtocol,
  UsageProtocol,
  Tracing,
  Auth
} = require('./components.js')


const routes = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path*',
        component: Redirect
      }
    ]
  },
  {
    path: '/',
    redirect: '/online/monitor',
    component: Layout
  },
  {
    path: '/online',
    component: Layout,
    redirect: '/online/monitor',
    meta: { funId: 20100 },
    children: [
      {
        path: 'monitor',
        name: 'mapControl',
        component: MapControl,
        meta: { title: 'gpsOnlineMonitor', closeAnimation: true, funId: 3001 }
      },
      {
        path: 'track',
        name: 'mapControlTrack',
        component: MapControlTrack,
        props: route => ({ itemId: route.query.id ? parseInt(route.query.id) : null }),
        meta: { title: 'gpsHistoryPlay', closeAnimation: true, funId: 3005 }
      },
      {
        path: 'tracing',
        name: 'tracing',
        component: Tracing,
        props: route => ({ itemId: route.query.id ? parseInt(route.query.id) : null }),
        meta: { title: 'gpsTrack', closeAnimation: true, funId: 3005 }
      },
      {
        path: 'polygon',
        name: 'mapControlPolygon',
        meta: { title: 'gpsRegion', closeAnimation: true, funId: 20110 },
        component: MapControlPolygon
      }
    ]
  },
  {
    path: '/risk',
    component: Layout,
    redirect: '/risk/evaluate',
    meta: { funId: 20000 },
    children: [
      {
        path: 'place',
        component: RiskPlace,
        name: 'riskPlace',
        meta: { title: 'riskPlace', closeAnimation: true, funId: 20070 },
        props: route => ( { itemId: route.query.id ? parseInt(route.query.id) : null } )
      },
      {
        path: 'road',
        name: 'riskRoad',
        meta: { title: 'gpsRoad', closeAnimation: true, funId: 30210 },
        component: RiskRoad
      },
    ]
  },
  {
    path: '/manage',
    redirect: '/manage/group',
    component: Layout,
    children: [
      {
        path: 'hold',
        name: 'manageHold',
        components: {
          default: ManageHold,
          aside: AsideTree
        },
        meta: { title: 'infoHold', showTitle: false, showTree: true, funId: 5010 },
      },
      {
        path: 'hold/detail',
        component: DetailHold,
        meta: { noVisisted: true },
        props: route => ({itemId: route.query.id})
      },
      {
        path: 'user',
        name: 'manageUser',
        meta: { title: 'infoUser', showTitle: false, showTree: true, funId: 5030 },
        components: {
          default: MangaeUser,
          aside: AsideTree
        }
      },
      {
        path: 'user/detail',
        component: DetailUser,
        meta: { noVisisted: true, funId: 5030 },
        props: route => ({itemId: route.query.id})
      },
      {
        path: 'device',
        name: 'manageDevice',
        meta: { title: 'infoDevice', showTitle: false, showTree: true, funId: 5040 },
        components: {
          default: ManageDevice,
          aside: AsideTree
        }
      },
      {
        path: 'device/detail',
        name: 'deviceDetail',
        component: DetailDevice,
        meta: { noVisisted: true, funId: 5040 },
        props: route => ({itemId: route.params.itemId})
      },
      {
        path: 'vehicle',
        name: 'manageVehicle',
        meta: { title: 'infoVehicle', showTitle: false, showTree: true, funId: 5040 },
        components: {
          default: ManageVehicle,
          aside: AsideTree
        }
      },
      {
        path: 'vehicle/detail',
        name: 'detailVehicle1',
        component: DetailVehicle,
        meta: { noVisisted: true, funId: 5040 },
        props: route => ({itemId: route.query.id})
      },
      {
        path: 'vehicle/install',
        name: 'installDetail1',
        component: InstallDetail,
        meta: { noVisisted: true, funId: 5040 },
        props: route => ({itemId: route.query.id})
      },
      {
        path: 'group',
        name: 'manageGroup',
        meta: { title: 'infoVehicle', showTitle: false, showTree: true, funId: 5040 },
        components: {
          default: ManageGroup,
          aside: AsideTree
        }
      },
      {
        path: 'expire2',
        name: 'batchExpire',
        meta: { title: 'vipUpdateHistory', showTitle: false, showTree: true, funId: 20141 },
        components: {
          default: BatchExpire,
          aside: AsideTree
        }
      },
      {
        path: 'notify',
        name: 'manageNotify',
        components: {
          default: ManageNotify
        },
        meta: { title: 'notifyMenu', showTitle: false, showTree: false, funId: 20170 },
      },
    ]
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/connect',
    name: 'connect',
    component: Connect
  },
  {
    path: '/auth',
    name: 'auth',
    component: Auth
  },
  {
    path: '/forget',
    component: Forget
  },
  {
    path: '/register',
    component: Register
  },
  {
    path: '/securityProtocol',
    component: SecurityProtocol
  },
  {
    path: '/usageProtocol',
    component: UsageProtocol
  },
  {
    path: '/home',
    component: Layout,
    props: {
      tagsView: false,
      theme: process.env.VUE_APP_HOMEV === '.tl' ? 'default' : 'dark',
      currentHead: false
    },
    children: [
      {
        path: '',
        component: Home,
        name: 'home',
        meta: {
          title: 'home'
        }
      }
    ]
  },
  {
    path: '/report',
    component: Layout,
    children: [
      {
        path: 'one',
        name: 'report',
        component: ReportOne,
        meta: { title: 'reportMenu', funId: 20150 },
      }
      // {
      //   path: 'two',
      //   name: 'report2',
      //   component: ReportTwo,
      //   meta: { title: '报表查询2', funId: 20150 },
      // }
    ]
  },
  {
    path: '/log',
    component: Layout,
    children: [
      {
        path: '',
        name: 'log',
        component: Log,
        meta: { title: 'userLog', funId: 20160 },
      }
    ]
  },
  {
    path: '/visitorlog',
    component: Layout,
    children: [
      {
        path: '',
        name: 'visitorlog',
        component: VisitorLog,
        meta: { title: 'visitorlog', funId: 20160 },
      }
    ]
  },
  {
    path: '/wallet',
    component: Layout,
    children: [
      {
        path: '',
        name: 'wallet',
        meta: { title: 'vipWalletDetail', showTitle: false, showTree: true, funId: 20080 },
        components: {
          default: Wallet,
          aside: AsideTree
        }
      }
    ]
  },
  {
    path: '/vip',
    redirect: '/vip/wallet',
    component: Layout,
    children: [
      {
        path: 'wallet',
        name: 'walletView',
        meta: { title: 'vipWallet', showTitle: false, showTree: true, funId: 20080 },
        components: {
          default: WalletView,
          aside: AsideTree
        }
      },
      {
        path: 'invoice',
        name: 'walletInvoice',
        meta: { title: 'walletInvoice', showTitle: false, showTree: true, funId: 20080 },
        components: {
          default: WalletInvoice,
          aside: AsideTree
        }
      },
      {
        path: 'expire',
        name: 'batchExpire2',
        meta: { title: 'vipUpdate', showTitle: false, showTree: true, funId: 20141 },
        components: {
          default: BatchExpire2,
          aside: AsideTree
        }
      },
    ]
  },
  {
    path: '/info',
    component: Layout,
    children: [
      {
        path: '',
        name: 'info',
        component: Info,
        meta: { title: 'userCenter', showTitle: false, showTree: false }
      }
    ]
  },
  {
    path: '/image',
    component: ImageViewer,
    props: route => ({src: route.query.src})
  },
  // {
  //   path: '/dialog-task',
  //   component: _import('dialog/dialog-task'),
  //   props: { openInDialog: false }
  // },
  // {
  //   path: '/dialog-install',
  //   component: _import('dialog/dialog-install'),
  //   props: { openInDialog: false }
  // },
  {
    path: '/dialog-setting',
    component: _import('dialog/dialog-setting'),
    props: { openInDialog: false }
  },
  {
    path: '/home-new',
    component: _import('home/home-new')
  },
  {
    path: '*',
    component: NotFound,
  }
]

const router = new Router({
  routes
})

const whiteList = ['/', '/login', '/forget', '/register', '/connect','/auth', '/securityProtocol', '/usageProtocol']

// IE 下Vue-router不跳转
if ('-ms-scroll-limit' in document.documentElement.style && '-ms-ime-align' in document.documentElement.style) {
  // detect it's IE11
  window.addEventListener('hashchange', function() {
    var currentPath = window.location.hash.slice(1)
    if (router.currentRoute.path !== currentPath) {
      router.push(currentPath)
    }
  }, false)
}

router.beforeEach((to, from, next) => {
  NProgress.start()
  const allFuns = getUserRole().UserFunList || []
  // console.log('页面跳转， 下一个页面', to, '权限列表', allFuns, '权限', allFuns.includes(to.id))
  if (getToken()) {

    // 判断权限
    if (!to.meta.funId || allFuns.includes(to.meta.funId)) {
      next()
      NProgress.done()
    } else {
      if (process.env.NODE_ENV === 'development') {
        console.log('to:', to, 'from:', from, '禁止进入')
      }
      next(false)
      NProgress.done()
    }

  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
      NProgress.done()
    } else {
      next('/login')
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done()
  setTimeout(()=>{
    // 此处粘贴百度统计复制过来的代码
  var _hmt = _hmt || [];
      (function() {
        var hm = document.createElement("script");
        hm.src = "https://hm.baidu.com/hm.js?eb417d2826dd54ffec07322412d57e1e";
        var s = document.getElementsByTagName("script")[0]; 
        s.parentNode.insertBefore(hm, s);
    })();
  },0);

})

export default router
