  <template>
    <div class="page-container" :class="`page-menu-position-${menuPosition}`">
        <el-aside
          v-if="loginInfo.isUser && menuPosition=='right' && hasMenu"
          class="page-aside"
          :class="{'no-animation':!useAnimation}"
          :width="isCollapse?'100px':'180px'"
          :style="isCollapse ? {'overflow': 'visible'} : {}"
          >
          <p class="page-aside__collapse" @click="isCollapse = !isCollapse" v-if="false">
            <i-icon name="icon-menu1" :class="{collapse:!isCollapse,'has-animation':useAnimation}"></i-icon>
          </p>
          <page-aside :is-collapse="isCollapse" ref="aside" @collapse="isCollapse=!isCollapse" :theme="theme"></page-aside>
          <!-- <tool-tip
            content="展开" placement="right" :disabled="!isCollapse" type="grey-aphla">
            <p class="page-aside__collapse" @click="isCollapse = !isCollapse">
              <transition name="collapse-arrow" mode="out-in">
                <i-icon name="icon-keyboard-backspace" v-if="!isCollapse" key="icon-keyboard-backspace"></i-icon>
                <i-icon name="icon-keyboard-right" v-else key="icon-keyboard-right"></i-icon>
              </transition>
            </p>
          </tool-tip> -->
        </el-aside>
        <div class="headerBlock">
           <template v-if="$route.path!=='/home'">
            <div class="page-header-light" v-if="menuPosition=='top' && hasMenu">
            
                <page-header-light @notify="openNotify"></page-header-light>
              
            </div>
          </template>

          <el-header class="page-header" height="47px" v-if="menuPosition=='right' && hasMenu" v-show="tagsView">
            <page-header></page-header>

            <head-search :menus="menus" v-if="loginInfo.UserType!=961 && loginInfo.isUser"></head-search>

            <div class="icon-message abox" style="cursor:pointer;margin-right: 4px;" @click="$root.$emit('opendiseasemap')" v-if="$i18n.locale==='zh'">
              <img :src="`${DOWNLOAD_URL}/static/image/feiyan.svg`" style="height: 38px;margin-top: 5px;max-width:150px; "/>
            </div>

            <p class="page-header__collapse icon-shoucangjia" @click="openTaskList" :class="{'scale':isScale}" v-if="loginInfo.UserType!=961 && loginInfo.isUser" v-ripple v-tooltip="$t('layout.favourite')">
              <el-badge :value="taskList.length" :hidden="!taskList.length" style="vertical-align:text-top;">
                <i-icon name="icon-_icon-27" style="display:block"></i-icon>
              </el-badge>
            </p>
            <layout-switch>
              <p class="page-header__collapse" v-ripple v-tooltip="$t('layout.changeLayout')">
                <i-icon name="icon-_icon_PC-" class="icon-layout"></i-icon>
              </p>
            </layout-switch>
            <p class="page-header__collapse" v-if="false" @click="isCollapse = !isCollapse" v-ripple>
              <i-icon name="icon-menu1" :class="{collapse:!isCollapse,'has-animation':useAnimation}"></i-icon>
            </p>
            <p class="page-header__collapse" v-if="!loginInfo.isUser" @click="exit" v-ripple>
              <i-icon name="icon-exit-to-app"></i-icon>
            </p>
            <!--用户-->
            <el-popover
              placement="bottom"
              :open-delay="0"
              transition="page-header-transtion"
              popper-class="page-header-light-popover page-header-light-popover__top"
              :boundaries-padding="0"
              ref="popUser"
              trigger="hover">
              <p slot="reference" class="page-header__collapse" v-ripple>
                <i-icon name="icon-_icon-29"></i-icon>
              </p>
              <ul class="vi-light-mfun-sub" @click="$refs.popUser.doClose()">
                <router-link to="" @click.native="openDialogMobileObject" key="绑定手机" tag="li" class="vi-light-mfun-subitem" v-if="loginInfo.UserID === 0 && $i18n.locale!=='en'">
                  <i-icon name="icon-_icon-13" style="color:#409EFF"></i-icon>
                  绑定手机
                </router-link>
                <router-link
                  v-for="lk in lastMenu"
                  tag="li"
                  :to="lk.link"
                  :key="lk.title"
                  @click.native="linkEmit(lk)"
                  class="vi-light-mfun-subitem"
                  :class="{'normalLink': !lk.link}" >
                  <i-icon v-if="lk.icon" :name="lk.icon" :class="lk.icon" :style="{color:lk.icolor}"></i-icon>
                  {{lk.title}}
                </router-link>
              </ul>
            </el-popover>

            <p class="page-header__collapse" v-ripple v-if="languageVisible">
              <el-dropdown trigger="click" @command="language" v-if="true">
                <span class="el-dropdown-link">
                  <template v-if="$i18n.locale==='zh'"><i-icon name="icon-zhongguo" class="language-icon" ></i-icon></template>
                  <template v-else-if="$i18n.locale==='fr'"><i-icon name="icon-faguo" class="language-icon" ></i-icon></template>
                  <template v-else><i-icon name="icon-meiguo" class="language-icon"></i-icon></template>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="zh">
                    <el-row align="middle" type="flex">
                      <i-icon name="icon-zhongguo" class="language-icon"></i-icon>中文
                    </el-row>
                  </el-dropdown-item>
                  <el-dropdown-item command="en">
                    <el-row align="middle" type="flex">
                      <i-icon name="icon-meiguo" class="language-icon"></i-icon>English
                    </el-row>
                  </el-dropdown-item>
                  <el-dropdown-item command="fr">
                    <el-row align="middle" type="flex">
                      <i-icon name="icon-faguo" class="language-icon"></i-icon>‪Français
                    </el-row>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </p>
            <task-list ref="taskList"></task-list>
          </el-header>

          <el-main class="page-main">
            <page-main ref="pageMain"></page-main>
          </el-main>
        </div>
        <dialog-notify ref="dialogNotify"></dialog-notify>
        <detail-device ref="detailDevice" :append-to-body="true"></detail-device>
        <detail-vehicle ref="detailVehicle" ></detail-vehicle>
        <dialog-change-mobile-object ref="dialogChangeMobileObject"></dialog-change-mobile-object>
        <el-dialog :visible.sync="batchExpireVisible" :title="$t('updateVip.add')" custom-class="v-dialog v-dialog--lg v-dialog--nopadding v-gs-dialog" v-if="batchExpireVisibleReal" @closed="batchExpireVisibleReal=false">
          <batch-expire2 pop-window></batch-expire2>
        </el-dialog>
        <dialog-wrapper ref="dialogSetting">
          <dialog-setting></dialog-setting>
        </dialog-wrapper>

        <el-dialog :custom-class="iframe.class" :visible.sync="isShowDialog" :width="iframe.width" :title="iframe.title" append-to-body v-if="isShowDialogReal">
          <div v-loading="iframeLoading" element-loading-spinner="el-icon-loading" element-loading-background="#FFFFFF" style="position:relative;">
            <iframe frameborder="0" class="mine" :height="iframe.height" width="100%" scrolling="yes" :src="iframe.src" @load="iframeLoading=false"></iframe>
          </div>
        </el-dialog>
    </div>
</template>

  <script>
  import PageHeader from './page-header'
  import PageMain from './page-main'
  const PageAside = require('./page-aside.vue').default
  const PageHeaderLight = require('@/views/layout/page-header-light.vue').default
  import { getToken, removeToken } from '@/common/auth'
  import { getVuex, getLastActiveDay, setLastActiveDay, setLanguage, getLanguageVisible, getSessionConnectKey, S_IMAGE_PREFIX_URL } from '@/common/cache'
  import {mapGetters, mapMutations, mapActions} from 'vuex'
  import NoData from '@/components/no-data'
  import * as API from '@/api/alarm'
  import ChangePassword from '@/views/common/change-password'
  import TaskList from '@/views/online/components/task-list'
  const DialogNotify = require('@/views/notify/dialog-notify.vue').default
  import {BASE_URL} from '@/api/config'
  import { setCommonParam } from '@/api/config'
  import HeadSearch from '@/views/layout/head-search'
  import BatchExpire2 from '@/views/batch/batch-expire2'
  import miment from 'miment'
  import DetailVehicle from '@/views/manage/detail/detail-vehicle'
  import DetailDevice from '@/views/manage/detail/detail-device'
  import State from '@/store/state'
  import LayoutSwitch from '@/views/layout/layout-switch'
  import {getLayout} from '@/common/cache'
  import {QueryFocusDeviceList} from '@/api/common'
  import DialogSetting from '@/views/dialog/dialog-setting'
  import Vue from 'vue'
  import { setAutoLogin, setUserRole, getOutUrl } from '@/common/cache'
  import { DOWNLOAD_URL } from '@/api/config'
  import DialogChangeMobileObject from '@/views/info/dialog-change-mobile-object'

  export default {
    name: 'Layout',
    props: {
      tagsView: {
        type: Boolean,
        default: true
      },
      theme: {
        type: String,
        default: 'default'
      },
      currentHead: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        DOWNLOAD_URL,
        activeIndex: '/home',
        roleId: '1',
        alarmData: [],
        isCollapse: true,
        isAnimating: false,
        useAnimation: true,
        isScale: false,
        batchExpireVisible: false,
        batchExpireVisibleReal: false,
        menus: [],
        menuPosition:'top',
        lastMenu: [],
        languageVisible: true,
        isShowDialog: false,
        isShowDialogReal: false,
        iframeLoading: false,
        iframe: {
          title: '抗击肺炎专题',
          class: 'el-dialog--p0 dialogfeiyan',
          width: '400px',
          height: '700px',
          src: 'https://i.snssdk.com/feoffline/hot_list/template/hot_list/forum_tab.html'
        }
      }
    },
    components: {
      DialogNotify,
      DetailDevice,
      DetailVehicle,
      NoData,
      ChangePassword,
      PageHeader,
      PageAside,
      PageMain,
      TaskList,
      HeadSearch,
      BatchExpire2,
      PageHeaderLight,
      LayoutSwitch,
      DialogSetting,
      DialogChangeMobileObject
    },
    computed: {
      ...mapGetters([
        'userName',
        'userId',
        'loginInfo',
        'taskList',
        // 'menuPosition',
        'ver',
        'hasMenu'
      ])
    },
    watch: {
      '$route': 'updateActiveTabIndex',
      isCollapse() {
        this.$refs.pageMain.doLayout && this.$refs.pageMain.doLayout()
      }
    },
    mounted() {
      if (this.hasMenu) {
        this.$refs.dialogNotify.open()
      }

      this.menus = (this.$refs.aside && this.$refs.aside.datas) || []

      this.$bus.$on('open-vehicle', (id, options = {tabName: 'vehicle'}) => {
        this.$refs.detailVehicle.open(id, options)
      })

      this.$bus.$on('open-device', id => {
        this.$refs.detailDevice.open(id)
      })

      this.$root.$on('opendiseasemap', () => {
        this.isShowDialog = true
        this.isShowDialogReal = true
        this.iframeLoading = true
      })
    },
    methods: {
      ...mapMutations({
        'setState': 'SET_STATE',
        'setMenuPosition': 'SET_MENU_POSITION',
        'setVer': 'SET_VER',
        'setHasMenu': 'SET_HAS_MENU',
        'setMonitorLeft': 'SET_MONITOR_LEFT',
        'addTaskCar': 'ADD_TASK_CAR',
        'delAllTaskCar': 'DEL_ALL_TASK_CAR'
      }),
      handleSelect() {
        //console.log(key, keyPath);
      },
      language(key) {
        this.$i18n.locale = key
        setLanguage (key)
        setCommonParam({language:key})
        //console.log(this)
      },
      updateActiveTabIndex() {
        this.activeIndex = this.$route.path
        if (this.$route.meta) {
          this.useAnimation = !this.$route.meta.closeAnimation
        }
      },
      exit() {
        setAutoLogin({
          u: '',
          p: '',
          isAuto: false
        })
        removeToken()
        setUserRole()
        this.delAllViews().then(() => {
          const outUrl = getOutUrl()
          if (outUrl) {
            window.location.href = outUrl
          } else {
            this.$router.push('/login')
          }
        })
      },
      openDialogMobileObject() {
        this.$refs.dialogChangeMobileObject.open()
      },
      reload() {
        //console.log('reload')
        location.reload()
      },
      fetchAlarm() {
        API.Query().then(({data}) => {
          this.alarmData = data.list
        })
      },
      deleteAlarm(item) {
        API.Modify(item.AID).then(() => {
          // deleteFromArray(this.alarmData, k => {
          //   return k.AID == item.AID
          // })
          const index = this.alarmData.findIndex(k => {
            return k.AID == item.AID
          })
          this.alarmData.splice(index, 1)
        })
      },
      beforeEnter() {
        this.isAnimating = true
      },
      afterEnter() {
        this.isAnimating = false
      },
      beforeLeave() {
        this.isAnimating = true
      },
      afterLeave() {
        this.isAnimating = false
      },
      openTaskList() {
        this.$refs.taskList.open()
      },
      openBatchVip() {
        const lastActiveDay = getLastActiveDay()// 2019-03-13
        // 获取最后登录日期
        if (lastActiveDay && lastActiveDay == miment().format('YYYY-MM-DD')) {
          return
        }
        // 升级VIP权限
        if (!this.loginInfo.allFuns.includes(20141)) {
          return
        }
        if (this.$i18n.locale !== 'zh') {
          return
        }
        if (!this.hasMenu) {
          return
        }


        this.batchExpireVisible = true
        this.batchExpireVisibleReal = true

        setLastActiveDay(miment().format('YYYY-MM-DD'))
      },
      openNotify(force) {
        this.$refs.dialogNotify.open(force)
      },
      setHeaderHeight() {
        try {

          if (getLayout()) {
            this.setMenuPosition(getLayout())
          }

          if (this.menuPosition == 'top') {
            document.querySelector(':root').setAttribute('style', '--page-header-height:70px')
          } else {
            document.querySelector(':root').setAttribute('style', '--page-header-height:70px')
          }

          if (!this.hasMenu) {
            document.querySelector(':root').setAttribute('style', '--page-header-height:0px')
            document.querySelector(':root').style.minWidth = '400px'
          } else {
            document.querySelector(':root').style.minWidth = '1280px'
          }
          // if(!this.currentHead){
          //    document.querySelector(':root').setAttribute('style', '--page-header-height:0px')
          // }
        } catch (e) {
          console.error('setHeaderHeight', e)
        }
      },
      refreshVuexTime() {
        this.setVer(this.ver)
      },
      // 普通菜单
      linkEmit(k) {
        if (k.emit) {
          switch (k.emit) {
            case 'openExit':
              this.exit()
              break;
            case 'notify':
              this.openNotify(true)
              break;
            case 'setting':
              this.openSetting()
              break;
          }
        }
      },
      // 加载关注车辆
      loadFavorite() {
        QueryFocusDeviceList().then(ret => {
          if (!ret.data.errCode) {
            this.delAllTaskCar()
            ret.data.forEach(k => {
              this.addTaskCar(k.ObjectID)
            })
          }
        })
      },
      openSetting() {
        this.$refs.dialogSetting.open({
          class: 'v-dialog v-dialog--md v-dialog--mdpadding v-dialog--free',
          title: this.$t('route.setting')
        })
      },
      ...mapActions(['delAllViews'])
    },
    beforeRouteEnter(to, from, next) {
      const state = getVuex()
      // 以下情况需要重新登录
      // 1.state为空
      // 2.state值异常
      // 3.版号不一致
      if (!state || state.userId === undefined || state.loginInfo === null || state.ver != State.ver)
      {
        console.warn('invalid state or state expired.', state)
        return next('/login')
      }

      next()
    },
    created() {
      // 从localstorage中读取vuex
      if (!this.userId) {
        const state = getVuex()
        // console.log(state)
        // 清空
        // state.cachedViews = []
        // state.visitedViews = []
        // if (this.loginInfo.UserType != 951 && this.loginInfo.UserType != 952 && this.loginInfo.UserType != 953 && this.loginInfo.UserType != 954) {
        //   state.cachedViews = []
        //   state.visitedViews = []
        // }

        state.cachedTrees = []
        this.setState(state)
      }

      // console.log('创建页面')
      // 匹配当前路由
      this.updateActiveTabIndex()
      this.roleId = getToken()
      //this.fetchAlarm()
      // this.loginInfo.PlatformTitle 
      document.title = '戈赛赛况平台'

      document.getElementById('ico').href = `${BASE_URL}/assets/Icon?holdid=${this.loginInfo.HoldID}`

      if (!getSessionConnectKey() && !this.hasMenu) {
        // 没有connect key时, 菜单要显示
        this.setHasMenu(true)
      } else if (getSessionConnectKey() && this.hasMenu) {
        // 有connect key时，菜单不要显示
        this.setHasMenu(false)
      }

      // 没有用户管理的查询权限不显示目录
      const noMenuTypeList = [961,962,963]
      if (noMenuTypeList.includes(this.loginInfo.UserType)) {
        this.setMonitorLeft(false)
      } else {
        this.setMonitorLeft(true)
      }

      // if (!this.loginInfo.allFuns.includes(5014)) {
      //   this.setMonitorLeft(false)
      // } else if (!getSessionConnectKey()) {
      //   this.setMonitorLeft(true)
      // }

      this.openBatchVip()
      this.setHeaderHeight()

      this.languageVisible = !!getLanguageVisible()

      // 关闭页面1个小时需要重新登录
      setInterval(() => {
        this.refreshVuexTime()
      }, 1000 * 60 * 10)

      const lastSubmenu = []

      if (this.loginInfo.UserID > 0) {
        lastSubmenu.push({ title: this.$t('route.userCenter'), icon: 'icon-_icon-13', link: '/info', id: 99999, icolor: '#409EFF' })
        lastSubmenu.push({ title: this.$t('layout.notify'), icon: 'icon-_icon-11', link: '', emit: 'notify', id: 99998, icolor: '#FA8C16' })
        // lastSubmenu.push({ title: this.$t('route.setting'), icon: 'icon-shezhi-', link: '', emit: 'setting', id: 99996, icolor: '#D46B08' })
      }
      if (this.loginInfo.allFuns.includes(30200)) {
        lastSubmenu.push({ title: this.$t('route.visitorlog'), icon: 'icon-_icon-7', link: '/visitorlog', id: 30200, icolor: '#1890FF' })
      }
      lastSubmenu.push({ title: this.$t('route.exit'), icon: 'icon-denglu-copy', link: '', id: 99997, emit: 'openExit' })
      this.lastMenu = lastSubmenu

      this.loadFavorite()

      this.$root.$on('dialog-setting', this.openSetting)
      Vue.prototype['$loadingc'] = timeout => {

        this.loading = true
        if (timeout) {
          setTimeout(() => {
            this.loading = false
          }, timeout * 1000)
        }

        return {
          close: () => {
            this.loading = false
          }
        }
      }

      let image_prefix = localStorage.getItem(S_IMAGE_PREFIX_URL)
      if (image_prefix) {
        Vue.prototype['$IMAGE_URL_PREFIX'] = image_prefix
        Vue.prototype['$IMAGE_URL_PREFIX_M'] = image_prefix.replace('upload/image/', 'api/posts/image.ashx')
      }
    }
  }
  </script>

  <style lang="scss" scoped>
  @import "../../styles/element-variables.scss";
  @import "../../styles/mixin.scss";
  @import "../../styles/variables-simple.scss";
  .page-container {
    // background-color: $page-background-color;
    & > .el-container {
      border-right: 1px solid #EBEEF5;
    }
    .page-header-light {
      padding: 0;
    }
    .page-header {
      overflow: visible;
      padding: 0;
      display: flex;
      box-sizing: content-box;
      //background-color: $--color-primary;
      //box-shadow: 0px 2px 2px -2px rgba(0,0,0,0.2);
      border-bottom: 1px solid rgba($--color-primary, 1);
      & > .wrapper {
        flex-grow: 1;
      }
      .page-header__collapse {
        flex-grow: 0;
        flex-shrink: 0;
        line-height: $page-header-height;
        height: $page-header-height;
        width: $page-header-height;
        cursor: pointer;
        text-align: center;
        //color: #FFF;
        color: #606266;
        transition: all 0.15s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 12px;
        &.scale ::v-deep .el-badge__content {
          animation: .3s scaleElement ease-out;
        }
        .icon {
          width: 48px;
          height: 48px;
          padding: 15px;
          vertical-align: middle;
        }
      }
    }
    .page-aside {
      height: 100vh;
      overflow-y: auto;
      @include scroll-bar-grey;
      position: relative;
      transition: width 0.3s ease;
      z-index: 120;
      box-shadow: 0 0 5px 0 rgba(0,0,0,.15);
      background-color: #FFF;
      &.no-animation {
        transition: none;
      }

      .page-aside__collapse {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 24px;
        line-height: 24px;
        text-align: center;
        font-size: 14px;
        background-color: rgba($page-main-background-color, .9);
        border-bottom: 1px solid #DCDFE6;
        border-top: 1px solid #DCDFE6;
        cursor: pointer;
        .icon {
          font-size: 18px;
          &.has-animation {
            transition: all 0.3s ease;
          }
          &.collapse {
            transform: rotate(-90deg);
          }
        }
        &:hover {
          background-color: rgba($page-main-background-color, .7);
        }
      }
    }
    .page-main {
      padding: 0;
      background-color: rgba($page-main-background-color, 1);
      //border-top-left-radius: 8px;
    }
  }

  .collapse-arrow-enter-active,.collapse-arrow-leave-active {
    transition: all 0.15s ease;
  }
  .collapse-arrow-enter, .collapse-arrow-leave-to {
    opacity: 0.8;
  }

  @keyframes scaleElement {
    0% {
      transform: scale(1.15);
      opacity: .75;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  .el-dropdown {
    height: 48px;
    width: 48px;
  }
  .el-dropdown-link .icon {
    padding: 15px;
    height: 48px;
    width: 48px;
  }
  .language-icon {
    font-size: 18px;
    margin-right: 6px;
  }
  .icon-layout {
    font-size: 14px;
  }
  .vi-light-mfun-sub {
  background-color:  $--color-primary;
  list-style: none;
  padding: 8px 6px;
  border-radius: 2px;
  .vi-light-mfun-subitem {
    height: 44px;
    line-height: 44px;
    border-radius: 2px;
    padding: 0 24px 0 12px;
    color: #fff;
    &:hover {
      color:$--color-primary;
      cursor: pointer;
    }
    .icon {
      margin-right: 6px;
      margin-bottom: -1px;
      font-size: 18px;
    }
  }
}

.icon-shoucangjia ::v-deep .el-badge__content.is-fixed {
  transform: translateY(5px) translateX(100%);
}
</style>
