<template>
  <div class="wrapper">
    <div class="tags-view-wrapper" ref="scroll">
      <div class="tags-view-scroll">
        <router-link class="tags-view__item" v-ripple ref="tag" tag="a" v-for="(tag,index) in Array.from(visitedViews)" :to="{ path: tag.path, query: tag.query }" :key="index" @contextmenu.prevent.native="openMenu(tag,$event)">
          <span>{{$t(`route.${tag.title}`)}}</span><i class="el-icon-close" @click.prevent.stop="closeSelectedTag(tag)"></i>
        </router-link>
      </div>
    </div>
    <ul class='contextmenu' v-show="visible" :style="{left:left+'px',top:top+'px'}">
      <li @click="refreshSelectedTag(selectedTag)">{{$t('contextMenu.refresh')}}</li>
      <li @click="closeSelectedTag(selectedTag)">{{$t('contextMenu.close')}}</li>
      <li @click="closeOthersTags">{{$t('contextMenu.closeOtherTabs')}}</li>
      <li @click="closeAllTags">{{$t('contextMenu.closeAll')}}</li>
      <li @click="openTagInNewPage(selectedTag)">{{$t('contextMenu.newPage')}}</li>
    </ul>
  </div>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex'
import ScrollPane from '@/components/scroll-pane'
import BetterScroll from 'better-scroll'
import {setVisitedViewInfo} from '@/common/cache'
export default {
  components: {
    ScrollPane
  },
  data() {
    return {
      visible: false,
      top: 0,
      left: 0,
      selectedTag: {}
    }
  },
  computed: {
    ...mapGetters([
      'visitedViews',
      'cachedViews',
      'userId'
    ])
  },
  created() {
    this.addViewTags()
    this.moveToCurrentTag()
  },
  mounted() {
    setTimeout(() => {
      this.initScroll()
    }, 25)
  },
  watch: {
    $route() {
      this.addViewTags()
      this.moveToCurrentTag()
    },
    visible(value) {
      if (value) {
        window.addEventListener('click', this.closeMenu)
      } else {
        window.removeEventListener('click', this.closeMenu)
      }
    },
    // 缓存标签历史记录
    visitedViews(val) {
      setVisitedViewInfo({
        userId: this.userId,
        visitedViews: val,
        cachedViews: this.cachedViews,
        v: window.compileVersion
      })
    }
  },
  methods: {
    generateRoute() {
      // console.log('generateRoute', this.$route)
      if (this.$route.name) {
        return this.$route
      }
      return false
    },
    isActive(route) {
      return route.path === this.$route.path
    },
    addViewTags() {
      const route = this.generateRoute()
      //console.log('addViewTags', route)
      if (!route) {
        return false
      }
      this.addView(route)
    },
    moveToCurrentTag() {
      if (!this.$refs.tag) return
      const tags = this.$refs.tag
      this.$nextTick(() => {
        for (const tag of tags) {
          if (tag.to.path === this.$route.path) {
            setTimeout(() => {
              this.bScroll.scrollToElement(tag.$el, 300)
            }, 17)

            //this.$refs.scrollPane.moveToTarget(tag.$el)
            this.updateVisitedView(this.$route)
            break
          }
        }
      })
    },
    refreshSelectedTag(view) {
      this.delCachedTrees()
      this.delCachedView(view).then(() => {
        const { fullPath } = view
        this.$nextTick(() => {
          // console.log( '/redirect' + fullPath)
          this.$router.replace({
            path: '/redirect' + fullPath
          })
        })
      })
    },
    closeSelectedTag(view) {
      this.delView(view).then(({visitedViews}) => {
        if (this.isActive(view)) {

          const latestView = visitedViews.slice(-1)[0]
          if (latestView) {
            this.$router.push(latestView)
          } else {
            this.$router.push('/')
          }
        }
      })
    },
    closeOthersTags() {
      this.$router.push({
        path: this.selectedTag.path,
        query: this.selectedTag.query
      })
      this.delOthersViews(this.selectedTag).then(() => {
        this.moveToCurrentTag()
      })
    },
    openTagInNewPage(view) {

      if (window.location.href.indexOf('#')!=-1) {
        let url = window.location.origin + window.location.pathname + '#' + view.fullPath
        window.open(url)
      } else {
        this.$router.push({
          path: view.path,
          query: view.query
        })
        window.open(window.location.href)
      }

    },
    closeAllTags() {
      this.delAllViews()
      this.$router.push('/home')
    },
    openMenu(tag, e) {
      this.visible = true
      this.selectedTag = tag
      this.left = e.clientX
      this.top = e.clientY
    },
    closeMenu() {
      this.visible = false
    },
    initScroll() {
      this.bScroll = new BetterScroll(this.$refs.scroll, {
        scrollY: false,
        scrollX: true,
        click: true,
        scrollbar: true,
        mouseWheel: true
      })
    },
    ...mapActions([
      'addView',
      'addVisitedView',
      'addCachedView',
      'delView',
      'delVisitedView',
      'delCachedView',
      'delOthersViews',
      'delOthersVisitedViews',
      'delOthersCachedViews',
      'delAllViews',
      'delAllVisitedViews',
      'delAllCachedViews',
      'updateVisitedView'
    ]),
    ...mapMutations({
      'delCachedTrees': 'DEL_CACHED_TREES'
    })
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/variables-simple.scss";
$tab-item-height: 40px;
$border-color: #DCDFE6;
.tags-view-wrapper {
  height: $page-header-height;
  //padding-top: 6px;
  //display: flex;
  //align-items: flex-end;
  overflow: hidden;
  padding: 8px 8px 0;
  // & ::v-deep .bscroll-indicator {
    //background-color: rgba(0,0,0,.2) !important;
    // background-color: rgba(#0D47A1, .4) !important;
    // border-color: rgba(#0D47A1, .4) !important;
  // }
  .tags-view-scroll {
    height: $tab-item-height;
    //padding: 0 8px;
    // background-color: $--color-primary;
    flex-grow: 1;
    //display: flex;
    overflow: hidden;
    width: fit-content;
    white-space: nowrap;
  }
  .tags-view__item {
    height: $tab-item-height;
    //height: calc(#{$tab-item-height} - 1px);
    //line-height: $tab-item-height;
    line-height: calc(#{$tab-item-height} - 1px);
    display: inline-block;
    flex-shrink: 0;
    //align-items: center;
    //color: #DDDDDD;
    color: #FAFAFA;
    padding: 2px 10px;
    margin-right: 8px;
    font-size: 13px;
    //margin-right: 8px;
    //border-top-left-radius: 8px;
    //border-top-right-radius: 8px;
    //background-color: rgba($page-main-background-color, .65);
    //transition: $--color-transition-base;
    //color: #FFF;
    border-top: 1px solid $home-backcolor;
    // border-left: 1px solid $home-backcolor;
    // border-right: 1px solid $home-backcolor;
    border-bottom: 1px solid $--color-primary;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    //background-color: #42A5F5;
    background-color: $home-backcolor;
    color: $--color-text-regular;
    //border-top: 1px solid $border-color;
    //border-left: 1px solid #1976D2;
    //border-right: 1px solid rgba(#1976D2, .5);
    // &:hover {
    //   background-color: rgba($page-main-background-color, .85);
    // }

    &.router-link-active {
      color: $--color-primary;
      //background-color: rgba($page-main-background-color, 1);
      //background-color: #42A5F5;
      background-color: #FFF;
      border-top: 1px solid $--color-primary;
      border-left: 1px solid $--color-primary;
      border-right: 1px solid $--color-primary;
      border-bottom: 1px solid #FFF;
    }
    span {
      display: inline-block;
      min-width: 4em;
    }

    .el-icon-close {
      padding: 3px;
      margin-left: 5px;
      font-size: 12px;
      border-radius: 50%;
      cursor: default;
      //transition: $--color-transition-base;
      //float: right;
      //margin-top: 12px;
      &:hover {
        background-color: $page-background-color;
        color: $--color-text-primary;
      }
    }
  }
  & ::v-deep .bscroll-horizontal-scrollbar {
    z-index: 200 !important;
  }
}
.contextmenu {
  margin: 0;
  background: #fff;
  z-index: 201;
  position: fixed;
  list-style-type: none;
  padding: 5px 0;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: $--color-text-regular;
  // box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, .3);
  box-shadow: $popover-shadow-deep;
  li {
    margin: 0;
    padding: 7px 16px;
    cursor: pointer;
    white-space: nowrap;
    &:hover {
      background: #eee;
    }
  }
}
</style>
