<template>
  <el-dialog :title="item.Head" :visible.sync="visible" width="700px" custom-class="v-dialog" class="notifyDialog" :append-to-body="appendToBody">
    <div v-html="item.Body"></div>
    <div slot="footer">
      <p class="footer-version" @dblclick="openVersion">V{{version}}</p>
      <el-button @click="notShowNextTime" size="medium" class="border" plain v-if="!isView">{{$t('layout.dontShow')}}</el-button>
      <el-button @click="visible = false" type="primary" size="medium" class="border" plain>{{$t('common.close')}}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import * as API from '@/api/notify'
import { getNotifyHistoryList, addNotifyHistoryList, /*setNotifyHistoryList*/ } from '@/common/cache'
import {mapGetters} from 'vuex'
export default {
  props: {
    appendToBody: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visible: false,
      item: {},
      isView: false,
      version: ''
    }
  },
  computed: {
    ...mapGetters([
      'loginInfo'
    ])
  },
  mounted() {
    this.version = window.compileVersion
  },
  methods: {
    open(force) {
      const notifyHistory = getNotifyHistoryList()
      API.QueryCurrentNotify().then(ret => {
        if (ret.data.list.length > 0) {

          // 强制打开
          if (force) {
            this.item = ret.data.list[0]
            this.visible = true
            return
          }

          // 正常打开
          if (!notifyHistory.includes(this.getCookieId(ret.data.list[0].NID))) {
            this.item = ret.data.list[0]
            this.visible = true
          }

        } else if (force) {
          this.$message(this.$t('layout.noMessage'))
        }
      })
    },
    view(item) {
      this.item = item
      this.visible = true
      this.isView = true
      // this.$refs.iframe.contentWindow.document = item.body
    },
    getCookieId(nid) {
      return `(${this.loginInfo.UserID},${nid})`
    },
    notShowNextTime() {
      addNotifyHistoryList(this.getCookieId(this.item.NID))
      this.visible = false
    },
    openVersion() {
      this.$alert(`V${this.version}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.notifyDialog ::v-deep .el-dialog__body {
  color:#fff;
  p {
    margin: .5em;
  }
}

.footer-version {
  float: left;
  font-size: 12px;
  color: #fff;
  cursor: default;
  user-select: none;
}
</style>
