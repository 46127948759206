<template>
  <div>
    <el-dialog :visible.sync="visible" v-if="visibleReal" :title="$t('holdDetail.name')"
      custom-class="v-dialog  v-dialog--footer dialogUser" top="5vh" @closed="closed" :close-on-click-modal="false"
      :append-to-body="appendToBody">
      <div class="dl-panel">
        <div class="dl-panel__chunk">
          <el-row :gutter="gap">
            <el-col :span="13">
              <div class="chunk-title">
                <i-icon class="chunk-title__icon" name="icondankuang-zhanghu1"></i-icon>
                <span>{{$t('holdDetail.basicInfo')}}</span>
              </div>
              <div class="chunk-content">
                <el-form ref="form" v-loading="loading" :rules="rules" :model="item" label-position="left"
                  label-width="90px">
                  <el-form-item :label="$t('holdDetail.holdName')" prop="HoldName">
                    <el-input v-model.trim="item.HoldName" :placeholder="$t('holdDetail.placeHolder1')" maxlength="20"
                      clearable size="small"></el-input>
                  </el-form-item>
                  <el-form-item :label="$t('holdDetail.parentHoldName')">
                    {{id ? other.parentItem.Name : currentHoldName}}</el-form-item>
                  <el-form-item :label="$t('holdDetail.userName')" prop="UserName">
                    <el-input v-model.trim="item.UserName" :disabled="!!id" :placeholder="$t('holdDetail.placeHolder2')"
                      maxlength="20" clearable size="small"></el-input>
                    <el-button type="text" class="iml-0 color2" @click="randomName" v-if="!id" size="small">
                      {{$t('holdDetail.random')}}</el-button>
                  </el-form-item>
                  <el-form-item :label="$t('holdDetail.password')" prop="UserPassword">
                    <el-input v-model="item.UserPassword" :placeholder="$t('holdDetail.placeHolder3')" maxlength="20"
                      clearable size="small"></el-input>
                    <el-button type="text" class="iml-0 color2" @click="randomPass" v-if="!id">{{$t('holdDetail.random')}}
                    </el-button>
                  </el-form-item>
                  <el-form-item :label="$t('holdDetail.ServiceEndTime')" prop="ServiceEndTime">
                    <el-date-picker type="date" v-model="item.ServiceEndDate" :disabled="isSelf"
                      :placeholder="$t('holdDetail.placeHolder6')" style="width:100%;"
                      :picker-options="other.pickOptions" size="small"></el-date-picker>
                  </el-form-item>
                  <el-form-item :label="$t('holdDetail.contacter')" prop="Contacter">
                    <el-input v-model.trim="item.Contacter" :placeholder="$t('holdDetail.placeHolder4')" maxlength="20"
                      clearable size="small"></el-input>
                  </el-form-item>
                  <el-form-item :label="$i18n.locale=='zh' ? $t('holdDetail.phone'): $t('holdDetail.email')" prop="Tel">
                    <div class="pos" v-if="$i18n.locale!=='zh'&& !id">Send Email to User <el-checkbox
                        v-model="item.SendEmail"></el-checkbox>
                    </div>
                    <el-input v-model.trim="item.Tel"
                      :placeholder="$t('holdDetail.placeHolder5') + ($i18n.locale=='zh' ? '' : ' or email')"
                      maxlength="32" size="small"></el-input>
                  </el-form-item>
                  <el-form-item label="颜色" prop="TeamColor">
                    <el-color-picker v-model="item.TeamColor" size="small" ></el-color-picker>
                    <!-- <el-select v-model="item.TeamColor" style="width:100%;" placeholder="请选择队伍颜色" filterable
                      size="small">
                      <el-option v-for="k in colorOptions" :label="k.name" :value="k.value" :key="k.name"></el-option>
                    </el-select> -->
                  </el-form-item>
                  <el-form-item :label="$t('holdDetail.address')">
                    <el-input v-model.trim="item.Addr" maxlength="127" size="small"></el-input>
                  </el-form-item>
                  <el-form-item :label="$t('holdDetail.remark')">
                    <el-input v-model.trim="item.Remark" maxlength="127" size="small"></el-input>
                  </el-form-item>

                </el-form>
              </div>
            </el-col>
            <el-col :span="11">
              <div class="chunk-title">
                <i-icon class="chunk-title__icon" name="icondankuang-quanxian1"></i-icon>
                <span>{{$t('holdDetail.authInfo')}}</span>
              </div>
              <div class="chunk-content">
                <div class="floatButton" v-if="!isSelf">
                  <el-button type="text" @click="checkAll">{{$t('userDetail.checkAll')}}</el-button>
                  <el-button type="text" @click="cancelAll">{{$t('userDetail.cancelAll')}}</el-button>
                </div>
                <el-scrollbar ref="elScroll" class="funMenu">
                  <el-tree :check-strictly="true" ref="tree" :data="other.fun" show-checkbox node-key="id"
                    :default-checked-keys="other.hasFun" :props="other.defaultProps" @check="checkHandler">
                    <span class="custom-tree-node" slot-scope="{ node }">
                      <span>{{ $t('funMenu')[node.label] || node.label }}</span>
                    </span>
                  </el-tree>
                </el-scrollbar>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="dl-panel__chunk" v-if="platform=='_kc'&& item.CCAdminMail1||item.CCAdminMail2">
          <div class="chunk-title">
            <!-- Email notifications -->
            <span style="line-height:20px;text-align:center;">Email<br> notifications</span>
            <!-- {{$t('holdDetail.authInfo')}} -->
          </div>
          <div class="chunk-content">
            <el-form>
              <el-row>
                <el-col :span="10" :offset="1">
                  <el-checkbox v-model="item.CCMail1">Admin CC Email {{item.CCAdminMail1}}</el-checkbox>
                </el-col>
                <el-col :span="10" :offset="1">
                  <el-checkbox v-model="item.ApplySubHold">Apply to all subordinates</el-checkbox>
                </el-col>
                <el-col :span="10" style="margin-top:15px;" :offset="1">
                  <el-checkbox v-model="item.CCMail2">Admin CC Email {{item.CCAdminMail2}}</el-checkbox>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </div>
      <div slot="footer">
        <el-button @click="cancel" class="customStyle" size="medium">{{$t('common.cancel')}}</el-button>
        <el-button @click="save" :loading="btnSaveLoading" v-if="loginInfo.allFuns.includes(5012)" class="customStyle"
          plain type="primary" size="medium" v-ripple>{{$t('common.save')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import mixin from './mixin.js'
  import {
    QueryDistrict
  } from '@/api/device'
  import * as API from '@/api/hold'
  import {
    GetFunTree
  } from '@/api/user'
  import {
    regions
  } from '@/common/dictionary'
  import {
    RandomStr
  } from '@/common/utils'

  export default {
    props: {
      appendToBody: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        tabName: 'info',
        item: {
          ApplySubHold: false,
          CCMail1: false,
          CCMail2: false,
          CCAdminMail1: '',
          CCAdminMail2: '',
          SendEmail: false
        },
        platform: process.env.VUE_APP_PLAT,
        rules: {
          HoldName: [{
            required: true,
            message: this.$t('holdDetail.placeHolder1'),
            trigger: 'blur'
          }],
          UserName: [{
            required: true,
            message: this.$t('holdDetail.placeHolder2'),
            trigger: 'blur'
          }],
          UserPassword: [{
            required: true,
            message: this.$t('holdDetail.placeHolder3'),
            trigger: 'blur'
          }],
          Contacter: [{
            required: true,
            message: this.$t('holdDetail.placeHolder4'),
            trigger: 'blur'
          }],
          Tel: [{
            required: true,
            message: this.$t('holdDetail.placeHolder5'),
            trigger: 'blur'
          }],
          ServiceDateTime: [{
            required: true,
            message: this.$t('holdDetail.placeHolder6'),
            trigger: 'blur'
          }]
        },
        colorOptions: [{
            name: '红色',
            value: 1
          },
          {
            name: '绿色',
            value: 2
          },
          {
            name: '蓝色',
            value: 3
          }
        ],
        regions,
        other: {
          parentItem: {},
          fun: [],
          hasFun: [],
          defaultProps: {
            id: 'id',
            children: 'children',
            label: 'label',
            disabled() {
              return false
            }
          },
          pickOptions: {
            shortcuts: [{
              text: this.$t('placeManage.today'),
              onClick(picker) {
                picker.$emit('pick', new Date())
              }
            }]
          }
        },
        cityOptions: []
      }
    },
    computed: {
      isSelf() {
        return this.id == this.loginInfo.HoldID && this.loginInfo.UserType === 951
      }
    },
    created() {
      this.other.defaultProps.disabled = () => {
        return this.isSelf
      }
      this.getCityOptions()
    },
    methods: {

      fetchItem() {
        let limit = ['风控管理', '工单系统', '保单管理']
        const promise1 = new Promise(resolve => {
          API.Get(this.id || 0, this.currentHoldId).then(ret => {
            this.item.SendEmail = false
            this.item = Object.assign({}, ret.data.hold)
            if (ret.data.MailParam) {
              this.item.CCMail1 = ret.data.MailParam.CCMail1
              this.item.CCMail2 = ret.data.MailParam.CCMail2
              this.item.CCAdminMail1 = ret.data.MailParam.CCAdminMail1
              this.item.CCAdminMail2 = ret.data.MailParam.CCAdminMail2
            }
            if (this.id) {
              let temp = ret.data.fun.map(item => !limit.includes(item.label) ? item : null).filter(num => num)
              this.other.fun = temp
              this.other.hasFun = ret.data.hasFun
              this.other.parentItem = ret.data.parentItem
            }
            resolve(ret)
          })
        })

        let promise2 = new Promise(resolve => resolve())
        if (!this.id) {
          promise2 = new Promise(resolve => {
            GetFunTree(this.currentHoldId, 1).then(ret => {
              let temp = ret.data.fun.map(item => !limit.includes(item.label) ? item : null).filter(num => num)
              this.other.fun = temp
              this.other.hasFun = ret.data.hasFun
              resolve(ret)
            })
          })
        }

        return Promise.all([promise1, promise2])
      },

      addItem() {
        this.item.ParentHoldID = this.currentHoldId
        return new Promise(resolve => {
          this.item.Email = this.item.Tel
          API.Add(this.item, this.$refs.tree.getCheckedKeys()).then(ret => resolve(ret))
        })
      },

      modifyItem() {
        return new Promise(resolve => {
          API.Modify(this.item, this.$refs.tree.getCheckedKeys()).then(ret => resolve(ret))
        })
      },

      validForms() {
        return this.$refs.form.validate()
      },

      resetForms() {
        this.tabName = 'info'
        this.item = {}
        this.$refs.form.resetFields()
      },

      checkHandler(node, {
        checkedKeys,
        checkedNodes
      }) {

        // 判断节点是否被选中
        const isChecked = checkedNodes.includes(node)

        // 获取所有子节点
        const allChildrenKeys = []
        const getChildrenKeys = (node) => {
          if (!node.children) {
            return allChildrenKeys
          } else {
            node.children.forEach(j => {
              allChildrenKeys.push(j.id) // 添加子节点
              getChildrenKeys(j)
            })
          }
        }
        getChildrenKeys(node)

        // checkedKeys中移除或添加后，设置选中节点
        if (isChecked) {
          allChildrenKeys.forEach(k => {
            if (!checkedKeys.includes(k)) {
              checkedKeys.push(k)
            }
          })
        } else {
          allChildrenKeys.forEach(k => {
            if (checkedKeys.includes(k)) {
              const index = checkedKeys.indexOf(k)
              checkedKeys.splice(index, 1)
            }
          })
        }

        this.$refs.tree.setCheckedKeys(checkedKeys)
      },

      checkAll() {
        let allFun = []
        const addFun = k => {
          if (k.children && k.children.length) {
            k.children.forEach(addFun)
          }
          allFun.push(k.id)
        }

        this.other.fun.forEach(addFun)
        this.$refs.tree.setCheckedKeys(allFun)
      },

      cancelAll() {
        this.$refs.tree.setCheckedKeys([])
      },

      randomName() {
        this.item.UserName = RandomStr(6)
      },

      randomPass() {
        this.item.UserPassword = RandomStr(6)
      },
      // getCityOptions() {
      //   const data = require('@/common/city.json')
      //   let citys = data.districts[0].districts.map(i => {
      //     return {
      //       name: i.name,
      //       value: i.name
      //     }
      //   })
      //   citys = citys.sort( (a, b) => {
      //     return a.name.localeCompare(b.name)
      //   })
      //   //console.log(citys.map(k => k.name).join('/t'))
      //   citys.splice(0, 0, {
      //     name: this.$t('holdDetail.byInstallAddr'),
      //     value: null
      //   })
      //   citys.splice(0, 0, {
      //     name: this.$t('holdDetail.nullAddress'),
      //     value: ''
      //   })
      //   this.cityOptions = citys
      // },
      getCityOptions() {
        return new Promise((resolve, reject) => {
          QueryDistrict().then(ret => {
            let citys = ret.data.list.map(i => {
              return {
                name: i.DName,
                value: i.DName
              }
            })
            citys = citys.sort((a, b) => {
              return a.name.localeCompare(b.name)
            })
            //console.log(citys.map(k => k.name).join('/t'))
            citys.splice(0, 0, {
              name: this.$t('holdDetail.byInstallAddr'),
              value: null
            })
            citys.splice(0, 0, {
              name: this.$t('holdDetail.nullAddress'),
              value: ''
            })
            this.cityOptions = citys
            resolve(ret.data.list)
          }).catch(err => reject(err))
        })
      },
    },
    mixins: [mixin]
  }

</script>

<style lang="scss" scoped>
  .el-tree ::v-deep {
    &>.el-tree-node {
      min-width: 100%;
      display: inline-block !important;
    }
  }

  .floatButton {
    .el-button{
      color: #E99C24;
    }
  }

  .pos {
    position: absolute;
    top: -40px;
    right: 38px;
    z-index: 1;
  }

  .chunk-content {
    & ::v-deep .el-form--label-top {
      .el-form-item__label {
        padding: 0;
      }
    }
    .funMenu{
      height: 490px !important;
    }
  }

</style>
