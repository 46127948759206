<template>
  <el-dialog :visible.sync="visible" v-if="show" :title="$t('deviceDetail.selectVehicle', {name: ''})" custom-class="v-dialog v-dialog--md v-dialog--mdpadding v-dialog--footer" @closed="closed">

    <div>
      <span class="block-tip"><span style="color: #606266;margin-right: 18px;">{{$t('deviceDetail.deviceID')}}: {{sim2}}</span>  {{$t('deviceDetail.tip4')}}</span>
      <search-group class="imb-1" :options="options" style="float:right;" @click="queryHandle" @input="({select,value,Op}) => {searchName=select;searchText=value;searchOption=Op}"
                    :field="{select: searchName, value: searchText}"></search-group>
    </div>

    <el-table
      v-loading="tableLoading"
      element-loading-text="loading"
      class="v-table--mini v-table__customStyle"
      ref="table"
      border
      size="mini"
      :data="tableData"
      @sort-change="sortChange"
      max-height="468px"
      style="width: 100%">
      <el-table-column
        class-name="vehicle-name"
        :label="$t('vehicleManage.vehicleName')"
        sortable="custom"
        prop="VehicleName"
        show-overflow-tooltip>
        <template slot-scope="{row}">
          <vehicle-name  v-if="row.VehicleID" :color="row.VehicleNumBackColor" :data-id="row.VehicleID" :title="`${row.VehicleName}`" disabled>{{ row.VehicleName }}</vehicle-name>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('vehicleManage.vehiclePeople')"
        sortable="custom"
        prop="PeopleName"
        width="180">
        <template slot-scope="{row}">{{row.PeopleName}}</template>
      </el-table-column>
      <el-table-column sortable="custom" prop="DeviceCount" :label="$t('vehicleManage.deviceCount')"
        width="140">
        <div style="overflow:hidden;" slot-scope="{row}">{{ row.DeviceCount }}</div>
      </el-table-column>
      <el-table-column
        width="220"
        show-overflow-tooltip
        sortable="custom" prop="CreateTime" :label="$t('vehicleManage.createTime')">
        <template slot-scope="{row}">{{miment(row.CreateTime).format()}}</template>
      </el-table-column>
      <el-table-column>
        <span slot-scope="{row}" class="i-link" @click="bingVehicle(row.VehicleID)">{{$t('deviceDetail.bind')}}</span>
      </el-table-column>
    </el-table>
    <el-pagination
      class="v-pager imx-2 imt-1"
      :page-size="pageSize"
      :pager-count="7"
      :current-page.sync="pageIndex"
      layout="total, prev, pager, next, jumper"
      :total="pageTotalCount"
      @current-change="pageCurrentChange"
      @size-change="pageSizeChange"
      popper-class="custom">
    </el-pagination>
    <div slot="footer">
      <el-button @click="cancel" class="customStyle"  type="text" size="medium">{{$t('common.cancel')}}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import * as API from '@/api/vehicle'
import {Bind as BindAPI} from '@/api/device'
import {Filter, Op} from '@/api/config'
import miment from 'miment'
import {mapGetters} from 'vuex'
import SearchGroup from '@/views/manage/components/search-group'
export default {
  data() {
    return {
      miment,
      objectId: null,
      sim2: null,
      visible: false,
      show: false,
      tableData: [],
      searchName: '', // 查找字段名
      searchText: '', // 查找关键字
      tableLoading: false,
      pageTotalCount: 0,
      pageIndex: 1,
      pageSize: 10,
      sortKey: '',
      sortDirect: '',
      options: [
        { label: this.$t('vehicleManage.vehicleName'), value: 'VehicleName', Op: Op.Contains },
        { label: this.$t('vehicleManage.vehiclePeople'), value: 'PeopleName', Op: Op.Contains },
        { label: this.$t('vehicleManage.remark'), value: 'V.Remark', Op: Op.Contains }
      ],
    }
  },
  watch: {
    '$i18n.locale': 'getOptions'
  },
  computed: {
    ...mapGetters([
      'loginInfo'
    ])
  },
  components: {
    SearchGroup
  },
  created() {
    this.searchName = this.options[0].value
  },
  methods: {
    open(objectId, sim2) {
      this.objectId = objectId
      this.sim2 = sim2
      this.visible = true
      this.show = true
      this.queryHandle()
    },
    cancel() {
      this.visible = false
    },
    closed() {
      this.searchName = this.options[0].value
      this.searchText = ''
      this.tableData.splice(0, this.tableData.length)
      this.tableLoading = false
      this.pageIndex = 1
      this.pageSize = 10
      this.sortKey = ''
      this.sortDirect = ''
      this.objectId = null
      this.sim2 = null
      this.show = false
    },
    getOptions() {
      this.options = [
        { label: this.$t('vehicleManage.vehicleName'), value: 'VehicleName', Op: Op.Contains },
        { label: this.$t('vehicleManage.vehiclePeople'), value: 'PeopleName', Op: Op.Contains },
        { label: this.$t('vehicleManage.remark'), value: 'V.Remark', Op: Op.Contains }
      ]
    },
    sortChange({ prop, order}) {
      this.sortKey = prop
      this.sortDirect = order ? order.replace(/ending/g, '') : ''
      this.queryHandle()
    },
    queryHandle() {
      if (!this.visible) {
        return
      }
      const promise = new Promise((resolve, reject) => {
        const filters = []

        this.searchText && this.searchText.length > 0
          && (filters.push(new Filter(this.searchName, this.searchText, this.searchOption)))
        this.tableLoading = true
        API.Query(this.pageIndex, this.pageSize, this.sortKey, this.sortDirect, filters, this.loginInfo.HoldID, true).then(ret => {
          this.tableData = ret.data.list.slice()
          this.pageTotalCount = ret.data.total
          this.tableLoading = false
          resolve(ret)
        }).catch(err => {
          this.tableLoading = false
          reject(err)
        })
      })
      return promise
    },
    pageCurrentChange(val) {
      this.pageIndex = val
      this.queryHandle()
    },
    pageSizeChange(val) {
      // console.log('size-change')
      this.pageIndex = 1
      this.pageSize = val
      this.queryHandle()
    },
    bingVehicle(vehicleId) {
      this.tableLoading = true
      BindAPI([this.objectId], vehicleId).then(ret => {
        this.tableLoading = false
        if (ret.data.errCode) {
          this.$alert(ret.data.errMsg)
        } else {
          this.$message({
            message: this.$t('deviceDetail.bindSuccess'),
            type: 'success'
          })
          this.$emit('refresh')
          this.visible = false
        }
      }).catch(err => {
        console.error(err)
        this.tableLoading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-input-group ::v-deep .el-button--primary:not(.is-circle).is-plain {
  border: 1px solid #CC3333;
  padding: 9px 16px;
  background-color: #FFF;
  &:hover {
    background-color: #CC3333;
    color: #FFF;
  }
}
.block-tip {
  background-color: #F5F5F5;
  color: #999;
  padding: 8px 10px;
  font-size: 12px;
  line-height: 20px;
  border: 1px solid #DCDFE6;
  margin-bottom: 12px;
}
</style>

