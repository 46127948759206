<template>
  <el-popover
    placement="bottom-end"
    :open-delay="0"
    transition="page-header-transtion"
    popper-class="page-header-light-popover"
    :boundaries-padding="0"
    ref="pops"
    trigger="click">
    <h4>{{$t('layout.changeLayout')}}</h4>
    <div class="content">

      <div class="switch" @click="select('top')">
        <vue-content-loading class="content__loading" :width="160" :height="100">
          <rect x="0" y="5" rx="4" ry="4" width="160" height="15" />
          <rect x="0" y="25" rx="4" ry="4" width="160" height="75" />
        </vue-content-loading>
        <i-icon name="icon-open_icon_successx" v-if="menuPosition=='top'"></i-icon>
        <p>{{$t('layout.topMenu')}}</p>
      </div>

      <div class="switch" @click="select('right')">
        <!-- <vue-content-loading class="content__loading" :width="160" :height="100">
          <rect x="0" y="5" rx="4" ry="4" width="125" height="15" />
          <rect x="0" y="25" rx="4" ry="4" width="125" height="75" />
          <rect x="130" y="5" rx="4" ry="4" width="30" height="95" />
        </vue-content-loading> -->
        <vue-content-loading class="content__loading" :width="160" :height="100">
          <rect x="30" y="5" rx="4" ry="4" width="125" height="15" />
          <rect x="30" y="25" rx="4" ry="4" width="125" height="75" />
          <rect x="0" y="5" rx="4" ry="4" width="25" height="95" />
        </vue-content-loading>
        <i-icon name="icon-open_icon_successx" v-if="menuPosition=='right'"></i-icon>
        <p>{{$t('layout.rightMenu')}}</p>
      </div>

    </div>

    <slot slot="reference"></slot>
  </el-popover>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import VueContentLoading from 'vue-content-loading'
import {setLayout} from '@/common/cache'
export default {
  components: {
    VueContentLoading
  },
  computed: {
    ...mapGetters([
      'menuPosition'
    ])
  },
  methods: {
    ...mapMutations({
      'setMenuPosition': 'SET_MENU_POSITION'
    }),
    select(val) {
      setLayout(val)
      this.setMenuPosition(val)
      this.$refs.pops & (this.$refs.pops.showPopper = false)
      if (val == 'top') {
        document.querySelector(':root').setAttribute('style', '--page-header-height:48px')
      } else {
        document.querySelector(':root').setAttribute('style', '--page-header-height:48px')
      }
      // console.log(document.querySelector(':root').getAttribute('--page-header-height'))
    }
  }
}
</script>

<style lang="scss" scoped>
h4 {
  padding: 12px 16px 4px;
  font-size: 13px;
}

.content {
  margin-top: 6px;
  margin-bottom: 10px;
  padding: 0 16px;
  font-size: 0;
  white-space: nowrap;
  .switch {
    width: 160px;
    margin-right: 16px;
    display: inline-block;
    cursor: pointer;
    font-size: 16px;
    position: relative;
    .icon {
      position: absolute;
      bottom: 60px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
      filter: drop-shadow(0 0 0 #ccc);
    }
    p {
      font-size: 13px;
      text-align: center;
    }
  }
  .switch:last-child {
    margin-right: 0;
  }
}
</style>

