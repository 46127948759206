import { getLanguage, getDefaultMap } from '@/common/cache'
const con = require('./_config_' + process.env.NODE_ENV)
export const BASE_URL = con.URL
export const POST_URL = con.URL + '/posts/posts.ashx'
export const DOWNLOAD_URL = con.URL.replace(/api/g,'')
export const DEFAULT_IMAGE_URL_PREFIX = DOWNLOAD_URL + 'upload/image/'
export const PLATFORM = 1
export const APPID = 'wx144ffc5b084960c9'
let _commonParams = {
  format: 'json',
  language: getLanguage('zh'),
  current_map: getDefaultMap('Amap')
}
export const commonParams = _commonParams

export const Op = {
  Equals: 0, // 等于
  GreaterThan: 1, // 大于
  LessThan: 2, // 小于
  GreaterThanOrEqual: 3, // 大于等于
  LessThanOrEqual: 4, // 小于等于
  Contains: 5, // 包含
  StartsWith: 6, // 以XXX开始
  EndsWith: 7, // 以XXX结束
  SListContains: 8, // List<string>包含
  IListContains: 9, // List<int>包含
  OListContains: 10 // List<object>包含
}

export class Filter {
  constructor(PropertyName, Value, Operation, Id = null) {
    this.PropertyName = PropertyName
    this.Value = Value
    this.Operation = Operation,
    this.Id = Id
  }
}

export const Sort = {
  ASC: 'OrderBy',
  DESC: 'OrderByDescending'
}

export function setCommonParam(option) {
  _commonParams = Object.assign(_commonParams, option)
}
