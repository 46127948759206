import request from '@/common/request'
import { commonParams } from '@/api/config'
import { getToken } from '@/common/auth'

export function GetTree() {
  const loginKey = getToken()
  return request({
    url: './query/QueryUserTree',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey
    })
  })
}

export function QueryUserList(queryKey='') {
  const loginKey = getToken()
  return  request({
    url: './query/QueryUserList',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      queryKey
    })
  })
}

export function QueryUserListWithObject(queryKey='') {
  const loginKey = getToken()
  return  request({
    url: './query/QueryUserListWithObject',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      queryKey
    })
  })
}

export function QueryTreeVehicleList(queryKey='', HoldID) {
  const loginKey = getToken()
  return  request({
    url: './query/QueryTreeVehicleList',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      queryKey,
      HoldID
    })
  })
}

export function QueryTreeVehicleListVIP(queryKey='', HoldID=null, FunID) {
  const loginKey = getToken()
  return  request({
    url: './query/QueryTreeVehicleListVIP',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      queryKey,
      FunID,
      HoldID
    })
  })
}

export function QueryTreeVehicleListVIPFK(queryKey='', HoldID=null, FunID) {
  const loginKey = getToken()
  return  request({
    url: './query/QueryTreeVehicleListVIPFK',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      queryKey,
      FunID,
      HoldID
    })
  })
}
