<template>
  <span class="add-task-car" @click="click" ref="wrapper" :title="$t('common.assTaskCar')">
    <!-- <i-icon name="icon-taxi"></i-icon> -->
    <template v-if="types===0">
      <em> <i-icon name="iconxinxiguanli_cheliang_weixuanzhong"></i-icon></em>
    </template>
    <template v-if="types===1">
      <em><i-icon name="iconxinxiguanli_renyuan_xuanzhong"></i-icon></em>
    </template>
    <template v-if="types===2">
      <em><i-icon name="iconxinxiguanli_wupin_xuanzhong"></i-icon></em>
    </template>
    <i class="el-icon-plus"></i>

    <span class="add-task-car__inner" v-if="isMove" :class="{'move': isMove}">
      <!-- <i-icon name="icon-taxi"></i-icon> -->
      <template v-if="types===0">
        <em> <i-icon name="iconxinxiguanli_cheliang_weixuanzhong"></i-icon></em>
      </template>
      <template v-if="types===1">
        <em><i-icon name="iconxinxiguanli_renyuan_xuanzhong"></i-icon></em>
      </template>
      <template v-if="types===2">
        <em><i-icon name="iconxinxiguanli_wupin_xuanzhong"></i-icon></em>
      </template>
      <i class="el-icon-plus"></i>
    </span>
  </span>
</template>

<script>
export default {
  props: {
    types: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      isMove: false
    }
  },
  mounted() {

  },
  methods: {
    click() {
      this.isMove = true
      setTimeout(() => {
        this.isMove = false
      }, 500)
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.add-task-car {
  position: relative;
  em{
    display: inline-block;
    width:20px;
    height: 20px;
    overflow: hidden;
    position: relative;
    font-size: 30px;
    .icon{
      position: absolute;
      top:0;
      left: -4px;
    }
  }
  &:hover {
    cursor: pointer;
    transition: all 0.3s ease-out;
  }
  & > .el-icon-plus {
    position: absolute;
    font-size: 12px;
    right: -10px;
    top: -2px;
  }

  .add-task-car__inner {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    &.move {
      animation: move 0.5s ease-out;
      color: #CC3333;
    }
    .el-icon-plus {
      position: absolute;
      font-size: 12px;
      right: -10px;
      top: -2px;
    }
  }
}
@keyframes move {
  0% {
    top: 0;
    opacity: 1;
  }
  100% {
    top: -20px;
    opacity: 0;
  }
}
</style>
