import { mapGetters, mapMutations } from 'vuex'
import TreeUser from '@/views/common/tree-user'
import SearchGroup from '@/views/manage/components/search-group'
import ChangeParent from './change-parent'
import { getColumnWidth, setColumnWidth } from '@/common/cache'
let common = {
  data() {
    return {
      queryButtonLoading: false, // 查询按钮loading
      exportButtonLoading: false, // 导出按钮loading
      pageTotalCount: 0, // 总行数
      pageIndex: 1, // 页码
      pageSize: 10, // 每页行数
      pageSizes: [10, 20, 30, 40, 50, 100],
      pageSizes2: [10, 20, 30, 40, 50, 100, 500, 1000],
      sortKey: '', // 排序关键字
      sortDirect: '', // 排序方向
      searchName: '', // 查找字段名
      searchText: '', // 查找关键字
      searchOption: null, // 比较方法
      tableData: [], // 表格数据
      tableLoading: false, // 表格loading
      dialogChangeParent: false, // 更改上级对话框
      dialogChangeParnetReal: false, // 上级对话框
      changedRow: [], // 选中行
      isShowMore: false,
      hasChild: true,
      holdName: '',
      tableHeight: 'calc(100vh - 171px)',
      tableHeight2: 'calc(100vh - 238px)',
      tableLoadingText: this.$t('common.loading')
    }
  },
  components: {
    TreeUser,
    SearchGroup,
    ChangeParent
  },
  computed: {
    ...mapGetters([
      'loginInfo',
      'currentHoldId',
      'currentHoldName',
      'currentHasChild'
    ])
  },
  watch: {
    currentHasChild(val) {
      // if (this.$options.name != this.$route.name) {
      //   return
      // }

      if (this._inactive) {
        return
      }
      this.hasChild = val
    },
    hasChild() {
      this.setCurrentHasChild(this.hasChild)
      this.queryHandle()
    },
    currentHoldId() {
      // if (this.$options.name != this.$route.name) {
      //   return
      // }

      if (this._inactive) {
        return
      }

      // 保存本次查询的HoldId
      this.currentHoldId_bak = this.currentHoldId
      this.queryHandle()
    },
    $route(val, oldVal) {
      // 路由切换回本页面时，如果当前HoldID与上次查询holdId不一致时，查询一次数据
      if (val.path !== oldVal.path
        && val.name === this.$options.name
        && this.currentHoldId_bak != this.currentHoldId) {
        this.currentHoldId_bak = this.currentHoldId
        this.queryHandle()
      } else if (this.hasChild !== this.currentHasChild) {
        this.hasChild = this.currentHasChild
      }
    },
    tableLoading(val, oldVal) {
      if (val && !oldVal) {
        if (this.tableLoading_timer) {
          clearTimeout(this.tableLoading_timer)
        }

        this.tableLoading_timer = setTimeout(() => {
          this.tableLoading = false
          this.tableLoading_timer = null
        }, 15000)
      } else {
        if (this.tableLoading_timer) {
          clearTimeout(this.tableLoading_timer)
          this.tableLoading_timer = null
        }
      }
    },
    '$i18n.locale'() {
      this.tableLoadingText = this.$t('common.loading')
    }
  },
  created() {
    // 计算pageSize
    this.pageSize = Math.floor((window.innerHeight - 184 - 17) / 38) - 1
    // 插入pageSizes
    if (!this.pageSizes.includes(this.pageSize)) {
      let index = 0
      for (; index < this.pageSizes.count; index++) {
        if (this.pageSize < this.pageSize[index]) {
          break
        }
      }
      this.pageSizes.splice(index + 1, 0, this.pageSize)
    }


    this.searchName = this.options[0].value
    this.hasChild = this.currentHasChild
    this.currentHoldId_bak = this.currentHoldId
    this.loadHeaderDragWidth()
  },
  methods: {
    print(type, msg, time = 5000) {
      if (!msg) {
        return
      }
      this.$message({
        showClose: true,
        message: msg,
        type,
        duration: time
      })
    },
    pageCurrentChange(val) {
      this.pageIndex = val
      this.queryHandle()
    },
    pageSizeChange(val) {
      // console.log('size-change')
      // this.pageIndex = 1
      this.pageSize = val
      this.queryHandle()
    },
    treeClick(holdID, name) {
      this.setCurrentHoldId(holdID)
      this.setCurrentHoldName(name)
    },
    rowChange(row, tip = true) {
      if (tip === true) {
        if (!row || row.length === 0) {
          this.$message({
            message: this.$t('common.emptySelectedTip')
          })
          return
        }
      }
      this.changedRow = row.slice()
      this.dialogChangeParent = true
      this.dialogChangeParnetReal = true
    },
    /*
     * 列信息
     * 字段名
     * ascending / descending
     */
    sortChange({ column, prop, order }) {
      // {column, prop, order}
      console.log('排序变更', column, prop, order)
      this.sortKey = prop
      this.sortDirect = order ? order.replace(/ending/g, '') : ''
      this.queryHandle()
    },
    // rowClass({row}) {
    //   const findRow = k => {
    //     return k.HoldID === row.HoldID
    //   }
    //   if (this.$refs.multipleTable.selection && this.$refs.multipleTable.selection.findIndex(findRow) != -1) {
    //     return 'checked'
    //   }
    //   return null
    // },
    saveHeaderDragWidth(newWidth, oldWidth, column) {
      const columnsWidth = getColumnWidth()
      const tableWidth = columnsWidth[this.$options.name] || {}
      const labelKey = `width_${column.label}`
      tableWidth[labelKey] = newWidth
      columnsWidth[this.$options.name] = tableWidth
      setColumnWidth(columnsWidth)
    },
    loadHeaderDragWidth() {
      const columnsWidth = getColumnWidth()
      this.tableWidth = columnsWidth[this.$options.name] || {}
    },
    TW(label, defaultWidth) {
      const width = this.tableWidth[`width_${label}`] || defaultWidth
      return `${width}`
    },
    formatText(row, col, val) {
      return val || '--'
    },
    ...mapMutations({
      setCurrentHoldId: 'SET_CURRENT_HOLD_ID',
      setCurrentHoldName: 'SET_CURRENT_HOLD_NAME',
      setCurrentHasChild: 'SET_CURRENT_HAS_CHILD'
    })
  },
  filters: {
    emptyFormat: function (value) {
      if (!value) return '--'
      return value
    }
  }
}

export default common

