<template>
  <div class="list_option" @click="select">
    {{label}}
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      default: null
    }
  },
  methods: {
    select() {
      this.$emit('click')
      this.$parent && this.$parent.$parent.select({...this.$props})
    }
  }
}
</script>

<style lang="scss" scoped>

$padding-vertical: 8px;
$padding-horizontal: 12px;
$text-dark: #424242;
$text-secondary: #616161;
$color-bk: #FFF;
$color-hover: #CFD8DC;

.list_option {
  padding: $padding-vertical $padding-horizontal;
  min-width: 80px;
  white-space: nowrap;
  background-color: $color-bk;
  color: $text-secondary;
  cursor: pointer;
  line-height: 1;
  background-color: #FAFAFA;
  //border-bottom: 1px solid #EBEEF5;
  font-size: 13px;
  text-align: center;
  &:hover {
    background-color: rgba($color-hover, .6);
  }
}
</style>

