<template>
  <div class="infoWindow">
    <div class="relieve" v-if="isAlarm" @click="cancelAlarm"><span>
        <i-icon name="iconjiechujingbao"></i-icon>
      </span>{{$t('onlineMonitor.disalarm')}}</div>
    <div class="caption">
      <div class="center">
        <template v-if="TypeID===1">
          <div class="name">{{personName}}</div>
        </template>
        <template v-if="TypeID===2">
          <div class="name">{{ArtName}}</div>
        </template>
        <template v-if="TypeID===0">
          <div class="name">{{VehicleName}}</div>
        </template>
        <div class="area">
          <div class="span" v-if="TypeID===1 && externalData && externalData.battery != 0">
            <i-icon name="icondianchi2"></i-icon>
            <em>{{externalData.battery}}%</em>
          </div>
          <div class='span'>
            <i-icon name="iconxinhao"></i-icon>
            <template v-if="GSM>24">
              {{$t('onlineMonitor.gsm1')}}
            </template>
            <template v-else-if="GSM>15">
              {{$t('onlineMonitor.gsm2')}}
            </template>
            <template v-else>
              {{$t('onlineMonitor.gsm3')}}
            </template>
            <span>{{GSM==='NULL'?'':GSM}}</span>
          </div>
          <div class='span'>
            <i-icon name="iconweixing"></i-icon>
            <template v-if="GPS>7">
              {{$t('onlineMonitor.gps1')}}
            </template>
            <template v-else-if="GPS>4">
              {{$t('onlineMonitor.gps2')}}
            </template>
            <template v-else>
              {{$t('onlineMonitor.gps3')}}
            </template>
            <span>{{GPS==='NULL'?'':GPS}}</span>
          </div>
          <div v-if="VideoCircuit" class='span'>
            <i-icon name="iconshexiangtou"></i-icon>
          </div>
        </div>
      </div>
      <span class="close" @click="onClose"><i class="el-icon-close"></i></span>
    </div>
    <div class="bd">
      <template v-if="TypeID!==1">
        <div class="album" v-if="externalData && externalData.battery != 0">
          <div class="boxs">
            <div class="img">
              <img :src="require(`../../assets/battery/${parseInt(externalData.battery/10).toFixed(0)*10}.png`)" />
            </div>
            <p class="batterys">{{externalData.battery}}%</p>
            <p class="batteryLife" v-if="externalData.batteryLife">
              <!-- {{$t('commonPanel.endurance')}} -->
              {{externalData.batteryLife}} {{$t('commonPanel.day')}}</p>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="photo">
          <div class="in">
            <img src="../../assets/images/person.png" alt="" v-if="TypeID===1&&!PicPath.length">
            <img :src="'https://www.365qczx.com/api/posts/image.ashx?name='+PicPath[0]+'&size=140'" alt="" v-if="TypeID===1&&PicPath.length">
          </div>
        </div>
      </template>
      <div class="box" :class="$i18n.locale">
        <div :class="TypeID===1||(TypeID!==1 && externalData && externalData.battery != 0)?'right w1':'right'">
          <template v-if="TypeID===1">
            <div class="line" v-for="(item,index) in personnelData" :key="index"  :style="item.hidden ? (externalData && externalData.battery != 0 ? 'display:none' : 'display:none') : ''">
              <strong :title="$t('onlineMonitor')[item.title]">{{$t('onlineMonitor')[item.title]}}</strong>
              <span v-if="item.rawHtml" v-html="item.text"></span><span v-else
                :class="(item.title=='statsDes'&& externalData && externalData.battery != 0)?'minHeight':''">{{item.text}}</span>
            </div>
          </template>
          <template v-else-if="TypeID===2">
            <div class="line" v-for="(item,index) in objectData" :key="index"  :style="item.hidden ? (externalData && externalData.battery != 0 ? 'display:none' : 'display:none') : ''">
              <strong :title="$t('onlineMonitor')[item.title]">{{$t('onlineMonitor')[item.title]}}</strong>
              <span v-if="item.rawHtml" v-html="item.text"></span><span v-else
                :class="(item.title=='statsDes'&& externalData && externalData.battery != 0)?'minHeight':''">{{item.text}}</span>
            </div>
          </template>
          <template v-else>
            <div class="line" v-for="(item,index) in datas.filter(k => !!k)" :key="index"
              :style="item.hidden ? (externalData && externalData.battery != 0 ? 'display:none' : 'display:none') : ''">
              <strong :title="$t('onlineMonitor')[item.title]">{{$t('onlineMonitor')[item.title]}}</strong>
              <span v-if="item.rawHtml" v-html="item.text"></span><span v-else
                :class="(item.title=='statsDes'&& externalData && externalData.battery != 0)?'minHeight':''">{{item.text}}</span>
            </div>
            <div class="line" v-if="externalData && getScheduleLabel(externalData.schedule) != '暂无'">
              <strong>{{$t('commonPanel.wake')}}</strong>
              <span v-html="getScheduleLabel(externalData.schedule)"></span>
            </div>
          </template>
        </div>
        <template v-if="showWifiInfo">
          <div class="line" v-if="activeType">
            <strong>
              <template v-if="activeType=='wifi'">{{$t('commonPanel.wifiLocation')}}</template>
              <template v-if="activeType=='lbs'">{{$t('commonPanel.lbsLocation')}}</template>
              <template v-if="activeType=='gps'">{{$t('commonPanel.gpsLocation')}}</template>
              <!-- {{gpsLabel}}  -->
            </strong>
            <span-address v-if="activeLon" :lon="activeLon" :lat="activeLat" :show-default="showAddrDefault"
              :disabled="disabledAddress" @click="onAddressClick(objectId)" :time="activeType=='gps'?gtime:rtime">
            </span-address>
            <span v-else>({{$t('commonPanel.noPlace')}})</span>
            <em v-if="locationCount > 1" @click="()=> dropAddress=!dropAddress" :class="dropAddress?'down':'down r1'">
              <i-icon name="iconxiala1"></i-icon>
            </em>
          </div>

          <template v-if="dropAddress">
            <div class="line" v-if="wifiLon&&activeType!=='wifi'">
              <strong>{{$t('commonPanel.wifiLocation')}}</strong>
              <span-address :lon="wifiLon" :lat="wifiLat" :show-default="showAddrDefault" :disabled="disabledAddress"
                @click="onAddressClick(objectId)" :time="rtime"></span-address>
            </div>
            <div class="line" v-if="lbsLon&&activeType!=='lbs'">
              <strong>{{$t('commonPanel.lbsLocation')}}</strong>
              <span-address :lon="lbsLon" :lat="lbsLat" :show-default="showAddrDefault" :disabled="disabledAddress"
                @click="onAddressClick(objectId)" :time="rtime"></span-address>
            </div>
            <div class="line" v-if="gpsLon&&activeType!=='gps'">
              <!-- <strong>{{gpsLabel}}</strong> -->
              <strong>{{$t('commonPanel.gpsLocation')}}</strong>
              <span-address v-if="gpsLon" :lon="gpsLon" :lat="gpsLat" :show-default="showAddrDefault"
                :disabled="disabledAddress" @click="onAddressClick(objectId)" :time="gtime"></span-address>
              <span v-else>({{$t('commonPanel.noPlace')}})</span>
            </div>
          </template>
        </template>
        <template v-if="!hideOriginGps">
          <div class="line">
            <strong>{{gpsLabel}}</strong>
            <span-address :lon="lon" :lat="lat" :show-default="showAddrDefault" :disabled="disabledAddress"
              @click="onAddressClick(objectId)" :time="gtime"></span-address>
          </div>
        </template>
      </div>
    </div>
    <div class="dm">
        <div class="cell">
            <div class="dt"><img :src="require('../../assets/images/win_01.png')" alt=""><span>里程</span></div>
            <div class="dd">
            <strong class="str">{{(Mileage-ToDayMile)>0?(Mileage-ToDayMile).toFixed(1):0.0}}{{$t('onlineMonitor.un2')}}
              <el-tooltip placement="top">
                <div slot="content">{{$t('onlineMonitor.allMiles')}}:{{Mileage?Mileage:0}}{{$t('onlineMonitor.un2')}}
                </div>
                <div class="pos">
                  <i-icon name="iconzhuanhuan"></i-icon>
                </div>
              </el-tooltip>
            </strong>
            </div>
        </div>
        <div class="cell">
            <div class="dt"> <img :src="require('../../assets/images/win_02.png')" alt=""><span>{{TypeID!==1?'速度':'配速'}}</span></div>
            <div class="dd">{{TypeID!==1?Speed+'km/h':PaceSpeed}}</div>
        </div>
        <div class="cell">
            <div class="dt"> <img :src="require('../../assets/images/win_03.png')" alt=""><span>海拔</span></div>
            <div class="dd">{{Altitude}}米</div>
        </div>
        <div class="cell">
            <div class="dt"> <img :src="require('../../assets/images/win_04.png')" alt=""><span>状态</span></div>
            <div class="dd" :style="{color: titleBackground}">
             <!-- 蓝色 -->
              <strong v-if="titleBackground==='#1469b8'">熄火</strong>
              <!-- 绿色 -->
              <strong v-if="titleBackground==='#65c240'">运动</strong>
              <!-- 红色 -->
              <strong v-if="titleBackground==='#ff4141'">静止</strong>
              <!-- 灰色 -->
              <strong v-if="titleBackground==='#7f7f7f'">离线</strong>
              <!-- 黄色 -->
              <strong v-if="titleBackground==='#ffd800'">报警</strong>
                <!-- 绿色 -->
              <strong v-if="titleBackground==='#ac55eb'">GPS弱</strong>
            </div>
        </div>
        <!-- <div class="cell">
            <div class="dt"><img :src="require('../../assets/images/win_05.png')" alt=""><span>电量</span></div>
            <div class="dd">{{BattertLife}}</div>
        </div> -->
    </div>
    <!-- <div class="divider">
      <div class="cell">
        <template v-if="$i18n.locale==='zh'">
          <div class="el">
            <span>
              <i-icon name="iconlicheng"></i-icon>
            </span>
            <strong>{{(Mileage-ToDayMile)>0?(Mileage-ToDayMile).toFixed(1):0.0}}{{$t('onlineMonitor.un2')}}
              <el-tooltip placement="top">
                <div slot="content">{{$t('onlineMonitor.allMiles')}}:{{Mileage?Mileage:0}}{{$t('onlineMonitor.un2')}}
                </div>
                <div class="pos">
                  <i-icon name="iconzhuanhuan"></i-icon>
                </div>
              </el-tooltip>
            </strong>
          </div>
        </template>
        <template v-else>
          <div class="el">
            <span>
              <i-icon name="iconlicheng"></i-icon>
            </span>
            <strong>{{Mileage?Mileage.toFixed(1):0}}{{$t('onlineMonitor.un2')}}</strong>
          </div>
        </template>
      </div>
      <div class="cell">
        <div class="el"><span>
            <i-icon name="iconsudu"></i-icon>
          </span><strong>{{Speed}}{{$t('onlineMonitor.un1')}}</strong></div>
      </div>
      <div class="cell">
        <div class="el">
          <strong>{{BattertLife}}</strong>
        </div>
      </div>
      <div class="cell">
        <div class="el">
          <strong>{{Altitude}}</strong>
        </div>
      </div>
      <div class="cell" v-if="TrackerType!==0&&Temperature">
        <div class="el"><span>
            <i-icon name="iconwendu"></i-icon>
          </span><strong>{{Temperature?Temperature:0}} {{$t('onlineMonitor.un3')}}</strong></div>
      </div>
    </div> -->

    <div class="tool" v-if="!!links.length">
      <div class="gap" :class="$i18n.locale">
        <!-- <div :class="item.lang?'box lang':'box'" v-for="(item,index) in links" :key="index"
          @click="e => onLinkClick(item.text || item, )">
          <div class="is">
            <el-tooltip placement="top" :hide-after="3000">
              <div slot="content"> {{item.text || item}}</div>
              <span>
                <i-icon :name="item.icon" :class="item.icon"></i-icon>
              </span>
            </el-tooltip>
          </div>
        </div> -->
        <div class="ol" v-for="(item,index) in links" :key="index" @click="e => onLinkClick(item.text || item, )">
          <template v-if="item.icon==='iconxiangqing'">
            <div class="img"><img :src="require('../../assets/images/win_08.png')" alt=""></div>
            <div class="txt">资料</div>
          </template>
           <template v-if="item.icon==='iconguiji'">
            <div class="img"><img :src="require('../../assets/images/win_10.png')" alt=""></div>
            <div class="txt">轨迹</div>
          </template>
           <template v-if="item.icon==='iconshoucang'">
            <div class="img"><img :src="require('../../assets/images/win_12.png')" alt=""></div>
            <div class="txt">关注</div>
          </template>
          <template v-if="item.icon==='iconyishoucang'">
            <div class="img"><img :src="require('../../assets/images/win_09.png')" alt=""></div>
            <div class="txt">取消关注</div>
          </template>
           <template v-if="item.icon==='iconzhiling'">
            <div class="img"><img :src="require('../../assets/images/win_11.png')" alt=""></div>
            <div class="txt">发送指令</div>
          </template>
        </div>
      </div>
    </div>
    <div class="arrow"></div>
  </div>
</template>

<script>
  import SpanAddress from '@/views/common/span-address'
  import {
    GetWIFIAndLBSInfoByObjectID
  } from '@/api/common'
  import {
    debounce
  } from '@/common/utils'
  export default {
    name: "infoWindow",
    props: {
      VehicleName: {
        type: String,
        default: ''
      },
      personName: {
        type: String,
        default: ''
      },
      ArtName: {
        type: String,
        default: ''
      },
      VehicleID: {
        type: String,
        default: ''
      },
      TypeID: {
        type: String,
        default: ''
      },
      VideoCircuit: {
        type: String,
        default: ''
      },
      Speed: {
        type: String,
        default: ''
      },
      PaceSpeed: {
        type: String,
        default: ''
      },
      Temperature: {
        type: String,
        default: '0'
      },
      SF: {
        type: String,
        default: '0'
      },
      isFocus: {
        type: Boolean,
        default: false
      },
      Mileage: {
        type: Number,
        default: 0
      },
      ToDayMile: {
        type: String,
        default: ''
      },
      BattertLife: {
        type: String,
        default: ''
      },
      Altitude: {
        type: String,
        default: ''
      },
      DayMile: {
        type: String,
        default: ''
      },
      vehicleData: {
        type: Array,
        default: () => ([])
      },
      personnelData: {
        type: Array,
        default: () => ([])
      },
      objectData: {
        type: Array,
        default: () => ([])
      },
      // 
      title: {
        type: String,
        default: ''
      },
      signal: {
        type: [String, Number],
        default: 0
      },
      battery: {
        type: [String, Number],
        default: 0
      },
      datas: {
        type: Array,
        default: () => ([])
      },
      more: {
        type: Array,
        default: () => ([])
      },
      close: {
        type: Boolean,
        default: true
      },
      onChange: {
        type: Function,
        default: () => {}
      },
      onClose: {
        type: Function,
        default: () => {}
      },
      onHeadClick: {
        type: Function,
        default: () => {}
      },
      titleBackground: {
        type: String,
        default: '#0277BD'
      },
      attachState: {
        type: String,
        default: ''
      },
      bgColor: {
        type: Boolean,
        default: true
      },
      showMore: {
        type: Boolean,
        default: false
      },
      time: {
        type: String,
        default: ''
      },
      visible: {
        type: Boolean,
        default: true
      },
      paddingLeft: {
        type: String,
        default: '100px'
      },
      links: {
        type: Array,
        default: () => ([])
      },
      dvrData: {
        type: Array,
        default: () => ([])
      },
      PicPath: {
        type: Array,
        default: () => ([])
      },
      onLinkClick: {
        type: Function,
        default: () => {}
      },
      isAlarm: {
        type: Boolean,
        default: false
      },
      onCancelAlarm: {
        type: Function,
        default: () => {}
      },
      objectId: {
        type: Number,
        default: null
      },
      headClick: {
        type: Boolean,
        default: false
      },
      lon: {
        type: [Number, String],
        default: null
      },
      lat: {
        type: [Number, String],
        default: null
      },
      GSM: {
        type: [Number, String],
        default: null
      },
      GPS: {
        type: [Number, String],
        default: null
      },
      externalTag: {
        type: Boolean,
        default: false
      },
      externalData: {
        type: Object,
        default: null
        // 有线设备无线设备的额外信息如：
        // externalData = {
        //   trackerType: 3212,
        //   battery: device.Battery,
        //   batteryLife: device.BatteryLife,
        //   schedule: device.Schedule
        // }
      },
      showHeadButton: {
        type: Boolean,
        default: false
      },
      onHeadButtonClick: {
        type: Function,
        default: () => {}
      },
      videoURL: {
        type: Function,
        default: () => {}
      },
      showAddrDefault: {
        type: Boolean,
        default: false
      },
      labelSuffix: {
        type: String,
        default: '：'
      },
      showWifiInfo: {
        type: Boolean,
        default: false
      },
      gpsLabelDefault: {
        type: String,
        default: ''
      },
      disabledAddress: {
        type: Boolean,
        default: false
      },
      onAddressClick: {
        type: Function,
        default: () => {}
      },
      gtime: {
        type: String,
        default: ''
      },
      TrackerType: {
        type: String,
        default: '0'
      },
      rtime: {
        type: String,
        default: ''
      },
      hideOriginGps: {
        type: Boolean,
        default: false
      },
      onAddressUpdate: {
        type: Function,
        default: () => {}
      },
      SF: {
        type: Number,
        default: 0
      },
      showDvr: {
        type: Boolean,
        default: false
      },
    },
    watch: {
      gpsLabelDefault(val) {
        this.gpsLabel = val
      },
      objectId() {
        this.d_getWifiLocation()
      },
      gtime() {
        this.d_getWifiLocation()
      },
      rtime() {
        this.d_getWifiLocation()
      }
    },
    computed: {
      locationCount() {
        if (!this.showWifiInfo) {
          return false
        }
        let i = 0;
        if (this.wifiLon) {
          i++
        }
        if (this.gpsLon) {
          i++
        }
        if (this.lbsLon) {
          i++
        }
        return i
      }
    },
    data() {
      return {
        wifiLon: null,
        wifiLat: null,
        lbsLon: null,
        lbsLat: null,
        gpsLon: null,
        gpsLat: null,
        activeType: 'gps',
        activeLon: null,
        activeLat: null,
        gpsLabel: this.gpsLabelDefault || this.$t('commonPanel.place'),
        dropAddress: false
      };
    },
    methods: {
      getScheduleLabel(schedule) {
        if (!schedule) {
          return '暂无'
        }
        if (schedule.indexOf(':') != -1) {
          const numberArray = schedule.replace(/:/g, '').split(' ')
          const now = new Date()
          const maxArray = numberArray.filter(k => {
            return parseInt(k) > (now.getHours() * 100 + now.getMinutes())
          })

          let highligntItemIndex = 0
          if (maxArray.length > 0) {
            highligntItemIndex = numberArray.indexOf(maxArray[0])
          }

          let output = ''
          const timeArray = schedule.split(' ')
          timeArray.forEach((k, index) => {
            output += index === highligntItemIndex ?
              `<img src="${require('../../assets/icon/Timer24.gif')}" style="vertical-align:bottom;margin-right:4px;" alt="${this.$t('commonPanel.wakeTime')}" title="${this.$t('commonPanel.wakeTime')}"/><span style="color:red;">${k}</span>` :
              k
            output += "&nbsp;"
          })
          return output
        } else {
          let iPos = 0
          let numberArray = []
          let timeArray = []
          while (iPos + 4 <= schedule.length) {
            numberArray.push(schedule.substr(iPos, 2) + schedule.substr(iPos + 2, 2))
            timeArray.push(schedule.substr(iPos, 2) + ':' + schedule.substr(iPos + 2, 2))
            iPos = iPos + 4;
          }

          const now = new Date()
          const maxArray = numberArray.filter(k => {
            return parseInt(k) > (now.getHours() * 100 + now.getMinutes())
          })

          let highligntItemIndex = 0
          if (maxArray.length > 0) {
            highligntItemIndex = numberArray.indexOf(maxArray[0])
          }

          let output = ''
          timeArray.forEach((k, index) => {
            output += index === highligntItemIndex ?
              `<img src="${require('../../assets/icon/Timer24.gif')}" style="vertical-align:bottom;margin-right:4px;" alt="${this.$t('commonPanel.wakeTime')}" title="${this.$t('commonPanel.wakeTime')}"/><span style="color:red;">${k}</span>` :
              k
            output += "&nbsp;"
          })
          return output
        }
      },
      cancelAlarm() {
        this.onCancelAlarm(this.objectId)
      },
      getWifiLocation() {
        if (this.showWifiInfo) {
          GetWIFIAndLBSInfoByObjectID(this.objectId).then(ret => {
            this.dropAddress = false
            if (!ret.data.cell.points.length) {
              this.lbsLon = null
              this.lbsLat = null
            } else {
              this.lbsLon = ret.data.cell.sCenter.Lng
              this.lbsLat = ret.data.cell.sCenter.Lat
            }

            if (!ret.data.wifi.points.length) {
              this.wifiLon = null
              this.wifiLat = null
            } else {
              this.wifiLon = ret.data.wifi.sCenter.Lng
              this.wifiLat = ret.data.wifi.sCenter.Lat
            }

            if (ret.data.gps.center.Lng) {
              this.gpsLon = ret.data.gps.sCenter.Lng
              this.gpsLat = ret.data.gps.sCenter.Lat
            } else {
              this.gpsLon = null
              this.gpsLat = null
            }

            this.activeType = ret.data.type
            this.activeLon = this.activeType === 'wifi' ? this.wifiLon : (this.activeType === 'lbs' ? this.lbsLon :
              this.gpsLon)
            this.activeLat = this.activeType === 'wifi' ? this.wifiLat : (this.activeType === 'lbs' ? this.lbsLat :
              this.gpsLat)
            setTimeout(() => {
              if (this.locationCount > 1) {
                this.onAddressUpdate()
              }
            }, 150)
          }).catch(e => {
            console.error(e)
            this.wifiLon = null
            this.wifiLat = null
            this.lbsLon = null
            this.lbsLat = null
            this.gpsLon = null
            this.gpsLat = null
          })
        } else {
          this.wifiLon = null
          this.wifiLat = null
          this.lbsLon = null
          this.lbsLat = null
          this.gpsLon = null
          this.gpsLat = null
        }
      }
    },
    created() {
      this.d_getWifiLocation = debounce(this.getWifiLocation, 50)
      if (this.showWifiInfo) {
        this.getWifiLocation()
      }
    },
    mounted() {

    },

  };

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import './infoWindow.scss';

</style>
