export const addView = ({ dispatch }, view) => {
  dispatch('addVisitedView', view)
  dispatch('addCachedView', view)
}

export const addVisitedView = ({commit}, view) => {
  commit('ADD_VISITED_VIEW', view)
}

export const addCachedView = ({commit}, view) => {
  commit('ADD_CACHED_VIEW', view)
}

export const delView = ({ dispatch, state }, view) => {
  return new Promise(resolve => {
    dispatch('delVisitedView', view)
    dispatch('delCachedView', view)
    resolve({
      visitedViews: [...state.visitedViews],
      cachedViews: [...state.cachedViews]
    })
  })
}

export const delVisitedView = ({commit, state}, view) => {
  return new Promise((resolve) => {
    commit('DEL_VISITED_VIEW', view)
    resolve([...state.visitedViews])
  })
}

export const delCachedView = ({commit, state}, view) => {
  return new Promise(resolve => {
    commit('DEL_CACHED_VIEW', view)
    resolve([...state.cachedViews])
  })
}


export const delOthersViews = ({ dispatch, state }, view) => {
  return new Promise(resolve => {
    dispatch('delOthersVisitedViews', view)
    dispatch('delOthersCachedViews', view)
    resolve({
      visitedViews: [...state.visitedViews],
      cachedViews: [...state.cachedViews]
    })
  })
}

export const delOthersVisitedViews = ({ commit, state }, view) => {
  return new Promise(resolve => {
    commit('DEL_OTHERS_VISITED_VIEWS', view)
    resolve([...state.visitedViews])
  })
}

export const delOthersCachedViews = ({ commit, state }, view) => {
  return new Promise(resolve => {
    commit('DEL_OTHERS_CACHED_VIEWS', view)
    resolve([...state.cachedViews])
  })
}

export const delAllViews = ({ dispatch, state }) => {
  return new Promise(resolve => {
    dispatch('delAllVisitedViews')
    dispatch('delAllCachedViews')
    resolve({
      visitedViews: [...state.visitedViews],
      cachedViews: [...state.cachedViews]
    })
  })
}

export const delAllVisitedViews = ({ commit, state }) => {
  return new Promise(resolve => {
    commit('DEL_ALL_VISITED_VIEWS')
    resolve([...state.visitedViews])
  })
}

export const delAllCachedViews = ({ commit, state }) => {
  return new Promise(resolve => {
    commit('DEL_ALL_CACHED_VIEWS')
    resolve([...state.cachedViews])
  })
}

export const updateVisitedView = ({ commit }, view) => {
  commit('UPDATE_VISITED_VIEW', view)
}
