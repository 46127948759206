<template>
  <el-row class="wrapper" align="middle" type="flex">

    <el-row class="header__history" type="flex">
      <tags-view class="page-tagsview" ref="tagsview" v-show="tagsView"></tags-view>
      <!-- <change-password class="header__action"></change-password>
      <el-button class="header__action" @click="exit" type="text">{{$t('route.exit')}}</el-button> -->
      <el-popover v-if="false" width="150" trigger="hover" placement="bottom-end" :visible-arrow="false" popper-class="header__popover">
        <span class="header__name" slot="reference">{{loginInfo.HoldName}}<i class="el-icon-arrow-down"></i></span>
        <div class="header__popoverContent">
          <div class="header__popoverContentTop">{{loginInfo.HoldName}}</div>
          <div class="header__popoverContentMiddle">
            <ul>
              <li @click="goToInfo">个人资料</li>
              <li><change-password></change-password></li>
              <li @click="exit">退出</li>
            </ul>
          </div>
        </div>
      </el-popover>
    </el-row>

    <!-- <span class="header__title" @click="reload">{{$t('system')}}</span>
    <img class="header__logo" src="../../assets/logo/365.png"/> -->
  </el-row>
</template>

<script>
import { removeToken } from '@/common/auth'
import { setAutoLogin, setUserRole } from '@/common/cache'
import ChangePassword from '@/views/common/change-password'
import TagsView from './tags-view'
import { mapGetters } from 'vuex'
export default {
  props: {
    tagsView: {
      type: Boolean,
      default: true
    }
  },
  components: {
    ChangePassword,
    TagsView
  },
  computed: {
    ...mapGetters([
      'loginInfo'
    ])
  },
  methods: {
    exit() {
      setAutoLogin({
        u: '',
        p: '',
        isAuto: false,
        isAgree:true
      })
      removeToken()
      setUserRole()
      this.$refs.tagsview.closeAllTags()
      this.$router.push('/login')
    },
    reload() {
      window.location.reload()
    },
    goToInfo() {
      this.$router.push(`/manage/hold/detail?id=${this.loginInfo.HoldID}`)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/variables-simple.scss";

.wrapper {

  height: $page-header-height;
  position: relative;
  display: flex;
  .header__logo {
    margin: 0 14px;
    width: 30px;
    height: 30px;
    user-select: none;
    flex-shrink: 0;
  }
  .header__title {
    padding: 0 10px;
    color: $--color-text-primary;
    user-select: none;
    cursor: pointer;
    flex-shrink: 0;
  }
  .header__history {
    // position: absolute;
    top: 0;
    flex-grow: 1;
    width: 0;
    // right: $page-aside-width;
    //width: calc(100% - 235px);
    height: 100%;
    .page-tagsview {
      flex-grow: 1;
      width: 0;
    }
    .header__action {
      margin-right: 12px;
      display: flex;
      align-items: center;
      color: $--color-text-secondary;
      &:hover {
        color: $--color-primary;
      }
    }
    .header__name {
      padding: 0 12px;
      color: $--color-text-secondary;
      height: $page-header-height;
      line-height: $page-header-height;
      font-size: 13px;
      user-select: none;
      i {
        font-weight: 600;
        margin-left: 12px;
      }
    }
  }

}
</style>

<style lang="scss">
@import "../../styles/variables-simple.scss";
.header__popover {
  user-select: none;
  overflow: hidden;
  border: 0;
  font-size: 12px;

  .header__popoverContent {
    margin: -12px;
    &Top {
      //background-image: linear-gradient(135deg, $--color-primary 0%, rgba($--color-primary, .5) 100%);
      background-color: $--color-primary;
      color: #FFF;
      padding: 16px 16px;
    }
    &Middle {
      margin-top: 8px;
      ul {
        list-style-type: none;
        li {
          height: 32px;
          line-height: 32px;
          padding: 0 16px;
          cursor: pointer;
          &:hover {
            background-color: $page-background-color;
          }
          .change-password {
            padding-left: 0 !important;
            .el-button {
              width: 100%;
              text-align: left;
            }
            span {
              color: $--color-text-regular !important;
            }
          }
        }
      }
    }
  }
}

</style>

