<template>
  <span :title="title" ref="target">
    <template v-if="indexOfKey===-1">
      {{text}}
    </template>
    <template v-else>
      {{part1}}<span class="hightlight-key" v-if="indexOfKey!=-1">{{partKey}}</span>{{part2}}
    </template>
  </span>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: ''
    },
    lightKey: {
      type: String,
      default: ''
    }
  },
  computed: {
    indexOfKey() {
      return this.text.toLowerCase().indexOf(this.lightKey.toLowerCase())
    },
    partKey() {
      return this.text.substring(this.indexOfKey, this.indexOfKey + this.lightKey.length)
    },
    part1() {
      return this.text.substring(0, this.indexOfKey)
    },
    part2() {
      return this.text.substring(this.indexOfKey + this.lightKey.length)
    },
    title() {
      if (!this.$refs.target) {
        return ''
      }

      const containerWidth = this.$refs.target.innerWidth
      const scrollWidth = this.$refs.target.scrollWidth
      if (scrollWidth > containerWidth) {
        return this.text
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/variables-simple.scss";
.hightlight-key {
  color: $--color-primary;
}
</style>
