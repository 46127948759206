import request from '@/common/request'
import {
  commonParams
} from '@/api/config'
import {
  getToken
} from '@/common/auth'

export function Query(pageIndex, pageSize, sortKey, sortDirect, filters, HoldID, hasChild) {
  const loginKey = getToken()
  return request({
    url: './query/QueryDevice',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      pageIndex,
      pageSize,
      sortKey,
      sortDirect,
      filters,
      HoldID,
      hasChild
    })
  })
}

// 车辆列表-设备模式 - -2021.04.22
/**
 * 显示/隐藏应用组
 * @param  pageIndex 页码
 * @param pageSize  每页显示数量
 * @param sortKey  关键字
 * @param pageSize  每页显示数量
 * @param sortDirect   排序：   ASC（顺序）   DESC（倒序）
 * @param filters  查询条件
 * @param HoldID   用户ID
 * @param hasChild   是否包含下级  bool
 * @returns {wx.RequestTask | never}
 */

export function QueryVehicleDevice(pageIndex, pageSize, sortKey, sortDirect, filters, HoldID, hasChild) {
  const loginKey = getToken()
  return request({
    url: './query/QueryVehicleDevice',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      pageIndex,
      pageSize,
      sortKey,
      sortDirect,
      filters,
      HoldID,
      hasChild
    })
  })
}

// 人员列表-设备模式 - -2021.04.22
/**
 * 显示/隐藏应用组
 * @param  pageIndex 页码
 * @param pageSize  每页显示数量
 * @param sortKey  关键字
 * @param pageSize  每页显示数量
 * @param sortDirect   排序：   ASC（顺序）   DESC（倒序）
 * @param filters  查询条件
 * @param HoldID   用户ID
 * @param hasChild   是否包含下级  bool
 * @returns {wx.RequestTask | never}
 */

export function QueryPersonDevice(pageIndex, pageSize, sortKey, sortDirect, filters, HoldID, hasChild) {
  const loginKey = getToken()
  return request({
    url: './query/QueryPersonDevice',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      pageIndex,
      pageSize,
      sortKey,
      sortDirect,
      filters,
      HoldID,
      hasChild
    })
  })
}


// 物品列表-设备模式 - -2021.04.22
/**
 * 显示/隐藏应用组
 * @param  pageIndex 页码
 * @param pageSize  每页显示数量
 * @param sortKey  关键字
 * @param pageSize  每页显示数量
 * @param sortDirect   排序：   ASC（顺序）   DESC（倒序）
 * @param filters  查询条件
 * @param HoldID   用户ID
 * @param hasChild   是否包含下级  bool
 * @returns {wx.RequestTask | never}
 */
export function QueryArticleDevice(pageIndex, pageSize, sortKey, sortDirect, filters, HoldID, hasChild) {
  const loginKey = getToken()
  return request({
    url: './query/QueryArticleDevice',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      pageIndex,
      pageSize,
      sortKey,
      sortDirect,
      filters,
      HoldID,
      hasChild
    })
  })
}


export function QueryUnBind(pageIndex, pageSize, sortKey, sortDirect, filters, HoldID, hasChild, containBinded) {
  const loginKey = getToken()
  return request({
    url: './query/QueryDeviceUnBind',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      pageIndex,
      pageSize,
      sortKey,
      sortDirect,
      filters,
      HoldID,
      hasChild,
      containBinded
    })
  })
}

export function Get_zd(ObjectID, holdId = null) { //新疆准东定制接口
  const loginKey = getToken()
  return request({
    url: './query/ZD_QueryVehicleInfo',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      ObjectID,
      holdId
    })
  })
}

export function Get(ObjectID, holdId = null) {
  const loginKey = getToken()
  return request({
    url: './query/GS_QueryDeviceInfo',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      ObjectID,
      holdId
    })
  })
}

// 查看人员信息--人员-设备模式 - -2021.04.22
/**
 * @param ObjectID //设备ID
 * @param holdId //用户ID
 * @returns {wx.RequestTask | never}
 */

export function GetPersonInfo(ObjectID, holdId = null) {
  const loginKey = getToken()
  return request({
    url: './query/GS_QueryPersonInfo',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      ObjectID,
      holdId
    })
  })
}

// 查看物品信息--物品-设备模式 - -2021.04.22
/**
 * @param ObjectID //设备ID
 * @param holdId //用户ID
 * @returns {wx.RequestTask | never}
 */

export function GetArticleInfo(ObjectID, holdId = null) {
  const loginKey = getToken()
  return request({
    url: './query/QueryArticleInfo',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      ObjectID,
      holdId
    })
  })
}


export function Add(newItem) {
  const loginKey = getToken()
  return request({
    url: './add/AddDevice',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      newItem
    })
  })
}
// 新增设备与人员绑定--人员列表-设备模式 - -2021.04.22
/**
 * @param newPersonItem   
 * @returns {wx.RequestTask | never}
 */


export function AddPersonWithDevice(newPersonItem, newItem, savePerson, videoCircuit,ContestantNo,EIName) {
  const loginKey = getToken()
  return request({
    url: './add/AddPersonWithDevice',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      newPersonItem,
      newItem,
      savePerson,
      videoCircuit,
      ContestantNo,
      EIName
    })
  })
}


// 新增设备与物品绑定--物品列表-设备模式 - -2021.04.22
/**
 * @param newArtItem   
 * @returns {wx.RequestTask | never}
 */


 export function AddArticleWithDevice(newArtItem, newItem, saveArt, videoCircuit) {
  const loginKey = getToken()
  return request({
    url: './add/AddArticleWithDevice',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      newArtItem,
      newItem,
      saveArt,
      videoCircuit
    })
  })
}



export function AddWidthVehicle(newVehicleItem, newPeopleItem, devices, newItem, saveVehicle, videoCircuit) {
  const loginKey = getToken()
  return request({
    url: './add/AddVehicleComplexWithDevice',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      newVehicleItem,
      newPeopleItem,
      devices,
      newItem,
      saveVehicle,
      videoCircuit
    })
  })
}

export function ZD_AddVehicleComplexWithDevice(newVehicleItem, newPeopleItem, devices, newItem, saveVehicle, videoCircuit,newZD_Item) {
  const loginKey = getToken()
  return request({
    url: './add/ZD_AddVehicleComplexWithDevice',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      newVehicleItem,
      newPeopleItem,
      devices,
      newItem,
      saveVehicle,
      videoCircuit,
      newZD_Item
    })
  })
}



export function Delete(ObjectIDs) {
  const loginKey = getToken()
  return request({
    url: './delete/DeleteDevice',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      ObjectIDs
    })
  })
}

export function Bind(ObjectIDs, VehicleID) {
  const loginKey = getToken()
  return request({
    url: './delete/BindDevice',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      ObjectIDs,
      VehicleID
    })
  })
}

export function UnBind(ObjectIDs) {
  const loginKey = getToken()
  return request({
    url: './delete/UnBindDevice',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      ObjectIDs
    })
  })
}

export function Modify(newItem) {
  const loginKey = getToken()
  return request({
    url: './modify/ModifyDevice',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      newItem
    })
  })
}


export function ModifyDeviceWithVehicle(newVehicleItem, newPeopleItem, newItem, saveVehicle, videoCircuit) {
  const loginKey = getToken()
  return request({
    url: './modify/ModifyDeviceWithVehicle',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      newVehicleItem,
      newPeopleItem,
      newItem,
      saveVehicle,
      videoCircuit
    })
  })
}

export function ZD_ModifyDeviceWithVehicle(newVehicleItem, newPeopleItem, newItem, saveVehicle, videoCircuit,newZD_Item) {
  const loginKey = getToken()
  return request({
    url: './modify/ZD_ModifyDeviceWithVehicle',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      newVehicleItem,
      newPeopleItem,
      newItem,
      saveVehicle,
      videoCircuit,
      newZD_Item
    })
  })
}

// 修改人员信息--人员列表-设备模式 - -2021.04.22
/**
 * @param newPersonItem   
 * @returns {wx.RequestTask | never}
 */


 export function ModifyDeviceWithPerson(newPersonItem, newItem, savePerson, videoCircuit,ContestantNo,EIName) {
  const loginKey = getToken()
  return request({
    url: './modify/ModifyDeviceWithPerson',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      newPersonItem,
      newItem,
      savePerson,
      videoCircuit,
      ContestantNo,
      EIName
    })
  })
}

// 修改物品信息--物品列表-设备模式 - -2021.04.22
/**
 * @param newArtItem   
 * @returns {wx.RequestTask | never}
 */
 export function ModifyDeviceWithArticle(newArtItem, newItem, saveArt, videoCircuit) {
  const loginKey = getToken()
  return request({
    url: './modify/ModifyDeviceWithArticle',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      newArtItem,
      newItem,
      saveArt,
      videoCircuit
    })
  })
}



export function QueryDeviceInfo(ObjectID, holdId = null) {
  const loginKey = getToken()
  return request({
    url: './query/GS_QueryDeviceInfo',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      ObjectID,
      holdId
    })
  })
}

export function AddInstallInfo({
  ObjectID,
  InstallTime,
  InstallPeopleID,
  InstallPosition,
  PhotoName,
  Remark = ''
}) {
  const loginKey = getToken()
  return request({
    url: './add/AddInstallInfo',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      ObjectID,
      InstallTime,
      InstallPeopleID,
      InstallPosition,
      PhotoName,
      Remark
    })
  })
}

export function RemoveDevice(ObjectID) {
  const loginKey = getToken()
  return request({
    url: './add/RemoveDevice',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      ObjectID
    })
  })
}

export function BatchChangeParentHoldForDevice(ObjectIDs, newParentHoldID) {
  const loginKey = getToken()
  return request({
    url: './modify/BatchChangeParentHoldForDevice',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      ObjectIDs,
      newParentHoldID
    })
  })
}




export function BatchChangeParentHoldForDeviceId(DeviceId, newParentHoldID) {
  const loginKey = getToken()
  return request({
    url: './modify/BatchChangeParentHoldForDeviceId',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      DeviceId,
      newParentHoldID
    })
  })
}

export function BatchChangeParentHoldAndServiceDateForDevice(ObjectIDs, newParentHoldID, serviceDate) {
  const loginKey = getToken()
  return request({
    url: './modify/BatchChangeParentHoldAndServiceDateForDevice',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      ObjectIDs,
      newParentHoldID,
      serviceDate
    })
  })
}

export function Export(filters, HoldID, hasChild) {
  const loginKey = getToken()
  return request({
    url: './query/ExportDevice',
    method: 'post',
    responseType: 'blob',
    timeout: 240000,
    data: Object.assign({}, commonParams, {
      loginKey,
      filters,
      HoldID,
      hasChild
    })
  })
}

export function kycExport(filters, HoldID, hasChild) {
  const loginKey = getToken()
  return request({
    url: './query/KYC_ExportDevice',
    method: 'post',
    responseType: 'blob',
    timeout: 240000,
    data: Object.assign({}, commonParams, {
      loginKey,
      filters,
      HoldID,
      hasChild
    })
  })
}


// 导出表格--人员列表-设备模式 - -2021.04.22
/**
 * @param filters //查询条件
 * @param holdId //用户ID
 * @param hasChild //是否包含下级
 * @returns {wx.RequestTask | never}
 */
export function ExportPersonDevice_af(filters, HoldID, hasChild) {
  const loginKey = getToken()
  return request({
    url: './query/ExportPersonDevice_af',
    method: 'post',
    responseType: 'blob',
    timeout: 240000,
    data: Object.assign({}, commonParams, {
      loginKey,
      filters,
      HoldID,
      hasChild
    })
  })
}

// 导出表格--物品列表-设备模式 - -2021.04.22
/**
 * @param filters //查询条件
 * @param holdId //用户ID
 * @param hasChild //是否包含下级
 * @returns {wx.RequestTask | never}
 */

export function ExportActicleDevice_af(filters, HoldID, hasChild) {
  const loginKey = getToken()
  return request({
    url: './query/ExportActicleDevice_af',
    method: 'post',
    responseType: 'blob',
    timeout: 240000,
    data: Object.assign({}, commonParams, {
      loginKey,
      filters,
      HoldID,
      hasChild
    })
  })
}

export function ExportDevice_af(filters, HoldID, hasChild) {
  const loginKey = getToken()
  return request({
    url: './query/ExportDevice_af',
    method: 'post',
    responseType: 'blob',
    timeout: 240000,
    data: Object.assign({}, commonParams, {
      loginKey,
      filters,
      HoldID,
      hasChild
    })
  })
}

export function Import(fileName, HoldID) {
  const loginKey = getToken()
  return request({
    url: './add/ImportDeviceFromExcel',
    method: 'post',
    timeout: 0,
    data: Object.assign({}, commonParams, {
      loginKey,
      fileName,
      HoldID
    })
  })
}

export function DownLoadImportDeviceDetail(fileName) {
  const loginKey = getToken()
  return request({
    url: './add/DownLoadImportDeviceDetail',
    method: 'post',
    responseType: 'blob',
    data: Object.assign({}, commonParams, {
      loginKey,
      fileName
    })
  })
}

export function ImportDeviceBeforeStart(fileName, HoldID) {
  const loginKey = getToken()
  return request({
    url: './add/ImportDeviceBeforeStart',
    method: 'post',
    timeout: 60000,
    data: Object.assign({}, commonParams, {
      loginKey,
      fileName,
      HoldID
    })
  })
}

export function ImprortDevicePosition() {
  const loginKey = getToken()
  return request({
    url: './add/ImprortDevicePosition',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey
    })
  })
}

export function BatchModifyVehicleName(fileName) {
  const loginKey = getToken()
  return request({
    url: './add/Service1.asmx/BatchModifyVehicleName',
    method: 'post',
    timeout: 60000,
    data: Object.assign({}, commonParams, {
      loginKey,
      fileName
    })
  })
}

export function ModifyVehicleImage(objectId, blobData) {
  const loginKey = getToken()
  let formData = new FormData()
  formData.append('loginKey', loginKey)
  formData.append('objectId', objectId)
  formData.append('file', blobData, 'file.jpg')
  return request({
    url: './modify/ModifyVehicleImage',
    method: 'post',
    // headers: { 'Content-Type': 'multipart/from-data' },
    data: formData
  })
}

export function QueryDeviceIdList(filters, HoldID, hasChild) {
  const loginKey = getToken()
  return request({
    url: './query/QueryDeviceIdList',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      filters,
      HoldID,
      hasChild
    })
  })
}

export function ImportDeviceFromExcelWithVIP(fileName, HoldID, Months, option, TypeID) {
  const loginKey = getToken()
  return request({
    url: './add/ImportDeviceFromExcelWithVIP',
    method: 'post',
    timeout: 0,
    data: Object.assign({}, commonParams, {
      loginKey,
      fileName,
      HoldID,
      Months,
      option,
      TypeID
    })
  })
}

export function ImportDeviceSIM(fileName, HoldID) {
  const loginKey = getToken()
  return request({
    url: './add/ImportDeviceSIM',
    method: 'post',
    timeout: 60000,
    data: Object.assign({}, commonParams, {
      loginKey,
      fileName,
      HoldID
    })
  })
}

export function QueryDeviceWxInfo(ObjectID) {
  const loginKey = getToken()
  return request({
    url: './query/QueryDeviceWxInfo',
    method: 'post',
    timeout: 60000,
    data: Object.assign({}, commonParams, {
      loginKey,
      ObjectID
    })
  })
}

export function QueryDistrict() {
  const loginKey = getToken()
  return request({
    url: './query/QueryDistrict',
    method: 'post',
    data: Object.assign({}, commonParams, {})
  })
}
