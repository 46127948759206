<template>
  <div class="dialog-setting">
    <el-form class="fresh" label-position="left" label-width="120px">
      <el-row>
        <el-col :span="24">

          <el-form-item :label="$t('vehicleDetail.devicePlate')">
            <div class="dialog-map-exp">
              <img class="dialog-map-image" src="../../assets/images/mapback.png" />
              <div class="dialog-map-icon">
                <img class="dialog-map-expcar" src="../../assets/car-type/小轿车.svg"/>
                <label>{{vehicleLabel}}</label>
              </div>
            </div>
            <el-checkbox-group v-model="checkList" :min="1">
              <el-checkbox label="PeopleName">{{$t('vehicleDetail.peopleName')}}</el-checkbox>
              <el-checkbox label="VehicleName">{{$t('vehicleDetail.devicePlate')}}</el-checkbox>
              <el-checkbox label="SIM2">{{$t('deviceDetail.deviceID')}}</el-checkbox>
              <el-checkbox label="SIM">{{$t('deviceDetail.sim')}}</el-checkbox>
              <el-checkbox label="VIN">{{$t('vehicleDetail.vehicleId')}}</el-checkbox>
              <el-checkbox label="TrackerTypeName">{{$t('deviceManage.TrackerTypeName')}}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item :label="$t('dialogSetting.tableData')">
            <el-checkbox-group v-model="checkList2" :min="1">
              <p><el-checkbox label="PeopleName">{{$t('vehicleDetail.peopleName')}}</el-checkbox></p>
              <p><el-checkbox label="VehicleName">{{$t('vehicleDetail.devicePlate')}}</el-checkbox></p>
              <p><el-checkbox label="VIN">{{$t('vehicleDetail.vehicleId')}}</el-checkbox></p>
              <p><el-checkbox label="SIM2">{{$t('deviceManage.deviceID')}}</el-checkbox></p>
              <p><el-checkbox label="TrackerTypeName">{{$t('deviceManage.TrackerTypeName')}}</el-checkbox></p>
              <p><el-checkbox label="Speed">{{$t('deviceDetail.speed')}}</el-checkbox></p>
              <p><el-checkbox label="Miles">{{$t('deviceDetail.miles')}}</el-checkbox></p>
              <p><el-checkbox label="isOnline">{{$t('deviceManage.online')}}</el-checkbox></p>
              <p><el-checkbox label="Acc">Acc</el-checkbox></p>
              <p><el-checkbox label="isAlarm">{{$t('deviceManage.alarm')}}</el-checkbox></p>
              <p><el-checkbox label="Status">{{$t('deviceManage.status')}}</el-checkbox></p>
              <p><el-checkbox label="GPSTime">{{$t('deviceManage.gpsTime')}}</el-checkbox></p>
              <p><el-checkbox label="RcvTime">{{$t('deviceManage.rcvTime')}}</el-checkbox></p>
              <p><el-checkbox label="Address">{{$t('deviceManage.place')}}</el-checkbox></p>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="">
            <p class="hightip">{{$t('dialogSetting.changeTip')}}</p>
            <el-button type="primary" @click="onSave" style="min-width:120px;border-radius:4px;">{{$t('common.save')}}</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import {setCustomSetting,getCustomSetting,getLanguage} from '@/common/cache'
export default {
  data() {
    return {
      splitDot: '-',
      checkList: [],
      checkList2: []
    }
  },
  computed: {
    vehicleLabel() {
      let arr = []
      let demo = this.$t(`dialogSetting.demoData`)
      for (var p in demo) {
        if (this.checkList.includes(p)) {
          arr.push(demo[p])
        }
      }
      if (arr.length == 0) {
        arr = ['--']
      }
      return arr.join(this.splitDot)
    }
  },
  created() {
    let viewItems = getCustomSetting('viewItems')
    let viewColumns = getCustomSetting('viewColumns')
    if (!viewItems) {
      viewItems = ['VehicleName']
    }
    if (!viewColumns) {
      viewColumns = ['VehicleName','Speed','Miles','isOnline','Acc','isAlarm','Status','GPSTime','RcvTime','Address']
    }

    this.checkList = viewItems
    this.checkList2 = viewColumns
  },
  methods: {
    checkChange1(val) {
      setCustomSetting('viewItems', val)
    },
    checkChange2(val) {
      setCustomSetting('viewColumns', val)
    },
    onSave() {
      setCustomSetting('viewItems', this.checkList)
      setCustomSetting('viewColumns', this.checkList2)
      let reloadTips = this.$t('dialogSetting.tips');
      if (confirm(reloadTips)) {
        window.location.reload()
      }
      //   if (confirm("保存成功，是否重新加载页面？")) {
      //     window.location.reload()
      //   }
      
    }
  }
}
</script>

<style lang="scss" scoped>
.el-form ::v-deep {
  max-width: 750px;
  margin: 24px auto;
  .el-form-item__label {
    color: #606266;
  }
}
.dialog-setting {

  .dialog-map-exp {
    display: inline-block;
    position: relative;
    min-width: 400px;
    min-height: 250px;
    .dialog-map-image {
      height: 250px;
      float: left;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, .15);
    }
    .dialog-map-icon {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      display: flex;
      justify-content: center;
      align-items: center;

      label {
        margin-left: 8px;
        font-size: 12px;
        background-color: #FFF;
        border-color:#1E88E5;
        padding: 4px 4px;
        opacity: 0.8;
        border-radius: 3px;
        background-color: rgb(25, 118, 210);
        color: #FFF;
        line-height: 1;
      }
    }
    .dialog-map-expcar {
      height: 36px;
    }
  }
}
</style>
