<template>
  <button
    ref="button"
    class="i-dropdown"
    :class="{active: onActive, hover: onHover}"
    @click="click"
    @mouseover.self="onHover = true"
    @mouseout.self="onHover = false"
    @mouseover.exact="over"
    @mouseout.exact="out"

    >
    <div slot="reference" class="mask" @mouseover.self="onHover = true" @mouseout.self="onHover = false"></div>
    <slot name="active">{{showText ? showText : text}}</slot>
    <el-popover trigger="manual" placement="bottom" v-model="onActive" popper-class="el-popper--isnp el-popper--isdrop" :visible-arrow="false" transition="idropdown-animation">

      <p slot="reference" class="i-dropdown-reference"></p>
      <div class="i-dropdown__list" >
        <slot></slot>
      </div>
    </el-popover>
  </button>
</template>

<script>
export default {
  props: {
    value: {
      default: null
    },
    trigger: {
      type: String,
      default: 'hover'
    },
    hideOnClick: {
      type: Boolean,
      default: true
    },
    showText: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      onActive: false,
      onHover: false,
      internal_value: this.value,
      x: 0,
      y: 0,
      rect: null
    }
  },
  computed: {
    isHover() {
      return this.trigger === 'hover'
    },
    isClick() {
      return this.trigger === 'click'
    },
    text() {
      if (!this.internal_value) {
        return 'è¯·é€‰æ‹©'
      }

      const options = this.$children.map(k => {
        return {...k.$props}
      })

      const index = options.findIndex(k => k.value == this.internal_value)
      return index != -1 ? options[index].label : this.internal_value
    },
    optionStyle() {
      if (this.rect && ((this.y + 200) > window.innerHeight)) {
        return {
          position: 'fixed',
          left: this.x + 'px',
          top: this.y - this.rect.height + 'px',
          transform: 'translateY(-100%)'
        }
      } else {
        return {
          position: 'fixed',
          left: this.x + 'px',
          top: this.y + 'px'
        }
      }

    }
  },
  watch: {
    onActive(value) {
       setTimeout(() => {
        if (value && this.isClick) {
          window.addEventListener('click', this.closeMenu)
        } else {
          window.removeEventListener('click', this.closeMenu)
        }
       }, 300);
    }
  },
  methods: {
    over() {
      if (!this.isHover || this.banOver) {
        return
      }
      this.onActive = this.isHover ? true : this.onActive
      this.computePosition()
    },
    out() {
      this.onActive = this.isHover ? false : this.onActive
    },
    click() {
      if (!this.isClick) {
        return
      }
      this.onActive = this.isClick ? !this.onActive : this.onActive
      this.computePosition()
    },
    focusout() {
      setTimeout(() => {
        this.onHover = this.onActive = false
      }, 100)
    },
    computePosition() {
      const rect = this.$refs.button.getBoundingClientRect()
      this.x = rect.left
      this.y = rect.top + rect.height
      this.rect = rect
    },
    select(val) {
      this.internal_value = val.value
      this.$emit('input', this.internal_value)

      this.onActive = this.onHover = this.hideOnClick ? false : this.onActive
      this.banOver = true
      setTimeout(() => {
        this.banOver = false
      }, 200)
    },
    logSomething() {
      console.log('focus')
    },
    closeMenu() {
      this.onHover = this.onActive = false
    }
  },
  beforeDestroy() {
    window.removeEventListener('click', this.closeMenu)
  }
}
</script>

<style lang="scss" scoped>

$color-active: #B0BEC5;
$color-hover: #CFD8DC;
$shadow-focus: 0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12);
$padding-vertical: 8px;
$padding-horizontal: 12px;

.i-dropdown {
  padding: $padding-vertical $padding-horizontal;
  transition: all 0.2s ease-in-out;
  //padding: 0.5em 0.75em;
  padding: 4px 0.75em;
  outline: none;
  border-radius: 2px;
  position: relative;
  -webkit-appearance: none !important;
  border: none;
  background-color: transparent;
  white-space: nowrap;
  .mask {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    cursor: pointer;
  }

  &.hover {
    //background-color: $color-hover;
    .i-link {
      text-decoration: underline;
    }
  }

  // &:active {
  //   background-color: $color-active;
  // }

  // &:focus {

  // }

  &__list {
    box-shadow: $shadow-focus;
    padding: 4px 0;
    border-radius: 4px;
  }
}
</style>

