<template>
  <svg class="icon" aria-hidden="true" @click="e => $emit('click', e)"><use :xlink:href="`#${name}`"></use></svg>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: ''
    }
  }
}
</script>

