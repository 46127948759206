<template>
  <div class="change-password">
    <!-- <el-button type="text" @click="dialogVisible=true">修改密码</el-button> -->
    <el-dialog :title="$t('userCenter.changeMobile')" :visible.sync="dialogVisible" :close-on-click-modal="false" custom-class="v-dialog v-dialog--xs v-dialog--footer v-dialog--lgpadding" append-to-body @close="beforeClose">
      <el-form ref="refForm" :model="form" status-icon :rules="rules" label-width="100px">
        <el-form-item label="验证手机号" v-if="form.oldMobile" prop="oldCode">
          <el-row>
            <el-col :span="24">{{form.oldMobile}}</el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-input type="text" maxlength="20" v-model.trim="form.oldCode" auto-complete="off"></el-input>
            </el-col>
            <el-col :span="10" :offset="2">
              <el-button type="text" :disabled="!!smsTimeOld" @click="onSendOldCode">{{ `${smsTimeOld > 0 ? $t('register.tryAgain')+'('+smsTimeOld+')' : $t('register.sendButton')}`}}</el-button>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item :label="$t('userCenter.newMobile')" prop="newMobile">
            <el-input type="text" maxlength="11" v-model.trim="form.newMobile" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="newCode">
          <el-row>
            <el-col :span="12"><el-input type="text" maxlength="20" v-model="form.newCode" auto-complete="off"></el-input></el-col>
            <el-col :span="10" :offset="2"><el-button type="text" :disabled="!!smsTime" @click="onSendNewCode">{{ `${smsTime > 0 ? $t('register.tryAgain')+'('+smsTime+')' : $t('register.sendButton')}`}}</el-button></el-col>
          </el-row>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="customStyle" type="text" size="medium" @click="dialogVisible = false">{{$t('common.cancel')}}</el-button>
        <el-button class="customStyle" plain type="primary" size="medium" @click="change" :loading="btnLoading">{{$t('common.save')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapMutations,mapGetters } from 'vuex'
import { SendCodeToOldMobile, SendCodeToNewMobile, ChangeUserMobile, ChangeUserMobileAdmin } from '@/api/user'
export default {
  data() {
    let validatePhone = (rule, value, callback) =>{
        let mobile = /^1(3|4|5|6|7|8|9)\d{9}$/; //最新16手机正则
        let tel = /^(0[0-9]{2,3}\-)([2-9][0-9]{4,7})+(\-[0-9]{1,4})?$/; //座机
        if (value == '' || value == undefined || value == null) {
            callback();
        } else {
            if ((!mobile.test(value)) && value != '') {
                callback(new Error('请填写正确的手机号码！'));
            } else {
                callback();
            }

        }
    }
    return {
      mode: 0,
      btnLoading: false,
      userId: null,
      form: {
        oldMobile: '',
        oldCode: '',
        newMobile: '',
        newCode: ''
      },
      smsTime: 0,
      smsTimeOld: 0,
      dialogVisible: false,
      rules: {
        newMobile: [
          { required: true, message: '请输入手机号', trigger: 'blur'},
          { validator: validatePhone, trigger: ['blur'] }
        ],
        newCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ],
        oldCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    ...mapGetters([
      'loginInfo'
    ])
  },
  created() {

  },
  methods: {
    ...mapMutations({
      'setLoginInfo': 'SET_LOGIN_INFO'
    }),
    okHandleValidate() {

      const promise = new Promise((resolve, reject) => {
        this.$refs.refForm.validate(valid => {
          if (valid) {
            resolve(true)
          } else {
            reject(false)
          }
        })
      })

      return promise
    },
    onSendNewCode() {
      if (!this.form.newMobile) {
        return
      }
      SendCodeToNewMobile(this.form.newMobile).then(() => {
        this.startTimer()
      })
    },
    onSendOldCode() {
      SendCodeToOldMobile().then(() => {
        this.startTimerOld()
      })
    },
    startTimerOld() {
      if (this.smsTimeOld > 0) {
        return
      }

      this.smsTimeOld = 60
      const decorate = () => {
        if (this.smsTimeOld <= 0) {
          return
        }
        this.smsTimeOld--
        setTimeout(decorate, 1000)
      }

      decorate()
    },
    startTimer() {
      if (this.smsTime > 0) {
        return
      }

      this.smsTime = 60
      const decorate = () => {
        if (this.smsTime <= 0) {
          return
        }
        this.smsTime--
        setTimeout(decorate, 1000)
      }

      decorate()
    },
    async change() {
      let valid
      try {
        valid = await this.okHandleValidate()
      } catch (e) {
        valid = false
      }

      console.log(valid)
      if (!valid) {
        return
      }

      this.btnLoading = true
      const fn = this.mode === 0 ? ChangeUserMobile : ChangeUserMobileAdmin
      let codeKey =  this.mode === 0 ? this.form.oldCode :this.userId
      fn(codeKey, this.form.newMobile, this.form.newCode).then(({data}) => {
        if (data.errCode) {
          this.$message(data.errMsg)
        } else {
          this.$emit('update', this.form.newMobile)
          this.$message({ message: this.$t('common.success'), type: 'success' })
          this.dialogVisible = false
        }
      }).finally(() => {
        this.btnLoading = false
      })
    },

    beforeClose() {
      this.form = {
        title: ''
      }
      this.$refs.refForm.resetFields()
    },
    open(oldMobile) {
      this.mode = 0
      this.form.oldMobile = oldMobile
      this.dialogVisible = true
    },
    openByUserID(userId) {
      this.mode = 1
      this.userId = userId
      this.dialogVisible = true
    },
    setTitle(t) {
      const info = Object.assign({}, this.loginInfo, {
        PlatformTitle: t
      })
      this.setLoginInfo(info)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/element-variables.scss";
.change-password {
  // padding-left: 20px !important;
  padding-right: 0 !important;

  & ::v-deep .el-button {
    color: $--color-text-secondary !important;
    &:hover {
      color: $--color-primary !important;
    }
  }
}
</style>
