<template>
  <header style="height: 48px;background:rgba(216,216,216,1);">
    <img src="../../assets/logo/logo.png" />
    <span>GPS监控平台</span>
  </header>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
  header {
    display: flex;
    align-items: center;
    padding: 0 16px;
    img {
      height: 32px;
      margin-right: 12px;
    }
    span {
      font-size: 18px;
      font-family:PingFang-SC-Bold;
      font-weight:bold;
      color:rgba(51,51,51,1);
    }
  }
</style>
