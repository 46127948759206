<template>
  <!-- <main-wrapper :tool="false"> -->

    <div class="v-wrapper tool--none" ref="vWrapper" :class="{'popWindow': popWindow}">
      <div class="v-body">
        <template>
          <div class="vipFeatures" v-if="false">
            <span class="vipFeaturesSummar">{{$t('updateVip.vipFeatures')}}:</span>
            <span><i-icon name="icon-open_icon_successx"></i-icon>{{$t('updateVip.vipFeature90')}}</span>
            <span><i-icon name="icon-open_icon_successx"></i-icon>{{$t('updateVip.vipFeaturePhoto')}}</span>
            <span><i-icon name="icon-open_icon_successx"></i-icon>{{$t('updateVip.vipFeatureGeo')}}</span>
            <span><i-icon name="icon-open_icon_successx"></i-icon>{{$t('updateVip.vipFeatrueOil')}}</span>
            <span v-if="$i18n.locale =='zh'"><i-icon name="icon-open_icon_successx"></i-icon>{{$t('updateVip.vipFeaturePlace')}}</span>
            <span><i-icon name="icon-open_icon_successx"></i-icon>{{$t('updateVip.vipFeatureMore')}}</span>
          </div>

          <div class="search-bars clearfix pdFixed">
            <el-button plain border type="primary" class="obtn" v-ripple @click="quickPayHandler">{{$t('updateVip.oneCLickPay')}}</el-button>
            <el-button plain border type="primary" class="obtn" v-ripple @click="$router.push('/manage/expire2')" v-if="!popWindow">{{$t('updateVip.myOrder')}}</el-button>
            <el-button plain border type="primary" class="obtn" v-ripple @click="beforeOpenUpdateVipAdmin" v-if="!popWindow && loginInfo.allFuns.includes(20142)">{{$t('updateVip.adminVip')}}</el-button>
            <search-group :options="options" @click="click" style="float:right;" @input="({select,value,Op}) => {searchName=select;searchText=value;searchOption=Op}"
              :field="{select: searchName, value: searchText}" multiple-mode multiple-key="D.SIM2"></search-group>

            <span class="iml-1" style="float:right;font-size:14px;line-height:32px;">{{$t('deviceManage.status')}}:
              <el-select v-model="queryKey2" style="width:140px;" size="small">
                <el-option v-for="item in options2" :key="item.label" :value="item.value" :label="item.label"></el-option>
              </el-select>
            </span>
            <el-checkbox v-model="hasChild" class="search-containChild">{{$t('deviceManage.includeChild')}}</el-checkbox>
            <el-checkbox v-model="hideTip" class="search-containChild imr-2" style="margin-left:16px;">{{$t('deviceManage.wrap')}}</el-checkbox>
            <el-checkbox v-model="isShowMore" class="search-containChild">{{$t('deviceManage.displayMore')}}</el-checkbox>
          </div>

          <el-table :data="tableData" class="v-table" border @sort-change="sortChange" @header-dragend="saveHeaderDragWidth" size="mini" ref="multipleTable" v-loading="tableLoading" row-key="ObjectID" :element-loading-text="tableLoadingText"
            @select="selectHandler" @select-all="selectAllHandler" height="calc(100% - 242px)">
            <el-table-column :key="10" type="selection" width="55" :selectable="selectableHandle"></el-table-column>
            <el-table-column :key="20" sortable="custom" prop="SIM2" :label="$t('deviceManage.deviceID')" :width="TW($t('deviceManage.deviceID'), 240)" :show-overflow-tooltip="!hideTip">
              <div style="white-space:nowrap;" slot-scope="{row}">
                <add-task-car @click="addToTaskList(row)" :types="row.TypeID"></add-task-car>
                <img src="../../assets/icon/vip.png" class="vip" v-if="row.isVip"/>
                <device-popover :item-id="row.ObjectID">
                  <span class="i-link" @click.stop="rowClick(row)">{{ row.SIM2|emptyFormat }}</span>
                </device-popover>
              </div>
            </el-table-column>
            <el-table-column :key="30" sortable="custom" prop="VehicleNum" class-name="vehicle-name" :label="$t('deviceManage.deviceName')" :width="TW($t('deviceManage.vehicleName'), 120)" :show-overflow-tooltip="!hideTip">
              <div slot-scope="{row}">
                 <template v-if="row.TypeID===0">
                    <!-- @click.stop="$refs.vehicleDetail.open(row.VehicleID, {})" -->
                    <vehicle-name  v-if="row.VehicleID" :color="row.VehicleNumBackColor" :data-id="row.VehicleID" :title="`${row.VehicleName}`">{{ row.VehicleName }}</vehicle-name>
                    <span v-else class="i-link" @click="bindVehicle(row)"><i class="el-icon-search"></i> {{$t('common.queryAndBind')}}</span>
                </template>
                <template v-else>
                    <span>{{row.VehicleName}}</span>
                </template>
              </div>
            </el-table-column>
            <el-table-column :key="60" sortable="custom" prop="PayEndDate" :label="$t('deviceManage.vip')" :width="TW($t('deviceManage.vip'), 120)">
              <template slot-scope="{row}">
                <p v-if="!row.PayEndDate">--</p>
                <p v-else-if="miment(row.PayEndDate).getTime() <= miment().getTime()">{{$t('deviceManage.optionKey12')}}</p>
                <p v-else-if="miment(row.PayEndDate).getTime() < miment().add(90, 'DD').getTime()" style="color:#f44336;">{{miment(row.PayEndDate).format("YYYY-MM-DD")}}</p>
                <p v-else>{{miment(row.PayEndDate).format("YYYY-MM-DD")}}</p>
              </template>
            </el-table-column>
            <el-table-column :key="65" sortable="custom" prop="PayEndDate" :label="$t('deviceManage.remainDays')" :width="TW($t('deviceManage.remainDays'), 120)">
              <template slot-scope="{row}">
                <p v-if="!row.PayEndDate">--</p>
                <p v-else-if="miment(row.PayEndDate).getTime() <= miment().getTime()">{{$t('deviceManage.optionKey12')}}</p>
                <p v-else>{{getRemainDays(row.PayEndDate)}}{{$t('deviceManage.days')}}</p>
              </template>
            </el-table-column>

            <el-table-column :key="70" sortable="custom" prop="MDTModelName" :label="$t('deviceManage.deviceType')" :width="TW($t('deviceManage.deviceType'), 100)">
              <template slot-scope="{row}">{{$t('dict')[row.TrackerTypeName]}}</template>
            </el-table-column>
            <el-table-column :key="80" sortable="custom" prop="SIM" :label="$t('deviceManage.sim')" :width="TW($t('deviceManage.sim'), 120)"  :show-overflow-tooltip="!hideTip">
              <span slot-scope="{row}" :title="row.ObjectID">{{row.SIM}}</span>
            </el-table-column>
            <!-- <el-table-column :key="90" sortable="custom" prop="Factory" :label="$t('deviceManage.mdtType')" :width="TW($t('deviceManage.mdtType'), 100)" v-if="isShowMore">

            </el-table-column> -->
            <el-table-column :key="100" prop="StatusDes" :label="$t('deviceManage.status')" :width="TW($t('deviceManage.status'), 300)" v-if="isShowMore" :show-overflow-tooltip="!hideTip"></el-table-column>
            <el-table-column :key="110" sortable="custom" prop="isOnline" :label="$t('deviceManage.online')" :width="TW($t('deviceManage.online'), 75)" v-if="isShowMore">
              <template slot-scope="{row}">
                <p class="square" :class="{isOnline: !!row.isOnline}"></p>
                <p class="imgcolumn" v-if="false"><img src="../../assets/icon/在线.gif" alt="在线" :title="$t('deviceManage.keyOnline')"/></p>
                <p class="imgcolumn" v-if="false"><img src="../../assets/icon/离线1.gif" alt="离线" :title="$t('deviceManage.keyOffline')"/></p>
              </template>
            </el-table-column>
            <el-table-column :key="120" :label="$t('deviceManage.acc')" :width="TW($t('deviceManage.acc'), 75)" v-if="isShowMore">
              <template slot-scope="{row}">
                <template v-if="row.TypeID===0">
                   <p class="imgcolumn" :class="row.tagClass">
                        <template v-if="row.tagClass==1">
                          <span class="status" alt="启动,行驶" :title="$t('deviceManage.keyOnRoad')">
                              <i class="s1"></i>
                          </span>
                        </template>
                        <template v-if="row.tagClass==2">
                          <span class="status" alt="熄火" :title="$t('deviceManage.keyNoSpeed')">
                              <i class="s4"></i>
                          </span>
                        </template>
                        <template v-if="row.tagClass==3">
                          <span class="status" alt="启动,怠速" :title="$t('deviceManage.keyDaisu')">
                              <i class="s2"></i>
                          </span>
                        </template>
                          <template v-if="row.tagClass==4">
                          <span class="status" alt="离线" :title="$t('deviceManage.keyOffRef')">
                              <i class="s6"></i>
                          </span>
                        </template>
                        <template v-if="row.tagClass==6">
                          <template v-if="row.Status">
                            <span class="status" alt="启动,参考定位" :title="$t('deviceManage.keyRef')">
                                <i class="s3"></i>
                            </span>
                          </template> 
                           <template v-else>
                             <span class="status" alt="熄火,参考定位" :title="$t('deviceManage.keyNoSpeedRef')">
                              <i class="s5"></i>
                             </span>
                          </template> 
                        </template>                   
                     </p>
                </template> 
                <template v-else>
                   <p class="imgcolumn" :class="row.tagClass">
                        <template v-if="row.tagClass==4">
                          <span class="status" alt="离线" :title="$t('deviceManage.keyOffRef')">
                              <i class="s6"></i>
                          </span>
                        </template>
                        <template v-if="row.tagClass==1">
                          <span class="status" alt="运动" :title="$t('deviceManage.keyMov')">
                              <i class="s1"></i>
                          </span>
                        </template>
                        <template v-if="row.tagClass==3">
                          <span class="status" alt="静止" :title="$t('deviceManage.keyStop')">
                              <i class="s2"></i>
                          </span>
                        </template>
                        <template v-if="row.tagClass==6">
                          <span class="status" alt="参考定位" :title="$t('deviceManage.keyNoGps')">
                              <i class="s2"></i>
                          </span>
                        </template>                   
                     </p>
                </template> 
              </template>
            </el-table-column>
            <el-table-column :key="130" sortable="custom" prop="isAlarm" :label="$t('deviceManage.alarm')" :width="TW($t('deviceManage.alarm'), 75)" v-if="isShowMore">
              <template slot-scope="{row}">
                <p v-if="!!row.isAlarm" class="imgcolumn"><img src="../../assets/icon/报警.gif" alt="报警" :title="$t('deviceManage.keyAlarm')"/></p>
                <p v-else class="imgcolumn"><img src="../../assets/icon/正常.gif" alt="正常" :title="$t('deviceManage.keyNormarl')"/></p>
              </template>
            </el-table-column>
            <el-table-column :key="140" sortable="custom" prop="RcvTime" :label="$t('deviceManage.rcvTime')" :width="TW($t('deviceManage.rcvTime'), 150)" v-if="isShowMore">
              <p slot-scope="{row}">{{row.RcvTime ? miment(row.RcvTime).format() : ''}}</p>
            </el-table-column>
            <el-table-column :key="150" sortable="custom" prop="OfflineDesc" v-if="isShowMore" :label="$t('deviceManage.offlineTime')" :width="TW($t('deviceManage.offlineTime'), 150)" :show-overflow-tooltip="!hideTip"></el-table-column>
            <el-table-column :key="160" :label="$t('deviceManage.place')"  v-if="isShowMore" :width="TW($t('deviceManage.place'), 180)" :show-overflow-tooltip="!hideTip">
              <template slot-scope="{row}">
                <span-address :lon="row.Lon" :lat="row.Lat"></span-address>
              </template>
            </el-table-column>
            <!-- <el-table-column :key="170" prop="FullPathName" :label="$t('deviceManage.holdName')" :width="TW($t('deviceManage.holdName'), 150)">
              <p slot-scope="{row}">{{row.FullPathName}}</p>
            </el-table-column> -->
            <el-table-column :key="180" prop="CreateTime" :label="$t('deviceManage.createTime')" min-width="160px" >
              <p slot-scope="{row}">{{miment(row.CreateTime).format()}}</p>
            </el-table-column>
            <el-table-column :key="190" prop="Remark"  v-if="isShowMore" :label="$t('deviceManage.remark')" :width="TW($t('deviceManage.remark'), 180)" :show-overflow-tooltip="!hideTip"></el-table-column>
            <el-table-column :key="200" label="" :resizable="false" v-if="false" min-width="95px">
              <template slot-scope="{row}">
                <i-drop-down trigger="click">
                  <span slot="active" class="i-link">{{$t('common.operate')}}<i class="el-icon-caret-bottom" style="margin-left:0.5em;"></i></span>
                  <i-drop-down-option :label="$t('common.modify')" value="0" @click="rowClick(row)"></i-drop-down-option>
                  <i-drop-down-option :label="$t('common.migrate')" value="3" @click="rowChange([row])"></i-drop-down-option>
                  <i-drop-down-option :label="$t('deviceManage.updateVip')" value="3" @click="() => singlePay(row)"></i-drop-down-option>
                  <i-drop-down-option :label="$t('common.delete')" value="4" @click="deleteHandle([row])"></i-drop-down-option>
                </i-drop-down>
              </template>
            </el-table-column>
          </el-table>

          <el-pagination
            background
            class="v-pager"
            :page-size="pageSize"
            :page-sizes="pageSizes"
            :pager-count="11"
            :current-page.sync="pageIndex"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageTotalCount"
            @current-change="pageCurrentChange"
            @size-change="pageSizeChange"
            popper-class="custom">
          </el-pagination>

          <vcl-code v-if="false" class="ima-4" :width="400" :height="150"></vcl-code>

          <!-- <transition name="scale-grow" @before-enter="isAnimating=true" @before-leave="isAnimating=true" @after-enter="isAnimating=false" @after-leave="isAnimating=false">
            <router-view @refresh="refreshHandle"></router-view>
          </transition> -->

          <el-dialog width="400px" :title="$t('deviceManage.updateVip')" :visible.sync="dialogSinglePay" custom-class="v-dialog v-dialog--wallet" top="5vh">
            <single-pay v-bind="single" v-if="dialogSinglePay" @close="() => dialogSinglePay = false" @go-wallet="goWallet"></single-pay>
          </el-dialog>

          <el-dialog :title="$t('deviceManage.moverDevices')" custom-class="v-dialog v-dialog--sm v-dialog--mdpadding" :visible.sync="dialogChangeParent">
            <change-parent :datas="changedRow" v-if="dialogChangeParent" :old-hold-info="{id:currentHoldId,name:currentHoldName}" mode="device" @close="dialogChangeParent=false"></change-parent>
          </el-dialog>

          <!--查看选中设别详情-->
          <el-dialog :visible.sync="dialogObjectList" :title="$t('updateVip.selectedList')" custom-class="v-dialog v-dialog--md v-dialog--mdpadding v-dialog--footer" class="v-dialog-commandlist" @closed="dialogDetailListPageIndex=1" append-to-body>
            <el-table :data="checkedObjectListPage" class="v-table__customStyle" row-key="ObjectID" style="width:100%;" size="mini" max-height="528" stripe ref="multiTable">
              <el-table-column type="selection" width="50"></el-table-column>
              <el-table-column :label="$t('updateVip.vehicleName')" prop="VehicleName" width="130" show-overflow-tooltip></el-table-column>
              <el-table-column :label="$t('updateVip.deviceID')" prop="SIM2" width="130" show-overflow-tooltip></el-table-column>
              <el-table-column :label="$t('updateVip.sim')" prop="SIM" width="120" show-overflow-tooltip></el-table-column>
              <el-table-column :label="$t('updateVip.vipEndDate')" prop="PayEndDate" width="115">
                <template slot-scope="{row}">
                  <p v-if="!row.PayEndDate || miment(row.PayEndDate).getTime() <= miment().getTime()">{{$t('updateVip.expired')}}</p>
                  <p v-else-if="miment(row.PayEndDate).getTime() < miment().add(90, 'DD').getTime()" style="color:#f44336;">{{miment(row.PayEndDate).format("YYYY-MM-DD")}}</p>
                  <p v-else>{{miment(row.PayEndDate).format("YYYY-MM-DD")}}</p>
                </template>
              </el-table-column>
              <el-table-column :label="$t('updateVip.trackerType')" width="110">
                <template slot-scope="{row}">
                  <p style="color:#3F51B5;">{{$t('dict')[row.TrackerTypeName]}}</p>
                </template>
              </el-table-column>
              <el-table-column :label="$t('updateVip.online')" width="75">
                <template slot-scope="{row}">
                  <span class="square online" v-if="row.isOnline"></span>
                  <span class="square offline" v-else></span>
                </template>
              </el-table-column>
              <el-table-column :label="$t('updateVip.holdName')" prop="HoldName"  show-overflow-tooltip></el-table-column>
              <el-table-column :label="$t('updateVip.createTime')" prop="CreateTime" width="120">
                <template slot-scope="{row}">
                  {{miment(row.CreateTime).format("YYYY-MM-DD")}}
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              background
              style="text-align:center;"
              class="v-pager imt-1"
              :page-size="dialogDetailListPageSize"
              :pager-count="11"
              :current-page.sync="dialogDetailListPageIndex"
              layout="total, prev, pager, next, jumper"
              :total="checkedObjectList.length"
              popper-class="custom">
            </el-pagination>
            <div slot="footer">
              <el-button @click="removeSelection">{{$t('common.removeChecked')}}</el-button>
              <el-button @click="removeAll">{{$t('common.removeAll')}}</el-button>
              <el-button @click="dialogObjectList=false">{{$t('common.close')}}</el-button>
            </div>
          </el-dialog>

          <!--订单详情页-->
          <el-dialog :visible.sync="dialogDetail" :title="$t('updateVip.updateVip')" width="375px" custom-class="`v-dialog v-dialog--mdpadding "  append-to-body  @closed="dialogDetailClosed">
            <div class="batch-wrapper batch-wrapper--flex" v-loading="payLoading">

              <div class="batch-body wrapper--success" v-if="dialogDetailStep===COMPLETED_ORDER">
                <i-icon name="icon-shengjichenggong"></i-icon>
                <p class="success-tip">{{$t('updateVip.updateSuccess')}}</p>
                <el-button class="bottom" type="success" @click="closeAndGo" size="medium">{{$t('updateVip.viewOrder')}}</el-button>
              </div>
              <transition name="layer">
                <div class="batch-body floatLayer" v-if="dialogDetailStep===IN_ORDER">
                  <h2>{{$t('updateVip.orderTip1')}}：</h2>
                  <p class="orderItem clearfix">
                    <span class="orderTitle">{{$t('updateVip.orderName')}}：</span>
                    <span class="orderText"><img src="../../assets/icon/vip.png"/> {{$t('updateVip.updateVip')}} </span>
                  </p>
                  <p class="orderItem clearfix">
                    <span class="orderTitle">{{$t('updateVip.orderAmount')}}：</span>
                    <span class="orderText"><span class="important">{{form.PayYear}}</span> {{$t('updateVip.year')}}</span>
                  </p>
                  <p class="orderItem clearfix">
                    <span class="orderTitle">{{$t('updateVip.orderVehicle')}}：</span>
                    <span class="orderText">{{$t('updateVip.total')}} <span class="important">{{checkedObjectKeys.length}}</span> {{$t('updateVip.deviceUnit')}}{{$t('updateVip.device')}}</span>
                  </p>
                  <p class="orderItem clearfix">
                    <span class="orderTitle">{{$t('updateVip.orderMethod')}}：</span>
                    <span class="orderText">
                      <i-icon name="icon-weixinzhifu1" v-if="form.PayMethod===2"></i-icon>
                      <i-icon name="icon-zhifubao" v-if="form.PayMethod===3"></i-icon>
                      <img src="../../assets/icon/VPoint.png" class="img" v-if="form.PayMethod===1" alt="">
                      {{payMethodText}}
                    </span>
                  </p>
                  <p class="orderItem clearfix">
                    <span class="orderTitle">{{$t('updateVip.payFee')}}：</span>
                    <span class="orderText"><span class="important">{{payTotal}}</span> {{payMethodSuffix}}</span>
                  </p>
                  <el-row :gutter="12" class="orderButtons">
                    <el-col :span="12">
                      <el-button class="orderPay cancel" size="medium" @click="cancelPay" :title="$t('updateVip.backBUttonText')">{{$t('updateVip.backBUttonText')}}</el-button>
                    </el-col>
                    <el-col :span="12">
                      <el-button class="orderPay" size="medium" @click="pay" :title="$t('updateVip.nextButtonText')">{{$t('updateVip.nextButtonText')}}</el-button>
                    </el-col>
                  </el-row>
                </div>
              </transition>
              <transition name="layer">
                <div class="batch-body floatLayer payImg" v-if="dialogDetailStep===IN_WECHAT_IMAGE">
                  <h2>{{$t('updateVip.orderTip2')}}</h2>
                  <p>
                    <img :src="wxImgUrl"/>
                  </p>
                  <p class="orderItem clearfix">
                    <span class="orderText">{{$t('updateVip.payFee')}}：<span class="important">{{payTotal}}</span> {{payMethodSuffix}}</span>
                  </p>
                  <i-icon name="icon-weixinzhifu" class="weixinzhifu"></i-icon>
                  <p class="cancelText" @click="cancelPay">{{$t('common.back')}}</p>
                </div>
              </transition>
            </div>
          </el-dialog>

          <detail-device ref="detail" @refresh="refreshHandle" :append-to-body="true"></detail-device>

          <import-tool ref="importTool" :download-url="$t('batchImport.fileName2')"></import-tool>
          <import-detail :items="excelItems" ref="importDetail" :download-method="excelDownLoadMethod"></import-detail>

          <el-dialog :title="$t('updateVip.wallet')" append-to-body width="400px" :visible.sync="dialogInfoVisible" custom-class="v-dialog v-dialog--wallet">
            <wallet-info v-if="dialogInfoVisible" @close="() => dialogInfoVisible = false" @send="send"></wallet-info>
          </el-dialog>

          <bind-vehicle ref="bindVehicle" @refresh="queryHandle"></bind-vehicle>

          <el-dialog :title="$t('updateVip.sendVPoint')" append-to-body :visible.sync="dialogSendVisible" custom-class="v-dialog v-dialog--sm v-dialog--mdpadding">
            <wallet-send v-if="dialogSendVisible" @close="() => dialogSendVisible = false"></wallet-send>
          </el-dialog>

          <detail-vehicle ref="vehicleDetail" @refresh="queryHandle"></detail-vehicle>
        </template>
      </div>
      <div class="batch-expire-pay">
        <div class="fixedBottom">
          <div class="fixedBottom-section sec1">
            <p class="fixedBottom-sectionLabel">{{$t('updateVip.payVehicle')}}:</p>
            <div class="fixedBottom-sectionAction hover" @click="openDialog"><i class="el-icon-loading" v-if="countLoading"></i><span>{{checkedObjectKeys.length}} {{$t('updateVip.deviceUnit')}}</span></div>
          </div>
          <div class="fixedBottom-section sec2">
            <p class="fixedBottom-sectionLabel">{{$t('updateVip.payTime')}}:<span v-if="$i18n.locale=='zh'" style="font-size:12px;color:#b79b60;float:right;">{{$t('dict')['有线']}}:￥{{oneYearPrice}}{{$t('updateVip.payUnit')}}/{{$t('updateVip.year')}} {{$t('dict')['无线']}}:￥{{oneYearPriceWire}}{{$t('updateVip.payUnit')}}/{{$t('updateVip.year')}}</span></p>
            <div class="fixedBottom-sectionAction noBk">
              <span class="fixedBottom-sectionActionText" v-for="item in payOptions" :key="item.value" @click="changePayYear(item)" :class="{'active': item.value == form.PayYear}">
                {{item.label}} {{$t('updateVip.year')}} <span class="fixedBottom-sectionActionTextPrice" ></span>
              </span>
            </div>
          </div>
          <div class="fixedBottom-section sec3">
            <p class="fixedBottom-sectionLabel">{{$t('updateVip.payFee')}}:</p>
            <div class="fixedBottom-sectionAction price" v-if="!feeLoading"> {{payTotal}} <span class="price-unit">{{$t('updateVip.payUnit')}}</span></div>
            <div class="fixedBottom-sectionAction price" v-else><i class="el-icon-loading"></i></div>
          </div>
          <div class="fixedBottom-section sec4">
            <p class="fixedBottom-sectionLabel">{{$t('updateVip.payMethod')}}:</p>
            <div class="fixedBottom-sectionAction noBk">
              <div class="fixedBottom-sectionActionPay" @click="payCheckOrder(2)">
                <i-icon name="icon-weixinzhifu1"></i-icon>{{$t('updateVip.wechat')}}
              </div>
              <div class="fixedBottom-sectionActionPay" @click="payCheckOrder(3)" v-if="!btnAlipayLoading">
                <i-icon name="icon-zhifubao"></i-icon>{{$t('updateVip.alipay')}}
              </div>
              <div class="fixedBottom-sectionActionPay" @click="payCheckOrder(3)" v-else>
                <i-icon name="icon-zhifubao"></i-icon>{{$t('updateVip.alipay')}}
              </div>
              <div class="fixedBottom-sectionActionPay" @click="payCheckOrder(1)" >
                <img src="../../assets/icon/VPoint.png" alt="">{{$t('updateVip.vpoint')}}
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="features">
          <span>{{$t('updateVip.vipFeatures')}}:<i-icon name="icon-_icon_PC-1" style="color:#FA8C16"></i-icon>{{$t('updateVip.vipFeature90')}}</span>
          <span><i-icon name="icon-_icon-9" style="color:#13C2C2"></i-icon>{{$t('updateVip.vipFeaturePhoto')}}</span>
          <span><i-icon name="icon-_icon-10" style="color:#1890FF"></i-icon>{{$t('updateVip.vipFeatureGeo')}}</span>
          <span><i-icon name="icon-baobiao1" style="color:#7CB305"></i-icon>{{$t('updateVip.vipFeatrueOil')}}</span>
          <span><i-icon name="icon-_icon_PC-4" style="color:#7CB305"></i-icon>{{$t('updateVip.vipFeaturePlace')}}</span>
          <span><i-icon name="icon-_icon_PC-5" style="color:#D46B08"></i-icon>{{$t('updateVip.vipFeatureMore')}}</span>
        </div> -->
         <div class="features">
          <strong>{{$t('updateVip.vipFeatures')}}:</strong>
          <span>{{$t('updateVip.vipFeature90')}}</span>
          <span>{{$t('updateVip.vipFeaturePhoto')}}</span>
          <span>{{$t('updateVip.vipFeatureGeo')}}</span>
          <span>{{$t('updateVip.vipFeatrueOil')}}</span>
          <span>{{$t('updateVip.vipFeaturePlace')}}</span>
          <span>{{$t('updateVip.vipFeatureMore')}}</span>
        </div>
      </div>

      <batch-expire-admin ref="batchExpireAdmin" @refresh="queryHandle" @success="clearSelected"></batch-expire-admin>
    </div>
  <!-- </main-wrapper> -->
</template>

<script>
import { VclCode } from 'vue-content-loading'
import SearchGroup from '@/views/manage/components/search-group'
import IDropDown from '@/base/i-dropdown/i-dropdown'
import IDropDownOption from '@/base/i-dropdown/i-dropdown-option'
import mixin from '@/views/manage/mixin'
import * as API from '@/api/device'
import {Filter, Op, BASE_URL} from '@/api/config'
import TreeUser from '@/views/common/tree-user'
import SinglePay from '@/views/wallet/single-pay'
import miment from 'miment'
import DetailDevice from '@/views/manage/detail/detail-device'

// const DetailDevice = require('@/views/manage/detail/detail-device' + process.env.VUE_APP_VRC + '.vue').default
import ImportTool from '@/views/manage/components/import-tool'
import ImportDetail from '@/views/manage/components/import-detail'
import { saveBlob,translateGps} from '@/common/utils'
import AddTaskCar from '@/views/common/add-task-car'
import {mapMutations} from 'vuex'
import WalletInfo from '@/views/wallet/wallet-info'
import WalletSend from '@/views/wallet/wallet-send'
import DevicePopover from '@/views/common/device-popover'
import BindVehicle from '@/views/manage/detail/bind-vehicle'
import DetailVehicle from '@/views/manage/detail/detail-vehicle'
// const DetailVehicle = require('@/views/manage/detail/detail-vehicle' + process.env.VUE_APP_VRC + '.vue').default
import {deleteFromArray} from '@/common/utils'
import { GetObjectListByObjectIDs } from '@/api/command'
import {getToken} from '@/common/auth'
// import { GetObjectIdByMixIDLimit } from '@/api/common'
import * as Fee from '@/api/fee'
import BatchExpireAdmin from '@/views/batch/batch-expire-admin'
const PRE_ORDER = 1 // 选择订单时
const IN_ORDER = 2 // 查看订单详情
const IN_WECHAT_IMAGE = 3 // 等待支付中
const COMPLETED_ORDER = 9 // 支付成功

export default {
  name: 'batchExpire2',
  props: {
    popWindow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      miment,
      hideTip: false,
      options: [
        { label: '设备ID', value: 'D.SIM2', Op: Op.Contains },
        { label: 'SIM卡号', value: 'D.SIM', Op: Op.Contains },
        { label: '状态', value: 'A.StatusDes', Op: Op.Contains },
        { label: '备注', value: 'D.Remark', Op: Op.Contains },
      ],
      options2: [
        { label: '所有', value: '所有' },
        { label: '在线', value: '在线' },
        { label: '离线', value: '离线' },
        { label: '启动', value: '启动' },
        { label: '熄火', value: '熄火' },
        { label: '报警', value: '报警' },
        { label: '无报警', value: '无报警' },
        { label: 'SIM卡正常', value: 'SIM卡正常' },
        { label: 'SIM卡即将到期', value: 'SIM卡即将到期' },
        { label: 'SIM卡已到期', value: 'SIM卡已到期' },
        { label: 'SIM卡余额不多', value: 'SIM卡余额不多' },
        { label: '服务已到期', value: '服务已到期' },
        { label: '服务7天到期', value: '服务7天到期' },
        { label: '服务30天到期', value: '服务30天到期' },
        { label: '服务60天到期', value: '服务60天到期' },
      ],
      queryKey2: '服务60天到期',
      isAnimating: false, // 是否正在动画
      dialogSinglePay: false,
      single: {
        id: null,
        vehicleName: null,
        serviceEndDate: null
      },
      excelItems: [
        { label: '行号', prop: 'Index' },
        { label: '设备ID', prop: 'SIM2' },
        { label: 'SIM卡号', prop: 'SIM' },
        { label: '设备型号', prop: 'MDTModel' },
        { label: '接线类型', prop: 'TrackerTypeName' },
        { label: '备注', prop: 'Remark' },
        { label: '导入结果', prop: 'errDesc' },
        { label: '导入详情', prop: 'errMsg' },
      ],
      excelDownLoadMethod: null,
      dialogInfoVisible: false,
      dialogSendVisible: false,

      payOptions: [
        { label: '1', value: 1 },
        { label: '2', value: 2 },
        { label: '3', value: 3 }
      ],

      dialogDetailListPageIndex: 1,
      dialogDetailListPageSize: 10,
      dialogObjectList: false,
      oneYearPrice: 12,
      oneYearPriceWire: 6,
      orderDetailList: [],
      orderDetailListVisible: false,
      checkedObjectKeys: [],
      checkedObjectList: [],
      form: {
        PayYear: 1,   // 支付时间
        PayMethod: 1  // 支付方式
      },
      wxImgUrl: '',
      aliPayUrl: '',
      orderNo: '',
      feeLoading: false,
      countLoading: false,
      payTotal: 0,

      // 1 选择车辆与支付方式页面
      // 2 订单确认页面
      // 3 微信支付扫码页面
      // 9 支付成功页面
      PRE_ORDER,
      IN_ORDER,
      IN_WECHAT_IMAGE,
      COMPLETED_ORDER,
      payLoading: false,
      dialogDetail: false,  // 订单详情窗口
      dialogDetailStep: PRE_ORDER,
      btnAlipayLoading: true
    }
  },
  components: {
    AddTaskCar,
    BatchExpireAdmin,
    SinglePay,
    IDropDown,
    IDropDownOption,
    SearchGroup,
    VclCode,
    TreeUser,
    DetailDevice,
    ImportTool,
    ImportDetail,
    WalletInfo,
    WalletSend,
    DevicePopover,
    BindVehicle,
    DetailVehicle
  },
  mixins: [
    mixin
  ],
  created() {
    this.miment = miment
    this.searchName = this.options[0].value
    this.getOptions()
    this.queryHandle()
  },
  watch: {
    '$i18n.locale': 'getOptions',
    'checkedObjectKeysLength': 'fetchTotalFee',
    'form.PayYear': 'fetchTotalFee'
    // isShowMore() {
    //   setTimeout(() => {
    //     this.$refs.multipleTable.doLayOut()
    //   }, 25)
    // }
  },
  methods: {
    click() {
      this.pageIndex = 1
      this.queryHandle()
    },
    getOptions() {
      this.options = [
        { label: this.$t('deviceManage.deviceID'), value: 'D.SIM2', Op: Op.Contains },
        { label: this.$t('deviceManage.sim'), value: 'D.SIM', Op: Op.Contains },
        { label: this.$t('deviceManage.deviceName'), value: 'VehicleNum', Op: Op.Contains },
        { label: this.$t('deviceManage.status'), value: 'A.StatusDes', Op: Op.Contains },
        { label: this.$t('deviceManage.remark'), value: 'D.Remark', Op: Op.Contains }
      ]
      this.options2 = [
        { label: this.$t('deviceManage.optionKey1'), value: '所有' },
        { label: this.$t('deviceManage.optionKey2'), value: '在线' },
        { label: this.$t('deviceManage.optionKey3'), value: '离线' },
        { label: this.$t('deviceManage.optionKey4'), value: '启动' },
        { label: this.$t('deviceManage.optionKey5'), value: '熄火' },
        { label: this.$t('deviceManage.optionKey6'), value: '报警' },
        { label: this.$t('deviceManage.optionKey7'), value: '无报警' },
        { label: this.$t('deviceManage.optionKey8'), value: 'SIM卡正常' },
        { label: this.$t('deviceManage.optionKey9'), value: 'SIM卡即将到期' },
        { label: this.$t('deviceManage.optionKey10'), value: 'SIM卡已到期' },
        { label: this.$t('deviceManage.optionKey11'), value: 'SIM卡余额不多' },
        { label: this.$t('deviceManage.optionKey12'), value: '服务已到期' },
        { label: this.$t('deviceManage.optionKey13'), value: '服务7天到期' },
        { label: this.$t('deviceManage.optionKey14'), value: '服务30天到期' },
        { label: this.$t('deviceManage.optionKey15'), value: '服务60天到期' },
      ]
    },
    rowClick(row) {
      // this.$router.push({
      //   name: 'deviceDetail',
      //   params: {
      //     itemId: row.ObjectID + ''
      //   }
      // })

      this.$refs.detail.open(row.ObjectID)
      //this.$router.push(`/manage/device/detail?id=${row.ObjectID}`)
    },
    selectableHandle() {
      return true
      //return row.UserID != this.loginInfo.UserID
    },
    addHandle() {
      this.$refs.detail.open()
      //this.$router.push('/manage/device/detail')
    },
    deleteHandle(rows) {
      if (!(rows instanceof Array)) {
        rows = null
      }
      const targetRows = rows || this.$refs.multipleTable.selection
      if (targetRows.length <= 0) {
        this.$alert(this.$t('common.emptySelectedTip'), '', {
          type: 'error'
        })
        return
      }

      let msg0 = this.$t('common.deleteTip')
      this.$confirm(msg0, this.$t('common.warn'), {
        confirmButtonText: this.$t('common.confirm'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning'
      }).then(() => {

        this.deleteButtonLoading = true
        API.Delete(targetRows.map(k => k.ObjectID)).then(() => {
          // 从本地删除
          // targetRows.forEach(item => {
          //   deleteFromArray(this.tableData, row => {
          //     return row == item
          //   })
          // })

          this.$message({
            type: 'success',
            message: this.$t('common.deleteSuccessed'),
            showClose: true
          });

          if ((targetRows.length < this.pageSize)
            && (targetRows.length < this.tableData.length)) {
            this.pageIndex = this.pageIndex > 1 ? this.pageIndex - 1 : 1
          }

          this.queryHandle()

        }).catch(err => {
          this.print('error', err.message)
        })

      }).catch(() => {

      });
    },
    queryHandle() {
      const promise = new Promise((resolve, reject) => {
        const filters = []

        this.searchText.length > 0
          && (filters.push(new Filter(this.searchName, this.searchText, this.searchOption)))
        this.getExternalFilters(filters)
        this.tableLoading = true
        API.Query(this.pageIndex, this.pageSize, this.sortKey, this.sortDirect, filters, this.currentHoldId, this.hasChild).then(ret => {
          // console.log(ret)
          this.tableData = ret.data.list.slice()
          this.tableData.map(k => {
            k.tagClass = translateGps(k.TypeID,k,false)
          })
          this.pageTotalCount = ret.data.total
          this.tableLoading = false

          // 设置勾选状态
          this.$nextTick(() => {
            this.setTableListCheck()
          })

          resolve()
        }).catch(err => {
          this.print('error', err.message)
          this.tableLoading = false
          reject(err)
        })
      })
      return promise
    },
    getExternalFilters(filters) {
      if (this.queryKey2 == '所有') {
        return filters
      }
      if (this.queryKey2 == '在线') {
        filters.push(new Filter('A.isOnline', 1, Op.Equals))
      } else if (this.queryKey2 == '离线') {
        filters.push(new Filter('A.isOnline', 0, Op.Equals))
      } else if (this.queryKey2 == '启动') {
        filters.push(new Filter('A.Status', 1, Op.Equals))
      } else if (this.queryKey2 == '熄火') {
        filters.push(new Filter('A.Status', 0, Op.Equals))
      } else if (this.queryKey2 == '报警') {
        filters.push(new Filter('A.isAlarm', 1, Op.Equals))
      } else if (this.queryKey2 == '无报警') {
        filters.push(new Filter('A.isAlarm', 0, Op.Equals))
      } else if (this.queryKey2 == 'SIM卡正常') {
        filters.push(new Filter('A.isOnline', 0, Op.Equals))
      } else if (this.queryKey2 == 'SIM卡即将到期') {
        filters.push(new Filter('A.isOnline', 0, Op.Equals))
      } else if (this.queryKey2 == 'SIM卡已到期') {
        filters.push(new Filter('A.isOnline', 0, Op.Equals))
      } else if (this.queryKey2 == 'SIM卡余额不多') {
        filters.push(new Filter('A.isOnline', 0, Op.Equals))
      } else if (this.queryKey2 == '服务已到期') {
        filters.push(new Filter('ISNULL(D.PayEndDate, DATEADD(DD, -1, GETDATE()))', miment(), Op.LessThanOrEqual))
      } else if (this.queryKey2 == '服务7天到期') {
        filters.push(new Filter('ISNULL(D.PayEndDate, DATEADD(DD, -1, GETDATE()))', miment().add(7, 'DD'), Op.LessThanOrEqual))
      } else if (this.queryKey2 == '服务30天到期') {
        filters.push(new Filter('ISNULL(D.PayEndDate, DATEADD(DD, -1, GETDATE()))', miment().add(30, 'DD'), Op.LessThanOrEqual))
      } else if (this.queryKey2 == '服务60天到期') {
        filters.push(new Filter('ISNULL(D.PayEndDate, DATEADD(DD, -1, GETDATE()))', miment().add(60, 'DD'), Op.LessThanOrEqual))
      }
    },
    exportHandle() {
      this.exportButtonLoading = true
      API.Export(this.currentHoldId, this.hasChild).then(ret => {
        this.exportButtonLoading = false
        saveBlob(ret.data, `${this.$t('deviceDetail.name')}.xlsx`)
      })
    },
    batchImport() {
      this.excelDownLoadMethod = API.DownLoadImportDeviceDetail
      this.$refs.importTool.import()
        .then(fileName => {
          this.$refs.importDetail.open(fileName)
          return API.Import(fileName, this.currentHoldId)
        }).then(ret => {
          console.log(ret.data)
          this.$refs.importDetail.setData(ret.data.list)
        })
    },
    refreshHandle() {
      this.queryHandle()
    },
    singlePay(item) {
      this.single.id = item.ObjectID
      this.single.serviceEndDate = item.PayEndDate
      this.single.vehicleName = item.SIM2
      this.single.trackerType = item.TrackerType
      this.dialogSinglePay = true
    },
    addToTaskList(row) {
      if (!row.VehicleID) {
        this.$message('该设备没有绑定车辆')
        return
      }
      this.addTaskCar(row.ObjectID)
    },
    send() {
      this.dialogInfoVisible = false
      this.dialogSendVisible = true
    },
    goWallet() {
      this.dialogSinglePay = false
      this.dialogInfoVisible = true
    },
    bindVehicle(row) {
      this.$refs.bindVehicle.open(row.ObjectID, row.SIM2)
    },
    getRemainDays(payEndDate) {
      return Math.floor((-miment().diff(payEndDate))/1000/3600/24)
    },

    fetchTotalFee() {
      this.feeLoading = true
      Fee.GetTotalFeeByIDs(this.checkedObjectKeys, this.form.PayYear).then(ret => {
        this.feeLoading = false
        this.payTotal = ret.data.totalFee
      })
    },

    fetchObjectList() {
      this.countLoading = true
      const promise = new Promise((resolve) => {
        GetObjectListByObjectIDs(this.checkedObjectKeys).then(ret => {
          this.countLoading = false
          this.checkedObjectList = ret.data.list
          resolve()
        })
      })
      return promise
    },

    changePayYear(item) {
      this.form.PayYear = item.value
    },

    setTableListCheck() {
      this.tableData.forEach(k => {
        if (this.checkedObjectKeys.includes(k.ObjectID)) {
          this.$refs.multipleTable.toggleRowSelection(k, true)
        }
      })
    },

    selectHandler(selection) {
      this.setSelection(selection)
    },

    selectAllHandler(selection) {
      this.setSelection(selection)
    },

    setSelection(selection) {
      const selectedKeys = selection.map(k => k.ObjectID)
      this.tableData.forEach(k => {
        const isCheckd = selectedKeys.includes(k.ObjectID)
        if (isCheckd) {
          if (!this.checkedObjectKeys.includes(k.ObjectID)) {
            this.checkedObjectKeys.push(k.ObjectID)
          }
        } else {
          deleteFromArray(this.checkedObjectKeys, j => j == k.ObjectID)
        }
      })
    },

    async openDialog() {
      await this.fetchObjectList()
      this.dialogObjectList = true
    },

    // 移除选中的行
    removeSelection() {
      // 移除选中行
      const list = this.$refs.multiTable.selection
      list.forEach(k => {
        deleteFromArray(this.checkedObjectList, j => j.ObjectID === k.ObjectID)
        deleteFromArray(this.checkedObjectKeys, j => j === k.ObjectID)
        const index = this.tableData.findIndex(j => j.ObjectID == k.ObjectID)
        if (index != -1) {
          this.$refs.multipleTable.toggleRowSelection(this.tableData[index], false)
        }
      })

    },
    // 移除所有
    removeAll() {
      this.checkedObjectList.splice(0)
      this.checkedObjectKeys.splice(0)
      this.tableData.forEach(k => {
        this.$refs.multipleTable.toggleRowSelection(k, false)
      })
    },

    // 订单详情关闭
    dialogDetailClosed() {
      this.dialogDetailStep = IN_ORDER
      this.endCheckWxPay()
    },
    cancelPay() {
      this.dialogDetailStep = IN_ORDER
      this.dialogDetail = false
      this.endCheckWxPay()
    },
    quickPay() {
      return new Promise((resolve, reject) => {
        // 清空列表
        this.removeAll()
        const filters = []

        this.searchText.length > 0
          && (filters.push(new Filter(this.searchName, this.searchText, this.searchOption)))
        this.getExternalFilters(filters)
        this.btnLoading = true
        API.QueryDeviceIdList(filters, this.currentHoldId, this.hasChild).then(ret => {
          this.btnLoading = false
          this.checkedObjectKeys = ret.data
          this.tableData.forEach(k => {
            this.$refs.multipleTable.toggleRowSelection(k, true)
          })
          resolve()
        }).catch(e => reject(e))
      })
    },
    quickPayHandler() {
      this.quickPay().then(() => {
        this.payCheckOrder(2)
      })
    },
    pay() {

      if (this.form.PayMethod === 2) {
        // 创建微信支付充值钱包订单
        this.payLoading = true

        Fee.UpdateVIPWechat(this.checkedObjectKeys, this.form.PayYear).then(ret => {
          this.payLoading = false
          if (!ret.data.errCode) {
            this.dialogDetailStep = IN_WECHAT_IMAGE
            this.wxImgUrl = `${BASE_URL}/pay/Image?text=${ret.data.code_url}`
            this.orderNo = ret.data.orderNo
            this.startCheckWxPay()
          } else if (ret.data.errMsg){
            this.$alert(ret.data.errMsg)
            console.error(ret.data)
          } else {
            console.error(ret.data)
          }
        }).catch(err => {
          this.payLoading = false
          this.$message({
            message: err,
            type: 'error'
          })
        })
        return
      } else if (this.form.PayMethod === 3){
        this.startCheckWxPay()
        window.open(this.aliPayUrl)
      } else {
        this.payLoading = true
        Fee.Add(this.checkedObjectKeys, this.form.PayYear).then(ret => {
          this.payLoading = false
          if (!ret.data.errCode) {
            this.dialogDetailStep = COMPLETED_ORDER
            this.clearSelected()
            this.queryHandle()
          } else if (ret.data.errMsg){
            this.$alert(ret.data.errMsg)
            console.error(ret.data)
            if (ret.data.errCode==60001) {
              this.dialogDetail = false
              this.dialogInfoVisible = true
            }
          } else {
            console.error(ret.data)
          }
        }).catch(err => {
          this.payLoading = false
          this.$message({
            message: err,
            type: 'error'
          })
        })
      }
    },
    startCheckWxPay() {
      Fee.IsWxPaySuccess(this.orderNo).then(ret => {
        if (ret.data.isSuccess) {
          this.payLoading = true
          Fee.Add(this.checkedObjectKeys, this.form.PayYear).then(ret => {
            this.payLoading = false
            if (!ret.data.errCode) {
              this.dialogDetailStep = COMPLETED_ORDER
              this.queryHandle()
            } else if (ret.data.errMsg){
              this.$alert(ret.data.errMsg)
              console.error(ret.data)
            } else {
              console.error(ret.data)
            }
          }).catch(err => {
            this.payLoading = false
            this.$message({
              message: err,
              type: 'error'
            })
          })
        } else {
          this.startCheckWxPay_timer = setTimeout(() => {
            this.startCheckWxPay()
          }, 2000)
        }
      })
    },
    endCheckWxPay() {
      if (this.startCheckWxPay_timer) {
        clearTimeout(this.startCheckWxPay_timer)
        this.startCheckWxPay_timer = null
      }
    },

    close() {
      this.dialogDetail = false
      this.queryHandle()
    },

    closeAndGo() {
      this.dialogDetail = false
      this.$router.push('/manage/expire2')
    },

    payCheckOrder(method) {
      if (this.checkedObjectKeys.length === 0) {
        this.$alert(this.$t('updateVip.pleaseSelectVehicle'))
        return
      }
      this.form.PayMethod = method
      // 如果是支付宝
      if (this.form.PayMethod === 3) {
        // 创建支付宝支付充值钱包订单
        this.btnAlipayLoading = true
        Fee.UpdateVIPAliPay(this.checkedObjectKeys, this.form.PayYear).then(ret => {
          this.btnAlipayLoading = false
          this.payLoading = false
          if (!ret.data.errCode) {
            this.dialogDetailStep = IN_ORDER
            this.orderNo = ret.data.orderNo
            this.aliPayUrl = `${BASE_URL}/pay/AlipayByOrder?loginKey=${getToken()}&totalPoints=${this.payTotal}&OrderNo=${this.orderNo}&format=json`
            this.dialogDetail = true
          } else if (ret.data.errMsg){
            this.$alert(ret.data.errMsg)
            console.error(ret.data)
          } else {
            console.error(ret.data)
          }
        }).catch(err => {
          this.payLoading = false
          this.$message({
            message: err,
            type: 'error'
          })
        })
        return
      }
      this.dialogDetailStep = IN_ORDER
      this.dialogDetail = true
    },
    beforeOpenUpdateVipAdmin() {
      this.$confirm(this.$t('updateVip.canYouConfirmAll')).then(() => {
        this.quickPay().then(() => {
          this.openUpadteVipAdmin()
        })
        
      }).catch(() => {
        // this.openUpadteVipAdmin()
      })
    },
    openUpadteVipAdmin() {
      if (this.checkedObjectKeys.length === 0) {
        this.$alert(this.$t('updateVip.pleaseSelectVehicle'))
        return
      }
      this.$refs.batchExpireAdmin.open(this.checkedObjectKeys)
    },

    clearSelected() {
      this.checkedObjectKeys = []
      this.checkedObjectList = []
    },

    ...mapMutations({
      'addTaskCar': 'ADD_TASK_CAR'
    })
  },
  computed: {
    checkedObjectKeysLength() {
      return this.checkedObjectKeys.length
    },
    payMethodText() {
      return ['', this.$t('updateVip.vpoint'), this.$t('updateVip.wechat'), this.$t('updateVip.alipay')][this.form.PayMethod]
    },
    payMethodSuffix() {
      return ['', this.$t('updateVip.vpoint'), this.$t('updateVip.payUnit'), this.$t('updateVip.payUnit')][this.form.PayMethod]
    },
    checkedObjectListPage() {
      const start = (this.dialogDetailListPageIndex - 1) * this.dialogDetailListPageSize
      const end = start + this.dialogDetailListPageSize
      return this.checkedObjectList.slice(start, end)
    }
  },
  mounted() {
    // const move = () => {
    //   if (this.popWindow) {
    //     return
    //   }
    //   this.scrollTop = this.$refs.vWrapper.scrollTop
    // }
    // this.top_move = throttle(25, move)
    // setTimeout(() => {
    //   this.$refs.vWrapper.addEventListener('scroll', this.top_move)
    // }, 300)
    // this.$once('hook:beforeDestroy', () => {
    //   this.$refs.vWrapper.removeEventListener('scroll', this.top_move)
    // })
  }
}
</script>

<style lang="scss" scoped>
.v-wrapper {
  display: flex;
  align-items: stretch;
  padding: 0 30px;
  .v-nav {
    width: 250px;
    flex-shrink: 0;
    overflow-y: auto;
    position: relative;
  }
  .v-body {
    width: 0;
    flex-grow: 1;
  }
}

.square {
  height: 14px;
  width: 14px;
  display: block;
  border-radius: 2px;
  background-color: #BDBDBD;
  &.isOnline {
    background-color: #4CAF50;
  }
}

.imgcolumn {
  img {
    vertical-align: text-top;
    margin-top: -1px;
  }
}

.add-task-car {
  margin-right: 1em;
}

.vip {
  height: 15px;
  vertical-align: sub;
  margin: 0 4px 0 4px;
}

.vipFeatures {
  background-image: linear-gradient(to bottom, #fba141 0%, #fb8733 100%);
  height: 42px;
  line-height: 42px;
  box-shadow: 1px 3px 6px 0 rgba(0, 0, 0, .2);
  span {
    font-size: 12px;
    color: #FFF;
    margin-left: 36px;
    font-weight: 600;
    .icon {
      margin-right: 4px;
      font-size: 16px;
      margin-top: -2px;
      vertical-align: middle;
    }
  }
  .vipFeaturesSummar {
    color: #F4F4F8;
    margin-left: 12px;
    font-weight: normal;
  }
}

// .v-pager {
//   margin-bottom: 100px;
// }
.batch-expire-pay {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  // box-shadow: 0 -2px 12px 0 rgba(0, 0, 0, 0.1);
  background-color: #001025;
}

.features {
  display: flex;
  font-size: 14px;
  padding:10px 0;
  strong{
    padding-left: 20px;
    line-height: 24px;
    margin-right: 10px;
    font-weight: normal;
    color:#fff;
  }
  span{
    margin-right: 20px;
    padding: 2px 5px;
    color: #996633;
    border-radius: 5px;
    background-color: #fbe4b2;
    border: 1px solid #ffcc99;
  }
}

.fixedBottom {
  height: 90px;
  // background-color: #FFF;
  // border: 1px solid #FF6600;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  .fixedBottom-section {
    margin: 0;
    height: 90px;
    display: flex;
    flex-direction: column;
    padding: 8px;

    &.sec1 {
      flex: 0 0 135px;
      margin-left: 12px;
      .fixedBottom-sectionAction{
        text-align: left;
        text-indent: 5px;
        span{
         font-size: 20px;
        }
      }
    }
    &.sec2 {
      flex: 0 0 340px;
    }
    &.sec3 {
      flex: 0 0 120px;
    }
    &.sec4 {
      // flex: 0 0 510px;
      // padding-right: 45px;
      flex: 0 0 450px;
      padding-right: 0px;
    }

    .fixedBottom-sectionLabel {
      font-size: 14px;
      line-height: 28px;
      height: 28px;
      color: #fff;
    }

    .fixedBottom-sectionAction {
      height: 36px;
      line-height: 36px;
      text-align: center;
      // background-image: linear-gradient(rgb(251, 161, 65) 0%, rgb(251, 135, 51) 100%);
      color: #4d6182;
      border-radius: 4px;
      margin-top: 8px;
      &.noBk {
        background-image: none;
        text-align: left;
        margin-left: -12px;
      }
      &.hover:hover {
        cursor: pointer;
        // background-image: linear-gradient(rgba(251, 161, 65, .4) 0%, rgb(251, 135, 51) 100%);
      }

      .fixedBottom-sectionActionText {
        height: 36px;
        line-height: 34px;
        display: inline-block;
        //background-image: linear-gradient(rgba(251, 161, 65, .4) 0%, rgba(251, 135, 51, .4) 100%);
        color: #999999;
        border: 1px solid#999999;
        border-radius: 17px;
        width: 100px;
        text-align: center;
        margin-left: 12px;
        //transition: all 0.3s ease-in;
        .fixedBottom-sectionActionTextPrice {
          display: none;
          color: rgba(0, 0, 0, .26);
          font-size: 12px;
        }
        &:hover {
          //background-image: linear-gradient(rgba(251, 161, 65, .8) 0%, rgba(251, 135, 51, .8) 100%);
          cursor: pointer;
        }
        &.active {
          // background-image: linear-gradient(rgb(251, 161, 65) 0%, rgb(251, 135, 51) 100%);
          // color: #FFF;
          // width: 190px;
          color: #b79b60;
          border: 1px solid #b79b60;
          cursor: default;
          .fixedBottom-sectionActionTextPrice {
            display: unset;
          }
        }


      }

      .fixedBottom-sectionActionPay {
        height: 40px;
        line-height: 40px;
        display: inline-flex;
        width: 130px;
        border-radius: 21px;
        border:1px solid #1890ff;
        border-color:#1890ff;
        color:#1890ff;
        align-items: center;
        margin-left: 12px;
        justify-content: center;
        font-size: 14px;
        // background-image: linear-gradient(to bottom, #e57373 0%, #d32f2f 100%);
        &:hover {
          color:#fff;
          // background-image: linear-gradient(rgba(229, 115, 115, .4) 0%, #1890ff 100%);
          background-color: #1890ff;
          border-color:#1890ff;
          cursor: pointer;
        }
        .icon {
          font-size: 22px;
          margin-right: 8px;
          display: none;
        }
        img {
          height: 21px;
          margin-right: 8px;
          display: none;
        }
      }

      &.price {
        text-align: left;
        background: none;
        color: rgb(251, 135, 51);
        font-weight: 600;
        font-size: 20px;
        .price-unit {
          color: rgba(0, 0, 0, .54);
          font-weight: 500;
        }
      }
    }
  }
}

$payBackgroundImage: linear-gradient(to bottom, #ef5350 0%, #d32f2f 100%);
$payBackgroundImageHover: linear-gradient(to bottom, #e57373 0%, #d32f2f 100%);
.batch-wrapper {
  height: 490px;
  position: relative;
  &.batch-wrapper--flex {
    display: flex;
    .batch-nav {
      width: 450px;
      flex-shrink: 0;
      &.batch-nav--is-tree {
        position: relative;
      }
    }
    .batch-body {
      flex-grow: 1;
      margin: 0;
      margin-right: 0;
      position: relative;
      &.wrapper--success {
        .icon {
          font-size: 128px;
          margin: 24px auto;
          display: block;
        }
      }
      .options {
        display: flex;
        position: relative;
        flex-wrap: wrap;
        justify-content: space-between;
        .option {
          cursor: pointer;
          width: calc(33% - 8px);
          background-color: #F8F8F8;
          height: 40px;
          line-height: 40px;
          text-align: center;
          &.active {
            background-color: #67C23A;
            color: #FFF;
          }
          &:hover {
            background-color: #67C23A;
            color: #FFF;
          }
        }
      }
      .tip {
        color: #67C23A;
        margin-top: 15px;
      }
      .top {
        height: 100px;
        display: flex;
        align-items: center;
        background-color: #89ABE3;
        .img {
          width: 60px;
          height: 60px;
          margin-left: 20px;
        }
        .wallet-remain {
          font-size: 20px;
          margin-left: 20px;
          color: #FFF;
        }
      }
      .item {
        font-size: 14px;
        height: 50px;
        line-height: 50px;
        color: #303133;
      }
      .info {
        position: absolute;
        left: 0;
        bottom: 61px;
        width: 100%;
        z-index: 1;
        p {
          font-size: 18px;
          .money {
            color: rgb(255, 102, 0);
          }
        }
      }
      .bottom {
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        .el-row {
          .el-button {
            flex: 1 0 calc(33% - 8px);
            background-image: linear-gradient(to bottom, #ef5350 0%, #d32f2f 100%);
            & ::v-deep span {
              color: white;
            }
            &:hover {
              background-image: linear-gradient(to bottom, #e57373 0%, #d32f2f 100%);
            }
            .icon {
              font-size: 24px;
              margin-right: 8px;
            }
            img {
              height: 23px;
              margin-right: 8px;
            }
          }
        }

      }
      .success {
        padding-top: 40px;
        margin-left: auto;
        margin-right: auto;
        display: block;
      }
      .success-tip {
        // color: #ECEFF1;
        font-size: 13px;
        line-height: 25px;
        padding: 24px;
        text-align: center;
      }

      &.floatLayer {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0;
        background-color: rgba(255,255,255, .95);
        //padding-left: 50px;
        $fWidth: 24em;
        h2 {
          margin-bottom: 30px;
          margin-top: 42px;
          color: #303133;
          max-width: 336px;
          margin-left: auto;
          margin-right: auto;
          padding-left: 70px;
        }

        .orderItem {
          line-height: 38px;
          height: 38px;
          font-size: 14px;
          max-width: $fWidth;
          margin: 0 auto;
          // padding-left: 5em;
          .orderTitle {
            width: 11em;
            padding-right: 10px;
            text-align: right;
            float: left;
            color: #303133;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .orderText {
            margin-left: 11em;
            width: calc(#{$fWidth} - 11em);
            text-align: left;
            display: block;
            img {
              vertical-align: sub;
              &.img {
                height: 18px;
              }
            }
            span.important {
              color: rgb(255, 102, 0);
            }
            .icon {
              font-size: 16px;
              margin-right: 4px;
            }
          }
        }
        .orderButtons {
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 1;
          width: 100%;
          margin: 0 !important;
          .orderPay {
            margin-top: 30px;
            color: #FFF;
            background-image: $payBackgroundImage;
            width: 100%;
            padding: 14px 20px;
            border: 0;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            &:hover {
              background-image: $payBackgroundImageHover;
            }
            &.cancel {
              background: #EEE;
              & ::v-deep span {
                color: #000;
              }
              // background-image: linear-gradient(to bottom, #BDBDBD 0%, #757575 100%);
              // &:hover {
              //   background-image: linear-gradient(to bottom, #E0E0E0 0%, #757575 100%);
              // }
            }
          }
        }

        &.payImg {
          h2 {
            margin: 40px 0;
            font-weight: 500;
            max-width: 100%;
            padding-left: 0;
          }
          text-align: center;
          padding-left: 0;
          .orderItem {
            padding-left: 0;
            text-align: center;
            .orderText {
              margin-left: 0;
              margin-top: 25px;
              font-size: 18px;
              text-align: center;
              width: 100%;
            }
          }
          .weixinzhifu {
            font-size: 128px;
          }
          .cancelText {
            cursor: pointer;
          }
        }
      }
    }
  }
}
.layer-leave-active {
  display: none;
}
.layer-enter-active, .layer-leave-active {
  transition: all 0.3s;
}
.layer-enter, .layer-leave-to
/* .list-leave-active for below version 2.1.8 */ {
  opacity: 0;
  transform: scale(0.5);
  transform-origin: 50% 0;
}
.search-bars{
  padding: 6px 12px!important;
   .obtn{
    border-radius: 20px !important;
  }
  & ::v-deep .el-input__inner{
    border-radius: 20px;
  }
}

</style>
