<template>
  <div class="page-list-menu" :class="{'is-collapse': isCollapse}">

    <router-link tag="div" exact class="page-list-menu__block" :to="k.link" :class="{'hide': k.hide}" v-for="k in listInner" :key="k.title" @mouseenter.native="() => toggleCollapse(k)" @mouseover.native="openSub(k)">

      <p :key="k.title" class="block__title" v-if="k.items.length > 0" @click="() => toggleCollapse(k)" v-ripple>
        <i-icon :name="k.icon" :style="{color:k.icolor}"></i-icon>
        <!-- <transition name="fade"> -->
        <span style="white-space:nowrap;overflow:hidden;text-overflow:ellipsis;">
        {{k.title}}
        </span>
        <i-icon v-if="!isCollapse" :name="`${k.collapse?'icon-menu-down':'icon-menu-down'}`" class="right"></i-icon>
        <!-- </transition> -->
      </p>

      <p v-else :key="k.title" class="block__title" tag="p" :to="k.link" v-ripple>
        <i-icon :name="k.icon" :style="{color:k.icolor}"></i-icon>
        <!-- <transition name="fade"> -->
          <span name="fade">{{k.title}}</span>
        <!-- </transition> -->
      </p>

      <el-collapse-transition>
        <ul v-show="!k.collapse && !isCollapse" v-if="k.items.length > 0">
          <router-link tag="li" v-if="j.link" :to="j.link" class="block__item" v-for="j in k.items" :key="j.title" @click.native="closeSub(k)">
            <i-icon v-if="j.icon" :name="j.icon" :class="j.icon" :style="{color:j.icolor}"></i-icon>
            {{j.title}}
          </router-link>
          <li v-for="j in k.items" v-if="!j.link" :key="j.title" class="block__item" @click.stop="normalLink(j, k)">
            <i-icon v-if="j.icon" :name="j.icon" :class="j.icon" :style="{color:j.icolor}"></i-icon>
            {{j.title}}
          </li>
        </ul>
      </el-collapse-transition>
    </router-link>

    <div class="page-list-menu__block" key="个人信息" v-if="false">

      <p class="block__title" @click="collapseInfo=!collapseInfo">
        <i-icon name="icon-account"></i-icon>
        <span v-if="!isCollapse" style="flex: 1 0;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;">{{loginInfo.HoldName}}
        </span>
        <i-icon v-if="!isCollapse" class="right" style="margin-top:0;" :name="`${collapseInfo?'icon-menu-down':'icon-menu-down'}`"></i-icon>
      </p>
      <el-collapse-transition>
        <ul v-show="!collapseInfo && !isCollapse">
          <li class="block__item" @click="$emit('openExit')">退出</li>
        </ul>
      </el-collapse-transition>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  props: {
    datas: {
      type: Array,
      default: () => ([])
    },
    isCollapse: {
      type: Boolean,
      default: true
    },
    accordion: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      listInner: this.getListInner(),
      collapseInfo: true
    }
  },
  computed: {
    ...mapGetters([
      'loginInfo'
    ])
  },
  watch: {
    '$i18n.locale': function (){
      this.listInner = this.getListInner()
    }
  },
  created() {
    // console.log(this.datas)
  },
  methods: {
    getListInner() {
      const list = this.datas.map((k, index) => {
        k.collapse = typeof k.collapse === 'boolean' ? k.collapse : true
        k.hide = false

        // 如果展开状态
        if (!k.collapse) {
          this.oldCollapseIndex = index
        }

        return k
      })
      return list
    },
    toggleCollapse(item) {

      item.collapse = !item.collapse
      const index = this.listInner.findIndex(k => k.title === item.title)
      this.listInner.splice(index, 1, item)

      // 收起上次展开菜单
      if (this.oldCollapseIndex && this.oldCollapseIndex !== index) {
        const oldItem = this.listInner[this.oldCollapseIndex]
        oldItem.collapse = true
        this.listInner.splice(this.oldCollapseIndex, 1, oldItem)
      }

      this.oldCollapseIndex = index
    },
    openSub(item) {
      if (!this.isCollapse) {
        return
      }
      item.hide = false
      const index = this.listInner.findIndex(k => k.title === item.title)
      this.listInner.splice(index, 1, item)
    },
    closeSub(item) {
      if (!this.isCollapse) {
        return
      }
      // console.log('closeSub', item.hide)
      item.hide = true
      const index = this.listInner.findIndex(k => k.title === item.title)
      this.listInner.splice(index, 1, item)
    },
    normalLink(subItem, item) {
      console.log('normalLink')
      if (subItem.emit) {
        console.log(subItem.emit)
        this.$emit(subItem.emit)
      }
      this.closeSub(item)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/variables-simple.scss";
.page-list-menu {
  $padding: 0 20px;
  &__block {
    .block__title {
      padding: $padding;
      line-height: 40px;
      height: 40px;
      color: #000;
      font-size: 14px;
      //font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      user-select: none;
      transition: $--color-transition-base;
      position: relative;
      p {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .icon {
        // flex: 0 0 20px;
        font-size: 32px;
        margin-right: 18px;
        //transition: all 0.3s ease;
        transition: $--color-transition-base;
        &.right {
          float: right;
          // margin-top: 12px;
          margin-right: 0;
        }
      }

      & > span {
        flex: 1 0;
      }
      &:hover {
        //background-color: rgba($page-main-background-color, .7);
        color: rgba($--color-primary, .8);
        .icon {
          color: rgba($--color-primary, .8);
        }
      }
      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 0;
        height: 100%;
        left: 0;
        top: 0;
        background-color: transparent;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        z-index: -1;
        transition: all .3s ease;
      }
      &.router-link-active {
        //background-color: rgba($page-main-background-color, 1);
        //font-weight: 600;
        // color: $--color-primary;

        // background-color: #EEEEEE;
        color: $--color-primary;
        background-color: $--color-primary-light-9;
        position: relative;
        &::before {
          width:calc(100% - 20px);
          background-color: $--color-primary-light-9;
        }
        .icon {
          color:$--color-primary;
        }
      }
    }
    ul {
      list-style: none;
      //background-color: $page-common-back;
      .block__item {
        position: relative;
        white-space: nowrap;
        &::after {
          content: '';
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          z-index: -2;
          background-color: $page-common-back;
        }
        &::before {
          content: '';
          display: block;
          position: absolute;
          width: 0;
          height: 100%;
          left: 0;
          top: 0;
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;
          z-index: -1;
          background-color: transparent;
          transition: all .3s ease;
        }
        &.router-link-active::before {
          width: calc(100% - 20px);
          background-color: $--color-primary-light-9;
          //background-color: $--color-primary;
        }
      }
    }
    .block__item {
      // padding: $padding;
      padding-left: 58px;
      line-height: 40px;
      height: 40px;
      font-size: 14px;
      user-select: none;
      cursor: pointer;
      transition: $--color-transition-base;
      &:hover {
        color: rgba($--color-primary, .8);
      }
      &.router-link-active {
        color: $--color-primary;
        //background-color: $--color-primary-light-9;
        position: relative;
        //font-weight: 600;
      }
    }
  }
  &.is-collapse {
    .page-list-menu__block {
      width: 100%;
      // overflow: visible;
      &.router-link-active {
        background-color: $--color-primary-light-9;
      }
      &:hover {
        background-color: #F4F4F8;
      }
      .block__title {
        // width: 58px;
        width: 100%;
        padding: 0;
        padding-right: 0;
        height: 96px;
        flex-direction: column;
        &.router-link-active::before {
          width: 100%;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
        .icon {
          margin-right: 0;
        }
        & > span {
          font-size: 13px;
          flex: 0 1 auto;
          line-height: 1;
          margin-top: 16px;
        }

        @media screen and (max-height: 900px) {
          height: 80px;
          & > span {
            margin-top: 8px;
          }
        }
        @media screen and (max-height: 800px) {
          height: 60px;
          .icon {
            font-size: 24px;
          }
          & > span {
            margin-top: 8px;
          }
        }
        @media screen and (max-height: 750px) {
          height: 60px;
          .icon {
            font-size: 24px;
          }
          & > span {
            margin-top: 8px;
          }
        }
      }
      position: relative;
      &:hover:not(.hide) {
        width: 100%;
        overflow: visible;
        .block__title {
          word-wrap: none;
          white-space: nowrap;
          overflow: hidden;
        }
        ul {
          border-radius: 0;
          display: block !important;
          position: absolute;
          left: 100%;
          top: 0;
          // transform: translateX(-100%);
          z-index: 201;
          padding: 5px 6px;
          background-color: #FFF;
          // background-color: $page-background-color;
          // width: $page-aside-width;
          border: 1px solid #ddd;
          width: fit-content;
          color: rgba(0, 0, 0, 0.76);
          // box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
          //box-shadow: 1px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
          .block__item {
            transition: none;
            padding: 0 24px 0 12px;
            height: 44px;
            line-height: 44px;
            background-color: #FFF;
            &.router-link-active::before {
              width: 100%;
              border-radius: 0;
            }
            &.router-link-active {
              color: $--color-primary;
              position: relative;
            }
            .icon {
              margin-right: 6px;
              margin-bottom: -1px;
              font-size: 18px;
            }
          }
        }

        // @media screen and (max-height: 650px) {
        //   ul {
        //     transform: translateX(-100%) translateY(-40%);
        //   }
        // }
      }
    }
  }
}
</style>
