const menuPosition = getMenuPosition()

const state = {
  ver: '365.20191104120000',
  userName: '',
  userId: '',
  loginInfo: null,
  currentHoldId: 0,
  currentHoldName: '',
  currentHasChild: true,
  visitedViews: [],
  cachedViews: [],
  cachedTrees: [],
  taskList: [],
  cachedDevices:[],
  cacheDvrs:[],
  currentMap: 'Amap',
  menuPosition: menuPosition,
  hasMenu: true,
  monitorLeft: true,
  deviceViews:[],
  glBmap:false,
  glAmap:false
}

function getMenuPosition() {
  if (process.env.VUE_APP_PLAT === '_kc' || process.env.VUE_APP_PLAT === '_gb') {
    return 'top'
  } else {
    return 'right'
  }
}

export default state
