export default {
    system: 'Système de suivi GPS',
    route: {
      home: 'Accueil',
      gpsMenu: 'Surveiller',
      riskMenu: 'Gestion des risques',
      infoMenu: 'Info Management',
      vipMenu: 'VIP',
      reportMenu: 'Rapports',
      notifyMenu: 'Avis',
      gpsOnlineMonitor: 'Moniteur de véhicule',
      cctv: 'DVR',
      gpsHistoryPlay: "Tracer Historique",
      gpsRegion: 'Géo-clôture',
      gpsRoad: 'Road',
      gpsPhotoView: 'Vue photo',
      gpsMessage: 'Envoyer le message',
      riskQuery: 'Évaluation du crédit',
      riskAssets: 'Analyser les actifs',
      riskPlace: "Lieu d'arrêt fréquent",
      riskEvent: 'Événement à risque',
      riskEvaluate: 'Évaluation des risques',
      riskPrevent: 'Avertissement de risque',
      riskShcm: 'Lieu de risque',
      riskPeople: 'Liste grise ',
      infoHold: 'Enterprise',
      infoVehicle: 'Dispositif',
      infoDevice: 'Dispositif',
      infoUser: 'Utilisateur',
      infoPeople: 'Installer',
      infoTask: 'Tâche',
      taskSystem: 'Système de tâches',
      vipWallet: 'Mon portefeuille',
      vipWalletDetail: 'Ordres',
      vipUpdate: 'Surclassement VIP',
      vipUpdateHistory: 'Commander',
      walletInvoice: 'Gestion des factures',
      userCenter: 'Centre des utilisateurs',
      userLog: 'Journal utilisateur',
      language: 'Langue',
      exit: 'Sortir',
      setting: 'Réglage',
      spread: 'Se propager',
      gpsMenufk: 'Moniteur de véhicule',
      spreadfk: 'Se propager',
      taskSystemfk: 'Tâche',
      infoPeoplefk: 'Installer',
      reportMenufk: 'Rapports',
      provinceManage: 'Province',
      visitorlog: 'Journal Vistor',
      manageInsurance: 'Assurance',
      systemInfo:'Information système',
      gpsTrack:"Piste en direct"
    },
    common: {
      back:'Retour',
      save: 'Sauvegarder',
      close:'Fermer',
      active:'Activer',
      cancel: 'Annuler',
      success:'Succès',
      errorTip:'Désolé, veuillez réessayer',
      removeChecked:'Supprimer coché',
      removeAll:'Enlever tout',
      next:'Suivant',
      selectDate:'Veuillez choisir la date',
      inputText: 'Veuillez saisir du texte',
      noResults: 'Pas de données',
      noMoreResults: 'Plus de données',
      operate: 'Fonctionner',
      move:'Bouge toi',
      delete: 'Effacer',
      edit:'Éditer',
      modify:'Modifier',
      emptySelectedTip:'Veuillez sélectionner les données cibles',
      deleteTip:'Êtes-vous sûr de vouloir supprimer ces données?',
      warn:'Avertissement',
      confirm:'Confirmer',
      deleteSuccessed:'Suppression réussie',
      loading:'Chargement',
      bind: 'Lier',
      refresh: 'Rafraîchir',
      ok: "OK",
      migrate:'Migrer',
      assTaskCar:'Suivre',
      timeout: "Délai d'expiration de la demande",
      query: 'Chercher',
      queryAndBind:"Lier le véhicule",
      funDisabled: 'Cette fonction est en cours de maintenance, veuillez patienter.',
      netError: 'Erreur réseau',
      serverError:'Erreur interne du serveur',
      inputKey:'Veuillez saisir la clé',
      queryItem: 'Requete',
      joinAll:'Rejoignez tous',
      clears:'Marque claire',
      addMarkers:"Ajouter des points d'intérêt",
      joinRisk:'Ajouter des points de contrôle des risques'
    },
    other: {
      sendSuccess:  'Envoyer la commande réussie',
      cancelAlarm: "Annuler l'alarme",
      cancelAlarlText: "Veuillez saisir la raison de l'annulation de l'alarme ou non.	",
      selectAddress:'Sélectionnez une adresse	'
    },
    contextMenu: {
      refresh: 'Rafraîchir',
      close:'Fermer',
      closeOtherTabs: 'Fermer les autres onglets',
      closeAll:'Fermer tous les onglets',
      history:"Historique",
      newPage:'Ouvrir dans une nouvelle page'
    },
    layout: {
      noMessage:  'Pas de nouveau message',
      dontShow:'Ne plus montrer',
      notify: 'Notifier',
      exit: 'Sortir',
      favourite: 'Favoris',
      changeLanguage:'Changer de langue',
      queryBykey:'Véhicule / appareil / entreprise',
      changeLayout: 'Disposition',
      changeLayout: 'Contacter',
      contact: 'Menu principal',
      topMenu: 'Menu de gauche',
      '功能':'Menu',
      '用户':'Entreprise',
      '车辆': 'Véhicule'
    },
    login: {
      ipAndPort: 'IP de service: 119.23.233.52 Port: 9881',
      ip: 'IP de service',
      port: 'Port',
      submitButton:'Connexion',
      userNamePlaceHolder:'Veuillez saisir le nom de connexion',
      passWordPlaceHolder:'Veuillez saisir le mot de passe',
      autoLogin: 'Connexion automatique',
      autoLoginTitle:'Veuillez ne pas cocher dans une occasion publique',
      noUsernameTip: 'Échec de la connexion, veuillez saisir le nom de connexion',
      passwordErrorTip:'Échec de la connexion, veuillez saisir le mot de passe',
      pleaseInputMobile:'Veuillez saisir votre mobile',
      pleaseInputCode: 'Veuillez saisir le code de vérification',
      errorMobile: 'Erreur mobile',
      loginBySMS: 'Connexion par mobile',
      loginByPassword:'Connexion par mot de passe',
      loginCheck:"Veuillez vérifier l'accord",
      ProtocolTips:'Accepter et respecter les',
      ProtocolName:"accord d'utilisation de l'utilisateur",
      ProtocolName2:"politique de confidentialité de l'utilisateur",
      ourService:'Notre service',
      wechat:'Wechat',
      forgetPassword:'Mot de passe oublié?',
      findPassword:'Trouve',
      register:'Pas de compte?',
      registerAccount:"S'inscrire",
      scanQR: 'Scanner le code QR',
      followUS: 'Suivez nous',
      downloadAndroidAPP:"Téléchargez l'application Android",
      downloadIOSAPP:"Téléchargez l'application IOS",
      anonymous: 'Anonyme',
      allService: [
        { title: 'Easy Manage Vehicles', comments: ['Self Manage', 'Easy postion', 'Trace replay', 'Rich reports'] },
        { title: 'Professional', comments: ['Pre-evaluation', 'Risk Montor', 'Find car'] },
        { title: 'SIM Manage', comments: ['Balance query', 'Self recharge', 'Send message'] },
        { title: 'Customization', comments: ['Protocol interface', 'Open interface', 'Personalized customize'] }
      ],
      notFoundID: "Cet identifiant d'appareil est introuvable.",
      reset: 'Réinitialiser'
    },
    forget: {
      findPassword: 'Mot de passe oublié?',		
      register: "S'inscrire	",	
      login: 'Connexion'	,	
      pleaseInputYourMobile:'Veuillez saisir votre numéro de téléphone',			
      mobile: 'Téléphoner',			
      valid: 'Vérifier',
      pleaseDrag: "Faites glisser le curseur jusqu'à la fin",			
      validSuccess:'Vérifier le succès	',		
      findError:"Désolé, aucun compte correspondant n'a été trouvé, veuillez contacter l'administrateur!",
      findSuccess: 'Le mot de passe a été récupéré et envoyé sur votre téléphone portable ou par e-mail, veuillez faire attention à vérifier!	',		
      findSuccessSms:'Nous avons envoyé un code de vérification sur votre téléphone mobile.'	,		
      findSuccessEmail:'Nous avons envoyé un code de vérification à votre adresse e-mail',			
      resetPassword: 'Réinitialiser le mot de passe'	,		
      multiTip:'Cet e-mail est lié par plusieurs entreprises, veuillez en choisir une.	',		
      email: 'E-mail	',		
      pleaseInputEmail:'Veuillez saisir votre adresse e-mail',			
      errorFormatEmail:"Adresse e-mail d'erreur",		
      useEmail:"Utiliser l'e-mail"	,	
      useMobile:"Utiliser le mobile"		
    },
    register: {
      registerUser: "S'inscrire",	
      pleaseLogin:'Connexion',	
      tip:'astuce',		
      registerType1:"Compte d'appareil",	
      registerType2:'Compte Entreprise'	,	
      mobile:'Mobile',		
      pleaseInputMobile:'Veuillez saisir votre numéro de téléphone'	,
      valid:'Vérifier',	
      nextStep:'Suivant',
      mobileFormatError:"Numéro de téléphone d'erreur"	,
      pleaseDrag:"Faites glisser le curseur jusqu'à la fin",
      validSuccess:'Vérifier le succès	',	
      step1:'Numéro de téléphone',	
      step2:'Info',		
      step3:'Véhicule',		
      step4:'Succès	',	
      step5:"Adresse e-mail - Nom de connexion"	,
      errorPhone:"Numéro de téléphone d'erreur",	
      validTitle:'Numéro de téléphone valide',		
      validTitleEmail:'Validez votre adresse e-mail',	
      validTip:"Le code de vérification a été envoyé sur votre téléphone mobile",	
      validTipEmail: 'Le code de vérification a été envoyé à votre adresse e-mail',	
      validTip2: "Le code de vérification a été envoyé sur votre téléphone mobile",	
      validTip2Email:"Le code de vérification a été envoyé à votre adresse e-mail",	
      sendButton:'Envoyer',		
      validCode: 'Code de vérification',		
      errorValidCode:'Code de vérification invalide',
      tryAgain:'Essayer à nouveau'	,	
      name:'Nom',	
      password: 'Mot de passe',		
      passwordAgain:'Confirmer'	,	
      vehicleName: 'Nom',		
      assetName:"Nom / étiquette de l'élément",
      asset:'Actif',
      sim:'SIM',	
      sim2:"ID Appareil",	
      typeID:"Type d'inscription",
      vehicle:'véhicule',
      person:'Personne',
      article:'Actif',
      registerSuccess:"Succès. Accédez à la page de connexion après {time} secondes",		
      companyName:'Nom du compte'	,	
      contacter: 'Nom du contact',		
      address: 'Adresse',		
      inputNewPassword:'Saisir un nouveau mot de passe',		
      pleaseInputName: 'Veuillez saisir le nom',		
      pleaseInputPassword:'Veuillez saisir le mot de passe',	
      pleaseInputPasswordAgain:'Veuillez saisir à nouveau le mot de passe'	,	
      pleaseInputVehiclePlate:"Veuillez saisir la plaque d'immatriculation du véhicule",	
      pleaseInputassetName: "Veuillez saisir le nom / l'étiquette de l'élément"	,	
      pleaseInputSim:'Veuillez saisir le numéro de la carte SIM'	,	
      pleaseInputSim2:"Veuillez saisir l'identifiant de l'appareil",	
      pleaseInputEnterprise:'Veuillez saisir le nom du compte',		
      pleaseInputContacter:'Veuillez saisir le nom du contact	',	
      pleaseInputAddress:"Veuillez saisir l'adresse	",
      pleaseInputSixNumber:'Veuillez saisir un mot de passe de 6 à 18 chiffres',
      pleaseInputSixNumber2: 'Veuillez saisir 6 à 18 lettres ou chiffres',
      errorFormat:"Erreur de format d'entrée	",
      checkPassError: 'Les mots de passe nouveaux et confirmés ne sont pas les mêmes'	,	
      pleaseLoginTip1:'Veuillez utiliser la plaque du véhicule comme nom de connexion pour vous connecter',		
      pleaseLoginTip2: 'Veuillez utiliser le numéro de téléphone comme nom de connexion pour vous connecter',	
      pleaseLoginTip3:"Veuillez utiliser l'adresse e-mail comme nom de connexion pour vous connecter",
      pleaseLoginTip4:"Veuillez utiliser le nom / l'étiquette de l'actif comme nom de connexion pour vous connecter",	
      customerAccount:'Compte client'		
    },
    batchImport: {
      title: 'Importer',
      tip1: 'Faites glisser le fichier ici. Ou',
      tip2: 'Cliquez pour télécharger',
      tip3: 'Cliquez pour télécharger',
      tip4: 'Modèle',
      fileName1: 'VehicleTemplate.xlsx',
      fileName2: 'DeviceTemplate.xlsx',
      fileName3: '工单导入模板.xlsx',
      fileName4: '批量更新SIM卡号模板.xlsx',
      fileName5: '保单导入模板.xlsx',
      fileName6: 'DevicePersonTemplate.xlsx',
      fileName7: 'DeviceArticleTemplate.xlsx',
      fileName8: '批量更新车牌模板.xlsx',
      download: 'Télécharger',
      close: 'Fermer',
      downloadDetail: 'Télécharger le détail',
      closePage: 'Fermer la fenêtre',
      importdetail: 'ImportDétail',
      importProcess: 'Traitement...',
      errTip: 'Erreur de téléversement',
      target: 'Entreprise cible',
      nowLoading: 'En cours de chargement...',
      nowProcess: 'Traiter...',
      nowEnd: 'Importé'
    },
    home: {
      '低电压报警': 'Alarme basse tension',
      '低电量报警': 'Alarme de niveau de batterie faible',
      '超速报警':'超速报警',
      '禁行时间段报警':'禁行时间段报警',
      '滞留车辆报警':'滞留车辆报警',
      '拆机报警': 'Alarme de suppression',
      '剪线报警': "Éteindre l'alarme",
      '区域报警': 'Alarme de géo-clôture',
      '围栏报警': 'Alarme de géo-clôture',
      '双离线报警': 'Tout hors ligne',
      '设备分离报警': 'Alarme fractionnée',
      '出省报警': 'Hors province',
      '二押点报警': 'Endroit à haut risque',
      '断电报警':'Alarme panne de courant',
      '区域报警':' Alarme de zone',
      '围栏报警':"Hors de l'alarme de clôture",
      '紧急报警':"Alarme d'urgence",
      '偏离路线报警':"Alarme d'itinéraire de départ",
      '跌落报警':'Alarme de chute',
      '碰撞报警':'Alarme de collision',
      simState: 'État de la carte SIM',
      key1: 'SIM expirées',
      key2: 'Tracker en ligne',
      key3: 'Suivi des véhicules hors ligne',
      key4: 'Nouvel appareil ajouté dans 90 jours',
      chart1: {
        '正常': 'Normal',
        '即将到期': 'Expire bientôt',
        '已到期': 'Expiré',
        '自购卡': 'Auto-achat SIM'
      },
      chart2: {
        '在线': 'En ligne',
        '离线1天内': 'Hors ligne dans 1 jour',
        '离线1-7天': 'Hors ligne 1',
        '离线7-30天': 'Hors ligne 7',
        '离线30天以上': 'Hors ligne 30 Jrs +',
        '未上线': 'Hors ligne',
        '不在线': 'Hors ligne',
        '离线': 'Hors ligne',
        '正常': 'En ligne'
      },
      chart3: {
        '未上线': 'Hors ligne',
        '1天内': 'Un jour',
        '1-7天': '1 à 7 Jrs',
        '7-30天': '7-30 Jrs',
        '30天以上': '30 Jrs +',
        '在线': 'En ligne',
        '不在线': 'Hors ligne',
        '离线': 'Hors ligne',
        '正常': 'En ligne'
      },
      chart4: {
  
      },
      chartOnline:{
        s1:'En ligne',
        s2:'Hors ligne',
        total:"Nombre d'appareils"
      },
      msgTips:"L'ID de l'appareil a été copié",
      unit1: 'Pièce',
      unit2: 'unité 2',
      unit3: 'Régler',
      unit4: 'an',
      un1:'KM/H',
      un2:'KM',
      un3:'°C',
      recentNew: 'Récemment',
      recentActive: 'Récemment actif',
      count: 'Nombre',
      incomeExpired: "Sur le point d'expirer",
      nodate: 'Pas de date',
      offlineRate: 'Pourcentage hors ligne',
      gpsOnlineRate: 'Tarif en ligne',
      wireGpsOnlineRate: 'Tarif en ligne sans fil',
      remainDay: 'Reste {number} jours',
      titleOnline:"Statistiques en ligne",
      titleDevice:"Distribution d'équipements",
      titleNet:'Accès au réseau',
      titleTotal:"Nombre d'appareils en ligne",
      titleNew:"Nouvelles statistiques d'accès au réseau",
      titleYear:"Ajouté au cours des 3 dernières années",
      titleAlarm:"Statistiques d'alarme",
      titleEven:"Événement d'alarme",
      noDatas:"Pas de nouvelles",
      copy:'copie',
      deviceStatus1:"véhicule",
      deviceStatus2:"Personne",
      deviceStatus3:"Actif",
    },
    commonTreeUser: {
      search: 'Chercher',
      searchUser: 'Rechercher un utilisateur',
      searchUserOrDevice: 'Rechercher un utilisateur ou un appareil',
      back: 'Retour',
      backParent: 'Retour au supérieur',
      backRoot: 'Retour à la racine',
      includeChild: 'Inclure le subordonné',
      userList: "liste d'utilisateur",
      checkContainTip: 'Cocher ne pas inclure le subordonné',
      checkNotContainTip: 'Vérifier inclure le subordonné',
      exchange: "Mode d'échange",
      exchangeCountVisible: 'Afficher ou masquer le nombre de véhicules',
      pleaseInputKey: 'Veuillez saisir des mots-clés',
      keyTip: "Plaque du véhicule / ID de l'appareil / VIN / WONO / PeopleName",
      loading: 'Chargement en cours...',
      searchPlace: 'Rechercher un utilisateur'
    },
    commonListVehicle: {
      search: 'Chercher',
      searchVehicle: "Entrez la plaque d'immatriculation/le nom/l'ID de l'appareil",
      vehicleList: 'Liste des véhicules',
      serarchDev:"Entrez l'ID/le nom de l'appareil",
      modify: 'Modifier',
      command: 'Commander',
      rcvTime: 'Heure Reçu',
      noDevice: 'Aucun périphérique de liaison',
      noVehicle: 'Aucune information sur le véhicule',
      all: 'Tout',
      online: 'En ligne',
      offline: 'Hors ligne',
      onAlarm: 'Alarme',
      unused: 'Inutilisé',
      star: 'Suivre',
      typeAll:'Tout',
      typeVehicle:'véhicule',
      typePerson:'Personne',
      typeAirtcle:'Actif',
      allTip: 'Montrez tous les véhicules.',
      onlineTip: 'Afficher les véhicules en ligne.',
      offlineTip: 'Afficher les véhicules hors ligne.',
      onAlarmTip: 'montrer les véhicules avec des alarmes.',
      unusedTip: 'Montrez les véhicules inutilisés.',
      starTip: 'Montrez les véhicules suiveurs.',
      expiredDevice: 'Pardon. Cet appareil VIP a expiré.',
      simTip: "* Cet appareil n'a pas d'identifiant d'appareil, il s'agit du numéro de carte SIM.",
      data: 'Modifier',
      enterprise: 'Entreprise',
      sms: 'SMS',
      vehicleInfo: 'Informations sur le véhicule',
      deviceInfo: 'Info appareil',
      totalRecord: 'Le total est de {count}',
      deviceList:"Liste des périphériques",
      userList:"liste d'utilisateur",
      followStatus:"Suivi",
      followStatus2:"Se désabonner",
    },
    commonPanel: {
      place: 'Emplacement',
      clickViewLocation: 'Où suis-je?',
      showLngLat: 'Afficher la longitude et la latitude.',
      showLocation: "Afficher l'emplacement.",
      noPlaceTip: 'Aucun emplacement.',
      noPlace: 'Aucun emplacement',
      battery: 'La batterie',
      endurance: 'Endurance',
      day: 'Jours',
      wake: 'Réveiller',
      lbsLocation: 'KG',
      wifiLocation: 'WIFI',
      gpsLocation: 'GPS',
      lastLocation: 'Heure GPS',
      satelLocation: 'GPS',
      wakeTime: 'Télécharger',
      emperature:'Température',
      allMiles:'kilométrage total'
    },
    userManage: {
      userName: 'Nom de la compagnie',
      account: 'Compte',
      contacer: 'Contacter',
      phone: 'Téléphoner',
      createTime: 'Créé le ',
      remark: 'Remarque',
      fullPath: 'Chemin',
      addHold: 'Nouvel utilisateur',
      deleteChecked: 'Activer Appareil',
      changeParent: 'Migrer',
      export: 'Supprimer Sélectionnés',
      includeChild: 'Inclure le subordonné',
      search: 'Chercher'
    },
    commonManage: {
      search: 'Chercher',
      selectType: 'Veuillez sélectionner',
      inputPlaceHolder: 'SVP Entrez text  recherché'
    },
    vehicleManage: {
      vehicleName: 'Nom',
      vehiclePeople: 'Propriétaire',
      vehicleBrand: 'Marque',
      vehicleType: 'Type Véhicule',
      deviceCount: 'Nombre de périphériques',
      createTime: 'Créé le ',
      remark: 'Remarque',
      add: 'Véhicule neuf',
      delete: 'Activer Appareil',
      change: 'Migrer',
      export: 'Supprimer Sélectionnés',
      upload: 'Importer',
      includeChild: 'Inclure le subordonné',
      removeSuccess: 'Suppression réussie',
      addDevice: 'Ajouter un appareil',
      searchBind: 'Rechercher et lier',
      enterprise: 'Entreprise',
      storeName: 'Nom du magasin',
      wono: 'GAGNÉ',
      vin: 'NIV',
      installTime: "Temps d'installation",
      nodevice: 'aucun dispositif'
    },
    deviceManage: {
      deviceID: "ID Appareil",
      vehicleName: 'Nom',
      deviceName:"Nom de l'équipement",
      articleName:"Nom de l'Asset",
      articleId:"ID de l'Asset",
      articleColor:'Couleur',
      articleWeight:'poids',
      articleLong:'longueur',
      articlesize:'size',
      articleWidth:'largeur',
      articleHigh:'la taille',
      personName:'Nom du Person',
      personId:'Identifiant de la personne',
      personAge:'âge',
      personIDCard:"numéro d'identification",
      personPhone:"Numéro de téléphone portable",
      personSex:'genre',
      pesonGender1:'Masculin',
      pesonGender2:'Femelle',
      personType:"Catégorie de Person",
      vip: 'EXP VIP.',
      status: 'Statut',
      online: 'En ligne',
      offline: 'Hors ligne',
      acc: ' Statut',
      alarm: 'Alarme',
      rcvTime: 'Heure Reçu',
      gpsTime: 'Heure GPS',
      offlineTime: 'Temps hors ligne',
      sim: '# SIM',
      place: 'Emplacement',
      remark: 'Remarque',
      createTime: 'Créé le ',
      deviceType: "Type d'appareil",
      mdtType: 'Protocole',
      add: 'Nouvel appareil',
      active:'Activer Appareil',
      delete: 'Supprimer Sélectionnés',
      export: 'Supprimer Sélectionnés',
      changeParent: 'Migrer par lots',
      upload: 'Importation par lots',
      uploadsim: 'Numéro de carte SIM de mise à jour par lots',
      includeChild: 'Inclure le subordonné',
      optionKey1: 'Tout',
      optionKey2: 'En ligne',
      optionKey3: 'Hors ligne',
      optionKey4: 'Moving',
      optionKey5: 'Ignition Off',
      optionKey6: 'Alarme',
      optionKey7: 'Normal',
      optionKey8: 'État normal de la carte SIM',
      optionKey9: "La carte SIM est sur le point d'expirer",
      optionKey10: 'La carte SIM a expiré',
      optionKey11: 'Carte SIM pas assez de solde',
      optionKey12: 'VIP expiré',
      optionKey13: 'VIP expire dans 7 jours',
      optionKey14: 'VIP expire dans 30 jours',
      optionKey15: 'VIP expire dans 60 jours',
      statusKey1:"All",
      statusKey2:"inactivé",
      statusKey3:"Ordinaire",
      statusKey4:"Temps d'arrêt",
      statusKey5:"Hors ligne",
      optionKey16:"Static",
      optionKey17:"Weak",
      displayMore: 'Afficher plus',
      wrap: 'Envelopper',
      updateVip: 'Mettre à niveau VIP',
      moverDevices: 'Migrer les appareils',
      activateDevice:"Activer l'appareil",
      keyOnline: 'En ligne',
      keyOffline: 'Hors ligne',
      keyOnRoad: 'En mouvement',
      keyDaisu: 'Inactif',
      keyRef: 'Emplacement de référence',
      keyNoSpeed: 'Acc Off',
      keyNoSpeedRef: 'Acc Off, emplacement de référence',
      keyAlarm: 'Alarme',
      keyNormarl: 'Normal',
      holdName: 'Entreprise',
      remainDays: 'Reste jours',
      days: 'Jours',
      normalSearch: 'Recherche normale',
      multipleSearch: 'Recherche par lots',
      textareaTip: "SVP saisir l’ID de l’Appareil, séparés pas des virgules si plusieurs ID, jusqu’à 1000 entrées à la fois.",
      enterTip: "Vous avez saisi {count} ID d'appareil.",
      clear: 'Dégager',
      simInfo: 'Informations SIM',
      deviceMode: "Voir Appareils",
      vehicleMode: 'Voir Véhicules',
      Index: 'Indice',
      SIM2: "ID Appareil",
      SIM: 'SIM',
      MDTModel: "Modèle d'appareil",
      TrackerTypeName: 'Type Traçage',
      Remark: 'Remarque',
      errDesc: 'La description',
      errMsg: 'Résultat',
      clickViewDetail: "Afficher les détails de l'appareil",
      clickViewSIM: 'Afficher les détails de la carte SIM',
      trace: 'Rapport de trace',
      findDevice: "Rechercher {count} appareils",
      inputAgain: 'Entrez à nouveau',
      peopleName: 'Nom de personne',
      wono: 'GAGNÉ',
      storeName: 'Nom du magasin',
      installTime: "Temps d'installation",
      vin: 'NIV',
      effectiveWay: 'Option',
      effectiveNow: 'Now',
      effectiveOnActive: 'Maintenant',
      effectiveDelay: '3 mois plus tard',
      oneYear: '1 an',
      twoYear: '2 années',
      threeYear: '3 années',
      fourYears:'4 années',
      fiveYears:'5 années'
    },
    accountManage: {
      account: 'Identifiant',
      userName: "Nom d'utilisateur",
      userType: "Type d'utilisateur",
      startTime: 'Heure de début',
      endTime: 'Heure de fin',
      remark: 'Remarque',
      fullPath: 'Chemin',
      add: 'Nouvel utilisateur',
      delete: 'Activer Appareil',
      export: 'Supprimer Sélectionnés',
      includeChild: 'Inclure le subordonné'
    },
    peopleManage: {
      userName: "Nom d'utilisateur",
      account: 'Identifiant',
      phone: 'Téléphoner',
      createTime: 'Créé le ',
      fullPath: 'Chemin',
      remark: 'Remarque',
      add: 'De nouvelles personnes',
      delete: 'Activer Appareil',
      export: 'Supprimer Sélectionnés',
      includeChild: 'Inclure le subordonné',
      enterprise: 'Entreprise',
      userType: "Type d'utilisateur"
    },
    taskManage: {
      orderNo: 'N ° de commande.',
      installPeople: 'Installateur',
      startTime: 'Heure de début',
      endTime: 'Heure de fin',
      total: 'Le total',
      taskState: 'Tâche',
      remark: 'Remarque',
      add: 'Nouvelle tâche',
      delete: 'Activer Appareil',
      export: 'Supprimer Sélectionnés',
      createTime: 'Créé le ',
      agentName: "Nom d'agent",
      expectTime: "Attendez-vous à l'heure",
      projectName: 'nom du projet',
      customerName: 'Nom du client',
      vin: 'NIV',
      checkTime: "Vérifier l'heure",
      orderStatus: {
        '0': 'Établi',
        '1': 'En attente de confirmation',
        '2': 'Travail',
        '3': "En attente d'approbation",
        '9': 'Terminé'
      }
    },
    wallet: {
      time: 'Datetime',
      name: 'Un service',
      vpoint: 'Vpoint',
      remain: 'Rester',
      balance: 'Équilibre',
      accountBalance: 'Solde du compte',
      orderNo: 'N ° de commande',
      remarkOrder: 'N ° de commande / marque',
      remark: 'Remarque',
      createUser: 'Créer un utilisateur',
      user: "Compte d'utilisateur",
      account: 'Compte',
      openWallet: 'Ouvrir le portefeuille',
      export: 'Supprimer Sélectionnés',
      includeChild: 'Inclure le subordonné',
      orderDetail: 'Détails de la commande',
      recharge:"Ordre de renouvellement",
      readDetail:'Consulter le dossier de renouvellement',
      recgargeTitle:'Enregistrement de la transaction récente',
      vehicleName: 'Nom',
      holdName: "Nom d'utilisateur",
      deviceID: "ID Appareil",
      amount: 'Montant',
      vipEndDate: "Date d'expiration VIP",
      wallet: 'Portefeuille',
      sendVPoint: 'Cadeau VPoint',
      gift: 'Cadeau',
      pay: 'Montant',
      payPrefix: '',
      payMethod: 'Méthode de paiement',
      alipay: 'Alipay',
      wechat: 'Wechat',
      alipayTip: "Des centaines de millions d'utilisateurs l'utilisent et la sécurité peut être confiée.",
      wechatTip: "Le choix de millions d'utilisateurs, plus rapide et plus sûr",
      payButton: 'Payer',
      custom: 'Personnalisé',
      payTotal: 'Payer Total',
      payUnit: 'CNY',
      backMine: 'Retour',
      successTip: 'Succès, vous avez maintenant {total} VPoint',
      bottomButton: 'Merci',
      targetUser: 'Utilisateur cible',
      targetCount: 'Nombre cible',
      sendSuccessed: 'Envoyer le succès',
      limitRemain: 'Votre vpoint est inférieur à {count}.',
      confirmSend: "Êtes-vous sûr d'envoyer {count} vpoints à {target}, vous resterez {restez} vpoint après l'envoi?",
      tip: 'Conseil',
      giftPrefix: '',
      selectUser: 'Veuillez sélectionner un utilisateur',
      buy: '',
      year: 'An',
      recentOrders: 'Dernières commandes',
      viewAll: 'Voir tout',
      addVpoint: 'Ajouter un vpoint',
      addVpointDesc: 'Utilisez vpoint pour mettre à niveau VIP',
      orderDetailDesc: 'Afficher les commandes détaillées du portefeuille',
      invoiceManage: 'Gérer la facture',
      invoiceManageDesc: 'Afficher ou demander des factures',
      pleaseInputRemark: 'Veuillez saisir une remarque.',
      administrator: 'Administrateur',
      currentUser: 'Utilisateur actuel',
      confirmSend2: "Êtes-vous sûr d'envoyer {count} vpoints à {target}？"
    },
    updateVip: {
      orderNo: 'N ° de commande.',
      holdName: 'département',
      orderCount: 'Quantité de renouvellement',
      orderPoint: 'VPoint',
      orderStatus: 'Statut de la commande',
      orderUser: 'Payeur',
      createTime: 'Créé le ',
      updateTime: 'Temps de mise à jour',
      includeChild: 'Inclure le subordonné',
      add: 'Mettre à niveau VIP',
      delete: 'Activer Appareil',
      view: 'Vue',
      orderDetail: 'Détails de la commande',
      vehicleName: 'Nom',
      deviceID: "ID Appareil",
      amount: 'Temps de mise à niveau',
      vipEndDate:"Date d'expiration VIP",
      sim: 'Carte SIM',
      trackerType: 'Fil / sans fil',
      online: 'En ligne ou pas',
      wallet: 'Mon portefeuille',
      sendVPoint: "Cadeau VPoint",
      updateVip: 'Mettre à niveau VIP',
      adminVip: 'Mettre à niveau VIP (administrateur)',
      selectVehicle: 'Sélectionnez un véhicule',
      remain: 'Équilibre',
      addPoint: 'Nouveau VPoint',
      vpoint: 'VPoint',
      payAmount: 'Temps de mise à niveau',
      year: 'An',
      month: 'Mois',
      payMethod: 'Méthode de paiement',
      wechat: 'Wechat Pay',
      alipay: 'AliPay',
      payFee: 'Frais',
      payUnit: 'CNY',
      selected: 'Choisi',
      deviceUnit: '',
      device: 'Appareil',
      orderTip1: 'Veuillez vérifier votre commande',
      orderName: 'Nom du service',
      orderAmount: 'Temps de mise à niveau',
      orderVehicle: 'Véhicules',
      orderMethod: 'Payer',
      payVehicle: 'Véhicules',
      payTime: 'Temps de mise à niveau',
      orderTip2: 'Veuillez utiliser wechat scanner le code qr et effectuer le paiement',
      nextButtonText: 'Poursuivre le paiement',
      backBUttonText: 'Sélectionner de nouveau',
      updateSuccess: 'Succès de la mise à niveau',
      viewOrder: "Voir l'ordre",
      selectedList: 'Liste sélectionnée',
      total: 'Le total',
      feeTip: 'GPS Tracker {price1} RMB / Year, Assets Tracker {price2} RMB / Year',
      pleaseSelectVehicle: "Veuillez d'abord sélectionner le véhicule cible",
      expired: 'Expiré',
      vipTip1: "Le véhicule VIP conserve plus de 90 jours de données d'historique.",
      vipTip2: "Le véhicule gratuit économise 7 jours de données d'historique.",
      dateTo: 'VIPdate au',
      vipFeatures: 'Fonctionnalités VIP',
      vipFeature90: 'Gardez une trace pendant 90 jours',
      vipFeaturePhoto: 'Prendre une photo',
      vipFeatureGeo: 'Clôture Geo',
      vipFeatrueOil: 'Rapport pétrolier',
      vipFeaturePlace: 'Trouver un arrêt fréquent',
      vipFeatureMore: 'Suite',
      oneCLickPay: 'Tout payer',
      myOrder: 'Ordres',
      byMonth: 'Par mois',
      byDate: 'Date fixe',
      confirmUpdateMsg:"Êtes-vous sûr de surclasser VIP pour {count} véhicules? plan de mise à niveau: {option} ， heure: {date}",
      monthUnit: 'Mois',
      canYouConfirmAll: 'Pouvez-vous vouloir sélectionner tous les véhicules dans le résultat de la requête?'
    },
    userLog: {
      operateTime: 'Temps de fonctionnement',
      user: "Compte d'utilisateur",
      userName: "Nom d'utilisateur",
      account: 'Compte',
      operateType: "Type d'opération",
      description: 'La description',
      remark: 'Remarque',
      range: 'Heure de la requête',
      '新增': 'Nouveau',
      '增加': 'Nouveau',
      '修改': 'Modifier',
      '删除': 'Effacer',
      '登录': 'Connexion',
      '迁移': 'Migrer',
      '手机客户端':'Fin mobile',
      'order1':"Envoyer la commande d'effacement d'alarme avec succès",
      '修改安装照片同时更新APP工单照片地址':'Modifier la photo du véhicule',
      '下发终端指令':'Émettre des commandes de terminal',
      '系统':'système',
    },
    userCenter: {
      title: 'informations utilisateur',
      myProvider: 'Mon fournisseur de services',
      provider: 'Fournisseur',
      contacter: 'Contacter',
      phone: 'Téléphoner',
      address: 'Adresse',
      email: 'Email',
      changePassword: 'Changer le mot de passe',
      changePasswordTip: 'Afin de protéger la sécurité de votre compte, veuillez modifier votre mot de passe en temps opportun.',
      change: 'Changement',
      customlize: 'Réglage auto-défini',
      customlizeTip1: 'Veuillez saisir 1 à 30 caractères ou chiffres, titre en anglais',
      customlizeTip2: 'Veuillez télécharger une photo PNG de 40 pixels * 40 pixels!',
      upload: 'Télécharger',
      changeTitle: 'Changer le titre',
      newTitle: 'Nouveau titre',
      changeLogo: 'Changer de logo',
      newLogo: 'Nouveau logo',
      changeLogoTip: 'Faites glisser les fichiers ici ou ',
      clickUpload: 'Cliquez pour télécharger',
      emptyWarning: 'Veuillez saisir le titre',
      changeAllLogo: "Sous-groupe ",
      changeAllLogoTip: "Veuillez sélectionner l'élément à définir",
      changeAllLogoOption1: 'Titre',
      changeAllLogoOption2: 'Logo',
      changeAllLogoConfirm: "Si vous confirmez la configuration de votre tout sous-groupe, veuillez saisir 'confirmer' dans la zone de texte qui s'affiche, puis cliquez sur le bouton Enregistrer.",
      changeAllLogoConfirmText: 'confirmer',
      changeMobile: 'Changer de mobile',
      newMobile: 'Nouveau mobile',
      emailTips:"Les notifications par courriel seront envoyées à l’adresse courriel du compte",
      wordChange:'Modifier',
      wordPassword:"Mot de Passe",
      wordEmail:"Notifications",
      wordNotic:"par Courriel"
    },
    changePassword: {
      key1: 'Changer le mot de passe',
      key2: 'Ancien mot de passe',
      key3: 'Nouveau mot de passe',
      key4: 'Confirmez le mot de passe',
      tip1: "Veuillez saisir l'ancien mot de passe",
      tip2: 'Veuillez saisir un nouveau mot de passe',
      tip3: 'Veuillez saisir à nouveau le nouveau mot de passe',
      tip4: 'Mot de passe incohérent'
    },
    holdDetail: {
      name: 'Entreprise',
      basicInfo: 'Informations de base',
      authInfo: 'Autorisation',
      parentHoldName: 'Supérieur',
      holdName: "Nom d'utilisateur",
      userName: 'Identifiant',
      password: 'Mot de passe',
      contacter: 'Contacter',
      email:'E-mail',
      phone: "Téléphoner",
      remark: "Remarque",
      timezone: 'Fuseau horaire',
      address: 'Adresse',
      ServiceEndTime: 'Date de fin du service',
      placeHolder1: "Veuillez saisir le nom d'utilisateur",
      placeHolder2: "Veuillez saisir le compte",
      placeHolder3: "Veuillez saisir le mot de passe",
      placeHolder4: "Veuillez saisir contacter",
      placeHolder5: "Veuillez saisir le téléphone",
      placeHolder6: 'Veuillez sélectionner la date de fin du service',
      defaultProvince: "Province par défaut",
      byInstallAddr: "Adresse d'installation",
      byFixed: 'Adresse fixe',
      nullAddress: 'Rien',
      random: 'Aléatoire'
    },
    vehicleDetail: {
      name: "Détail du véhicule",
      QueryPwd: 'Mot de passe de requête',
      basicInfo: 'Informations de base',
      peopleInfo: "Information du propriétaire",
      moreInfo: "Plus d'informations",
      holdName: 'Entreprise',
      vehiclePlate: 'Nom',
      devicePlate:"Nom",
      vehicleColor: 'Couleur du véhicule',
      vehicleBrand: 'Marque du véhicule',
      vehicleType: 'Type Véhicule',
      deviceID: "ID Appareil",
      deviceType: "Modèle d'appareil",
      deviceLine: "Type d'appareil",
      remark: 'Remarque',
      peopleName: 'Nom',
      peopleSex: 'Le sexe',
      male: 'Homme',
      female: 'Femelle',
      phone: 'Téléphone',
      idcard: "Carte d'identité",
      wechat: 'Wechat',
      qq: 'QQ',
      Age:'âge',
      homeAddress: 'Adresse du domicile',
      companyAddress: 'Adresse de la société',
      vehicleId: 'NIV du véhicule',
      vehicleMachineId: 'Numéro de moteur',
      actionRemove: 'Supprimer',
      actionUnBind: 'Délier',
      actionViewInstall: "Afficher les informations d'installation",
      actionInstall: 'Installer',
      moveVehicle: 'Migrer le véhicule',
      pleaseSelectVehicle: 'Veuillez sélectionner un véhicule',
      pleaseInputVehicle: "Veuillez saisir le numéro de plaque d'immatriculation du véhicule",
      pleaseSelectBrand: 'Veuillez sélectionner la marque du véhicule',
      pleaseSelectType: 'Veuillez sélectionner le type de véhicule',
      pleaseInputName: 'Veuillez saisir le nom',
      pleaseSelectSex: 'Veuillez sélectionner le sexe',
      pleaseInputTelephont: 'Veuillez saisir le numéro de téléphone',
      pleaseInputAge: "Veuillez entrer l'âge",
      pleaseInputIdCard: "Veuillez saisir le numéro de carte d'identité",
      pleaseInputWechat: 'Veuillez saisir le numéro de wechat',
      pleaseInputQQ: 'Veuillez saisir le numéro qq',
      pleaseInputFrameNo: 'Veuillez saisir le numéro de châssis du véhicule',
      pleaseInputEngineNo: 'Veuillez saisir le numéro du moteur du véhicule',
      bindDevice: 'Lier le périphérique',
      installDevice: "Détails de l'installation",
      saveInstallInfo: 'Sauvegarder',
      noDeviceInfo: 'Aucun appareil installé',
      clickToSelect: 'Cliquez pour sélectionner',
      tip: 'Conseil',
      unbindText: 'Confirmer la dissociation de cet appareil avec le véhicule?',
      location: 'Emplacement',
      getLocation: 'GetLocation',
      getSuccess: 'Obtenez le succès de votre emplacement',
      needConfirm: 'Cette position est trop large. Veuillez cliquer sur la bonne loupe pour sélectionner la bonne position.',
      getFailed: "L'obtention de l'emplacement a échoué, veuillez cliquer sur le bouton droit pour obtenir l'emplacement.",
      savePeopleTip: "Afin d'enregistrer les informations d'adresse, le propriétaire ",
      wono: 'GAGNÉ',
      carInstallStore: 'Nom du magasin',
      carCost: 'Coût',
      carStorePhone: 'MagasinMobile',
      carInstallTime: 'ActiveTime',
      selectPos: 'Sélectionner',
      installed: 'Appareil installé',
      saveAddressTip: '',
      usevandplogin: "Utilisez la plaque d'immatriculation et le mot de passe de requête pour vous connecter à la plate-forme pour interroger l'emplacement du véhicule",
      '不规则形体':'Corps irrégulier',
      '长方体':'cuboïde',
      '圆形/椭圆形':'Rond/ellipse',
      '儿童':'enfant',
      '老人':'Vieil homme',
      '中年':'âge moyen',
      '青年':'jeunesse',
      '其他':'autre'
    },
    installInfo: {
      vehicleNumber: 'Plaque du véhicule',
      deviceID: "ID Appareil",
      installTime: "Temps d'installation",
      installPeople: 'Installer des personnes',
      installPlace: "Position d'installation",
      installPhoto: 'Installer la photo',
      remark: 'Remarque',
      dianping: 'La batterie',
      fujiashi: 'Boîte à gants de conduite Co',
      zuocetaban: 'Pédale gauche',
      youcetaban: 'Pédale droite',
      fangxiangpan: 'Sous le volant',
      qiandingdeng: 'Lampe frontale avant',
      houdingdeng: 'Lampe frontale arrière',
      houbeixiang: 'Tronc',
      qita: 'Autre',
      change: 'Remplacer',
      pleaseSelectDate: "Veuillez sélectionner l'heure d'installation",
      pleaseSelectPeople: "Veuillez sélectionner l'installateur",
      pleaseSelectPosition: "Veuillez sélectionner l'emplacement d'installation",
      pleaseUploadPhoto: 'Veuillez télécharger une photo',
      errorTip1: "Le téléchargement des photos de la tête ne peut être qu'au format JPG!",
      errorTip2: "La taille du DP téléchargé ne doit pas dépasser 5 Mo!",
      exceedTip: 'Le téléchargement ne doit pas dépasser 9 photos',
      exceedTip4: 'Le téléchargement ne doit pas dépasser 4 photos',
      installPositionNumber: 'Numéro de poste'
    },
    deviceDetail: {
      name: "Détail de l'appareil",
      personName:'Personne',
      articleName:'Actif',
      articleNameTag:'Actif',
      personNameTag:'Personne',
      deviceName:'Nom',
      personName:'Nom du personnel',
      personId:"Identifiant de la personne",
      personAge:'âge',
      personIDCard:"Carte d'identité",
      personPhone:"Numéro de téléphone portable",
      personSex:'genre',
      pesonGender1:'Masculin',
      pesonGender2:'Femelle',
      personType:"Catégorie de personnel",
      personPic:'photo',
      articleName:"Nom de l'Asset",
      articleWeight:'poids',
      articleLong:'longueur',
      articleHigh:'la taille',
      articleColor:"Couleur de l'Asset",
      articleWidth:'largeur',
      articleShape:"Forme de l'Asset",
      articlesize:'size',
      Unit:'cm',
      Unit2:'KG',
      holdName: 'Enterprise',
      payEndDate: "Date d'expiration VIP",
      deviceID: "ID Appareil",
      sim: 'Carte SIM',
      deviceType: "Modèle d'appareil",
      remark: 'Remarque',
      timezone: 'Fuseau horaire',
      pleaseInputSim: 'Veuillez saisir le numéro de la carte SIM',
      pleaseInputDeviceID: "Veuillez saisir l'identifiant de l'appareil",
      pleaseSelectDeviceType: "Veuillez sélectionner le type d'appareil",
      device: 'Appareil',
      vehicle: 'Véhicule',
      showMore: 'Montre plus',
      showLess: 'Montrer moins',
      saveVehicleInfo: 'Ajouter des informations sur le véhicule',
      tip1: 'Rappel:',
      tip2: "1.Vous pouvez enregistrer des informations sur le véhicule tout en ajoutant un appareil et lier automatiquement l'appareil au véhicule. Vous pouvez également cliquer sur le bouton de suppression sur le côté droit des informations vechile pour supprimer les informations sur le véhicule et enregistrer uniquement les informations sur l'appareil. Enfin, vous pouvez lier l'appareil au véhicule sur la page de gestion du véhicule.",
      tip3: "2.Utilisez la page de gestion des véhicules si vous avez besoin d'une liaison de périphérique à des véhicules existants.",
      unBindTip: "Cela détachera l'appareil avec le véhicule {name}",
      unBindConfirm: 'Confirmer',
      unBindSuccess: 'Dissociation réussie',
      selectVehicle: 'Sélectionnez le véhicule {name}',
      bindSuccess: 'Bind réussi',
      select: 'Sélectionner',
      bind: 'Lier',
      tip4: 'Veuillez sélectionner le véhicule pour lier le dispositif de largeur.',
      clickToUnbind: 'Cliquez pour détacher le véhicule',
      remainTime: 'Rester',
      days: 'Jours',
      install: 'Installer',
      installTime: "Temps d'installation",
      installPlace: "Lieu d'installation",
      installPeople: 'Ouvrier',
      installPhoto: 'Installer la photo',
      showInstallInfo: "Afficher les informations d'installation",
      saveInstallInfo: "Ajouter des informations d'installation",
      clickToDeleteInformation: "Cliquez pour supprimer les informations d'installation",
      deleteInfoConfirm: "Êtes-vous sûr de supprimer les informations d'installation",
      tip: 'Conseil',
      vehicleProvince: 'Province du véhicule',
      pleaseSelectProvince: 'Veuillez sélectionner la province du véhicule',
      detailInfo: 'Informations détaillées',
      deviceInfo: 'Info appareil',
      mdtType: 'Protocole',
      createTime: 'Crate time',
      simInfo: 'SIM',
      gpsInfo: 'Données GPS',
      gpsTime : 'Heure GPS',
      rcvTime: 'Recevoir du temps',
      oil: 'Pétrole',
      speed: 'Vitesse',
      miles: 'Miles',
      location: 'Emplacement',
      statsuDes: 'Statut',
      userInfo: 'Informations utilisateur',
      contacter: 'Contacter',
      contactTel: 'Tél',
      addr: 'Addr',
      vehicleType: 'Type Véhicule',
      brandIcon: 'Marque',
      editImg: 'Éditer',
      editImgTip: 'Image de recadrage',
      editImgSuccess: 'Succès',
      contactInfo: 'E-mail',
      WebCam:'WebCam'
    },
    userDetail: {
      name: "Détails de l'utilisateur",
      basicInfo: 'Informations de base',
      autoInfo: 'Autorisation',
      holdName: 'Enterprise',
      realName: "Nom d'utilisateur",
      userName: "Identifiant",
      password: 'Mot de passe',
      startTime: 'Heure de début',
      endTime: 'Heure de fin',
      remark: 'Remarque',
      timezone: 'Fuseau horaire',
      checkAll: 'Vérifie tout',
      cancelAll: 'Tout annuler',
      pleaseInputLongUserName: "Le nom d'utilisateur doit comporter au moins 2 caractères",
      pleaseInputUserName: 'Veuillez saisir le nom de connexion',
      pleaseInputPassword: 'Veuillez saisir le mot de passe utilisateur',
      pleaseInputName: 'Veuillez saisir le nom de connexion',
      pleaseSelctStartTime: 'Veuillez sélectionner la date de début',
      pleaseSelectEndTime: 'Veuillez sélectionner la date de fin',
      mobile: 'Mobile'
    },
    bindDevice: {
      placeHolder: "Veuillez saisir l'identifiant de l'appareil",
      tip: "Conseil: le véhicule peut lier plusieurs appareils",
      deviceID: "ID Appareil",
      sim: 'SIM',
      deviceModel: "Modèle d'appareil",
      trackerType: 'Type Traçage',
      remark: 'Remarque',
      selected: 'Choisi',
      search: 'Chercher',
      viewAll: 'Voir tout',
      vehicleName: 'Nom',
      containAll: 'Contenir lié'
    },
    popoverInfo: {
      enterprise: 'Enterprise',
      deviceID: "ID Appareil",
      SIM: 'SIM',
      deviceModel: "Modèle d'appareil",
      remark: 'Remarque',
      modifier: 'Modificateur',
      updateTime: "Temps de mise à jour",
      vehicle: 'Nom',
      queryPswd: 'Mot de passe de requête',
      bingCount: 'Nombre de liens',
      owner: 'Propriétaire',
      ownerMobile: 'Mobile',
      ownerAddress: 'Adresse',
      createUser: 'Créer un utilisateur',
      createTime: 'Créé le ',
      loginName: 'Identifiant',
      serviceEnddate: 'Date de fin de service',
      contacter: 'Contacter',
      mobile: 'Mobile'
    },
    favorite: {
      name: 'Favoris',
      detail: "Détail du véhicule",
      empty: 'Vider',
      export: 'Supprimer Sélectionnés',
      print: 'Imprimer',
      fullScreen: 'Plein écran',
      monitor: 'Surveiller',
      vehicleName: 'Nom',
      peopleName: 'Le nom du propriétaire',
      deviceID: "ID Appareil",
      alarm: 'Alarme',
      statusdes: 'Statut',
      riskStatus: 'Statut du risque',
      operation: 'Opération',
      remove: 'Supprimer',
      onlineDevice: "Câblage du traceur GPS",
      nolineDevice: 'Suivi Véhicules',
      time: 'Temps',
      remark: 'Remarque',
      isAlarm: 'Alarme',
      highRisk: 'Risque élevé',
      middleRisk: 'Risque moyen',
      lowRisk: 'Faible risque',
      tip: 'Conseil',
      text1: 'Effacer la liste?',
      excelName: 'DeviceDetail',
      batteryTip1: 'Suivi des actifs, batterie {number}%',
      battery: 'La batterie',
      removeOne: 'Avez-vous confirmé que vous suiviez plus longtemps ce véhicule: {name}?'
    },
    onlineMonitor: {
      userList: "liste d'utilisateur",
      sendSMS: 'Envoyer le message',
      sendSMSTip: 'Veuillez saisir le numéro de la carte SIM',
      sendSMSError: 'Veuillez saisir le bon numéro de carte SIM',
      favoritePlace: 'POI',
      favoritePlaceTip: 'Veuillez saisir des mots-clés pour rechercher un POI',
      favoriteRegion: 'Région',
      favoriteRegionTip: 'Veuillez saisir des mots-clés pour rechercher la région',
      regionSearch: 'Rect',
      regionSearchTip: 'Dessinez des zones carrées sur la carte pour trouver des véhicules',
      refreshTip: 'Actualiser après {count} secondes',
      refreshAction: 'Pause',
      refreshResume: 'CV',
      ruler: 'Règle',
      save: 'Sauvegarder',
      fullScreen: 'Plein écran',
      emptyMap: 'Effacer la carte',
      batchCommand: "Commande par lots",
      exit: 'Sortir',
      searchEryadian: 'Lieu de risque',
      searchEryadianTip: 'Veuillez saisir des mots clés pour rechercher un lieu à risque',
      distanceTip: 'Cliquez sur le bouton gauche de la souris pour obtenir le point de mesure, et cliquez sur le bouton droit de la souris pour terminer la plage',
      vehicleName: 'Nom',
      deviceName:"Nom de l'équipement",
      speed: 'Vitesse',
      totalMile: 'Kilométrage',
      oil: 'Carburant',
      statsDes: 'Statut',
      gpsTime: 'Heure GPS',
      dataTime: 'Temps de données',
      rcvTime: 'Heure Rcv',
      place: 'Lieu',
      lineFeed: 'Saut de ligne',
      deviceID: "ID Appareil",
      directoin: 'Direction',
      remove: 'Supprimer',
      mile: 'Mile',
      dayMiles: 'Miles de jour',
      allMiles: 'Odomètre',
      gps: 'GPS',
      lbs: 'KG',
      wifi: 'WIFI',
      viewInfo: 'Voir les détails',
      realSence: 'Vue sur la rue',
      gpsHistoryPlay: 'Historique de lecture',
      gpsTrack:'Pister',
      setCommand: 'Définir la commande',
      riskScan: 'Détection des risques',
      alarm: 'Alarme',
      noAlarm: "Pas d'alarme",
      haslineDevice: "Câblage du traceur GPS",
      nolineDevice: 'Suivi Véhicules',
      noRealsenceTip: "Cette position n'a pas de vue sur la rue.",
      unit1: 'km/h',
      unit2: 'km',
      unit3: 'L',
      unit4: '千克',
      panelLink1: 'LBS/WIFI',
      panelLink2: "Détail",
      panelLink3: 'St. View',
      panelLink4: 'Trajectoire',
      panelLink5: 'Commander',
      panelLink6: 'Détection des risques',
      panelLink7: 'Envoyer un msg',
      panelLink8: 'Définir defense',
      panelLink9: 'Annuler defense',
      panelLink10:'Follow',
      panelLink11:'unfollow',
      panelLink12:'Pister',
      panelLink13: '应急预案',
      panelLink14: 'Tire pressure monitoring',
      setSF: 'Définir le succès',
      cancelSF: 'Annuler le succès',
      noLbsTip: "Cet appareil n'a pas d'informations LBS et wifi.",
      lngLat: 'Coordonner',
      removeFavirite: 'Supprimer le POI',
      removeEryadian: 'Supprimer le POI',
      saveMapInfo: 'Position de la carte enregistrée avec succès.',
      noPositionTip: "Cet appareil n'a pas été utilisé",
      regionName: 'Nom de la géo-clôture',
      createTime: 'Créé le ',
      deletePoiTip: 'La suppression du POI {name} a réussi',
      savePoiTitle: 'Veuillez saisir le nom du POI',
      savePoiTip: 'Veuillez saisir',
      savePoiTip2: "Nom d'entrée trop long",
      saveSuccessed: "Sauvegarde réussie",
      savePoiTip3: "Veuillez cliquer sur la carte pour accéder au nouveau POI",
      layers: "Couches",
      layerNormal: "Standard",
      layerZixingche: "Bicyclette",
      layerTraffic: "Trafic",
      layerHumanitarian: "Humanitaire",
      layerSatellite: "Satellite",
      layerGoogle: "Google Map",
      layerBaidu: "Baidu: carte",
      layerTecent: "Tecent: carte",
      monitorAll: "Surveiller tout",
      refresh:"Refresh",
      onRefresh:"Turn on refresh",
      offRefresh:"Turn off refresh",
      monitorAllTip: "Il y a plus de véhicules dans la liste actuelle, seuls 500 véhicules sont chargés.",
      all: "Tout",
      simcard: "Numéro de carte SIM",
      pleaseInputSimcard: "Veuillez saisir le numéro de carte SIM de votre appareil",
      inputSimcardSeeHistory: "Entrez le numéro de la carte SIM pour afficher l'historique",
      alarmType: "Type d'alarme",
      enterprise: "Entreprise",
      refreshUserTree: "Actualiser l'arborescence des utilisateurs",
      fullscreen: "Toggle fullscreeen",
      showAddress: "Afficher l'adresse par défaut",
      peopleName: "Gens",
      vin: "NIV",
      storeName: "Magasin",
      setViewItem: "Définir l'élément de vue",
      searchPlace: "Rechercher un lieu",
      clickRightRemove: "cliquez sur le bouton droit déplacer",
      emptyList: "Vider",
      uName:'Nom',
      uAge:'âge',
      uSex:'genre',
      uMale:'Masculin',
      uFemale:'femelle',
      aName:"Nom de l'Asset",
      aId:'code produit',
      aWeight:'poids',
      aSize:'Taille',
      aColor:'Couleur',
      statusTip1:'mouvement',
      statusTip2:'Flamme',
      statusTip3:'encore',
      statusTip4:'Hors ligne',
      statusTip5:'alarme',
      statusTip6:'Sans GPS',
      statusTip7:'Inutilisé',
      alamStatus1:"En ligne",
      alamStatus2:"alarme",
      alamStatus3:"le total",
      disalarm:"Désarmer l'alarme",
      lastLocation:'Dernier téléchargement',
      gps1:"Fort",
      gps2:"moyen",
      gps3:"faible",
      gsm1:"Fort",
      gsm2:"moyen",
      gsm3:"faible",
      owner:'驾驶员',
      goods:'运输货物',
      departure:'出发地',
      destination:'目的地',
      weight:'重量',
      personInfo:'Person Info',
      assetInfo:'Asset Info',
      tireHight:'Trop haut',
      tireLow:'Trop faible',
      tireStatus1:"Fuite d'air aiguë",
      tireStatus2:"Fuite lente d'air",
      tireStatus3:'Aération',
      tireStatus4:'Faible puissance',
      tireTitle:'Surveillance de la pression des pneus',
      tireTimes:'Temps de surveillance',
      tirePress:'Pression des pneus',
      tireTemp:'Température',
      tireDialogTips1:'Aucune information sur la pression des pneus',
      tireDialogTips2:'Aucune donnée sur les courbes de température et de pression des pneus',
      tireBegin:'Date de début',
      tireIner:'à',
      tireEnd:'Date de fin',
    },
    onlineTrack: {
      vehicleInfo: 'Informations sur le véhicule',
      vehicleNumber: "Nom",
      deviceList: "Liste des périphériques",
      vehicleSelectTip: "Sélectionnez un véhicule",
      placeParams: "Paramètres de relecture",
      viewTrip: "Voir le voyage",
      today: "Aujourd'hui",
      yestoday: "Hier",
      beforeYestoday: "Avant hier",
      threeDay: "3 derniers jours",
      startTime: "Début Hr",
      endTime: "Fin Hr",
      longStop: "Au fil du temps, arrêtez",
      mode: "Mode",
      lineMode: "Ligne",
      pointMode: "Indiquer",
      realTimeMode: "Temps réel",
      historyMode: "L'histoire",
      other: "Options",
      showPoint: "Afficher le point",
      hideZero: "Ignorer 0 km / h",
      referPosition: "Emplacement de référence",
      historyDetail: "Montrer les détails",
      showAddress: "Aff.Adr.Maison",
      showColor: '3 color trace',
      showRealSence: "Vue sur la rue",
      playSpeed: "Vitesse",
      playTrack: "Jouer",
      onPlay: "Sur le jeu",
      orderNumber: "Non.",
      speed: "Vitesse",
      miles: "Kilométrage",
      oil: "Carburant",
      statusDes: "Statut",
      gpst: "GPS",
      rcvt: "Reçu",
      gpsTime: "Heure GPS",
      rcvTime: "Heure Reçu",
      place: "Lieu",
      time: "Temps",
      speedDesc: "{speed} km / h",
      direction: "Direction",
      mile: "Kilométrage",
      currentMile: 'Km Présentement',
      status: "Statut",
      stopFlag: "Drapeau d'arrêt",
      showStopFlag: "Afficher le parking",
      show: "Affichage",
      infoWindow: "InfoWindow",
      trackLine: "TrackLine",
      blod: "Audacieux",
      progress: "Le progrès",
      wrap: "Saut de ligne",
      searchVehicle: "Rechercher un véhicule",
      minute: "Minute",
      lowSpeed: "Faible vitesse",
      highSpeed: "Haute vitesse",
      lowAndHigh: "Bas/Haut",
      normal: "Normal",
      low: "Faible",
      high: "Haut",
      realSenceTip: " Ralentir la lecture lors de l'affichage de la vue de la rue",
      colorTip: '速度<30km/h;红色;速度<60km/h 蓝色;速度>80 绿色',
      historyTrip: "Voyage d'histoire",
      query: "Requete",
      kilo: "KM",
      from: "De",
      to: "À",
      noRealSenceTip: "Il n'y a pas de vue de la rue ici",
      oilUnit: "L",
      stopTime: "Temps d'arrêt",
      stopTip: "Arrêter {time} minutes",
      poiPanel: "POI et clôture",
      poiTab: "POI",
      regionTab: "Clôture",
      circleTab: '圆形围栏',
      rectangleTab: '矩形围栏',
      regionTabLink: "Lier Clôture",
      loadAll: "Tout charger",
      noStopInfo: "Aucune donnée d'arrêt.",
      play: "Jouer",
      pause: "Pause",
      stop: "Arrêter",
      historyRange: "Historique Zone",
      historyTime: "Temps",
      historySpeed: "Vitesse",
      historySearch: "Requete",
      historyOnsearch: "Chargement",
      historyTip: "Veuillez sélectionner la zone dans la liste déroulante sur la carte.",
      historySuccess: "Trouvez {count} voitures.",
      showInfo: "Ouvrir infoWindow",
      isGps: "Est-ce que le GPS",
      yes: "Oui",
      showMore: "Suite",
      hideMore: "Cacher",
      measure: "Mesure",
      showPath: "Afficher le chemin",
      homeAndCom: "Afficher l'adresse du domicile",
      noHomeAndComPosition: "Aucune adresse de domicile et aucune adresse d'entreprise",
      noComPosition: "Aucune adresse d'entreprise",
      noHomePosition: "Aucune adresse de domicile",
      goPosition: "Définir l'adresse",
      homeAddress: "Adresse du domicile",
      companyAddress: "Adresse de la société",
      lnglat: "Lnglat",
      exportData: "Supprimer Sélectionnés",
      exportFileName: "Trackdata_ {name} _ {start} _ {end} _. Xlsx",
      ADSpeed:"ADSpeed",
      collision:"collision",
      drop:"tomber",
      GPSFlag:"GPSFlag",
      Overturn:"Renverser",
      SharpTurn:"Virage en épingle",
      Attachment:"Attachement",
      ubiRollover:'rollover',
      ubiCollision:'collision',
      ubiDecelerate:'harsh accelerate',
      ubiAccelerate:'harsh decelerate',
      ubiTurnRight:'Sharp right turn',
      ubiTurnLeft:'Sharp left turn',
      realTracking:'Suivi en temps réel',
      timeLimitTips:"Sélectionnez l'heure (jusqu'à 30 jours peuvent être interrogés)"
    },
    regionManage: {
      name: 'Geo-fence Manage',
      totalTip: "Le total{count}",
      includeChild: "Inclure le subordonné",
      modify: "Modifier",
      add: "Nouveau",
      deflete: "Effacer",
      viewDetail: "Voir les détails",
      contactRegion: "Lier",
      cancelContact: "Délier",
      addTip: "Veuillez cliquer sur le schéma de dessin de la carte, double-cliquez ou cliquez avec le bouton droit de la souris pour terminer.",
      regionSetting: "Configuration de la géo-clôture",
      start: "Début",
      regionids:"Nom de la géo-clôture",
      recordType: "Type d'enregistrement",
      validTime: "Plage de temps valide",
      triggerAlarm: "Déclencher l'alarme",
      regionLimit: "Vitesse limite",
      limitTip: "km / h",
      enterTip: "Entrez le conseil Geo-fence",
      leaveTip: "Quitter Geo-fence Tip",
      vehiceBindList: "Liste de liaison de véhicule",
      regionTip: "Les conseils de région d'entrée et de sortie ne prennent en charge que le modèle complet VDF",
      viewBindDevice: "Afficher l'appareil lié",
      bindCountTip: "Total {count} lié",
      clear: "Dégager",
      clearTip: "Effacez l'ancienne géo-clôture et dessinez à nouveau",
      drawAgain: "Dessiner à nouveau",
      selectVehicle: "Sélectionnez un véhicule",
      enterRegion: "Entrez Geo-fence",
      leaveRegion: "Quitter Geo-fence",
      enterLeaveRegion: "Entrez et quittez Geo-fence",
      enterLeaveRegionNoAction: "Entrez et laissez pas enregistrer",
      vehicleids:"Nom",
      deviceID: "ID Appareil",
      lineType: "Type d'appareil",
      yes: "Oui",
      no: "Non",
      limitTip2: "{speed} limite km / h",
      ioTypeList: ['Entrez et quittez Geo-fence', 'Enter Geo-fence', 'Leave Geo-fence'],
      deleteTip: 'Supprimer {name}?',
      deleteSuccessed: "Suppression réussie",
      selectTip: "Veuillez sélectionner Geo-fence",
      selectTip2:"Veuillez sélectionner l'itinéraire",
      saveSuccessed: "Sauvegarde de succès",
      enterRegionTip: "Veuillez saisir le nom de la géo-clôture",
      enterRoadTip: '请输入线路名称',
      jiechu: "Supprimer",
      nodata: "Pas de données",
      viewAll: "Voir tout",
      addRoad: "Ajouter une route",
      roadLimit: "Vitesse limite",
      roadWidth: "Largeur de la route",
      roadSetting: "Réglage de la route",
      modifyRoad: "Modifier la route",
      roadName: "Nom de la route",
      totalRoadTip: "Le total{total}",
      endEdit: "Compléter",
      editPoly: "Modifier",
      unit1:'Unité : m',
      regionLimit2:'最大滞留时间',
      triggerAlarm2:'滞留设置',
      limitTip2: '分钟',
    },
    placeManage:{
      name:"Informations sur l'appareil",
      deviceName:"Nom de l'équipement",
      deviceList:"Liste des périphériques",
      parma:"Paramètre de requête",
      placeHolderSearch:"Rechercher un appareil",
      placeHolderFind:"Veuillez sélectionner un appareil",
      today:"De nos jours",
      yestoday:'hier',
      lastday:"Avant hier",
      day1:"3 derniers jours",
      begin:'Heure de départ',
      end:"Heure de fin",
      placeHolderTime:"Choisissez la date et l'heure",
      stay:'Reste le temps',
      lt:'dépasser',
      min:'minute',
      modes:'Mode requête',
      mode1:"Vérifier souvent arrêter",
      mode2:"Vérifiez la ligne",
      btn:'Renseigner',
      form:'De',
      to:"À",
      roadDetail:"Détails de l'itinéraire",
      export:'Exportation',
      pointerDetail:'Détails du séjour',
      clock:"temps",
      address:"positionner",
      show:'affichage',
      showHome:"Lieu d'origine",
      showCompany:"Emplacement de la société",
      showAll:"Afficher l'image complète",
      layout1:"Imagerie par satellite",
      layout2:"Réseau routier",
      layout3:"Vue sur la rue",
      startTime:"heure de départ",
      endTime:"Heure de fin",
      stayTimes:"Durée du séjour",
      roadAll:"Tout le voyage",
      length:'Longueur totale',
      startPointer:"point de départ",
      endPointer:"finir",
      lonlat:"Latitude et longitude",
      location:"adresse",
    },
    photo: {
      search: 'Chercher',
      vipTip: "Uniquement appareil VIP",
      last1Day: "Récent 1 jour",
      last2Days: "Récent 2 jours",
      last3Days: "3 derniers jours",
      vehicleName: "Véhicule",
      searchTip: "{Count} images au total",
      startTime: "Début",
      endTime: "Finir",
      takePhoto: "Prendre une photo",
      photoCount: "Compter",
      photoInterval: "Intervalle",
      photoChnnel: "Canal",
      sendCommand: "Envoyer la commande",
      setting: "Réglage",
      paraType: "Taper",
      photoSize: "Résolution",
      runTakePhoto: "Normal",
      conditionPhoto: "Événement",
      size: "Taille",
      direction: "Direction",
      place: "Lieu",
      speed: "Vitesse",
      unUsed: "Inutilisé",
      sec: "Seconde",
      chnnel1: "Canal 1",
      chnnel2: "Canal 2",
      chnnel3: "Canal 3",
      chnnel4: "Canal 4",
      selectVehicleTip: "Veuillez sélectionner un véhicule",
      maxCountWarn: "Il y a trop d'images qui répondent à vos critères de requête, le système ne renverra que les 300 dernières images.",
      sendSuccessed: "Envoi réussi",
      vehicle: "Véhicule",
      keyDaojing: "Alarme",
      keySpeed: "Arrêter",
      keyDoorOpen: "Porte ouverte",
      keyDoorClose: "Porte Fermée",
      keyCus: "Porter",
      keyNon: "Vider",
      keyAccOn: "ACC sur",
      keyAccOff: "ACC désactivé",
    },
    notify:{
      title:'Titre',
      BeginTime:"Temps d'activation",
      ShowLevel:'Restrictions de visualisation des utilisateurs individuels',
      UpdateTime:'Temps de mise à jour',
      CreateTime:"Temps de creation",
      UserName:"Créer un utilisateur",
      operation: 'en fonctionnement',
      preview:'Aperçu',
      delete:'effacer',
      levelTips:"Restriction, niveau",
      verson1:'supprimé',
      verson2:'Actuellement activé',
      verson3:'Programmé',
      verson4:'ancienne version',
      tip1:"Augmenter l'annonce",
      tip2:"supprimer sélectionnée"
    },
    changeMap: {
      name: 'Changer de carte',
      google: 'Google Map',
      amap: 'AMap',
      bmap: 'Baidu Map',
      qqmap: 'Tencent Map',
      bing: 'Bing Map'
    },
    homeAlarm: {
      name: "Détail de l'alarme",
      alarmType: "Type d'alarme",
      query: "Requete",
      export: "Supprimer Sélectionnés",
      tip: "Afficher uniquement le périphérique en ligne et d'alarme.",
      vehicleids:"Plaque Véhicule",
      deviceID: "ID Appareil",
      alarmTime: "Heure de l'alarme",
      alarmPlace: "Lieu d'alarme",
      alarmType1: "Alarme de basse tension",
      alarmType2: "Alarme de suppression",
      alarmType3: "Éteindre l'alarme",
      alarmType4: "Alarme de zone",
      alarmType5: "Tout hors ligne",
      alarmType6: "Alarme fractionnée",
      alarmType7: "Hors province",
      alarmType8: "Endroit à haut risque",
      alarmType9: "Alarme de niveau de batterie faible",
      alarmType10:'Alarme panne de courant',
      alarmType11: "Hors de l'alarme de clôture",
      alarmType12: "Alarme d'itinéraire de départ",
      alarmType13:'Alarme de chute',
      alarmType14:'Alarme de collision',
      alarmType15:"Alarme d'urgence",
      alarmType16:'超速报警',
      alarmType17:'禁行时间段报警',
      alarmType18:'滞留车辆报警',
      tip2: "Pas de données.",
    },
    dialogCellAndLbs: {
      name: 'Emplacement LBS / WIFI',
      gpsPoint: "Localisation GPS",
      wifiPoint: "Emplacement WIFI",
      lbsCenter: "Centre LBS",
      distance: "Distance",
      distanceWithLbs: "Distance avec emplacement LBS",
      distanceWidthWifi: "Distance largeur emplacement WIFI",
      emtpy: "(nul)", 
    },
    dialogBatchCommand: {
      sendCommand: 'Envoyer la commande',
      commandHistory: "Historique des commandes",
      selectVehicle: "Sélectionnez un véhicule",
      selectDevices: "Appareil total {count}",
      hasSelect:'Selected',
      selectType: "Protocole de l'appareil",
      selectCommand: "Nom de la commande",
      setOptions: "Définir les paramètres",
      sendButtonText: "Envoyer",
      commandName: "Nom de la commande",
      commandContent: "Contenu de la commande",
      commandDeviceCount: "Nombre de périphériques",
      Resend: "Renvoyer",
      successRate: "Taux de réussite",
      time: "Temps",
      options: "Options",
      queryTime: "Heure de la requête",
      queryButton: "Requete",
      recentUse: "Récemment utilisé",
      totalCommand: "Commande totale",
      usuallyCommand: "habituellement",
      moreCommand: "Suite",
      vehicleName: "Plaque du véhicule",
      deviceID: "ID Appareil",
      sendStatus: "Envoyer le statut",
      refresh: "Rafraîchir",
      query: "Requete",
      selectedList: "Liste sélectionnée",
      wireType: "Type d'appareil",
      isOnline: "En ligne ou pas",
      allResend: "Tous renvoyés",
      view: "Vue",
      reSend: "ReSendAll",
      sendDetail: "Envoyer le détail",
      alreadySend: "Déjà renvoyer",
      reSendAll: "Tout renvoyer",
      reSendChecked: "Renvoyer vérifié",
      sending: "Envoi en cours...",
      sendSuccessed: "Envoyer le succès",
      sendError: "Envoyer une erreur. Veuillez réessayer.",
      unableMsg: "Trop de sélections",
      expand: "Développer",
      collapse: "Effondrer",
      line: "Traqueur GPS",
      offline: "Tracker GPS sans fil",
      unsupported: "Protocole de périphérique non pris en charge",
      sleepErroMsg:"Le paramètre est trop petit. Il est recommandé de régler la plage de paramètres de 30 s à 300 s",
      sleepErroMsg2:"Le paramètre est trop grand. Il est recommandé de régler la plage de paramètres de 30 s à 300 s",
      intervalErroMsg:"Le paramètre est trop petit. Il est recommandé de régler la plage de paramètres de 5 minutes à 43200 minutes",
      intervalErroMsg2:"Le paramètre est trop grand. Il est recommandé de régler la plage de paramètres de 5 minutes à 43200 minutes",
    },
    dialogMessage: {
      name: 'Envoyer le message',
      selectVehicle: "Sélectionnez un véhicule",
      searchInfo: "Titre",
      searchBody: "Un message",
      queryPlaceHolder: "Veuillez saisir le mot-clé",
      bodyPlaceHolder: "veuillez entrer le contenu",
      sendButton: "Envoyer",
      saveAndSend: "Enregistrer et envoyer",
      pleaseSelectVehicle: "Sélectionnez un véhicule",
      sendSuccessed: "Envoyer le succès",
      confirmDelete: "Voulez-vous vraiment supprimer ces informations?",
      deleteSuccessed: "Supprimer le succès",
    },
    dialogSetting: {
      vehicleNumber: 'Plaque Véhicule',
      tableData: 'Colonne de table',
      changeTip: "* Les paramètres prennent effet après l'actualisation de la page",
      demoData: {
        PeopleName: 'Jack',
        VehicleName: 'AA-001',
        SIM2: '861906228999018',
        SIM: '1440118228887',
        VIN: 'LHRTY4852D8478254',
        VehiclePriceName: '$66000',
        TrackerTypeName: 'W',
      },
      tips:"Sauvegarde réussie. Voulez-vous recharger la page?",
    },
    moveParent: {
      name: "Déplacer l'utilisateur",
      tip: "La migration des véhicules fera migrer le dispositif de liaison de véhicule en même temps",
      moveTarget: "Migrer des objets",
      oldParent: "Ancien supérieur",
      newParent: "Nouveau supérieur",
      selectParent: "Veuillez sélectionner un nouveau supérieur",
      selectUser: "Veuillez sélectionner un utilisateur",
      changeSuccessed: "Modifier le succès",
      activeSuccessed: "Activation réussie",
      activeFail:"Activation echouée",
      total: "Le total{total}",
      inputIds: "ID de périphérique d'entrée",
      vipDate: "Mise à jour d'expiration VIP",
      vipExpiring:"Date d'expiration du VIP"
    },
    reportQuery: {
      allReport: 'Tous les rapports',
      condition: "Paramètre de rapport",
      result: "Résultats de recherche",
      btnQuery: "Chercher",
      options: "Option",
      export: "Supprimer Sélectionnés",
      fullScreen: "Plein écran",
      expand: "Développer",
      noData: "Pas de données",
      day1: "Aujourd'hui",
      day2: "Hier",
      day3: "Avant hier",
      day12: "2 derniers jours",
      day13: "3 derniers jours",
      day14: "Les 7 derniers jours",
      week1: "Semaine",
      week2: "La semaine dernière",
      week3: "La semaine avant la dernière",
      week12: "2 dernières semaines",
      week13: "3 dernières semaines",
      month1: "Mois",
      month2: "Le mois dernier",
      month3: "Le mois avant dernier",
      month12: "2 derniers mois",
      month13: "3 derniers mois",
      time: "Temps",
      temperature: "Température",
      noavailable: "Aucun rapport disponible",
    },
    spread: {
      full: 'Plein écran',
      sum: 'Le total',
      unit: '',
      exit: 'Sortir'
    },
    reportMenu: {
    '基础报表': 'Rapport de base',
    '里程报表':"Rapport kilométrique",
    '油耗报表':"Rapport de consommation de carburant",
    '警情报表':"Rapport Alarme",
    '温度报表':"Rapport de température",
    '超速报表':"Rapport de survitesse",
    '行驶状态报表':"Rapport sur l'état de la conduite",
    '区域报表':"Rapport de géo-clôture",
    '统计报表':"bulletin d'analyse",
    '其他':"Autre",
    '用户资料报表':"Rapport de données utilisateur",
    '车辆资料报表':"Rapport de données du véhicule",
    '人物资料报表':'Rapport de données de personnage',
    '物品资料报表':"Rapport sur les données de l'Asset",
    '里程日统计表':"Statistiques de kilométrage quotidien",
    '里程月统计表':"Statistiques mensuelles de kilométrage",
    '里程汇总表':"Récapitulatif du kilométrage",
    '空重车里程时间统计表':"Rapport sur les statistiques de temps de kilométrage du véhicule de chargement et de déchargement",
    '油量曲线表':"Rapport sur la courbe de carburant",
    '油耗明细表':"Rapport détaillé sur la consommation de carburant",
    '报警明细表':"Rapport détaillé Alarmes",
    '处警明细表':"Rapport de gestion des alarmes",
    '报警日统计表':"Statistiques d'alarmes quotidiennes",
    '报警月统计表':"Statistiques d'alarmes mensuelles",
    '温度曲线表':"Rapport de courbe de température",
    '超速明细表':"Rapport de survitesse",
    '超速日统计表':"Rapport quotidien de survitesse",
    '超速月统计表':"Rapport mensuel de survitesse",
    'ACC时间统计表':"Rapport horaire ACC",
    '车辆工作情况报表':"Rapport sur l'état des travaux du véhicule",
    '车辆驶停情况表':"Rapport de conduite et de stationnement du véhicule",
    '进出区域统计表':"Rapport Statistique Géo-Clôture",
    '进出区域明细表':"Rapport détails Géo-Clôture",
    '车辆趟次明细表':"Rapport de voyage",
    '车辆趟次统计表':"Statistiques de voyage",
    '轨迹报表':"Rapport de Suivi",
    '时刻状态报表':"Rapport sur l'état de l'heure",
    '车门状态报表':"Rapport d'état de la porte",
    '目标巡检报表':"Cibler Rapport d’inspection",
    '公共信息报表':"Rapport d'information publique",
    '无线设备电量报表':"Rapport Niveau Batterie",
    "车辆轨迹报表": "Vehicle trace report",
    "时段里程报表": "Time slot mileage report",
    '设备激活报表':"Rapport d'activation de l'appareil",
    '围栏报警报表':"Rapport d'alarme de clôture",
    '防拆报表':"Rapport de démolition",
    '最后定位报表':"Rapport de localisation final",
    '停驶报表':"Rapport de suspension",
    '电量统计':"Statistiques d'électricité",
    '设备分离报表':"Rapport fractionné",
    '离线报警表':"Rapport hors ligne",
    '长时间未回家报表':"Rapport de sortie du domicile",
    '长时间未回公司报表':"Quitter le rapport d'entreprise",
    '出省报表':"Rapport de la province extérieure",
    '夜间用车报表':"Rapport de voiture de nuit",
    '里程统计报表':"Rapport sur les statistiques de kilométrage",
    '超速明细报表':"Rapport détaillé de survitesse",
    '设备资料报表':"Rapport détaillé de l'appareil",
    '其他报警报表':"Autre rapport d'alarme",
    '拆机报警报表':"Rapport d'alarme de chute",
    '长时间停驶报表':"Rapport d'arrêt long",
    '进出围栏报表':"Rapport de clôture géographique",
    '工单统计报表':"Rapport d'installation",
    '事故报警报表':"Rapport de plantage",
    'UBI明细表':'Horaire UBI',
    '摩托车管理报表':"Rapport de gestion de la moto",
    '服务费到期报表':"Rapport de frais de service dû",
    '续费记录报表': "Rapport d'enregistrement de renouvellement",
    '新增激活报表':"Nouveau rapport d'activation"
    },
    funMenu: {
      'GPS监控': 'GPS monitor',
      '在线监控':"Moniteur en ligne",
      '位置服务':'Service de localisation',
      '系统信息':'Message système',
      '报表中心':'Centre de rapports',
      '监听':"Écouter",
      '显示SIM':"Afficher la carte SIM",
      '激活':"Activer",
      '线路管理':'Gestion de ligne',
      '省界管理':'Gestion des frontières',
      '业务分布图':'Répartition des entreprises',
      '轨迹回放':'Trajectoire',
      '拍照浏览':'Parcourir les photos',
      '车辆管理':'Gestion des équipements',
      '风控管理':'Gestion des risques',
      '二押点':'Deuxième pari',
      '工单系统':"Système d'ordre de travail",
      '工单管理':'Gestion des bons de travail',
      '技师管理':'Technicien Gestion',
      '导出':'Exportation',
      '审核':'Audit',
      '审核后编辑':'Modifier après examen',
      '保单管理':'Gestion des politiques',
      '录入保单':'Entrez la politique',
      '锁车':"Verrouiller la voiture",
      '解锁':"Ouvrir",
      '解除报警':"Relâcher l'alarme",
      '目标图像':"image cible",
      '轨迹查询':"Suivi de la requête",
      '电子围栏':"Géo-clôture",
      '体验日志':"Journal des visiteurs",
      '发送信息':"Envoyer le message",
      '发送指令':"Envoyer la commande",
      '发送短信':"Envoyer un SMS",
      '汽车金融':"BHPH",
      '风控评估':"Évaluation du risque",
      '资产分析':"Analyse des actifs",
      '暗访追车':"Poursuite en voiture inopinée",
      '找常停点':"Trouvez des arrêts fréquents",
      '灰名单':"Liste grise",
      '新增':"Nouveau",
      '删除':"Effacer",
      '编辑':"Éditer",
      '查询':"Requete",
      '续费充值':"Améliorer",
      '到期提醒':"Rappel d'expiration",
      '自助续费':"Recharge automatique",
      '我的钱包':"Mon portefeuille",
      '转赠':"Donner à",
      '信息管理':"Gérer les informations",
      '用户管理':"Gérer l'entreprise",
      '账号管理':"Gérer le compte",
      '设备管理':"Gérer l'appareil",
      '重要信息':"Une information important",
      '付费有效期':"Période de validité du paiement",
      '默认VIP(4年)':'Default VIP(4 years)',
      '服务截止日期':"Date de fin du service",
      '免费查看7天轨迹':"Vue de piste gratuite de 7 jours",
      '安装人员':"Installateur",
      '安装任务':"Tâche d'installation",
      '报表查询':"Rapports",
      '操作日志':"Journal des opérations",
      '基础报表':"Rapport de base",
      '里程报表':"Rapport kilométrique",
      '油耗报表':"Rapport de consommation de carburant",
      '警情报表':"Rapport Alarme",
      '温度报表':"Rapport de température",
      '超速报表':"Rapport de survitesse",
      '行驶状态报表':"Rapport sur l'état de la conduite",
      '区域报表':"Rapport de géo-clôture",
      '统计报表':'Rapport statistique',
      '摩托车管理报表':'Rapport de gestion de la moto',
      '服务费到期报表':'Rapport de frais de service dû',
      '续费记录报表':"Rapport d'enregistrement de renouvellement",
      '新增激活报表':"Nouveau rapport d'activation",
      'UBI明细表':'Horaire UBI',
      '设防/撤防报表':'Rapport armé/désarmé',
      '工单统计报表':"Rapport de statistiques d'ordre de travail",
      '其他':"Autre",
      '用户资料报表':"Rapport de données utilisateur",
      '里程日统计表':"Statistiques de kilométrage quotidien",
      '里程月统计表':"Statistiques mensuelles de kilométrage",
      '里程汇总表':"Récapitulatif du kilométrage",
      '空重车里程时间统计表':"Statistiques de temps de kilométrage du véhicule de chargement et de déchargement",
      '油量曲线表':"Rapport de courbe de niveau de carburant",
      '油耗明细表':"Rapport de consommation de carburant",
      '报警明细表':"Rapport détaillé Alarmes",
      '处警明细表':"Liste de gestion des alarmes",
      '报警月统计表':"Statistiques d'alarmes mensuelles",
      '温度曲线表':"Liste des courbes de température",
      '超速明细表':"Liste de survitesse",
      '超速日统计表':"Rapport de vitesse",
      '超速月统计表':"Statistiques mensuelles de survitesse",
      'ACC时间统计表':"Rapport horaire ACC",
      '车辆工作情况报表':"Liste des statuts de travail du véhicule",
      '车辆驶停情况表':"Rapport de stationnement et de conduite du véhicule",
      '进出区域统计表':"Rapport Statistique Géo-Clôture",
      '进出区域明细表':"Liste de géo-clôture",
      '车辆趟次明细表':"Liste des voyages",
      '车辆趟次统计表':"Statistiques de voyage",
      '轨迹报表':"Rapport de Suivi",
      '时刻状态报表':"Rapport sur l'état de l'heure",
      '车门状态报表':"Rapport d'état de la porte",
      '目标巡检报表':"Cibler Rapport d’inspection",
      '公共信息报表':"Rapport d'information publique",
      '无线设备电量报表':"Rapport Niveau Batterie",
      '消费':"Consommer",
      "VIP管理": "VIP",
      "升级VIP": "Upgrade VIP",
      "公告管理": "Notice Manage",
      "资料管理": "Data Manage",
      "风控名单": "Risk List",
      "车辆资料报表": "Vehicle data report",
      '人物资料报表':'Rapport de données de personnage',
      '物品资料报表':"Rapport sur les données de l'Asset",
      "车辆轨迹报表": "Vehicle trace report",
      "报警日统计表": "Alarm daily report",
      "时段里程报表": "Time slot mileage report",
      '设备分离报表':"Rapport fractionné",
      '离线报警表':"Rapport hors ligne",
      '长时间未回家报表':"Rapport de sortie du domicile",
      '长时间未回公司报表':"Quitter le rapport d'entreprise",
      '出省报表':"Rapport de la province extérieure",
      '设备激活报表':"Rapport d'activation de l'appareil",
      '围栏报警报表':"Rapport d'alarme de clôture",
      '防拆报表':"Rapport de démolition",
      '最后定位报表':"Rapport de localisation final",
      '停驶报表':"Rapport de suspension",
      '电量统计':"Statistiques d'électricité",
      '夜间用车报表':"Rapport de voiture de nuit",
      '里程统计报表':"Rapport sur les statistiques de kilométrage",
      '超速明细报表':"Rapport détaillé de survitesse",
      '设备资料报表':"Rapport détaillé de l'appareil",
      '其他报警报表':"Autre rapport d'alarme",
      '拆机报警报表':"Rapport d'alarme de chute",
      '长时间停驶报表':"Rapport d'arrêt long",
      '进出围栏报表':"Rapport de clôture géographique",
      '批量指令':"Commande batch",
      '查询电子围栏':"Requête Geo",
      '事故报警报表':"Rapport de plantage",
      'DVR视频':'DVR',
      '摩托车管理报表':"Rapport de gestion de la moto",
      '服务费到期报表':"Rapport de frais de service dû",
      '续费记录报表': "Rapport d'enregistrement de renouvellement",
      '新增激活报表':"Nouveau rapport d'activation"
    },
    reportDetail: {
      '用户名称': "Nom d'utilisateur",
      '信用等级':"cote de crédit",
      '营业执照':"Licence d'exploitation",
      '传真号码':"Numéro de fax",
      '24小时联系电话':"Numéro de téléphone 24h / 24.",
      '联系人':"Contacts",
      '邮政编码':"code postal",
      '联系地址':"Adresse de contact",
      '状态描述':"Description de l'état",
      '服务截止日期':"Fin du Service",
      '设备ID':"ID Appareil",
      '车牌/设备ID':"Plaque Véhicule / ID Appareil",
      '车牌/设备ID/车架号/工单号':"Plaque du véhicule / ID de l'appareil / VIN / WONO",
      '开始日期':"Date de début",
      '结束日期':"Date de fin",
      '统计日期':"Date statistique",
      '所属单位':"Entreprise",
      '牌号':"Plaque Véhicule",
      '里程(公里)':"Kilométrage (km)",
      '开始时间':"Heure de début",
      '结束时间':"Heure de fin",
      '报警类型':"Type d'alarme",
      '显示位置':"Afficher Position",
      '报警时间':"Heure de l'alarme",
      '编号':"numéro",
      '速度(KM/H)':"Vitesse (KM / H)",
      'GPS状态描述':"Description de l'état du GPS",
      'GPS时间':"Heure GPS",
      '经度':"longitude",
      '纬度':"latitude",
      '位置':"position",
      '状态类型':"Type de statut",
      '持续时间(分钟)':"Durée (minutes)",
      '驾驶员':"Chauffeur",
      'ACC状态':"Statut Acc",
      '持续时长':"Durée",
      '里程':"kilométrage",
      '总里程':"Kilométrage total",
      '油耗':"Consommation d'essence",
      '起点经度':"Longitude de départ",
      '起点纬度':"Latitude de départ",
      '终点经度':"Longitude de fin",
      '终点纬度':"Latitude de destination",
      '起点位置':"Position de départ",
      '终点位置':"Endroit de fin",
      '速度≥':"Vitesse ≥",
      '接收时间':"Heure Reçu",
      '方向':"direction",
      '里程(KM)':"Kilométrage (KM)",
      '油量(L)':"Niveau de carburant (L)",
      '状态':"statut",
      '状 态':"statut",
      '定位':"Emplacement",
      '日期':"Date",
      '离线时长':"Hors ligne Depuis",
      'SIM卡号':"# Carte SIM",
      '设备型号':"Type d'équipement",
      'SIM有效期':"Période de validité de la carte SIM",
      '最后在线':"Dernière connexion",
      '最后定位':"Emplacement final",
      '最后状态':"Statut final",
      '所属用户':"Propriétaires",
      '用户联系人':"Contacts",
      '用户电话':"Téléphoner",
      '安装日期':"date d'installation",
      '机主姓名':"Nom",
      '机主电话':"Téléphone principal",
      '车牌编号':"Numéro de plaque d'immatriculation",
      '车牌号码':"Numéro de véhicule",
      '开始年月':"Date de début",
      '结束年月':"Date de fin",
      '年月':"Date",
      '处警内容':"Contenu",
      '定位时间':"Heure GPS",
      '车牌':"Véhicule",
      '报警名称':"Nom de l'alarme",
      '处警时间':"Heure de l'alarme",
      '处警人':"Gérer les personnes",
      '空车里程(KM)':"Kilométrage à vide (KM)",
      '空车时间(分钟)':"Temps vide (minute)",
      '重车里程(KM)':"Kilométrage lourd (KM)",
      '重车时间(分钟)':"Temps lourd (minute)",
      '时 间':"Temps",
      '时间':"Temps",
      '油量(升)':"Huile (litre)",
      '变化类型':"Changer le type",
      '开始油量(升)':"De l'huile (litre)",
      '结束油量(升)':"À l'huile (litre)",
      '变化油量(升)':"Changer l'huile (litre)",
      '类型':"Taper",
      '开始里程(公里)':"Kilométrage de départ (KM)",
      '行驶里程(公里)':"Kilométrage du voyage (KM)",
      '劫警':"Rob",
      '盗警':"Voler",
      '超速报警':"Haute vitesse",
      '低速报警':"Faible vitesse",
      '疲劳驾驶':"Fatigué",
      '进范围报警':"Entrée Géo-Clôture",
      '出范围报警':"Quitter Geo Fence",
      '超出线路报警':"Au-dessus de la route",
      '剪线报警':"Ligne de coupe",
      '油量上升异常报警':"Exception de hausse du pétrole",
      '油量异常下降报警':"Exception de baisse du pétrole",
      '天线断路报警':"Rupture d'antenne",
      '搅拌时间过长':"Mélange long",
      '非法时间卸料':"Déchargement illégal",
      '其它':"Autres",
      '报警总数':"Total des alarmes",
      '单位':"Compagnie",
      '最高速度(KM/H)':"Vitesse maximale (km / h)",
      '超速里程(KM)':"Vitesse excessive (km)",
      '开始位置':"Lieu Départ",
      '结束位置':"Lieu Arrivée",
      '当日最高速度(KM/H)':"Vitesse maximale quotidienne",
      '超速总时长':"Temps total de survitesse",
      '当日超速次数':"Temps de survitesse",
      '熄火':"Acc off",
      '启动怠速':"Inactif",
      '启动行驶':"Acc on",
      '总时长':"Temps total",
      '第一次启动':"Première mise en service",
      '最后一次熄火':"Dernier accès",
      '第一次启动位置':"Premier accès",
      '最后一次熄火位置':"Dernier accès",
      'acc状态':"Statut Acc",
      '区域名称':"Nom de la clôture géographique",
      '进区域次数':"Entrez les heures de clôture",
      '出区域次数':"Quitter les temps de clôture",
      '进出总次数':"Entrer ou quitter les heures de clôture",
      '进出类型':"Entrée ou Sortie",
      '当前油量(L)':"Ancien actuel (L)",
      '当前里程(KM)':"Kilométrage actuel (KM)",
      '出区':"Quitter la clôture",
      '进区':"Entrez dans la clôture",
      '上次出区时间':"Heure du dernier congé",
      '进区时间':"Entrez l'heure",
      '出区时间':"Temps de congé",
      '途中时长':"Durée",
      '途中里程(KM)':"Kilométrage",
      '区内停留时间':"Arrêtez-vous dans la clôture",
      '起始点':"Lieu Départ",
      '到达点':"Lieu Arrivée",
      '趟次数':"Nombre de voyages",
      '持续时间≥X分钟':"Durée min",
      '发送时间':"Envoyer l'heure",
      '信息内容':"Envoyer le message",
      '接收':"Recevoir",
      '操作单位':"Nom de l'opération",
      '操作员':"Exploiter l'utilisateur",
      '电量低于(单位%):':"Batterie maximale (%)",
      '剩余电量':"Reste de la batterie",
      '车主姓名':"Nom du véhicule",
      '车主电话':"Téléphone du propriétaire",
      '当月最高速度(KM/H)':"Vitesse maximale mensuelle",
      '当月超速次数':"Décompte mensuel de survitesse",
      '车辆编号':"Code du véhicule",
      '全部':"Tout",
      '加油':"Charge de carburant",
      '异常':"Exception",
      '是':"Oui",
      '否':"Non",
      '全部类型':"Tous les types",
      '拆机报警':"Alarme de chute",
      '低油量报警':"Alarme de bas niveau d'huile",
      '围栏报警':"Sortie de clôture",
      '电池低电压报警':"Alarme de basse tension de la batterie",
      '定位模块故障报警':"Alarme de défaillance du module de positionnement",
      '定位天线断路报警':"Alarme de circuit ouvert de l'antenne de positionnement",
      '定位天线短路报警':"Alarme de court-circuit de l'antenne de positionnement",
      '断电报警':"Alarme de panne de courant",
      '紧急报警':"Alarme d'urgence",
      '进入二押点':"Entrez le deuxième pari",
      '进围栏报警':"alarme lors entrée dans la clôture",
      '开盖报警':"Alarme d'ouverture du couvercle",
      '碰撞报警':"Avertissement de collision",
      '疲劳驾驶报警':"Alarme de fatigue lors conduite",
      '区域超速报警':"Alarme de survitesse de zone",
      '通讯受干扰报警':"Alarme d'interférence de communication",
      '震动报警':"Alarme de vibration",
      '震动传感器故障':"Panne du capteur de vibrations",
      '主电低压报警':"Alarme de basse pression d'équipement",
      '低电压报警':"Alarme basse tension",
      '出省报警':"Hors province",
      '油量异常上升报警':"Exception de montée du pétrole",
      '非法卸料报警':"Déchargement illégal",
      '超时未返回':"Délai de rappel",
      '电瓶拆除报警':"Démontage de la batterie",
      '天线短路报警':"Court-circuit d'antenne",
      'GSM模块故障':"Panne du module GSM",
      'GPS接收机故障':"Défaut du récepteur GPS",
      '总线故障':"Panne de bus",
      '密码输入错误':"Mot de passe incorrect",
      '非法动车报警':"Mouvement illgeal",
      '开箱报警':"Alarme ouverte",
      '启动怠速 (速度=0)':"Ralenti (vitesse = 0)",
      '启动行驶 (速度>0)':"Ralenti (vitesse> 0)",
      '启动(速度>=0)':"Run (vitesse> 0)",
      '进区域':"Entrée Géo-Clôture",
      '出区域':"Sortie Géo-Clôture",
      '门开':"Porte ouverte",
      '门关':"Porte Fermée",
      '参考油耗':"Consommation de carburant de référence",
      '参考百公里油耗':"Référence à une consommation de carburant de 100 km",
      '耗油量':"Consommation de carburant",
      '实际百公里油耗':"Consommation réelle de carburant de 100 kilomètres",
      '加油量':"Volume de carburant",
      '异常油量(升)':"Volume d'huile anormal (litre)",
      '参考耗油量':"Consommation de carburant de référence",
      '变化油量':"Volume d'huile variable",
      '开始时段':"Heure de début",
      '结束时段':"Heure de fin",
      '时段起始时间':"De",
      '时段结束时间':"À",
      '低电量报警': 'Low battery level alarm',
      '移位报警': 'Displacement alarm',
      '侧翻报警':'Rollover alarm',
      '安装异常报警': 'Install error alarm',
      '滞留报警': 'Overstay alarm',
      '上线报警': 'Online alarm',
      '设防报警': 'Defence alarm',
      '疲劳驾驶预警': 'Fatigue driving alarm',
      '二押点报警': 'Endroit à haut risque',
      '低油量报警': 'Low fuel alarm',
      '超时停车报警': 'Timeout parking alarm',
      '出围栏报警': 'Out of geofence alarm',
      '1小时':"1 heure",
      '3小时':"3 heures",
      '6小时':"6 heures",
      '12小时':"12 heures",
      '1天':"Un jour",
      '2天':"2 jours",
      '3天':"3 jours",
      '1周':"1 semaine",
      '2周':"2 semaines",
      '3周':"3 semaines",
      '1月':"1 mois",
      '2月':"2 mois",
      '3月':"3 mois",
      '6月':"6 mois",
      '1年':"1 an",
      '到期时间':"Date d'expiration",
      '已到期':'be expired',
      '<3天':'Moins de 3 jours',
      '<7天':'Moins de 7 jours',
      '<15天':'Moins de 15 jours',
      '<30天':'Moins de 30 jours',
      '设备号':'Périphérique Non',
      '名称':'Nom',
      '颜色':'Couleur',
      '长':'longue',
      '宽':'largeur',
      '高':'haute',
      '重量':'poids',
      '设备状态':"état de l'équipement",
      '偏离线路报警':'Alarme de ligne de déviation',
      '电话':'Téléphone',
      '年龄':'âge'
    },
    dict: {
      '小轿车': 'Auto',
      '救护车':"Ambulance",
      '大卡车':"Camion",
      '油罐车':"Wagon-citerne à carburant",
      '警车':"Voiture de police",
      '公交车':"Autobus",
      '工程车':"Véhicule d'ingénierie",
      '环卫车':"Camion d'assainissement",
      '旅游车':"Voiture de tourisme",
      '挖机':"Excavatrice",
      '厢式车':"Van",
      '混凝土搅拌车':"Camion malaxeur à béton",
      '个人定位':"Positionnement Person",
      '其他':"Autres",
      '货车':"Camion",
      '摩托车':"Moto",
      '渣土车':"Camion à fumier",
      "RV":"VR",
      "Fifth Wheel":"Caravane à Sellette",
      "Trailer":"Remorque",
      "Marine":"Marine",
      "ATV":"VTT",
      "Pop Up Camper":"Tente-Roulotte",
      "Skid Steer":"Rétrocaveuse",
      "Lift":"Lift",
      "Generator":"Génératrice",
      "Closed Trailer":"Remorque Fermée",
      "Snowmobile":"Motoneige",
      "Semi Truck":"Camion Lourd",
      "Arrow Sign":"Panneau à Flèche",
      "Message Board":"Panneau d’Affichage",
      "Toilet Trailer":"Remorque de Toilettes",
      "Porta Potty":"Toilette Chimique",
      "Crash Truck":"Crash Truck",
      "Roll off Bin":"Roll off Bin",
      "Diesel Plate":"Diesel Plate",
      "RTV":"RTV",
      "Speed Sign":"Speed Sign",
      "5th wheel":"5th wheel",
      "Single":"Single",
      '有线':"Traqueur GPS",
      '无线':"Suivi Véhicules",
      '有线设备':"Câblage du traceur GPS",
      '无线设备':"Suivi Véhicules",
      '超级管理员':"Super administrateur",
      '普通管理员':"Administrateur",
      '普通帐号':"Utilisateur",
       '安装人员': 'Installer',
      '奥迪':"Audi",
      'ALPINA':"ALPINA",
      '宝马':"BMW",
      '奔驰':"Benz",
      '保时捷':"Porsche",
      '宝沃':"Baowo",
      '大众':"Dazhong",
      'Smart':"Intelligent",
      '西雅特':"West Yate",
      '本田':"Honda",
      '丰田':"Toyota",
      '雷克萨斯':"LEXUS",
      '铃木':"Suzuki",
      '马自达':"Mazda",
      '讴歌':"Eulogiser",
      '日产':"Nissan",
      '斯巴鲁':"Subaru",
      '三菱':"Mitsubishi",
      '五十铃':"Isuzu",
      '英菲尼迪':"Infiniti",
      '起亚':"KLA",
      '双龙':"Shuanglong",
      '现代':"Moderne",
      '别克':"Buick",
      '道奇':"esquiver",
      '福特':"Gué",
      'Faraday Future':"Futur de Faraday",
      'GMC':"GMC",
      'Jeep':"Jeep",
      '凯迪拉克':"Cadillac",
      '克莱斯勒':"Chrysler",
      '林肯':"Lincoln",
      '特斯拉':"Tesla",
      '雪佛兰':"Chevrolet",
      '标致':"Beau",
      'DS':"DS",
      '雷诺':"Renault",
      '雪铁龙':"Citroën",
      '阿斯顿·马丁':"Aston",
      '宾利':"Bentley",
      '捷豹':"Jaguar",
      '路虎':"Land Rover",
      '劳斯莱斯':"Rolls-Royce",
      'MINI':"MINI",
      '迈凯伦':"McLaren",
      '阿尔法·罗密欧':"ALFA ROMEO",
      '布加迪':"Bugatti",
      '菲亚特':"Décret",
      '法拉利':"Ferrari",
      '兰博基尼':"Lamborghini",
      '玛莎拉蒂':"Maserati",
      '依维柯':"Iveco",
      'Polestar':"L'Etoile polaire",
      '沃尔沃':"Volvo",
      '斯柯达':"SKODA",
      'ARCFOX':"ARCFOX",
      '宝骏':"Bao Jun",
      '比亚迪':"BYD",
      '奔腾':"Pentium",
      '比速':"Vitesse spécifique",
      '北汽绅宝':"SAIC Saab",
      '北汽幻速':"Vitesse magique Beiqi",
      '北汽威旺':"Beiqi Wei Wang",
      '北汽昌河':"Beiqi Changhe",
      '北汽制造':"BAW",
      '北汽道达':"Beiqi Dao Da",
      '北汽新能源':"Beiqi Nouvelle Énergie",
      '北京':"Pékin",
      '长安汽车':"CHANA",
      '长安欧尚':"Auchan",
      '长安轻型车':"CHANA Lumière",
      '长安跨越':"CHANA Kuayue",
      '长城':"Grande Muraille",
      '大乘汽车':"Mahayana",
      '东风风度':"est",
      '东风风光':"est",
      '东风风神':"est",
      '东风风行':"est",
      '东风小康':"est",
      '东风':"est",
      '东南':"Sud-est",
      '电咖':"Dianka",
      '福迪':"Fudi",
      '福汽启腾':"Fuqi Qi Teng",
      '福田':"Fukuda",
      '广汽传祺':"Guang Qi",
      '广汽新能源':"Guang Qi",
      '观致':"GuanZhi",
      '国金':"GuoJin",
      '哈弗':"Haff",
      '海马':"Haima",
      '汉腾':"HanTeng",
      '红旗':"HongQi",
      '华泰':"Huatai",
      '红星汽车':"Hongxing",
      '黄海':"HuangHai",
      '华骐':"HuaQi",
      '华颂':"HuaSong",
      '吉利':"De bon augure",
      '江淮':"JiangHuai",
      '捷途':"JieTu",
      '江铃':"JiangLing",
      '奇点汽车':"QiDian",
      '金杯':"JinBei",
      '金龙':"JinLong",
      '九龙':"JiuLong",
      '君马':"JunMa",
      '凯翼':"GaiYi",
      '开瑞':"KaiRui",
      '卡升':"KaSheng",
      '卡威':"Carvey",
      '康迪全球鹰':"Condi Global Hawk",
      '领克':"Gramme de collier",
      '陆风':"Brise de terre",
      '力帆':"Lifan",
      '猎豹':"LieBao",
      '零跑汽车':"LingPao",
      '领途汽车':"LingTu",
      '理想智造':"LiXiang",
      '名爵':"MingJue",
      '纳智捷':"Nazhijie",
      '欧尚汽车':"Oushang",
      '欧拉':"Oula",
      '奇瑞':"Qirui",
      '启辰':"Qichen",
      '前途':"Qiantui",
      '庆铃':"Qingling",
      '荣威':"Rongwei",
      'SWM斯威':"SWM",
      '思皓':"Sihao",
      '上汽大通':"Shangqi",
      '腾势':"Tengshi",
      '五菱':"Wuling",
      'WEY':"WEY",
      '蔚来':"Weilai",
      '潍柴英致':"Weichai",
      '威马汽车':"Weima",
      '小鹏汽车':"Xiaopeng",
      '星驰':"Xingchi",
      '新特汽车':"Xinte",
      '驭胜':"Qusheng",
      '野马':"Yema",
      '一汽':"FAW",
      '裕路':"Yulu",
      '云度':"Yundu",
      '云雀汽车':"Yunque",
      '众泰':"Zhongtai",
      '中华':"Zhonghua",
      '知豆':"Zhidou",
      '之诺':"Zhinuo",
      '中兴':"ZTE",
      '全部':"Tout"
    },
    color: {
      '蓝色': 'Bleu',
      '绿色': 'Vert',
      '黄色': 'Jaune',
      '白色': 'blanc',
      '黑色': 'Noir',
      'red':"Rouge", 
      'orange':'Orange', 
      'grey':"Gris", 
      'brown':"Brun", 
      'pink':"Rose", 
      'purple':"Mauve"
    },
    commandList: {
      '回传间隔': 'Intervalle de retour',
      '无线闹钟':"Réveil sans fil",
      '休眠时长':"Durée de la dormance",
      '追踪设置':"Paramètres de trace",
      '定位模式':"Mode de localisation",
      '锁车/解锁':"Verrouiller / Déverrouiller",
      '拆机报警设置':"Capteur de lumière / Chute d'alarme",
      '超速报警设置':"Réglage de l'alarme de survitesse",
      '里程参数设置':"Réglage des paramètres de kilométrage",
      '设置功能开关':"Réglage du commutateur",
      '其他设置':"Autre paramètre",
      '控制终端状态':"Contrôle de l'état de l'appareil",
      '设置低油量/油量异常报警阀值':"Réglage de l'alarme d'huile",
      '设置号码':"Réglage du numéro de téléphone",
      '设置油量特征参数':"Réglage du paramètre d'huile",
      '电话回拨':"Moniteur à distance",
      '立即拍照':"Prendre une photo",
      '连续拍照':"Photo Continuois",
      '拍照间隔设置':"Intervalle de photo",
      '紧急抓拍设置':"Paramètre d'instantané d'urgence",
      '条件抓拍设置':"Paramètre d'instantané de condition",
      '图像分辨率设置':"Réglage de la résolution d'image",
      '遥控车门锁':"Verrouillage de la porte à distance",
      '位移报警设置':"Alarme Displacemeng",
      '震动报警设置':"Alarme de vibration",
      '查询ICCID':"Requête ICCID",
      '远程升级':"Mise à jour à distance",
      '高级指令':"Commande avancée",
      '锁车解锁':"Verrouiller / Déverrouiller",
      '设置通讯参数':"Définition du paramètre de communication",
      '设置接入参数':"Définition du paramètre d'accès",
      '位置汇报参数设置':"Réglage de l'emplacement",
      '终端通话设置':"Paramètre d'appel terminal",
      '终端电话拨打':"Appel téléphonique terminal",
      '图像/视频设置':"Réglage Image / Vedio",
      '报警开关设置':"Réglage du commutateur d'alarme",
      '报警发送SMS开关':"Paramètre SMS",
      '报警拍摄开关':"Réglage de la photo d'alarme",
      '报警拍摄存储开关':"Paramètre d'enregistrement de photo Alarl",
      '关键报警开关':"Réglage d'alarme important",
      '报警参数设置':"Réglage des paramètres d'alarme",
      '超速参数设置':'Réglage des paramètres de survitesse',
      '车辆基本资料设置':"Réglage des informations de base",
      '电话本设置':"Réglage de l'annuaire téléphonique",
      '连接指定服务器':"Paramètres du serveur",
      '终端关机':"Fermeture du terminal",
      '终端复位':"Réinitialisation du terminal",
      '恢复出厂设置':"Restaurer les paramètres d'usine",
      '关闭数据通信':"Fermer la communication des données",
      '关闭所有无线通信':"Fermer tout le réseau",
      '车辆控制':"Contrôle de l'appareil",
      '临时位置跟踪控制':"Suivi de commande",
      '终端唤醒':"Terminal réveillé",
      '设置油量报警报警参数':"Réglage de l'alarme d'huile",
      '设置油箱参数':"Réglage du réservoir",
      '录音指令':"Paramètre d'enregistrement",
      '单次点名':"Lieu de la requête",
      '设置ACC开发送数据间隔':"Réglage de l'intervalle de données de ACC sur",
      '设置ACC关发送数据间隔':"Désactivation de l'intervalle de données de l'ACC",
      '设备重启':"Redémarrage de l'appareil",
      '取消所有报警':"Annulation de toutes les alarmes",
      '里程清零':"Kilométrage effacé",
      '升级':"Updatae Softwire",
      '里程初始化':"Réglage du kilométrage",
      'MT01专用':"MT01",
      '设置油量异常报警参数':"Réglage des paramètres d'alarme anormale d'huile",
      '打开实时视频':'Open live video',
      '停止实时视频':'Stop real-time video',
      '运动静止回传间隔设置':'Set the uploading interval in motion and static mode'
    },
    commandParams: {
      '选择操作': "Sélectionner",
      '锁车':"Verrouiller la voiture",
      '解锁':"Ouvrir",
      '终端心跳时间间隔':"Intervalle de fréquence cardiaque terminal",
      '秒':"Seconde",
      '分钟':"Minutes",
      '次':"Fois",
      '度':"Degrés",
      '毫米':"Millimètre",
      'TCP应答超时时间':"Délai de réponse TCP",
      'TCP重传次数':"Nombre de retransmissions TCP",
      'UDP应答超时时间':"Délai de réponse UDP",
      'UDP重传次数':"Nombre de retransmissions UDP",
      'SMS应答超时时间':"Délai de réponse SMS",
      'SMS重传次数':"Nombre de retransmissions SMS",
      '设置接入参数':"Définition des paramètres d'accès",
      '主服务器APN':"APN du serveur maître",
      '拨号用户名':"Nom d'utilisateur commuté",
      '拨号密码':"Composez le mot de passe",
      '主服务器IP':"IP du serveur maître",
      '备服务器APN':"APN du deuxième serveur",
      '备服务器IP':"IP du deuxième serveur",
      '服务器TCP端口':"Port TCP du serveur",
      '服务器UDP端口':"Port UDP du serveur",
      '监控平台电话':"Téléphone de la plate-forme de surveillance",
      '终端复位电话':"Téléphone de réinitialisation du terminal",
      '恢复出厂设置电话':"Restaurer le téléphone configuré en usine",
      '监控平台SMS电话':"Téléphone SMS de plate-forme de surveillance",
      '接收终端SMS文本报警电话':"Réception du téléphone d'alarme SMS du terminal",
      '电话回拨号码':"Surveiller les numéros de téléphone",
      '监管平台特权短信号码':"Numéro de SMS privilégié de la plate-forme de supervision",
      '位置汇报策略':"Stratégie de rapport de localisation",
      '定时汇报':"Rapports de synchronisation",
      '定距汇报':"Rapports à distance",
      '定时和定距汇报':"Compte-rendu de chronométrage et de distance",
      '位置汇报方案':"Schéma de signalement de l'emplacement",
      '根据ACC状态':"Dépend du statut acc",
      '根据登录状态和ACC状态':"En fonction du statut de connexion et du statut ACC",
      '缺省汇报时间间隔':"Intervalle de rapport par défaut",
      '驾驶员未登录汇报时间间隔':"Intervalle de temps de rapport non journalisé du pilote",
      '休眠汇报时间间隔':"Intervalle de rapport d'hibernation",
      '紧急报警汇报时间间隔':"Intervalle de rapport d'alarme d'urgence",
      '缺省汇报距离间隔':"Intervalle de distance de rapport par défaut",
      '驾驶员未登录汇报距离间隔':"Intervalle de distance de rapport non journalisé du conducteur",
      '休眠汇报距离间隔':"Intervalle de distance de rapport de mise en veille prolongée",
      '紧急报警汇报距离间隔':"Intervalle de distance de rapport d'alarme d'urgence",
      '拐点补传角度':"Angle de transmission supplémentaire du point d'inflexion",
      '终端电话接听策略':"Stratégie de réception téléphonique du terminal",
      '自动接听':"Réponse automatique",
      'ACC ON时自动接听':"Réponse automatique lorsque ACC ON",
      '单次通话最长':"Appel unique le plus long",
      '月通话最长':"Appel mensuel le plus long",
      '终端电话拨打类型':"Types d'appels téléphoniques terminaux",
      '普通通话':"Appel ordinaire",
      '电话回拨':"Surveiller",
      '拨打号码':"Composer le numéro",
      '图像/视频质量':"Qualité d'image / vidéo",
      '亮度':"Luminosité",
      '对比度':"Contraste",
      '饱和度':"Saturation",
      '色度':"Chroma",
      '紧急报警':"Alarme d'urgence",
      '关':"Fermer",
      '开':"Ouvrir",
      '超速报警':"Alarme de vitesse",
      '疲劳驾驶报警':"Alarme de fatigue lors conduite",
      '预警':"Alerte précoce",
      'GNSS模块故障':"Alarme de module GNSS",
      'GNSS天线未接':"Antenne GNSS non connectée",
      'GNSS天线短路':"Court-circuit d'antenne GNSS",
      '终端主电源欠压':"Sous-tension de l'alimentation principale du terminal",
      '终端主电源掉电':"Panne de courant de l'alimentation principale du terminal",
      '终端LCD故障':"Panne de l'écran LCD du terminal",
      'TTS模块故障':"Panne du module TTS",
      '摄像头故障':"Dysfonctionnement de la caméra",
      '当天累计驾驶超时':"Heures supplémentaires de conduite cumulées ce jour-là",
      '超时停车':"Stationnement aux heures supplémentaires",
      '进出区域':"Zone d'importation et d'exportation",
      '进出路线':"Voie d'entrée et de sortie",
      '路段行驶时间不足/过长':"Temps de conduite insuffisant / excessif dans la section",
      '路线偏离报警':"Alarme pour déviation d'itinéraire",
      '车辆VSS故障':"Défaut du VSS du véhicule",
      '车辆油量异常':"Anomalie de l'huile du véhicule",
      '车辆被盗':"Vol de véhicule",
      '车辆非法点火':"Allumage illégal de véhicules",
      '车辆非法位移':"Déplacement illégal de véhicules",
      '最高速度':"Vitesse de pointe",
      '超速持续时间':"Durée de survitesse",
      '连续驾驶最长时间':"Conduite continue pendant la plus longue période",
      '当天累计驾驶最大时间':"Temps de conduite maximal cumulé pour la journée",
      '最小休息时间':"Temps de repos minimum",
      '最大停车时间':"Temps de stationnement maximum",
      '车辆里程表读数':"Relevés du compteur kilométrique du véhicule",
      '所在省域ID':"ID provincial",
      '所在市域ID':"Identifiant de la ville",
      '车牌号':"Numéro de plaque d'immatriculation",
      '车牌颜色':"Couleur de la plaque d'immatriculation",
      '指令类型':"Type d'instruction",
      '删除所有联系人':"Supprimer tous les contacts",
      '更新电话本':"Mettre à jour l'annuaire téléphonique",
      '追加电话本':"Annuaire téléphonique supplémentaire",
      '修改电话本':"Modification de l'annuaire téléphonique",
      '标志':"Signe",
      '呼入':"En appel",
      '呼出':"Appel sortant",
      '呼入/呼出':"Entrée / sortie",
      '电话号码':"Numéro de téléphone",
      '联系人':"Contacts",
      '连接控制':"Contrôle de connexion",
      '切换到指定监管平台服务器':"Basculer vers le serveur de plate-forme de surveillance désigné",
      '切换回原缺省监管平台服务器':"Revenir au serveur de la plate-forme de surveillance par défaut d'origine",
      '鉴权码':"Code d'identification",
      '拨号点名称':"Nom du point de numérotation",
      'IP地址':"adresse IP",
      'TCP端口':"Port TCP",
      'UDP端口':"Port UDP",
      '连接到指定服务器时限':"Connexion à la limite de temps du serveur spécifiée",
      '该指令无需参数':"Cette instruction ne nécessite pas de paramètres",
      '车门控制':"Contrôle de la porte",
      '解锁车门':"Déverrouillage de la porte",
      '关闭车门':"Ferme la porte",
      '汇报间隔':"Intervalle de rapport",
      '跟踪有效期':"Suivi de la date d'expiration",
      '低油量报警值':"Valeur d'alarme d'huile basse",
      '升':"Litre",
      '油量异常下降报警阈值':"Seuil d'alarme pour baisse anormale du volume d'huile",
      '油量异常下降时间阈值':"Seuil de temps de vidange anormal de l'huile",
      '油箱1容积':"Volume du réservoir 1",
      '油箱1高度':"Hauteur du réservoir de carburant 1",
      '油箱2容积':"Volume du réservoir de carburant 2",
      '油箱2高度':"Hauteur du réservoir de carburant 2",
      '停止录音':"Arrête d'enregistrer",
      '开始录音':"Commencer l'enregistrement",
      '录音时间':"Temps d'enregistrement",
      '保存标志':"Enregistrer le drapeau",
      '实时长传':"Longue passe en temps réel",
      '保存':"Sauvegarder",
      '音频采样率':"Taux d'échantillonnage audio",
      '请输入指令内容':"Veuillez saisir le contenu de l'instruction.",
      '时间间隔':"Intervalle de temps",
      '端口':"Port",
      '里程值':"Valeur kilométrique",
      '回传间隔':"Intervalle de retour",
      '启动回传间隔':"Intervalle de retour (Acc on)",
      '熄火回传间隔':"Intervalle de retour (Acc off)",
      '休眠回传间隔':"Intervalle de retour (veille)",
      '回传间隔 (30-300秒)':"intervalle d'envoi GPS (30-300 s)",
      '回传间隔 (5-300秒)':"intervalle d'envoi GPS (5-300 s)",
      '持续时间 (5-57600分)':"durée (5-57600 min)",
      '唤醒时间1':"Heure de réveil 1",
      '唤醒时间2':"Heure de réveil 2",
      '唤醒时间3':"Heure de réveil 3",
      '唤醒时间4':"Heure de réveil 4",
      '休眠时长':"Durée de la dormance",
      '打开':"Ouvrir",
      '关闭':"Fermer",
      '持续时间':"Durée",
      '分':"Min",
      '纯基站定位':"Emplacement de la station de base pure",
      '基站+GPS':"Station de base + GPS",
      '报警，追踪15分钟，间隔300秒':"Alarme, suivi 15 minutes, intervalle 300 secondes",
      '报警不追踪':"L'alarme ne suit pas",
      '清零':"Zéro compensation",
      '设置里程值':"Définition de la valeur de kilométrage",
      '省电关闭通信模块':"Module de communication d'arrêt d'économie d'énergie",
      '不关闭':"Pas arrêté",
      '超速报警速度值0-200':"Valeur de vitesse d'alarme de survitesse 0-200",
      '超速预警值':"Valeur d'avertissement de survitesse",
      '持续超速触发报警时间1-255,推荐5':"Heure d'alarme de déclenchement de survitesse continue 1-255, recommandation 5",
      '低速报警速度值':"Valeur de vitesse d'alarme basse vitesse",
      '持续低速触发报警时间1-65535,推荐600':"Heure d'alarme de déclenchement à basse vitesse continue 1-65535, recommandée 600",
      'ACC进入省电时间1-65535,推荐600':"Temps d'économie d'énergie d'entrée ACC 1-65535, recommandation 600",
      '拐角补传角度,推荐30':"Angle d'angle, recommander 30",
      '震动报警灵敏度':"Sensibilité de l'alarme de vibration",
      '最低':"Le minimum",
      '低':"Faible",
      '中':"Moyen",
      '高':"Haut",
      '最高':"Plus haut",
      '选择动作':"Sélectionner",
      '重启终端':"Redémarrer le terminal",
      '唤醒终端':"Réveillez le terminal",
      '关闭终端':"Terminal de fermeture",
      '恢复出厂设置':"Rétablir les paramètres d'usine",
      '低油量报警阀值':"Seuil d'alarme bas niveau d'huile",
      '油量异常上升报警阈值':"Seuil d'alarme pour augmentation anormale du volume d'huile",
      '油量异常上升时间阈值':"Seuil de temps de montée anormal du volume d'huile",
      '中心号码1':"Numéro du centre 1",
      '中心号码2':"Numéro de centre 2",
      '报警通知号码1':"Numéro de notification d'alarme 1",
      '报警通知号码2':"Numéro de notification d'alarme 2",
      '电话回拨号码':"Numéro du moniteur",
      '邮箱总容积0-999.9':"Volume total de la boîte aux lettres 0-999,9",
      '空邮箱电压AD值0-9999':"Valeur AD de tension du courrier aérien 0-9999",
      '1/4邮箱油量电压AD值0-9999':"1/4 Tension de carburant de la boîte aux lettres Valeur AD 0-9999",
      '1/2邮箱油量电压AD值0-9999':"1/2 Tension de carburant de la boîte aux lettres Valeur AD 0-9999",
      '3/4邮箱油量电压AD值0-9999':"3/4 Tension de carburant de la boîte aux lettres Valeur AD 0-9999",
      '满邮箱油量电压AD值0-9999':"Valeur AD de tension de carburant de boîte aux lettres complète 0-9999",
      '拍照通路':"Chemin de la photo",
      '第一路':"Canal 1",
      '第二路':"Canal 2",
      '第三路':"Canal 3",
      '第四路':"Canal 4",
      '抓拍间隔,最大65535':"Intervalle de capture, maximum 65535",
      '抓拍次数,最大65535':"Nombre de pressions, maximum 65535",
      '设置紧急情况（盗警、劫警）下图像抓拍的时间间隔和次数':"Configurer l'intervalle de temps et la fréquence de capture d'image en cas d'urgence (vol et vol qualifié)",
      '图像抓拍时间间隔，最大255秒':"Intervalle de capture d'image, maximum 255 secondes",
      '抓拍间隔':"Intervalle de capture",
      '抓拍次数':"Temps de capture",
      '设置状态改变情况下图像抓拍的时间间隔和次数':"Définissez l'intervalle de temps et le nombre de captures d'image lorsque l'état change",
      '拍照间隔':"Intervalle de photo",
      '触发条件':"Événement déclencheur",
      '开车门':"Ouvrez la porte de la voiture",
      '关车门':"Porte Fermée",
      'ACC点火':"Allumage ACC",
      'ACC熄火':"Décrochage ACC",
      '空车':"Voiture vide",
      '重车':"Voiture lourde",
      '进出范围':"Gamme d'entrée et de sortie",
      '速度为0':"La vitesse est de 0.",
      '选择分辨率':"Résolution sélective",
      '上锁':"Enfermer",
      '开锁':"Ouvrir",
      '打开位移报警':"Alarme de déplacement ouvert",
      '关闭位移报警':"Désactiver l'alarme de déplacement",
      '位移半径，推荐300':"Rayon de déplacement, recommandation 300",
      '米':"M",
      '仅平台':"Plateforme uniquement",
      '平台+短信':"Plateforme + SMS",
      '平台+短信+电话':"Plateforme + SMS + Téléphone",
      '打开震动报警':"Activez l'alarme de vibration",
      '关闭震动报警':"Désactiver l'alarme de vibration",
      '选择报警通知方式':"Sélection du mode de notification d'alarme",
      '查询ICCID并自动更新SIM卡号，该指令无需参数':"Renseignez-vous sur ICCID et mettez à jour automatiquement le numéro de la carte SIM. Aucun paramètre n'est requis pour cette instruction.",
      '升级':"Améliorer",
      '停止':"Arrêter",
      '服务器IP地址':"Adresse IP du serveur",
      '端口号':"Port",
      '终端软件版本号':"Numéro de version du logiciel du terminal",
      '退出追踪':"Suivi de sortie",
      'WIFI>LBS,GPS 关':"WIFI> LBS, GPS OFF",
      '命令字':'CMDKey',
      '消息体':'Message',
      '运动回传间隔':'Data uploading interval in motion',
      '静止回传间隔':'Data uploading interval in static',
      '震动报警':'Vibration alarm'
    }
  }
  