import * as types from './mutation-types'
import {
  setVuex,
  setDeviceCache,
  removeDeviceCache
} from '@/common/cache'

const mutations = {
  [types.SET_USER_NAME](state, userName) {
    state.userName = userName
    setVuex(state)
  },
  [types.SET_USER_ID](state, userId) {
    state.userId = userId
    setVuex(state)
  },
  [types.SET_STATE](state, s) {
    const keys = Object.keys(s)
    for (let i = 0; i < keys.length; i++) {
      const prop = keys[i]
      state[prop] = s[prop]
    }
    setVuex(state)
  },
  [types.SET_LOGIN_INFO](state, i) {
    state.loginInfo = i
    setVuex(state)
  },
  [types.SET_CURRENT_HOLD_ID](state, currentHoldId) {
    state.currentHoldId = currentHoldId
    setVuex(state)
  },
  [types.SET_CURRENT_HOLD_NAME](state, currentHoldName) {
    state.currentHoldName = currentHoldName
    setVuex(state)
  },
  [types.SET_CURRENT_HAS_CHILD](state, currentHasChild) {
    state.currentHasChild = currentHasChild
    setVuex(state)
  },
  [types.ADD_VISITED_VIEW](state, view) {
    if (state.visitedViews.some(v => v.path === view.path)) return
    if (view.meta.noVisisted) return
    state.visitedViews.push(
      Object.assign({}, {
        path: view.path,
        fullPath: view.fullPath,
        name: view.name,
        query: view.query
      }, {
        title: view.meta.title || 'no-name'
      })
    )
    setVuex(state)
  },
  [types.ADD_CACHED_VIEW](state, view) {
    if (state.cachedViews.includes(view.name)) return
    if (!view.meta.noCache && !view.meta.noVisisted) {
      state.cachedViews.push(view.name)
    }
    setVuex(state)
  },
  [types.DEL_VISITED_VIEW](state, view) {
    for (const [i, v] of state.visitedViews.entries()) {
      if (v.path === view.path) {
        state.visitedViews.splice(i, 1)
        break
      }
    }
    setVuex(state)
  },
  [types.DEL_CACHED_VIEW](state, view) {
    for (const i of state.cachedViews) {
      if (i === view.name) {
        const index = state.cachedViews.indexOf(i)
        state.cachedViews.splice(index, 1)
        break
      }
    }
    setVuex(state)
  },
  [types.DEL_OTHERS_VISITED_VIEWS](state, view) {
    for (const [i, v] of state.visitedViews.entries()) {
      if (v.path === view.path) {
        state.visitedViews = state.visitedViews.slice(i, i + 1)
        break
      }
    }
    setVuex(state)
  },
  [types.DEL_OTHERS_CACHED_VIEWS](state, view) {
    for (const i of state.cachedViews) {
      if (i === view.name) {
        const index = state.cachedViews.indexOf(i)
        state.cachedViews = state.cachedViews.slice(index, index + 1)
        break
      }
    }
    setVuex(state)
  },
  [types.DEL_ALL_VISITED_VIEWS](state) {
    state.visitedViews = []
    setVuex(state)
  },
  [types.DEL_ALL_CACHED_VIEWS](state) {
    state.cachedViews = []
    setVuex(state)
  },
  [types.UPDATE_VISITED_VIEW](state, view) {
    for (let v of state.visitedViews) {
      if (v.path === view.path) {
        v = Object.assign(v, {
          fullPath: view.fullPath,
          query: view.query
        })
        break
      }
    }
    setVuex(state)
  },
  [types.DEL_CACHED_TREES](state) {
    state.cachedTrees = []
  },
  [types.ADD_CACHED_TREES](state, name) {
    if (state.cachedTrees.includes(name)) return
    state.cachedTrees.push(name)
  },
  [types.ADD_TASK_CAR](state, id) {
    if (state.taskList.includes(id)) return
    state.taskList.push(id)
    setVuex(state)
  },
  [types.DEL_TASK_CAR](state, id) {
    for (const i of state.taskList) {
      if (i === id) {
        const index = state.taskList.indexOf(i)
        state.taskList.splice(index, 1)
        break
      }
    }
    setVuex(state)
  },
  [types.DEL_ALL_TASK_CAR](state) {
    state.taskList = []
    setVuex(state)
  },
  [types.ADD_TASK_DEVICES](state, id) {
    if (state.cachedDevices.includes(id)) return
    state.cachedDevices.push(id)
    // setVuex(state)
  },
  [types.DEL_TASK_DEVICES](state, id) {
    for (const i of state.cachedDevices) {
      if (i === id) {
        const index = state.cachedDevices.indexOf(i)
        state.cachedDevices.splice(index, 1)
        break
      }
    }
    setVuex(state)
  },
  [types.DEL_ALL_TASK_DEVICES](state) {
    state.cachedDevices = []
    setVuex(state)
  },
  [types.ADD_VIEWS_DEVICES](state, id) {
    if (state.deviceViews.includes(id)) return
    state.deviceViews.push(id)
    setDeviceCache(id)
  },
  [types.DEL_VIEWS_DEVICES](state, id) {
    for (const i of state.deviceViews) {
      if (i.ObjectID === id.ObjectID ) {
        const index = state.deviceViews.indexOf(i)
        state.deviceViews.splice(index, 1)
        break
      }
    }
    removeDeviceCache(id)
  },
  [types.DEL_ALL_VIEWS_DEVICES](state) {
    console.log(state)
    state.deviceViews = []
    setDeviceCache()
  },
  [types.ADD_TASK_DVR](state, id) {
    if (state.cacheDvrs.includes(id)) return
    state.cacheDvrs.push(id)
    setVuex(state)
  },
  [types.DEL_TASK_DVR](state, id) {
    for (const i of state.cacheDvrs) {
      if (i === id) {
        const index = state.cacheDvrs.indexOf(i)
        state.cacheDvrs.splice(index, 1)
        break
      }
    }
    setVuex(state)
  },
  [types.DEL_ALL_TASK_DVR](state) {
    state.cacheDvrs = []
    setVuex(state)
  },
  [types.SET_CURRENT_MAP](state, name) {
    state.currentMap = name
    setVuex(state)
  },
  [types.SET_BMAP_GL](state, name) {
    state.glBmap = name
    setVuex(state)
  },
  [types.SET_AMAP_GL](state, name) {
    state.glAmap = name
    setVuex(state)
  },
  [types.SET_ALL_VIEWS](state, {
    visitedViews,
    cachedViews
  }) {
    console.log(visitedViews, cachedViews)
    state.visitedViews.splice(0, state.cachedViews.length, ...visitedViews)
    state.cachedViews.splice(0, state.cachedViews.length, ...cachedViews)
  },
  [types.SET_MENU_POSITION](state, position) {
    state.menuPosition = position
  },
  [types.SET_VER](state, val) {
    state.ver = val
    setVuex(state)
  },
  [types.SET_HAS_MENU](state, val) {
    state.hasMenu = val
    setVuex(state)
  },
  [types.SET_MONITOR_LEFT](state, val) {
    state.monitorLeft = val
    setVuex(state)
  }
}

export default mutations
