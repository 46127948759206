<template>
  <el-dialog :title="$t('deviceManage.moverDevices')" :visible.sync="visible" v-if="visibleReal" @closed="closed" custom-class="v-dialog v-dialog--sm v-dialog--mdpadding v-dialog--footer detailPage"
    append-to-body >
    <p class="imb-0" style="color:#fff;" v-if="!inNext">{{$t('deviceManage.textareaTip')}}</p>
    <el-input
      autofocus
      clearable
      ref="textarea"
      type="textarea"
      @blur="textAreaBlur"
      class="txt"
      :rows="14"
      v-if="!inNext"
      v-model="textarea">
    </el-input>
    <ul v-else class="txtList">
      <p class="imb-2" v-html="$t('deviceManage.findDevice', { count: `<em>${tableData.length}</em>` })"></p>
      <li v-for="(k, index) in inputArray" :key="index">
        <i v-show="false">{{tempIndex = tableData.findIndex(p => p.SIM2 == k)}}</i>
        <span>{{k}}</span>
        <span class="success" v-if="tempIndex !=-1"><i class="el-icon-check"></i></span>
        <span class="error" v-else><i class="el-icon-close"></i></span>
        <span>{{tempIndex == -1 ? '' : tableData[tempIndex].FullPathName}}</span>
      </li>
    </ul>
    <template slot="footer">
      <span v-if="inputArray.length && !inNext" class="searchGroupCount">
        {{$t('deviceManage.enterTip', {count:inputArray.length})}}
        <el-button type="text" @click="clearText">{{$t('deviceManage.clear')}}</el-button>
      </span>
      <span v-if="inNext" class="searchGroupCount">
        <el-button type="text" @click="inNext = false" style="min-width:0;">{{$t('deviceManage.inputAgain')}}</el-button>
      </span>
      <el-button @click="visible=false" class="gs-button1" type="text" size="medium" style="font-size:14px">{{$t('common.cancel')}}</el-button>
      <el-button @click="next" :loading="loading" v-if="!inNext" class="gs-button2 queryBtn" plain type="primary" size="medium" v-ripple style="font-size:14px">{{$t('common.query')}}</el-button>
      <el-button @click="confirm" v-else class="gs-button2 queryBtn" plain type="primary" size="medium" v-ripple style="font-size:14px">{{$t('common.confirm')}}</el-button>
      <!-- <el-button @click="confirm" class="customStyle queryBtn" plain type="primary" size="medium" v-ripple style="font-size:14px">{{$t('common.query')}}</el-button> -->
    </template>
  </el-dialog>
</template>

<script>
import {Query} from '@/api/device'
import {Filter, Op} from '@/api/config'
const SPLIT = ','
export default {
  data() {
    return {
      textarea: '',
      visible: false,
      visibleReal: false,
      inNext: false,
      loading: false,
      inputArray: [],
      tableData: []
    }
  },
  methods: {
    open() {
      this.visible = true
      this.visibleReal = true
      setTimeout(() => {
        this.$refs.textarea.focus()
      }, 317)
    },
    confirm() {
      this.$emit('confirm', this.tableData)
      this.visible = false
    },
    closed() {
      this.visibleReal = false
      this.inNext = false
      this.textarea = ''
      this.inputArray = []
    },
    clearText() {
      this.textarea = ''
    },
    next() {
      this.textAreaBlur()
      this.fetchDataDevice()
    },
    textAreaBlur() {
      let val = this.textarea
      // 替换.;换行符为逗号
      val = val.replace(/\./g, SPLIT)
      val = val.replace(/;/g, SPLIT)
      val = val.replace(/[\r\n]/g, SPLIT)
      val = val.replace(/[\n]/, SPLIT)
      // 替换数字以外的字符为空
      val = val.replace(/[^\d,]/g, '')
      // 移除空字符
      this.inputArray = val.split(SPLIT).filter(k => !!k).slice(0, 1000)
      val = this.inputArray.join(SPLIT)
      this.textarea = val
    },
    fetchDataDevice() {
      this.loading = true
      if (!this.textarea) {
        this.loading = false
        return
      }

      Query(1, 1000, '', '', [new Filter('D.SIM2', this.inputArray, Op.SListContains)], null, true).then(ret => {
        // console.log(ret)
        this.tableData = ret.data.list.slice()
        this.inNext = true
      }).catch(err => {
        console.error(err)
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.searchGroupCount {
  float: left;
  color: #fff;
  font-size: 14px;
  .el-button {
    font-size: 13px;
    min-width: 100px;
  }
}
.txtList {
  list-style: none;
  & p:first-child {
    font-size: 14px;
    color: #fff;
    em {
      color: var(--color-p);
      margin: 0 4px;
      font-style: normal;
    }
  }
  li {
    font-weight: 500;
    color: #fff;
    margin-bottom: 4px;
    & span:nth-child(2) {
      min-width: 150px;
      display: inline-block;
    }
    .el-icon-check {
      color: green;
    }
    .el-icon-close {
      color: red;
    }
    & span:last-child {
      margin-left: 24px;
      color: rgba(0, 0, 0, .54);
    }
  }
}
.txt{
  & ::v-deep .el-textarea__inner{
    border-color: #617895;
    background-color: #617895;
    color: #fff;
  }
}
.gs-button1{
  background: url(../../../assets/images/form-btn2.png) no-repeat center;
  background-size: auto 100%;
  color: #2F302C;
  padding: 0;
  border: none;
  height: 42px;
  min-width: 110px;
}
.gs-button1:hover{
  color: #2F302C;
  background: url(../../../assets/images/form-btn2.png) no-repeat center;
  background-size: auto 100%;
}
.gs-button2{
  background: url(../../../assets/images/form-btn1.png) no-repeat center !important;
  background-size: auto 100% !important;
  color: #2F302C !important;
  padding: 0;
  border: none;
  height: 42px;
  min-width: 110px;
}
.gs-button2:hover{
  color: #2F302C;
  background: url(../../../assets/images/form-btn1.png) no-repeat center !important;
  background-size: auto 100% !important;
}

</style>
