import request from '@/common/request'
import { commonParams } from '@/api/config'
import { getToken } from '@/common/auth'

export function GetObjectListByObjectIDs(ObjectIDs) {
  const loginKey = getToken()
  return request({
    url: './query/GetObjectListByObjectIDs',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      ObjectIDs
    })
  })
}

export function SendCommand(objectIds, protocolType, commandName, data) {
  const loginKey = getToken()
  return request({
    url: './command/SendCommand',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      objectIds,protocolType, commandName, data
    })
  })
}

export function BatchCommandHistory(pageIndex, pageSize, sortKey, sortDirect, startTime, endTime, hasChild) {
  const loginKey = getToken()
  return request({
    url: './command/BatchCommandHistory',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      pageIndex,
      pageSize,
      sortKey,
      sortDirect,
      startTime,
      endTime,
      hasChild
    })
  })
}

export function BatchCommandDetail(BID) {
  const loginKey = getToken()
  return request({
    url: './command/BatchCommandDetail',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      BID
    })
  })
}

export function ReSendCommandList(objectIdList, commandNameList, commandTextList) {
  const loginKey = getToken()
  return request({
    url: './command/ReSendCommandList',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey, objectIdList, commandNameList, commandTextList
    })
  })
}

export function CancelAlarm(objectId, reason) {
  const loginKey = getToken()
  return request({
    url: './command/CancelAlarm',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey, objectId, reason
    })
  })
}

