<template>
  <div @click="$emit('click')" class="arrowButton" :style="spanStyle">
    <div class="arrowButton__inner" :class="position" :style="spanStyle">
      <div class="span top"></div>
      <div class="cen">
        <i class="el-icon-arrow-right"></i>
      </div>
      <div class="span bot"></div>
      
      <!-- <i class="arrowButton__background" :class="{'attach': attach}" :style="spanBackStyle"></i> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    btnWidth: {
      type: String,
      default: '72px'
    },
    position: {
      type: String,
      default: 'left'
    },
    attach: Boolean
  },
  computed: {
    spanStyle() {
      if (this.position == 'top' || this.position == 'bottom') {
        return {
          height: '16px',
          width: this.btnWidth
        }
      } else {
        return {
          height: this.btnWidth,
          width: '16px'
        }
      }
    },
    spanBackStyle() {
      const w = parseInt(this.btnWidth)
      const wshort = parseInt(this.btnWidth)/2
      if (this.position == 'top' || this.position == 'bottom') {
        return {
          'border-width': w +'px',
          'border-left-width': wshort + 'px',
          'border-right-width': wshort + 'px',
        }
      } else {
        return {
          'border-width': w +'px',
          'border-top-width': wshort + 'px',
          'border-bottom-width': wshort + 'px',
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/variables-simple.scss";
$borderWidth: 7px;
.arrowButton {
  position: relative;
  appearance: none;
  opacity: .6;
  transition: opacity .5 ease;
  &:hover {
    opacity: 1;
  }
  .arrowButton__inner {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    &.right {
      right: 0;
      top: 0;
      display:flex;
			flex-flow: column;
      color:#fff;
      .span{
        width:16px;
        height: 16px;
      }
      .top {
        background: url(../assets/images/collapse1.png);
        background-size: 100% 100%;
      }

      .bot {
        background: url(../assets/images/collapse2.png);
        background-size: 100% 100%;
      }
      .cen{
        padding:3px 0;
        background-color: #1890ff;
        i{
          transform:rotate(180deg);
          -ms-transform:rotate(180deg); 	/* IE 9 */
          -moz-transform:rotate(180deg); 	/* Firefox */
          -webkit-transform:rotate(180deg); /* Safari 和 Chrome */
          -o-transform:rotate(180deg);
        }
      }
      .arrowButton__arrow {
        border-width: $borderWidth;
        border-style: solid;
        margin-left: -$borderWidth;
        border-top-color: transparent;
        border-bottom-color: transparent;
        border-right-color: #fff;
        border-left-color: transparent;
      }
      .arrowButton__background {
        position: absolute;
        z-index: -1;
        left: 0;
        top: 0;
        border-style: solid;
        border-top-color: transparent;
        border-bottom-color: transparent;
        border-right-color: transparent;
        border-left-color: $--color-primary;
        &.attach {
          // 反向吸附
          border-right-color: $--color-primary;
          border-left-color: transparent;
          transform: translateX(-96px);
        }
      }
    }
    &.left {
      right: 0;
      top: 0;
      right: 0;
      top: 0;
      display:flex;
			flex-flow: column;
      color:#fff;
      .span{
        width:16px;
        height: 16px;
      }
      .top {
        background: url(../assets/images/collapse1.png);
        background-size: 100% 100%;
      }

      .bot {
        background: url(../assets/images/collapse2.png);
        background-size: 100% 100%;
      }
      .cen{
        padding:3px 0;
        background-color: #1890ff;
        i{
           transform:rotate(0deg);
          -ms-transform:rotate(0deg); 	/* IE 9 */
          -moz-transform:rotate(0deg); 	/* Firefox */
          -webkit-transform:rotate(0deg); /* Safari 和 Chrome */
          -o-transform:rotate(0deg);
        }
      }
      .arrowButton__arrow {
        margin-right: -$borderWidth;
        border-width: $borderWidth;
        border-style: solid;
        border-top-color: transparent;
        border-bottom-color: transparent;
        border-right-color: transparent;
        border-left-color: #fff;
      }
      .arrowButton__background {
        position: absolute;
        z-index: -1;
        left: 0;
        top: 0;
        border-style: solid;
        border-top-color: transparent;
        border-bottom-color: transparent;
        border-right-color: transparent;
        border-left-color: $--color-primary;
        &.attach {
          // 反向吸附
          border-right-color: $--color-primary;
          border-left-color: transparent;
          transform: translateX(-96px);
        }
      }
    }
    &.top {
      top: 0;
      left: 0;
      display: flex;
      font-size: 18px;
      color:#fff;
      .span{
        width:16px;
        height: 100%;
        transform:rotate(270deg);
        -ms-transform:rotate(270deg); 	/* IE 9 */
        -moz-transform:rotate(270deg); 	/* Firefox */
        -webkit-transform:rotate(270deg); /* Safari 和 Chrome */
        -o-transform:rotate(270deg);
      }
      .top {
        background: url(../assets/images/collapse1.png);
        background-size: 100% 100%;
      }

      .bot {
        background: url(../assets/images/collapse2.png);
        background-size: 100% 100%;
      }
      .cen{
        background-color: #1890ff;
        padding: 0 3px;
        i{
          transform:rotate(270deg);
          -ms-transform:rotate(270deg); 	/* IE 9 */
          -moz-transform:rotate(270deg); 	/* Firefox */
          -webkit-transform:rotate(270deg); /* Safari 和 Chrome */
          -o-transform:rotate(270deg);
        }
      }
      .arrowButton__arrow {
        border-width: $borderWidth;
        border-style: solid;
        margin-top: -$borderWidth;
        border-top-color: transparent;
        border-bottom-color: #fff;
        border-right-color: transparent;
        border-left-color: transparent;
      }
      .arrowButton__background {
        position: absolute;
        z-index: -1;
        left: 0;
        bottom: 0;
        border-style: solid;
        border-top-color: transparent;
        border-bottom-color: $--color-primary;
        border-right-color: transparent;
        border-left-color: transparent;
        &.attach {
          // 反向吸附
          border-top-color: $--color-primary;
          border-bottom-color: transparent;
          transform: translateY(96px);
        }
      }
    }
    &.bottom {
      top: 0;
      left: 0;
      display: flex;
      font-size: 18px;
      color: #fff;
      .span{
        width:16px;
        height: 100%;
        transform:rotate(270deg);
        -ms-transform:rotate(270deg); 	/* IE 9 */
        -moz-transform:rotate(270deg); 	/* Firefox */
        -webkit-transform:rotate(270deg); /* Safari 和 Chrome */
        -o-transform:rotate(270deg);
      }
      .top {
        background: url(../assets/images/collapse1.png);
        background-size: 100% 100%;
      }

      .bot {
        background: url(../assets/images/collapse2.png);
        background-size: 100% 100%;
      }
      .cen{
        background-color: #1890ff;
        padding: 0 3px;
        i{
          transform:rotate(90deg);
          -ms-transform:rotate(90deg); 	/* IE 9 */
          -moz-transform:rotate(90deg); 	/* Firefox */
          -webkit-transform:rotate(90deg); /* Safari 和 Chrome */
          -o-transform:rotate(90deg);
        }
      }
      .arrowButton__arrow {
        border-width: $borderWidth;
        border-style: solid;
        margin-bottom: -$borderWidth;
        border-top-color: #fff;
        border-bottom-color: transparent;
        border-right-color: transparent;
        border-left-color: transparent;
      }
      .arrowButton__background {
        position: absolute;
        z-index: -1;
        left: 0;
        bottom: 0;
        border-style: solid;
        border-top-color: transparent;
        border-bottom-color: $--color-primary;
        border-right-color: transparent;
        border-left-color: transparent;
        &.attach {
          // 反向吸附
          border-top-color: $--color-primary;
          border-bottom-color: transparent;
          transform: translateY(96px);
        }
      }
    }
  }
}
</style>
