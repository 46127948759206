<template>
  <div class="content">
    <div class="content__left">
      <div class="left__header">
        <el-input v-model="searchText" :placeholder="$t('bindDevice.placeHolder')" clearable @clear="queryHandle" @keydown.native.enter="queryHandle"></el-input>
        <el-button type="primary" class="border iml-2" plain @click="queryHandle">{{$t('bindDevice.search')}}</el-button>
      </div>

      <p class="left__tip">
        <el-checkbox v-model="hasChild" @change="queryHandle" style="float:right;">{{$t('bindDevice.viewAll')}}</el-checkbox>
        <el-checkbox v-model="containBinded" @change="queryHandle" style="float:right;" class="imr-1">{{$t('bindDevice.containAll')}}</el-checkbox>
        {{$t('bindDevice.tip')}}
      </p>
      <el-table
        v-loading="tableLoading"
        element-loading-text="loading"
        class="v-table--mini v-table__customStyle"
        ref="table"
        border
        size="mini"
        :data="tableData"
        style="width: 100%"
        @select="selectionChange">
        <el-table-column type="selection" width="70"></el-table-column>
        <el-table-column
          :label="$t('bindDevice.deviceID')"
          width="180">
          <template slot-scope="{row}">{{(row.SIM2 || row.SIM)|emptyFormat}}</template>
        </el-table-column>
        <!-- <el-table-column
          :label="$t('bindDevice.sim')"
          width="180">
          <template slot-scope="{row}">{{row.SIM|emptyFormat}}</template>
        </el-table-column> -->
        <el-table-column
          v-if="containBinded"
          :label="$t('bindDevice.vehicleName')">
          <template slot-scope="{row}">{{row.VehicleID ? row.VehicleName : '--'}}</template>
        </el-table-column>
        <el-table-column
          :label="$t('bindDevice.deviceModel')">
          <template slot-scope="{row}">{{row.MDTModelName|emptyFormat}}</template>
        </el-table-column>
        <el-table-column
          :label="$t('bindDevice.trackerType')">
          <template slot-scope="{row}">{{row.TrackerType === 1 ? $t('dict')['无线'] : $t('dict')['有线']}}</template>
        </el-table-column>
        <!-- <el-table-column
          show-overflow-tooltip
          :label="$t('bindDevice.remark')">
          <template slot-scope="{row}">{{row.Remark|emptyFormat}}</template>
        </el-table-column> -->
      </el-table>
      <el-pagination
        class="v-pager imx-2 imt-1"
        :page-size="pageSize"
        :pager-count="7"
        :current-page.sync="pageIndex"
        layout="total, prev, pager, next, jumper"
        :total="pageTotalCount"
        @current-change="pageCurrentChange"
        @size-change="pageSizeChange"
        popper-class="custom">
      </el-pagination>
    </div>
    <div class="content__right">
      <p class="list__title">{{$t('bindDevice.selected')}}</p>
      <div class="list__body">
        <transition-group name="scale-shrink">
          <p class="list__item" v-for="item in selectList" :key="item.ObjectID">{{item.SIM2 || item.SIM}}</p>
        </transition-group>
      </div>
    </div>
  </div>
</template>

<script>
import * as API from '@/api/device'
import {mapGetters} from 'vuex'
import {deleteFromArray} from "@/common/utils"
import {Filter, Op} from '@/api/config'
import mixin from './mixin-detail'
export default {
  props: {
    value: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      queryButtonLoading: false, // 查询按钮loading
      pageTotalCount: 0, // 总行数
      pageIndex: 1, // 页码
      pageSize: 10, // 每页行数
      sortKey: '', // 排序关键字
      sortDirect: '', // 排序方向
      searchName: '', // 查找字段名
      searchText: '', // 查找关键字
      searchOption: null, // 比较方法
      tableData: [], // 表格数据
      tableLoading: false, // 表格loading
      selectList: this.value,
      hasChild: false,
      containBinded: false
    }
  },
  computed: {
    ...mapGetters([
      'loginInfo',
      'currentHoldId'
    ])
  },
  created() {

    this.queryHandle()

  },
  methods: {
    queryHandle() {
      this.tableLoading = true
      const promise = new Promise((resolve, reject) => {
        const filters = []

        this.searchText.length > 0
          && (filters.push(new Filter("(ISNULL(SIM2, '') + ISNULL(SIM, '')", this.searchText, Op.Contains)))
        
        API.QueryUnBind(this.pageIndex, this.pageSize, this.sortKey, this.sortDirect, filters, this.hasChild ? this.loginInfo.HoldID : this.currentHoldId, true, this.containBinded).then(ret => {
          this.tableData = ret.data.list.slice()
          this.pageTotalCount = ret.data.total
          
          resolve()
        }).catch(err => {
          this.print('error', err.message)
          
          reject(err)
        })
      }).finally(() => {
        this.tableLoading = false
      })

      return promise
    },
    selectionChange(sel, row) {
      const index = sel.findIndex(k => k.ObjectID == row.ObjectID)
      if (index != -1) {
        this.addToList(row)
      } else {
        this.deleteFromList(row)
      }
    },
    addToList(row) {
      this.selectList.push(row)
      this.$emit('input', this.selectList)
    },
    deleteFromList(row) {
      deleteFromArray(this.selectList, k => k.ObjectID == row.ObjectID)
      this.$emit('input', this.selectList)
    },
    pageCurrentChange(val) {
      this.pageIndex = val
      this.queryHandle().then(() => {
        this.selectList.forEach(item => {
          const index = this.tableData.findIndex(k => k.ObjectID == item.ObjectID)
          if (index != -1) {
            this.$refs.table.toggleRowSelection(this.tableData[index], true)
          }
        })
      })
    },
    pageSizeChange(val) {
      this.pageIndex = 1
      this.pageSize = val
      this.queryHandle().then(() => {
        this.selectList.forEach(item => {
          const index = this.tableData.findIndex(k => k.ObjectID == item.ObjectID)
          if (index != -1) {
            this.$refs.table.toggleRowSelection(this.tableData[index], true)
          }
        })
      })
    }
  },
  mixins: [
    mixin
  ]
}
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  align-items: stretch;
  .content__left {
    width: 0;
    flex-grow: 1;
    padding-right: 16px;
    .left__header {
      display: flex;
      .el-input {
        flex-grow: 1;
        width: 0;
      }
      .el-button {
        flex-grow: 0;
      }
    }
    .left__tip {
      padding: 16px 0;
      color: #909399;
    }
  }
  .content__right {
    width: 200px;
    display: flex;
    flex-direction: column;
    .list__title{
      background-color: #F4F4F8;
      color: #514C76;
      padding: 8px  16px;
      border: 1px solid #DCDFE6;
      border-bottom: none;
      flex-grow:0;
    }
    .list__body {
      min-height: 420px;
      border: 1px solid #DCDFE6;
      flex-grow: 1;
    }
    .list__item {
      line-height: 24px;
      padding: 0 16px;
      border-bottom: 1px solid #DCDFE6;
    }
  }
}
.v-pager {
  text-align: right;
  & ::v-deep .el-pagination__total {
    float: left;
  }
  & ::v-deep .el-pagination__sizes {
    float: left;
  }
  & ::v-deep .el-input__inner {
    border-radius: 2px !important;
  }
}
.v-table--mini {
  & ::v-deep th,& ::v-deep td {
    padding: 4px;
  }
  & ::v-deep tr th:first-child .cell{
    visibility: hidden;
  }
}
</style>

