import Vue from 'vue'
import VueI18n from 'vue-i18n'
import elementEnLocale from 'element-ui/lib/locale/lang/en'
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'
import elementFrLocale from 'element-ui/lib/locale/lang/fr'
import enLocale from './en'
import zhLocale from './zh'
import frLocale from './fr'
import { getLanguage } from '@/common/cache'

Vue.use(VueI18n)

const messages = {
  en: {
    ...enLocale,
    ...elementEnLocale
  },
  zh: {
    ...zhLocale,
    ...elementZhLocale
  },
  fr:{
    ...frLocale,
    ...elementFrLocale
  }
}

const i18n = new VueI18n({
  locale: getLanguage() || 'zh',
  messages,
  fallbackLocale: 'en'
})

export default i18n
