<template>
  <div class="img-wrapper">
    <el-popover
      placement="bottom-start"
      title="VIP"
      width="200"
      trigger="hover">
      <img src="../assets/icon/vip.png"/>
    </el-popover>

  </div>
</template>

<script>
export default {

}
</script>


<style lang="scss" scoped>

</style>

