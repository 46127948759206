export default {
  system: 'GPS监控平台',
  route: {
    home: '首页',
    gpsMenu: '位置服务',
    riskMenu: '风控管理',
    infoMenu: '信息管理',
    vipMenu: 'VIP管理',
    reportMenu: '报表查询',
    notifyMenu: '公告管理',
    gpsOnlineMonitor: '在线监控',
    cctv: 'DVR视频',
    gpsHistoryPlay: '轨迹回放',
    gpsTrack: '实时追踪',
    gpsRegion: '电子围栏',
    gpsRoad: '线路管理',
    gpsPhotoView: '拍照浏览',
    gpsMessage: '发送信息',
    riskQuery: '信用评估',
    riskAssets: '资产分析',
    riskPlace: '找常停点',
    riskEvent: '暗访追车',
    riskEvaluate: '风控评估',
    riskPrevent: '风险预警',
    riskShcm: '二押点',
    riskPeople: '灰名单',
    infoHold: '单位管理',
    infoVehicle: '设备管理',
    infoDevice: '设备管理',
    infoUser: '账号管理',
    infoPeople: '派单人员',
    infoTask: '工单管理',
    taskSystem: '派单系统',
    vipWallet: '我的钱包',
    vipWalletDetail: '消费明细',
    vipUpdate: '升级VIP',
    vipUpdateHistory: '续费订单',
    walletInvoice: '发票管理',
    userCenter: '个人中心',
    userLog: '操作日志',
    language: '语言',
    exit: '退出',
    setting: '设置',
    spread: '分布热力图',
    gpsMenufk: '位置服务',
    spreadfk: '分布热力图',
    taskSystemfk: '工单系统',
    infoPeoplefk: '技师管理',
    reportMenufk: '报表中心',
    provinceManage: '省份管理',
    visitorlog: '体验日志',
    manageInsurance: '保单管理',
    systemInfo: '系统信息'
  },
  common: {
    back: '返回',
    save: '保存',
    close: '关闭',
    active: '激活',
    cancel: '取消',
    success: '保存成功',
    errorTip: '保存失败，请重试',
    removeChecked: '移除选中',
    removeAll: '移除全部',
    next: '下一步',
    selectDate: '选择日期时间',
    inputText: '请输入内容',
    noResults: '没有查询到结果',
    noMoreResults: '没有更多数据',
    operate: '操作',
    move: '迁移',
    delete: '删除',
    edit: '编辑',
    modify: '修改',
    emptySelectedTip: '请选择操作对象',
    deleteTip: '此操作将永久删除对象?',
    warn: '警告',
    confirm: '确定',
    deleteSuccessed: '删除成功',
    loading: '拼命加载中',
    bind: '绑定',
    refresh: '刷新',
    ok: '确定',
    migrate: '迁移',
    assTaskCar: '关注车辆',
    timeout: '请求超时',
    query: '查找',
    queryAndBind: '绑定车辆',
    funDisabled: '此功能正在维护中，敬请期待',
    netError: '网络连接失败',
    serverError: '服务器开小差了，请稍候再试',
    inputKey: '请输入关键字',
    queryItem: '查询条件',
    joinAll: '全部加入',
    clears: '清除标记',
    addMarkers: '添加兴趣点',
    joinRisk: '添加二押点'
  },
  other: {
    sendSuccess: '发送成功',
    cancelAlarm: '处警',
    cancelAlarlText: '请输入处警内容，或者为空',
    selectAddress: '选择地址'
  },
  contextMenu: {
    refresh: '刷新',
    close: '关闭',
    closeOtherTabs: '关闭其他',
    closeAll: '关闭所有',
    history: '浏览历史',
    newPage: '新页面打开'
  },
  layout: {
    noMessage: '没有新的消息',
    dontShow: '不再显示',
    notify: '消息',
    exit: '退出登录',
    favourite: '收藏夹',
    changeLanguage: '切换语言',
    queryBykey: '车牌/ID/用户名',
    changeLayout: '切换布局',
    topMenu: '顶部菜单',
    rightMenu: '左侧菜单',
    contact: '在线支持',
    '功能': '功能',
    '用户': '用户',
    '车辆': '车辆'
  },
  login: {
    ipAndPort: '设备接入IP:119.23.233.52 端口:6000',
    ip: '设备接入IP',
    port: '端口',
    submitButton: '登录',
    userNamePlaceHolder: ' 请输入用户名',
    passWordPlaceHolder: ' 请输入密码',
    autoLogin: '自动登录',
    autoLoginTitle: '公共场合请不要勾选',
    noUsernameTip: '登录失败。请填写用户名',
    passwordErrorTip: '登录失败。请填写密码',
    pleaseInputMobile: ' 请输入手机号',
    pleaseInputCode: ' 请输入验证码',
    errorMobile: '手机号格式不正确',
    loginBySMS: '短信验证码登录',
    loginByPassword: '账号密码登录',
    loginCheck: '请勾选协议',
    ProtocolTips: '同意并遵守',
    ProtocolName: '软件许可及服务协议',
    ProtocolName2: '隐私政策',
    ourService: '我们的服务',
    wechat: '微信',
    forgetPassword: '忘记密码？',
    findPassword: '找回密码',
    register: '还没有账号？',
    registerAccount: '注册账号',
    scanQR: '扫描二维码',
    followUS: '关注微信公众号',
    downloadAndroidAPP: '下载Android APP',
    downloadIOSAPP: '下载IOS APP',
    anonymous: '我要体验',
    allService: [{
        title: '轻松管理车辆',
        comments: ['自助管理', '便捷定位', '轨迹回放', '丰富报表']
      },
      {
        title: '专业金融风控',
        comments: ['贷前评估', '风险监控', '贷后找车']
      },
      {
        title: 'SIM管理服务',
        comments: ['余额查询', '自助充值', '短信发送']
      },
      {
        title: '贴身定制开发',
        comments: ['协议接入', '开放接口', '个性定制']
      }
    ],
    notFoundID: '没有找到此ID',
    reset: '找回密码'
  },
  forget: {
    findPassword: '找回密码',
    register: '注册',
    login: '登录',
    pleaseInputYourMobile: '请输入你要找回登录密码的手机号',
    mobile: '手机号',
    valid: '验证',
    pleaseDrag: '请将滑块拖动到最右边',
    validSuccess: '验证成功',
    findError: '对不起，没找到手机匹配的账号,请联系管理员！',
    findSuccess: '密码已找回并发送到您的手机或邮箱，请注意查收!',
    findSuccessSms: '验证码已经发送到你的手机，请注意查收',
    findSuccessEmail: '验证码已经发送到你的邮箱，请注意查收',
    resetPassword: '重置密码',
    multiTip: '请选择用户',
    email: 'Email',
    pleaseInputEmail: '请输入电子邮箱地址',
    errorFormatEmail: '邮箱地址输入不正确',
    useEmail: '邮箱注册',
    useMobile: '手机注册'
  },
  register: {
    registerUser: '注册账号',
    pleaseLogin: '登录',
    tip: '已有账号？',
    registerType1: '个人设备注册',
    registerType2: '企业用户注册',
    mobile: '手机号',
    pleaseInputMobile: '请输入手机号',
    valid: '验证',
    nextStep: '下一步',
    mobileFormatError: '手机号码格式不正确，请重新输入',
    pleaseDrag: '请将滑块拖动到最右边',
    validSuccess: '验证成功',
    step1: '设置手机号',
    step2: '填写基本信息',
    step3: '填写设备信息',
    step4: '注册成功',
    errorPhone: '手机号码格式不正确',
    validTitle: '验证手机',
    validTitleEmail: '验证邮箱',
    validTip: '校验码已发送到你的手机，请勿泄漏！',
    validTipEmail: '校验码已发送到你的邮箱，请勿泄漏！',
    validTip2: '校验码已发送至你的手机，请查收',
    validTip2Email: '校验码已发送至你的邮箱，请查收',
    sendButton: '发送验证码',
    validCode: '验证码',
    errorValidCode: '验证码不正确',
    tryAgain: '重新发送',
    name: '姓名',
    password: '登录密码',
    passwordAgain: '确认密码',
    vehicleName: '车牌号',
    articleName: '物品名',
    personName: '姓名',
    assetName: '车牌',
    asset: '设备',
    sim: 'SIM卡号',
    sim2: '终端ID号',
    typeID: '注册类型',
    vehicle: '车辆',
    person: '人员',
    article: '物品',
    registerSuccess: '注册成功，{time}秒后自动前往登录页面',
    companyName: '公司名称',
    contacter: '联系人',
    address: '地址',
    inputNewPassword: '请输入新密码',
    pleaseInputName: '请输入姓名',
    pleaseInputPassword: '请输入密码',
    pleaseInputPasswordAgain: '请再次输入密码',
    pleaseInputVehiclePlate: '请输入车牌号',
    pleaseInputVehiclePlate1: '请输入姓名',
    pleaseInputVehiclePlate2: '请输入物品名',
    placeNametips: "用于平台登录名",
    pleaseInputassetName: '请输入车牌号',
    pleaseInputSim: '请输入SIM卡号',
    pleaseInputSim2: '请输入设备ID号',
    pleaseInputEnterprise: '请输入公司名',
    pleaseInputContacter: '请输入联系人',
    pleaseInputAddress: '请输入地址',
    pleaseInputSixNumber: '请输入6至18位密码',
    pleaseInputSixNumber2: '请输入6到18位字母或数字',
    errorFormat: '输入格式错误',
    checkPassError: '两次密码输入不一致',
    pleaseLoginTip1: '请使用车牌和登录密码登录',
    pleaseLoginTip2: '请使用手机号和密码登录',
    pleaseLoginTip3: '请使用注册邮箱和登录密码登录',
    pleaseLoginTip4: '请使用车牌和登录密码登录',
    customerAccount: '客户账号'
  },
  batchImport: {
    title: '信息导入',
    tip1: '将文件拖到此处, 或',
    tip2: '点击上传',
    tip3: '点击下载',
    tip4: '导入模板',
    fileName1: '车辆导入模板.xlsx',
    fileName2: '设备导入模板.xlsx',
    fileName3: '工单导入模板.xlsx',
    fileName4: '批量更新SIM卡号模板.xlsx',
    fileName5: '保单导入模板.xlsx',
    fileName6: '人员设备导入模板.xlsx',
    fileName7: '物品设备导入模板.xlsx',
    fileName8: '批量更新车牌模板.xlsx',
    download: '下载',
    close: '关闭',
    downloadDetail: '下载导入详情',
    closePage: '关闭本页面',
    importdetail: '导入详情',
    importProcess: '正在导入中...',
    errTip: '上传失败，请检查网络连接或稍后再试',
    target: '导入目录',
    nowLoading: '正在读取...',
    nowProcess: '正在导入...',
    nowEnd: '导入结束'
  },
  home: {
    '低电压报警': '低电压报警',
    '低电量报警': '低电量报警',
    '拆机报警': '拆机报警',
    '剪线报警': '断电报警',
    '区域报警': '区域报警',
    '围栏报警': '围栏报警',
    '双离线报警': '双离线报警',
    '设备分离报警': '设备分离报警',
    '出省报警': '出省报警',
    '二押点报警': '二押点报警',
    '断电报警': '断电报警',
    '区域报警': ' 区域报警',
    '围栏报警': '围栏报警',
    '紧急报警': '紧急报警',
    '偏离路线报警': '偏离路线报警',
    '跌落报警': '跌落报警',
    '碰撞报警': '碰撞报警',
    '超速报警': '超速报警',
    '禁行报警': '禁行报警',
    '滞留报警': '滞留报警',
    simState: 'SIM卡状态',
    key1: 'SIM卡过期数量',
    key2: '设备在线数',
    key3: '无线设备离线情况',
    key4: '最近90天新增入网',
    chart1: {
      '正常': '正常',
      '即将到期': '即将到期',
      '已到期': '已到期',
      '自购卡': '自购卡'
    },
    chart2: {
      '在线': '在线',
      '离线1天内': '离线<1天',
      '离线1-7天': '离线>1天',
      '离线7-30天': '离线>7天',
      '离线30天以上': '离线>30天',
      '未上线': '未上线',
      '不在线': '不在线',
      '离线': '离线',
      '正常': '正常'
    },
    chart3: {
      '未上线': '未上线',
      '1天内': '1天内',
      '1-7天': '1-7天',
      '7-30天': '7-30天',
      '30天以上': '30天以上',
      '在线': '在线',
      '不在线': '不在线',
      '离线': '离线',
      '正常': '正常'
    },
    chart4: {

    },
    chartOnline: {
      s1: '在线',
      s2: '离线',
      total: '设备总数'
    },
    msgTips: '设备ID号已复制',
    unit1: ' 张',
    unit2: ' 辆',
    unit3: ' 台',
    unit4: ' 年',
    recentNew: '最近365天新增入网',
    recentActive: '最近激活设备数量',
    count: '数量',
    incomeExpired: '即将到期列表',
    nodate: '暂无数据',
    offlineRate: '离线设备占比',
    gpsOnlineRate: '有线设备在线率',
    wireGpsOnlineRate: '无线设备在线率',
    remainDay: '剩余{number}天',
    titleOnline: '在线统计',
    titleDevice: '设备分布',
    titleNet: '入网',
    titleTotal: '设备在线数',
    titleNew: '新增入网统计',
    titleYear: '近3年入网',
    titleAlarm: '报警统计',
    titleEven: '报警事件',
    noDatas: "暂无消息",
    copy: '复制',
    deviceStatus1: "车辆",
    deviceStatus2: "人员",
    deviceStatus3: "物品",
  },
  commonTreeUser: {
    search: '查找',
    searchUser: '查找单位',
    searchUserOrDevice: '查找单位或设备',
    back: '返回',
    backParent: '返回上级',
    backRoot: '返回根用户',
    includeChild: '包含下级',
    userList: '单位列表',
    checkContainTip: '勾选包含下级用户',
    checkNotContainTip: '勾选不包含下级用户',
    exchange: '切换模式',
    exchangeCountVisible: '切换设备数量显示',
    pleaseInputKey: '请输入关键字',
    keyTip: '车牌/设备ID/车架号/工单号/车主姓名',
    loading: '正在查找...',
    searchPlace: '搜索单位'
  },
  commonListVehicle: {
    search: '查找',
    searchVehicle: '输入队员姓名/设备ID',
    serarchDev: '输入设备ID/名称',
    vehicleList: '车辆列表',
    modify: '修改',
    command: '指令',
    rcvTime: '接收时间',
    noDevice: '无绑定设备',
    noVehicle: '无车辆信息',
    all: '全部',
    online: '在线',
    offline: '离线',
    onAlarm: '报警',
    unused: '未用',
    star: '关注',
    typeAll: '全部',
    typeVehicle: '车辆',
    typePerson: '人员',
    typeAirtcle: '物品',
    allTip: '显示全部状态的车辆',
    onlineTip: '仅显示在线状态的车辆',
    offlineTip: '仅显示离线状态的车辆',
    onAlarmTip: '仅显示报警状态的车辆',
    unusedTip: '仅显示未使用的车辆',
    starTip: '仅显示关注的车辆',
    expiredDevice: '该设备VIP已到期，请升级后查看',
    simTip: '*未填写设备ID，该号码为SIM卡号',
    data: '资料',
    enterprise: '用户资料',
    sms: '发送短信',
    vehicleInfo: '车辆资料',
    deviceInfo: '设备资料',
    totalRecord: '共{count}条记录',
    deviceList: '设备列表',
    userList: '用户列表',
    followStatus: "关注成功",
    followStatus2: "取消关注",
  },
  commonPanel: {
    place: '位置',
    clickViewLocation: '点击查看位置',
    showLngLat: '显示经纬度',
    showLocation: '显示地址信息',
    noPlaceTip: '暂无位置信息',
    noPlace: '无',
    battery: '电量',
    endurance: '续航',
    day: '天',
    wake: '回传',
    lbsLocation: '基站定位',
    wifiLocation: 'WIFI定位',
    gpsLocation: 'GPS位置',
    lastLocation: '最后上传',
    satelLocation: '卫星定位',
    wakeTime: '回传',
    temperature: '温度',
    allMiles: '总里程'
  },
  commonManage: {
    search: '查询',
    selectType: '请选择',
    inputPlaceHolder: '请输入查找内容'
  },
  userManage: {
    userName: '用户名称',
    account: '账号',
    contacer: '联系人',
    phone: '紧急联系电话',
    createTime: '创建时间',
    remark: '备注',
    fullPath: '用户路径',
    addHold: '增加用户',
    deleteChecked: '删除选中',
    changeParent: '迁移用户',
    export: '导出',
    includeChild: '包含下级',
    search: '查询'
  },
  vehicleManage: {
    vehicleName: '车牌',
    vehiclePeople: '车主',
    vehicleBrand: '品牌',
    vehicleType: '车型',
    deviceCount: '设备数量',
    createTime: '创建时间',
    remark: '备注',
    add: '增加车辆',
    delete: '删除选中',
    change: '迁移用户',
    export: '导出',
    upload: '导入',
    includeChild: '包含下级',
    removeSuccess: '移除成功',
    addDevice: '新增设备',
    searchBind: '查找绑定',
    enterprise: '所属用户',
    storeName: '门店',
    wono: '工单号',
    vin: '车架号',
    installTime: '激活时间',
    nodevice: '没有设备'
  },
  deviceManage: {
    deviceID: '设备ID',
    vehicleName: '车牌',
    deviceName: '名称',
    articleName: '物品名',
    articleId: '物品ID',
    articleColor: '颜色',
    articleWeight: '重量',
    articleLong: '长度',
    articlesize: '尺寸',
    articleWidth: '宽度',
    articleHigh: '高度',
    personName: '姓名',
    personId: '人员ID',
    personAge: '年龄',
    personIDCard: '身份证号码',
    personPhone: '手机号码',
    personSex: '性别',
    pesonGender1: '男',
    pesonGender2: '女',
    personType: '人员类别',
    vip: 'VIP有效期',
    status: '状态',
    online: '在线',
    offline: '离线',
    acc: ' 状态',
    alarm: '报警',
    rcvTime: '接收时间',
    gpsTime: 'GPS时间',
    offlineTime: '离线时长',
    sim: 'SIM卡号',
    place: '位置',
    remark: '备注',
    createTime: '创建时间',
    deviceType: '设备类型',
    mdtType: '设备协议',
    add: '增加设备',
    active: '激活设备',
    delete: '删除选中',
    export: '导出',
    changeParent: '批量迁移',
    upload: '批量导入',
    uploadsim: '批量修改SIM卡号',
    includeChild: '包含下级',
    optionKey1: '所有',
    optionKey2: '在线',
    optionKey3: '离线',
    optionKey4: '运动',
    optionKey5: '熄火',
    optionKey6: '报警',
    optionKey7: '无报警',
    optionKey8: 'SIM卡正常状态',
    optionKey9: 'SIM卡即将到期',
    optionKey10: 'SIM卡已到期',
    optionKey11: 'SIM卡余额不多',
    optionKey12: 'VIP到期',
    optionKey13: 'VIP7天到期',
    optionKey14: 'VIP30天到期',
    optionKey15: 'VIP60天到期',
    statusKey1: "全部",
    statusKey2: "未激活",
    statusKey3: "正常",
    statusKey4: "停机",
    statusKey5: "离线",
    optionKey16: "静止",
    optionKey17: "参考定位",
    displayMore: '查看更多',
    wrap: '换行显示',
    updateVip: '升级VIP',
    moverDevices: '迁移设备',
    keyOnline: '在线',
    keyOffline: '离线',
    keyOnRoad: '启动,行驶',
    keyDaisu: '启动,怠速',
    keyRef: '启动,参考定位',
    keyMov: "运动",
    keyStop: '静止',
    keyNoGps: '参考定位',
    keyNoSpeed: '熄火',
    keyNoSpeedRef: '熄火,参考定位',
    keyOffRef: '离线',
    keyAlarm: '报警',
    keyNormarl: '正常',
    holdName: '所属部门',
    remainDays: '剩余天数',
    days: '天',
    normalSearch: '普通查找',
    multipleSearch: '批量查找',
    textareaTip: '请输入设备ID，多个设备ID之间以英文逗号分隔，单次最多输入1000个',
    enterTip: '已输入 {count} 个设备ID',
    clear: '清空',
    simInfo: 'SIM卡信息',
    deviceMode: '设备模式',
    vehicleMode: '车辆模式',
    Index: '序号',
    SIM2: '设备ID',
    SIM: 'SIM卡号',
    MDTModel: '设备型号',
    TrackerTypeName: '设备类型',
    Remark: '备注',
    errDesc: '导入结果',
    errMsg: '导入详情',
    clickViewDetail: '点击查看设备详情',
    clickViewSIM: '点击查看SIM卡信息',
    trace: '轨迹报表',
    findDevice: '已找到{count}个设备',
    inputAgain: '重新输入',
    peopleName: '姓名',
    wono: '工单号',
    storeName: '门店',
    installTime: '激活时间',
    vin: '车架号',
    effectiveWay: '生效方式',
    effectiveNow: '立即生效',
    effectiveOnActive: '工单通过后生效',
    effectiveDelay: '立即生效并增加3个月',
    oneYear: '一年',
    twoYear: '两年',
    threeYear: '三年',
    fourYears: '四年',
    fiveYears: '五年'
  },
  accountManage: {
    account: '账号',
    userName: '姓名',
    userType: '类型',
    startTime: '启用日期',
    endTime: '截止日期',
    remark: '备注',
    fullPath: '用户路径',
    add: '增加账号',
    delete: '删除选中',
    export: '导出',
    includeChild: '包含下级'
  },
  peopleManage: {
    userName: '姓名',
    account: '账号',
    phone: '电话',
    createTime: '创建时间',
    fullPath: '用户路径',
    remark: '备注',
    add: '新增人员',
    delete: '删除选中',
    export: '导出',
    includeChild: '包含子集',
    enterprise: '所属用户',
    userType: '用户类型'
  },
  taskManage: {
    orderNo: '工单号',
    installPeople: '安装人员',
    startTime: '开始时间',
    endTime: '结束时间',
    total: '总数量',
    taskState: '任务状态',
    remark: '备注',
    add: '增加工单',
    delete: '删除选中',
    export: '导出',
    createTime: '创建时间',
    agentName: '客服',
    expectTime: '上门时间',
    projectName: '项目名',
    customerName: '客户姓名',
    vin: '车架号',
    checkTime: '工单完成时间',
    orderStatus: {
      '0': '已创建',
      '1': '待接单',
      '2': '安装中',
      '3': '审核中',
      '9': '已完成'
    }
  },
  wallet: {
    time: '时间',
    name: '服务',
    vpoint: '沃点',
    remain: '剩余',
    balance: '余额',
    accountBalance: '账户余额',
    orderNo: '订单号',
    remarkOrder: '订单号/备注',
    remark: '备注',
    createUser: '用户',
    user: '用户/账号',
    account: '账号',
    openWallet: '打开钱包',
    export: '导出',
    includeChild: '包含下级',
    orderDetail: '订单详情',
    recharge: "续费订单",
    readDetail: '查看续费记录',
    recgargeTitle: '最近交易记录',
    vehicleName: '车牌',
    holdName: '所属部门',
    deviceID: '设备ID',
    amount: '升级时长',
    vipEndDate: 'VIP到期日',
    wallet: '钱包',
    sendVPoint: '赠送沃点',
    gift: '转赠',
    pay: '充值金额',
    payPrefix: '充',
    payMethod: '充值方式',
    alipay: '支付宝',
    wechat: '微信',
    alipayTip: '数亿用户都在用，安全可托付',
    wechatTip: '亿万用户的选择，更快更安全',
    payButton: '立即支付',
    custom: '自定义',
    payTotal: '支付金额',
    payUnit: '元',
    backMine: '返回我的钱包',
    successTip: '支付成功，您的沃点已增加{count}点， 当前剩余{total}点',
    bottomButton: '知道了',
    targetUser: '转赠对象',
    targetCount: '转赠数量',
    sendSuccessed: '赠送成功',
    limitRemain: '剩余沃点不足{count}点',
    confirmSend: '确定赠送{count}沃点给{target}, 赠送后将剩余{remain}沃点?',
    tip: '提示',
    giftPrefix: '赠',
    selectUser: '请选择用户',
    buy: '充',
    year: '年',
    recentOrders: '最近交易记录',
    viewAll: '查看全部',
    addVpoint: '充值沃点',
    addVpointDesc: '沃点用于升级车辆VIP',
    orderDetailDesc: '查看钱包支付记录',
    invoiceManage: '发票管理',
    invoiceManageDesc: '查看或开具发票',
    pleaseInputRemark: '请输入备注',
    administrator: '超级管理员',
    currentUser: '当前用户',
    confirmSend2: '确定赠送{count}沃点给{target}？'
  },
  updateVip: {
    orderNo: '订单号',
    holdName: '所属部门',
    orderCount: '续期数量',
    orderPoint: '消费(沃点)',
    orderStatus: '订单状态',
    orderUser: '缴费人',
    createTime: '创建时间',
    updateTime: '更新时间',
    includeChild: '包含下级',
    add: '升级VIP',
    delete: '删除选中',
    view: '查看',
    orderDetail: '订单详情',
    vehicleName: '车牌',
    deviceID: '设备ID',
    amount: '升级时长',
    vipEndDate: 'VIP到期日',
    sim: 'SIM卡',
    trackerType: '有线/无线',
    online: '是否在线',
    wallet: '钱包',
    sendVPoint: '赠送沃点',
    updateVip: '升级VIP',
    adminVip: '管理员续费',
    selectVehicle: '选择设备',
    remain: '余额',
    addPoint: '去充值',
    vpoint: '沃点',
    payAmount: '升级时长',
    year: '年',
    month: '月',
    payMethod: '支付方式',
    wechat: '微信',
    alipay: '支付宝',
    payFee: '支付金额',
    payUnit: '元',
    selected: '已选择',
    deviceUnit: '台',
    device: '设备',
    orderTip1: '请核对您的订单信息',
    orderName: '服务名称',
    orderAmount: '升级时长',
    orderVehicle: '升级车辆',
    orderMethod: '支付方式',
    payVehicle: '续费设备',
    payTime: '续费时长',
    orderTip2: '请使用微信扫描二维码完成支付',
    nextButtonText: '继续支付',
    backBUttonText: '重新选择',
    updateSuccess: '升级成功',
    viewOrder: '查看订单',
    selectedList: '已选择列表',
    total: '共',
    feeTip: '有线设备{price1}元/年，无线设备{price2}元/年',
    pleaseSelectVehicle: '请先选择续费设备',
    expired: '已到期',
    vipTip1: 'VIP车辆保存90天以上历史数据，拥有全部功能',
    vipTip2: '免费车辆保存7天历史数据，拥有基本定位功能',
    dateTo: 'VIP有效期至',
    vipFeatures: '续费特权',
    vipFeature90: '90天历史轨迹',
    vipFeaturePhoto: '拍照浏览',
    vipFeatureGeo: '电子围栏',
    vipFeatrueOil: '油耗报表',
    vipFeaturePlace: '找常停点',
    vipFeatureMore: '更多功能',
    oneCLickPay: '一键续费',
    myOrder: '历史订单',
    byMonth: '按月续费',
    byDate: '固定日期',
    confirmUpdateMsg: '确认为{count}辆车续费么？续费方式:{option}，时长:{date}',
    monthUnit: '个月',
    canYouConfirmAll: '是否选中查询结果中所有车辆?'
  },
  userLog: {
    operateTime: '操作时间',
    user: '用户/账号',
    userName: '用户名称',
    account: '账号',
    operateType: '操作类型',
    description: '描述',
    remark: '备注',
    range: '查询时间',
    '新增': '增加',
    '增加': '增加',
    '修改': '修改',
    '删除': '删除',
    '登录': '登录',
    '迁移': '迁移',
    '手机客户端': '手机客户端',
    'order1': '成功发出解除报警指令',
    '修改安装照片同时更新APP工单照片地址': '修改安装照片',
    '下发终端指令': '下发终端指令',
    '系统': '系统',
  },
  userCenter: {
    title: '个人中心',
    myProvider: '我的服务商',
    provider: '服务商',
    contacter: '联系人',
    phone: '电话',
    address: '地址',
    email: '邮箱',
    changePassword: '修改密码',
    changePasswordTip: '为保障账户安全，建议定期修改登录密码',
    change: '修改',
    customlize: '自定义设置',
    customlizeTip1: '请填写1-30位的中文或数字、英文标题!',
    customlizeTip2: '请上传大小为40px*40px的png图片!',
    upload: '上传',
    changeTitle: '修改标题',
    newTitle: '新标题',
    changeLogo: '修改Logo',
    newLogo: '新Logo',
    changeLogoTip: '将文件拖到此处，或',
    clickUpload: '点击上传',
    emptyWarning: '请输入标题',
    changeAllLogo: '下级标题与Logo设置',
    changeAllLogoTip: '将当前设置标题、Logo应用所有下级，请选择项目',
    changeAllLogoOption1: '标题',
    changeAllLogoOption2: 'Logo',
    changeAllLogoConfirm: '请在文本框中输入"确认修改"，并点击保存按钮',
    changeAllLogoConfirmText: '确认修改',
    changeMobile: '修改手机号',
    newMobile: '新手机号'
  },
  changePassword: {
    key1: '修改密码',
    key2: '原密码',
    key3: '新密码',
    key4: '确认密码',
    tip1: '请输入旧密码',
    tip2: '请输入新密码',
    tip3: '请再次输入新密码',
    tip4: '两次输入密码不一致'
  },
  holdDetail: {
    name: '用户详情',
    basicInfo: '基本信息',
    authInfo: '权限信息',
    parentHoldName: '所属用户',
    holdName: '用户名称',
    userName: '登录账号',
    password: '登录密码',
    contacter: '联系人',
    email: '邮箱',
    phone: '联系手机',
    remark: '备注',
    address: '联系地址',
    timezone: '时区',
    ServiceEndTime: '有效日期',
    placeHolder1: '请输入用户名称',
    placeHolder2: '请输入登录账号',
    placeHolder3: '请输入登录密码',
    placeHolder4: '请输入联系人',
    placeHolder5: '请输入电话',
    placeHolder6: '请选择用户有效期',
    defaultProvince: '默认用车省份',
    byInstallAddr: '设备安装所在省份',
    byFixed: '固定地址',
    nullAddress: '--无--',
    random: '随机'
  },
  vehicleDetail: {
    name: '车辆详情',
    QueryPwd: '查询密码',
    basicInfo: '基本信息',
    peopleInfo: '车主信息',
    moreInfo: '其他信息',
    holdName: '所属用户',
    vehiclePlate: '车牌号码',
    devicePlate: "设备名称",
    vehicleColor: '车牌颜色',
    vehicleBrand: '车辆品牌',
    vehicleType: '车辆类型',
    deviceID: '设备ID',
    deviceType: '设备型号',
    deviceLine: '接线类型',
    remark: '备注',
    peopleName: '姓名',
    peopleSex: '性别',
    male: '男',
    female: '女',
    phone: '电话',
    idcard: '证件号码',
    wechat: '微信',
    qq: 'QQ',
    Age: '年龄',
    homeAddress: '家庭地址',
    companyAddress: '公司地址',
    vehicleId: '车架号码',
    vehicleMachineId: '发动机号',
    actionRemove: '拆除',
    actionUnBind: '解绑',
    actionViewInstall: '查看安装信息',
    actionInstall: '安装',
    moveVehicle: '迁移车辆',
    pleaseSelectVehicle: '请选择车牌号码',
    pleaseInputVehicle: '请输入车牌号码',
    pleaseSelectBrand: '请选择车辆品牌',
    pleaseSelectType: '请选择车辆类型',
    pleaseInputName: '请输入姓名',
    pleaseSelectSex: '请选择性别',
    pleaseInputTelephont: '请输入电话号码',
    pleaseInputAge: '请输入年龄',
    pleaseInputIdCard: '请输入证件号码',
    pleaseInputWechat: '请输入微信',
    pleaseInputQQ: '请输入QQ',
    pleaseInputFrameNo: '请输入车架号',
    pleaseInputEngineNo: '请输入发动机号',
    bindDevice: '绑定设备',
    installDevice: '安装详情',
    saveInstallInfo: '保存安装信息并提交审核',
    noDeviceInfo: '未装定位设备',
    clickToSelect: '点击选择',
    tip: '提示',
    unbindText: '确认移除该设备与车辆的绑定？',
    location: '经纬度',
    getLocation: '获取',
    getSuccess: '获取位置成功',
    needConfirm: '此位置范围过大，请点击右侧放大镜选择准确位置',
    getFailed: '获取位置失败，请点击右侧放大镜定位',
    savePeopleTip: '保存地址信息时需要先录入车主姓名',
    wono: '工单号',
    carInstallStore: '门店名称',
    carCost: '车辆价值(元)',
    carStorePhone: '门店电话',
    carInstallTime: '激活时间',
    selectPos: '选择',
    installed: '装车设备',
    saveAddressTip: '保存家庭地址(公司地址)需要填写姓名',
    usevandplogin: '使用车牌与查询密码登录平台查询车辆位置',
    '不规则形体': '不规则形体',
    '长方体': '长方体',
    '圆形/椭圆形': '圆形/椭圆形',
    '儿童': '儿童',
    '老人': '老人',
    '中年': '中年',
    '青年': '青年',
    '其他': '其他',
    'A': 'A队队员',
    'A+': 'A+队队员'
  },
  installInfo: {
    vehicleNumber: '车牌号码',
    deviceID: '设备ID',
    installTime: '安装时间',
    installPeople: '安装人员',
    installPlace: '安装位置',
    installPhoto: '安装照片',
    remark: '备注',
    dianping: '电瓶',
    fujiashi: '副驾驶杂物箱',
    zuocetaban: '左侧踏板',
    youcetaban: '右侧踏板',
    fangxiangpan: '方向盘下边',
    qiandingdeng: '前顶灯',
    houdingdeng: '后顶灯',
    houbeixiang: '后备箱',
    qita: '其他',
    change: '更换',
    pleaseSelectDate: '请选择安装时间',
    pleaseSelectPeople: '请选择安装人员',
    pleaseSelectPosition: '请选择安装位置',
    pleaseUploadPhoto: '请上传照片',
    errorTip1: '上传头像图片只能是 JPG 格式!',
    errorTip2: '上传头像图片大小不能超过 5MB!',
    exceedTip: '最多上传9张图片',
    exceedTip4: '最多上传4张图片',
    installPositionNumber: '请输入位置编号'
  },
  deviceDetail: {
    name: '设备详情',
    personName: '人员',
    articleName: '物品',
    articleNameTag: '物品',
    personNameTag: '人员',
    deviceName: '名称',
    personName: '姓名',
    personId: '人员ID',
    personAge: '年龄',
    personIDCard: '身份证号码',
    personPhone: '手机号码',
    personSex: '性别',
    pesonGender1: '男',
    pesonGender2: '女',
    personType: '人员类别',
    personPic: '照片',
    articleName: '物品名',
    articleWeight: '重量',
    articleLong: '长度',
    articleHigh: '高度',
    articleColor: '物品颜色',
    articlesize: '尺寸',
    articleWidth: '宽度',
    articleShape: '物品形状',
    Unit: '厘米',
    Unit2: 'KG',
    holdName: '所属用户',
    payEndDate: 'VIP有效期',
    deviceID: '设备ID',
    sim: 'SIM卡号',
    deviceType: '设备型号',
    remark: '备注',
    timezone: '时区',
    pleaseInputSim: '请输入SIM卡号码',
    pleaseInputDeviceID: '请输入设备ID',
    pleaseSelectDeviceType: '请选择设备型号',
    device: '设备',
    vehicle: '车辆',
    showMore: '展开更多信息',
    showLess: '收起更多信息',
    saveVehicleInfo: '录入车辆信息',
    tip1: '提示：',
    tip2: '1.您可以在增加设备时同时保存车辆信息并自动将设备与车辆绑定，也可以点击车辆信息右侧的移除按钮移除车辆信息，单独保存设备信息，稍候可在车辆管理页面将设备与车辆绑定。',
    tip3: '2.若您需要设备与现有车辆绑定请使用车辆管理功能。',
    unBindTip: '将设备与车辆{name}解除绑定么',
    unBindConfirm: '确认信息',
    unBindSuccess: '已解除绑定',
    selectVehicle: '选择车辆{name}',
    bindSuccess: '绑定成功',
    select: '选择',
    bind: '绑定',
    tip4: '请选择车辆将设备与车辆绑定',
    clickToUnbind: '点击解除车辆绑定',
    remainTime: '剩余时间',
    days: '天',
    install: '安装',
    installTime: '安装时间',
    installPlace: '安装位置',
    installPeople: '安装人员',
    installPhoto: '安装照片',
    showInstallInfo: '显示安装信息',
    saveInstallInfo: '录入安装信息',
    clickToDeleteInformation: '点击删除安装信息',
    deleteInfoConfirm: '确认要删除安装信息么',
    tip: '提示',
    vehicleProvince: '用车区域',
    pleaseSelectProvince: '请选择用车区域',
    detailInfo: '详细信息',
    deviceInfo: '设备信息',
    mdtType: '通信协议',
    createTime: '录入日期',
    simInfo: 'SIM信息',
    gpsInfo: 'GPS信息',
    gpsTime: 'GPS时间',
    oil: '油量',
    rcvTime: '接收时间',
    speed: '速度',
    miles: '里程',
    location: '位置',
    statsuDes: '状态',
    userInfo: '用户信息',
    contacter: '联系人',
    contactTel: '联系电话',
    addr: '地址',
    vehicleType: '车辆类型',
    brandIcon: '品牌',
    editImg: '编辑图片',
    editImgTip: '裁剪为合适的大小',
    editImgSuccess: '保存成功',
    contactInfo: '注册手机',
    WebCam: '摄像头'
  },
  userDetail: {
    name: '账号详情',
    basicInfo: '基本信息',
    autoInfo: '权限信息',
    holdName: '所属用户',
    realName: '账号昵称',
    userName: '登录账号',
    password: '登录密码',
    startTime: '启用日期',
    endTime: '截止日期',
    remark: '备注',
    timezone: '时区',
    checkAll: '全选',
    cancelAll: '取消',
    pleaseInputLongUserName: '用户账号太短',
    pleaseInputUserName: '请输入用户账号',
    pleaseInputPassword: '请输入用户密码',
    pleaseInputName: '请输入用户姓名',
    pleaseSelctStartTime: '请选择启用日期',
    pleaseSelectEndTime: '请选择截止日期',
    mobile: '手机号'
  },
  bindDevice: {
    placeHolder: '请输入设备ID查询',
    tip: '提示: 一辆车可以绑定多台设备',
    deviceID: '设备ID',
    sim: 'SIM卡号',
    deviceModel: '设备型号',
    trackerType: '接线类型',
    remark: '备注',
    selected: '已选列表',
    search: '查询',
    viewAll: '查看全部',
    vehicleName: '车牌',
    containAll: '包含绑定设备'
  },
  popoverInfo: {
    enterprise: '所属用户',
    deviceID: '设备ID',
    SIM: 'SIM',
    deviceModel: '类型',
    remark: '备注',
    modifier: '修改人',
    updateTime: '修改时间',
    vehicle: '车牌',
    queryPswd: '查询密码',
    bingCount: '绑定数量',
    owner: '车主',
    ownerMobile: '电话',
    ownerAddress: '地址',
    createUser: '创建人',
    createTime: '创建时间',
    loginName: '登录账号',
    serviceEnddate: '有效期',
    contacter: '联系人',
    mobile: '电话'
  },
  favorite: {
    name: '关注设备',
    detail: '车辆详情',
    empty: '清空',
    export: '导出',
    print: '打印',
    fullScreen: '全屏',
    monitor: '监控',
    vehicleName: '设备名',
    peopleName: '车主姓名',
    deviceID: '设备ID',
    alarm: '报警',
    statusdes: '状态',
    riskStatus: '风险状态',
    operation: '操作',
    remove: '不关注',
    onlineDevice: '有线设备',
    nolineDevice: '无线设备',
    time: '时间',
    remark: '备注',
    isAlarm: '正在报警',
    highRisk: '高风险',
    middleRisk: '中等风险',
    lowRisk: '低风险',
    tip: '提示',
    text1: '确认要移除全部关注设备么？',
    excelName: '设备详情',
    batteryTip1: '无线设备,电量{number}%',
    battery: '电量',
    removeOne: '确认不再关注设备{name}么？'
  },
  onlineMonitor: {
    userList: '用户列表',
    sendSMS: '短信指令',
    sendSMSTip: '请输入终端SIM卡号码',
    sendSMSError: 'SIM卡号码格式不正确',
    favoritePlace: '查找兴趣点',
    favoritePlaceTip: '请输入关键字查找兴趣点',
    favoriteRegion: '电子围栏',
    favoriteRegionTip: '请输入关键字查询电子围栏',
    favoriteRoadTip: '请输入关键字查询电子线路',
    regionSearch: '区域查找',
    regionSearchTip: '在地图上绘制方形区域查找车辆',
    refreshTip: '{count} 秒后刷新',
    refreshAction: '暂停',
    refreshResume: '继续',
    ruler: '测距',
    save: '保存地图视野范围',
    fullScreen: '全屏',
    emptyMap: '清空地图',
    batchCommand: "批量指令",
    exit: '退出',
    searchEryadian: '查找二押点',
    searchEryadianTip: '请输入关键字查询二押点',
    distanceTip: '点击鼠标左键获取测量点，右键结束测距',
    deviceName: "名称",
    vehicleName: '车牌',
    speed: '速度',
    totalMile: '总里程',
    oil: '油量',
    statsDes: '状态信息',
    gpsTime: 'GPS时间',
    dataTime: '接收时间',
    rcvTime: '接收时间',
    place: '位置',
    lineFeed: '换行',
    deviceID: '设备ID',
    directoin: '方向',
    remove: '移除',
    mile: '里程',
    dayMiles: '当日里程',
    allMiles: '总里程',
    gps: '定位',
    lbs: '基站',
    wifi: 'WIFI',
    viewInfo: '查看信息',
    realSence: '显示实景',
    gpsHistoryPlay: '轨迹回放',
    gpsTrack: '实时跟踪',
    setCommand: '指令设置',
    riskScan: '风险检测',
    alarm: '报警',
    noAlarm: '没有报警',
    haslineDevice: '有线',
    nolineDevice: '无线',
    noRealsenceTip: '此处无街景',
    unit1: '公里/小时',
    unit2: '公里',
    unit3: '升',
    unit4: '千克',
    un1: 'KM/H',
    un2: 'KM',
    un3: '°C',
    panelLink1: '基站/WIFI',
    panelLink2: '查看信息',
    panelLink3: '显示实景',
    panelLink4: '轨迹回放',
    panelLink5: '指令发送',
    panelLink6: '风险检测',
    panelLink7: '发送短信',
    panelLink8: '设防',
    panelLink9: '撤防',
    panelLink10: '关注',
    panelLink11: '取消关注',
    panelLink12: '追踪',
    panelLink13: '应急预案',
    panelLink14: '胎压监测',
    setSF: '设防成功',
    cancelSF: '撤防成功',
    noLbsTip: '该设备没有基站与WIFI信息，请稍候再试.',
    lngLat: '经纬度',
    removeFavirite: '移除兴趣点',
    removeEryadian: '移除二押点',
    saveMapInfo: '保存地图位置成功',
    noPositionTip: '设备没有使用',
    regionName: '区域名称',
    createTime: '创建时间',
    deletePoiTip: '删除兴趣点{name}成功',
    savePoiTitle: '请输入兴趣点名称',
    savePoiTip: '请输入',
    savePoiTip2: '输入名称过长',
    saveSuccessed: '添加成功',
    savePoiTip3: '请点击地图添加兴趣点',
    layers: '图层',
    layerNormal: '标准',
    layerZixingche: '自行车图',
    layerTraffic: '交通图',
    layerHumanitarian: 'Humanitarian',
    layerSatellite: '卫星图',
    layerGoogle: '谷歌地图',
    layerBaidu: '百度地图',
    layerTecent: '腾讯地图',
    monitorAll: '监控全部',
    refresh: "点击刷新",
    onRefresh: "同步刷新开启",
    offRefresh: "同步刷新关闭",
    monitorAllTip: '当前列表设备较多，仅加载500个设备',
    all: '总',
    simcard: 'SIM卡号码',
    pleaseInputSimcard: '请输入SIM卡号码',
    inputSimcardSeeHistory: '输入SIM卡号码查看历史记录',
    alarmType: '报警类型',
    enterprise: '单位名',
    refreshUserTree: '刷新单位列表',
    fullscreen: "切换全屏",
    showAddress: '默认显示地址',
    peopleName: '车主',
    vin: '车架号',
    storeName: '门店名称',
    setViewItem: '设置显示内容',
    searchPlace: '查找地址',
    clickRightRemove: '点击右键移除',
    emptyList: '清空',
    uName: '姓名',
    uAge: '年龄',
    uSex: '性别',
    uMale: '男',
    uFemale: '女',
    aName: '物品名',
    aId: '物品编号',
    aWeight: '重量',
    aSize: '尺寸',
    aColor: '颜色',
    statusTip1: '运动',
    statusTip2: '熄火',
    statusTip3: '静止',
    statusTip4: '离线',
    statusTip5: '报警',
    statusTip6: 'GPS弱',
    statusTip7: '未使用',
    alamStatus1: "在线",
    alamStatus2: "报警",
    alamStatus3: "总数",
    disalarm: '解除报警',
    lastLocation: '最后上传',
    gps1: "强",
    gps2: "中",
    gps3: "弱",
    gsm1: "强",
    gsm2: "中",
    gsm3: "弱",
    owner: '驾驶员',
    goods: '运输货物',
    departure: '出发地',
    destination: '目的地',
    weight: '重量',
    personInfo: '人员信息',
    assetInfo: '物品信息',
    tireHight: '过高',
    tireLow: '过低',
    tireStatus1: '急漏气',
    tireStatus2: '慢漏气',
    tireStatus3: '加气',
    tireStatus4: '低电量',
    tireTitle: '胎压监测',
    tireTimes: '监测时间',
    tirePress: '胎压',
    tireTemp: '温度',
    tireDialogTips1: '没有胎压相关信息',
    tireDialogTips2: '没有温度曲线和胎压曲线数据',
    tireBegin: '开始日期',
    tireIner: '至',
    tireEnd: '结束日期',
  },
  onlineTrack: {
    vehicleInfo: '车辆信息',
    vehicleNumber: '设备名',
    deviceList: '设备列表',
    vehicleSelectTip: '请选择车辆',
    placeParams: '回放参数',
    viewTrip: '显示行程',
    today: '今天',
    yestoday: '昨天',
    beforeYestoday: '前天',
    threeDay: '最近3天',
    startTime: '开始时间',
    endTime: '结束时间',
    longStop: '停车超时',
    mode: '模式',
    lineMode: '平滑模式',
    pointMode: '路况模式',
    realTimeMode: '实时轨迹',
    historyMode: '历史轨迹',
    other: '高级',
    showPoint: '显示轨迹点',
    hideZero: '屏蔽0速度',
    referPosition: '参考定位',
    historyDetail: '轨迹明细',
    showAddress: '显示地址',
    showColor: '3色轨迹',
    showRealSence: '显示实景',
    playSpeed: '回放速度',
    playTrack: '播放',
    onPlay: '正在播放',
    orderNumber: '序号',
    speed: '速度',
    miles: '总里程',
    oil: '油量',
    statusDes: '状态信息',
    gpst: '定位',
    rcvt: '接收',
    gpsTime: '定位时间',
    rcvTime: '接收时间',
    place: '位置',
    time: '时间',
    speedDesc: '{speed} 公里/小时',
    direction: '方向',
    mile: '里程',
    currentMile: '行驶里程',
    status: '状态',
    stopFlag: '停车牌',
    showStopFlag: '显示停车牌',
    show: '显示',
    infoWindow: '弹窗',
    trackLine: '轨迹线',
    blod: '粗',
    progress: '播放进度',
    wrap: '换行',
    searchVehicle: '列表查找',
    minute: '分钟',
    lowSpeed: '低速',
    highSpeed: '高速',
    lowAndHigh: '高低速',
    normal: '中',
    low: '低',
    high: '高',
    realSenceTip: '显示实景将减慢播放速度',
    colorTip: '速度<30km/h;红色;速度<60km/h 蓝色;速度>80 绿色',
    historyTrip: '历史行程',
    query: '查询',
    kilo: '千米',
    from: '从',
    to: '到',
    noRealSenceTip: '此处无街景',
    oilUnit: '升',
    stopTime: '停车时长',
    stopTip: '停车时长{time}分钟',
    poiPanel: '围栏与标记',
    poiTab: '标记',
    regionTab: '围栏',
    circleTab: '圆形围栏',
    rectangleTab: '矩形围栏',
    regionTabLink: '关联围栏',
    roadTab:'线路',
    loadAll: '加载全部',
    noStopInfo: '没有查询到停车信息',
    play: '播放',
    pause: '暂停',
    stop: '停止',
    historyRange: '历史区域查设备',
    historyTime: '时间',
    historySpeed: '速度',
    historySearch: '查询',
    historyOnsearch: '正在查找',
    historyTip: '请在地图上拉框选定区域',
    historySuccess: '找到{count}辆车',
    showInfo: '显示弹窗',
    isGps: '精确定位',
    yes: '是',
    showMore: '更多',
    hideMore: '收起',
    measure: '测距',
    showPath: '显示轨迹线',
    homeAndCom: '家和公司',
    noHomeAndComPosition: '没有录入家和公司的位置',
    noComPosition: '没有录入公司的位置',
    noHomePosition: '没有录入家的位置',
    goPosition: '录入位置',
    homeAddress: '家的位置',
    companyAddress: '公司位置',
    lnglat: '经纬度',
    exportData: '导出轨迹',
    exportFileName: '设备轨迹_{name}_{start}_{end}_.xlsx',
    ADSpeed: '突变',
    collision: '碰撞',
    drop: '跌落',
    GPSFlag: '标志',
    Overturn: '反转',
    SharpTurn: '急转弯',
    Attachment: '附件',
    ubiRollover: '翻转',
    ubiCollision: '碰撞',
    ubiDecelerate: '急减速',
    ubiAccelerate: '急加速',
    ubiTurnRight: '右急转弯',
    ubiTurnLeft: '左急转弯',
    realTracking: '实时跟踪',
    timeLimitTips: '选择时间（最多可查询30天)'
  },
  regionManage: {
    name: '区域管理',
    totalTip: '共有{count}个围栏',
    includeChild: '包含下级',
    modify: '修改',
    add: '新增围栏',
    deflete: '删除',
    viewDetail: '查看围栏',
    contactRegion: '关联设备',
    cancelContact: '取消关联',
    addTip: '请点击地图绘制，双击或点击鼠标右键结束',
    regionSetting: '围栏配置',
    start: '开始',
    regionName: '围栏名称',
    recordType: '记录类型',
    validTime: '有效时段',
    triggerAlarm: '触发报警',
    regionLimit: '限速设置',
    limitTip: '公里/小时',
    enterTip: '进区提示',
    leaveTip: '出区提示',
    vehiceBindList: '设备列表',
    regionTip: '进出围栏提示仅支持VDF全功能机型',
    viewBindDevice: '查看已绑定设备',
    bindCountTip: '已绑定{count}台设备',
    clear: '清除',
    clearTip: '请重新绘制或修改',
    drawAgain: '重新画',
    selectVehicle: '选择设备',
    enterRegion: '进围栏',
    leaveRegion: '出围栏',
    enterLeaveRegion: '进出围栏',
    enterLeaveRegionNoAction: '进出围栏不记录',
    vehicleName: '名称',
    deviceID: '设备ID',
    lineType: '接线类型',
    yes: '是',
    no: '否',
    limitTip2: '限速{speed}公里/小时',
    ioTypeList: ['进出区域', '进区域', '出区域'],
    deleteTip: '确认删除 {name}?',
    deleteSuccessed: '删除成功',
    selectTip: '请选择围栏',
    selectTip2: '请选择线路',
    saveSuccessed: '保存成功',
    enterRegionTip: '请输入围栏名称',
    enterRoadTip: '请输入线路名称',
    jiechu: '解除',
    nodata: '没有数据',
    viewAll: '查看详情',
    addRoad: '新增线路',
    roadLimit: '线路限速',
    roadWidth: '线路宽度',
    roadSetting: '线路配置',
    modifyRoad: '修改线路',
    roadName: '线路名称',
    totalRoadTip: '共有{count}个线路',
    endEdit: '完成编辑',
    editPoly: '编辑',
    unit1: '单位：米',
    regionLimit2: '最大滞留时间',
    triggerAlarm2: '滞留设置',
    limitTip2: '分钟',
  },
  placeManage: {
    name: "设备信息",
    deviceName: "设备名",
    deviceList: "设备列表",
    parma: "查询参数",
    placeHolderSearch: "查找设备",
    placeHolderFind: "请选择设备",
    today: "今天",
    yestoday: '昨天',
    lastday: "前天",
    day1: "最近3天",
    begin: '开始时间',
    end: "结束时间",
    placeHolderTime: "选择日期时间",
    stay: '停留时间',
    lt: '大于',
    min: '分钟',
    modes: '查询模式',
    mode1: "查常停点",
    mode2: "查线路",
    btn: '查询',
    form: '从',
    to: "到",
    roadDetail: '线路详情',
    export: '导出',
    pointerDetail: '停留点详情',
    clock: "时间",
    address: "位置",
    show: '显示',
    showHome: '家的位置',
    showCompany: "公司位置",
    showAll: "显示全图",
    layout1: "卫星图",
    layout2: "路网",
    layout3: "街景",
    startTime: "出发时间",
    endTime: "结束时间",
    stayTimes: "停留时长",
    roadAll: "全程",
    length: '总长',
    startPointer: "起点",
    endPointer: "终点",
    lonlat: "经纬度",
    location: "地址",
  },
  photo: {
    search: '查询',
    vipTip: 'VIP设备开放此功能',
    last1Day: '最近1天',
    last2Days: '最近2天',
    last3Days: '最近3天',
    vehicleName: '名称/车牌/ID',
    searchTip: '共查询到{count}张图像',
    startTime: '开始时间',
    endTime: '结束时间',
    takePhoto: '拍照',
    photoCount: '拍照张数',
    photoInterval: '拍照间隔',
    photoChnnel: '拍照通路',
    sendCommand: '发送指令',
    setting: '设置',
    paraType: '参数类型',
    photoSize: '图像大小',
    runTakePhoto: '运行中拍照',
    conditionPhoto: '条件触发拍照',
    size: '分辨率',
    direction: '方向',
    place: '位置',
    speed: '速度',
    unUsed: '未使用',
    sec: '秒',
    chnnel1: '第一路',
    chnnel2: '第二路',
    chnnel3: '第三路',
    chnnel4: '第四路',
    selectVehicleTip: '请先选择车辆',
    maxCountWarn: '由于符合您查询条件的图像太多，系统将只返回最新的300张图像',
    sendSuccessed: '发送成功',
    vehicle: '名称/车牌/ID',
    keyDaojing: '盗警劫警',
    keySpeed: '速度=0',
    keyDoorOpen: '车门开',
    keyDoorClose: '车门关',
    keyCus: '载客',
    keyNon: '空客',
    keyAccOn: 'ACC开',
    keyAccOff: 'ACC关'
  },
  notify: {
    title: '标题',
    BeginTime: '启用时间',
    ShowLevel: '个人用户查看限制',
    UpdateTime: '更新时间',
    CreateTime: '创建时间',
    UserName: '创建用户',
    operation: '操作',
    preview: '预览',
    delete: '删除',
    levelTips: '限制，层级',
    verson1: '已删除',
    verson2: '当前启用',
    verson3: '预定',
    verson4: '旧版本',
    tip1: "增加公告",
    tip2: "删除选中"
  },
  changeMap: {
    name: '切换地图',
    google: 'Google地图',
    amap: '高德地图',
    bmap: '百度地图',
    qqmap: '腾讯地图',
    bing: 'Bing地图'
  },
  homeAlarm: {
    name: '报警详情',
    alarmType: '报警类型',
    query: '查询',
    export: '导出',
    tip: '仅显示在线状态报警设备',
    vehicleName: '设备名',
    deviceID: '设备ID',
    alarmTime: '报警时间',
    alarmPlace: '报警地址',
    alarmType1: '低电压报警',
    alarmType2: '拆机报警',
    alarmType3: '剪线报警',
    alarmType4: '区域报警',
    alarmType5: '双离线报警',
    alarmType6: '设备分离报警',
    alarmType7: '出省报警',
    alarmType8: '二押点报警',
    alarmType9: '低电量报警',
    alarmType10: '断电报警',
    alarmType11: '围栏报警',
    alarmType12: '偏离路线报警',
    alarmType13: '跌落报警',
    alarmType14: '碰撞报警',
    alarmType15: '紧急报警',
    alarmType16: '超速报警',
    alarmType17: '禁行报警',
    alarmType18: '滞留报警',
    tip2: '该查询条件没有查询到数据.'
  },
  dialogCellAndLbs: {
    name: '基站/WIFI定位详情',
    gpsPoint: 'GPS定位点',
    wifiPoint: 'WIFI定位点',
    lbsCenter: 'LBS定位点',
    distance: '距离',
    distanceWithLbs: '距离基站',
    distanceWidthWifi: '距离WIFI',
    emtpy: '(无)'
  },
  dialogBatchCommand: {
    sendCommand: '发送指令',
    commandHistory: '指令历史',
    selectVehicle: '选择设备',
    selectDevices: '已选择{count}台设备',
    hasSelect: '已选择',
    selectType: '选择协议',
    selectCommand: '选择指令',
    setOptions: '设置参数',
    sendButtonText: '发送',
    commandName: '指令名称',
    commandContent: '指令内容',
    commandDeviceCount: '设备数量',
    Resend: '已重发',
    successRate: '成功率',
    time: '时间',
    options: '操作',
    queryTime: '查询时间',
    queryButton: '查询',
    recentUse: '最近使用',
    totalCommand: '全部指令',
    usuallyCommand: '常用指令',
    moreCommand: '更多指令',
    vehicleName: '名称',
    deviceID: '设备ID',
    sendStatus: '发送状态',
    refresh: '刷新',
    query: '查询',
    selectedList: '已选择列表',
    wireType: '有线/无线',
    isOnline: '是否在线',
    allResend: '已全部重发',
    view: '查看',
    reSend: '重发',
    sendDetail: '发送详情',
    alreadySend: '已重新发送',
    reSendAll: '重发所有',
    reSendChecked: '重发选中',
    sending: '正在发送...',
    sendSuccessed: '发送成功',
    sendError: '发送失败，请重试',
    unableMsg: '数量过多',
    expand: '展开',
    collapse: '收起',
    line: '有线设备',
    offline: '无线设备',
    unsupported: '不支持的设备协议',
    sleepErroMsg: '参数过小，设置参数范围5m-43200m',
    sleepErroMsg2: '参数过大，设置参数范围5m-43200m',
    intervalErroMsg: '参数过小，设置参数范围30s-300s',
    intervalErroMsg2: '参数过大，设置参数范围30s-300s'
  },
  dialogMessage: {
    name: '发送信息',
    selectVehicle: '选择设备',
    searchInfo: '查询信息',
    searchBody: '信息内容',
    queryPlaceHolder: '请输入关键字',
    bodyPlaceHolder: '请输入信息内容',
    sendButton: '发送',
    saveAndSend: '保存并发送',
    pleaseSelectVehicle: '请选择车辆',
    sendSuccessed: '发送成功',
    confirmDelete: '确定要删除该公共信息么？',
    deleteSuccessed: '删除成功'
  },
  dialogSetting: {
    vehicleNumber: '设备名',
    tableData: '表格内容',
    changeTip: '*设置内容在刷新页面后生效',
    demoData: {
      PeopleName: '张三',
      VehicleName: '粤B00001',
      SIM2: '861906228999018',
      SIM: '1440118228887',
      VIN: 'LHRTY4852D8478254',
      VehiclePriceName: '66万',
      TrackerTypeName: '有线',
    },
    tips: '保存成功，是否重新加载页面?',
  },
  moveParent: {
    name: '迁移用户',
    tip: '迁移车辆将同时迁移车辆绑定设备',
    moveTarget: '迁移对象',
    oldParent: '原上级',
    newParent: '新上级',
    selectParent: '请选择新上级',
    selectUser: '请选择用户',
    changeSuccessed: '修改成功',
    activeSuccessed: '设备激活成功',
    activeFail: '设备激活失败',
    total: '共选择{count}个',
    inputIds: '输入ID添加',
    vipDate: 'VIP到期更新',
    vipExpiring: 'VIP有效期'
  },
  reportQuery: {
    allReport: '全部报表',
    condition: '查询条件',
    result: '查询结果',
    btnQuery: '查询',
    options: '选项',
    export: '导出',
    fullScreen: '全屏',
    expand: '展开',
    noData: '没有查询到数据',
    day1: '今天',
    day2: '昨天',
    day3: '前天',
    day12: '近2天',
    day13: '近3天',
    day14: '近一周',
    week1: '本周',
    week2: '上周',
    week3: '前周',
    week12: '近2周',
    week13: '近3周',
    month1: '本月',
    month2: '上月',
    month3: '前月',
    month12: '近2月',
    month13: '近3月',
    time: '时间',
    temperature: '温度',
    noavailable: '没有可用报表'
  },
  spread: {
    full: '全屏',
    sum: '累计装车',
    unit: '辆',
    exit: '退出'
  },
  reportMenu: {
    '基础报表': '基础报表',
    '里程报表': '里程报表',
    '油耗报表': '油耗报表',
    '警情报表': '警情报表',
    '温度报表': '温度报表',
    '超速报表': '超速报表',
    '行驶状态报表': '行驶状态报表',
    '区域报表': '区域报表',
    '统计报表': '统计报表',
    '其他': '其他',
    '用户资料报表': '用户资料报表',
    '车辆资料报表': '车辆资料报表',
    '人物资料报表': '人物资料报表',
    '物品资料报表': '物品资料报表',
    '里程日统计表': '里程日统计表',
    '里程月统计表': '里程月统计表',
    '里程汇总表': '里程汇总表',
    '空重车里程时间统计表': '空重车里程时间统计表',
    '油量曲线表': '油量曲线表',
    '油耗明细表': '油耗明细表',
    '报警明细表': '报警明细表',
    '处警明细表': '处警明细表',
    '报警日统计表': '报警日统计表',
    '报警月统计表': '报警月统计表',
    '温度曲线表': '温度曲线表',
    '超速明细表': '超速明细表',
    '超速日统计表': '超速日统计表',
    '超速月统计表': '超速月统计表',
    'ACC时间统计表': 'ACC时间统计表',
    '车辆工作情况报表': '车辆工作情况报表',
    '车辆驶停情况表': '车辆驶停情况表',
    '进出区域统计表': '进出区域统计表',
    '进出区域明细表': '进出区域明细表',
    '车辆趟次明细表': '车辆趟次明细表',
    '车辆趟次统计表': '车辆趟次统计表',
    '轨迹报表': '轨迹报表',
    '时刻状态报表': '时刻状态报表',
    '车门状态报表': '车门状态报表',
    '目标巡检报表': '目标巡检报表',
    '公共信息报表': '公共信息报表',
    '无线设备电量报表': '无线设备电量报表',
    "车辆轨迹报表": "车辆轨迹报表",
    "时段里程报表": "时段里程报表",
    '设备激活报表': '设备激活报表',
    '围栏报警报表': '围栏报警报表',
    '防拆报表': '防拆报表',
    '最后定位报表': '最后定位报表',
    '停驶报表': '停驶报表',
    '电量统计': '电量统计',
    '设备分离报表': '设备分离报表',
    '离线报警表': '离线报警表',
    '长时间未回家报表': '长时间未回家报表',
    '长时间未回公司报表': '长时间未回公司报表',
    '出省报表': '出省报表',
    '夜间用车报表': '夜间用车报表',
    '里程统计报表': '里程统计报表',
    '超速明细报表': '超速明细报表',
    '设备资料报表': '设备资料报表',
    '其他报警报表': '其他报警报表',
    '拆机报警报表': '拆机报警报表',
    '长时间停驶报表': '长时间停驶报表',
    '进出围栏报表': '进出围栏报表',
    '工单统计报表': '工单统计报表',
    '事故报警报表': '事故报警报表',
    '设防/撤防报表': '设防/撤防报表',
    'UBI明细表': 'UBI明细表',
    '摩托车管理报表': '摩托车管理报表',
    '服务费到期报表': '服务费到期报表',
    '续费记录报表': '续费记录报表',
    '新增激活报表': '新增激活报表'
  },
  funMenu: {
    'GPS监控': 'GPS监控',
    '在线监控': '监控',
    '位置服务': '位置服务',
    '系统信息': '系统信息',
    '报表中心': '报表中心',
    '显示SIM': '显示SIM',
    '轨迹回放': '轨迹回放',
    '拍照浏览': '拍照浏览',
    '车辆管理': '人车',
    '风控管理': '风控管理',
    '线路管理': '线路管理',
    '省界管理': '省界管理',
    '业务分布图': '业务分布图',
    '二押点': '二押点',
    '工单系统': '工单系统',
    '工单管理': '工单管理',
    '技师管理': '技师管理',
    '导出': '导出',
    '审核': '审核',
    '审核后编辑': '审核后编辑',
    '保单管理': '保单管理',
    '录入保单': '录入保单',
    '激活': '激活',
    '监听': '监听',
    '锁车': '锁车',
    '解锁': '解锁',
    '解除报警': '解除报警',
    '目标图像': '目标图像',
    '轨迹查询': '轨迹',
    '电子围栏': '电子围栏',
    '体验日志': '体验日志',
    '发送信息': '发送信息',
    '发送指令': '发送指令',
    '发送短信': '发送短信',
    '汽车金融': '汽车金融',
    '风控评估': '风控评估',
    '资产分析': '资产分析',
    '暗访追车': '暗访追车',
    '找常停点': '找常停点',
    '灰名单': '灰名单',
    '新增': '新增',
    '删除': '删除',
    '编辑': '编辑',
    '查询': '查询',
    '续费充值': '续费充值',
    '到期提醒': '到期提醒',
    '自助续费': '自助续费',
    '我的钱包': '我的钱包',
    '转赠': '转赠',
    '信息管理': '信息管理',
    '用户管理': '单位',
    '账号管理': '账号',
    '设备管理': '设备管理',
    '重要信息': '重要信息',
    'VIP有效期': 'VIP有效期',
    '默认VIP(4年)': '默认VIP(4年）',
    '服务截止日期': '服务截止日期',
    '免费查看7天轨迹': '免费查看7天轨迹',
    '安装人员': '安装人员',
    '安装任务': '安装任务',
    '报表查询': '报表查询',
    '操作日志': '操作日志',
    '基础报表': '基础报表',
    '里程报表': '里程报表',
    '油耗报表': '油耗报表',
    '警情报表': '警情报表',
    '温度报表': '温度报表',
    '超速报表': '超速报表',
    '行驶状态报表': '行驶状态报表',
    '区域报表': '区域报表',
    '统计报表': '统计报表',
    '摩托车管理报表': '摩托车管理报表',
    '服务费到期报表': '服务费到期报表',
    '续费记录报表': '续费记录报表',
    '新增激活报表': '新增激活报表',
    'UBI明细表': 'UBI明细表',
    '设防/撤防报表': '设防/撤防报表',
    '工单统计报表': '工单统计报表',
    '其他': '其他',
    '用户资料报表': '用户资料报表',
    '里程日统计表': '里程日统计表',
    '里程月统计表': '里程月统计表',
    '里程汇总表': '里程汇总表',
    '空重车里程时间统计表': '空重车里程时间统计表',
    '油量曲线表': '油量曲线表',
    '油耗明细表': '油耗明细表',
    '报警明细表': '报警明细表',
    '处警明细表': '处警明细表',
    '报警月统计表': '报警月统计表',
    '温度曲线表': '温度曲线表',
    '超速明细表': '超速明细表',
    '超速日统计表': '超速日统计表',
    '超速月统计表': '超速月统计表',
    'ACC时间统计表': 'ACC时间统计表',
    '车辆工作情况报表': '车辆工作情况报表',
    '车辆驶停情况表': '车辆驶停情况表',
    '进出区域统计表': '进出区域统计表',
    '进出区域明细表': '进出区域明细表',
    '车辆趟次明细表': '车辆趟次明细表',
    '车辆趟次统计表': '车辆趟次统计表',
    '轨迹报表': '轨迹报表',
    '时刻状态报表': '时刻状态报表',
    '车门状态报表': '车门状态报表',
    '目标巡检报表': '目标巡检报表',
    '公共信息报表': '公共信息报表',
    '无线设备电量报表': '无线设备电量报表',
    "消费": "消费",
    "VIP管理": "VIP管理",
    "升级VIP": "升级VIP",
    "公告管理": "公告管理",
    "资料管理": "资料管理",
    "风控名单": "风控名单",
    "车辆资料报表": "车辆资料报表",
    '人物资料报表': '人物资料报表',
    '物品资料报表': '物品资料报表',
    "车辆轨迹报表": "车辆轨迹报表",
    "报警日统计表": "报警日统计表",
    "时段里程报表": "时段里程报表",
    '设备分离报表': '设备分离报表',
    '离线报警表': '离线报警表',
    '长时间未回家报表': '长时间未回家报表',
    '长时间未回公司报表': '长时间未回公司报表',
    '出省报表': '出省报表',
    '设备激活报表': '设备激活报表',
    '围栏报警报表': '围栏报警报表',
    '防拆报表': '防拆报表',
    '最后定位报表': '最后定位报表',
    '停驶报表': '停驶报表',
    '电量统计': '电量统计',
    '夜间用车报表': '夜间用车报表',
    '里程统计报表': '里程统计报表',
    '超速明细报表': '超速明细报表',
    '设备资料报表': '设备资料报表',
    '其他报警报表': '其他报警报表',
    '拆机报警报表': '拆机报警报表',
    '长时间停驶报表': '长时间停驶报表',
    '进出围栏报表': '进出围栏报表',
    '批量指令': '批量指令',
    '查询电子围栏': '查询电子围栏',
    '事故报警报表': '事故报警报表',
    'DVR视频': 'DVR视频',
    'UBI明细表': 'UBI明细表',
    '摩托车管理报表': '摩托车管理报表',
    '服务费到期报表': '服务费到期报表',
    '续费记录报表': '续费记录报表',
    '新增激活报表': '新增激活报表'
  },
  reportDetail: {
    '用户名称': '用户名称',
    '信用等级': '信用等级',
    '营业执照': '营业执照',
    '传真号码': '传真号码',
    '24小时联系电话': '24小时联系电话',
    '联系人': '联系人',
    '邮政编码': '邮政编码',
    '联系地址': '联系地址',
    '状态描述': '状态描述',
    '服务截止日期': '服务截止日期',
    '设备ID': '设备ID',
    '车牌/设备ID': '车牌/设备ID',
    '车牌/设备ID/车架号/工单号': '车牌/设备ID/车架号/工单号',
    '开始日期': '开始日期',
    '结束日期': '结束日期',
    '统计日期': '统计日期',
    '所属单位': '所属单位',
    '牌号': '牌号',
    '里程(公里)': '里程(公里)',
    '开始时间': '开始时间',
    '结束时间': '结束时间',
    '报警类型': '报警类型',
    '显示位置': '显示位置',
    '报警时间': '报警时间',
    '编号': '编号',
    '速度(KM/H)': '速度(KM/H)',
    'GPS状态描述': 'GPS状态描述',
    'GPS时间': 'GPS时间',
    '经度': '经度',
    '纬度': '纬度',
    '位置': '位置',
    '状态类型': '状态类型',
    '持续时间(分钟)': '持续时间(分钟)',
    '驾驶员': '驾驶员',
    'ACC状态': 'ACC状态',
    '持续时长': '持续时长',
    '里程': '里程',
    '总里程': '总里程',
    '油耗': '油耗',
    '起点经度': '起点经度',
    '起点纬度': '起点纬度',
    '终点经度': '终点经度',
    '终点纬度': '终点纬度',
    '起点位置': '起点位置',
    '终点位置': '终点位置',
    '速度≥': '速度≥',
    '接收时间': '接收时间',
    '方向': '方向',
    '里程(KM)': '里程(KM)',
    '油量(L)': '油量(L)',
    '状态': '状态',
    '状 态': '状态',
    '定位': '定位',
    '离线时长': '离线时长',
    '日期': '日期',
    'SIM卡号': 'SIM卡号',
    '设备型号': '设备型号',
    'SIM有效期': 'SIM有效期',
    '最后在线': '最后在线',
    '最后定位': '最后定位',
    '最后状态': '最后状态',
    '所属用户': '所属用户',
    '用户联系人': '用户联系人',
    '用户电话': '用户电话',
    '安装日期': '安装日期',
    '机主姓名': '机主姓名',
    '机主电话': '机主电话',
    '车牌编号': '车牌编号',
    '车牌号码': '车牌号码',
    '开始年月': '开始年月',
    '结束年月': '结束年月',
    '年月': '年月',
    '处警内容': '处警内容',
    '定位时间': '定位时间',
    '车牌': '车牌',
    '报警名称': '报警名称',
    '处警时间': '处警时间',
    '处警人': '处警人',
    '空车里程(KM)': '空车里程(KM)',
    '空车时间(分钟)': '空车时间(分钟)',
    '重车里程(KM)': '重车里程(KM)',
    '重车时间(分钟)': '重车时间(分钟)',
    '时 间': '时间',
    '时间': '时间',
    '油量(升)': '油量(升)',
    '变化类型': '变化类型',
    '开始油量(升)': '开始油量(升)',
    '结束油量(升)': '结束油量(升)',
    '变化油量(升)': '变化油量(升)',
    '类型': '类型',
    '开始里程(公里)': '开始里程(公里)',
    '行驶里程(公里)': '行驶里程(公里)',
    '劫警': '劫警',
    '盗警': '盗警',
    '超速报警': '超速报警',
    '低速报警': '低速报警',
    '疲劳驾驶': '疲劳驾驶',
    '进范围报警': '进范围报警',
    '出范围报警': '出范围报警',
    '超出线路报警': '超出线路报警',
    '剪线报警': '剪线报警',
    '油量上升异常报警': '油量上升异常报警',
    '油量异常下降报警': '油量异常下降报警',
    '天线断路报警': '天线断路报警',
    '搅拌时间过长': '搅拌时间过长',
    '非法时间卸料': '非法时间卸料',
    '其它': '其它',
    '报警总数': '报警总数',
    '单位': '单位',
    '最高速度(KM/H)': '最高速度(KM/H)',
    '超速里程(KM)': '超速里程(KM)',
    '开始位置': '开始位置',
    '结束位置': '结束位置',
    '当日最高速度(KM/H)': '当日最高速度(KM/H)',
    '超速总时长': '超速总时长',
    '当日超速次数': '当日超速次数',
    '熄火': '熄火',
    '启动怠速': '启动怠速',
    '启动行驶': '启动行驶',
    '总时长': '总时长',
    '第一次启动': '第一次启动',
    '最后一次熄火': '最后一次熄火',
    '第一次启动位置': '第一次启动位置',
    '无后一次熄火位置': '无后一次熄火位置',
    'acc状态': 'acc状态',
    '区域名称': '区域名称',
    '进区域次数': '进区域次数',
    '出区域次数': '出区域次数',
    '进出总次数': '进出总次数',
    '进出类型': '进出类型',
    '当前油量(L)': '当前油量(L)',
    '当前里程(KM)': '当前里程(KM)',
    '出区': '出区',
    '进区': '进区',
    '上次出区时间': '上次出区时间',
    '进区时间': '进区时间',
    '出区时间': '出区时间',
    '途中时长': '途中时长',
    '途中里程(KM)': '途中里程(KM)',
    '区内停留时间': '区内停留时间',
    '起始点': '起始点',
    '到达点': '到达点',
    '趟次数': '趟次数',
    '持续时间≥X分钟': '持续时间≥X分钟',
    '发送时间': '发送时间',
    '信息内容': '信息内容',
    '接收': '接收',
    '操作单位': '操作单位',
    '操作员': '操作员',
    '电量低于(单位%):': '电量低于(单位:%)',
    '剩余电量': '剩余电量',
    '车主姓名': '车主姓名',
    '车主电话': '车主电话',
    '当月最高速度(KM/H)': '当月最高速度(KM/H)',
    '当月超速次数': '当月超速次数',
    '车辆编号': '车辆编号',
    '全部': '全部',
    '加油': '加油',
    '异常': '异常',
    '是': '是',
    '否': '否',
    '全部类型': '全部类型',
    '拆机报警': '拆机报警',
    '低油量报警': '低油量报警',
    '油量异常上升报警': '油量异常上升报警',
    '非法卸料报警': '非法卸料报警',
    '超时未返回': '超时未返回',
    '电瓶拆除报警': '电瓶拆除报警',
    '天线短路报警': '天线短路报警',
    'GSM模块故障': 'GSM模块故障',
    'GPS接收机故障': 'GPS接收机故障',
    '总线故障': '总线故障',
    '密码输入错误': '密码输入错误',
    '非法动车报警': '非法动车报警',
    '开箱报警': '开箱报警',
    '启动怠速 (速度=0)': '启动怠速 (速度=0)',
    '启动行驶 (速度>0)': '启动行驶 (速度>0)',
    '启动(速度>=0)': '启动(速度>=0)',
    '进区域': '进区域',
    '出区域': '出区域',
    '门开': '门开',
    '门关': '门关',
    '参考油耗': '参考油耗',
    '参考百公里油耗': '参考百公里油耗',
    '耗油量': '耗油量',
    '实际百公里油耗': '实际百公里油耗',
    '加油量': '加油量',
    '异常油量(升)': '异常油量(升)',
    '参考耗油量': '参考油耗量',
    '变化油量': '变化油量',
    '开始时段': '开始时段',
    '结束时段': '结束时段',
    '时段起始时间': '时段起始时间',
    '时段结束时间': '时段结束时间',
    '低电量报警': '低电量报警',
    '移位报警': '移位报警',
    '侧翻报警':'侧翻报警',
    '安装异常报警': '安装异常报警',
    '滞留报警': '滞留报警',
    '上线报警': '上线报警',
    '设防报警': '设防报警',
    '疲劳驾驶预警': '疲劳驾驶报警',
    '二押点报警': '二押点报警',
    '低油量报警': '低油量报警',
    '超时停车报警': '超时停车报警',
    '出围栏报警': '出围栏报警',
    '1小时': '1小时',
    '3小时': '3小时',
    '6小时': '6小时',
    '12小时': '12小时',
    '1天': '1天',
    '2天': '2天',
    '3天': '3天',
    '1周': '1周',
    '2周': '2周',
    '3周': '3周',
    '1月': '1月',
    '2月': '2月',
    '3月': '3月',
    '6月': '6月',
    '1年': '1年',
    '设备号': '设备号',
    '名称': '名称',
    '颜色': '颜色',
    '长': '长',
    '宽': '宽',
    '高': '高',
    '重量': '重量',
    '设备状态': '设备状态',
    '偏离线路报警': '偏离线路报警',
    '电话': '电话',
    '年龄': '年龄'
  },
  dict: {
    '小轿车': '小轿车',
    '救护车': '救护车',
    '大卡车': '大卡车',
    '油罐车': '油罐车',
    '警车': '警车',
    '公交车': '公交车',
    '工程车': '工程车',
    '环卫车': '环卫车',
    '旅游车': '旅游车',
    '挖机': '挖机',
    '厢式车': '厢式车',
    '混凝土搅拌车': '混凝土搅拌车',
    '个人定位': '个人定位',
    '其他': '其他',
    '货车': '货车',
    '摩托车': '摩托车',
    '渣土车': '渣土车',
    "RV": "RV",
    "Fifth Wheel": "Fifth Wheel",
    "Trailer": "Trailer",
    "Marine": "Marine",
    "ATV": "ATV",
    "Pop Up Camper": "Pop Up Camper",
    "Skid Steer": "Skid Steer",
    "Lift": "Lift",
    "Generator": "Generator",
    "Closed Trailer": "Closed Trailer",
    "Snowmobile": "Snowmobile",
    "Semi Truck": "Semi Truck",
    "Arrow Sign": "Arrow Sign",
    "Message Board": "Message Board",
    "Toilet Trailer": "Toilet Trailer",
    "Porta Potty": "Porta Potty",
    "Crash Truck": "Crash Truck",
    "Roll off Bin": "Roll off Bin",
    "Diesel Plate": "Diesel Plate",
    "RTV": "RTV",
    "Speed Sign": "Speed Sign",
    "5th wheel": "5th wheel",
    "Single": "Single",
    '有线': '有线',
    '无线': '无线',
    '有线设备': '有线设备',
    '无线设备': '无线设备',
    '超级管理员': '超级管理员',
    '普通管理员': '普通管理员',
    '普通帐号': '普通帐号',
    '安装人员': '安装人员',
    '奥迪': '奥迪',
    'ALPINA': 'ALPINA',
    '宝马': '宝马',
    '奔驰': '奔驰',
    '保时捷': '保时捷',
    '宝沃': '宝沃',
    '大众': '大众',
    'Smart': 'Smart',
    '西雅特': '西雅特',
    '本田': '本田',
    '丰田': '丰田',
    '雷克萨斯': '雷克萨斯',
    '铃木': '铃木',
    '马自达': '马自达',
    '讴歌': '讴歌',
    '日产': '日产',
    '斯巴鲁': '斯巴鲁',
    '三菱': '三菱',
    '五十铃': '五十铃',
    '英菲尼迪': '英菲尼迪',
    '起亚': '起亚',
    '双龙': '双龙',
    '现代': '现代',
    '别克': '别克',
    '道奇': '道奇',
    '福特': '福特',
    'Faraday Future': 'Faraday Future',
    'GMC': 'GMC',
    'Jeep': 'Jeep',
    '凯迪拉克': '凯迪拉克',
    '克莱斯勒': '克莱斯勒',
    '林肯': '林肯',
    '特斯拉': '特斯拉',
    '雪佛兰': '雪佛兰',
    '标致': '标致',
    'DS': 'DS',
    '雷诺': '雷诺',
    '雪铁龙': '雪铁龙',
    '阿斯顿·马丁': '阿斯顿·马丁',
    '宾利': '宾利',
    '捷豹': '捷豹',
    '路虎': '路虎',
    '劳斯莱斯': '劳斯莱斯',
    'MINI': 'MINI',
    '迈凯伦': '迈凯伦',
    '阿尔法·罗密欧': '阿尔法·罗密欧',
    '布加迪': '布加迪',
    '菲亚特': '菲亚特',
    '法拉利': '法拉利',
    '兰博基尼': '兰博基尼',
    '玛莎拉蒂': '玛莎拉蒂',
    '依维柯': '依维柯',
    'Polestar': 'Polestar',
    '沃尔沃': '沃尔沃',
    '斯柯达': '斯柯达',
    'ARCFOX': 'ARCFOX',
    '宝骏': '宝骏',
    '比亚迪': '比亚迪',
    '奔腾': '奔腾',
    '比速': '比速',
    '北汽绅宝': '北汽绅宝',
    '北汽幻速': '北汽幻速',
    '北汽威旺': '北汽威旺',
    '北汽昌河': '北汽昌河',
    '北汽制造': '北汽制造',
    '北汽道达': '北汽道达',
    '北汽新能源': '北汽新能源',
    '北京': '北京',
    '长安汽车': '长安汽车',
    '长安欧尚': '长安欧尚',
    '长安轻型车': '长安轻型车',
    '长安跨越': '长安跨越',
    '长城': '长城',
    '大乘汽车': '大乘汽车',
    '东风风度': '东风风度',
    '东风风光': '东风风光',
    '东风风神': '东风风神',
    '东风风行': '东风风行',
    '东风小康': '东风小康',
    '东风': '东风',
    '东南': '东南',
    '电咖': '电咖',
    '福迪': '福迪',
    '福汽启腾': '福汽启腾',
    '福田': '福田',
    '广汽传祺': '广汽传祺',
    '广汽新能源': '广汽新能源',
    '观致': '观致',
    '国金': '国金',
    '哈弗': '哈弗',
    '海马': '海马',
    '汉腾': '汉腾',
    '红旗': '红旗',
    '华泰': '华泰',
    '红星汽车': '红星汽车',
    '黄海': '黄海',
    '华骐': '华骐',
    '华颂': '华颂',
    '吉利': '吉利',
    '江淮': '江淮',
    '捷途': '捷途',
    '江铃': '江铃',
    '奇点汽车': '奇点汽车',
    '金杯': '金杯',
    '金龙': '金龙',
    '九龙': '九龙',
    '君马': '君马',
    '凯翼': '凯翼',
    '开瑞': '开瑞',
    '卡升': '卡升',
    '卡威': '卡威',
    '康迪全球鹰': '康迪全球鹰',
    '领克': '领克',
    '陆风': '陆风',
    '力帆': '力帆',
    '猎豹': '猎豹',
    '零跑汽车': '零跑汽车',
    '领途汽车': '领途汽车',
    '理想智造': '理想智造',
    '名爵': '名爵',
    '纳智捷': '纳智捷',
    '欧尚汽车': '欧尚汽车',
    '欧拉': '欧拉',
    '奇瑞': '奇瑞',
    '启辰': '启辰',
    '前途': '前途',
    '庆铃': '庆铃',
    '荣威': '荣威',
    'SWM斯威': 'SWM斯威',
    '思皓': '思皓',
    '上汽大通': '上汽大通',
    '腾势': '腾势',
    '五菱': '五菱',
    'WEY': 'WEY',
    '蔚来': '蔚来',
    '潍柴英致': '潍柴英致',
    '威马汽车': '威马汽车',
    '小鹏汽车': '小鹏汽车',
    '星驰': '星驰',
    '新特汽车': '新特汽车',
    '驭胜': '驭胜',
    '野马': '野马',
    '一汽': '一汽',
    '裕路': '裕路',
    '云度': '云度',
    '云雀汽车': '云雀汽车',
    '众泰': '众泰',
    '中华': '中华',
    '知豆': '知豆',
    '之诺': '之诺',
    '中兴': '中兴',
    '全部': '全部'
  },
  color: {
    '蓝色': '蓝色',
    '绿色': '绿色',
    '黄色': '黄色',
    '白色': '白色',
    '黑色': '黑色'
  },
  commandList: {
    '回传间隔': '回传间隔',
    '无线闹钟': '超长待机休眠参数',
    '休眠时长': '休眠时长',
    '追踪设置': '追踪设置',
    '定位模式': '定位优先级',
    '锁车/解锁': '锁车/解锁',
    '拆机报警设置': '拆机报警设置',
    '超速报警设置': '超速报警设置',
    '里程参数设置': '里程参数设置',
    '设置功能开关': '设置功能开关',
    '其他设置': '其他设置',
    '控制终端状态': '控制终端状态',
    '设置低油量/油量异常报警阀值': '设置低油量/油量异常报警阀值',
    '设置号码': '设置号码',
    '设置油量特征参数': '设置油量特征参数',
    //'电话回拨': '电话回拨',
    '立即拍照': '立即拍照',
    '连续拍照': '连续拍照',
    '拍照间隔设置': '拍照间隔设置',
    '紧急抓拍设置': '紧急抓拍设置',
    '条件抓拍设置': '条件抓拍设置',
    '图像分辨率设置': '图像分辨率设置',
    '遥控车门锁': '遥控车门锁',
    '位移报警设置': '位移报警设置',
    '震动报警设置': '震动报警设置',
    '查询ICCID': '查询ICCID',
    '远程升级': '远程升级',
    '高级指令': '高级指令',
    '锁车解锁': '锁车解锁',
    '设置通讯参数': '设置通讯参数',
    '设置接入参数': '设置接入参数',
    '位置汇报参数设置': '位置汇报参数设置',
    '终端通话设置': '终端通话设置',
    '终端电话拨打': '终端电话拨打',
    '图像/视频设置': '图像/视频设置',
    '报警开关设置': '报警开关设置',
    '报警发送SMS开关': '报警发送SMS开关',
    '报警拍摄开关': '报警拍摄开关',
    '报警拍摄存储开关': '报警拍摄存储开关',
    '关键报警开关': '关键报警开关',
    '报警参数设置': '疲劳驾驶参数',
    '超速参数设置': '超速参数设置',
    '车辆基本资料设置': '车辆基本资料设置',
    '电话本设置': '电话本设置',
    '连接指定服务器': '连接指定服务器',
    '终端关机': '终端关机',
    '终端复位': '终端复位',
    '恢复出厂设置': '恢复出厂设置',
    '关闭数据通信': '关闭数据通信',
    '关闭所有无线通信': '关闭所有无线通信',
    '车辆控制': '车辆控制',
    '临时位置跟踪控制': '追踪设置',
    '终端唤醒': '终端唤醒',
    '设置油量报警报警参数': '设置油量报警报警参数',
    '设置油箱参数': '设置油箱参数',
    '录音指令': '录音指令',
    '单次点名': '单次点名',
    '设置ACC开发送数据间隔': '设置ACC开发送数据间隔',
    '设置ACC关发送数据间隔': '设置ACC关发送数据间隔',
    '设备重启': '设备重启',
    '取消所有报警': '取消所有报警',
    '里程清零': '里程清零',
    '升级': '升级',
    '里程初始化': '里程初始化',
    'MT01专用': 'MT01专用',
    '设置油量异常报警参数': '设置油量异常报警参数',
    '运动静止回传间隔设置': '运动静止回传间隔设置'
  },
  commandParams: {
    '选择操作': '选择操作',
    '锁车': '锁车',
    '解锁': '解锁',
    '终端心跳时间间隔': '终端心跳时间间隔',
    '秒': '秒',
    '分钟': '分钟',
    '次': '次',
    '度': '度',
    '毫米': '毫米',
    'TCP应答超时时间': 'TCP应答超时时间',
    'TCP重传次数': 'TCP重传次数',
    'UDP应答超时时间': 'UDP应答超时时间',
    'UDP重传次数': 'UDP重传次数',
    'SMS应答超时时间': 'SMS应答超时时间',
    'SMS重传次数': 'SMS重传次数',
    '设置接入参数': '设置接入参数',
    '主服务器APN': '主服务器APN',
    '拨号用户名': '拨号用户名',
    '拨号密码': '拨号密码',
    '主服务器IP': '主服务器IP',
    '备服务器APN': '备服务器APN',
    '备服务器IP': '备服务器IP',
    '服务器TCP端口': '服务器TCP端口',
    '服务器UDP端口': '服务器UDP端口',
    '监控平台电话': '监控平台电话',
    '终端复位电话': '终端复位电话',
    '恢复出厂设置电话': '恢复出厂设置电话',
    '监控平台SMS电话': '监控平台SMS电话',
    '接收终端SMS文本报警电话': '接收终端SMS文本报警电话',
    //'电话回拨号码': '电话回拨号码',
    '监管平台特权短信号码': '监管平台特权短信号码',
    '位置汇报策略': '位置汇报策略',
    '定时汇报': '定时汇报',
    '定距汇报': '定距汇报',
    '定时和定距汇报': '定时和定距汇报',
    '位置汇报方案': '位置汇报方案',
    '根据ACC状态': '根据ACC状态',
    '根据登录状态和ACC状态': '根据登录状态和ACC状态',
    '缺省汇报时间间隔': '缺省汇报时间间隔',
    '驾驶员未登录汇报时间间隔': '驾驶员未登录汇报时间间隔',
    '休眠汇报时间间隔': '休眠汇报时间间隔',
    '紧急报警汇报时间间隔': '紧急报警汇报时间间隔',
    '缺省汇报距离间隔': '缺省汇报距离间隔',
    '驾驶员未登录汇报距离间隔': '驾驶员未登录汇报距离间隔',
    '休眠汇报距离间隔': '休眠汇报距离间隔',
    '紧急报警汇报距离间隔': '紧急报警汇报距离间隔',
    '拐点补传角度': '拐点补传角度',
    '终端电话接听策略': '终端电话接听策略',
    '自动接听': '自动接听',
    'ACC ON时自动接听': 'ACC ON时自动接听',
    '单次通话最长': '单次通话最长',
    '月通话最长': '月通话最长',
    '终端电话拨打类型': '终端电话拨打类型',
    '普通通话': '普通通话',
    //'电话回拨': '电话回拨',
    '拨打号码': '拨打号码',
    '图像/视频质量': '图像/视频质量',
    '亮度': '亮度',
    '对比度': '对比度',
    '饱和度': '饱和度',
    '色度': '色度',
    '紧急报警': '紧急报警',
    '关': '关',
    '开': '开',
    '超速报警': '超速报警',
    '疲劳驾驶报警': '疲劳驾驶报警',
    '预警': '预警',
    'GNSS模块故障': 'GNSS模块故障',
    'GNSS天线未接': 'GNSS天线未接',
    'GNSS天线短路': 'GNSS天线短路',
    '终端主电源欠压': '终端主电源欠压',
    '终端主电源掉电': '终端主电源掉电',
    '终端LCD故障': '终端LCD故障',
    'TTS模块故障': 'TTS模块故障',
    '摄像头故障': '摄像头故障',
    '当天累计驾驶超时': '当天累计驾驶超时',
    '超时停车': '超时停车',
    '进出区域': '进出区域',
    '进出路线': '进出路线',
    '路段行驶时间不足/过长': '路段行驶时间不足/过长',
    '路线偏离报警': '路线偏离报警',
    '车辆VSS故障': '车辆VSS故障',
    '车辆油量异常': '车辆油量异常',
    '车辆被盗': '车辆被盗',
    '车辆非法点火': '车辆非法点火',
    '车辆非法位移': '车辆非法位移',
    '最高速度': '最高速度',
    '超速持续时间': '超速持续时间',
    '连续驾驶最长时间': '连续驾驶最长时间',
    '当天累计驾驶最大时间': '当天累计驾驶最大时间',
    '最小休息时间': '最小休息时间',
    '最大停车时间': '最大停车时间',
    '车辆里程表读数': '车辆里程表读数',
    '所在省域ID': '所在省域ID',
    '所在市域ID': '所在市域ID',
    '车牌号': '车牌号',
    '车牌颜色': '车牌颜色',
    '指令类型': '指令类型',
    '删除所有联系人': '删除所有联系人',
    '更新电话本': '更新电话本',
    '追加电话本': '追加电话本',
    '修改电话本': '修改电话本',
    '标志': '标志',
    '呼入': '呼入',
    '呼出': '呼出',
    '呼入/呼出': '呼入/呼出',
    '电话号码': '电话号码',
    '联系人': '联系人',
    '连接控制': '连接控制',
    '切换到指定监管平台服务器': '切换到指定监管平台服务器',
    '切换回原缺省监管平台服务器': '切换回原缺省监管平台服务器',
    '鉴权码': '鉴权码',
    '拨号点名称': '拨号点名称',
    'IP地址': 'IP地址',
    'TCP端口': 'TCP端口',
    'UDP端口': 'UDP端口',
    '连接到指定服务器时限': '连接到指定服务器时限',
    '该指令无需参数': '该指令无需参数',
    '车门控制': '车门控制',
    '解锁车门': '解锁车门',
    '关闭车门': '关闭车门',
    '汇报间隔': '汇报间隔',
    '跟踪有效期': '跟踪有效期',
    '低油量报警值': '低油量报警值',
    '升': '升',
    '油量异常下降报警阈值': '油量异常下降报警阈值',
    '油量异常下降时间阈值': '油量异常下降时间阈值',
    '油箱1容积': '油箱1容积',
    '油箱1高度': '油箱1高度',
    '油箱2容积': '油箱2容积',
    '油箱2高度': '油箱2高度',
    '停止录音': '停止录音',
    '开始录音': '开始录音',
    '录音时间': '录音时间',
    '保存标志': '保存标志',
    '实时长传': '实时长传',
    '保存': '保存',
    '音频采样率': '音频采样率',
    '请输入指令内容': '请输入指令内容',
    '时间间隔': '时间间隔',
    '端口': '端口',
    '里程值': '里程值',
    '回传间隔': '回传间隔',
    '启动回传间隔': '启动回传间隔',
    '熄火回传间隔': '熄火回传间隔',
    '休眠回传间隔': '休眠回传间隔',
    '回传间隔 (30-300秒)': '回传间隔(30-300 秒)',
    '回传间隔 (5-300秒)': '回传间隔(5-300 秒)',
    '持续时间 (5-57600分)': '持续时间(5-57600 分)',
    '唤醒时间1': '唤醒时间1',
    '唤醒时间2': '唤醒时间2',
    '唤醒时间3': '唤醒时间3',
    '唤醒时间4': '唤醒时间4',
    '休眠时长': '休眠时长',
    '打开': '打开',
    '关闭': '关闭',
    '持续时间': '持续时间',
    '分': '分',
    '纯基站定位': '纯基站定位',
    '基站+GPS': '基站+GPS',
    '报警，追踪15分钟，间隔300秒': '报警，追踪15分钟，间隔300秒',
    '报警不追踪': '报警不追踪',
    '清零': '清零',
    '设置里程值': '设置里程值',
    '省电关闭通信模块': '省电关闭通信模块',
    '不关闭': '不关闭',
    '超速报警速度值0-200': '超速报警速度值0-200',
    '超速预警值': '超速预警值',
    '持续超速触发报警时间1-255,推荐5': '持续超速触发报警时间1-255,推荐5',
    '低速报警速度值': '低速报警速度值',
    '持续低速触发报警时间1-65535,推荐600': '持续低速触发报警时间1-65535,推荐600',
    'ACC进入省电时间1-65535,推荐600': 'ACC进入省电时间1-65535,推荐600',
    '拐角补传角度,推荐30': '拐角补传角度,推荐30',
    '震动报警灵敏度': '震动报警灵敏度',
    '最低': '最低',
    '低': '低',
    '中': '中',
    '高': '高',
    '最高': '最高',
    '选择动作': '选择动作',
    '重启终端': '重启终端',
    '唤醒终端': '唤醒终端',
    '关闭终端': '关闭终端',
    '恢复出厂设置': '恢复出厂设置',
    '低油量报警阀值': '低油量报警阀值',
    '油量异常上升报警阈值': '油量异常上升报警阈值',
    '油量异常上升时间阈值': '油量异常上升时间阈值',
    '中心号码1': '中心号码1',
    '中心号码2': '中心号码2',
    '报警通知号码1': '报警通知号码1',
    '报警通知号码2': '报警通知号码2',
    //'电话回拨号码': '电话回拨号码',
    '邮箱总容积0-999.9': '邮箱总容积0-999.9',
    '空邮箱电压AD值0-9999': '空邮箱电压AD值0-9999',
    '1/4邮箱油量电压AD值0-9999': '1/4邮箱油量电压AD值0-9999',
    '1/2邮箱油量电压AD值0-9999': '1/2邮箱油量电压AD值0-9999',
    '3/4邮箱油量电压AD值0-9999': '3/4邮箱油量电压AD值0-9999',
    '满邮箱油量电压AD值0-9999': '满邮箱油量电压AD值0-9999',
    '拍照通路': '拍照通路',
    '第一路': '第一路',
    '第二路': '第二路',
    '第三路': '第三路',
    '第四路': '第四路',
    '抓拍间隔,最大65535': '抓拍间隔,最大65535',
    '抓拍次数,最大65535': '抓拍次数,最大65535',
    '设置紧急情况（盗警、劫警）下图像抓拍的时间间隔和次数': '设置紧急情况（盗警、劫警）下图像抓拍的时间间隔和次数',
    '图像抓拍时间间隔，最大255秒': '图像抓拍时间间隔，最大255秒',
    '抓拍间隔': '抓拍间隔',
    '抓拍次数': '抓拍次数',
    '设置状态改变情况下图像抓拍的时间间隔和次数': '设置状态改变情况下图像抓拍的时间间隔和次数',
    '拍照间隔': '拍照间隔',
    '触发条件': '触发条件',
    '开车门': '开车门',
    '关车门': '关车门',
    'ACC点火': 'ACC点火',
    'ACC熄火': 'ACC熄火',
    '空车': '空车',
    '重车': '重车',
    '进出范围': '进出范围',
    '速度为0': '速度为0',
    '选择分辨率': '选择分辨率',
    '上锁': '上锁',
    '开锁': '开锁',
    '打开位移报警': '打开位移报警',
    '关闭位移报警': '关闭位移报警',
    '位移半径，推荐300': '位移半径，推荐300',
    '米': '米',
    '仅平台': '仅平台',
    '平台+短信': '平台+短信',
    '平台+短信+电话': '平台+短信+电话',
    '打开震动报警': '打开震动报警',
    '关闭震动报警': '关闭震动报警',
    '选择报警通知方式': '选择报警通知方式',
    '查询ICCID并自动更新SIM卡号，该指令无需参数': '查询ICCID并自动更新SIM卡号，该指令无需参数',
    '升级': '升级',
    '停止': '停止',
    '服务器IP地址': '服务器IP地址',
    '端口号': '端口号',
    '终端软件版本号': '终端软件版本号',
    '退出追踪': '退出追踪',
    'WIFI>LBS,GPS 关': 'WIFI>LBS,GPS 关',
    '命令字': '命令字',
    '消息体': '消息体',
    '打开实时视频': '打开实时视频',
    '停止实时视频': '停止实时视频',
    '运动回传间隔': '运动回传间隔',
    '静止回传间隔': '静止回传间隔',
    '震动报警': '震动报警'
  }
}
