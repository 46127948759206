<template>
  <div class="wrapper" v-if="!isShowSuccess">
    <div class="top">
      <img class="img" src="../../assets/images/wb.png"/>
      <div class="wallet-remain">
        <p>{{holdName}}</p>
         <h5>{{$t('wallet.balance')}}</h5>
        <h4><strong>{{remain}}</strong>{{$t('wallet.vpoint')}}</h4>
        <!-- <p style="font-size:14px;">￥1 = 1{{$t('wallet.vpoint')}}</p> -->
      </div>
      <el-button type="primary" size="mini" @click="send">{{$t('wallet.gift')}}</el-button>
    </div>
    <div class="middle">
      
      <div class="borderBox">
        <p class="item" v-if="isAdministrator">{{$t('wallet.currentUser')}}：<span>{{$t('wallet.administrator')}}</span></p>
        <p class="item" v-if="isAdministrator">{{$t('wallet.targetUser')}}：<span>{{holdName}}</span></p>
        <p class="item">{{$t('wallet.pay')}}<span>{{form.PayYear}}{{form.giftPoints && !isAdministrator ?` + ${form.giftPoints}`:''}} {{$t('wallet.vpoint')}}</span></p>
      </div>
      <div class="borderBox">
        <div class="options">
          <div class="option" :class="{active: !form.isCustom && item.value === form.PayYear}" v-for="item in options" :key="item.value" @click="changePayYear(item)">{{item.label}}</div>
          <div class="option expand" :class="{active:form.isCustom}" @click="form.isCustom=true">
            <p v-if="!form.isCustom">{{$t('wallet.custom')}}</p>
            <p v-else>{{$t('wallet.payPrefix')}}￥<el-input-number v-model="form.PayYear" controls-position="right" :min="50" :max="999999"></el-input-number></p>
          </div>
        </div>
      </div>
      <div class="borderBox" v-if="!isAdministrator">
        <div class="bdTit" v-if="!isAdministrator">{{$t('wallet.payMethod')}}</div>
        <div class="pay-method"  @click="payMethod=1" v-if="!isAdministrator">
          <img class="pay-icon" src="../../assets/images/icon_alipay.svg"/>
          <div class="pay-info">
            <p class="name">{{$t('wallet.alipay')}}</p>
            <p class="desc">{{$t('wallet.alipayTip')}}</p>
          </div>
          <img class="pay-check" src="../../assets/images/check_box.svg" v-if="payMethod==1"/>
          <img class="pay-check" src="../../assets/images/checkbox_border.svg" v-else/>
        </div>
        <div class="pay-method imt-2" @click="payMethod=2" v-if="!isAdministrator">
          <img class="pay-icon" src="../../assets/images/yxx_wechat_pay_success.svg"/>
          <div class="pay-info">
            <p class="name">{{$t('wallet.wechat')}}</p>
            <p class="desc">{{$t('wallet.wechatTip')}}</p>
          </div>
          <img class="pay-check" src="../../assets/images/check_box.svg" v-if="payMethod==2"/>
          <img class="pay-check" src="../../assets/images/checkbox_border.svg" v-else/>
        </div>
      </div>

      <el-input
        class="imt-1"
        v-if="isAdministrator"
        type="textarea"
        :rows="4"
        :placeholder="$t('wallet.pleaseInputRemark')"
        v-model="remark">
      </el-input>

    </div>
    <transition name="el-zoom-in-bottom">
      <div class="wx-img" v-if="!!wxImgUrl && !isShowSuccess">
        <i-icon name="icon-close_circle_solid" class="wx-img-close" @click="closeWxImg"></i-icon>
        <el-row type="flex" justify="center">
          <img :src="wxImgUrl"/>
        </el-row>
        <el-row type="flex" justify="center">{{$t('wallet.payTotal')}}: ￥{{form.PayYear}}{{$t('wallet.payUnit')}}</el-row>
        <el-row type="flex" justify="center">
          <el-col type="flex" justify="center" align="middle">
            <i-icon name="icon-weixinzhifu" class="weixinzhifu"></i-icon>
          </el-col>
        </el-row>
      </div>
    </transition>
    <template v-if="!isAdministrator">
      <el-button class="bottom border" v-if="isSelf" v-ripple type="primary" plain @click="pay" :loading="btnLoading">{{$t('wallet.payButton')}}</el-button>
      <el-button class="bottom border" v-else type="primary" plain @click="fetchMine">{{$t('wallet.backMine')}}</el-button>
    </template>
    <template v-else>
      <el-button class="bottom border" type="primary" plain @click="addVpointAdmin">{{$t('wallet.sendVPoint')}}</el-button>
    </template>
  </div>
  <div class="wrapper wrapper--success" v-else>
    <i-icon class="success" name="icon-Checkbox_Selected-copy"></i-icon>
    <p class="success-tip">{{$t('wallet.successTip', {count:form.PayYear, total: form.PayYear+remain})}}</p>
    <el-button class="bottom" type="warning" @click="close" style="width:100%;" >{{$t('wallet.bottomButton')}}</el-button>
  </div>
</template>

<script>
import * as Wallet from '@/api/wallet'
import miment from 'miment'
import {mapGetters} from 'vuex'
import {BASE_URL} from '@/api/config'
import {getToken} from '@/common/auth'
export default {
  props: {
    id: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      options: [
        { label: '￥1000', value: 1000 },
        { label: '￥500', value: 500 },
        { label: '￥200', value: 200 },
        { label: '￥100', value: 100 }
      ],
      isShowSuccess: false,
      btnLoading: false,
      remain: null,
      oneYearPrice: 12,
      isSelf: true,
      holdName: '',
      startTime: null,
      payMethod: 1, // 支付宝 2 微信
      form: {
        ObjectID: null,
        ObjectName: 123123,
        VehicleName: null,
        PayYear: 1000,
        giftPoints: 100,
        isCustom: false
      },
      wxImgUrl: '',
      orderNo: '',

      remark: ''
    }
  },
  watch: {
    currentHoldId() {
      this.fetchData()
    },
    'form.PayYear': {
      deep: true,
      handler() {
        this.fetchGift()
      }
    },
  },
  computed: {
    endTime() {
      if (this.startTime) {
        return miment(this.startTime).add(this.form.PayYear, 'YYYY').format("YYYY-MM-DD")
      } else {
        return miment().add(this.form.PayYear, 'YYYY').format("YYYY-MM-DD")
      }
    },
    payTotal() {
      return this.oneYearPrice * this.form.PayYear
    },
    isAdministrator() {
      //return true
      return this.loginInfo.HoldID === 1 && this.loginInfo.loginName === 'admin'
    },
    ...mapGetters([
      'currentHoldId',
      'loginInfo'
    ])
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      Wallet.GetWalletInfo(this.currentHoldId).then(ret => {
        this.remain = ret.data.TotalBalance
        this.holdName = ret.data.Name
        this.isSelf = ret.data.isSelf
      })
    },
    fetchMine() {
      Wallet.GetWalletInfo(null).then(ret => {
        this.remain = ret.data.TotalBalance
        this.holdName = ret.data.Name
        this.isSelf = ret.data.isSelf
      })
    },
    fetchGift() {
      const promise = new Promise((resolve) => {
        Wallet.GetWalletDiscount(this.form.PayYear).then(ret => {
          this.form.giftPoints = ret.data.gift
          resolve(ret.data.gift)
        })
      })
      return promise
    },
    changePayYear(item) {
      this.form.PayYear = item.value
      this.form.isCustom = false
    },
    startCheckWxPay() {
      Wallet.IsWxPaySuccess(this.orderNo).then(ret => {
        if (ret.data.isSuccess) {
          this.isShowSuccess = true
        } else {
          this.startCheckWxPay_timer = setTimeout(() => {
            this.startCheckWxPay()
          }, 2000)
        }
      })
    },
    endCheckWxPay() {
      if (this.startCheckWxPay_timer) {
        clearTimeout(this.startCheckWxPay_timer)
        this.startCheckWxPay_timer = null
      }
    },
    send() {
      this.$emit('send')
    },
    pay() {
      // this.$message({
      //   message:'暂未开通',
      //   type: 'info'
      // })
      // return
      this.endCheckWxPay()
      if (this.payMethod == 2) {
        this.btnLoading = true
        Wallet.GetWxImage(this.form.PayYear).then(ret => {
          if (!ret.data.errCode) {
            this.orderNo = ret.data.orderNo
            this.wxImgUrl = `${BASE_URL}/pay/Image?text=${ret.data.code_url}`
            this.startCheckWxPay()
          } else {
            this.$alert({
              type: 'error',
              message: ret.data.errMsg,
              title: '提示信息'
            })
          }
          this.btnLoading = false
        })
      } else {
        this.startCheckWxPay()
        window.open(`${BASE_URL}/pay/Alipay?loginKey=${getToken()}&totalPoints=${this.form.PayYear}&format=json`);
      }


    },
    closeWxImg() {
      this.wxImgUrl = ''
      this.endCheckWxPay()
    },
    close() {
      this.$emit('close')
    },
    addVpointAdmin() {
      if (!this.remark) {
        this.$alert(this.$t('wallet.pleaseInputRemark'))
        return
      }

      this.$confirm(`${this.$t('wallet.confirmSend2', {target: this.holdName, count: this.form.PayYear})}`, this.$t('wallet.tip'), {
        confirmButtonText: this.$t('common.ok'),
        cancelButtonText: this.$t('common.cancel'),
        lockScroll: false,
        type: 'info'
      }).then(() => {
        Wallet.AddVpointAdmin(this.currentHoldId, this.form.PayYear, this.remark).then(ret => {
          console.log(ret)
          if (ret.data.errCode) {
            this.$alert(ret.data.errMsg)
            return
          }
          this.$alert(this.$t('wallet.sendSuccessed'))
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  // height: 595px;
  position: relative;
  &.wrapper--success {
    background-color: #89ABE3;
  }
  padding: 10px 20px 80px 20px;
}
.top {
  height: 100px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  .img {
    width: 100%;
    height: 100%;
    position: absolute;
    left:0;
    top:0;
    z-index: -1;
  }
  p{
    line-height: 22px;
    font-size: 16px;
  }
  h5{
    font-size: 12px;
    font-weight: normal;
  }
  h4{
    font-size: 20px;
    font-weight: normal;
    strong{
      font-size: 24px;
    }
  }
  .wallet-remain {
    padding-top: 10px;
    padding-left: 15px;
    color:#fff;
    
  }
  .el-button{
    display: block;
    position: absolute;
    right: 20px;
    top: 30px;
    z-index: 1;
    width: 100px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    font-size: 16px;
    color: #fff;
    border-radius: 25px;
    padding:0;
    background-color: #f1a501;
    cursor: pointer;
  }

}
.middle {
  height: auto;
  overflow: hidden;
  .item {
    font-size: 12px;
    height: 24px;
    line-height: 24px;
    color: #303133;
    span{
      color: #1890ff;
      float: right;
    }
  }
  .borderBox{
    padding: 10px 20px;
    margin-top: 10px;
    border: 1px solid #f3f3f3;
    border-radius: 20px;
    .bdTit{
      height: 24px;
      font-size: 14px;
      color:#80899a;
    }
  }
  .options {
    margin-right: -10px;
    margin-bottom: -10px;
    display: flex;
    position: relative;
    flex-wrap: wrap;
    .option {
      cursor: pointer;
      width: calc(50% - 10px);
      border: 1px solid #f9f9f9;
      border-radius: 5px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      margin-right: 10px;
      margin-bottom: 10px;
      color:#4d6182;
      font-size: 16px;
      &.active {
        color: #fff;
        background-color: #f1a501;
      }
      &:hover {
        color: #fff;
        background-color: #f1a501;
      }

      &.expand {
        width: calc(100% - 10px);
      }
    }
  }
  .tip {
    color: #67C23A;;
  }
  .pay-method {
    height: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .pay-icon {
      height: 40px;;
    }
    .pay-info {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      font-size: 12px;
      margin-left: 15px;
      .name {
        color: #000;
        margin-bottom: 5px;
      }
      .desc {
        color: #959595;
      }
    }
    .pay-check {
      height: 18px;
    }
  }
}
.bottom {
  position: absolute;
  height: 40px;
  width: 80%;
  bottom: 15px;
  left: 50%;
  margin-left: -40%;
  z-index: 1;
  &.el-button {
    height: 40px;
    border-radius:20px;
  }
}
.success {
  font-size: 128px;
  padding-top: 40px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.success-tip {
  color: #ECEFF1;
  font-size: 13px;
  line-height: 25px;
  padding: 24px;
}
.wx-img {
  position: absolute;
  height: 415px;
  z-index: 2;
  left: 0;
  bottom: 2px;
  background-color: #FFF;
  width:100%;
  box-shadow: 0 2px 25px rgba(0, 0, 0, .4);
  img {
    margin-top: 48px;
    margin-bottom: 48px;
  }
  .icon.weixinzhifu {
    font-size: 10em;
  }
  .wx-img-close {
    position: absolute;
    right:5px;
    top: -5px;
    cursor: pointer;
    font-size: 27px;
    transform: translateY(-100%);
    border: 1px solid rgba(0, 0, 0, .4);
    border-radius: 50%;
  }
}
</style>
