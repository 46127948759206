import InfoWindow from './infoWindow.vue'
import i18n from '@/lang'

const infoWindow = {
  install(Vue) {
    const panel = Vue.extend(InfoWindow)
    Vue.prototype['$infowindow'] = {
      create: datas => {
        let el = new panel({
          i18n
        })
        Object.assign(el, datas)
        return el.$mount().$el
      },
      getInstance: datas => {
        let el = new panel({
          i18n
        })
        Object.assign(el, datas)
        return el
      }
    }

  }
}

export default infoWindow
