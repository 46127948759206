import request from '@/common/request'
import { commonParams } from '@/api/config'
import { getToken } from '@/common/auth'

export function Query() {
  const loginKey = getToken()
  return request({
    url: './query/QueryAlarm',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
    })
  })
}

export function Modify(AID) {
  const loginKey = getToken()
  return request({
    url: './modify/ModifyAlarm',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      AID
    })
  })
}
