<template>
  <div class="i-map-panel" style="" @drag.stop @mousedown.stop @mousemove.stop @mouseup.stop v-show="visible">
    <!-- :style="{backgroundColor: titleBackground}" -->
    <div class="i-map-panel-head " :class="{'elevation-1': false}">
      <p class="i-map-panel-head__text" :style="headClick?{cursor:'pointer'}:null"
        v-html="title" @click="headClickHandler"></p>
      <add-task-car v-if="showHeadButton" @click="onHeadButtonClick"></add-task-car>
      <div class="i-map-panel-head__right" v-html="time"></div>
      <span class="i-map-panel-head__right alarm" :title="$t('other.cancelAlarm')"><i-icon  v-if="isAlarm" name="icon-bell-ring" style="flex: 0 0 16px;" @click="cancelAlarm"></i-icon></span>
      <svg class="icon i-map-panel-head__close" aria-hidden="true" style="font-size:20px;" v-if="close" @click="onClose">
        <use xlink:href="#icon-close"></use>
      </svg>
      <span class="video-1" v-if="showDvr">
        <img src="../../assets/images-fk/dvr.png" alt="" @click="videoURL(dvrData)">
      </span>
    </div>
    <div class="i-map-panel-content">
        <template v-if="$i18n.locale==='fr'">
          <div class="i-map-panel-content__batteryWrapper2" v-if="externalData && externalData.battery != 0">
            <img :src="require(`../../assets/battery/${parseInt(externalData.battery/10).toFixed(0)*10}.png`)" style="height:60px;"/>
            <p>{{$t('commonPanel.battery')}}{{externalData.battery}}%</p>
            <p class="batteryLife" v-if="externalData.batteryLife">{{$t('commonPanel.endurance')}} {{externalData.batteryLife}} {{$t('commonPanel.day')}}</p>
           </div>
        </template>
        <template v-else>
          <div class="i-map-panel-content__batteryWrapper" v-if="externalData && externalData.battery != 0">
            <img :src="require(`../../assets/battery/${parseInt(externalData.battery/10).toFixed(0)*10}.png`)" style="height:80px;"/>
            <p>{{$t('commonPanel.battery')}}{{externalData.battery}}%</p>
            <p class="batteryLife" v-if="externalData.batteryLife">{{$t('commonPanel.endurance')}} {{externalData.batteryLife}} {{$t('commonPanel.day')}}</p>
          </div>
        </template>
      <div class="i-map-panel-content__ubiWrapper" v-if="externalTag">
         <img :src="require(`../../assets/icon/trackUbi${externalTagData}.png`)"/>
      </div>
      <div class="i-map-panel-content__item clearfix" v-for="(item,index) in datas.filter(k => !!k)" :key="index" :style="item.hidden ? (externalData && externalData.battery != 0 ? 'visibility:hidden' : 'display:none') : ''">
        <template v-if="$i18n.locale==='fr'">
           <p class="i-map-panel-content__item-title"><span>{{item.title}}</span>{{labelSuffix}}</p>
        </template>
        <template v-else>
          <p class="i-map-panel-content__item-title" :style="{'width': paddingLeft}"><span :style="{width:labelWidth}">{{item.title}}</span>{{labelSuffix}}</p>
        </template>
        <p class="i-map-panel-content__item-text" :style="{'margin-left': paddingLeft}" v-if="item.rawHtml" v-html="item.text">
        </p>
        <p class="i-map-panel-content__item-text" :style="{'margin-left': paddingLeft}" v-else-if="item.highlight">
          <span :class="{'highlight': item.highlight, [item.theme]: item.highlight}" :title="item.desc">{{item.text}}</span>
        </p>
        <p class="i-map-panel-content__item-text" :style="{'margin-left': paddingLeft}" :title="item.desc" v-else>{{item.text}}</p>
      </div>
      <template v-if="showWifiInfo">
        <div class="i-map-panel-content__item clearfix" v-if="wifiLon">
          <p class="i-map-panel-content__item-title" :style="{'width': paddingLeft}" :class="{active:activeType=='wifi' && locationCount > 1}"><span :style="{width:labelWidth}">{{$t('commonPanel.wifiLocation')}}</span>{{labelSuffix}}</p>
          <p class="i-map-panel-content__item-text" :style="{'margin-left': paddingLeft}">
            <span-address  :lon="wifiLon" :lat="wifiLat" :show-default="showAddrDefault" :disabled="disabledAddress" @click="onAddressClick(objectId)" :time="rtime"></span-address>
          </p>
        </div>
        <div class="i-map-panel-content__item clearfix" v-if="lbsLon">
          <p class="i-map-panel-content__item-title" :style="{'width': paddingLeft}" :class="{active:activeType=='lbs' && locationCount > 1}"><span :style="{width:labelWidth}">{{$t('commonPanel.lbsLocation')}}</span>{{labelSuffix}}</p>
          <p class="i-map-panel-content__item-text" :style="{'margin-left': paddingLeft}">
            <span-address :lon="lbsLon" :lat="lbsLat" :show-default="showAddrDefault" :disabled="disabledAddress" @click="onAddressClick(objectId)" :time="rtime"></span-address>
          </p>
        </div>
        <div class="i-map-panel-content__item clearfix">
          <p class="i-map-panel-content__item-title" :style="{'width': paddingLeft}" :class="{active:activeType=='gps' && locationCount > 1}"><span :style="{width:labelWidth}">{{gpsLabel}}</span>{{labelSuffix}}</p>
          <p class="i-map-panel-content__item-text" :style="{'margin-left': paddingLeft}">
            <span-address v-if="gpsLon" :lon="gpsLon" :lat="gpsLat" :show-default="showAddrDefault" :disabled="disabledAddress" @click="onAddressClick(objectId)" :time="gtime"></span-address>
            <span v-else>({{$t('commonPanel.noPlace')}})</span>
          </p>
        </div>
      </template>
      <div class="i-map-panel-content__item clearfix" v-if="!hideOriginGps">
        <template v-if="$i18n.locale==='fr'">
          <p class="i-map-panel-content__item-title">
            <span>{{gpsLabel}}</span>{{labelSuffix}}
          </p>
        </template>
        <template v-else>
          <p class="i-map-panel-content__item-title" :style="{'width': paddingLeft}">
            <span :style="{width:labelWidth}">{{gpsLabel}}</span>{{labelSuffix}}
          </p>
        </template>
        <p class="i-map-panel-content__item-text" :style="{'margin-left': paddingLeft}">
          <span-address :lon="lon" :lat="lat" :show-default="showAddrDefault" :disabled="disabledAddress" @click="onAddressClick(objectId)" :time="gtime"></span-address>
        </p>
      </div>
      <div class="i-map-panel-content__item" v-if="externalData && getScheduleLabel(externalData.schedule) != '暂无'">
        <p class="i-map-panel-content__item-title" style="line-height:24px;">
          <span :style="{width:labelWidth}">{{$t('commonPanel.wake')}}</span>{{labelSuffix}}
        </p>
        <p class="i-map-panel-content__item-text timer" style="line-height:24px;" :style="{'padding-left': paddingLeft}" v-html="getScheduleLabel(externalData.schedule)">

        </p>
      </div>
      <div class="i-map-panel-content__item" v-if="moreBtnVisible">
        <p class="i-map-panel-content__item-title"></p>
        <p class="i-map-panel-content__item-text">
          <el-button type="text" @click="moreBtnVisible=false">More<i class="el-icon-arrow-down"></i></el-button>
        </p>
      </div>
      <template v-if="!moreBtnVisible" >
        <div class="i-map-panel-content__item" v-for="(item,index) in more"  :key="`M${index}`">
          <p class="i-map-panel-content__item-title">{{item.title}}</p>
          <p class="i-map-panel-content__item-text" :style="{'padding-left': paddingLeft}">{{item.text}}</p>
        </div>
      </template>
      <div class="i-map-panel-content__link" v-if="!!links.length">
        <span class="link__item" :class="{'is-icon': !!item.icon}" v-for="(item,index) in links" v-if="item" :key="index" @click="e => onLinkClick(item.text || item, )"
          v-tooltip.top="{
            content: !!item.icon ? item.text : null,
            classes: ['tooltip-panel-link'],
            delay: {
              show: 0,
              hide: 0
            }
          }">
          <i v-if="item.icon">
            <i-icon :name="item.icon" :class="item.icon"></i-icon>
          </i>
          <i v-else>
            {{item.text || item}}
          </i>
        </span>
      </div>
    </div>
    <div class="i-map-panel-jiao" v-if="bgColor">
      <div class="i-map-panel-jiao_left "><div class="i-map-panel-jiao_left_inner "></div></div>
      <div class="i-map-panel-jiao_right "><div class="i-map-panel-jiao_right_inner "></div></div>
      <!-- <div class="i-map-panel-jiao_inner elevation-1 i-map-panel-jiao_inner-right"></div> -->
    </div>
  </div>
</template>

<script>
import SpanAddress from '@/views/common/span-address'
import AddTaskCar from '@/views/common/add-task-car'
import VipPanel from '@/components/vip-panel'
import {GetWIFIAndLBSInfoByObjectID} from '@/api/common'
import {debounce} from '@/common/utils'
import { modelId } from 'vuex'
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    signal: {
      type: [String, Number],
      default: 0
    },
    battery: {
      type: [String, Number],
      default: 0
    },
    datas: {
      type: Array,
      default: () => ([])
    },
    more: {
      type: Array,
      default: () => ([])
    },
    close: {
      type: Boolean,
      default: true
    },
    onChange: {
      type: Function,
      default: () => {}
    },
    onClose: {
      type: Function,
      default: () => {}
    },
    onHeadClick: {
      type: Function,
      default: () => {}
    },
    titleBackground: {
      type: String,
      default: '#0277BD'
    },
    attachState: {
      type: String,
      default: ''
    },
    bgColor: {
      type: Boolean,
      default: true
    },
    showMore: {
      type: Boolean,
      default: false
    },
    time: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      default: true
    },
    paddingLeft: {
      type: String,
      default: '100px'
    },
    links: {
      type: Array,
      default:() => ([])
    },
    dvrData:{
      type: Array,
      default:() => ([])
    },
    onLinkClick: {
      type: Function,
      default:() => {
      }
    },
    isAlarm: {
      type: Boolean,
      default: false
    },
    onCancelAlarm: {
      type: Function,
      default: () => {}
    },
    objectId: {
      type: Number,
      default: null
    },
    headClick: {
      type: Boolean,
      default: false
    },
    lon: {
      type: [Number, String],
      default: null
    },
    lat: {
      type: [Number, String],
      default: null
    },
    externalTag: {
      type: Boolean,
      default: false
    },
    externalData: {
      type: Object,
      default: null
      // 有线设备无线设备的额外信息如：
      // externalData = {
      //   trackerType: 3212,
      //   battery: device.Battery,
      //   batteryLife: device.BatteryLife,
      //   schedule: device.Schedule
      // }
    },
    showHeadButton: {
      type: Boolean,
      default: false
    },
    onHeadButtonClick: {
      type: Function,
      default: () => {}
    },
    videoURL:{
      type:Function,
      default:()=>{}
    },
    showAddrDefault: {
      type: Boolean,
      default: false
    },
    labelSuffix: {
      type: String,
      default: '：'
    },
    showWifiInfo: {
      type: Boolean,
      default: false
    },
    gpsLabelDefault: {
      type: String,
      default: ''
    },
    disabledAddress: {
      type: Boolean,
      default: false
    },
    onAddressClick: {
      type: Function,
      default: () => {
      }
    },
    gtime: {
      type: String,
      default: ''
    },
    rtime: {
      type: String,
      default: ''
    },
    hideOriginGps: {
      type: Boolean,
      default: false
    },
    onAddressUpdate: {
      type: Function,
      default: () => {
      }
    },
    SF: {
      type: Number,
      default: 0
    },
    showDvr: {
      type: Boolean,
      default: false
    },
  },
  watch: {
    more() {
      this.moreBtnVisible = this.more.length > 0 && !this.showMore
    },
    moreBtnVisible(val) {
      this.onChange(!val)
    },
    objectId() {
      this.d_getWifiLocation()
    },
    gpsLabelDefault(val) {
      this.gpsLabel = val
    }
  },
  created() {
    this.d_getWifiLocation = debounce(this.getWifiLocation, 50)
    if (this.showWifiInfo) {
      this.getWifiLocation()
    }
  },
  mounted() {
    if(this.datas[6]&&this.datas[6].modelId==='3169'){
       this.changeVideo = true
    }else{
       this.changeVideo = false
    }
  },
  data() {
    return {
      changeVideo:false,
      moreBtnVisible: this.more.length > 0 && !this.showMore,
      wifiLon: null,
      wifiLat: null,
      lbsLon: null,
      lbsLat: null,
      gpsLon: null,
      gpsLat: null,
      activeType: 'gps',
      gpsLabel: this.gpsLabelDefault || this.$t('commonPanel.place'),
      vid: process.env.VUE_APP_VID,
      //a:null
    }
  },
  computed: {
    batteryIcon() {
      let icon = parseInt(this.battery / 10)
      let color = '#4CAF50'
      if (this.battery > 80) {
        color = '#4CAF50'
      } else if (this.battery > 50) {
        color = '#8BC34A'
      } else if (this.battery > 25) {
        color = '#FF9800'
      } else {
        color = '#FF5722'
      }
      return `
        <svg class="icon" aria-hidden="true" style="font-size:48px;fill:${color}">
          <use xlink:href="#icon-battery-${icon}"></use>
        </svg>
        <p style="font-size: 12px;font-weight: 500;color: #606266;">电量：${this.battery}%</p>
        `
    },
    signalIcon() {
      const n = parseInt(this.signal)
      let icon = 0
      if (n < -115) {
        icon = 0
      } else if (n < -105) {
        icon = 1
      } else if (n < -95) {
        icon = 2
      } else if (n < -85) {
        icon = 3
      } else if (n >= -85) {
        icon = 4
      }
      return `
        <svg class="icon" aria-hidden="true" style="font-size:16px;margin-right: -5px;">
          <use xlink:href="#icon-si-glyph-antenna-"></use>
        </svg>
        <svg class="icon" aria-hidden="true" style="font-size:20px;">
          <use xlink:href="#icon-ic_signal_cellular__bar${icon}"></use>
        </svg>
        `
    },
    labelWidth() {
      return `calc(${this.paddingLeft} - 1em)`
    },
    locationCount() {
      if (!this.showWifiInfo) {
        return false
      }
      let i = 0;
      if (this.wifiLon) {
        i++
      }
      if (this.gpsLon) {
        i++
      }
      if (this.lbsLon) {
        i++
      }
      return i
    }
  },
  methods: {
    // classChange(){
    //   // 保存modelId判断图标显示隐藏
    //   this.a = this.datas[6].modelId
    //   if(this.a == null){
    //     this.changeVideo = true
    //   }
    //   if(this.a == 3169){
    //     this.changeVideo = false
    //   }else{
    //     this.changeVideo = true
    //   }
    // },
    openMore() {
      this.moreBtnVisible = false
    },
    headClickHandler(e) {
      this.onHeadClick(e, this.objectId)
    },
    getScheduleLabel(schedule) {
      if (!schedule) {
        return '暂无'
      }
      if (schedule.indexOf(':') != -1) {
        const numberArray = schedule.replace(/:/g, '').split(' ')
        const now = new Date()
        const maxArray = numberArray.filter(k => {
          return parseInt(k) > (now.getHours() * 100 + now.getMinutes())
        })

        let highligntItemIndex = 0
        if (maxArray.length > 0) {
          highligntItemIndex = numberArray.indexOf(maxArray[0])
        }

        let output = ''
        const timeArray = schedule.split(' ')
        timeArray.forEach((k, index) => {
          output += index === highligntItemIndex ? `<img src="${require('../../assets/icon/Timer24.gif')}" style="vertical-align:bottom;margin-right:4px;" alt="${this.$t('commonPanel.wakeTime')}" title="${this.$t('commonPanel.wakeTime')}"/><span style="color:red;">${k}</span>` : k
          output += "&nbsp;"
        })
        return output
      } else {
        let iPos = 0
        let numberArray = []
        let timeArray = []
        while (iPos + 4 <= schedule.length) {
          numberArray.push(schedule.substr(iPos, 2) + schedule.substr(iPos + 2, 2))
          timeArray.push(schedule.substr(iPos, 2) + ':' + schedule.substr(iPos + 2, 2))
          iPos = iPos + 4;
        }

        const now = new Date()
        const maxArray = numberArray.filter(k => {
          return parseInt(k) > (now.getHours() * 100 + now.getMinutes())
        })

        let highligntItemIndex = 0
        if (maxArray.length > 0) {
          highligntItemIndex = numberArray.indexOf(maxArray[0])
        }

        let output = ''
        timeArray.forEach((k, index) => {
          output += index === highligntItemIndex ? `<img src="${require('../../assets/icon/Timer24.gif')}" style="vertical-align:bottom;margin-right:4px;" alt="${this.$t('commonPanel.wakeTime')}" title="${this.$t('commonPanel.wakeTime')}"/><span style="color:red;">${k}</span>` : k
          output += "&nbsp;"
        })
        return output
      }
    },
    cancelAlarm() {
      this.onCancelAlarm(this.objectId)
    },
    getWifiLocation() {
      if (this.showWifiInfo) {
        GetWIFIAndLBSInfoByObjectID(this.objectId).then(ret => {
          if (!ret.data.cell.points.length) {
            this.lbsLon = null
            this.lbsLat = null
          } else {
            this.lbsLon = ret.data.cell.sCenter.Lng
            this.lbsLat = ret.data.cell.sCenter.Lat
          }

          if (!ret.data.wifi.points.length) {
            this.wifiLon = null
            this.wifiLat = null
          } else {
            this.wifiLon = ret.data.wifi.sCenter.Lng
            this.wifiLat = ret.data.wifi.sCenter.Lat
          }

          if (ret.data.gps.center.Lng) {
            this.gpsLon = ret.data.gps.sCenter.Lng
            this.gpsLat = ret.data.gps.sCenter.Lat
          } else {
            this.gpsLon = null
            this.gpsLat = null
          }

          this.activeType = ret.data.type
          setTimeout(() => {
            if (this.locationCount > 1) {
              this.onAddressUpdate()
            }
          }, 150)
        }).catch(e => {
          console.error(e)
          this.wifiLon = null
          this.wifiLat = null
          this.lbsLon = null
          this.lbsLat = null
          this.gpsLon = null
          this.gpsLat = null
        })
      } else {
        this.wifiLon = null
        this.wifiLat = null
        this.lbsLon = null
        this.lbsLat = null
        this.gpsLon = null
        this.gpsLat = null
      }
    }
  },
  components: {
    AddTaskCar,
    SpanAddress,
    VipPanel
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/variables-simple.scss";
$opacity: .9;
$radius: 8px;
.video-1{
  position: absolute;
  top: 2px;
  right: 50px;
  cursor: pointer;
  img{
    width: 20px;
    height: 25px;
    margin-left:10px;
  }
}
.is-video{
   display: none;
}
.i-map-panel {
  width: 350px;
  z-index: 1;
  margin-top: 0;
  opacity: $opacity;
  cursor: default;
  p {
    margin: 0;
    line-height: 14px;
  }

  .highlight {
    color: #009FDB !important;
    @mixin box{
      border-radius: 2px;
      padding: 0 2px;
    }
    &.success {
      color: #4CAF50 !important;
      border: 1px solid #4CAF50;
      @include box;
    }
    &.error {
      color: #f44336 !important;
      border: 1px solid #f44336;
      @include box;
    }
    &.warning {
      color: #FF5722 !important;
      border: 1px solid #FF5722;
      @include box;
    }
    &.info {
      color: #9E9E9E !important;
      border: 1px solid #9E9E9E;
      @include box;
    }
    &.default {
      color: #409EFF !important;
      border: 1px solid #409EFF;
      @include box;
    }
  }

  &-head {
    padding:  20px 12px 8px 12px;
    position: relative;
    background: url(../../assets/images/gs-d21.png) no-repeat center bottom;
    background-size: 100% 100%;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    text-indent: 10px;
    min-height: 14px;
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;
    .add-task-car {
      margin-left: 4px;
    }
    &__text {
      //padding-right: 100px;
      margin: 0;
      font-size: 14px;
      font-weight: 600;
      & .state {
        font-size: 12px;
        display: inline-block;
        margin-left: 8px;
        font-weight: 500;
        //color: #ECEFF1;
      }
    }
    &__right {
      position: absolute;
      // top: 8px;
      right: 35px;
      &.hasClose {
        right: 40px;
      }
      &.alarm {
        animation: flash infinite 1s linear;
        font-size: 15px;
        &:hover {
          animation: none;
          fill: #C8E6C9;
          cursor: pointer;
        }
      }
    }
    &__close {
      position: absolute;
      right: 8px;
      top: 12px;
      cursor: pointer;
      &:hover {
        fill: #C8E6C9;
      }
    }
  }
  &-content {
    padding: 8px 14px;
    background: url(../../assets/images/gs-d22.png) repeat-y center;
    background-size: 100% auto;
    // background-color: #FFF;
    // position: relative;
    // border-bottom-left-radius: 8px;
    // border-bottom-right-radius: 8px;
    &__battery {
      position: relative;
      &-left {
        padding-right: 100px;
      }
      &-right {
        position: absolute;
        top: 8px;
        right: 16px;

      }
    }
    &__batteryWrapper {
      position: absolute;
      right: 12px;
      top: 0;
      z-index: 1;
      padding: 5px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      img {
        margin-bottom: 3px;
      }
      p {
        font-size: 12px;
        line-height: 18px;
        &.batteryLife {
          color: rgb(78, 171, 5);
        }
      }
    }
    &__batteryWrapper2{
      position: absolute;
      right: 12px;
      top: 22px;
      z-index: 1;
      padding: 5px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      img {
        margin-bottom: 3px;
      }
      p {
        font-size: 12px;
        line-height: 12px;
        &.batteryLife {
          color: rgb(78, 171, 5);
        }
      }
    }
    &__ubiWrapper {
      position: absolute;
      right: 12px;
      top: -4px;
      z-index: 1;
      padding: 5px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      img {
        height: 50px;
      }
      // p {
      //   font-size: 12px;
      //   line-height: 18px;
      //   &.batteryLife {
      //     color: rgb(78, 171, 5);
      //   }
      // }
    }
    &__item {
      position: relative;
      padding: 4px 0;
      font-size: 12px;
      font-weight: 500;
      color: #fff;
      &-title {
        //position: absolute;
        top: 4px;
        left: 0;
        float: left;
        white-space: nowrap;
        &.active::before {
          content: '▶';
          display: inline-block;
          transform: translateX(calc(-100% - 1px));
          position: absolute;
          // color: red;
        }
        span {
          text-align-last: justify;
          display: inline-block;
        }
      }
      &-text {
        min-height: 1em;
        //padding-left: 100px;
        padding-left: 4px;
        color: #fff;
        word-break: break-word;
        white-space: normal;
        &.timer {
          height: 24px;
          line-height: 24px;
        }
      }
    }
    &__link {
      margin-top: 6px;
      margin-left: -8px;
      margin-right: -8px;
      border-top: 1px solid #DCDFE6;
      padding: 4px 0;
      // height: 32px;
      // line-height: 32px;
      margin-bottom: -8px;
      display: flex;
      // justify-content: space-around;
      //background-color: rgba($--color-primary,.12);

      .link__item {
        border-radius: 2px;
        padding: 4px 0;
        width: 0;
        flex-grow: 1;
        font-size: 12px;
        //padding: 0 8px;
        color: #fff;
        //border-radius: 4px;
        cursor: pointer;
        transition: all 0.3s ease;
        overflow: hidden;
        white-space: nowrap;
        word-break: keep-all;
        text-align: center;
        &.is-icon {
          padding: 0;
          font-size: 18px;
        }
        &:hover {
          background-color: $--color-primary;
          color: #FFF;
        }
      }
    }
  }
  &-jiao {
    width: 100%;
    height: 56px;
    background: url(../../assets/images/gs-d23.png) no-repeat center bottom;
    background-size: 100% 100%;
    position: absolute;
    left:0;
    bottom: -12px;
    z-index: -1;

    // background-color: transparent;
    // text-align: center;
    // overflow: hidden;
    // height: 17px;
    // position: absolute;
    // width: 32px;
    // height: 30px;
    // left: 50%;
    // transform: translateX(-50%);

    &_left {
      display: none;
      position: absolute;
      height: 100%;
      width: 50%;
      left: 0;
      top: 0;
      overflow: hidden;
      &_inner {
        position: absolute;
        left: 70%;
        top: 0;
        height: 100%;
        width: 75%;
        transform: skewX(22.6deg);
        box-shadow: rgba(0, 0, 0, 0.6) 0px 1px 6px;
        background-color: #FFF;
      }
    }

    &_right {
      display: none;
      position: absolute;
      height: 100%;
      width: 50%;
      left: 50%;
      top: 0;
      overflow: hidden;
      &_inner {
        position: absolute;
        right: 70%;
        top: 0;
        height: 100%;
        width: 75%;
        transform: skewX(-22.6deg);
        box-shadow: rgba(0, 0, 0, 0.6) 0px 1px 6px;
        background-color: #FFF;
      }
    }
  }
}
@keyframes flash {
  10% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
}
</style>

