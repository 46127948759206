<template>
  <div>
    <el-row class="imb-1" v-if="mode==='car'">
      <el-alert :title="$t('moveParent.tip')" type="warning" :closable="false"></el-alert>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-form label-width="120px">
          <el-form-item :label="`${$t('moveParent.moveTarget')}:`">
            <template v-if="list.length <= 6">
              <el-tag v-for="item in list" :key="item[prop.id]" class="imr-0">{{item[prop.name]}}</el-tag>
            </template>
            <template v-else>
              <el-tag :key="list[0][prop.id]" class="imr-0">{{list[0][prop.name]}}</el-tag>
              <el-tag :key="list[1][prop.id]" class="imr-0">{{list[1][prop.name]}}</el-tag>
              <p>...</p>
              <el-tag :key="list[list.length - 2][prop.id]" class="imr-0">{{list[list.length - 2][prop.name]}}</el-tag>
              <el-tag :key="list[list.length - 1][prop.id]" class="imr-0">{{list[list.length - 1][prop.name]}}</el-tag>
              <p class="tip" v-html="$t('moveParent.total', {count: `<em>${list.length}</em>`})"></p>
            </template>
            <p @click="$refs.batchInput.open()" class="i-link" v-if="batchInput">{{$t('moveParent.inputIds')}}</p>
          </el-form-item>
          <el-form-item :label="`${$t('moveParent.oldParent')}:`">
            <i-icon name="icon-account"></i-icon>{{oldHoldInfo.name}}
          </el-form-item>
          <el-form-item :label="`${$t('moveParent.newParent')}:`">
            <span v-if="newHoldInfo.id"><i-icon name="icon-account"></i-icon>{{newHoldInfo.name}}</span>
            <span v-else style="color:#C0C4CC;">{{$t('moveParent.selectParent')}}</span>
          </el-form-item>
          <el-form-item :label="`${$t('moveParent.vipExpiring')}:`" v-if="mode==='device'&&platform=='_kc'">
            <el-date-picker type="date" v-model.trim="serviceDate"  :placeholder="$t('userDetail.pleaseSelctStartTime')" style="width:100%;"></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-row type="flex">
              <el-button type="primary border gs-button2" plain @click="submit">{{$t('common.ok')}}</el-button>
            </el-row>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="12">
        <div class="selectUser" :class="{'showTip': mode==='car'}">
          <tree-user :checkbox="false" :title="$t('moveParent.selectUser')" show-icon @click="treeClick" :collapse="false" :widthFixed= 'false'>
          </tree-user>
        </div>
      </el-col>
    </el-row>
    <batch-input ref="batchInput" @confirm="mergeList" v-if="batchInput"></batch-input>
  </div>
</template>

<script>
import TreeUser from '@/views/common/tree-user'
import {BatchChangeParentHoldForHold} from '@/api/hold'
import {BatchChangeParentHoldForDevice,BatchChangeParentHoldAndServiceDateForDevice} from '@/api/device'
import {BatchChangeParnetHoldForVehicle} from '@/api/vehicle'
import BatchInput from '@/views/manage/components/batch-input'
export default {
  props: {
    datas: {
      type: Array,
      default: () => ([])
    },
    oldHoldInfo: {
      type: Object,
      default: () => ({})
    },
    mode: {
      type: String,
      default: 'car' // car ; user ; device ;
    },
    batchInput: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    prop() {
      return {
        car: { id: 'VehicleID', name: 'VehicleName' },
        user: { id: 'HoldID', name: 'HoldName' },
        device: { id: 'ObjectID', name: 'SIM2' }
      }[this.mode]
    }
  },
  data() {
    return {
      list: this.datas.slice(),
      btnLoading: false,
      serviceDate:'',
      newHoldInfo: {
        id: 0,
        name: ''
      },
      platform: process.env.VUE_APP_PLAT,
    }
  },
  watch: {
    datas() {
      this.list = this.datas.slice()
    }
  },
  components: {
    TreeUser,
    BatchInput
  },
  methods: {
    treeClick(id, name) {
      this.newHoldInfo.id = id
      this.newHoldInfo.name = name
    },
    submit() {
      if (!this.list.length) {
        this.$alert(this.$t('common.emptySelectedTip'))
        return
      }
      if (!this.newHoldInfo.id) {
        this.$alert(this.$t('moveParent.selectParent'))
        return
      }
      this.btnLoading = true
      // this.$alert('修改父级' + this.datas.map(k => k[this.prop.id]) + '新父级:' + this.newHoldInfo.id)
      const keys = this.list.map(k => k[this.prop.id])
      const API = {
        'car': BatchChangeParnetHoldForVehicle,
        'device': process.env.VUE_APP_PLAT === '_kc'?BatchChangeParentHoldAndServiceDateForDevice:BatchChangeParentHoldForDevice,
        'user': BatchChangeParentHoldForHold
      }[this.mode]

      API(keys, this.newHoldInfo.id,this.serviceDate).then(ret => {
        if (!ret.data.errCode) {
          this.$message({
            message: this.$t('moveParent.changeSuccessed'),
            type: 'success'
          })
          this.close()
          this.$emit('refresh')
        }
      })

    },
    close() {
      this.$emit('close')
    },
    mergeList(table) {
      table.forEach(k => {
        if (this.list.findIndex(p => p[this.prop.id] == k[this.prop.id]) == -1) {
          this.list.push(k)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.selectUser {
  height:410px;
  position:relative;
  border:1px solid #DCDFE6;
  &.showTip {
    height: 362px;
  }
}
.el-tag {
  vertical-align: middle;
}
.tip {
  color: rgba(0,0,0,.54);
  & ::v-deep em {
    color: #cc3333;
    margin: 0 4px;
    font-style: normal;
  }
}
.gs-button2{
  background: url(../../assets/images/form-btn1.png) no-repeat center !important;
  background-size: auto 100% !important;
  color: #2F302C !important;
  padding: 0;
  border: none;
  height: 42px;
  min-width: 110px;
}
.gs-button2:hover{
  color: #2F302C !important;
  background: url(../../assets/images/form-btn1.png) no-repeat center !important;
  background-size: auto 100% !important;
}
</style>
