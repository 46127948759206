<template>
  <el-popover trigger="hover" v-model="visible" :width="350" placement="right-start" popper-class="el-popper--isnp" :visible-arrow="false">

    <div class="cp" v-if="false">
      <el-row :gutter="gap">
        <el-col :span="24">
          <label>用户名称</label>
          <p>{{item.HoldName}}</p>
        </el-col>
      </el-row>
      <el-row :gutter="gap">
        <el-col :span="12">
          <label>{{$t('popoverInfo.loginName')}}</label>
          <p>{{item.UserName}}</p>
        </el-col>
        <el-col :span="12">
          <label>{{$t('popoverInfo.serviceEnddate')}}</label>
          <p>{{miment(item.ServiceEndDate).format()}}</p>
        </el-col>
      </el-row>
      <el-row :gutter="gap">
        <el-col :span="12">
          <label>{{$t('popoverInfo.contacter')}}</label>
          <p>{{item.Contacter||'--'}}</p>
        </el-col>
        <el-col :span="12">
          <label>{{$t('popoverInfo.mobile')}}</label>
          <p>{{item.Tel||'--'}}</p>
        </el-col>
      </el-row>
      <el-row :gutter="gap">
        <el-col :span="12">
          <label>{{$t('popoverInfo.remark')}}</label>
          <p>{{item.Remark||'--'}}</p>
        </el-col>
        <el-col :span="12">
          <label>{{$t('popoverInfo.modifier')}}</label>
          <p>{{item.ModifierName||'--'}}</p>
        </el-col>
      </el-row>
    </div>

    <div class="hp" :class="$i18n.locale">
      <div class="hp-lock" v-if="itemId != item.HoldID" v-loading="true">

      </div>
      <div class="hp-block">
        <p class="hp-block-title hp-block-title-center">
          {{item.HoldName}}
        </p>
      </div>
      <div class="hp-horizontal-split"></div>
      <div class="hp-block">
        <el-row>
          <el-col :span="24">
            <label class="hp-block-itemTitle">{{$t('popoverInfo.loginName')}}：</label>
            <p class="hp-block-itemText">{{item.UserName}}</p>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <label class="hp-block-itemTitle">{{$t('popoverInfo.serviceEnddate')}}：</label>
            <p class="hp-block-itemText">{{miment(item.ServiceEndDate).format()}}</p>
          </el-col>
        </el-row>
      </div>
      <div class="hp-horizontal-split"></div>
      <div class="hp-block">
        <el-row>
          <el-col :span="24">
            <label class="hp-block-itemTitle">{{$t('popoverInfo.contacter')}}：</label>
            <p class="hp-block-itemText">{{item.Contacter}}</p>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <label class="hp-block-itemTitle">{{$t('popoverInfo.mobile')}}：</label>
            <p class="hp-block-itemText">{{item.Tel}}</p>
          </el-col>
        </el-row>
      </div>
      <div class="hp-horizontal-split"></div>
      <div class="hp-block">
        <el-row>
          <el-col :span="24">
            <label class="hp-block-itemTitle">{{$t('popoverInfo.remark')}}：</label>
            <p class="hp-block-itemText">{{item.Remark}}</p>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <label class="hp-block-itemTitle">{{$t('popoverInfo.modifier')}}：</label>
            <p class="hp-block-itemText">{{item.ModifierName}}</p>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <label class="hp-block-itemTitle">{{$t('popoverInfo.updateTime')}}：</label>
            <p class="hp-block-itemText">{{miment(item.UpdateTime).format()}}</p>
          </el-col>
        </el-row>
        <!-- <el-row>
          <el-col :span="24">
            <label class="hp-block-itemTitle">{{$t('popoverInfo.enterprise')}}：</label>
            <p class="hp-block-itemText">{{item.FullPathName}}</p>
          </el-col>
        </el-row> -->
      </div>
      <div class="hp-block hp-block-bottom" v-if="false">
        查看详情
      </div>
    </div>
    <slot slot="reference"></slot>
  </el-popover>
</template>

<script>
import * as API from '@/api/hold'
import miment from 'miment'
export default {
  props: {
    itemId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      miment,
      visible: false,
      item: {},
      gap: 24
    }
  },
  methods: {
    fetchData() {
      if (!this.itemId) {
        return
      }

      API.Get(this.itemId||0).then(ret => {
        this.item = Object.assign({}, ret.data.hold)
      })
    }
  },
  watch: {
    itemId() {
      // this.fetchData()
    },
    visible(val) {
      if (val && !this.item.HoldID) {
        this.fetchData()
      } else if (val && this.itemId != this.item.HoldID) {
        this.fetchData()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$borderColor: #DCDFE6;
$textPrimary: #000;
$textSecondary: #909399;
.hp {
  padding: 0 12px;
  //background-color: #F8F8F8;
  //border: 1px solid $borderColor;
  // box-shadow: 0 1px 20px 0 #e4e8eb;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  font-size: 13px;
  position: relative;
  .hp-lock {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #FFF;
    z-index: 1;
  }
  &-block {
    padding: 12px 0;
    &-bottom {
      margin-left: -12px;
      margin-right: -12px;
      border-top: 1px solid $borderColor;
      text-align: center;
    }

    &-title {
      color: $textPrimary;
      font-weight: 700;
      padding-top: 12px;
      &-center {
        text-align: center;
      }
    }
    &-text {

    }

    .el-row {
      padding-left: 12px;
      padding-right: 12px;
      line-height: 32px;
    }
    &-itemTitle {
      float:left;
      width: 5em;
      color: $textPrimary;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-itemText {
      margin-left: 5em;
      color: $textSecondary;
      text-align: left;
    }
  }
  &.en &-block {
    &-itemTitle {
      text-align: right;
      width: 7em;
    }
    &-itemText {
      margin-left: 7em;
    }
  }
  &-horizontal-split {
    height: 1px;
    margin: 12px 0;
    background-color: $borderColor;
  }
}

.content {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.cp {
  padding: 12px 16px;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-top: 24px solid #1890FF;
  border-radius: 8px;
  .el-row {
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 8px;
    }
    label {
      font-size: 12px;
      color: #999;
      display: inline-block;
      margin-bottom: 4px;
      margin-left: 2px;
    }
    p {
      font-size: 14px;
      background-color: #f4f4fa;
      padding: 4px 8px;
      font-size: 12px;
      color: #333;
    }
  }
}
</style>
