<template>
  <div>
    <el-dialog :visible.sync="visible" v-if="visibleReal" :title="$t('userDetail.name')" custom-class="v-dialog v-dialog--footer dialogUser" @closed="closed"
      :close-on-click-modal="false" :append-to-body="appendToBody">
      <div class="dl-panel">

        <div class="dl-panel__chunk">
            <el-row :gutter="gap">
            <el-col :span="13">
                <div class="chunk-title">
                    <i-icon class="chunk-title__icon" name="icondankuang-zhanghu1"></i-icon>
                    <span>{{$t('userDetail.basicInfo')}}</span>
                    </div>
                  <div class="chunk-content">
                    <el-form ref="form" v-loading="loading" :rules="rules" :model="item" :label-width="$i18n.locale==='zh'? '6em' : '10em'" label-position="left">
                        <el-form-item :label="$t('userDetail.holdName')">{{id ? other.holdInfo.Name : currentHoldName}}</el-form-item>
                        <el-form-item :label="$t('userDetail.userName')" prop="UserName"><el-input v-model.trim="item.UserName" :disabled="!!id" :placeholder="$t('userDetail.pleaseInputUserName')" maxlength="20" size="small"></el-input></el-form-item>
                        <el-form-item :label="$t('userDetail.startTime')" prop="StartTime"><el-date-picker type="datetime" v-model.trim="item.StartTime" :disabled="isSelf" :placeholder="$t('userDetail.pleaseSelctStartTime')" size="small" style="width:100%;"></el-date-picker></el-form-item>
                        <el-form-item :label="$t('holdDetail.email')" prop="Email"><el-input v-model.trim="item.Email" maxlength="32" size="small"></el-input></el-form-item>
            
                        <el-form-item :label="$t('userDetail.realName')" prop="RealName"><el-input v-model.trim="item.RealName" :placeholder="$t('holdDetail.holdName')" maxlength="25" size="small"></el-input></el-form-item>
                        <el-form-item :label="$t('userDetail.password')" prop="UserPassword"><el-input v-model.trim="item.UserPassword" :placeholder="$t('userDetail.pleaseInputPassword')" maxlength="20" size="small"></el-input></el-form-item>
                        <el-form-item :label="$t('userDetail.endTime')" prop="EndTime"><el-date-picker type="datetime" v-model.trim="item.EndTime" :disabled="isSelf" :placeholder="$t('userDetail.pleaseSelectEndTime')" style="width:100%;" size="small"></el-date-picker></el-form-item>
                        <el-form-item :label="$t('userDetail.mobile')" prop="Mobile" v-if="id && $i18n.locale==='zh'">
                          {{item.Mobile || '--'}} <el-button @click="changeMobile" type="text" class="iml-0" v-if="loginInfo.allFuns.includes(5032) && loginInfo.UserID != item.UserID">{{$t('common.modify')}}</el-button>
                        </el-form-item>
                        <el-form-item :label="$t('userDetail.remark')"><el-input v-model.trim="item.Remark" maxlength="126" size="small"></el-input></el-form-item>
                    </el-form>
                  </div>
              </el-col>
            <el-col :span="11">
              <div class="chunk-title">
                <i-icon class="chunk-title__icon" name="icondankuang-quanxian1"></i-icon>
                <span>{{$t('userDetail.autoInfo')}}</span>
              </div>
              <div class="chunk-content">
                <div class="floatButton" v-if="!isSelf">
                  <el-button type="text" @click="checkAll">{{$t('userDetail.checkAll')}}</el-button>
                  <el-button type="text" @click="cancelAll">{{$t('userDetail.cancelAll')}}</el-button>
                </div>
                <el-scrollbar ref="elScroll" class="funMenu">
                  <el-tree
                    :check-strictly="true"
                    ref="tree"
                    :data="other.fun"
                    show-checkbox
                    node-key="id"
                    :default-checked-keys="other.hasFun"
                    :props="other.defaultProps"
                    @check="checkHandler">
                    <span class="custom-tree-node" slot-scope="{ node, data }">
                      <span>{{ $t('funMenu')[node.label] || node.label }}{{ isDev ? '(' + data.id + ')' : ''}}</span>
                    </span>
                  </el-tree>
                </el-scrollbar>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div slot="footer">
        <el-button @click="cancel" class="customStyle" type="text" size="medium">{{$t('common.cancel')}}</el-button>
        <el-button @click="save" :loading="btnSaveLoading" class="customStyle" v-if="loginInfo.allFuns.includes(5032)" type="primary" plain size="medium" v-ripple>{{$t('common.save')}}</el-button>
      </div>
    </el-dialog>
    <dialog-change-mobile ref="dialogChangeMobile" @update="val => item.Mobile = val"/>
  </div>
</template>

<script>
import mixin from './mixin.js'
import * as API from '@/api/user'
import {regions} from '@/common/dictionary'
import DialogChangeMobile from '@/views/info/dialog-change-mobile'
export default {
  props: {
    appendToBody: {
      type: Boolean,
      default: false
    }
  },
  components: {
    DialogChangeMobile
  },
  data() {
    return {
      tabName: 'info',
      item: {

      },
      rules: {
        UserName: [ {required: true,message:this.$t('userDetail.pleaseInputUserName'),trigger: 'blur'}, { min: 2, message: this.$t('userDetail.pleaseInputLongUserName'),trigger: 'blur' } ],
        UserPassword: [ {required: true,message:this.$t('userDetail.pleaseInputPassword'),trigger: 'blur'} ],
        RealName: [ {required: true,message:this.$t('userDetail.pleaseInputName'),trigger: 'blur'} ],
        StartTime: [ {required: true,message:this.$t('userDetail.pleaseSelctStartTime'),trigger: 'blur'} ],
        EndTime: [ {required: true,message:this.$t('userDetail.pleaseSelectEndTime'),trigger: 'blur'} ]
      },
      regions,
      other: {
        holdInfo: {},
        fun: [],
        hasFun: [],
        defaultProps: {
          id: 'id',
          children: 'children',
          label: 'label',
          disabled() {
            return false
          }
        }
      },
      isDev: process.env.NODE_ENV === 'development'
    }
  },
  computed: {
    isSelf() {
      return this.id == this.loginInfo.UserID
    }
  },
  created() {
    this.other.defaultProps.disabled = () => {
      return this.isSelf
    }
  },
  methods: {
    fetchItem() {
      let limit = ['风控管理','工单系统','保单管理']
      const promise1 = new Promise(resolve => {
        API.Get(this.id||0).then(ret => {
          this.item = Object.assign({}, ret.data.user)
          if (this.id) {
            let temp = ret.data.fun.map(item => !limit.includes(item.label)?item:null).filter(num => num)
            this.other.fun = temp
            this.other.hasFun = ret.data.hasFun
            this.other.holdInfo = ret.data.holdInfo
          }
          resolve(ret)
        })
      })

      let promise2 = new Promise(resolve => resolve())
      if (!this.id) {
        promise2 = new Promise(resolve => {
          API.GetFunTree(this.currentHoldId, 1).then(ret => {
            let temp = ret.data.fun.map(item => !limit.includes(item.label)?item:null).filter(num => num)
            this.other.fun = temp
            this.other.hasFun = ret.data.hasFun
            resolve(ret)
          })
        })
      }

      return Promise.all([promise1, promise2])
    },

    addItem() {
      this.item.HoldID = this.currentHoldId
      return new Promise(resolve => {
        API.Add(this.item, this.$refs.tree.getCheckedKeys()).then(ret => resolve(ret))
      })
    },

    modifyItem() {
      return new Promise(resolve => {
        API.Modify(this.item, this.$refs.tree.getCheckedKeys()).then(ret => resolve(ret))
      })
    },

    validForms() {
      return this.$refs.form.validate()
    },

    resetForms() {
      this.item = {}
      this.tabName = 'info'
      this.$refs.form.resetFields()
    },

    checkHandler(node, {checkedKeys, checkedNodes}) {

      // 判断节点是否被选中
      const isChecked = checkedNodes.includes(node)

      // 获取所有子节点
      const allChildrenKeys = []
      const getChildrenKeys = (node) => {
        if (!node.children) {
          return allChildrenKeys
        } else {
          node.children.forEach(j => {
            allChildrenKeys.push(j.id)// 添加子节点
            getChildrenKeys(j)
          })
        }
      }
      getChildrenKeys(node)

      // checkedKeys中移除或添加后，设置选中节点
      if (isChecked) {
        allChildrenKeys.forEach(k => {
          if (!checkedKeys.includes(k)) {
            checkedKeys.push(k)
          }
        })
      } else {
        allChildrenKeys.forEach(k => {
          if (checkedKeys.includes(k)) {
            const index = checkedKeys.indexOf(k)
            checkedKeys.splice(index, 1)
          }
        })
      }

      this.$refs.tree.setCheckedKeys(checkedKeys)
    },

    checkAll() {
      let allFun = []
      const addFun = k => {
        if (k.children && k.children.length) {
          k.children.forEach(addFun)
        }
        allFun.push(k.id)
      }

      this.other.fun.forEach(addFun)
      this.$refs.tree.setCheckedKeys(allFun)
    },

    cancelAll() {
      this.$refs.tree.setCheckedKeys([])
    },

    changeMobile() {
      this.$refs.dialogChangeMobile.openByUserID(this.item.UserID)
    }
  },
  mixins: [mixin]
}
</script>

<style lang="scss" scoped>
.el-tree ::v-deep {
  & > .el-tree-node {
    min-width: 100%;
    display: inline-block !important;
  }
}
.el-tabs ::v-deep .el-tabs__content {
  min-height: 374px;
}

.floatButton {
  .el-button{
    color: #E99C24;
  }
}
.chunk-content{
    & ::v-deep .el-form--label-top{
      .el-form-item__label{
        padding: 0;
      }
    }
  }
</style>
