<template>
  <router-view></router-view>
</template>

<script>
  // eslint-disable-next-line
  import {
    remoteLoad
  } from '@/common/utils'
  import {
    dragElementDialog
  } from '@/common/utils'
  import {
    ConfigHMT
  } from '@/api/common'
  export default {
    name: 'app',
    async created() {
      //await remoteLoad('//unpkg.com/vuetify/dist/vuetify.min.css')
      await remoteLoad('//at.alicdn.com/t/font_604478_mb2kb029xq63l3di.js') // login
      await remoteLoad('//at.alicdn.com/t/font_731407_3beybsrte3m.js') // crm
      await remoteLoad('//at.alicdn.com/t/font_793763_dc9e11uh0m4.js') // cook
      // await remoteLoad('//at.alicdn.com/t/font_2026394_m2c27np10dh.js')
      // await remoteLoad('//at.alicdn.com/t/font_2026394_2hhmdx1bdcy.js')
      // await remoteLoad('//at.alicdn.com/t/font_2026394_m3cb7u7dsz.js')
      // await remoteLoad('//at.alicdn.com/t/font_2026394_xrhugrbu609.js')
      // await remoteLoad('//at.alicdn.com/t/font_2026394_scg5oedb1ec.js')
      await remoteLoad('//at.alicdn.com/t/font_2026394_scg5oedb1ec.js')
      await remoteLoad('//at.alicdn.com/t/font_2514986_w11czgwhjx.js')
      //at.alicdn.com/t/font_2514986_ob5chqyd8am.js
    },
    watch: {
      $route(val) {
        window._hmt && window._hmt.push(['_trackPageview', val.path])
      }
    },
    methods: {
      // 百度统计
      initHmt() {
        ConfigHMT().then(ret => {
          if (ret.data.hmt) {
            remoteLoad(ret.data.hmt)
          }
        }).catch(() => {})
      }
    },
    mounted() {
      dragElementDialog(window)
      this.initHmt()
    }
  }

</script>

<style>
  .tagRed {
    position: relative;
  }

  .tagRed::before {
    content: '';
    width: 4px;
    height: 4px;
    background-color: #ff4500;
    overflow: hidden;
    border-radius: 2px;
    position: absolute;
    left: 50%;
    margin-left: -2px;
    top: 90%;
    z-index: 1;
  }

  li {
    list-style: none;
  }

  .infoBox img {
    display: none !important;
  }

  .glmap .amap-info-contentContainer {
    display: block;
    width: 460px !important;
  }

  .glmap .bottom-center {
    width: 320px !important;
  }

  /* .gmnoprint{
  display: none;
} */

</style>
