import request from '@/common/request'
import { commonParams } from '@/api/config'
import { getToken } from '@/common/auth'

export function Config() {
  const loginKey = getToken()
  return request({
    url: './query/Config',
    method: 'post',
    timeout: 5000,
    data: {
      loginKey: loginKey || ''
    }
  })
}

export function GetFunTree(UserID) {
  const loginKey = getToken()
  return request({
    url: './query/QueryFunTree',
    method: 'post',
    data: {
      loginKey,
      UserID
    }
  })
}

export function GetFunList(UserID) {
  const loginKey = getToken()
  return request({
    url: './query/QueryFunList',
    method: 'post',
    data: {
      loginKey,
      UserID
    }
  })
}

export function GetFunNameList(UserID) {
  const loginKey = getToken()
  return request({
    url: './query/QueryFunNameList',
    method: 'post',
    data: {
      loginKey,
      UserID
    }
  })
}

export function GetEnterpriList(EID) {
  const loginKey = getToken()
  return request({
    url: './query/QueryEnterpriList',
    method: 'post',
    data: {
      loginKey,
      EID
    }
  })
}

export function QueryRootPath(rootHoldId, WithVehicle, MdtTypeID=null, showCount) {
  const loginKey = getToken()
  return request({
    url: './query/QueryRootPath',
    method: 'post',
    data: {
      loginKey,
      rootHoldId,
      WithVehicle,
      MdtTypeID,
      showCount
    }
  })
}

// 获取层级关系
// loginKey
// holdId
// WithVehicle
export function QueryTreePath(holdId, WithVehicle, MdtTypeID=null, showCount) {
  const loginKey = getToken()
  return request({
    url: './query/QueryTreePath',
    method: 'post',
    data: {
      loginKey,
      holdId,
      WithVehicle,
      MdtTypeID,
      showCount
    }
  })
}

// export function QueryDevicePathByVehicleID(VehicleID, MdtTypeID = null) {
//   const loginKey = getToken()
//   return request({
//     url: './query/QueryDevicePathByVehicleID',
//     method: 'post',
//     data: {
//       loginKey,
//       VehicleID,
//       MdtTypeID
//     }
//   })
// }


export function QueryDevicePathByObjectID(ObjectID, MdtTypeID = null) {
  const loginKey = getToken()
  return request({
    url: './query/QueryDevicePathByObjectID',
    method: 'post',
    data: {
      loginKey,
      ObjectID,
      MdtTypeID
    }
  })
}

export function QueryTreeComprehenList(queryKey = '', HoldID) {
  const loginKey = getToken()
  return request({
    url: './query/QueryTreeComprehenList',
    method: 'post',
    data: {
      loginKey,
      queryKey,
      HoldID
    }
  })
}

export function GetObjectIdByMixID(mixIds, MdtTypeID = null) {
  const loginKey = getToken()
  return request({
    url: './query/GetObjectIdByMixID',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      mixIds,
      MdtTypeID
    })
  })
}

export function GetObjectIdByMixIDLimit(mixIds, MdtTypeID = null, limit) {
  const loginKey = getToken()
  return request({
    url: './query/GetObjectIdByMixIDLimit',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      mixIds,
      MdtTypeID,
      limit
    })
  })
}

export function GetVehicleIdByMixID(mixIds) {
  const loginKey = getToken()
  return request({
    url: './query/GetVehicleIdByMixID',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      mixIds
    })
  })
}

export function GetVehicleInfoIdByMixID(mixIds) {
  const loginKey = getToken()
  return request({
    url: './query/GetVehicleInfoIdByMixID',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      mixIds
    })
  })
}

export function GetObjectIdAndVehicleIDByMixID(mixIds) {
  const loginKey = getToken()
  return request({
    url: './query/GetObjectIdAndVehicleIDByMixID',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      mixIds
    })
  })
}

export function QueryVehiclePure(pageIndex, pageSize, sortKey, sortDirect, filters, holdIDs, typeID) {
  const loginKey = getToken()
  return request({
    url: './query/QueryVehicleList',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      pageIndex,
      pageSize,
      sortKey,
      sortDirect,
      filters,
      holdIDs,
      typeID
    })
  })
}

// 18.	查询综合列表（人、车、物） - -2021.04.27
/**
 * "pageIndex":1,//页码
 *  pageSize":100,//每页数量，注意这里会请求人、车、物三个表的数据，即数据总和=（人+车+物）
 *  sortKey":"",  //关键字
 * sortDirect":"",  //排序：   ASC（顺序）   DESC（倒序）
 * filters":[],    //查询条件
 * holdIDs":[1],   //用户ID
 * typeID":4,    //0全部，1在线，2离线，3报警，4未用，5关注
 * isShowCircuit":false  
 * @returns {wx.RequestTask | never}
 */

 export function  QueryComprehenList(pageIndex, pageSize, sortKey, sortDirect, filters, holdIDs, typeID, isShowCircuit, OTypeID) {
  const loginKey = getToken()
  return request({
    url: './query/QueryComprehenList',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      pageIndex,
      pageSize,
      sortKey,
      sortDirect,
      filters,
      holdIDs,
      typeID,
      isShowCircuit,
      OTypeID
    })
  })
}

export function QueryVehiclePure2(pageIndex, pageSize, sortKey, sortDirect, filters, holdIDs, typeID, isShowCircuit) {
  const loginKey = getToken()
  return request({
    url: './query/QueryVehicleList2',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      pageIndex,
      pageSize,
      sortKey,
      sortDirect,
      filters,
      holdIDs,
      typeID,
      isShowCircuit
    })
  })
}

export function QueryComprehenListByObjectIDs(objectList) {
  const loginKey = getToken()
  return request({
    url: './query/QueryComprehenListByObjectIDs',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      objectList
    })
  })
}

export function QueryVehicleListByObjectIDs(objectList) {
  const loginKey = getToken()
  return request({
    url: './query/QueryVehicleListByObjectIDs',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      objectList
    })
  })
}

export function QueryVehicleSummarCount() {
  const loginKey = getToken()
  return request({
    url: './query/QueryVehicleSummarCount',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey
    })
  })
}

export function QueryBrand(pageIndex, pageSize, sortKey, sortDirect, filters) {
  const loginKey = getToken()
  return request({
    url: './query/QueryBrand',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      pageIndex,
      pageSize,
      sortKey,
      sortDirect,
      filters
    })
  })
}

export function QueryBrandGroup(pageIndex, pageSize, sortKey, sortDirect, filters) {
  const loginKey = getToken()
  return request({
    url: './query/QueryBrandGroup',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      pageIndex,
      pageSize,
      sortKey,
      sortDirect,
      filters
    })
  })
}

export function QueryCarType(pageIndex, pageSize, sortKey, sortDirect, filters) {
  const loginKey = getToken()
  return request({
    url: './query/QueryCarType',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      pageIndex,
      pageSize,
      sortKey,
      sortDirect,
      filters
    })
  })
}

export function QueryDict(pageIndex, pageSize, sortKey, sortDirect, filters, DictID) {
  const loginKey = getToken()
  return request({
    url: './query/QueryDict',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      pageIndex,
      pageSize,
      sortKey,
      sortDirect,
      filters,
      DictID
    })
  })
}

export function QueryDicts(pageIndex, pageSize, sortKey, sortDirect, filters, DictIDs) {
  const loginKey = getToken()
  return request({
    url: './query/QueryDicts',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      pageIndex,
      pageSize,
      sortKey,
      sortDirect,
      filters,
      DictIDs
    })
  })
}

export function QueryDeviceOnlineData(ObjectID, VehicleID) {
  const loginKey = getToken()
  return request({
    url: './query/QueryDeviceOnlineData',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      ObjectID,
      VehicleID
    })
  })
}

export function QueryDeviceOnlineDataByObjectIDs(ObjectIDs) {
  const loginKey = getToken()
  return request({
    url: './query/QueryDeviceOnlineDataByObjectIDs',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      ObjectIDs
    })
  })
}

export function QueryTrackData(ObjectID, startTime, endTime) {
  const loginKey = getToken()
  return request({
    url: './query/QueryTrackData',
    method: 'post',
    timeout: 60000,
    data: Object.assign({}, commonParams, {
      loginKey,
      ObjectID,
      startTime,
      endTime
    })
  })
}

export function QueryTrackDataFilter(ObjectID, startTime, endTime, hideZero, onlyGPS) {
  const loginKey = getToken()
  return request({
    url: './query/QueryTrackDataFilter',
    method: 'post',
    timeout: 60000,
    data: Object.assign({}, commonParams, {
      loginKey,
      ObjectID,
      startTime,
      endTime,
      hideZero,
      onlyGPS
    })
  })
}

export function QueryHaveTrackDay(ObjectID, startTime, endTime) {
  const loginKey = getToken()
  return request({
    url: './query/QueryHaveTrackDay',
    method: 'post',
    timeout: 60000,
    data: Object.assign({}, commonParams, {
      loginKey,
      ObjectID,
      startTime,
      endTime
    })
  })
}

export function QueryTrackDataRealTime(ObjectID) {
  const loginKey = getToken()
  return request({
    url: './query/QueryTrackDataRealTime',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      ObjectID
    })
  })
}
export function GetUBIDataByObjectId(ObjectID, startTime, endTime,) {
  const loginKey = getToken()
  return request({
    url: './query/GetUBIDataByObjectId',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      ObjectID,
      startTime,
      endTime,
    })
  })
}

export function QueryDataByLonlatAndTime(minLon, minLat, maxLon, maxLat, startTime, endTime, speed1, speed2) {
  const loginKey = getToken()
  return request({
    url: './query/QueryDataByLonlatAndTime',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      minLon, minLat, maxLon, maxLat, startTime, endTime, speed1, speed2
    })
  })
}

export function GetShcm(Province) {
  const loginKey = getToken()
  return request({
    url: './query/GetShcm',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      Province
    })
  })
}

export function GetShcmByLngLat(minLng, minLat, maxLng, maxLat, type = 0) {
  const loginKey = getToken()
  return request({
    url: './query/GetShcmByLngLat',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      minLng,
      minLat,
      maxLng,
      maxLat,
      type
    })
  })
}

export function GetRegionByLngLat(minLng, minLat, maxLng, maxLat) {
  const loginKey = getToken()
  return request({
    url: './query/GetRegionByLngLat',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      minLng,
      minLat,
      maxLng,
      maxLat
    })
  })
}

export function GetRegionCircleList(pageIndex, pageSize, hasChild, HoldID) {
  const loginKey = getToken()
  return request({
    url: './query/GetRegionCircleList',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      pageIndex, 
      pageSize, 
      hasChild,
      HoldID
    })
  })
}


export function GetRegionByObjectID(objectId) {
  const loginKey = getToken()
  return request({
    url: './query/GetRegionByObjectID',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      objectId
    })
  })
}

export function AddStar(VehicleID,TypeID) {
  const loginKey = getToken()
  return request({
    url: './add/AddStar',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      VehicleID,
      TypeID
    })
  })
}

export function DeleteStar(VehicleID,TypeID) {
  const loginKey = getToken()
  return request({
    url: './delete/DeleteStar',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      VehicleID,
      TypeID
    })
  })
}

export function DeleteAllStar() {
  const loginKey = getToken()
  return request({
    url: './delete/DeleteAllStar',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey
    })
  })
}

export function GetRiskType() {
  const loginKey = getToken()
  return request({
    url: './query/GetRiskType',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey
    })
  })
}

export function ConvertFrom(list, from='',to='') {
  return request({
    url: './query/convertFrom',
    method: 'post',
    data: Object.assign({}, commonParams, {
      list, from, to
    })
  })
}

export function ConvertFromGpsIgnoreMap(list) {
  return request({
    url: './query/ConvertFromGpsIgnoreMap',
    method: 'post',
    data: Object.assign({}, commonParams, {
      list
    })
  })
}

export function FindCarByRect(minLng, minLat, maxLng, maxLat, limit=200) {
  const loginKey = getToken()
  return request({
    url: './query/FindCarByRect',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      minLng,
      minLat,
      maxLng,
      maxLat,
      limit
    })
  })
}

export function GetAddress(lon, lat, type = 2) {
  const mode = 2  //1=从本地取中文地址  2=从网络取地址
  return request({
    url: './query/GetAddress',
    method: 'post',
    data: Object.assign({}, commonParams, {
      lon,
      lat,
      mode,
      type
    })
  })
}

export function GetRiskPlace(key) {
  const loginKey = getToken()
  return request({
    url: 'query/GetRiskPlace',
    method: 'POST',
    data: Object.assign({}, commonParams, {
      loginKey,
      key
    })
  })
}

export function DeviceSearch(queryKey) {
  const loginKey = getToken()
  return request({
    url: 'query/DeviceSearch',
    method: 'POST',
    data: Object.assign({}, commonParams, {
      loginKey,
      queryKey
    })
  })
}

export function SearchVehicleInfo(queryKey) {
  const loginKey = getToken()
  return request({
    url: 'query/SearchVehicleInfo',
    method: 'POST',
    data: Object.assign({}, commonParams, {
      loginKey,
      queryKey
    })
  })
}

export function GetEryaDian(pageIndex, pageSize, key, type) {
  const loginKey = getToken()
  return request({
    url: 'query/GetEryaDian',
    method: 'POST',
    data: Object.assign({}, commonParams, {
      loginKey,
      pageIndex,
      pageSize,
      key,
      type
    })
  })
}

export function GetEryaDianPage(pageIndex, pageSize, key, type) {
  const loginKey = getToken()
  return request({
    url: 'query/GetEryaDianPage',
    method: 'POST',
    data: Object.assign({}, commonParams, {
      loginKey,
      pageIndex,
      pageSize,
      key,
      type
    })
  })
}

export function GetPOI(key, all=false) {
  const loginKey = getToken()
  return request({
    url: 'query/GetPOI',
    method: 'POST',
    data: Object.assign({}, commonParams, {
      loginKey,
      key,
      all
    })
  })
}

export function DeletePoi(IDs) {
  const loginKey = getToken()
  return request({
    url: 'delete/DeletePoi',
    method: 'POST',
    data: Object.assign({}, commonParams, {
      loginKey,
      IDs
    })
  })
}

export function AddPoi(poi) {
  const loginKey = getToken()
  return request({
    url: 'add/AddPoi',
    method: 'POST',
    data: Object.assign({}, commonParams, {
      loginKey,
      poi
    })
  })
}

export function DeleteShcm(IDs) {
  const loginKey = getToken()
  return request({
    url: 'delete/DeleteShcm',
    method: 'POST',
    data: Object.assign({}, commonParams, {
      loginKey,
      IDs
    })
  })
}

export function AddSHCM(poi) {
  const loginKey = getToken()
  return request({
    url: 'add/AddSHCM',
    method: 'POST',
    data: Object.assign({}, commonParams, {
      loginKey,
      poi
    })
  })
}

export function GetWIFIAndLBSInfoByObjectID(objectId) {
  const loginKey = getToken()
  let _commonParams =  Object.assign({current_map: 'Amap'})
  return request({
    url: 'query/GetWIFIAndLBSInfoByObjectIDGD',
    method: 'POST',
    data: Object.assign({}, _commonParams, {
      loginKey,
      objectId
    })
  })
}

// export function GetWIFIAndLBSInfoByObjectIDGD(objectId) {
//   const loginKey = getToken()
//   return request({
//     url: 'query/GetWIFIAndLBSInfoByObjectIDGD',
//     method: 'POST',
//     data: Object.assign({}, commonParams, {
//       loginKey,
//       objectId
//     })
//   })
// }

export function QueryDetailRisks(id) {
  const loginKey = getToken()
  return request({
    url: 'query/QueryDetailRisks',
    method: 'POST',
    data: Object.assign({}, commonParams, {
      loginKey,
      id
    })
  })
}

export function QueryTaskDataByObjectIDs(ObjectIDs) {
  const loginKey = getToken()
  return request({
    url: 'query/QueryTaskDataByObjectIDs',
    method: 'POST',
    data: Object.assign({}, commonParams, {
      loginKey,
      ObjectIDs
    })
  })
}
export function QueryFocusDeviceList() {
  const loginKey = getToken()
  return request({
    url: 'query/QueryFocusDeviceList',
    method: 'POST',
    data: Object.assign({}, commonParams, {
      loginKey
    })
  })
}

export function ExportTaskDataByObjectIDs(ObjectIDs) {
  const loginKey = getToken()
  return request({
    url: 'query/ExportTaskDataByObjectIDs',
    method: 'POST',
    responseType: 'blob',
    data: Object.assign({}, commonParams, {
      loginKey,
      ObjectIDs
    })
  })
}

export function ExportFocusDeviceList(ObjectIDs) {
  const loginKey = getToken()
  return request({
    url: 'query/ExportFocusDeviceList',
    method: 'POST',
    responseType: 'blob',
    data: Object.assign({}, commonParams, {
      loginKey,
      ObjectIDs
    })
  })
}

export function GetSimUrl(sim) {
  const loginKey = getToken()
  return request({
    url: 'query/GetSimUrl',
    method: 'POST',
    data: Object.assign({}, commonParams, {
      loginKey,
      sim
    })
  })
}

export function GetSimInfoUrl(sim) {
  const loginKey = getToken()
  return request({
    url: 'query/GetSimInfoUrl',
    method: 'POST',
    data: Object.assign({}, commonParams, {
      loginKey,
      sim
    })
  })
}

export function AdvanceSearch(queryKey, cache) {
  const loginKey = getToken()
  return request({
    url: 'query/AdvanceSearch',
    method: 'POST',
    data: Object.assign({}, commonParams, {
      loginKey,
      queryKey,
      cache
    })
  })
}

export function AdvanceSearch2(queryKey, cache) {
  const loginKey = getToken()
  return request({
    url: 'query/AdvanceSearch2',
    method: 'POST',
    data: Object.assign({}, commonParams, {
      loginKey,
      queryKey,
      cache
    })
  })
}

export function AdvanceSearchFK(queryKey, cache) {
  const loginKey = getToken()
  return request({
    url: 'query/AdvanceSearchFK',
    method: 'POST',
    data: Object.assign({}, commonParams, {
      loginKey,
      queryKey,
      cache
    })
  })
}

export function QueryAlarms() {
  const loginKey = getToken()
  return request({
    url: './query/QueryAlarms',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey
    })
  })
}

export function ImportSHCM(fileName) {
  const loginKey = getToken()
  return request({
    url: './add/ImportSHCM',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      fileName
    })
  })
}

export function QueryObjectIDByDeviceID(deviceId) {
  const loginKey = getToken()
  return request({
    url: './query/QueryObjectIDByDeviceID',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      deviceId
    })
  })
}

export function QueryObjectIDByVehicleName(name) {
  const loginKey = getToken()
  return request({
    url: './query/QueryObjectIDByVehicleName',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      name
    })
  })
}

export function QueryFullPath(holdId) {
  const loginKey = getToken()
  return request({
    url: './query/QueryFullPath',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      holdId
    })
  })
}

export function GetObjectIdByVehicleID(VehicleID) {
  const loginKey = getToken()
  return request({
    url: './query/GetObjectIdByVehicleID',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      VehicleID
    })
  })
}

export function GetAllFocusVehicleID() {
  const loginKey = getToken()
  return request({
    url: './query/GetAllFocusVehicleID',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey
    })
  })
}

export function GetObjectIdsByVehicleIDs(VehicleID) {
  const loginKey = getToken()
  return request({
    url: './query/GetObjectIdsByVehicleIDs',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      VehicleID
    })
  })
}

export function GetGeoCode(address, city) {
  const loginKey = getToken()
  return request({
    url: './query/GetGeoCode',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      address,
      city
    })
  })
}

export function ExportTrackDataFilter(ObjectID, startTime, endTime, hideZero, onlyGPS, location) {
  const loginKey = getToken()
  return request({
    url: './query/ExportTrackDataFilter',
    method: 'post',
    timeout: 120000,
    responseType: 'blob',
    data: Object.assign({}, commonParams, {
      loginKey,
      ObjectID,
      startTime,
      endTime,
      hideZero,
      onlyGPS,
      location
    })
  })
}

export function ExportKMLTrackDataFilter(ObjectID, startTime, endTime, hideZero, onlyGPS, location) {
  const loginKey = getToken()
  return request({
    url: './query/ExportKMLTrackDataFilter',
    method: 'post',
    timeout: 120000,
    responseType: 'blob',
    data: Object.assign({}, commonParams, {
      loginKey,
      ObjectID,
      startTime,
      endTime,
      hideZero,
      onlyGPS,
      location
    })
  })
}

export function ConfigHMT() {
  return request({
    url: './query/ConfigHMT',
    method: 'post',
    timeout: 5000,
    data: {
    }
  })
}

export function ModifySF(objectId, sf) {
  const loginKey = getToken()
  return request({
    url: './modify/ModifySF',
    method: 'post',
    timeout: 5000,
    data: Object.assign({}, commonParams, {
      loginKey,
      objectId,
      sf
    })
  })
}


export function GetAddressList(list) {
  const loginKey = getToken()
  return request({
    url: './query/GetAddressList',
    method: 'post',
    timeout: 5000,
    data: Object.assign({}, commonParams, {
      loginKey,
      list
    })
  })
}


export function GetVehicleTyreList(ObjectID) {
  const loginKey = getToken()
  return request({
    url: './query/GetVehicleTyreList',
    method: 'post',
    timeout: 5000,
    data: Object.assign({}, commonParams, {
      loginKey,
      ObjectID
    })
  })
}
export function GetVehicleTyreDetails(ObjectID,StartTime,EndTime) {
  const loginKey = getToken()
  return request({
    url: './query/GetVehicleTyreDetails',
    method: 'post',
    timeout: 5000,
    data: Object.assign({}, commonParams, {
      loginKey,
      ObjectID,
      StartTime,
      EndTime
    })
  })
}

export function SetTyreInfo(ObjectID,tyreList,setItem) {
  const loginKey = getToken()
  return request({
    url: './add/Service1.asmx/SetTyreInfo',
    method: 'post',
    timeout: 5000,
    data: Object.assign({}, commonParams, {
      loginKey,
      ObjectID,
      tyreList,
      setItem
    })
  })
}
export function GetBindTyreList(ObjectID) {
  const loginKey = getToken()
  return request({
    url: './query/Service1.asmx/GetBindTyreList',
    method: 'post',
    timeout: 5000,
    data: Object.assign({}, commonParams, {
      loginKey,
      ObjectID
    })
  })
}

export function GetKml(fileName) {
  const loginKey = getToken()
  return request({
    url: './query/GetKml',
    method: 'post',
    timeout: 5000,
    data: Object.assign({}, commonParams, {
      loginKey,
      fileName
    })
  })
}

export function ModifyRoadActivate(RoadID,isActivate) {
  const loginKey = getToken()
  return request({
    url: './modify/ModifyRoadActivate',
    method: 'post',
    timeout: 5000,
    data: Object.assign({}, commonParams, {
      loginKey,
      RoadID,
      isActivate
    })
  })
}

export function GetRegionListByObjectID( containChild,queryKey,ObjectID,pageIndex,pageSize,HoldID) {
  const loginKey = getToken()
  return request({
    url: './query/GetRegionListByObjectID',
    method: 'post',
    timeout: 5000,
    data: Object.assign({}, commonParams, {
      loginKey,
      containChild,
      queryKey,
      ObjectID,
      pageIndex,
      pageSize,
      HoldID
    })
  })
}

export function GetRegionCircleListByObjectID( hasChild,queryKey,ObjectID,pageIndex,pageSize,HoldID) {
  const loginKey = getToken()
  return request({
    url: './query/GetRegionCircleListByObjectID',
    method: 'post',
    timeout: 5000,
    data: Object.assign({}, commonParams, {
      loginKey,
      hasChild,
      queryKey,
      ObjectID,
      pageIndex,
      pageSize,
      HoldID
    })
  })
}

export function GetRoadList(containChild, queryKey,pageIndex, pageSize) {
  const loginKey = getToken()
  return request({
    url: './query/GetRoadList',
    method: 'POST',
    data: Object.assign({}, commonParams, {
      loginKey,
      containChild,
      queryKey,
      pageIndex,
      pageSize
    })
  })
}

export function GetRoadInfo(PolygonID) {
  const loginKey = getToken()
  return request({
    url: './query/GetRoadInfo',
    method: 'POST',
    data: Object.assign({}, commonParams, {
      loginKey,
      PolygonID
    })
  })
}

export function GetRealtimeSpeed() {
  const loginKey = getToken()
  return request({
    url: './query/GetRealtimeSpeed',
    method: 'post',
    timeout: 5000,
    data: Object.assign({}, commonParams, {
      loginKey,
    })
  })
}

export function GetRegionCircleListByRoadId(roadId) {
  const loginKey = getToken()
  return request({
    url: './query/GetRegionCircleListByRoadId',
    method: 'POST',
    data: Object.assign({}, commonParams, {
      loginKey,
      roadId
    })
  })
}

export function GetRaceEnd() {
  const loginKey = getToken()
  return request({
    url: './query/GetRaceEnd',
    method: 'post',
    timeout: 5000,
    data: Object.assign({}, commonParams, {
      loginKey,
    })
  })
}

export function DeleteCenterCircle(idArray) {
  const loginKey = getToken()
  return request({
    url: './delete/DeleteCenterCircle',
    method: 'post',
    timeout: 5000,
    data: Object.assign({}, commonParams, {
      loginKey,
      idArray
    })
  })
}




