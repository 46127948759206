<template>
  <el-tooltip :popper-class="type" effect="dark" :content="content"
    :offset="offset"
    transition="tooltip-top"
    :placement="placement" :enterable="enterable" :visible-arrow="visibleArrow" :hide-after="hideAfter"
    :open-delay="openDealy"
    :disabled="disabled">
    <slot></slot>
  </el-tooltip>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placement: {
      type: String,
      default: 'top'
    },
    type: {
      type: String,
      default: 'primary'
    },
    transition: {
      type: String,
      default: 'tooltip-top'
    },
    enterable: {
      type: Boolean,
      default: false
    },
    hideAfter: {
      type: Number,
      default: 0
    },
    openDealy: {
      type: Number,
      default: 0
    },
    offset: {
      type: Number,
      default: 0
    },
    visibleArrow: {
      type: Boolean,
      default: false
    }
  }
}
</script>
