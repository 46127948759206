import { mapGetters } from 'vuex'

const mixin = {
  data() {
    return {
      id: null,
      visible: false,
      visibleReal: false,
      loading: false,
      btnSaveLoading: false,
      gap: 24
    }
  },
  computed: {
    ...mapGetters([
      'loginInfo',
      'currentHoldId',
      'currentHoldName',
      'currentHasChild'
    ])
  },
  methods: {

    open(id, datas) {
      this.id = id
      this.visible = true
      this.visibleReal = true
      this.loading = true
      this.fetchItem(datas,id).then(() => {
        this.loading = false
      })
      this.$emit('input', true)
    },

    save() {
      this.btnSaveLoading = true
      setTimeout(() => {
        this.validForms()
        .then(this.id ? this.modifyItem : this.addItem)
        .then(ret => {
          //this.btnSaveLoading = false
          if (!ret.data.errCode) {
            this.print('success', this.$t('common.success'))
            this.visible = false
            this.$emit('refresh')
          } else {
            this.print('error', ret.data.errMsg)
            this.btnSaveLoading = false
          }
        })
        .catch(err => {
          this.btnSaveLoading = false
          console.error(err)
          if (typeof err === 'boolean') return
          this.print('error', this.$t('common.errorTip'))
        })
      }, 200)

    },

    cancel() {
      this.visible = false
    },

    closed() {
      this.btnSaveLoading = false
      this.resetForms()
      this.$emit('input', false)
      this.visibleReal = false
    },

    print(type, msg, time = 10000) {
      if (!msg) {
        return
      }
      this.$message({
        showClose: true,
        message: msg,
        type,
        duration: time
      })
    }

  },
  filters: {
    emptyFormat: function (value) {
      if (!value) return '--'
      return value
    }
  }
}

export default mixin
