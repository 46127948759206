export function remoteLoad(url, hasCallback) {
  return createScript(url)
  /**
   * 创建script
   * @param url
   * @returns {Promise}
   */
  function createScript(url) {
    var scriptElement = document.createElement('script')
    document.body.appendChild(scriptElement)
    var promise = new Promise((resolve, reject) => {
      scriptElement.addEventListener('load', e => {
        removeScript(scriptElement)
        if (!hasCallback) {
          resolve(e)
        }
      }, false)

      scriptElement.addEventListener('error', e => {
        removeScript(scriptElement)
        reject(e)
      }, false)

      if (hasCallback) {
        window.____callback____ = function () {
          resolve()
          window.____callback____ = null
        }
      }
    })

    if (hasCallback) {
      url += '&callback=____callback____'
    }

    scriptElement.src = url

    return promise
  }

  /**
   * 移除script标签
   * @param scriptElement script dom
   */
  function removeScript(scriptElement) {
    document.body.removeChild(scriptElement)
  }
}

export function debounce(fn, delay) {
  // 维护一个 timer
  let timer = null;

  return function () {
    // 通过 ‘this’ 和 ‘arguments’ 获取函数的作用域和变量
    let context = this;
    let args = arguments;

    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  }
}

export function browerType() {
  // 判断浏览器类型
  const ua = window.navigator.userAgent.toLowerCase()
  return {
    isWeiXin: ua.match(/MicroMessenger/i) == 'micromessenger', // 是否微信
    isMobile: !!ua.match(/AppleWebKit.*Mobile.*/), // 是否移动设备
    isAndroidWebView: false, // 是否安卓WebView
    isIosWebView: false // 是否IOS WebView
  }
}

export function banOverFlowScroll(el) {
  // 滚动元素滚到头之后禁用掉默认滚动
  let sy = 0
  el.addEventListener('touchstart', e => {
    sy = e.touches[0].pageY
  })
  el.addEventListener('touchmove', e => {
    let down = (e.touches[0].pageY - sy > 0)
    if (down && el.scrollTop <= 0) {
      e.preventDefault()
    }
    if (!down && el.scrollTop >= el.scrollHeight - el.clientHeight) {
      e.preventDefault()
    }
  })
}

export function urlEncode(str) {
  // eslint-disable-next-line
  return escape(str).replace(/\+/g, '%2B').replace(/\"/g, '%22').replace(/\'/g, '%27').replace(/\//g, '%2F')
}

export function parseQueryString(search) {
  if (!search) {
    return {}
  }
  // 获取查询字符串的内容
  let keyValueCollections = {}
  let queryCollections = search.substr(1).split('&')
  for (let i = 0; i < queryCollections.length; i++) {
    keyValueCollections[queryCollections[i].split('=')[0]] = queryCollections[i].split('=')[1]
  }
  return keyValueCollections
}

// export function doAnyway(fn, context, tryCount) {
//   try {
//     if (tryCount-- < 0) {
//       return
//     }
//     console.log([].splice.apply(arguments, [3]))
//     fn.apply(context, [].splice.apply(arguments, [3]))
//     console.log('正常')
//   } catch (ex){
//     console.log('失败')
//     setTimeout(() => {
//       doAnyway([].splice.apply(arguments, [3]))
//     }, 50)
//   }
// }

export function deleteFromArray(arr, compare) {
  const index = arr.findIndex(compare)
  if (index > -1) {
    arr.splice(index, 1)
  }
}

export function findFromArray(arr, compare) {
  const index = arr.findIndex(compare)
  return ~index ? arr[index] : null
}

export function replaceFromArray(arr, newItem, compare) {
  const index = arr.findIndex(compare)
  if (~index) {
    arr.splice(index, 1, newItem)
  }
}

export function saveBlob(blob, name) {
  console.log('导出', `名称:${name}`, blob)
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, name)
  } else {
    let data = new Blob([blob], {
      type: "application/excel"
    });
    var a = document.createElement('a')
    a.download = name;
    a.href = window.URL.createObjectURL(data);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}

export function saveBlobKML(blob, name) {
  console.log('导出', `名称:${name}`, blob)
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, name)
  } else {
    let data = new Blob([blob], {
      type: "application/xml"
    });
    var a = document.createElement('a')
    a.download = name;
    a.href = window.URL.createObjectURL(data);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}

export function throttle(delay, action) {
  let last = 0
  return function () {
    let ctx = this
    let curr = +new Date()
    if (curr - last > delay) {
      action.apply(ctx, arguments)
      last = curr
    }
  }
}

export function saveCanvas(canvas, name) {
  const code = canvas.toDataURL()
  let parts = code.split(';base64,');
  let contentType = parts[0].split(':')[1];
  let raw = window.atob(parts[1]);
  let rawLength = raw.length;
  let uInt8Array = new Uint8Array(rawLength);
  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
  }
  const blob = new Blob([uInt8Array], {
    type: contentType
  })
  saveBlob(blob, name)
}

export function dragElementDialog(window) {
  let startX = 0
  let startY = 0
  let windowTop = 0
  let windowLeft = 0
  let el = null
  let elHeight = 0
  let elWidth = 0
  let minLeft = 0
  let minTop = 0
  let maxLeft = 0
  let maxTop = 0

  const move = e => {
    // 移动窗口位置
    let posX = e.clientX
    let posY = e.clientY

    let wTop = posY - startY + windowTop
    let wLeft = posX - startX + windowLeft

    // console.log(windowTop, windowLeft, wTop, wLeft)
    if (wTop < minTop) {
      wTop = minTop
    }

    if (wLeft < minLeft) {
      wLeft = minLeft
    }

    if (wTop > maxTop) {
      wTop = maxTop
    }

    if (wLeft > maxLeft) {
      wLeft = maxLeft
    }

    el.style.top = wTop + 'px'
    el.style.left = wLeft + 'px'
    el.style.position = 'absolute' // 绝对定位
  }

  const onDrag = throttle(5, move)

  const bindMove = e => {
    // 绑定element-dialog
    if (!e.target.classList.contains('el-dialog__header') || !e.target.parentElement.classList.contains('v-dialog')) {
      return
    }

    el = e.target.parentElement

    // 开始移动时鼠标的位置
    startX = e.clientX
    startY = e.clientY

    // 开始移动时窗口的位置
    windowTop = e.target.parentElement.offsetTop
    windowLeft = e.target.parentElement.offsetLeft

    // 窗口大小
    elHeight = e.target.parentElement.clientHeight
    elWidth = e.target.parentElement.clientWidth

    // 边界位置、包含transform 50%
    // minTop = elHeight / 2
    // minLeft = elWidth / 2
    // maxTop = window.innerHeight - elHeight + minTop
    // maxLeft = window.innerWidth - elWidth + minLeft
    // 边界位置、不包含transform 50%
    minTop = 0
    minLeft = 0
    maxTop = window.innerHeight - elHeight + minTop - 2
    maxLeft = window.innerWidth - elWidth + minLeft - 2

    window.addEventListener('mousemove', onDrag)
    document.body.onselectstart = () => {
      return false
    }
    document.body.ondragstart = () => {
      return false
    }
  }

  const unBindMove = () => {
    startX = 0
    startY = 0
    windowTop = 0
    windowLeft = 0
    el = null
    window.removeEventListener('mousemove', onDrag)
    document.body.onselectstart = null
    document.body.ondragstart = null
  }

  window.removeEventListener('mousedown', bindMove)
  window.removeEventListener('mouseup', unBindMove)
  window.addEventListener('mousedown', bindMove)
  window.addEventListener('mouseup', unBindMove)
}

export function RandomStr(length) {
  let str = ''
  let arr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456'
  for (let i = 0; i < length; i++) {
    str += arr[Math.floor(Math.random() * (arr.length - 1))]
  }
  return str
}
export function utcTime(times) {
  let timeStr = String(times).split(":");
  let d = new Date();
  let localZone = d.getTimezoneOffset()
  d.setHours(timeStr[0], timeStr[1], '00');
  d.setMinutes(d.getMinutes() + localZone)
  let hours = d.getHours() < 10 ? '0' + d.getHours() : d.getHours()
  let min = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes()
  let utcTime = hours + ':' + min
  return utcTime
}
export function localTime(times) {
  let timelist = ''
  if (times) {
    timelist = []
    let timeElement = String(times).split(" ");
    timeElement.forEach(i => {
      timelist.push(exchangeTimes(i))
    });
    timelist = timelist.join(' ')
  }
  return timelist

  function exchangeTimes(t) {
    let timeStr = String(t).split(":");
    let d = new Date();
    let localZone = d.getTimezoneOffset()
    d.setHours(timeStr[0], timeStr[1], '00');
    d.setMinutes(d.getMinutes() - 480 - localZone)
    let hours = d.getHours() < 10 ? '0' + d.getHours() : d.getHours()
    let min = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes()
    let utcTime = hours + ':' + min
    return utcTime
  }
}
export function ubiTags(datas) {
  let index = 0
  if (datas.Overturn !== '正常') {
    index = 1
  } else if (datas.Drop !== '正常') {
    index = 2
  } else if (datas.ADSpeed === '急加速') {
    index = 3
  } else if (datas.ADSpeed === '急刹车') {
    index = 4
  } else if (datas.SharpTurn === '右急转弯') {
    index = 5
  } else if (datas.SharpTurn === '左急转弯') {
    index = 6
  } else {
    index = 0
  }
  return index
}


//百度坐标转高德（传入经度、纬度）
export function bd_decrypt(bd_lng, bd_lat) {
  var X_PI = Math.PI * 3000.0 / 180.0;
  var x = bd_lng - 0.0065;
  var y = bd_lat - 0.006;
  var z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * X_PI);
  var theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * X_PI);
  var gg_lng = z * Math.cos(theta);
  var gg_lat = z * Math.sin(theta);
  return {
    lng: gg_lng,
    lat: gg_lat
  }
}
//高德坐标转百度（传入经度、纬度）
export function bd_encrypt(gg_lng, gg_lat) {
  var X_PI = Math.PI * 3000.0 / 180.0;
  var x = gg_lng,
    y = gg_lat;
  var z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * X_PI);
  var theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * X_PI);
  var bd_lng = z * Math.cos(theta) + 0.0065;
  var bd_lat = z * Math.sin(theta) + 0.006;
  return {
    bd_lat: bd_lat,
    bd_lng: bd_lng
  };
}


export function translateFR(status) {
  let jsons = {
    "Idling": "ralenti",
    "Moving": "En mouvement",
    "Stop": "Arrêter",
    "Main voltage": "retour, tension principale",
    "Satellite": "satellite",
    "Strong": "fort",
    "Middle": "Milieu",
    "Weak": "faible",
    "Temperature": "température",
    "Upload interval": "intervalle de téléchargement",
    "Multiple points upload": "téléchargement de points multiples",
    "Rest work": "travail de repos",
    "Battery": "batterie",
    "day": "journée",
    "Seconds": "Seconds",
    "Hours": "Les heures",
    "Emergency alarm": "Alerte d'urgence",
    "Return": "Revenir",
    "Heart beat": "Battement de coeur",
    "GSM Network": "Réseau GSM",
    "Power save mode": "Mode économie d'énergie",
    "Power off alarm": "Éteindre l'alarme",
    "Low voltage alarm": "Alarme basse tension",
    "Interval": "Intervalle",
    "Nort": "Nord",
    "South": "Sud",
    "East": "Est",
    "West": "Ouest"
  }
  let str = status
  let arr = status.split(',')
  arr.forEach(k => {
    for (let key in jsons) {
      str = str.replace(new RegExp(key, 'g'), jsons[key])
    }
  });
  return str
}

export function translateHour(status, local) {
  let lang = {
    en: {
      '天': 'day',
      '小时': 'hour',
      '分': 'minute'
    },
    fr: {
      '天': 'jours',
      '小时': 'Heure',
      '分': 'minute'
    },
    zh: {
      '天': '天',
      '小时': '小时',
      '分': '分'
    }
  }
  let str = status
  for (let key in lang[local]) {
    str = str.replace(new RegExp(key, 'g'), lang[local][key])
  }
  return str
}

export function sortPostion(arr) {
  let array = arr
  for (let j = 0; j < arr.length - 1; j++) {
    for (let i = 0; i < arr.length - 1 - j; i++) {
      if (parseInt(arr[i].TyreNo) > parseInt(arr[i + 1].TyreNo)) {
        var temp = arr[i];
        arr[i] = arr[i + 1];
        arr[i + 1] = temp;
      }
    }
  }
  return array
}


export function translateGps(TypeID, item, isAlarm) {
  // 1绿色,2蓝色,3红色,4灰色，5黄色，6紫色
  let tag = 4
  if (isAlarm) {
    //包含报警状态
    if (item.isOnline) {
      if (item.isAlarm) {
        tag = 5
      } else {
        if (TypeID === 0) {
          if (!(item.GPSFlag % 2)) {
            tag = 6
          } else {
            if (item.Status) {
              if (item.Speed) {
                tag = 1
              } else {
                tag = 3
              }
            } else {
              if (item.Speed) {
                tag = 1
              } else {
                tag = 2
              }
            }
          }
        } else {
          if (!(item.GPSFlag % 2)) {
            tag = 6
          } else {
            if (!!item.Speed) {
              tag = 1
            } else {
              tag = 3
            }
          }
        }
      }
    } else {
      tag = 4
    }

    // if (item.isAlarm) {
    //   tag = 5
    // } else {
    //   if (item.isOnline) {
    //     if (TypeID === 0) {
    //       if (item.Status) {
    //         if (!!(item.GPSFlag % 2)) {
    //           //acc开
    //           if (!!item.Speed) {
    //             tag = 1
    //           } else {
    //             tag = 3
    //           }
    //         } else {
    //           //acc开+无定位
    //           tag = 6
    //         }
    //       } else {
    //         if (!!(item.GPSFlag % 2)) {
    //           //Acc开+精确定位
    //           tag = 2
    //         } else {
    //           //Acc关+无定位
    //           tag = 6
    //         }
    //       }

    //     } else {
    //       if (!!(item.GPSFlag % 2)) {
    //         if (!!item.Speed) {
    //           tag = 1
    //         } else {
    //           tag = 3
    //         }
    //       } else {
    //         tag = 6
    //       }
    //     }

    //   } else {
    //     tag = 4
    //   }
    // }

  } else {
    //不包含报警状态
    if (item.isOnline) {
      if (TypeID === 0) {
        if (!(item.GPSFlag % 2)) {
          tag = 6
        } else {
          if (item.Status) {
            if (item.Speed) {
              tag = 1
            } else {
              tag = 3
            }
          } else {
            if (item.Speed) {
              tag = 1
            } else {
              tag = 2
            }
          }
        }
      } else {
        if (!(item.GPSFlag % 2)) {
          tag = 6
        } else {
          if (!!item.Speed) {
            tag = 1
          } else {
            tag = 3
          }
        }
      }
    } else {
      tag = 4
    }

    // if (item.isOnline) {
    //   if (TypeID === 0) {
    //     if (item.Status) {
    //       if (!!(item.GPSFlag % 2)) {
    //         //acc开
    //         if (!!item.Speed) {
    //           tag = 1
    //         } else {
    //           tag = 3
    //         }
    //       } else {
    //         //acc开+无定位
    //         tag = 6
    //       }
    //     } else {
    //       if (!!(item.GPSFlag % 2)) {
    //         //Acc开+熄火
    //         tag = 2
    //       } else {
    //         //Acc关+无定位
    //         tag = 6
    //       }
    //     }

    //   } else {
    //     if (!!(item.GPSFlag % 2)) {
    //       if (!!item.Speed) {
    //         tag = 1
    //       } else {
    //         tag = 3
    //       }
    //     } else {
    //       tag = 6
    //     }
    //   }

    // } else {
    //   tag = 4
    // }
  }
  return tag
}
