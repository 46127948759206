
import DataPanel from './data-panel.vue'
import i18n from '@/lang'

const dataPanel = {
  install(Vue) {

    const panel = Vue.extend(DataPanel)

    Vue.prototype['$dataPanel'] = {
      create: datas => {
        let el = new panel({i18n})
        Object.assign(el, datas)
        return el.$mount().$el
      },
      getInstance: datas => {
        let el = new panel({i18n})
        Object.assign(el, datas)
        return el
      }
    }

  }
}

export default dataPanel
