import storage from 'good-storage'
import miment from 'miment'

const TokenKey = 'Admin-Token'
const TokenKeyTime = 'Admin-Token-Time'

export function getToken() {
  // 检测时间
  const time = getTokenTime()
  // || miment().isAfter(miment(time).add(600, "mm"))
  if (!time) {
    return null
  }

  return storage.get(TokenKey)||storage.session.get(TokenKey)
}

export function setToken(token) {
  return storage.set(TokenKey, token)
}

export function removeToken() {
  return storage.remove(TokenKey)
}

export function setSessionToken(token) {
  return storage.session.set(TokenKey, token)
}


export function getTokenTime() {
  return storage.get(TokenKeyTime)
}

export function setTokenTime(time) {
  return storage.set(TokenKeyTime, time)
}

export function removeTokenTime() {
  return storage.remove(TokenKeyTime)
}
