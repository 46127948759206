<template>
  <!-- <i-icon name="icon-magnify" v-if="!isSearch" v-ripple></i-icon> -->
  <!-- <el-button v-if="!isSearch" icon="el-icon-search"></el-button> -->
  <div class="h-wrapper" :class="{focus:isFocus}">
    <el-popover
      v-model="visible"
      :visible-arrow="false"
      ref="popover"
      placement="bottom-start"
      title=""
      popper-class="el-popper--isnp"
      :offset="22"
      trigger="manual">
      <div class="h-results">
        <div v-for="group in results" :key="group.group" class="h-group">
          <p class="h-group-title">{{$t('layout')[group.group] || group.group}}</p>
          <div class="h-group-content" v-for="(subgroup, p) in group.items" :key="p">
            <div class="content-label" :class="{uselabel: subgroup.type == 2 || subgroup.type == 4} ">
              <!--用户名、车辆名-->
              <p v-if="subgroup.type == 2" @click="itemClick(subgroup)" @mouseover="showTitle">
                <highlight-key :light-key="queryKey" :text="subgroup.subgroup"></highlight-key>
              </p>

              <p v-else-if="subgroup.type == 4" @click="itemClick(subgroup)" @mouseover="showTitle">
                <highlight-key :light-key="queryKey" :text="subgroup.subgroup"></highlight-key>
              </p>


              <p v-else @click="itemClick(subgroup)" @mouseover="showTitle">{{subgroup.subgroup}}</p>
            </div>
            <ul class="content-list">
              <li v-for="(item,index) in subgroup.items" :key="index" @click="itemClick(item)">
                <highlight-key :light-key="queryKey" :text="item.name"></highlight-key>
              </li>
            </ul>
          </div>
        </div>
        <!-- {{results}} -->
        <div v-if="!results.length" class="no-results">{{$t('common.noResults')}}</div>
      </div>
      <el-input v-model.trim="queryKey" size="mini" @focus="bindFocus" slot="reference" @keydown.enter.native="fetchResult" @blur="isFocus=false"
      :placeholder="$t('layout.queryBykey')"
      :class="{'animate': loading}">
        <i-icon class="prifixIcon" name="icon-magnify" v-if="!isSearch" slot="prefix" :class="{'animate': loading}"></i-icon>
      </el-input>
    </el-popover>

    <detail-device ref="detailDevice" :append-to-body="appendToBody"></detail-device>
    <detail-hold ref="detailHold" :append-to-body="appendToBody"></detail-hold>
    <detail-user ref="detailUser" :append-to-body="appendToBody"></detail-user>
    <detail-vehicle ref="detailVehicle" :append-to-body="appendToBody"></detail-vehicle>
  </div>
</template>

<script>
import {debounce} from '@/common/utils'
import {AdvanceSearch} from '@/api/common'
import HighlightKey from '@/views/common/highlight-key'
import HoldPopover from '@/views/common/hold-popover'

// 详情页面
import DetailDevice from '@/views/manage/detail/detail-device'
import DetailHold from '@/views/manage/detail/detail-hold'
import DetailUser from '@/views/manage/detail/detail-user'
import DetailVehicle from '@/views/manage/detail/detail-vehicle'


export default {
  props: {
    menus: {
      type: Array,
      default: () => ([])
    },
    appendToBody: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isFocus: false,
      isSearch: false,
      visible: false,
      queryKey: '',
      results: [],
      loading: false
    }
  },
  watch: {
    visible(val) {
      if (val) {
        window.addEventListener('click', this.closeMenu)
      } else {
        window.removeEventListener('click', this.closeMenu)
      }
    },
    queryKey() {
      this.debounce_fetchResult()
    }
  },
  components: {
    HighlightKey,
    DetailDevice,
    DetailHold,
    DetailUser,
    DetailVehicle,
    HoldPopover
  },
  computed: {
    menuList() {
      let output = []
      this.menus.forEach(k => {
        if (k.link) {
          // 可点击, 无子节点
          output.push(Object.assign({}, k, {items: [k]}))
        } else {
          output.push(Object.assign({}, k))
        }
      })
      return output
    }
  },
  mounted() {
    this.debounce_fetchResult = debounce(this.fetchResult, 300)
  },
  methods: {
    bindFocus(){
      this.debounce_fetchResult()
    },
    fetchResult() {
      // let result = {
      //   group: '功能',
      //   items: [
      //     {
      //       subgroup: '首页',
      //       value: '',
      //       type: 1, // 1 路由 2 用户 3 账号 4 车辆 5 设备
      //       items: [
      //         {
      //           name: '',
      //           value: '',
      //           type: 1 // 1 路由 2 用户 3 账号 4 车辆 5 设备
      //         }
      //       ]
      //     }
      //   ]
      // }

      // console.log('fetchResult')

      if (!this.queryKey) {
        this.visible = false
        // result置空
        return
      }


      this.loading = true
      AdvanceSearch(this.queryKey, true).then(ret => {

        let results = ret.data.outList

        // 屏蔽功能查找
        // let menuGroup = this.filterMenu(this.queryKey)
        let menuGroup = { group: '功能', items: [] }

        if (menuGroup.items.length) {
          results.splice(0, 0, menuGroup)
        }

        this.results = results
        this.visible = true
        console.log(this.results)
      }).finally(() => {
        this.loading = false
      })

      // this.results = [
      //   menuGroup
      // ]


    },

    filterMenu(key) {
      let output = []
      this.menuList.forEach(k => {
        let j = {
          items: []
        }

        k.items.forEach(p => {
          if (p.title.toLowerCase().indexOf(key.toLowerCase()) != -1) {

            // 添加路由
            j.items.push({
              name: p.title,
              value: p.link,
              type: 1
            })

          }
        })

        if (j.items.length > 0) {
          output.push(Object.assign({
            subgroup: k.title,
            value: k.link,
            type: 1
          }, j))
        }
      })

      return { group: '功能', items: output }
    },

    itemClick(item) {
      if (item.type == 1) {
        this.$router.push(item.value)
      } else if (item.type == 2) {
        this.$refs.detailHold.open(item.value)
      } else if (item.type == 3) {
        this.$refs.detailUser.open(item.value)
      } else if (item.type == 4) {

        //this.$refs.detailVehicle.open(item.value, {tabName: 'vehicle'})

        // 打开在线监控
        if (item.items && item.items.length > 0) {
          this.$router.replace(`/online/monitor?action=addTaskList&objectid=${item.items.map(k => k.value).join(',')}`)
        } else {
          this.$refs.detailVehicle.open(item.value, {tabName: 'vehicle'})
        }
      } else if (item.type == 5) {
        this.$refs.detailDevice.open(item.value)
      }
    },

    showTitle(e) {
      if (e.target.offsetWidth < e.target.scrollWidth) {
        e.target.title = e.target.innerText
      } else {
        e.target.removeAttribute('title')
      }
    },

    closeMenu() {
      this.visible = false
    }

  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/variables-simple.scss";
@import "../../styles/mixin.scss";
.h-wrapper {
  display: flex;
  height: $page-header-height;
  align-items: center;
  justify-content: center;
  width: 215px;
  transition: all 0.3s ease-in-out;
  will-change: width;
  margin: 0 8px;
  // &.focus {
  //    width: 325px;
  // }
  .el-input {
    & ::v-deep  {
      .el-input__inner {
        border-radius: 14px;
        transition: all 0.3s ease-in-out;
        &:focus {
          box-shadow: 0 1px 3px 0 rgba(44,34,243,.25);
          border-color: transparent;
          background-color: #f4f4f8;
        }
      }
      .prifixIcon {
        font-size: 20px;
        vertical-align: middle;
        margin: 0 4px;
        height: 100%;
        &.animate {
          animation: magnify 2s linear infinite;
          color: $--color-primary;
        }
      }
    }
  }
  & ::v-deep .el-popover {
    margin-top: 22px;
  }

}
$lineHeight: 28px;
.h-results {
  width: 525px;
  border-radius: 4px;
  padding: 4px;
  max-height: calc(100vh - #{$page-header-height} * 2 );
  overflow-y: auto;
  @include scroll-bar;
  .h-group {
    .h-group-title {
      background-color: $--color-primary;
      color: #FFF;
      height: $lineHeight;
      line-height: $lineHeight;
      padding: 0 10px;
    }
    .h-group-content {
      display: flex;
      align-items: stretch;
      border-bottom: 1px solid #d3d3d3;

      .content-label {
        width: 200px;
        flex: 0 0 200px;
        background-color: #F1F3F5;
        color: #555;
        text-align: right;
        line-height: $lineHeight;
        padding: 0 10px;
        border-right: 1px solid #d3d3d3;
        &.uselabel:hover {
          cursor: pointer;
          background-color: #E7EDF3;
        }

        p {
          height: $lineHeight;
          line-height: $lineHeight;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

        }
      }
      .content-list {
        list-style: none;
        flex: 1 1;
        li {
          border-bottom: 1px solid #d3d3d3;
          height: $lineHeight;
          line-height: $lineHeight;
          padding: 0 10px;
          display: block;
          color: #2c3e50;

          &:last-child {
            border-bottom: 0;
          }

          &:hover {
            cursor: pointer;
            background-color: #E7EDF3;
          }
        }
        // & li:last-child {
        //   border-bottom: 0;
        // }
      }
    }
  }
  .no-results {
    height: $lineHeight * 2;
    line-height: $lineHeight * 2;
    text-align: center;
  }
}

@keyframes magnify {
  0% {
    transform: translateX(-4px);
  }
  12% {
    transform: translate3d(-2px, -2px, 0);
  }
  25% {
    transform: translateY(-4px);
  }
  37% {
    transform: translate3d(2px, -2px, 0);
  }
  50% {
    transform: translateX(4px);
  }
  62% {
    transform: translate3d(-2px, -2px, 0);
  }
  75% {
    transform: translateY(2px);
  }
  87% {
    transform: translate3d(-2px, 2px, 0);
  }
  100% {
    transform: translateX(-4px);
  }
}

@keyframes raceLamp {
  0% {
    width: 0;
    transform: translateX(0);
  }
  25% {
    width: 50%;
    transform: translateX(50%);
  }
  50% {
    width: 0;
    transform: translateX(100%);
  }
  75% {
    width: 50%;
    transform: translateX(50%);
  }
  100% {
    width: 0;
    transform: translateX(0);
  }
}
</style>
