import request from '@/common/request'
import { commonParams } from '@/api/config'
import { getToken } from '@/common/auth'

export function Query(pageIndex, pageSize, sortKey, sortDirect, filters) {
  const loginKey = getToken()
  return request({
    url: './query/QueryNotifyList',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      pageIndex,
      pageSize,
      sortKey,
      sortDirect,
      filters
    })
  })
}

export function QueryCurrentNotify() {
  const loginKey = getToken()
  return request({
    url: './query/QueryCurrentNotify',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey
    })
  })
}

export function AddNotifyInfo(newItem) {
  const loginKey = getToken()
  return request({
    url: './add/AddNotifyInfo',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      newItem
    })
  })
}

export function Delete(IDs) {
  const loginKey = getToken()
  return request({
    url: './delete/DeleteNotify',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      IDs
    })
  })
}
