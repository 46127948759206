<template>
<!-- :style="{overflowY:isAnimating?'hidden':'auto',overflowX:'hidden'}" -->
  <main-wrapper
    manage
    style="overflow:hidden"
    :tool="!!$route.meta.showTitle"
    tool-background-color="#409EFF"
    tool-color="#FFFFFF"
    :border-left-color="$route.meta.showTitle ? '#409EFF' : undefined"
  >
    <span slot="tool" class="ipx-1" v-if="!!$route.meta.showTitle">{{title}}</span>
    <div class="page-main-wrapper" :class="{'showTitle': !!$route.meta.showTitle}">
      <div class="page-main-aside" v-show="!!$route.meta.showTree && isShowAside" v-if="!noMenuTypeList.includes(loginInfo.UserType)">
        <keep-alive :include="cachedTrees">
          <router-view name="aside"></router-view>
        </keep-alive>
      </div>
      <div class="page-main-body" :class="{'wider': !isShowAside || !$route.meta.showTree}" :style="$route.path==='/home'?'minHeight:100vh':''">
        <!-- <arrow-button v-if="!!$route.meta.showTree && loginInfo.allFuns.includes(5014) " @click="isShowAside = !isShowAside" class="toggleNav" :position="isShowAside?'right':'left'"></arrow-button> -->
        <!-- <i-icon v-if="!!$route.meta.showTree" @click="isShowAside = !isShowAside" class="toggleNav" :name="`${isShowAside ? 'icon-shouqi' : 'icon-zhankai'}`"></i-icon> -->
        <keep-alive :include="cachedViews">
          <router-view ref="view" @refresh="refreshContent"></router-view>
        </keep-alive>
      </div>
    </div>

  </main-wrapper>
</template>

<script>
import {mapGetters} from 'vuex'
import ArrowButton from '@/components/arrow-button'
import {getAsideVisible,setAsideVisible} from '@/common/cache'
export default {
  components: {
    ArrowButton
  },
  computed: {
    ...mapGetters([
      'cachedViews',
      'cachedTrees',
      'loginInfo'
    ])
  },
  data() {
    return {
      title: '',
      isAnimating: false,
      isShowAside: true,
      noMenuTypeList: [961,962,963]
    }
  },
  watch: {
    $route() {
      this.updateTitle()
    },
    isShowAside(val) {
      setAsideVisible(val)
    }
  },
  methods: {
    refreshTree() {

    },
    refreshContent() {
      setTimeout(() => {
        this.$refs.view.refreshHandle && this.$refs.view.refreshHandle()
      }, 300)
    },
    updateTitle() {
      if (this.$route.meta.title) {
        this.title = this.$route.meta.title
      }
    },
    doLayout() {
      this.$refs.view.doLayout && this.$refs.view.doLayout()
    }
  },
  created() {
    this.updateTitle()
    this.isShowAside = getAsideVisible()
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/variables-simple.scss";
@import "../../styles/mixin.scss";
@include v-wrapper;
.page-main-wrapper {
  display: flex;
  align-items: stretch;
  position: relative;
  min-height: $page-main-height;
  min-height: var(--page-main-height);
  &.showTitle {
    min-height: $page-main-body-height;
  }
  .page-main-aside {
    // $nav-width: 240px;
    // width: $nav-width;
    // flex-shrink: 0;
    // overflow-y: auto;
    position: relative;
    // border-right: 1px solid #DCDFE6;
    // z-index: 112;

    z-index: $page-nav-zindex;
    height: calc(100vh - 70px);
    // height: calc(100%);
  }
  .page-main-body {
    width: 0;
    flex-grow: 1;
    height: calc(100vh - 70px);
    // height: calc(100%);
    position: relative;
    .toggleNav {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: 102;
      font-size: 56px;
      cursor: default;
    }
  }
}
</style>
